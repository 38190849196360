// recft_clsx_module_scss
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from '../general/DeleteAccount.module.scss'     //file scss cùng cấp
import { useDispatch } from 'react-redux'; //useSelector: hook lấy state từ jobSlice.js ; useDispatch: hook phát đi đến jobSlice.js

import collectionAPI from '../../../API/collectionAPI';
import { storeObjectInLocalStorage } from '../../share/sharedFunction';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { isSignIn, setState_objSignin } from '../../../features/generalSlice';
function DeleteAccount() {
   
   const [stateFormLogin, setstateFormLogin] = useState({username: "", password: ""});

   const handleSubmitSignIn = async (e:any) => {
      e.preventDefault();
      console.log('stateFormLogin: ', stateFormLogin);
      try {
         let response = await collectionAPI.collectionAPI.removeAccount(stateFormLogin);
         console.log('response: ', response);

         if (response.data.status === 'success') { // nếu trong server mà có data thì đi nhánh này, nó có data trong response

            alert(response.data.message)
         } 
         else if (response.data.status === 'fail') { // nếu trong server mà có data thì đi nhánh này, nó có data trong response

            alert(response.data.message)
         } 
   
      } catch (err) {
         // console.log('err:', err);
      }
   }

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      
      setstateFormLogin({
         ...stateFormLogin,
         [event.target.name]: event.target.value,
      })
   
   }
   const reset = () => {
      setstateFormLogin({username: "", password: ""})
   }

   const [stateBool_showPass, setStateBool_showPass] = useState(false); // boolean
   const handleTogglePassword = () => {
   
      setStateBool_showPass(!stateBool_showPass);
   
   }

   return (
      <div className={clsx(styles.component_DeleteAccount)}>
         <div className={clsx(styles.container)}>
            <div className={clsx(styles.formLogin)}>
               <form onSubmit = {handleSubmitSignIn}>
                  <h2>DELETE ACCOUNT</h2>
                  <div className={clsx(styles.row)}>
                     <label>Username: </label>
                     <span className={clsx(styles.box)}>
                        <input type="text" name="username" value={stateFormLogin.username} onChange={handleChange} placeholder='username...'/><div></div>
                     </span>
                  </div>
                  <div className={clsx(styles.row)}>
                     <label>Password: </label>
                     <span className={clsx(styles.box)}>
                        <input type={stateBool_showPass ? 'text' : 'password'} name="password" value={stateFormLogin.password} onChange={handleChange} placeholder='password...'/> 
                        <span className={clsx(styles.eyeWrapper)}><RemoveRedEyeIcon className={clsx(styles.eye)} onClick={handleTogglePassword}/></span>
                     </span>
                  </div>

                  <div className={clsx(styles.btnWrapper)}>
                        
                     <button type="submit" >Submit</button>
                     <button type="reset" onClick={reset}>Reset</button>
                  
                  </div>
               </form>
            </div>
         </div>
      </div>
   )
}
export default DeleteAccount