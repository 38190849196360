import { SignInDto } from '../types/AgentUser';
import apiClient from './APIClient';

const login = (body: {username: string, password: string})  => {
    const url = "/auth/loginAgentByUsername";
    // return apiClient.post<SignInDto>(url, body).then(res => res.data);
    return apiClient.post(url, body).then(res => res.data);
}

const findTree = (path: string)  => {
    const url = "/agent/findTree/"+path;
    return apiClient.get(url).then(res => res.data);
}
const findDirectChildren = (path: string)  => {
    const url = "/agent/findDirectChildren/" + path;
    return apiClient.get(url).then(res => res.data);
}

const findNodeByIdWithMemberAndUser = (nodeId: number)  => {
    const url = "/agent/findNodeByIdWithMemberAndUser/"+nodeId;
    return apiClient.get(url).then(res => res.data);
}

export default {
    login,
    findTree,
    findNodeByIdWithMemberAndUser,
    findDirectChildren
};
