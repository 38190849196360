import axios from 'axios';
import { appStore } from '../stores/AppStore';

const axiosInstance = axios.create({
    // baseURL: process.env.REACT_APP_API_BASE || 'http://localhost:3001/api',
    baseURL: process.env.REACT_APP_API_BASE || '/api',
    headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
    responseType: 'json',
});

// chèn token vào interceptor
axiosInstance.interceptors.request.use(function (config) {
    const store = appStore.authStore;

    if (store?.token && !config.headers['Authorization']) {
        config.headers['Authorization'] = `Bearer ${store.token}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(function (config) {
    const store = appStore.authStore;

    if(config.status === 401) {
        store.logout();
    }

    return config;
});

export const baseUrl: string | undefined = process.env.REACT_APP_API_BASE;

export default axiosInstance;