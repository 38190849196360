import { useState, useEffect } from 'react';
import collectionAPI from "../../../API/collectionAPI";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import clsx from 'clsx';
import styles from './hr.module.scss';
import { getCurrentDateTime, convertDateString } from './../../share/sharedFunction';

const FileUpload_nghiphep = () => {
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [stateArr_name_email_fullName, setStateArr_name_email_fullName] = useState([]);
    const getData_findAllEmployees = async () => {
        const response = await collectionAPI.collectionAPI.findAllEmployees(); //phải có await nghĩa là khi nào có data rồi thì mới lấy
        // console.log("response.data: ", response.data);
        setStateArr_name_email_fullName(response.data);
    };
    useEffect(() => {
        getData_findAllEmployees().then();
    }, []);
    
    const onDrop = (acceptedFiles) => {
        const selectedFile = acceptedFiles[0];
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onload = async (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: "binary" });

            // Assuming you want to read data from the first sheet
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            // Parse sheet data
            const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            sheetData.splice(0, 2); // bỏ 2 dòng đầu tiêu đề

            const arrNghiPhep = [];

            for (const row of sheetData) {
                // console.log('row[7]: ', row[7]);
                const nameToFind = row[7].trim().toLowerCase(); // Trim and convert to lowercase for case-insensitive comparison
                const foundObj = stateArr_name_email_fullName.find(obj => obj.fullName.trim().toLowerCase() === nameToFind);
            
                if (foundObj) {
                    // If a match is found, push the corresponding email and elements at index 6 and 7 into arrNghiPhep
                    const start = convertDateString(row[19]); // lấy cột bắt đầu nghỉ có index 19
                    const end = convertDateString(row[20]); // lấy cột bắt đầu nghỉ có index 19
                    let obj = {
                        email: foundObj.email,
                        createdAt: getCurrentDateTime(),
                        start: start,
                        end: end,
                        type: row[18]
                    }
                    // console.log('obj: ', obj);
                    arrNghiPhep.push(obj);
                }
            }
            
            // console.log('arrNghiPhep: ', arrNghiPhep);
            let response = await collectionAPI.collectionAPI.createRequest(arrNghiPhep);
            // console.log('response: ', response);
            // onDataFromFileUpload_nghiphep(arrNghiPhep)
            // console.log(arrGroupByEmail);
        };

        reader.readAsBinaryString(selectedFile);
    };





    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ".xlsx",
    });

    return (
        <div>
            <div {...getRootProps()} className={clsx(styles.dropZone)}>
                <input {...getInputProps()} />
                <p>Kéo thả file excel vào đây (.xlsx) , </p>
                <p>hoặc nhấn để chọn</p>
            </div>
        </div>
    );
};

export default FileUpload_nghiphep;

