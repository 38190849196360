import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, Outlet, useNavigate } from "react-router-dom"
import styles from './AgentSignUp.module.scss'
import { useSelector, useDispatch } from 'react-redux';
import { observer } from "mobx-react-lite";
import { useAppStore } from "../../../../stores/AppStore";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import arr_vietnam from '../../../share/arr_vietnam';
import UploadPicture_cccd_mt from './UploadPicture_cccd_mt';
import UploadPicture_cccd_ms from './UploadPicture_cccd_ms';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import collectionAPI from '../../../../API/collectionAPI';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

export const AgentSignUp =
    observer(() => {
        const {
            agentSignUpStore,
            agentSignUpStore: { usernameBoss, nodeId, randomNumber, objSignUp, isShowPass, isShowPassRetype },
        } = useAppStore();

        const [open, setOpen] = React.useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        // city, district, ward start---------------------------------------------------------------------------------
        const [stateBool_isDisable_city, setStateBool_isDisable_city] = useState(false);
        const [stateBool_isDisable_district, setStateBool_isDisable_district] = useState(false);
        const [stateBool_isDisable_ward, setStateBool_isDisable_ward] = useState(false);

        const [nameCitySelected, setNameCitiSelected] = useState(""); //chứa tên city khi mình chọn trong ô select option
        const [citiesOptions, setCitiesOptions] = useState<any[]>([]); //Tạo ra dánh sách các option để xổ ra

        const [nameDistrictSelected, setNameDistrictSelected] = useState("");
        const [districtsOptions, setDistrictsOptions] = useState<any[]>([]);

        const [nameWardSelected, setNameWardSelected] = useState("");
        const [wardsOptions, setWardsOptions] = useState<any[]>([]);

        const handleCity = (e: any) => {

            if (e.target.value === "empty") {
                alert("City can not be empty !");
            } else {

                agentSignUpStore.setObjSignUp("city", e.target.value);
                setNameCitiSelected(e.target.value);

                fullfillDistrictSelectOptions(e.target.value);
            }
        }

        const handleDistrict = (e: any) => {
            setNameDistrictSelected(e.target.value); // e.target.value là giá trị khi mình chọn trong ô select option
            fullfillWardsSelectOptions(e.target.value);
            agentSignUpStore.setObjSignUp("district", e.target.value);

            // console.log('setNameDistrictSelected: ', e.target.value);
        }

        const handleWard = (e: any) => {

            setNameWardSelected(e.target.value);
            agentSignUpStore.setObjSignUp("ward", e.target.value);

        }
        const fullfillCitySelectOptions = () => {
            // console.log('arr_vietnam: ', arr_vietnam);
            const arrVN = arr_vietnam.arr_vietnam;
            let arr: any = [];


            //@ts-ignore
            for (let i = 0, len = arrVN.length; i < len; i++) {
                arr.push(
                    <option key={Math.random()} value={arrVN[i].name}>{arrVN[i].name}</option>
                )
            }
            setCitiesOptions(arr); //set state cho nó là tập hợp các option để mình chèn vào select lúc render ra
        }
        const fullfillDistrictSelectOptions = (cityName: string) => {
            const arrVN = arr_vietnam.arr_vietnam;
            let arr: any = []
            // console.log('cityName: ', cityName);

            const indexCity = arrVN.findIndex((obj: any) => obj.name === cityName);// tìm index của city
            // console.log("indexCity: " + indexCity);
            // console.log((arr_vietnam as any)[indexCity].districts);

            let arrDistricts = arrVN[indexCity].districts

            for (let i = 0; i < arrDistricts.length; i++) {
                arr.push(
                    <option key={Math.random()} value={arrDistricts[i].name}>{arrDistricts[i].name}</option>
                )
            }

            setDistrictsOptions(arr);
        }
        const fullfillWardsSelectOptions = (districtName: string) => {
            const arrVN = arr_vietnam.arr_vietnam;
            let arr: any = []
            // console.log('districtName: ', districtName);
            const indexCity = arrVN.findIndex((obj: any) => obj.name === nameCitySelected);// tìm index của city
            const indexDistrict = arrVN[indexCity].districts.findIndex((obj: any) => obj.name === districtName);// tìm index của city

            let arrWards = arrVN[indexCity].districts[indexDistrict].Wards

            for (let i = 0; i < arrWards.length; i++) {
                arr.push(
                    // `$ {id}` ép id thành string
                    //@ts-ignore
                    <option key={Math.random()} value={arrWards[i].name}>{arrWards[i].name}</option>
                )
            }
            setWardsOptions(arr);
        }
        // city, district, ward end---------------------------------------------------------------------------------
        const [selectedFile_matTruoc, setSelectedFile_matTruoc] = useState(null);
        const [selectedFile_matSau, setSelectedFile_matSau] = useState(null);


        const receive_mediaID_mt_fromChild = (mediaId: string, imageFile: any) => {

            setSelectedFile_matTruoc(imageFile['0']);
            agentSignUpStore.setObjSignUp("cccd_mt_mediaId", mediaId)
            agentSignUpStore.setObjSignUp_error("cccd_mt_mediaId", "ok")
        }
        const receive_mediaID_ms_fromChild = (mediaId: string, imageFile: any) => {
            setSelectedFile_matSau(imageFile['0']);
            agentSignUpStore.setObjSignUp("cccd_ms_mediaId", mediaId)
            agentSignUpStore.setObjSignUp_error("cccd_ms_mediaId", "ok")

        }
        const navigate = useNavigate(); //hook dùng để chuyển trang web

        const gui = async () => {
            setOpen(true);
            const result = await agentSignUpStore.submit();

            if (result) {
                setOpen(true);
            }

            // console.log('objSignUp 1: ' + JSON.stringify(objSignUp, null, 4));
        }

        const currentUrl = window.location.href; // lấy cái url hiện tại chỉ đến trang này trên chrome

        useEffect(() => {
            // agentSignUpStore.getNodeIdFromUrl(currentUrl);
            agentSignUpStore.getUsernameFromUrl(currentUrl);
            agentSignUpStore.getRandomNumber();
            fullfillCitySelectOptions();
            checkAppInstalled(`exp://192.168.1.50:8081/--/path/into/app?hello=world`, "");
        }, []);

        const goToAppleStore = () => {
            window.open('https://apps.apple.com/vn/app/zalo/id579523206?l=vi', '_blank');
        };
        
        const goToGoogleStore = () => {
            window.open('https://play.google.com/store/apps/details?id=com.zing.zalo&hl=en&pli=1', '_blank');
        };
        
        // To navigate to a different page in your React app and open in a new tab
        const goToSignInPage = () => {
            window.open('/agent/signin', '_blank'); // Opens the page in a new tab
        };

        const [checked, setChecked] = useState(false);

        function checkAppInstalled(customURL: any, fallbackURL: any) {
            const start = new Date();
            let timeout = setTimeout(() => {
                setChecked(true);
                // window.location = fallbackURL; // Fallback if the app is not installed
            }, 1500); // Adjust as needed (lower for faster response, higher for safety)

            window.location = customURL; // Attempt to open the app

            // Clear the timeout if the app is opened (user leaves the page)
            window.onblur = function () {
                clearTimeout(timeout);
            };
        }

        if (checked) {
            return (
                <div className={clsx(styles.component_AgentSignUp)}>
                    <div className={clsx(styles.boxWallpaper)}>
                        <img src={`../../assets/signup/${randomNumber}.jpg`} className="wallpaper" alt="wallpaper" />
                        <h1>Welcome to NewWorld Services</h1>
                    </div>
                    <div className={clsx(styles.boxSignUp)}>
                        <div className={clsx(styles.boxTitle)}>
                            <h1>ĐĂNG KÝ HỘI VIÊN</h1>
                            <div className={clsx(styles.btnWrapper)}>
                                <button onClick={gui} className={clsx(styles.btnGui)}>Gửi</button>
                            </div>
                        </div>
                        <div className={clsx(styles.inputContainer)}>
                            <label>Mã code người giới thiệu </label>
                            <div className={clsx(styles.row, styles.darkBg)}>
                                {/* <p className={clsx(styles.value, styles.disable)}>{nodeId}</p> */}
                                <p className={clsx(styles.value, styles.disable)}>{usernameBoss}</p>
                                <span className={clsx(styles.thirdCol)}></span>
                            </div>
                            <label>Họ và tên </label>
                            <div className={clsx(styles.row)}>
                                <input type="text" name="name" value={objSignUp.name} onChange={agentSignUpStore.handleChange} /><div></div>
                                <span className={clsx(styles.thirdCol)}>
                                    {
                                        agentSignUpStore.objSignUp_error.name === "" ?
                                            ""
                                            :
                                            agentSignUpStore.objSignUp_error.name === "ok" ?
                                                <VerifiedIcon className={clsx(styles.success)} />
                                                :
                                                <HighlightOffIcon className={clsx(styles.fail)} />
                                    }
                                </span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.name !== "ok" &&
                                    agentSignUpStore.objSignUp_error.name
                                }
                            </div>
                            <label>Email </label>
                            <div className={clsx(styles.row)}>
                                <input type="text" name="email" value={objSignUp.email} onChange={agentSignUpStore.handleChange} /><div></div>
                                <span className={clsx(styles.thirdCol)}>
                                    {
                                        agentSignUpStore.objSignUp_error.email === "" ?
                                            ""
                                            :
                                            agentSignUpStore.objSignUp_error.email === "ok" ?
                                                <VerifiedIcon className={clsx(styles.success)} />
                                                :
                                                <HighlightOffIcon className={clsx(styles.fail)} />
                                    }
                                </span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.email !== "ok" &&
                                    agentSignUpStore.objSignUp_error.email
                                }
                            </div>
                            <label>Số điện thoại </label>
                            <div className={clsx(styles.row)}>
                                <input type="text" name="phone" value={objSignUp.phone} onChange={agentSignUpStore.handleChange} /><div></div>
                                <span className={clsx(styles.thirdCol)}>
                                    {
                                        agentSignUpStore.objSignUp_error.phone === "" ?
                                            ""
                                            :
                                            agentSignUpStore.objSignUp_error.phone === "ok" ?
                                                <VerifiedIcon className={clsx(styles.success)} />
                                                :
                                                <HighlightOffIcon className={clsx(styles.fail)} />
                                    }
                                </span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.phone !== "ok" &&
                                    agentSignUpStore.objSignUp_error.phone
                                }
                            </div>
                            <label>Username của bạn </label>
                            <div className={clsx(styles.row)}>
                                <input type="text" name="username" value={objSignUp.username} onChange={agentSignUpStore.handleChange} /><div></div>
                                <span className={clsx(styles.thirdCol)}>
                                    {
                                        agentSignUpStore.objSignUp_error.username === "" ?
                                            ""
                                            :
                                            agentSignUpStore.objSignUp_error.username === "ok" ?
                                                <VerifiedIcon className={clsx(styles.success)} />
                                                :
                                                <HighlightOffIcon className={clsx(styles.fail)} />
                                    }
                                </span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.username !== "ok" &&
                                    agentSignUpStore.objSignUp_error.username
                                }
                            </div>
                            <label>Mật khẩu </label>
                            <div className={clsx(styles.row)}>
                                <input type={isShowPass ? 'text' : 'password'} name="password" value={objSignUp.password} onChange={agentSignUpStore.handleChange} />
                                <RemoveRedEyeIcon className={clsx(styles.eye)} onClick={() => agentSignUpStore.setIsShowPass()} />
                                <span className={clsx(styles.eyeWrapper, styles.thirdCol)}>
                                    {
                                        agentSignUpStore.objSignUp_error.password === "" ?
                                            ""
                                            :
                                            agentSignUpStore.objSignUp_error.password === "ok" ?
                                                <VerifiedIcon className={clsx(styles.success)} />
                                                :
                                                <HighlightOffIcon className={clsx(styles.fail)} />
                                    }
                                </span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.password !== "ok" &&
                                    agentSignUpStore.objSignUp_error.password
                                }
                            </div>
                            <label>Nhập lại mật khẩu </label>
                            <div className={clsx(styles.row)}>
                                <input type={isShowPassRetype ? 'text' : 'password'} name="passwordRetype" value={objSignUp.passwordRetype} onChange={agentSignUpStore.handleChange} />
                                <RemoveRedEyeIcon className={clsx(styles.eye)} onClick={() => agentSignUpStore.setIsShowPassRetype()} />
                                <span className={clsx(styles.eyeWrapper, styles.thirdCol)}>
                                    {
                                        agentSignUpStore.objSignUp_error.passwordRetype === "" ?
                                            ""
                                            :
                                            agentSignUpStore.objSignUp_error.passwordRetype === "ok" ?
                                                <VerifiedIcon className={clsx(styles.success)} />
                                                :
                                                <HighlightOffIcon className={clsx(styles.fail)} />
                                    }
                                </span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.passwordRetype !== "ok" &&
                                    agentSignUpStore.objSignUp_error.passwordRetype
                                }
                            </div>
                            {/* <label>Chọn Thành phố</label>
                            <div className={clsx(styles.row)}>
                                <select name="citySelected" onChange={handleCity} disabled={stateBool_isDisable_city}
                                    className={clsx(styles.selectOption)}
                                // value={nameCitySelected}
                                >
                                    <option value="empty">Please select a city...</option>
                                    {citiesOptions}
                                </select>
                                <span className={clsx(styles.thirdCol)}></span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.city !== "ok" &&
                                    agentSignUpStore.objSignUp_error.city
                                }
                            </div>
                            <label>Chọn Quận</label>
                            <div className={clsx(styles.row)}>
                                <select name="districtSelected"
                                    onChange={handleDistrict}
                                    disabled={stateBool_isDisable_district}
                                    className={clsx(styles.selectOption)}
                                // value={nameDistrictSelected}
                                >
                                    {districtsOptions}
                                </select>
                                <span className={clsx(styles.thirdCol)}></span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.district !== "ok" &&
                                    agentSignUpStore.objSignUp_error.district
                                }
                            </div>
                            <label>Chọn Phường</label>
                            <div className={clsx(styles.row)}>
                                <select name="wardSelected" onChange={handleWard}
                                    disabled={stateBool_isDisable_ward} className={clsx(styles.selectOption)}
                                // value={nameWardSelected}
                                >
                                    {wardsOptions}
                                </select>
                                <span className={clsx(styles.thirdCol)}></span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.ward !== "ok" &&
                                    agentSignUpStore.objSignUp_error.ward
                                }
                            </div>
                            <label>Số nhà & tên đường</label>
                            <div className={clsx(styles.row)}>
                                <input type="text" name="address" value={objSignUp.address} onChange={agentSignUpStore.handleChange} />
                                <span className={clsx(styles.thirdCol)}>
                                    {
                                        agentSignUpStore.objSignUp_error.address === "" ?
                                            ""
                                            :
                                            agentSignUpStore.objSignUp_error.address === "ok" ?
                                                <VerifiedIcon className={clsx(styles.success)} />
                                                :
                                                <HighlightOffIcon className={clsx(styles.fail)} />
                                    }
                                </span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.address !== "ok" &&
                                    agentSignUpStore.objSignUp_error.address
                                }
                            </div>
                            <label>Số CCCD</label>
                            <div className={clsx(styles.row)}>
                                <input type="text" name="cccd" value={objSignUp.cccd} onChange={agentSignUpStore.handleChange} />
                                <span className={clsx(styles.thirdCol)}>
                                    {
                                        agentSignUpStore.objSignUp_error.cccd === "" ?
                                            ""
                                            :
                                            agentSignUpStore.objSignUp_error.cccd === "ok" ?
                                                <VerifiedIcon className={clsx(styles.success)} />
                                                :
                                                <HighlightOffIcon className={clsx(styles.fail)} />
                                    }
                                </span>
                            </div>
                            <div className={clsx(styles.rowReport)}>
                                {
                                    agentSignUpStore.objSignUp_error.cccd !== "ok" &&
                                    agentSignUpStore.objSignUp_error.cccd
                                }
                            </div> */}
                            {/* <div className={clsx(styles.row)}>
                            <label>CCCD mặt trước</label>
                            <UploadPicture_cccd_mt sendMediaId_mt_to_father={receive_mediaID_mt_fromChild}/>
                            <span className={clsx(styles.thirdCol)}>
                                {selectedFile_matTruoc && (
                                    <img
                                    // src={URL.createObjectURL(selectedFile_matTruoc)}
                                    src={URL.createObjectURL(selectedFile_matTruoc)}
                                    alt="Selected"
                                    style={{ maxWidth: '80%', maxHeight: '200px' }}
                                    />
                                )}
                            </span>
                        </div>
                        <div className={clsx(styles.rowReport)}>
                            <label></label>
                            <p className={clsx(styles.report)}>
                                {
                                    agentSignUpStore.objSignUp_error.cccd_mt_mediaId !== "ok" &&
                                    agentSignUpStore.objSignUp_error.cccd_mt_mediaId
                                }
                            </p>
                        </div>

                        <div className={clsx(styles.row)}>
                            <label>CCCD mặt sau</label>
                            <UploadPicture_cccd_ms sendMediaId_ms_to_father={receive_mediaID_ms_fromChild}/>
                            <span className={clsx(styles.thirdCol)}>
                                {selectedFile_matSau && (
                                    <img
                                    // src={URL.createObjectURL(selectedFile_matTruoc)}
                                    src={URL.createObjectURL(selectedFile_matSau)}
                                    alt="Selected"
                                    style={{ maxWidth: '80%', maxHeight: '200px' }}
                                    />
                                )}
                            </span>
                        </div>
                        <div className={clsx(styles.rowReport)}>
                            <label></label>
                            <p className={clsx(styles.report)}>
                                {
                                    agentSignUpStore.objSignUp_error.cccd_ms_mediaId !== "ok" &&
                                    agentSignUpStore.objSignUp_error.cccd_ms_mediaId
                                }
                            </p>
                        </div> */}
                        </div>

                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className={clsx(styles.modal)}
                    >
                        <Box sx={style} className={clsx(styles.buttonWrapper)}>
                            <button onClick={goToAppleStore} style={appleButtonStyles}>Go to Apple store</button>
                            <button onClick={goToGoogleStore} style={googleButtonStyles}>Go to Google Play store</button>
                            <button onClick={goToSignInPage} style={signinButtonStyles}>Go to Sign-in page</button>
                        </Box>
                    </Modal>
                </div>
            )
        } else {
            return <div>Loading</div>
        }
    })

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    boxShadow: '24px 24px 48px rgba(180, 180, 180, 0.7)', // Use a valid boxShadow string
    padding: '20px', // `p` typically refers to padding
    // border: '1px solid blue',
    borderRadius: '1rem',
};

const appleButtonStyles = {
    backgroundColor: '#0d6efd',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
};

const googleButtonStyles = {
    backgroundColor: 'rgb(87, 185, 63)',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '20px 0'
};

const signinButtonStyles = {
    backgroundColor: '#ffc107',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
};