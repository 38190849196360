import { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './hr.module.scss'
import collectionAPI from '../../../API/collectionAPI';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import SearchIcon from '@mui/icons-material/Search';
import {
   retrieveObjectFromLocalStorage,
   KetQuaCuoiCung,
   addLeadingZero,
   extractTimeFromDateString,
} from '../../share/sharedFunction';

function HRGuiMail() {
   const [stateArrDepartments, setStateArrDepartments] = useState<any[]>([]);
   const [stateArrEmployees, setStateArrEmployees] = useState<any[]>([]);
   const [stateArrContactsFinal, setStateArrContactsFinal] = useState<any[]>([]);
   const [stateArrEmployeesOfSelectedDept, setStateArrEmployeesOfSelectedDept] = useState<any[]>([]);
   const findAllDepartments = async () => {
      const response: any = await collectionAPI.collectionAPI.findAllDepartments(); //phải có await nghĩa là khi nào có data rồi thì mới lấy
      // console.log('response.data: ', response.data);
      setStateArrDepartments(response.data)
   }
   
   const findAllEmployees = async () => {
      const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
      const token = userExist.token;
      if(userExist !== null){
         try {
            let response = await collectionAPI.collectionAPI.findAllEmployees(token);
            // console.log('response.data: ', response.data);
            setStateArrEmployees(response.data);
            // ban đầu cho chọn phòng ban tất cả làm mặc định
            setStateArrEmployeesOfSelectedDept(response.data);
            setStateArrContactsFinal(response.data); 
            setSelectedGroup(100);
         } catch (err) {
            // console.log('err:', err);
         }
      }

   }
   useEffect(() => {
      findAllDepartments();
      findAllEmployees();
   },[]);

   const [selectedGroup, setSelectedGroup] = useState<number>();
   

   const selectGroup = (id: number) => {
      if(id !== 100){
         let groupContacts = stateArrEmployees.filter(obj => obj.departmentId === id); //trả ra mảng chứa các obj thoả điều kiện
         setStateArrEmployeesOfSelectedDept(groupContacts);
         // tại sao phải dùng thằng này ? vì thằng trên để lưu lại danh sách group nhân viên đc click vào, và giữ tạm ở đó
         // còn thằng ở dưới là hiện ra màn hình
         setStateArrContactsFinal(groupContacts); 
      } else {
         setStateArrEmployeesOfSelectedDept(stateArrEmployees);
         // tại sao phải dùng thằng này ? vì thằng trên để lưu lại danh sách group nhân viên đc click vào, và giữ tạm ở đó
         // còn thằng ở dưới là hiện ra màn hình
         setStateArrContactsFinal(stateArrEmployees); 
      }

      setSelectedGroup(id);
      setStateBoolSelectAll(false); //reset lại nút chọn tất cả về false để ko chọn
   }
   const [searchTerm, setSearchTerm] = useState('');

   const handleInputChange = (event: any) => {
      setSearchTerm(event.target.value);
      // Update the searchTerm state with the user input
      let textInput = event.target.value;
      let searchedContacts = stateArrEmployeesOfSelectedDept.filter(obj =>obj.fullName.toLowerCase().includes(textInput.toLowerCase()));
      setStateArrContactsFinal(searchedContacts);
   };

   const [contactsChecked, setArrContactsChecked] = useState<any[]>([]);

   const handleArrContactsCheck = (email:any) => {
         
      // setArrSkillChecked([id]); //viết như vầy thì nó chỉ lưu đc 1 cái id, mà ko lưu được cái trước đó đã nhấn => xuất hiện cách ở dưới để giải quyết
      // setArrSkillChecked(prev => [...prev, id]); //đẩy vào mảng state, ở đay state arrSkillChecked là dạng mảng, prev tượng trưng cho phần tử trước đó, ghi như vầy thì nó sẽ lưu được cả phần tử trước đó, tuy nhiên ta muốn viết hàm có điều kiện nữa thì => xuất hiện cách viết dưới đây

      setArrContactsChecked((prev: any[]) => {
         const isChecked = contactsChecked.includes(email); //nếu mảng arrSkillChecked có chứa tham số truyền vào id thì là true và gán cho hằng isChecked

         if (isChecked) { //nếu isChecked === true thì...
         
            return contactsChecked.filter(item => item !== email); //dùng js thuần
         
         } else {
            return [...prev, email]
         }
      })
   }// end handleArrContactsCheck
   const [stateBoolSelectAll, setStateBoolSelectAll] = useState(false); // state này dùng để biết cái ô Chọn tất cả là checked hay ko checked
   const selectAll = () => {
      // console.log("test");
      const uniqueEmails: string[] = [];
   
      setStateBoolSelectAll(!stateBoolSelectAll);
      if(!stateBoolSelectAll){ // vì state nó trễ 1 nhịp, phải thoát ra ngoài hàm thì nó mới update, nên mình chọn if là false, vì ra ngoài nó là true

         stateArrContactsFinal.forEach((obj) => {
            if (!uniqueEmails.includes(obj.email)) {
               uniqueEmails.push(obj.email);
            }
         });
         setArrContactsChecked(uniqueEmails); // cái này nó sẽ set toàn bộ danh sách thành tick vào
      }
      else {
         setArrContactsChecked([]); // trường hợp untick Chọn tất cả, thì nó cho state này về rỗng và các tên trong danh sách cũng untick
      }
   
   }
   interface Employee {
      id: string;
      nameChamCong: string;
      fullName: string;
      email: string;
      departmentId: number;
      department: Department;
   }
   interface Department {
      id: number;
      name: string;
   }
   const [stateObjStaff, setStateObjStaff] = useState<Employee>({
      id: '',
      nameChamCong: '',
      fullName: '',
      email: '',
      departmentId: 0,
      department: { id: 0, name: '' },
   });
   const selectStaff = (id: string) => {
   
      const obj = stateArrContactsFinal.find((obj) => obj.id === id); //lấy một object đầu tiên được tìm thấy mà thoả điều kiện
      setStateObjStaff(obj);
      // console.log('stateNumYear: ', stateNumYear);
      if(stateNumYear !==""){

         showResult2(stateNumYear, stateNumMonth, obj);
      }
      
   }
   const [selectReport, setSelectReport] = useState("");

   const [stateArrGroupByEmail, setStateArrGroupByEmail] = useState<any[]>(); //typescript array state
   interface ArrGroupByEmail {
      allDate: string[];
      checkIn: string[];
      checkOut: string[];
      email: string;
      fullName: string;
      nameChamCong: string;
      result: string[];
   }
   
    // Example usage in a React component
   const [stateObjFullEmployee, setStateObjFullEmployee] = useState<ArrGroupByEmail>({
      allDate: [],
      checkIn: [],
      checkOut: [],
      email: '',
      fullName: '',
      nameChamCong: '',
      result: [],
   });
   const [stateArrAllDate, setStateArrAllDate] = useState<any[]>([]);
   const [stateArrCheckIn, setStateArrCheckIn] = useState<any[]>([]);
   const [stateArrCheckOut, setStateArrCheckOut] = useState<any[]>([]);
   const [stateArrResult, setStateArrResult] = useState<any[]>([]);
   const [stateNumMonth, setStateNumMonth] = useState<number | "">("");
   const [stateNumYear, setStateNumYear] = useState<number | "">("");

   const handleChangeFrom = async (event:any) => { 
      setSelectReport(event.target.value);
      const [monthStr, yearStr] = event.target.value.split('-');
      const month = parseInt(monthStr, 10);
      const year = parseInt(yearStr, 10);
      setStateNumMonth(month);
      setStateNumYear(year);
      showResult1(year, month);
   };  

   const showResult1 = async (year: any, month: any) => {
   
      const response: any = await collectionAPI.collectionAPI.MergeRecordsAndRequestsByYearMonth(year, month); //phải có await nghĩa là khi nào có data rồi thì mới lấy
      const arr = response.data;
      // console.log('arr: ', arr);
      setStateArrGroupByEmail(arr);
      // console.log('stateObjStaff: ', stateObjStaff);
      if(stateObjStaff.email === ""){
         alert("Bạn vui lòng chọn một nhân viên bất kì để hiện nội dung vào email");
         return;
      }
      const obj = arr.find((obj:any) => obj.email === stateObjStaff.email); //lấy một object đầu tiên được tìm thấy mà thoả điều kiện
      if(obj !== null){
         
         setStateArrAllDate(obj.allDate);
         setStateArrCheckIn(obj.checkIn);
         setStateArrCheckOut(obj.checkOut);
         setStateArrResult(obj.result);
         setStateObjFullEmployee(obj);
      }
   }
   const [stateObjResult, setStateObjResult] = useState({});
   const showResult2 = async (year: any, month: any, staff:any) => {
      // console.log("test");
      // console.log('staff: ', staff);
      if(staff.email === ""){
         alert("Bạn vui lòng chọn một nhân viên bất kì để hiện nội dung vào email");
         return;
      }
      // console.log('stateArrGroupByEmail: ', stateArrGroupByEmail);
      const obj = stateArrGroupByEmail!.find((obj:any) => obj.email === staff.email); //lấy một object đầu tiên được tìm thấy mà thoả điều kiện
      // console.log('obj: ', obj);
      const groupedCounts = obj.result.reduce((acc:any, current:any) => {
         acc[current] = (acc[current] || 0) + 1;
         return acc;
      }, {});
      setStateObjResult(groupedCounts);

      // console.log('groupedCounts: ', groupedCounts);
      if(obj !== undefined){
         setStateArrAllDate(obj.allDate);
         setStateArrCheckIn(obj.checkIn);
         setStateArrCheckOut(obj.checkOut);
         setStateArrResult(obj.result);
         setStateObjFullEmployee(obj);
      }else {
         alert(`${staff.fullName} chưa có trong hệ thống chấm vân tay / báo cáo tháng 11`);
      }
   
   }

   const [stateArrFilteredEmailsNotExist, setStateArrFilteredEmailsNotExist] = useState<any[]>([]);
   const [stateArrFilteredEmailsExist, setStateArrFilteredEmailsExist] = useState<any[]>([]);
   const sendMulti = () => {
      // console.log('stateArrGroupByEmail: ', stateArrGroupByEmail);
      // console.log('contactsChecked: ', contactsChecked);
      if(stateArrGroupByEmail === undefined || contactsChecked.length === 0){
         alert("Bạn vui lòng chọn loại báo cáo và các email cần gửi đến");
         return;
      }
      else{
         const userConfirmed = window.confirm("Bạn có chắc chắn muốn gửi báo cáo đến những người này không ?");
         
         if (userConfirmed) {
           // User clicked "OK" or "Yes"
            // console.log('contactsChecked: ', contactsChecked);
            const filteredEmailsNotExist = contactsChecked.filter(emailB => !stateArrGroupByEmail.map(objA => objA.email).includes(emailB));
            const filteredEmailsExist = contactsChecked.filter(emailB => stateArrGroupByEmail.map(objA => objA.email).includes(emailB));
            setStateArrFilteredEmailsNotExist(filteredEmailsNotExist);
            setStateArrFilteredEmailsExist(filteredEmailsExist);
            setStateIsDialog1(!stateIsDialog1);

            // console.log("email ko có trong báo cáo: ",filteredEmailsNotExist);
            // console.log("email có trong báo cáo: ",filteredEmailsExist);
            // if(filteredEmails.length !== 0){
            //    alert("email ko có trong báo cáo:"+ `${filteredEmails}`);
            // }
         } else {
           // User clicked "Cancel" or "No"
         }
      }
   
   }

      const [stateIsDialog1, setStateIsDialog1] = useState(false);
      
      const onCancelDialog = () => {
         setStateIsDialog1(!stateIsDialog1);
      }
   
      const confirm = async () => {
         let obj = {
            emailList: stateArrFilteredEmailsExist,
            subject: ` Báo cáo chấm công tháng ${stateNumMonth}-${stateNumYear}`,
            year: stateNumYear,
            month: stateNumMonth
         }
         // console.log('obj: ', obj);
         let response = await collectionAPI.collectionAPI.sendMultiEmail(obj);
         // console.log('response: ', response);
         if(response.data === "Emails sent successfully"){
            alert("Emails sent successfully");
            onCancelDialog();
         }
      }
   return (
      <div className={clsx(styles.component_HRGuiMail)}>
         <h1>Gửi mail</h1>
         <div className={clsx(styles.contactsWrapper)}>
            <div className={clsx(styles.groups)}>
               <h3>Phòng ban</h3>
               <div className={clsx(styles.oneGroup, {
                        [styles.selectedGroup]: 100 === selectedGroup,
                        })} onClick={()=>selectGroup(100)}>
                           <Diversity1Icon className={clsx(styles.icon, {
                        [styles.selectedGroup]: 100 === selectedGroup,
                        })}/>
                  <p className={clsx(styles.groupName)}>Tất cả</p>
               </div>
               {
                  stateArrDepartments.map((obj, index) => (
                     <div
                        className={clsx(styles.oneGroup, {
                        [styles.selectedGroup]: obj.id === selectedGroup,
                        })}
                        key={obj.id}
                        onClick={() => selectGroup(obj.id)}
                     >
                        <PeopleOutlineIcon className={clsx(styles.icon, {
                        [styles.selectedGroup]: obj.id === selectedGroup,
                        })} />

                        <p className={clsx(styles.groupName)}>{obj.name}</p>
                     </div>
                  ))
               }
            </div>
            <div className={clsx(styles.contacts)}>
               <div className={clsx(styles.searchWrapper)}>
                  <div className={clsx(styles.searchGroup)}>
                     <SearchIcon className={clsx(styles.iconSearch)}/>
                     <input
                     type="text"
                     placeholder="Tìm tên..."
                     value={searchTerm}
                     onChange={handleInputChange}
                     />
                  </div>
               </div>
               <div className={clsx(styles.listContacts)}>
                  <div className={clsx(styles.contactRow, styles.selectAll)}>
                     <input
                        type="checkbox"
                        onChange={() => selectAll()}
                        checked={stateBoolSelectAll} // set điều kiện state arrSkillChecked phải chứa object.id thì mới cho nút này arrSkillChecked hiện lên
                     />
                     <p className={clsx(styles.contactName)} >Chọn tất cả</p>
                  </div>
                  {
                     stateArrContactsFinal.map((obj, index) => {
                        return ( 
                           <div className={clsx(styles.contactRow)} key={obj.id}>
                              <input
                                 type="checkbox"
                                 onChange={() => handleArrContactsCheck(obj.email)}
                                 checked={contactsChecked.includes(obj.email)} // set điều kiện state arrSkillChecked phải chứa object.id thì mới cho nút này arrSkillChecked hiện lên
                              />
                              <p className={clsx(styles.contactName)} onClick={()=>selectStaff(obj.id)}>{obj.fullName}</p>
                           </div>
                        )
                     })
                  }
               </div>
            </div>
            <div className={clsx(styles.mailContent)}>
               
               <div className={clsx(styles.reportType)}>
                  <p className={clsx(styles.key)}>Chọn báo cáo để gửi</p>
                  <select name="from" onChange={handleChangeFrom}>
                     <option value="">Chọn ...</option>
                     <option value="11-2023">11-2023</option>
                     <option value="12-2023">12-2023</option>
                     <option value="01-2024">01-2024</option>
                  </select>
                  <button className={clsx(styles.btnSend)} onClick={sendMulti}>Send</button>
               </div>
               <div className={clsx(styles.row)}>
                  <p className={clsx(styles.key)}>Kính gửi: Mr/Ms. &nbsp;</p>
                  <p className={clsx(styles.value)}>{stateObjStaff.fullName}</p>
               </div>
               <div className={clsx(styles.row)}>
                  <p className={clsx(styles.key)}>Phòng Nhân sự của Công ty Cổ phần Charm Services trân trọng gửi CBNV, &nbsp;</p>
                  <p className={clsx(styles.value)}></p>
               </div>

               <h2 className={clsx(styles.title)}>BÁO CÁO CHẤM CÔNG</h2>
               <h4>Tháng {selectReport}</h4>
               <table>
                  <thead>
                     <tr>
                        <th>STT</th>
                        <th>Ngày</th>
                        <th>Check In</th>
                        <th>Check Out</th>
                        <th>Kết Quả</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        stateArrAllDate.map((obj:any, index:any) => {
                           const checkIn = extractTimeFromDateString(stateArrCheckIn[index])
                           const checkOut = extractTimeFromDateString(stateArrCheckOut[index])
                           const checkInHour = checkIn.hoursNumber;
                           const checkInMinute = checkIn.minutesNumber;
                           const checkOutHour = checkOut.hoursNumber;
                           const checkOutMinute = checkOut.minutesNumber;
                           const checkInHourFull = addLeadingZero(checkInHour);
                           const checkInMinuteFull = addLeadingZero(checkInMinute);
                           const checkOutHourFull = addLeadingZero(checkOutHour);
                           const checkOutMinuteFull = addLeadingZero(checkOutMinute);
                           const result = KetQuaCuoiCung(stateArrResult[index]);
                           return ( 
                              <tr key={obj}>
                                 <td>{index+1}</td>
                                 <td>{stateArrAllDate[index]}</td>
                                 <td>{checkInHourFull}:{checkInMinuteFull}</td>
                                 <td>{checkOutHourFull}:{checkOutMinuteFull}</td>
                                 <td>{result}</td>
                              </tr>
                           )
                        })
                     }
                  </tbody>
               </table>
               <p className={clsx(styles.key)}>Tổng hợp: &nbsp;</p>

               {Object.entries(stateObjResult).map(([key, value]) => (
                  <div key={`${key}-${value}`} className={clsx(styles.row)}>
                     <p className={clsx(styles.key)}>{KetQuaCuoiCung(key)}: &nbsp;</p>
                     <p className={clsx(styles.value)}>{String(value)}</p>
                  </div>
               ))}
               <p className={clsx(styles.note)}>CBNV lưu ý: Anh/Chị vui lòng kiểm tra toàn bộ thông tin trên và phản hồi (nếu có).</p>
               <p className={clsx(styles.note)}>Trân trọng cảm ơn!</p>
               <p className={clsx(styles.note)}>Phòng Nhân sự,</p>
               <p className={clsx(styles.note)}>CÔNG TY CỔ PHẦN CHARM SERVICES</p>
               <p className={clsx(styles.note)}>[Mail] vy.ntt@charm.contact</p>
               <p className={clsx(styles.note)}>[zalo] 0339580562</p>
            </div>
               {/* mailContent end*/}
         </div>
         {/* <div className={clsx(styles.backShadow,{[styles.appear]: stateIsDialog1})} onClick={onCancelDialog}> */}
         <div className={clsx(styles.backShadow,{[styles.appear]: stateIsDialog1})}>
         </div>
         <div className={clsx(styles.dialog1, {[styles.appear]: stateIsDialog1})}>
            <div className={clsx(styles.btnWrapper)}>
               <button className={clsx(styles.btn, styles.confirm)} onClick={confirm}>confirm</button>
               <button className={clsx(styles.btn, styles.cancel)} onClick={onCancelDialog}>cancel</button>
            </div>
            <p className={clsx(styles.key)}>Những email có báo cáo: </p>
            <div className={clsx(styles.emails)}>
               
               {
                  stateArrFilteredEmailsExist.map((obj, index) => {
                     return ( 
                        <p key={obj} className={clsx(styles.name)}>{obj}</p>
                     )
                  })
               }
            </div>
               
            <p className={clsx(styles.key)}>Những email KHÔNG có báo cáo, nên sẽ không được gửi đi: </p>
            <div className={clsx(styles.emails)}>
               
               {
                  stateArrFilteredEmailsNotExist.map((obj, index) => {
                     return ( 
                        <p key={obj} className={clsx(styles.name)}>{obj}</p>
                     )
                  })
               }
            </div>
         </div>
      </div>
   )
}
export default HRGuiMail