// recft_clsx_module_scss_input_onchange
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, Outlet, useNavigate, } from "react-router-dom"
import styles from './main.module.scss'     //file css cùng cấp
import HRHome from './hr/HRHome';
import HRUpload from './hr/HRUpload';
import HRBaocao from './hr/HRBaocao';
import HRGuiMail from './hr/HRGuiMail';
import CskhHome from './cskh/CskhHome';
import CskhThongTin from './cskh/CskhThongTin';
import Changepassword from './general/Changepassword';
import RutdaoHome from './rutdao/RutdaoHome';
import RutdaoKhachhang from './rutdao/RutdaoKhachhang';
import Giaodich from './rutdao/Giaodich';
import Quy from './rutdao/Quy';
import ThuChi from './rutdao/ThuChi';
import Saoke from './rutdao/Saoke';
import KetoanHome from './ketoan/KetoanHome';
import KetoanTonghop from './ketoan/KetoanTonghop';
import TheTinDung from './rutdao/TheTinDung';
import QuanlyHome from './quanly/QuanlyHome';
import Capnhat from './quanly/Capnhat';
import Caidat from './quanly/Caidat';
import AgentHome from './agent/outside/AgentHome';
import BgdHome from './bgd/BgdHome';
import { AgentSignUp } from './agent/sign/AgentSignUp';
import { AgentSignIn } from './agent/sign/AgentSignin';
import AgentKhachhang from './agent/outside/khachhang/AgentKhachhang';
import { AgentLink } from './agent/outside/link/AgentLink';
import { AgentTree } from './agent/inside/AgentTree';
import { AgentTreeOutside } from './agent/outside/hoivien/AgentTreeOutside';
import DeleteAccount from './general/DeleteAccount';
import HoaDonHome from './hoadon/HoaDonHome';
import { HoaDon } from './hoadon/HoaDon';
import { Sidebar } from '../share/Sidebar';
import { SignIn } from './general/Signin';
import { MenuBar } from '../share/MenuBar';
import { Home } from './general/Home';

function MainView() {
    const location = useLocation();
    
    // Check if the path starts with '/agent/'
    const shouldShowMenuBar = !location.pathname.startsWith('/agent/');
    const deleteaccount = !location.pathname.includes('/deleteaccount');
    return (
        <div className={clsx(styles.component_MainView)}>
            {/* chỉ hiện MenuBar khi ko phải là path agent và delete account */}
            <span className={clsx(styles.menuBar)}>
                {shouldShowMenuBar && deleteaccount && <MenuBar />}
            </span>
            <span className={clsx(styles.sideBar)}>
                <Sidebar/>
            </span>
            <Routes> 
                <Route path='' element={<SignIn/> }/>
                <Route path='/' element={<SignIn/> }/>
                <Route path='/signin' element={<SignIn/> }/>
                <Route path='/deleteaccount' element={<DeleteAccount/> }/>
                <Route path='/changepassword' element={<Changepassword/> }/>
                <Route path='/home' element={<Home/> }/>

                <Route path='/agent/' element={<AgentHome/> }>
                    <Route path='signup/*' element={<AgentSignUp/> } />
                    <Route path='signin' element={<AgentSignIn/> } />
                    <Route path='home' element={<AgentHome/> } />
                    <Route path='khachhang' element={<AgentKhachhang/> } />
                    <Route path='Link' element={<AgentLink/> } />
                    <Route path='hoivien' element={<AgentTreeOutside/> } />
                    <Route path='agentManagement' element={<AgentTree/> } />
                </Route> 
                <Route path='/bgd/' element={<BgdHome/> }>
                    <Route path='' element={<KetoanTonghop/> } />
                    <Route path='tonghop' element={<KetoanTonghop/> } />
                </Route>
                <Route path='/cskh/' element={<CskhHome/> }>
                    <Route path='' element={<CskhThongTin/> } />
                    <Route path='nhapthongtin' element={<CskhThongTin/> } />
                </Route>
                <Route path='/finance/' element={<RutdaoHome/> }>
                    <Route path='' element={<RutdaoKhachhang/> } />
                    <Route path='khachhang' element={<RutdaoKhachhang/> } />
                    <Route path='giaodich' element={<Giaodich/> } />
                    <Route path='thetindung' element={<TheTinDung/> } />
                    <Route path='quy' element={<Quy/> } />
                    <Route path='thuchi' element={<ThuChi/> } />
                    <Route path='saoke' element={<Saoke/> } />
                </Route>
                <Route path='/ketoan/' element={<KetoanHome/> }>
                    <Route path='' element={<KetoanTonghop/> } />
                    <Route path='tonghop' element={<KetoanTonghop/> } />
                    <Route path='quy' element={<Quy/> } />
                    <Route path='thuchi' element={<ThuChi/> } />
                    <Route path='saoke' element={<Saoke/> } />
                
                </Route>
                <Route path='/quanly/' element={<QuanlyHome/> }>
                    <Route path='' element={<Capnhat/> } />
                    <Route path='capnhat' element={<Capnhat/> } />
                    <Route path='caidat' element={<Caidat/> } />
                </Route>
                <Route path='/hoadon/' element={<HoaDonHome/> }>
                    <Route path='' element={<HoaDon/> } />
                    <Route path='hoadonbanle' element={<HoaDon/> } />
                </Route>
                {/* <Route path='' element={<HRHome/> } /> */}
                <Route path="/hr/" element={<HRHome/>}>
                    {/* path='' bỏ trống là vì mình muốn mặc định ngay ban đầu là nó vào component này */}
                    <Route path='' element={<HRBaocao/> } />
                    <Route path='upload' element={<HRUpload/> } />
                    <Route path='baocao' element={<HRBaocao/> } />
                    <Route path='guimail' element={<HRGuiMail/> } />
                </Route>
                {/* lưu ý ở component EmployerHome muốn hiện ra các component con EmployerIntro, PostJob... chỗ nào thì chỗ đó phải khai báo <Outlet /> */}
            </Routes>
            {/* <Footer/> */}
        </div>
    )
}
export default MainView