export function numberToVietnameseWords(number) {
  const units = ["", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];
  const teens = ["mười", "mười một", "mười hai", "mười ba", "mười bốn", "mười lăm", "mười sáu", "mười bảy", "mười tám", "mười chín"];
  const tens = ["", "", "hai mươi", "ba mươi", "bốn mươi", "năm mươi", "sáu mươi", "bảy mươi", "tám mươi", "chín mươi"];
  const thousands = ["", "ngàn", "triệu", "tỷ"];

  // Remove commas and convert to number
  let num = parseInt(number.replace(/,/g, ''), 10);

  if (isNaN(num)) {
      return "Invalid number";
  }

  // Function to convert a 3-digit number to words
  function threeDigitToWords(num, isFinalGroup) {
      const hundred = Math.floor(num / 100);
      const ten = Math.floor((num % 100) / 10);
      const unit = num % 10;

      let result = "";
      if (hundred > 0) {
          result += units[hundred] + " trăm";
          if (ten === 0 && unit !== 0) {
              result += " lẻ " + (unit === 5 ? "năm" : units[unit]);
          }
      }
      if (ten > 1) {
          result += " " + tens[ten];
          if (unit > 0) {
              result += " " + (unit === 5 ? (isFinalGroup ? "lăm" : "năm") : units[unit]);
          }
      } else if (ten === 1) {
          result += " " + teens[unit];
      } else if (ten === 0 && unit !== 0) {
          if (hundred > 0) {
              result += " lẻ " + (unit === 5 ? (isFinalGroup ? "lăm" : "năm") : units[unit]);
          } else {
              result += (unit === 5 ? (isFinalGroup ? "lăm" : "năm") : units[unit]);
          }
      }
      return result.trim();
  }

  // Split number into groups of thousands
  let words = [];
  let i = 0;

  while (num > 0) {
      const segment = num % 1000;
      if (segment > 0) {
          const isFinalGroup = (i === 0);
          const segmentWords = threeDigitToWords(segment, isFinalGroup) + " " + thousands[i];
          words.unshift(segmentWords.trim());
      }
      num = Math.floor(num / 1000);
      i++;
  }

  // Join all words
  let finalWords = words.join(' ');

  // Insert commas at appropriate positions
  const parts = finalWords.split(' ');
  let commaWords = [];
  let groupIndex = 0;
  let groupWords = [];

  for (let j = 0; j < parts.length; j++) {
      groupWords.push(parts[j]);
      if (thousands.includes(parts[j])) {
          commaWords.push(groupWords.join(' '));
          groupWords = [];
          groupIndex++;
      }
  }

  if (groupWords.length > 0) {
      commaWords.push(groupWords.join(' '));
  }

  let result = commaWords.join(', ').replace(/\s+/g, ' ').replace(/mươi năm/g, 'mươi lăm').replace(/mươi một\b/g, 'mươi mốt').trim();

  // Capitalize the first letter
  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
}
export const getRandomNumberInRange = (from, to) => {
  if (from >= to) {
      throw new Error("Invalid range: 'from' must be less than 'to'");
  }

  const randomNumber = Math.random(); // Random number between 0 (inclusive) and 1 (exclusive)
  const scaledNumber = Math.floor(randomNumber * (to - from + 1)) + from; // Scale to desired range and floor

  return scaledNumber;
};

export function checkPermission(email, arr) {

  let found = false;
  for (let obj of arr) {
      if (obj.email === email) {
          found = true;
          break;
      }
  }
  if(found === false){
    alert("Bạn không có quyền xóa, vui lòng liên hệ với ban quản trị để xóa !");
  }
  return found;
}
export function removeDuplicateObjects(array) {
  // Use a Set to keep track of unique "soThe" values
  let uniqueSoTheSet = new Set();

  // Use filter to keep only the objects with unique "soThe" values
  return array.filter(obj => {
    if(obj.soThe.toLowerCase().includes('x')){ // nếu chứa ký tự X thì bỏ, vì thẻ này vì lý do gì đó chưa đc giải mã
      return false;
    }

    if (!uniqueSoTheSet.has(obj.soThe)) {
      // If the "soThe" value is not in the set, add it and return true to keep this object
      uniqueSoTheSet.add(obj.soThe);
      return true;
    }
    // If the "soThe" value is already in the set, return false to filter out this object
    return false;
  });
}

export function convertDateFormat_removeYear(inputDateString) {
  // Extract the month, day, hours, and minutes
  const [datePart, timePart] = inputDateString.split('T');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split('.')[0].split(':');

  // Construct the new date string in the format "mm-dd hh:mm"
  const newDateString = `${month}-${day} ${hours}:${minutes}`;
  return newDateString;
}
export function convertDateFormat_removeYearHourMinute(inputDateString) {
  // Extract the month, day, hours, and minutes
  const [datePart, timePart] = inputDateString.split('T');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split('.')[0].split(':');

  // Construct the new date string in the format "mm-dd hh:mm"
  const newDateString = `${day}-${month}`;
  return newDateString;
}


export function formatCreditCardXXXX(originalString) {
  // Extract the first 4 characters
  const firstFour = originalString.substring(0, 4);

  // Extract the last 4 characters
  const lastFour = originalString.substring(originalString.length - 4);

  // Create the middle part
  const middlePart = "XXXX XXXX";

  // Concatenate the parts
  const formattedString = `${firstFour} ${middlePart} ${lastFour}`;

  return formattedString;
}

export function replaceFirstFourLetters(inputString) {
  if (typeof inputString !== 'string') {
    throw new Error('Input must be a string');
  }

  if (inputString.length < 4) {
    return inputString;
  }

  // Replace the first five letters with "XXXXX"
  return 'XXXX' + inputString.slice(4);
}

export function convertToTitleCase(inputString) {
  // Split the input string into an array of words
  let words = inputString.toLowerCase().split(' ');
  
  // Iterate through each word in the array
  for (let i = 0; i < words.length; i++) {
      // Capitalize the first letter of each word
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  
  // Join the words back into a single string and return
  return words.join(' ');
}

export function parseBool(str) { // ép kiểu boolean
  return /^(true|1)$/i.test(str);
}

export function getDayOfWeek(year, month, day) {
  year = parseInt(year+'');
  month = parseInt(month+'');
  day = parseInt(day+'');

  const date = new Date(year, month - 1, day); // Month is 0-based in JavaScript
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const dayIndex = date.getDay(); // Returns a number representing the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  return daysOfWeek[dayIndex];
}

export function getDaysInMonth(year, month) {
  year = parseInt(year+'');
  month = parseInt(month+'');
  // Months are 0-indexed in JavaScript, so subtract 1 from the input month
  const lastDayOfMonth = new Date(year, month, 0).getDate();
  return lastDayOfMonth;
}

export function convertDateFormat4(dateString) { // from dd/mm/yyyy to mm-dd-yyyy
  // Split the date string by "/"
  var parts = dateString.split('/');
  
  // Rearrange the parts to form mm-dd-yyyy format
  var mm = parts[1];
  var dd = parts[0];
  var yyyy = parts[2];
  
  // Return the converted date in mm-dd-yyyy format
  return mm + '-' + dd + '-' + yyyy;
}

export function convertDateFormat3(dateString) { // "dd/mm/yyyy" to "yyyy-mm-ddT00:00:00Z"
  const parts = dateString.split('/');
  if (parts.length !== 3) {
      throw new Error('Invalid date format. Must be in dd/mm/yyyy format.');
  }

  const [day, month, year] = parts.map(Number);
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
      throw new Error('Invalid date format. Must be in dd/mm/yyyy format.');
  }

  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T00:00:00Z`;
}

export function convertDateFormat2(inputDate) { // "yyyy-mm-dd" to "yyyy-dd-mm"
  // Split the input date string by '-'
  const parts = inputDate.split('-');
  
  // Rearrange the parts to change the format from yyyy-mm-dd to yyyy-dd-mm
  const convertedDate = `${parts[0]}-${parts[2]}-${parts[1]}`;
  
  return convertedDate;
}
export function formatDate2(inputDate) { //  input: "yyyy-mm-dd" output format "dd/mm/yyyy"
  // Split the date string by '-' to get individual components
  const dateParts = inputDate.split('-');
  
  // Extract year, month, and day components
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  
  // Format the date as dd/mm/yyyy
  const formattedDate = `${day}/${month}/${year}`;
  
  return formattedDate;
}

export function getNextDay(inputDate) { // input "2024-02-02" => Output: "2024-02-03"
  // Parse the input date string into a Date object
  const date = new Date(inputDate);
  
  // Increment the date by one day
  date.setDate(date.getDate() + 1);
  
  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because January is 0
  const day = String(date.getDate()).padStart(2, '0');
  
  // Format the next day as yyyy-mm-dd
  const nextDay = `${year}-${month}-${day}`;
  
  return nextDay;
}


export function extractHHMM(dateTimeString) { // input 2023-11-02T00:00:00Z ; output: 00:00
  // Parse the input date-time string
  const dateTime = new Date(dateTimeString);

  // Extract hours and minutes
  const hours = dateTime.getHours().toString().padStart(2, '0'); // Ensure two digits, pad with zero if needed
  const minutes = dateTime.getMinutes().toString().padStart(2, '0'); // Ensure two digits, pad with zero if needed

  // Construct the time string (hh:mm)
  const timeString = `${hours}:${minutes}`;

  return timeString;
}

export function convertDateFormat(dateString) { // convert from yyyy-mm-dd to dd-mm-yyyy
  // Create a Date object from the input string
  const date = new Date(dateString);
  
  // Extract day, month, and year components
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const year = date.getFullYear();

  // Format the date as dd-mm-yyyy
  return `${day}-${month}-${year}`;
}

export function isNow24HoursLaterThan(time) {
  const now = new Date(getCurrentDateTimeVietNam());
  const timeDate = new Date(time);

  // Calculate the difference in milliseconds between now and time
  const differenceInMillis = now - timeDate;

  // Convert 24 hours to milliseconds
  const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;

  // Check if the difference is greater than or equal to 24 hours
  return differenceInMillis >= twentyFourHoursInMillis;
  // false thì là giờ hiện tại ko nhiều hơn 24 tiếng so với time
  // true thì là giờ hiện tại nhiều hơn 24 tiếng so với time
}

export function assignColorIndexes(arr) {
  // mu5ch đích là phân loại màu, các dòng có cùng id sẽ có cùng màu
  let arrBinaryDao = [];
  let idOld = arr[0];
  let color = "#ffffff" ;
  for (let idNew of arr) {
      if (idNew === idOld) {
          arrBinaryDao.push(color);
      } else {
          idOld = idNew;
          color = (color === "#ffffff" ) ? "#e7e6e6" : "#ffffff" ;
          arrBinaryDao.push(color);
      }
  }
  return arrBinaryDao;
}
export function assignColorBold(arr) {
  // mục đích là tô đậm cái dòng cuối cùng của group các dòng có id giống nhau
  let arrBinaryBold = [];
  let idOld = arr[0];
  for (let idNew of arr) {
      if (idNew === idOld) {
          arrBinaryBold.push("inherit");
      } else {
          idOld = idNew;
          arrBinaryBold.pop(); // bỏ phần tử cuối cùng
          arrBinaryBold.push("#f5f3a0");
          arrBinaryBold.push("inherit");

      }
  }
  return arrBinaryBold;
}

export function moveElementToTopOfAnArray(array, element) {
  if (!Array.isArray(array)) throw new Error('Input is not an array');

  const currentIndex = array.indexOf(element);
  
  // If the element is not found or is already at the top, return the original array
  if (currentIndex === -1 || currentIndex === 0) {
    return array;
  }

  // Remove the element from its current position
  array.splice(currentIndex, 1);
  
  // Insert the element at the top of the array
  array.unshift(element);

  return array;
}

export function removeDecimalAndRound(number) {
  if (typeof number !== 'number') {
      return null; // Return null for non-numeric input
  }

  return Math.round(number);
}

export function convertToISOString(inputString) {
  // Define the regular expression pattern for the format "YYYY-MM-DD HH:MM"
  const pattern = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

  // Test the input string against the pattern
  const match = inputString.match(pattern);

  if (!match) {
      // The input string does not match the expected format
      return null;
  }

  // Extract components from the matched groups
  const year = parseInt(match[1], 10);
  const month = parseInt(match[2], 10) - 1; // Month is zero-indexed
  const day = parseInt(match[3], 10);
  const hour = parseInt(match[4], 10);
  const minute = parseInt(match[5], 10);

  // Create a Date object using the extracted components
  const dateObject = new Date(year, month, day, hour, minute);

  // Convert the Date object to ISO string format
  const isoString = dateObject.toISOString();

  return isoString;
}

export function countOccurrences(arr, target) {
  return arr.filter(item => item === target).length;
}

export function formatCreditCardNumber(inputString) { // input = '1234567812341234' output: 1234 5678 1234 1234
  // Remove any existing spaces
  const cleanedInput = inputString.replace(/\s/g, '');
  // console.log('cleanedInput: ', cleanedInput);
  // Use a regular expression to add a space after every 4 characters
  const formattedString = cleanedInput.replace(/(.{4})/g, '$1 '); // 1 space between
  // const formattedString = cleanedInput.replace(/(.{4})/g, '$1  '); // 2 space between
  // console.log('formattedString: ', formattedString);
  // Remove the trailing space, if any
  return formattedString.trim();
}

export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function setCookie(cookieName, userInfo, expirationHours) {
  const cookieValue = JSON.stringify(userInfo);

  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + expirationHours * 60 * 60 * 1000);

  document.cookie = `${cookieName}=${encodeURIComponent(cookieValue)}; expires=${expirationDate.toUTCString()}; path=/`;
}

// // Example usage:
// const userInfo = {
//   fullName: "Trần Ngọc Tú",
//   email: "tu.tn@charm.contact",
//   role: "BGD"
// };

// setCookie("loginSuccessEmployee", userInfo, 8); /

export function getCookie(cookieName) {
  const cookieString = document.cookie;
  const cookiesArray = cookieString.split(';');

  for (const cookie of cookiesArray) {
    const [name, value] = cookie.trim().split('=');

    if (name === cookieName) {
      const encodedValue = decodeURIComponent(value);
      return JSON.parse(encodedValue); // Use JSON.parse for decoding
    }
  }

  return null; // Cookie not found
}


// Example usage:
// const user1Info = getCookie("loginSuccessEmployee");
// console.log("loginSuccessEmployee", user1Info);

// dateTimeUtils.js

export function storeObjectInSession(sessionName, objectToStore, expirationHours) {
  const expirationMilliseconds = expirationHours * 60 * 60 * 1000; // Convert hours to milliseconds
  const currentTime = new Date().getTime();

  const dataToStore = {
    value: objectToStore,
    expiration: currentTime + expirationMilliseconds,
  };

  sessionStorage.setItem(sessionName, JSON.stringify(dataToStore));
}

export function getObjectFromSession(sessionName) {
  const storedData = sessionStorage.getItem(sessionName);

  if (storedData) {
    const parsedData = JSON.parse(storedData);

    if (parsedData.expiration > new Date().getTime()) {
      return parsedData.value;
    } else {
      // Remove expired data from sessionStorage
      sessionStorage.removeItem(sessionName);
    }
  }

  return null;
}
/*
// Example usage
const myObject = { name: "John", age: 30 };

// Store the object in session for 1 hour
storeObjectInSession("mySession", myObject, 1);

// Retrieve the object from session
const retrievedObject = getObjectFromSession("mySession");

if (retrievedObject) {
  // console.log("Retrieved Object:", retrievedObject);
} else {
  // console.log("Object not found in session or expired.");
}
*/

export function storeObjectInLocalStorage(localStorageName, objectToStore, expirationHours) {
  const expirationMilliseconds = expirationHours * 60 * 60 * 1000; // Convert hours to milliseconds
  const currentTime = new Date().getTime();

  const dataToStore = {
    value: objectToStore,
    expiration: currentTime + expirationMilliseconds,
  };

  localStorage.setItem(localStorageName, JSON.stringify(dataToStore));
  /*cách dùng:
  storeObjectInLocalStorage("loginSuccessEmployee", obj, 8);
  */
}

export function retrieveObjectFromLocalStorage(localStorageName) {
  const storedData = localStorage.getItem(localStorageName);

  if (storedData) {
    const parsedData = JSON.parse(storedData);

    // Check if the data is still valid (not expired)
    const currentTime = new Date().getTime();
    if (currentTime < parsedData.expiration) {
      return parsedData.value;
    } else {
      // Remove the expired data from localStorage
      localStorage.removeItem(localStorageName);
    }
  }

  return null; // Return null if no valid data is found
  /*
  Cách dùng:
  const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
  */
}

export function searchPhone(objectsArray, searchText) {
  const matchingObjects = [];

  // Iterate through each object in the array
  for (let i = 0; i < objectsArray.length; i++) {
    // Check if the "phone" key exists and is an array
    if (objectsArray[i].hasOwnProperty('phone') && Array.isArray(objectsArray[i].phone)) {
      // Iterate through the "phone" array
      for (let j = 0; j < objectsArray[i].phone.length; j++) {
        // Get the current phone type
        const phone = objectsArray[i].phone[j].phone;

        // Check if the search text is found in the current phone type
        if (phone.includes(searchText)) {
          // Add the object to the array of matching objects
          matchingObjects.push(objectsArray[i]);
          // Break the inner loop if a match is found in this object
          break;
        }
      }
    }
  }

  // Return the array of matching objects
  return matchingObjects;
}
export function getCurrentDateTime() { // trả ra 2023-12-23T20:24:40Z (time zone UTC)
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = (now.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = now.getUTCDate().toString().padStart(2, "0");
  const hours = now.getUTCHours().toString().padStart(2, "0");
  const minutes = now.getUTCMinutes().toString().padStart(2, "0");
  const seconds = now.getUTCSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

export function getCurrentDateTimeVietNam() { // trả ra 2023-12-23T20:24:40Z (time zone +7)
  const now = new Date();
  
  // Adjust the time zone offset to +7
  now.setHours(now.getHours() + 7);

  // Format the date and time
  const formattedDateTime = now.toISOString().replace(/\.\d{3}Z$/, 'Z');

  return formattedDateTime;
}

export function formatDate(inputDateString) { // đầu vào 2024-01-19 đầu ra  2023-01-19T00:00:00Z hoặc null
  if(inputDateString !== ""){
    // Create a Date object from the input string
    var inputDate = new Date(inputDateString);
  
    // Format the date in the desired format
    var formattedDate = inputDate.toISOString();
  
    return formattedDate;
  }
  else {
    return null;
  }
}

export function combine_yyyy_mm_dd_hh_mm_plus7(dateString, timeString) {
  // Convert date string to Date object
  const dateObject = new Date(dateString);

  // Extract time components from the time string
  const [hours, minutes] = timeString.split(':');
  let hoursNum = Number(hours) + 7;

  // Set time components to the date object
  dateObject.setHours(parseInt(hoursNum, 10));
  dateObject.setMinutes(parseInt(minutes, 10));

  // Format the date object to the desired output structure
  const formattedDateTime = dateObject.toISOString();

  return formattedDateTime;
}

export function formatDateTime(input) { //input: 2023-11-02T09:30:00Z ; output: 2023-11-02 09:30
    const [datePart, timePart] = input.split('T');
    const formattedOutput = `${datePart} ${timePart.slice(0, 5)}`;
    return formattedOutput;
  }

export function formatDateString(inputDateString) {// output: yyyy-mm-dd hh:mm
  // input: 2023-11-02T00:00:00Z

  if (inputDateString === "" || inputDateString === undefined || inputDateString === null) {
    return null;
  }

  const dateObject = new Date(inputDateString);

  // Add 7 hours to UTC hours
  const adjustedHours = dateObject.getUTCHours() + 7;
  // const adjustedHours = dateObject.getUTCHours(); // ko cộng, vì lúc mình thêm giờ là thêm giờ việt nam

  const year = dateObject.getUTCFullYear();
  const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getUTCDate()).padStart(2, '0');
  const hours = String(adjustedHours).padStart(2, '0');
  const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function extractYYYY_MM_DD(inputDateString) { // output: yyyy-mm-dd
  // input: 2023-11-02T00:00:00Z
  if(inputDateString === "" || inputDateString === null){
    return null;
  }
  
    const dateObject = new Date(inputDateString);
  
    const year = dateObject.getUTCFullYear();
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getUTCDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
}

export function convertDateString1(inputDateString) {// Output: "2023-01-01 08:30"
   // Convert inputDateString to string if it's not already
  if (typeof inputDateString !== 'string') {
    inputDateString = String(inputDateString);
  }
  // Split the input date string into components
  const dateComponents = inputDateString.split(" ");

  // Get month abbreviation and convert it to a numeric month
  const monthAbbreviation = dateComponents[0];
  const month = new Date(`${monthAbbreviation} 1, 2000`).getMonth() + 1;

  // Get day and year components
  const day = parseInt(dateComponents[1], 10);
  const year = parseInt(dateComponents[2], 10);

  // Get AM/PM and set hours and minutes accordingly
  let hours, minutes;
  const amPm = dateComponents[3].toLowerCase();
  if (amPm === "am") {
    hours = 8;
    minutes = 30;
  } else if (amPm === "pm") {
    hours = 12;
    minutes = 0;
  }

  // Format the date components
  const formattedDate = `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(
    -2
  )} ${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}`;

  return formattedDate;
}

export function convertDateString(inputDateString) { // Output: "2023-11-02T00:00:00Z"
  
  // Split the input date string into components
  const dateComponents = inputDateString.split(" ");

  // Get month abbreviation and convert it to a numeric month
  const monthAbbreviation = dateComponents[0];
  const month = new Date(`${monthAbbreviation} 1, 2000`).getMonth() + 1;

  // Get day and year components
  const day = parseInt(dateComponents[1], 10);
  const year = parseInt(dateComponents[2], 10);

  // Get AM/PM and set hours and minutes accordingly
  let hours, minutes;
  const amPm = dateComponents[3].toLowerCase();
  if (amPm === "am") {
    hours = 0;
    minutes = 0;
  } else if (amPm === "pm") {
    hours = 12;
    minutes = 0;
  }

  // Format the date components
  const formattedDate = `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(
    -2
  )}T${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:00Z`;

  return formattedDate;
}


export const convertNumbertoDate = (daysToAdd) => { // hàm này chuyển từ số thành ngày, vì đọc từ excel nó là 45231 tức phải cộng từ ngày 1900-01-00 mới ra ngày hiện tại
  // Excel date epoch (January 1, 1900)
  const excelDateEpoch = new Date(1900, 0, 1);

  // Calculate the result date
  const resultDate = new Date(
    excelDateEpoch.getTime() + daysToAdd * 24 * 60 * 60 * 1000
  );

  // Subtract one day
  resultDate.setDate(resultDate.getDate() - 1);
  // phải -1 nó mới ra đúng ngày vì nó tính từ 1900-01-00, mà nó chỉ viết đc từ ngày 1900-01-01 nên phải -1 nó mới đúng ngày

  // Format the result date as "yyyy-mm-dd"
  const formattedResult = resultDate.toISOString().split("T")[0];

  return formattedResult;
};
// const dateString = "2023-01-15";
// const timeString = "12:30";
// out put: 2023-01-15T12:30:00Z
export function combineDateAndTime(dateString, timeString) {
  const datePart = dateString;
  let timePart;

  if (timeString) {
    timePart = timeString + ":00"; // Adding seconds part
  } else {
    timePart = "00:00:00"; // Default to midnight if timeString is null
  }

  return `${datePart}T${timePart}Z`;
}

export function getUniqueNames(invalidArr) {
  const uniqueNames = [];

  const nameSet = new Set();

  invalidArr.forEach((data) => {
    const { nameToFind } = data;
    nameSet.add(nameToFind);
  });

  uniqueNames.push(...nameSet);

  return uniqueNames;
}

export function convertNumberToHourMinute(excelDate) {// convert 44239.625 to 15:00
  // Check if excelDate is undefined
  if (excelDate === undefined) {
    return null;
  }

  // Excel date epoch (January 1, 1900)
  const excelDateEpoch = new Date(1900, 0, 1);

  // Calculate the result date
  const resultDate = new Date(
    excelDateEpoch.getTime() + excelDate * 24 * 60 * 60 * 1000
  );

  // Extract hh:mm from the result date
  const hours = resultDate.getHours();
  const minutes = resultDate.getMinutes();

  // Format the time as "hh:mm"
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return formattedTime;
}

export function calculateAverage(numbers) {
  // if (numbers.length === 0) {
  //     return null;
  // }

  const sum = numbers.reduce((acc, num) => acc + num, 0);
  const average = sum / numbers.length;

  return average;
}
export function isDayEmptySundayLeave(str) {
  // Kiểm tra ngày đó có phải là empty hay sunday hay là leave nghỉ phép ko
  const targetPrefix = str.toLowerCase().substring(0, 5);

  return ["empty", "sunda", "leave"].some(item => targetPrefix === item.toLowerCase().substring(0, 5));
}

export function calculateTimeDifference(checkin, checkout) {
  const checkinTime = new Date(checkin);
  const checkoutTime = new Date(checkout);

  const timeDifferenceInMilliseconds = checkoutTime.getTime() - checkinTime.getTime();
  if (isNaN(timeDifferenceInMilliseconds)) {
    return { hours: NaN, minutes: NaN };
  }

  const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
  
  return { hours, minutes };
}

export const tinhSoGioTrungBinhDiLam = (arr, year, month) => {
  // arr là arrGroupByEmail
  const days = getTotaldaysOfMonth(year, month);
  let arrResult = [];
  for (let i = 0; i < days.length; i++){
     let arrMinutes = []
     for (let k = 0, len = arr.length; k < len; k++){
        let resultOfCurrentDay = arr[k].result[i];
        let bool = isDayEmptySundayLeave(resultOfCurrentDay);
        if(!bool){ // nếu ko phải là empty, sunday, leave ngày nghỉ thì
           let checkin = arr[k].checkIn[i];
           let checkout = arr[k].checkOut[i];
           let checkoutSubtractCheckin = calculateTimeDifference(checkin,checkout);
           let hour = checkoutSubtractCheckin.hours;
           let minutes = checkoutSubtractCheckin.minutes;
           let totalMinutes = hour*60+minutes;
           if(totalMinutes > 200){
              arrMinutes.push(totalMinutes);
           }
        }
     } 
     arrResult.push(arrMinutes);
  }  
  let results = [];
  for (let a = 0; a < arrResult.length; a++){
     if(arrResult[a].length === 0){
        results.push(0);
     }
     else {
        let b = calculateAverage(arrResult[a]);
        let hour = Number((b/60).toFixed(2));
        results.push(hour);
     }
  }
  return results;
}
export const tinhSoGioTrungBinhCheckin = (arr, year, month) => {
  // arr là arrGroupByEmail
  const days = getTotaldaysOfMonth(year, month);
  let arrResult = [];
  for (let i = 0; i < days.length; i++){
    let arrMinutes = []
    for (let k = 0, len = arr.length; k < len; k++){
        let resultOfCurrentDay = arr[k].result[i];
        let bool = isDayEmptySundayLeave(resultOfCurrentDay);
        if(!bool){ // nếu ko phải là empty, sunday, leave ngày nghỉ thì

          let checkin = arr[k].checkIn[i];

          let timeCheckin = extractTimeFromDateString(checkin);
          // console.log('{hour, minute}: ', {hour, minute});
          let totalMinutes = timeCheckin.hoursNumber*60+timeCheckin.minutesNumber;

          if(totalMinutes > 200){
            arrMinutes.push(totalMinutes);
          }
        }
      } 
      arrResult.push(arrMinutes);
  }  
  let results = [];
  for (let a = 0; a < arrResult.length; a++){
    if(arrResult[a].length === 0){
        results.push(0);
    }
    else {
        let b = calculateAverage(arrResult[a]);
        let hour = Number((b/60).toFixed(2));
        results.push(hour);
    }
  }
  return results;
}
export const tinhSoGioTrungBinhCheckout = (arr, year, month) => {
  // arr là arrGroupByEmail
  const days = getTotaldaysOfMonth(year, month);
  let arrResult = [];
  for (let i = 0; i < days.length; i++){
    let arrMinutes = []
    for (let k = 0, len = arr.length; k < len; k++){
        let resultOfCurrentDay = arr[k].result[i];
        let bool = isDayEmptySundayLeave(resultOfCurrentDay);
        if(!bool){ // nếu ko phải là empty, sunday, leave ngày nghỉ thì

          let checkOut = arr[k].checkOut[i];

          let timeCheckOut = extractTimeFromDateString(checkOut);
          // console.log('{hour, minute}: ', {hour, minute});
          let totalMinutes = timeCheckOut.hoursNumber*60+timeCheckOut.minutesNumber;

          if(totalMinutes > 200){
            arrMinutes.push(totalMinutes);
          }
        }
      } 
      arrResult.push(arrMinutes);
  }  
  let results = [];
  for (let a = 0; a < arrResult.length; a++){
    if(arrResult[a].length === 0){
        results.push(0);
    }
    else {
        let b = calculateAverage(arrResult[a]);
        let hour = Number((b/60).toFixed(2));
        results.push(hour);
    }
  }
  return results;
}
export const tinhSoNhanVienDiLam = (arr, year, month) => {
  // arr là arrGroupByEmail
  const days = getTotaldaysOfMonth(year, month);
  let arrResult = [];
  for (let i = 0; i < days.length; i++){
    let staff = 0;
    for (let k = 0, len = arr.length; k < len; k++){
        let resultOfCurrentDay = arr[k].result[i];
        let bool = isDayEmptySundayLeave(resultOfCurrentDay);
        if(!bool){ // nếu ko phải là empty, sunday, leave ngày nghỉ thì
          staff++;
        }
    } 
    arrResult.push(staff);
  }  
  return arrResult;
}

export function getFractionalPart(number) {
  // 8.22 lấy số lẻ 0.22
  return number % 1;
}

export function convertFractionToTime(fraction) {
  // 8.22 convert to 08:13, because 0.22*60 = 13.2
  const totalMinutes = fraction * 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}
export function isSunday(date) {
  return new Date(date).getDay() === 0; // Sunday
}

export function isSaturday(date) {
  return new Date(date).getDay() === 6; // Saturday
}

export function handleWeekdayConditions(entry, resultArray) {
  const checkInTime = addMinutesToTime(entry.checkIn, 7);
  const checkOutTime = addMinutesToTime(entry.checkOut, 7);

  if (
    new Date(`2000-01-01T${checkInTime}`) > new Date(`2000-01-01T08:35`) &&
    new Date(`2000-01-01T${checkOutTime}`) < new Date(`2000-01-01T16:30`)
  ) {
    resultArray.push("in_late_out_soon");
  } else if (
    new Date(`2000-01-01T${checkInTime}`) > new Date(`2000-01-01T08:35`) &&
    new Date(`2000-01-01T${checkOutTime}`) >= new Date(`2000-01-01T16:30`)
  ) {
    resultArray.push("in_late_out_late");
  } else if (
    new Date(`2000-01-01T${checkInTime}`) <= new Date(`2000-01-01T08:35`) &&
    new Date(`2000-01-01T${checkOutTime}`) < new Date(`2000-01-01T16:30`)
  ) {
    resultArray.push("in_soon_out_soon");
  } else if (
    new Date(`2000-01-01T${checkInTime}`) <= new Date(`2000-01-01T08:35`) &&
    new Date(`2000-01-01T${checkOutTime}`) >= new Date(`2000-01-01T16:30`)
  ) {
    resultArray.push("in_soon_out_late");
  } else if (entry.checkIn === null || entry.checkOut === null) {
    resultArray.push("empty");
  }
}

export function handleSaturdayConditions(entry, resultArray) {
  const checkInTime = addMinutesToTime(entry.checkIn, 7);
  const checkOutTime = addMinutesToTime(entry.checkOut, 7);

  if (
    new Date(`2000-01-01T${checkInTime}`) > new Date(`2000-01-01T08:35`) &&
    new Date(`2000-01-01T${checkOutTime}`) < new Date(`2000-01-01T12:00`)
  ) {
    resultArray.push("in_late_out_soon");
  } else if (
    new Date(`2000-01-01T${checkInTime}`) > new Date(`2000-01-01T08:35`) &&
    new Date(`2000-01-01T${checkOutTime}`) >= new Date(`2000-01-01T12:00`)
  ) {
    resultArray.push("in_late_out_late");
  } else if (
    new Date(`2000-01-01T${checkInTime}`) <= new Date(`2000-01-01T08:35`) &&
    new Date(`2000-01-01T${checkOutTime}`) < new Date(`2000-01-01T12:00`)
  ) {
    resultArray.push("in_soon_out_soon");
  } else if (
    new Date(`2000-01-01T${checkInTime}`) <= new Date(`2000-01-01T08:35`) &&
    new Date(`2000-01-01T${checkOutTime}`) >= new Date(`2000-01-01T12:00`)
  ) {
    resultArray.push("in_soon_out_late");
  } else if (entry.checkIn === null || entry.checkOut === null) {
    resultArray.push("empty");
  }
}

export function addMinutesToTime(time, minutes) {
  if (time === null) {
    return null; // Return null if time is null
  }

  const [hours, originalMinutes] = time.split(":");
  const totalMinutes = parseInt(hours) * 60 + parseInt(originalMinutes);
  const newTotalMinutes = totalMinutes + minutes;

  const newHours = Math.floor(newTotalMinutes / 60);
  const newMinutes = newTotalMinutes % 60;
  return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
    2,
    "0"
  )}`;
}

export function fullMonth(month) {
    const numericMonth = Number(month);

    if (isNaN(numericMonth) || numericMonth < 1 || numericMonth > 12) {
        throw new Error('Invalid month. Month must be between 1 and 12.');
    }

    if (numericMonth >= 1 && numericMonth <= 9) {
        return `0${numericMonth}`;
    } else {
        return numericMonth.toString();
    }
}

export function addLeadingZero(number) {
  // Convert number to string
  let numberStr = number.toString();

  // Check if the length is less than 2
  if (numberStr.length < 2) {
    // Prepend '0' to the string
    numberStr = '0' + numberStr;
  }

  return numberStr;
}
export function getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    
    while (currentDate <= new Date(endDate)) {
       dates.push(currentDate.toISOString().split('T')[0]);
       currentDate.setDate(currentDate.getDate() + 1);
    }
       
       return dates;
}

export function compareCheckInTimes(time1, time2) {
  if(time1 === null){ // nếu time1 === null nghỉa là ngày đó nghỉ phép nên giờ check in chỗ đó file excel báo là null
      return null;
  }
  // Convert time strings to Date objects
  const date1 = new Date(`1970-01-01T${time1}:00Z`);
  const date2 = new Date(`1970-01-01T${time2}:00Z`);

  // Compare Date objects
  if (date1 <= date2) {
      return "time1<=time2";
  } else if (date1 > date2) {
      return "time1>time2";
  } 
}

export function compareCheckOutTimes(time1, time2) {
  if(time1 === null){ // nếu time1 === null nghỉa là ngày đó nghỉ phép nên giờ check in chỗ đó file excel báo là null
      return null;
  }
  // Convert time strings to Date objects
  const date1 = new Date(`1970-01-01T${time1}:00Z`);
  const date2 = new Date(`1970-01-01T${time2}:00Z`);

  // Compare Date objects
  if (date1 < date2) {
      return "time1<time2";
  } else if (date1 >= date2) {
      return "time1>=time2";
  } 
}

export function demxuathien(arr,x){
  let count = 0;
  for (let i = 0; i < arr.length; i++){
      if (arr[i] === x){
            count++;
      }
  }
  return count;
}

export const getTotaldaysOfMonth = (year, month) => {
  const numericYear = Number(year);
  const numericMonth = Number(month);

      if (isNaN(numericYear) || isNaN(numericMonth) || numericMonth < 1 || numericMonth > 12) {
        throw new Error('Invalid year or month. Year must be a number, and month must be between 1 and 12.');
      }

      const daysOfMonth = getDaysInMonth(numericYear, numericMonth);

      // Create an array to render based on the number of days in the month
      const totalDaysOfMonth = Array.from({ length: daysOfMonth }, (_, index) => index + 1);
      return totalDaysOfMonth;
};

export function getYearAndMonthFromDate(dateString) {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1; // Get the month (0-indexed, so add 1 to get the actual month)

  return { year, month };
}



export function extractTimeFromDateString(inputDateString) { // {hoursNumber, minutesNumber}
  const dateObject = new Date(inputDateString);
  const hours = String(dateObject.getUTCHours()).padStart(2, '0'); // thêm số 0 đằng trước nếu < 10
  const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
  const hoursNumber = Number(hours)
  const minutesNumber = Number(minutes)
  return {hoursNumber, minutesNumber};
}

export function tinhTrungBinhLateTimeCheckIn(arr){
  let averageDiffTime = [];

  for (let i = 0; i < arr.length; i++){
    let diff = 0;
    if(arr[i] !== 0 && arr[i] <= 11){
      diff = arr[i] - 8.5;
      averageDiffTime.push((diff*60).toFixed(0));
    }
    else {
      averageDiffTime.push(0);
    }
  } 
  return averageDiffTime;
}
export function tinhTrungBinhLateTimeCheckOut(arrTime, arrGroupByEmail){
  let averageDiffTime = [];

  for (let i = 0; i < arrTime.length; i++){
    let diff = 0;
    if(isSaturday(arrGroupByEmail[0].allDate[i])){ // là thứ 7 thì check rời sau 12h
      if(arrTime[i] !== 0 && arrTime[i] > 11){
        diff = arrTime[i] - 12;
        averageDiffTime.push((diff*60).toFixed(0));
      }
      else {
        averageDiffTime.push(0);
      }
    }
    else { // khác thứ 7 thì check rời sau 16:30

      if(arrTime[i] !== 0 && arrTime[i] > 11){
        diff = arrTime[i] - 16.5;
        averageDiffTime.push((diff*60).toFixed(0));
      }
      else {
        averageDiffTime.push(0);
      }
    }
  } 
  return averageDiffTime;
}

export function KetQuaCuoiCung(item) {
  switch (item) {
    case "in_late_out_soon":
      return "Đi trễ về sớm";
    case "in_late_out_late":
      return "Đi trễ về trễ";
    case "in_soon_out_soon":
      return "Đi sớm về sớm";
    case "in_soon_out_late":
      return "Đến và về đúng giờ";
    case "empty":
      return "Trống";
    case "sunday":
      return "Chủ Nhật";
    case "leave_request_full_day":
      return "Nghỉ phép cả ngày";
    case "leave_request_afternoon_in_invalid_out_valid":
      return "Nghỉ phép buổi chiều, nhưng checkin sau 08:30";
    case "leave_request_afternoon_in_valid_out_invalid":
      return "Nghỉ phép buổi chiều, nhưng checkout trước 16:30";
    case "leave_request_afternoon_in_invalid_out_invalid":
      return "Nghỉ phép buổi chiều, nhưng checkin sau 08:30, checkout trước 16:30";
    case "leave_request_morning_in_invalid_out_valid":
      return "Nghỉ phép buổi sáng, nhưng checkin sau 12:00";
    case "leave_request_morning_in_valid_out_invalid":
      return "Nghỉ phép buổi chiều, nhưng checkout trước 16:30";
    case "leave_request_morning_in_invalid_out_invalid":
      return "Nghỉ phép buổi chiều, nhưng checkin sau 08:30, checkout trước 16:30";
    case "leave_request_afternoon_in_valid_out_valid":
      return "Nghỉ phép buổi chiều, đến và về đúng giờ";
    case "leave_request_morning_in_valid_out_valid":
      return "Nghỉ phép buổi sáng, đến và về đúng giờ";
    default:
      return item;
  }
}

export function formatPhoneNumber(phoneNumber) { // input '0987654321' output: '0987 654 321'
  // Remove any existing spaces
  const cleanedPhoneNumber = phoneNumber.replace(/\s/g, '');

  // Insert spaces at specific positions
  const formattedPhoneNumber = cleanedPhoneNumber.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');

  return formattedPhoneNumber;
}

export function formatThoudsand(inputValue) {
  if (inputValue === "" || inputValue === undefined || inputValue === null) {
    return 0;
  }

  try {
    // Check if inputValue is a string
    if (typeof inputValue !== 'string') {
      // If not a string, convert to string
      inputValue = String(inputValue);
    }

    // Remove commas and periods from the string
    const numericValue = inputValue.replace(/[,.]/g, '');

    // Convert the cleaned string to a number
    const numericNumber = parseInt(numericValue + "");

    // Check if the conversion is successful and within a valid range
    if (!isNaN(numericNumber) && isFinite(numericNumber)) {
      // Format the number with commas manually
      let formattedNumber = "";
      const numericString = String(numericNumber);
      const length = numericString.length;
      let count = 0;

      for (let i = length - 1; i >= 0; i--) {
        formattedNumber = numericString[i] + formattedNumber;
        count++;
        if (count % 3 === 0 && i !== 0) {
          formattedNumber = "," + formattedNumber;
        }
      }

      return formattedNumber;
    } else {
      // console.log('inputValue: ', inputValue);
      throw new Error('Invalid number or out of range');
    }
  } catch (error) {
    console.error("Error in formatThoudsand:", error);
    return inputValue; // Return the original input in case of an error
  }
}


export function getCurrentDateYYYYMMDD() { // output ngày hôm nay theop format: yyyy-mm-dd
  const today = new Date();

  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2); // Months are zero-based
  const day = ("0" + today.getDate()).slice(-2);

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function getStartAndEndOfWeek() {
  const today = new Date();
  const currentDay = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

  // Calculate the start of the week (Monday)
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - currentDay + (currentDay === 0 ? -6 : 1));

  // Calculate the end of the week (Sunday)
  const endOfWeek = new Date(today);
  endOfWeek.setDate(today.getDate() + (6 - currentDay + (currentDay === 0 ? 0 : 1)));

  // Format the dates in "yyyy-mm-dd" format
  const formattedStartOfWeek = formatDateToYYYYMMDD(startOfWeek);
  const formattedEndOfWeek = formatDateToYYYYMMDD(endOfWeek);

  return {
    startOfWeek: formattedStartOfWeek,
    endOfWeek: formattedEndOfWeek
  };
}

export function formatDateToYYYYMMDD(date) { // input type Date , output: yyyy-mm-dd
  // Ensure that the input is a Date object
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  // Get the components of the date
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
  const day = ("0" + date.getDate()).slice(-2);

  // Format the date in "yyyy-mm-dd" format
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function getFirstAndLastDayOfMonth() {
  const today = new Date();
  
  // Calculate the first day of the month
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Calculate the last day of the month
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // Format the dates in "yyyy-mm-dd" format
  const formattedFirstDayOfMonth = formatDateToYYYYMMDD(firstDayOfMonth);
  const formattedLastDayOfMonth = formatDateToYYYYMMDD(lastDayOfMonth);

  return {
    firstDayOfMonth: formattedFirstDayOfMonth,
    lastDayOfMonth: formattedLastDayOfMonth
  };
}

export function getFirstAndLastDayOfYear() {
  const today = new Date();
  
  // Calculate the first day of the year
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);

  // Calculate the last day of the year
  const lastDayOfYear = new Date(today.getFullYear() + 1, 0, 0);

  // Format the dates in "yyyy-mm-dd" format
  const formattedFirstDayOfYear = formatDateToYYYYMMDD(firstDayOfYear);
  const formattedLastDayOfYear = formatDateToYYYYMMDD(lastDayOfYear);

  return {
    firstDayOfYear: formattedFirstDayOfYear,
    lastDayOfYear: formattedLastDayOfYear
  };
}

export function getFirstAndLastDayOfMonthOfYear(month, year) {
  // Ensure month is in the range [1, 12]
  month = Math.max(1, Math.min(12, month));

  // Create a new Date object with the given year and month (months are 0-indexed in JavaScript)
  const firstDay = new Date(year, month - 1, 1);
  const lastDay = new Date(year, month, 0); // Setting day to 0 gets the last day of the previous month

  // Format the dates to 'YYYY-MM-DD'
  const formattedFirstDay = `${firstDay.getFullYear()}-${(firstDay.getMonth() + 1).toString().padStart(2, '0')}-01`;
  const formattedLastDay = `${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

  return {
    firstDay: formattedFirstDay,
    lastDay: formattedLastDay,
  };
}

export function splitMonthYear(dateString) { // input: 12-2023 ; output: 12, 2023
  const [month, year] = dateString.split('-');
  
  return {
    month: parseInt(month, 10),
    year: parseInt(year, 10),
  };
}

export function getFirstAndLastDayOfYearParameter(year) {
  // Ensure the input is a valid integer
  const parsedYear = parseInt(year, 10);
  if (isNaN(parsedYear)) {
    throw new Error('Invalid year format. Please provide a valid integer year.');
  }

  // Create a new Date object for the first day of the year
  const firstDay = new Date(parsedYear, 0, 1);

  // Create a new Date object for the last day of the year
  const lastDay = new Date(parsedYear, 11, 31);

  // Format the dates to 'YYYY-MM-DD'
  const formattedFirstDay = `${firstDay.getFullYear()}-${(firstDay.getMonth() + 1).toString().padStart(2, '0')}-${firstDay.getDate().toString().padStart(2, '0')}`;
  const formattedLastDay = `${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

  return {
    firstDay: formattedFirstDay,
    lastDay: formattedLastDay,
  };
}
export function addOneDay(dateString) { // yyyy-mm-dd
  // Convert string to Date object
  const dateObject = new Date(dateString);

  // Add one day
  dateObject.setDate(dateObject.getDate() + 1);

  // Format the updated date to YYYY-mm-dd
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
export function addOneDay2(dateString) { // yyyy-dd-mm
  // Convert string to Date object 
  const dateObject = new Date(dateString);

  // Add one day
  dateObject.setDate(dateObject.getDate() + 1);

  // Format the updated date to YYYY-mm-dd
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');

  return `${year}-${day}-${month}`;
}

export function getCurrentDate() { // output: YYYY-MM-DD
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1; // Months are zero-based
  let day = today.getDate();

  // Add leading zeros if the month or day is less than 10
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  return `${year}-${month}-${day}`;
}