import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './Cskh.module.scss'
import CskhSubMenu from './CskhSubMenu';

function CskhHome() {
   const dispatch = useDispatch(); //hook phát đi đến GeneralSlice.js

   return (
      <div className={clsx(styles.component_CskhHome)}>
         <CskhSubMenu/>   
         <Outlet/>

      </div>
   )
}
export default CskhHome