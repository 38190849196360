import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from './TheTinDung.module.scss'
import {
    retrieveObjectFromLocalStorage,
    getFirstAndLastDayOfYearParameter,
    splitMonthYear,
    getFirstAndLastDayOfMonthOfYear,
    addOneDay,
    getFirstAndLastDayOfYear,
    getFirstAndLastDayOfMonth,
    getStartAndEndOfWeek,
    getCurrentDateYYYYMMDD,
    extractYYYY_MM_DD,
    formatDateString,
    wait,
    formatPhoneNumber,
    replaceFirstFourLetters,
} from '../../share/sharedFunction';
import collectionAPI from '../../../API/collectionAPI';
import SearchIcon from '@mui/icons-material/Search';
import NotYetSignIn from '../general/NotYetSignIn';
import { update_khachHangId, update_giaoDichId } from "../../../features/generalSlice";
import { useDispatch } from 'react-redux';
import SwapVertIcon from '@mui/icons-material/SwapVert';

function TheTinDung() {

    const [stateArrGiaodich, setStateArrGiaodich] = useState<any[]>([]);
    const [stateArrGiaodich_show, setStateArrGiaodich_show] = useState<any[]>([]);
    const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
        email: "",
        fullName: "",
        role: "",
        token:""
    })
    const checkSignin = () => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        setStateObjSignIn(userExist);
    
    }
    const findAllGiaodich = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        const token = userExist.token;
        let start = "";
        let end = "";
        start = getCurrentDateYYYYMMDD();
        end = addOneDay(start);
        let nguonKH = "Tất cả";
        let teamrutdaothongbaotrangthai = "Tất cả";
        let nhucau = "Rút tiền";
        try {
            let response = await collectionAPI.collectionAPI.findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByDESC(token, start, end, nguonKH, teamrutdaothongbaotrangthai, nhucau);
            // console.log('all giao dich: ', response.data.data);
            setStateArrGiaodich(response.data.data)
            setStateArrGiaodich_show(response.data.data)
        } catch (err) {
            // console.log('err:', err);
        }
    }
    const [stateArrNguonkh, setStateArrNguonkh] = useState<any[]>([]);
    const idsToRemove = ["Tất cả", "Rút đáo", "CSKH"];
    const findAllNguonkh = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist !== null){
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI.findAllNguonKH(token);
                const newArray = response.data.filter((item: any) => !idsToRemove.includes(item.id));

                setStateArrNguonkh(newArray);

            } catch (err) {
                // console.log('err:', err);
            }
        }
    }

    const [stateString_timeFrame, setStateString_timeFrame] = useState<string>("Hôm nay");

    const showGiaodich_atBegin = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        let start = getCurrentDateYYYYMMDD();
        let end = addOneDay(start);
        getData(start, end, stateString_nguonKH, stateString_teamrutdaothongbaotrangthai, stateString_nhucau);   
    }
    const handleChange_timeFrame = async (event:any) => {
        const timeFrame = event.target.value;
        if(timeFrame === "Chọn thời gian"){
            alert("Bạn vui lòng chọn thời gian cụ thể trong danh sách. Xin cảm ơn");
            return;
        }
        
        // console.log('timeFrame: ', timeFrame);
        setStateString_timeFrame(timeFrame);
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        const listMonthYear = [
            "12-2023",
            "01-2024",
            "02-2024",
            "03-2024",
            "04-2024"
        ]
        const token = userExist.token;

        let start = ""
        let end = ""
        if(timeFrame === "Hôm nay"){
            start = getCurrentDateYYYYMMDD();
            end = addOneDay(start);
        }
        else if (timeFrame === "Tuần này"){
            let obj = getStartAndEndOfWeek();
            start = obj.startOfWeek;
            end = obj.endOfWeek;
        }
        else if (timeFrame === "Tháng này"){
            let obj = getFirstAndLastDayOfMonth();
            start = obj.firstDayOfMonth;
            end = obj.lastDayOfMonth;
        }
        else if (timeFrame === "Năm nay"){
            let obj = getFirstAndLastDayOfYear();
            start = obj.firstDayOfYear;
            end = obj.lastDayOfYear;
        }
        else if (listMonthYear.includes(timeFrame)){
            const {month, year} = splitMonthYear(timeFrame)
            const {firstDay, lastDay} = getFirstAndLastDayOfMonthOfYear(month, year);

            start = firstDay;
            end = lastDay;
        }
        else if(timeFrame === "2024" || timeFrame === "2025"){
            const {firstDay, lastDay} = getFirstAndLastDayOfYearParameter(timeFrame);
            start = firstDay;
            end = lastDay;
        }
        setStateString_startTime(start);
        setStateString_endTime(end);

        getData(start, end, stateString_nguonKH, stateString_teamrutdaothongbaotrangthai, stateString_nhucau);
    
    };

    const getData = async (start: string, end: string, nguonKH: string, trangThai: string, nhuCau: string) => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        let token = userExist.token;
        try {
            let response = await collectionAPI.collectionAPI.findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByDESC(token, start, end, nguonKH, trangThai, nhuCau);
            // console.log('response.data.data: ', response.data.data);
            if(response.data.data.length <= 0){
                setStateArrGiaodich([]);
                setStateArrGiaodich_show([]);

                return;
            }
            let arr = response.data.data;
            // console.log('arr: ', arr);
            // let sortedArr = arr.sort((a: any, b: any) => new Date(a.the[0].id).getTime() - new Date(b.the[0].id).getTime());
            // console.log('sortedArr: ', sortedArr);
            let count_input = 0;
            let obj_input: any = {};

            // for (let obj of sortedArr) {
            //     obj_input[count_input] = extractYYYY_MM_DD(obj.the[0].hanDao);
            //     count_input++;
            // }
            // setStateObj_groupInput(obj_input);
            setStateArrGiaodich(arr);
            setStateArrGiaodich_show(arr);

            // setStateArr_khachHangByNguonKH(response.data.data); // lưu lại danh sách group nhân viên đc click vào, và giữ tạm ở đó
            // setStateArr_khachHangByNguonKHShow(response.data.data); // hiện ra màn hình
            
        } catch (err) {
            // console.log('err:', err);
        }
    
    }

    const [stateBool_sort, setStateBool_sort] = useState(false); // boolean

    const sortHanDao = () => {
        // Toggle the sorting order
        setStateBool_sort(!stateBool_sort);
    
        // Sort the array based on the sorting order
        const sortedArr = stateArrGiaodich.sort((a: any, b: any) => {
            const dateA = new Date(a.the[0].hanDao).getTime();
            const dateB = new Date(b.the[0].hanDao).getTime();
            
            // Sort in ascending order if stateBool_sort is true, otherwise sort in descending order
            return stateBool_sort ? dateA - dateB : dateB - dateA;
        });
    
        // Update the state
        let count_input = 0;
        let obj_input: any = {};
    
        for (let obj of sortedArr) {
            obj_input[count_input] = extractYYYY_MM_DD(obj.the[0].hanDao);
            count_input++;
        }
    
        setStateObj_groupInput(obj_input);
        setStateArrGiaodich(sortedArr);
        setStateArrGiaodich_show(sortedArr);
    }

    const [stateString_searchText, setStateString_searchText] = useState<string>('');

    const handleInputChange = (event: any) => {
        setStateString_searchText(event.target.value);
        let searchText = event.target.value;
        // console.log('searchText: ', searchText);
        let searchedContacts = stateArrGiaodich.filter(obj =>
            obj.khachhang.fullNameKH.toLowerCase().includes(searchText.toLowerCase()) ||
            obj.khachhang.phone.some((phoneObj: any) => phoneObj.phone.toLowerCase().includes(searchText.toLowerCase())) ||
            obj.khachhang.cccd.toString().includes(searchText.toLowerCase()) ||
            obj.the[0].soThe.toString().includes(searchText.toLowerCase()) ||
            obj.the[0].tenTrenThe.toString().includes(searchText.toLowerCase())
        );
        // console.log('searchedContacts: ', searchedContacts);
        setStateArrGiaodich_show(searchedContacts);
    };

    const [stateString_startTime, setStateString_startTime] = useState<string>("");
    const [stateString_endTime, setStateString_endTime] = useState<string>("");
    const handleChange_startTime = (event:any) => { 
        // console.log('event.target.value: ', event.target.value);
        setStateString_startTime(event.target.value) 
    }; 
    const handleChange_endTime = async (event:any) => { 
        const endDate = event.target.value
        setStateString_endTime(event.target.value);
        setStateString_timeFrame("Chọn thời gian");

        getData(stateString_startTime, endDate, stateString_nguonKH, stateString_teamrutdaothongbaotrangthai, stateString_nhucau);

    }; 

    
    const [stateString_nguonKH, setStateString_nguonKH] = useState<string>('Tất cả');
    const handleChange_nguonKH = async (event: any) => {
        const nguonKH = event.target.value;
        // console.log('value: ', nguonKH);
        setStateString_nguonKH(nguonKH);

        getData(stateString_startTime, stateString_endTime, nguonKH, stateString_teamrutdaothongbaotrangthai, stateString_nhucau);
    }
    const [stateString_nhucau, setStateString_nhucau] = useState("Rút tiền"); // string
    const handleChange_nhucau = (e:any) => {
        const nhucau = e.target.value;
        setStateString_nhucau(nhucau);
        getData(stateString_startTime, stateString_endTime, stateString_nguonKH, stateString_teamrutdaothongbaotrangthai, nhucau)

    }
    const [stateString_teamrutdaothongbaotrangthai, setStateString_trangthai] = useState("Tất cả"); // string
    const handleChange_teamrutdaothongbaotrangthai = async (e:any) => {
        const trangThai = e.target.value;
        // console.log('trangThai: ', trangThai);
        
        setStateString_trangthai(trangThai);

        getData(stateString_startTime, stateString_endTime, stateString_nguonKH, trangThai, stateString_nhucau);

    }

    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const selectGiaodich = (giaoDichId: number) => {
        
        let objGiaoDich:any = stateArrGiaodich.find((obj:any) => obj.id === giaoDichId);
        // console.log('objGiaoDich: ', objGiaoDich);
        let khachHangId = objGiaoDich.khachhang.id;
        dispatch(update_khachHangId(khachHangId));
        dispatch(update_giaoDichId(giaoDichId));

        navigate("/finance/khachhang", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />
    
    }
    useEffect(() => {
        checkSignin();
        findAllGiaodich();
        findAllNguonkh();
        showGiaodich_atBegin();
    },[]);

    const [stateObj_groupInput, setStateObj_groupInput] = useState({});

    const handleInputGroupChange = (index: number, value: string) => {
        setStateObj_groupInput(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
        // console.log('index: ', index);
        // console.log('value: ', value);
    };
    const saveHanDao = async () => {
    
        // console.log('stateObj_groupInput: ', stateObj_groupInput);
        const token = stateObjSignIn.token;
        for (let i = 0; i < stateArrGiaodich.length; i++){
            let id = stateArrGiaodich[i].the[0].id;
            // `$ {id}` ép id thành string
            //@ts-ignore
            let hanDao: any = `${stateObj_groupInput[i]}T00:00:00.000Z`;
            // console.log('hanDao: ', hanDao);
            if(hanDao !== null){ // cái nào null nghĩa là ko update thì bỏ qua
                try {
                    let response = await collectionAPI.collectionAPI.updateTheByIdByfield(token, id,"hanDao",hanDao);
                    // console.log('response: ', response);                    
                }catch(err){
                    // console.log('err:', err);
                }
            }
            await wait(300);
        }  
    }
    return (
        stateObjSignIn.token === "" ? <NotYetSignIn /> :

        <div className={clsx(styles.component_TheTinDung)}>
        <h2>DANH SÁCH THẺ TÍN DỤNG</h2>
        <div className={clsx(styles.navigation)}>
            <div className={clsx(styles.timeWrapper)}>
                <select name="from" onChange={handleChange_timeFrame} className={clsx(styles.selectOption)} value={stateString_timeFrame}>
                    <option value="Chọn thời gian">Chọn thời gian...</option>
                    <option value="Hôm nay">Hôm nay</option>
                    <option value="Tuần này">Tuần này</option>
                    <option value="Tháng này">Tháng này</option>
                    <option value="Năm nay">Năm nay</option>
                    <option value="01-2024">01-2024</option>
                    <option value="02-2024">02-2024</option>
                    <option value="03-2024">03-2024</option>
                    <option value="04-2024">04-2024</option>
                    <option value="05-2024">05-2024</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
            </div>
            <div className={clsx(styles.dateRange)}>
                <div className={clsx(styles.row)}>
                    <p className={clsx(styles.key)}>Bắt đầu</p>
                    <input type="date" 
                        className={clsx(styles.inputDate)} 
                        value={stateString_startTime} 
                        onChange={handleChange_startTime}/>
                </div>
                <div className={clsx(styles.row)}>
                    <p className={clsx(styles.key)}>Kết thúc</p>
                    <input type="date" 
                        className={clsx(styles.inputDate)} 
                        value={stateString_endTime} 
                        onChange={handleChange_endTime}/>
                </div>
            </div>
            <div className={clsx(styles.nhucau_trangthai)}>
                <div className={clsx(styles.nhucau)}>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="nhucau" checked={stateString_nhucau === "Rút tiền"} value="Rút tiền" className={clsx(styles.item)} 
                        onChange={handleChange_nhucau}/> <span>Rút tiền</span>
                    </div>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="nhucau" value="Đáo hạn" className={clsx(styles.item)} 
                        onChange={handleChange_nhucau}/> <span>Đáo hạn</span>
                    </div>
                </div>
                <div className={clsx(styles.trangthai)}>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="trangthai" checked={stateString_teamrutdaothongbaotrangthai === "Tất cả"} value="Tất cả" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Tất cả</span>
                    </div>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="trangthai"  value="Chờ" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Chờ</span>
                    </div>
                    <div className={clsx(styles.box)}>
                        {
                            stateString_nhucau === "Rút tiền" ?
                                <>
                                    <input type="radio" name="trangthai"  value="Đang Rút" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Đang Rút</span>
                                </>
                                :
                                <>
                                    <input type="radio" name="trangthai"  value="Đang đáo" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Đang đáo</span>
                                </>
                        }

                    </div>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="trangthai" value="Kế toán" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Kế toán</span>
                    </div>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="trangthai" value="Hỏng" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Hỏng</span>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.nguonKHWrapper)}>
                    <label htmlFor="">Nguồn khách hàng</label>
                    <select name="nguonkh" onChange={handleChange_nguonKH} className={clsx(styles.selectOption)}>
                        <option value="Tất cả">Tất cả</option>
                        <option value="Rút đáo">Rút đáo</option>
                        <option value="CSKH">CSKH</option>
                        {
                            stateArrNguonkh.map((obj, index) => {
                                return ( 
                                    <option key={obj.id} value={obj.id}>{obj.id}</option>
                                )
                            })
                        }
                    </select>
            </div>
            <div className={clsx(styles.searchWrapper)}>
                <div className={clsx(styles.searchGroup)}>
                    <SearchIcon className={clsx(styles.iconSearch)}/>
                    <input
                        type="text"
                        placeholder="Tìm tên, sđt, cccd, số / tên trên thẻ..."
                        value={stateString_searchText}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            {/* <div className={clsx(styles.sortWrapper)}>
                <SwapVertIcon onClick={sortHanDao}/> <span>Sort Hạn Đáo</span>
            </div> */}
        </div>
        {/* end navigation */}
        <div className={clsx(styles.table)}>
            <div className={clsx(styles.row, styles.heading)}>
                <p>ID Thẻ</p>
                <p>Tên trên thẻ</p>
                <p>Tên khách hàng</p>
                <p>SĐT</p>
                <p>Số thẻ</p>
                <p>Loại thẻ</p>
                <p>STK ngân hàng</p>
                <p>STK Thẻ tín dụng</p>
                <p>Tạo lúc</p>
                <p>Người giữ thẻ</p>
                <p className={clsx(styles.btnSaveWrapper)}>
                    <span className={clsx(styles.sortWrapper)}>
                        <SwapVertIcon onClick={sortHanDao} className={clsx(styles.sortIcon)}/>
                    </span>
                    <span>
                        Hạn đáo
                    </span>
                    <span className={clsx(styles.btnSave)} onClick={saveHanDao}>
                        Save
                    </span>
                </p>
                {/* <p>Xem</p> */}
            </div>
            <div className={clsx(styles.rowWrapper)}>
            {
                stateArrGiaodich_show.map((obj:any, index) => {
                    // console.log('----------------------------------------');
                    // console.log('obj: ', obj);
                    // console.log(' stateArrGiaodich_show.length: ', stateArrGiaodich_show.length);
                    if (obj.the.length !== 0){
                        return ( 
                            <div className={clsx(styles.row)} key={obj.id}>
                                <p>{obj.the[0].id}</p>
                                <p>{obj.the[0].tenTrenThe}</p>
                                <p>{obj.khachhang.fullNameKH}</p>
                                <p>
                                    {obj.khachhang.phone.map((objPhone:any, phoneIndex:any) => (
                                        <span key={phoneIndex}>{replaceFirstFourLetters(formatPhoneNumber(objPhone.phone))}<br/></span>
    
                                    ))}
                                </p>
                                <p>{obj.the[0].soThe}</p>
                                <p>{obj.the[0].loaiThe}</p>
                                {
                                    obj.soTaiKhoanNganHang.length !== 0 &&
                                    <p>{obj.soTaiKhoanNganHang[0].soTaiKhoanNganHang}</p>
                                }
                                <p>{obj.the[0].soTaiKhoanTheTinDung}</p>
                                <p>{formatDateString(obj.createdAt)}</p>
                                <p>{obj.the[0].aiDangGiu}</p>
                                <p>
                                <input
                                    type="text"
                                    className={clsx(styles.inputTag, styles.inputThird)}
                                    // `$ {id}` ép id thành string
                                    //@ts-ignore
                                    value={stateObj_groupInput[index] || ''}
                                    placeholder="YYYY-MM-DD"
                                    onChange={(event) => handleInputGroupChange(index, event.target.value)}
                                />
                                </p>
                                {/* <p><button onClick={()=>selectGiaodich(obj.id)}>Xem</button></p> */}
                            
                            </div>
                        )
                    }
                })
            }
            </div>
        </div>
    </div>
    );
    

}
export default TheTinDung