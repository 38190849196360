import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './Rutdao.module.scss'
import RutdaoSubMenu from './RutdaoSubMenu';

function RutdaoHome() {
   const dispatch = useDispatch(); //hook phát đi đến GeneralSlice.js

   return (
      <div className={clsx(styles.component_RutdaoHome)}>
         <RutdaoSubMenu/>   
         <Outlet/>

      </div>
   )
}
export default RutdaoHome


