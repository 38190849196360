import { makeAutoObservable, toJS } from "mobx";

const initialData = [
  { id: "1", name: "Charm Services" },
  { id: "2", name: "Forum Threads" },
  {
    id: "3",
    name: "Discussion Channels",
    children: [
      { id: "c1", name: "General Discussions" },
      { id: "c2", name: "Random Chats" },
      {
        id: "c3",
        name: "Open Source Communities",
        children: [
          { id: "os1", name: "React Community" },
          { id: "os2", name: "Python Developers" },
          {
            id: "os3",
            name: "Java Enthusiasts",
            children: [
              { id: "je1", name: "Spring Framework" },
              { id: "je2", name: "JavaFX" },
              {
                id: "je3",
                name: "Java Libraries",
                children: [
                  { id: "jl1", name: "Guava" },
                  { id: "jl2", name: "Apache Commons" },
                  {
                    id: "jl3",
                    name: "Java Utilities",
                    children: [
                      { id: "ju1", name: "Logging" },
                      { id: "ju2", name: "Collections" },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "4",
    name: "Private Messages",
    children: [
      { id: "d1", name: "Alice Smith" },
      { id: "d2", name: "Bob Johnson" },
      {
        id: "d3",
        name: "Charlie Brown",
        children: [
          { id: "cb1", name: "Family Chat" },
          { id: "cb2", name: "Work Friends" },
          {
            id: "cb3",
            name: "Project Collaborators",
            children: [
              { id: "pc1", name: "Team A" },
              { id: "pc2", name: "Team B" },
              {
                id: "pc3",
                name: "External Partners",
                children: [
                  { id: "ep1", name: "Company A" },
                  { id: "ep2", name: "Company B" },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];


export class AgentStore {
  title: string;
  data: any = [];

  constructor() {
    this.title = "";
    this.data = []
    makeAutoObservable(this)
  }

  setTitle(value: string) {
    this.title = value;
  }

  setData(value: any) {
    this.data = value;
  }
  loadData() {
    this.data = [...initialData];
  }

}

