import { useState, useEffect } from "react";
import clsx from "clsx";
import styles from "./AgentTree.module.scss";
import { Tree } from "react-arborist";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useAppStore } from "../../../../stores/AppStore";
import { formatDateString } from "../../../share/sharedFunction";

// const initialData1 = [
//   { id: "1", name: "Charm Services", phone: "069874651351"},
//   { id: "2", name: "Forum Threads" , path: "069874651351"},
//   {
//     id: "3",
//     name: "Discussion Channels",
//     children: [
//       { id: "c1", name: "General Discussions" },
//       { id: "c2", name: "Random Chats" },
//       {
//         id: "c3",
//         name: "Open Source Communities",
//         children: [
//           { id: "os1", name: "React Community" },
//           { id: "os2", name: "Python Developers" },
//           {
//             id: "os3",
//             name: "Java Enthusiasts",
//             children: [
//               { id: "je1", name: "Spring Framework" },
//               { id: "je2", name: "JavaFX" },
//               {
//                 id: "je3",
//                 name: "Java Libraries",
//                 children: [
//                   { id: "jl1", name: "Guava" },
//                   { id: "jl2", name: "Apache Commons" },
//                   {
//                     id: "jl3",
//                     name: "Java Utilities",
//                     children: [
//                       { id: "ju1", name: "Logging" },
//                       { id: "ju2", name: "Collections" },
//                     ],
//                   },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     id: "4",
//     name: "Private Messages",
//     children: [
//       { id: "d1", name: "Alice Smith" },
//       { id: "d2", name: "Bob Johnson" },
//       {
//         id: "d3",
//         name: "Charlie Brown",
//         children: [
//           { id: "cb1", name: "Family Chat" },
//           { id: "cb2", name: "Work Friends" },
//           {
//             id: "cb3",
//             name: "Project Collaborators",
//             children: [
//               { id: "pc1", name: "Team A" },
//               { id: "pc2", name: "Team B" },
//               {
//                 id: "pc3",
//                 name: "External Partners",
//                 children: [
//                   { id: "ep1", name: "Company A" },
//                   { id: "ep2", name: "Company B" },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];

export const AgentTree =
  observer(() => {
    const {
      agentStore,
      agentStore: { title },
      agentTreeStore,
      agentTreeStore: {arrData, arrNode, selectedNode, objView}
    } = useAppStore();

  // console.log(data);

  useEffect(() => {
      agentStore.loadData();
      // checkSignin();
  },[agentStore]);

    const [term, setTerm] = useState("");
    const [stateBool_showTree, setStateBool_showTree] = useState(false); // boolean

    // const [data, setData] = useState<any[]>(initialData2);
    // const [data, setData] = useState<any[]>([]);
    const [stateObjBool_view, setStateObjBool_view] = useState({
      viewNodeDetails: false,
      viewAddAgentChild: false,
    });

    useEffect(() => {
      
      agentTreeStore.findTree();
      const delayFunction = () => {
        setStateBool_showTree(false);
      };
      const timeoutId = setTimeout(delayFunction, 200);

      const delayFunction2 = () => {
        setStateBool_showTree(true);
      };
      const timeoutId2 = setTimeout(delayFunction2, 400);

      return () => clearTimeout(timeoutId2);

    }, [agentTreeStore]);

    const onAddNewAgent = () => {
      setStateObjBool_view((prevState) => ({
        ...prevState, // Spread the previous state to retain any other keys
        viewAddAgentNew: true, // Update the value of viewAddAgentNew key
      }));
      // console.log('data: ', toJS(data));
      // agentStore.loadData();
    };
    const selectNode = (node: any) => {
    
      // console.log('id: ', node.id);
      const nodeId = node.id;
      const obj = arrNode.find((item:any) => item.id === nodeId);
      // console.log('obj: ', toJS(obj));
      agentTreeStore.findNodeByIdWithMemberAndUser(nodeId);
    
    }
    return (
      <div className={clsx(styles.component_AgentTree)}>
        <div className={clsx(styles.boxGeneral)}>
          <div className={clsx(styles.boxTree)}>
            <div className={clsx(styles.boxTopLeft)}>
              <div className={clsx(styles.boxSearch)}>
                <input
                  type="search"
                  value={term}
                  onChange={(event) => setTerm(event.target.value)}
                />
                <SearchIcon />
              </div>
              <div className={clsx(styles.boxIconAdd)}>
                <AddIcon
                  className={clsx(styles.iconAddSeparate)}
                  onClick={onAddNewAgent}
                />
              </div>
            </div>
            {/* <p className={clsx(styles.a)}>{stateBool_showTree.toString()}</p> */}
            {stateBool_showTree && (
              <Tree
                initialData={arrData}
                openByDefault={false}
                width={500}
                height={1000}
                indent={24}
                rowHeight={30}
                overscanCount={1}
                // paddingTop={30}
                // paddingBottom={10}
                // padding={25}
                searchTerm={term}
                searchMatch={(node: any, searchTerm) =>
                  node.data.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                }
                className={clsx(styles.tree)}
                // onSelect={(node => selectNode(node))}
                onActivate={(node => selectNode(node))}
              >
                {Node}
              </Tree>
            )}
          </div>
          <div className={clsx(styles.nodeDetails)}>
            {objView.viewNodeDetails && (
              <div className={clsx(styles.boxNodeDetails)}>
                <h4>Thông tin chi tiết của hội viên </h4>
                <div className={clsx(styles.boxNodeDetailsInfo)}>
                  <div className={clsx(styles.left)}>
                    
                    <div className={clsx(styles.row, styles.topRow)}>
                      <p className={clsx(styles.key)}>Id:</p>
                      <p className={clsx(styles.value)}>{selectedNode.id}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Username:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.username}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Hội viên cấp dưới trực tiếp:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.numchild}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>CCCD:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.member?.cccd}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Thành phố:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.member?.city}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Quận:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.member?.district}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Phường:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.member?.ward}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Địa chỉ:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.member?.address}</p>
                    </div>
                  </div>
                  <div className={clsx(styles.right)}>
                    <div className={clsx(styles.row, styles.topRow)}>
                      <p className={clsx(styles.key)}>Loại:</p>
                      <p className={clsx(styles.value, styles.typeWrapper)}>
                        <span className={clsx(styles.type)}>
                          {selectedNode?.member?.Type}
                        </span>
                      </p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Tạo lúc:</p>
                      <p className={clsx(styles.value)}>{formatDateString(selectedNode?.member?.user?.createdAt)}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Email:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.member?.user?.email}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Tên:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.member?.user?.name}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>SĐT:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.member?.user?.phone}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Tình trạng:</p>
                      {
                        selectedNode?.member?.user?.status === "enable" ?
                        <p className={clsx(styles.value)}>Đang hoạt động</p>
                        :
                        <p className={clsx(styles.value)}>Bị khóa</p>
                      }
                    </div>
                 
                    <div className={clsx(styles.row)}>
                      <p className={clsx(styles.key)}>Tổng điểm:</p>
                      <p className={clsx(styles.value)}>{selectedNode?.member?.diem}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });

const Node = ({ node, style, dragHandle }: any) => {
  // const iconGold = (
  //   <img src="../assets/level/gold2.png" className="avatar" alt="avatar" />
  // );
  // const iconSilver = (
  //   <img src="../assets/level/silver2.jpg" className="avatar" alt="avatar" />
  // );
  const isLeafNode = node.isLeaf;
  let icon = isLeafNode
    ? "../assets/level/gold2.png"
    : "../assets/level/silver1.png";
  // console.log('action: ', node.data);
  const selectNode = (id: any) => {
    // const obj = stateArrNode.find((item) => item.id === idParams); //lấy một object đầu tiên được tìm thấy mà thoả điều kiện

  };
  // const addNode = (id: any) => {
  //   console.log("id: ", id);
  // };
  return (
    <div className={clsx(styles.boxNode)}>
      <div
        onClick={() => node.toggle(
          selectNode(node.id)
        )} // tắt mở children
        style={style}
        ref={dragHandle} // kéo thả
        title={node.data.name} // tooltip
        className={clsx(styles.node, styles.node_tree, {
          [styles.selected]: node.isSelected ? true : false,
        })}
        onContextMenu={(event) => {
          //right click kích hoạt hàm onContextMenu
          event.preventDefault();
          // alert("test");
        }}
      >
        {/* <img src={icon} alt={isLeafNode ? "Gold" : "Silver"} /> */}
        <span           
          className={clsx(styles.type,
            {[styles.Classic]: node.data.member.Type === "Classic"},
            {[styles.Silver]: node.data.member.Type === "Silver"},
            {[styles.Titan]: node.data.member.Type === "Titan"},
            {[styles.Gold]: node.data.member.Type === "Gold"},
            {[styles.Diamond]: node.data.member.Type === "Diamond"},
            {[styles.Platinum]: node.data.member.Type === "Platinum"},
            {[styles.PlatinumMax]: node.data.member.Type === "Platinum Max"},
            {[styles.Max]: node.data.member.Type === "Max"},
            )}
          >
          {node.data?.member?.Type}
        </span>
        <span className={clsx(styles.name)}>{node?.data?.name}</span>
        {
          node?.data?.children?.length > 0 &&
          <AddIcon
            className={clsx(styles.icon, styles.iconAdd)}
          />
        }
        {/* {
          node.data.children.length > 0 && node.isSelected &&
          <RemoveIcon
            className={clsx(styles.icon, styles.iconAdd)}
          />
        } */}
      </div>
      {/* <div className={clsx(styles.buttonWrapper)}>
        
        <ArrowForwardIcon
          className={clsx(styles.icon, styles.iconRightArrow)}
          onClick={() => selectNode(node.data.id)}
        />
      </div> */}
    </div>
  );
};
