import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, Outlet, useNavigate } from "react-router-dom"
import styles from './Sidebar.module.scss'
import { useSelector, useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { MenuRoute } from './route-option';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../stores/AppStore';
import { retrieveObjectFromLocalStorage } from './sharedFunction';

interface ItemWithChildren {
    id: number;
    path: string;
    title: string;
    status: boolean;
    iconLeft: string;
    iconRight?: boolean;
    node: string;
    children: {
        id: number;
        path: string;
        title: string;
        status: boolean;
        iconLeft: string;
    }[];
}

interface ItemWithoutChildren {
    id: number;
    path: string;
    title: string;
    status: boolean;
    iconLeft: string;
    iconRight?: boolean;
    node: string;
}
const emailPgsaru = ['vu@gmail.com', 'anh@gmail.com', 'dong@gmail.com', 'huong@gmail.com']

export const Sidebar = observer(() => {
    const { authStore } = useAppStore();
    const userName = authStore.user?.name;
    const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
    // const [logo_no_words, locoH, logoV] = logoUrl.split('_split_');
    const [stateSidebarItems, setStateSidebarItems] = useState(MenuRoute); //tạo state toggle và cho nội dung của nó ban đầu là mảng AdminMenuRoute
    // console.log('stateSidebarItems: ', stateSidebarItems);
    const openClose = (id: number) => {
        // Đại khái là click vào object nào thì cho status nó thành true nếu nó là false, còn nếu nó là false thì thành true, true thì mở, còn false thì đóng
        const toggleStatus = (items: any) => {
            return items.map((item: any) => {
                if (item.id === id) {
                    return { ...item, status: !item.status };
                }
                if (item.children) {
                    return { ...item, children: toggleStatus(item.children) };
                }
                return item;
            });
        };

        setStateSidebarItems(prevItems => toggleStatus(prevItems));
    };

    const [stateBoolSidebar, setStateBoolSidebar] = useState(false); // boolean

    const toggleSidebar = () => {

        setStateBoolSidebar(!stateBoolSidebar)

    }
    const [stateBoolBackShadow, setStateBoolBackShadow] = useState(true); // boolean
    const clearShadow = () => {



    }
    type Item = ItemWithChildren | ItemWithoutChildren;

    function hasChildren(item: Item): item is ItemWithChildren {
        return (item as ItemWithChildren).children !== undefined;
    }

    const sidebarItemClick = (id: number) => {

    };
    if(userExist === null){
        return null;
    }
    // console.log('userExist: ', userExist);
    return (
        // stateBoolSidebar === false ? 
        <>
            <div className={clsx(styles.BoxMenubar, { [styles.moveRightBoxMenubar]: !stateBoolSidebar }, { [styles.moveLeftBoxMenubar]: stateBoolSidebar })}>
                {/* <div className={clsx(styles.BoxMenubar)}> */}
                <MenuIcon className={clsx(styles.iconMenu)} onClick={toggleSidebar} />
            </div>
            <div className={clsx(styles.component_Sidebar, { [styles.moveLeftSidebar]: !stateBoolSidebar }, { [styles.moveRightSidebar]: stateBoolSidebar })}>
                <div className={clsx(styles.rowTop)}>
                    <div className={clsx(styles.boxIconMenu)}>
                        <MenuIcon className={clsx(styles.iconMenu)} onClick={toggleSidebar} />
                    </div>
                </div>
                <ul>
                    {stateSidebarItems && stateSidebarItems.map((obj) => {
                        // Check if the title is "HÓA ĐƠN" and if the email is in the emailPgsaru array
                        if (obj.title === "HÓA ĐƠN" && !emailPgsaru.includes(userExist.email)) {
                            return null; // Do not render the item if the condition is not met
                        }

                        return (
                            <li key={obj.id}>
                                <div className={clsx(styles.rowSidebarItem, { [styles.selected]: obj.status })} onClick={() => sidebarItemClick(obj.id)}>
                                    <Link to={obj.path} className={clsx(styles.boxTitle, { [styles.selected]: obj.status })}>
                                        <span className={clsx(styles.title)}>{obj.title}</span>
                                    </Link>
                                </div>
                            </li>
                        );
                    })}

                </ul>

            </div>
            <div className={clsx(styles.backShadow, { [styles.appear]: stateBoolSidebar })} onClick={toggleSidebar}>
            </div>
        </>
    );
})
