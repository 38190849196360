import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import styles from './Cskh.module.scss'
import collectionAPI from '../../../API/collectionAPI';
import Tooltip from '@mui/material/Tooltip';
import {
    isDateToday,
    isTimeSoonerThanNow,
    validatePhoneNumber,
    validateTienRut,
    validateTienship,
    calculateFutureDate,
    validate_phirutdao,
} from '../../share/share.validation';
import {
    formatDateTime,
    searchPhone,
    wait,
    getFirstAndLastDayOfYearParameter,
    splitMonthYear,
    getFirstAndLastDayOfMonthOfYear,
    extractTimeFromDateString,
    combine_yyyy_mm_dd_hh_mm_plus7,
    addOneDay,
    getFirstAndLastDayOfYear,
    getFirstAndLastDayOfMonth,
    getStartAndEndOfWeek,
    getCurrentDateYYYYMMDD,
    getCurrentDateTimeVietNam,
    formatDate,
    extractYYYY_MM_DD,
    formatPhoneNumber,
    formatThoudsand,
    formatDateString,
} from '../../share/sharedFunction';
import { arr_vietnam } from '../../share/arr_vietnam';
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import * as XLSX from 'xlsx';
import CloseIcon from '@mui/icons-material/Close';
// 2 cái interface này dùng để write vào file excel
interface PrintObj1 {
    STT: string;
    Tên_nhân_viên: string;
    Tên_khách_hàng: string;
    SĐT: string;
    NguồnKH: string;
    Nhu_cầu: string;
    Ngân_hàng: string;
    Loại_thẻ: string;
    Số_tiền_rút_đáo: string;
    Phí_rút_đáo: string;
    Thành_tiền: string;
    Phí_ship: string;
    Trạng_thái: string;
    Ghi_chú: string;
}
interface PrintObj2 {
    STT: string;
    Tên_nhân_viên: string;
    Tên_khách_hàng: string;
    SĐT: string;
    NguồnKH: string;
    Nhu_cầu: string;
}
function CskhThongTin() {

    // export excel start------------------------------------------------

    const [stateString_nguonKH_export, setStateString_nguonKH_export] = useState<string | "">("Tất cả");
    const handleChange_nguonKH_tonghop_export = (e: any) => {
        const value = e.target.value;
        setStateString_nguonKH_export(value)
    }
    const [stateString_time_export, setStateString_time_export] = useState<string | "">("Hôm nay");
    const handleChange_time = (e: any) => {
        const value = e.target.value;
        setStateString_time_export(value);
    }
    const managementGroup = [
        "admin", "BGĐ"
    ]
    const listMonthYear = [
        "12-2023",
        "01-2024",
        "02-2024",
        "03-2024",
        "04-2024"
    ]
    const btnExportToExcel = async (fileName: string) => {
        // alert("Tính năng này chỉ có BGĐ được sử dụng");
        if(stateString_time_export === "" || stateString_nguonKH_export === ""){
            alert("Bạn vui lòng chọn nguồn khách hàng và thời gian để có cơ sở xuất ra file excel !");
            return;
        }
        const token = checkAlreadySignIn1();

        let start = ""
        let end = ""
        if(stateString_time_export === "Hôm nay"){
            start = getCurrentDateYYYYMMDD();
            end = addOneDay(start);
        }
        else if (stateString_time_export === "Tuần này"){
            let obj = getStartAndEndOfWeek();
            start = obj.startOfWeek;
            end = obj.endOfWeek;
        }
        else if (stateString_time_export === "Tháng này"){
            let obj = getFirstAndLastDayOfMonth();
            start = obj.firstDayOfMonth;
            end = obj.lastDayOfMonth;
        }
        else if (stateString_time_export === "Năm nay"){
            let obj = getFirstAndLastDayOfYear();
            start = obj.firstDayOfYear;
            end = obj.lastDayOfYear;
        }
        
        else if (listMonthYear.includes(stateString_time_export)){
            const {month, year} = splitMonthYear(stateString_time_export)
            const {firstDay, lastDay} = getFirstAndLastDayOfMonthOfYear(month, year);

            start = firstDay;
            end = lastDay;
        }
        else if(stateString_time_export === "2023" || stateString_time_export === "2024"){
            const {firstDay, lastDay} = getFirstAndLastDayOfYearParameter(stateString_time_export);
            start = firstDay;
            end = lastDay;
        }

        let response = await collectionAPI.collectionAPI.findGiaodichRecordsByDateRangeAndNguonKH(token, start, end, stateString_nguonKH_export);
        const arrAllGiaodichsByTime = response.data.data;
        // console.log('arrAllGiaodichsByTime: ', arrAllGiaodichsByTime);

        let response2 = await collectionAPI.collectionAPI.findGiaodichRecordsByDateRange_succeed(token, start, end, stateString_nguonKH_export);
        const arrGroupByEmail = response2.data.data;
        // console.log('arrGroupByEmail: ', arrGroupByEmail);



        let arr = [
        ];
        for (let i = 0; i < arrAllGiaodichsByTime.length; i++){
            let obj = arrAllGiaodichsByTime[i];
            // console.log('obj: ', obj);
            let objectToPrint: PrintObj1 = {
                STT: '',
                Tên_nhân_viên: '',
                Tên_khách_hàng: "",
                SĐT: '',
                NguồnKH: "",
                Nhu_cầu: "",
                Ngân_hàng: "",
                Loại_thẻ: "",
                Số_tiền_rút_đáo: "",
                Phí_rút_đáo: "",
                Thành_tiền: "",
                Phí_ship: "",
                Trạng_thái: "",
                Ghi_chú: ""
            };
            objectToPrint['STT'] = (i+1).toString();
            objectToPrint['Tên_nhân_viên'] = obj.fullNameNVCSKHcreate;
            objectToPrint['Tên_khách_hàng'] = obj.khachhang.fullNameKH;
            objectToPrint['SĐT'] = obj.khachhang.phone[0].phone;
            objectToPrint['NguồnKH'] = obj.khachhang.nguonKH;
            objectToPrint['Nhu_cầu'] = obj.nhucau;
            objectToPrint['Ngân_hàng'] = obj.nganhangId;
            objectToPrint['Loại_thẻ'] = obj.loaiThe;
            objectToPrint['Số_tiền_rút_đáo'] = obj.tienrutdao.toLocaleString('en-US');
            objectToPrint['Phí_rút_đáo'] = obj.phirutdao;
            objectToPrint['Thành_tiền'] = obj.tienphi.toLocaleString('en-US');
            objectToPrint['Phí_ship'] = obj.tienship;
            objectToPrint['Trạng_thái'] = obj.teamrutdaothongbaotrangthai;
            objectToPrint['Ghi_chú'] = obj.ghichucskh;
            arr.push(objectToPrint);
        }  
        // 2 dòng trống ở dưới
        arr.push(
            // { STT: ''},
            { STT: "", Tên_nhân_viên: '', Tên_khách_hàng: "", SĐT: '',NguồnKH: "", Nhu_cầu:""},
            { STT: "", Tên_nhân_viên: '', Tên_khách_hàng: "", SĐT: '',NguồnKH: "", Nhu_cầu:""},
            { STT: "TỔNG HỢP", Tên_nhân_viên: '', Tên_khách_hàng: "", SĐT: '',NguồnKH: "", Nhu_cầu:""},
            { STT: "STT", Tên_nhân_viên: 'Tên nhân viên', Tên_khách_hàng: "Doanh thu", SĐT: 'Phí thu được',NguồnKH: "Tổng KH LH thành công", Nhu_cầu:"Phí khác"},
        )
        let doanhThu = 0;
        let phiThuDuoc = 0;
        let phiKhac = 0;
        for (let i = 0; i < arrGroupByEmail.length; i++){
            let obj = arrGroupByEmail[i];
            // console.log('obj: ', obj);
            let objectToPrint: PrintObj2 = {
                STT: '',
                Tên_nhân_viên: '',
                Tên_khách_hàng: "",
                SĐT: '',
                NguồnKH: "",
                Nhu_cầu: "",
            };
            objectToPrint['STT'] = (i+1).toString();
            objectToPrint['Tên_nhân_viên'] = obj.fullNameNV;
            objectToPrint['Tên_khách_hàng'] = obj.tienrutdao.toLocaleString('en-US');
            objectToPrint['SĐT'] = obj.tienphi.toLocaleString('en-US');
            objectToPrint['NguồnKH'] = obj.count;
            objectToPrint['Nhu_cầu'] = obj.tienship;
            arr.push(objectToPrint);

            doanhThu += obj.tienrutdao;
            phiThuDuoc += obj.tienphi;
            phiKhac += obj.tienship;
        }  
        // tính toán dòng tổng cuối cùng
        let objectToPrint: PrintObj2 = {
            STT: '',
            Tên_nhân_viên: '',
            Tên_khách_hàng: "",
            SĐT: '',
            NguồnKH: "",
            Nhu_cầu: "",
        };
        objectToPrint['STT'] = "";
        objectToPrint['Tên_nhân_viên'] = "Tổng cộng";
        objectToPrint['Tên_khách_hàng'] = doanhThu.toLocaleString('en-US');;
        objectToPrint['SĐT'] = phiThuDuoc.toLocaleString('en-US');
        objectToPrint['NguồnKH'] = "";
        objectToPrint['Nhu_cầu'] = phiKhac.toLocaleString('en-US');
        arr.push(objectToPrint);

        arr.push(
            { STT: "", Tên_nhân_viên: "", Tên_khách_hàng: "", SĐT: '',NguồnKH: "", Nhu_cầu:""},
            { STT: "Nguồn KH", Tên_nhân_viên: stateString_nguonKH_export, Tên_khách_hàng: "", SĐT: '',NguồnKH: "", Nhu_cầu:""},
            { STT: "Bắt đầu", Tên_nhân_viên: start, Tên_khách_hàng: "", SĐT: '',NguồnKH: "", Nhu_cầu:""},
            { STT: "Kết thúc", Tên_nhân_viên: end, Tên_khách_hàng: "", SĐT: '',NguồnKH: "", Nhu_cầu:""}
        )
        // console.log('arr: ', arr);
        const ws = XLSX.utils.json_to_sheet(arr); // convert json to sheet
        const wb = XLSX.utils.book_new(); // tạo file excel hoàn toàn mới
        XLSX.utils.book_append_sheet(wb, ws, `${start}__${end}`); // thêm sheet vào file excel
        XLSX.writeFile(wb, `${fileName}.xlsx`); // tạo tên file excel
    }
    // export excel end------------------------------------------------

    // kiểm tra đã sign in chưa ------------------------------------------start
    const [stateBool_isSignedIn, setStateBool_isSignedIn] = useState(false); // boolean   
    const [stateObj_signIn, setStateObj_signIn] = useState<Record<string, any>>({
        token: "",
        email: "",
        fullName: "",
        role: ""
    })
    const checkAlreadySignIn1 = () => { // kiểm tra xem user đã sign in step 1 chưa
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist !== null){
            setStateBool_isSignedIn(true);
            setStateObj_signIn(userExist);
            setStateString_tennhanvien(userExist.fullName)
            setStateString_emailnhanvien(userExist.email)
        }
        else {
            setStateBool_isSignedIn(false);
        }
        return userExist.token
    }

    useEffect(() => {
        checkAlreadySignIn1();
    },[]);
    // kiểm tra đã sign in chưa ------------------------------------------end

// KHU VỰC GỌI API ----------------------------------------------------start
    const [stateArrNganhang, setStateArrNganhang] = useState<any[]>([]);
    const findAllNganhang = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        const token = userExist.token;
        const tenNhanVien = userExist.fullName;
        setStateString_tennhanvien(tenNhanVien);

        if(userExist !== null){
            try {
                let response = await collectionAPI.collectionAPI.findAllNganhang(token);
                setStateArrNganhang(response.data);
            } catch (err) {
                // console.log('err:', err);
            }
        }
    }
    const [stateArrNguonkh, setStateArrNguonkh] = useState<any[]>([]);
    const findAllNguonkh = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist !== null){
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI.findAllNguonKH(token);
                let arr = response.data;
                arr.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
                setStateArrNguonkh(arr);
            } catch (err) {
                // console.log('err:', err);
            }
        }
    }

    const [stateArrGiaodich, setStateArrGiaodich] = useState<any[]>([]);
    const [stateArrGiaodichShow1, setStateArrGiaodichShow1] = useState<any[]>([]);
    const [stateArrGiaodichShow2, setStateArrGiaodichShow2] = useState<any[]>([]);
    const findAllGiaodich = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist !== null){
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI.findAllGiaodich(token);
                setStateArrGiaodich(response.data);
                setStateArrGiaodichShow1(response.data); // để search
                // console.log('response.data: ', response.data);
            } catch (err) {
                // console.log('err:', err);
            }
        }
    }

    const [stateArrKhachHang, setStateArrKhachHang] = useState<any[]>([]);
    const [stateArrKhachHangShow1, setStateArrKhachHangShow1] = useState<any[]>([]);
    const [stateArrKhachHangShow2, setStateArrKhachHangShow2] = useState<any[]>([]);
    const findAllKhachHang = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist !== null){
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI.findAllkhachhang(token);
                setStateArrKhachHang(response.data);
                setStateArrKhachHangShow1(response.data); // để search
            } catch (err) {
                // console.log('err:', err);
            }
        }
    }
    useEffect(() => {
        findAllNganhang();
        findAllKhachHang();
        findAllNguonkh();
    },[]);
// KHU VỰC GỌI API ----------------------------------------------------end

    const [stateBool_isDisable_tenKhachHang, setStateBool_isDisable_tenKhachHang] = useState(false);
    const [stateBool_isDisable_nguonKH, setStateBool_isDisable_nguonKH] = useState(false);
    const [stateBool_isDisable_city, setStateBool_isDisable_city] = useState(false);
    const [stateBool_isDisable_district, setStateBool_isDisable_district] = useState(false);
    const [stateBool_isDisable_ward, setStateBool_isDisable_ward] = useState(false);
    
    // TÊN NHÂN VIÊN
    const [stateString_tennhanvien, setStateString_tennhanvien] = useState<string | "">("");
    const [stateString_emailnhanvien, setStateString_emailnhanvien] = useState<string | "">("");

    // state control việc mở đóng của dialog suggestion khi ng dùng nhập vào ô tên khách hàng và số dt
    const [stateBoolIsDialog_suggestionsCskhAppear, setStateBoolIsDialog_suggestionsCskhAppear] = useState(false);

    // TÊN KHÁCH HÀNG++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const [stateString_tenkhachhang, setStateString_tenkhachhang] = useState('');
    const [error_tenkhachhang, setError_tenkhachhang] = useState<string | null>(null); // Explicitly type the state

    const handleInputChange_tenkhachhang = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setStateString_tenkhachhang(value);
        const result = value.length < 4 || value.length > 50 || !/[a-zA-Z]/.test(value)? "[4-50] ký tự" : "✅";

        setError_tenkhachhang(result);
        // console.log('stateString_sdt: ', stateString_sdt);
        if(value.length >= 1){ // nếu bắt đầu gõ 1 ký tự trở lên thì cái khu suggesttion khách hàng cũ sẽ mở lên, xáo hết thì cũng mất suggestion
            setStateBoolIsDialog_suggestionsCskhAppear(true);
        }
        else if(value.length === 0 && stateString_sdt === "") {
            setStateBoolIsDialog_suggestionsCskhAppear(false);
        }
        let foundObjInArray = [];
        if(stateString_sdt === ""){ // nếu sdt chưa nhập thì tìm trong mảng gốc
            foundObjInArray = stateArrKhachHang.filter((obj) => obj.fullNameKH.toLowerCase().includes(value.toLowerCase()));
            setStateArrGiaodichShow1(foundObjInArray);
            setStateArrGiaodichShow2(foundObjInArray);
        }
        else { // nếu số đt đã có tức mình phải tìm trong thằng show1, tức phải search trong kết quả của thằng search của dt, chứ ko còn seach trong mảng gốc
            // console.log('stateArrGiaodichShow1: ', stateArrGiaodichShow1);
            foundObjInArray = stateArrKhachHangShow1.filter((obj) => obj.fullNameKH.toLowerCase().includes(value.toLowerCase()));
            setStateArrGiaodichShow2(foundObjInArray);
        }
    };
    // SỐ ĐIỆN THOẠI++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const [stateString_sdt, setStateString_sdt] = useState('');
    const [error_sdt, setError_sdt] = useState<string | null>(null); // Explicitly type the state
    const [error_sdt2, setError_sdt2] = useState<boolean>(false); // Explicitly type the state

    const handleInputChange_sdt = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setStateString_sdt(value);
        // setError_sdt(value.length < 4 || value.length > 50 || !/[a-zA-Z]/.test(value)? "[4-50] ký tự" : "✅");
        const isValidPhoneNumber = validatePhoneNumber(value);
        setError_sdt(isValidPhoneNumber !== null ? `✅ ${isValidPhoneNumber}` : ": Sai SĐT");
        setError_sdt2(isValidPhoneNumber !== null ? true : false);

        if(value.length >= 1){ // nếu bắt đầu gõ 1 ký tự trở lên thì cái khu suggesttion khách hàng cũ sẽ mở lên, xáo hết thì cũng mất suggestion
            setStateBoolIsDialog_suggestionsCskhAppear(true);
        }
        else if(value.length === 0 && stateString_tenkhachhang === "") { // chỉ ẩn suggest khi cả hai ten va sdt đều ko có j
            setStateBoolIsDialog_suggestionsCskhAppear(false);
        }

        if(stateString_tenkhachhang === ""){
            let foundObjInArray = searchPhone(stateArrKhachHang, value);
            // stateArrKhachHang.filter((obj) => obj.phone.phone.toLowerCase().includes(value.toLowerCase()));
            setStateArrGiaodichShow1(foundObjInArray);
            setStateArrGiaodichShow2(foundObjInArray);
        }
        else {
            // nó phải tìm tiếp trong mảng mà đã search tên khách hàng rồi
            let foundObjInArray = searchPhone(stateArrKhachHangShow1, value);
            setStateArrGiaodichShow1(foundObjInArray);
            setStateArrGiaodichShow2(foundObjInArray);
        }
    };

    // NGUỒN KHÁCH HÀNG++++++++++++++++++++++++++++++++++++++++++++++++++++++
    
    const [stateString_nguonKH, setStateString_nguonKH] = useState("Hotline BL");
    const handleChange_nguonKH = (event:any) => { 
        const value = event.target.value;
        if(value === "Chọn..."){
            alert("Nguồn Khách hàng phải được chọn theo danh sách");
        }
        // console.log('value: ', value);
        setStateString_nguonKH(value) 
    };  

    // NHU CẦU++++++++++++++++++++++++++++++++++++++++++++++++++++++
    
    const [stateString_nhucau, setStateString_nhucau] = useState("Rút tiền");
    const handleChange_nhucau = (event:any) => { 
        const value = event.target.value;
        setStateString_nhucau(value) 
    };  
    // TÊN NGÂN HÀNG++++++++++++++++++++++++++++++++++++++++++++++++++++++

    const [stateString_tennganhang, setStateString_tennganhang] = useState("");
    const handleChange_tennganhang = (event:any) => { 
        const value = event.target.value;
        // console.log('value: ', value);
        setStateString_tennganhang(value) 
    };  
    // LOẠI THẺ++++++++++++++++++++++++++++++++++++++++++++++++++++++

    const [stateString_loaithe, setStateString_loaithe] = useState("Visa");
    const handleChange_loaithe = (event:any) => { 
        const value = event.target.value;
        // console.log('value: ', value);
        setStateString_loaithe(value) 
    };
    // SỐ TIỀN RÚT / ĐÁO++++++++++++++++++++++++++++++++++++++++++++++++++++++

    const [error_tienrutdao, setError_tienrutdao] = useState<string | null>(null); // Explicitly type the state
    const [stateNumber_tienrutdao, setStateNumber_tienrutdao] = useState<number>();
    const [stateNumber_tienrutdao_makeup, setStateNumber_tienrutdao_makeup] = useState<string>("");

    const handleChange_tienrutdao = (event:any) => { 
    
        let value = event.target.value;
        value = value.replace(/[,.]/g, '');

        if(isNaN(+value) === true){ // nếu ko phải là số thì...
            alert("Bạn không được nhập chữ vào ô này !");
            let afterRemoveLastLetter = value.slice(0, -1); // Tức bỏ cái chữ cuối cùng mới nhập vào, chỉ lấy trước nó thì dụ đang nhập 456 mà nhập 456a thì nó bỏ chữ a và giữ lại 456
            setStateNumber_tienrutdao(parseInt(afterRemoveLastLetter+""));
            setStateNumber_tienrutdao_makeup(formatThoudsand(afterRemoveLastLetter));
            return;
        }

        let stringToNum_tienrutdao = parseInt(value+"");
        setStateNumber_tienrutdao(stringToNum_tienrutdao); 
        const moneyToString = formatThoudsand(value); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNumber_tienrutdao_makeup(moneyToString); // để hiện ra ô input có dấu , cho dễ xem

        const result = validateTienRut(value); 

        if(result){ // Nếu số nhập vào hợp lệ
            setStateNumber_tienrutdao(value);
            if(stateNumber_phirutdao !== undefined){
                let tienphi = (value * stateNumber_phirutdao) / 100; 
                // console.log('tienphi: ', tienphi);
                // const moneyToString = formatThoudsand(tienphi); // chuyển thành chữ có dấu , phân cách hàng nghìn
                // setStateNumber_tienphi_makeup(moneyToString); // để hiện ra ô input có dấu , cho dễ xem
                setStateNumber_tienphi(tienphi);// ko cần format, vì khi hiện ra thì mình chỉ cần to localString thì nó có , phân cách

            }
        }
        // else {
        //     setStateNumber_tienrutdao(undefined);

        // }
        setError_tienrutdao(result ? "✅" : "❌");
    };  
    // PHÍ RÚT / ĐÁO++++++++++++++++++++++++++++++++++++++++++++++++++++++


    const [stateNumber_phirutdao, setStateNumber_phirutdao] = useState<number>();
    const [error_phirutdao, setError_phirutdao] = useState<string | null>(null); // Explicitly type the state
    const [stateNumber_tienphi, setStateNumber_tienphi] = useState<number>(0);
    const [stateNumber_tienphi_makeup, setStateNumber_tienphi_makeup] = useState<string>("");

    const handleChange_phirutdao = (event:any) => { 
        const value = event.target.value; // type string
        const phirutdao = parseFloat(value); // chuyển sang số lẻ
        const result = validate_phirutdao(value);
        // console.log('result validate_phirutdao: ', result);
        if(result){
            setStateNumber_phirutdao(phirutdao);
            if(stateNumber_tienrutdao !== undefined){
                let tienphi = (stateNumber_tienrutdao * value) / 100; 
                // console.log('tienphi: ', tienphi);
                // const moneyToString = formatThoudsand(tienphi); // chuyển thành chữ có dấu , phân cách hàng nghìn
                // setStateNumber_tienphi_makeup(moneyToString); // để hiện ra ô input có dấu , cho dễ xem
                setStateNumber_tienphi(tienphi);
            }
        }
        else {
            setStateNumber_phirutdao(undefined);
        }
    
        setError_phirutdao(result ? "✅" : "❌");

    };  
    // SỐ TIỀN SHIP ++++++++++++++++++++++++++++++++++++++++++++++++++++++

    const [stateNumber_tienship, setStateNumber_tienship] = useState<number>(0);
    const [error_tienship, setError_tienship] = useState<string | null>(null); // Explicitly type the state
    const [stateNumber_tienship_makeup, setStateNumber_tienship_makeup] = useState<string>("0");

    const handleChange_tienship = (event:any) => {
        let value = event.target.value;
        value = value.replace(/[,.]/g, '');

        if(isNaN(+value) === true){ // nếu ko phải là số thì...
            alert("Bạn không được nhập chữ vào ô này !");
            let afterRemoveLastLetter = value.slice(0, -1); // Tức bỏ cái chữ cuối cùng mới nhập vào, chỉ lấy trước nó thì dụ đang nhập 456 mà nhập 456a thì nó bỏ chữ a và giữ lại 456
            setStateNumber_tienship(parseInt(afterRemoveLastLetter+""));
            setStateNumber_tienship_makeup(formatThoudsand(afterRemoveLastLetter));
            return;
        }

        let stringToNum_tienship = parseInt(value+"");
        setStateNumber_tienship(stringToNum_tienship);
        const moneyToString = formatThoudsand(value); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNumber_tienship_makeup(moneyToString); // để hiện ra ô input có dấu , cho dễ xem

        const result = validateTienship(value);
        
        setError_tienship(result ? "✅" : "❌");
    };
    // LIÊN HỆ LẠI
    const [stateString_lienhelai, setStateString_lienhelai] = useState<any>(
        calculateFutureDate(0) 
        // ""
    );
    
    const [stateString_giolienhelai, setStateString_giolienhelai] = useState<any>('00:00'); // string
    const handleChange_lienhelai = (event:any) => { 
        // console.log('event.target.value: ', event.target.value);
        setStateString_lienhelai(event.target.value) 
    }; 
    const handleChange_giolienhelai = (event:any) => { 
        const value = event.target.value;
        if(value === '00:00'){
            alert("Giờ không được để trống");
            return;
        }
        setStateString_giolienhelai(value) 
    }; 
        // console.log('stateString_giolienhelai: ', stateString_giolienhelai);
    // HẠN THANH TOÁN
    const [stateString_hanthanhtoan, setStateString_hanthanhtoan] = useState<any>(
        // ""
        calculateFutureDate(22)
        );
    const handleChange_hanthanhtoan = (event:any) => { 
        // console.log('event.target.value: ', event.target.value);
        setStateString_hanthanhtoan(event.target.value) 
    }; 

    // TRẠNG THÁI ++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const [stateString_trangthai, setStateString_trangthai] = useState("Đã chuyển sang team Rút đáo");
    const handleChange_trangthai = (event:any) => { setStateString_trangthai(event.target.value) }; 

    // ghi chú Giaodich
    const [stateString_ghichu, setStateString_ghichu] = useState('');
    const handlechange_ghichu = (event:any) => {
        setStateString_ghichu(event.target.value);
    };
    // Chi nhánh
    const [stateString_chinhanh, setStateString_chinhanh] = useState('empty');
    const handleChiNhanh = (event:any) => {
        const value = event.target.value;
        if(value === 'empty'){
            alert("Chi nhánh không được để trống");
            return;
        }
        setStateString_chinhanh(event.target.value);
    };
    // loại khách hàng
    const [stateString_loaigiaodich, setStateString_loaigiaodich] = useState('Giao dịch thật');
    const handleLoaiKhachhang = (event:any) => {
        const value = event.target.value;
        setStateString_loaigiaodich(value);
    };
    // loại khách hàng
    const [stateString_diachiship, setStateString_diachiship] = useState('');
    const handleInputChange_diachiship = (event:any) => {
        const value = event.target.value;
        setStateString_diachiship(value);
    };
    
    // city, district, ward start---------------------------------------------------------------------------------

    const [nameCitySelected, setNameCitiSelected] = useState(''); //chứa tên city khi mình chọn trong ô select option
    const [citiesOptions, setCitiesOptions] = useState<any[]>([]); //Tạo ra dánh sách các option để xổ ra
 
    const [nameDistrictSelected, setNameDistrictSelected] = useState('');
    const [districtsOptions, setDistrictsOptions] = useState<any[]>([]);
 
    const [nameWardSelected, setNameWardSelected] = useState('');
    const [wardsOptions, setWardsOptions] = useState<any[]>([]);

    const handleCity = (e: any) => {

        if(e.target.value === "empty"){
            alert("City can not be empty !");
        }else {

        setNameCitiSelected(e.target.value);
        
        fullfillDistrictSelectOptions(e.target.value);
        }
    }

    const handleDistrict = (e: any) => {
        setNameDistrictSelected(e.target.value); // e.target.value là giá trị khi mình chọn trong ô select option
        fullfillWardsSelectOptions(e.target.value);
    }

    const handleAddress = (e: any) => {
    
        setNameWardSelected(e.target.value);
    
    }
    const handleWard = (e: any) => {
    
        setNameWardSelected(e.target.value);
    
    }
    const fullfillCitySelectOptions = () => {
        let arr: any = []
        for (let i = 0, len = arr_vietnam.length; i < len; i++){
            arr.push(
                <option key={Math.random()} value={arr_vietnam[i].name}>{arr_vietnam[i].name}</option>
            )
        }  
        setCitiesOptions(arr); //set state cho nó là tập hợp các option để mình chèn vào select lúc render ra
    }
    
    const fullfillDistrictSelectOptions = (cityName:string) => {
    let arr: any = []
    // console.log('cityName: ', cityName);
    
    const indexCity = arr_vietnam.findIndex((obj) => obj.name === cityName);// tìm index của city
    // console.log("indexCity: " + indexCity);
    // console.log(arr_vietnam[indexCity].districts);

    let arrDistricts = arr_vietnam[indexCity].districts

    for (let i = 0; i < arrDistricts.length; i++){
        arr.push(
            <option key={Math.random()} value={arrDistricts[i].name}>{arrDistricts[i].name}</option>
        )
    } 

    setDistrictsOptions(arr);
    }
    const fullfillWardsSelectOptions = (districtName:string) => {
        let arr: any = []
        // console.log('districtName: ', districtName);
        const indexCity = arr_vietnam.findIndex((obj) => obj.name === nameCitySelected);// tìm index của city
        const indexDistrict = arr_vietnam[indexCity].districts.findIndex((obj) => obj.name === districtName);// tìm index của city

        let arrWards = arr_vietnam[indexCity].districts[indexDistrict].Wards

        for (let i = 0; i < arrWards.length; i++){
            arr.push(
                // `$ {id}` ép id thành string
                //@ts-ignore
                <option key={Math.random()} value={arrWards[i].name}>{arrWards[i].name}</option>
            )
        } 
        setWardsOptions(arr);
    }
    useEffect(() => {
        fullfillCitySelectOptions();
    },[]);

    // city, district, ward end---------------------------------------------------------------------------------
    const [stateObj_khachhang, setStateObj_khachhang] = useState<Record<string, any>>({// dùng để chứa object cskh khi post đến backend
        "editedAt": null,
        "emailNVCSKHCreate": "",
        "fullNameNVCSKHcreate": "",
        "emailNVCSKHEdit": "",
        "fullNameKH": "",
        "phone": "",
        "nguonKH": "",
        "city": "",
        "district": "",
        "ward": "",
        "address": "",
        "cccd": "",
        "ntns": null,
    });
    const [stateObj_khachhang_edit, setStateObj_khachhang_edit] = useState<Record<string, any>>({// dùng để chứa object cskh khi post đến backend
        "editedAt": null,
        "emailNVCSKHCreate": "",
        "fullNameNVCSKHcreate": "",
        "emailNVCSKHEdit": "",
        "fullNameKH": "",
        "phone": "",
        "nguonKH": "",
        "city": "",
        "district": "",
        "ward": "",
        "address": "",
        "cccd": "",
        "ntns": null,
    });
    const [stateObj_phone, setStateObj_phone] = useState<Record<string, any>>({// dùng để chứa object cskh khi post đến backend
        "idKhachhang": 0,
        "phone": "",
    });
    const [stateObj_the, setStateObj_the] = useState<Record<string, any>>({// dùng để chứa object cskh khi post đến backend
        "idKhachhang": 0,
        "idGiaodich": 0,
        "loaiThe": "",
        "tenTrenThe": "",
        "soThe": "",
        "soTaiKhoanNganHang": "",
        "soTaiKhoanTheTinDung": "",
        "hanMucTheTinDung": 0,
    });
    const [stateObj_Giaodich, setStateObj_Giaodich] = useState<Record<string, any>>({// dùng để chứa object giaodich khi post đến backend
        "idKhachhang": 0,
        "createdAt": null,
        "editedAt": null,
        "emailNVCSKHCreate": "",
        "fullNameNVCSKHcreate": "",
        "emailNVCSKHEdit": "",
        "emailNVRutdaoCreate": "",
        "fullNameNVRutdaoCreate": "",
        "emailNVRutdaoEdit": "",
        "nhucau": "",
        "nganhangId": "",
        "loaiThe": "",
        "tienrutdao": 0,
        "phirutdao": 0,
        "tienphi": 0,
        "tienship": 0,
        "tenchinhanh": "",
        "ngaydukienlienhelai": null,
        "ngayhanthanhtoan": null,
        "trangthaiCSKH": "",
        "ghichurutdao": "",
        "ghichucskh": "",
        "nhanvienship": "",
        "diemdenship": "",
        "ngayrutdao": null,
        "loaigiaodich": "",
        "teamrutdaoreceivedAt": null,
        "teamrutdaothongbaotrangthai": "Chờ",
        "teamrutdaothongbaotrangthaiAt": null,
        "lydocuatrangthai": "",
        "noiDungChuyenTien": "",
        "tienShipNhanVien": 0,
    });
    const [stateObj_Giaodich_edit, setStateObj_Giaodich_edit] = useState<Record<string, any>>({// dùng để chứa object giaodich khi post đến backend
        "idKhachhang": "",
        "createdAt": null,
        "editedAt": null,
        "emailNVCSKHCreate": "",
        "fullNameNVCSKHcreate": "",
        "emailNVCSKHEdit": "",
        "emailNVRutdaoCreate": "",
        "fullNameNVRutdaoCreate": "",
        "emailNVRutdaoEdit": "",
        "nhucau": "",
        "nganhangId": "",
        "loaiThe": "",
        "tienrutdao": 0,
        "phirutdao": 0,
        "tienphi": 0,
        "tienship": 0,
        "tenchinhanh": "",
        "ngaydukienlienhelai": null,
        "ngayhanthanhtoan": null,
        "trangthaiCSKH": "",
        "ghichurutdao": "",
        "ghichucskh": "",
        "nhanvienship": "",
        "diemdenship": "",
        "ngayrutdao": null,
        "loaigiaodich": "",
        "teamrutdaoreceivedAt": null,
        "teamrutdaothongbaotrangthai": "Chờ",
        "teamrutdaothongbaotrangthaiAt": null,
        "lydocuatrangthai": "",
        "noiDungChuyenTien": "",
        "tienShipNhanVien": 0,
    });

    const inputRef_tenkhachhang = useRef<HTMLInputElement | null>(null);
    const inputRef_sdt = useRef<HTMLInputElement | null>(null);
    const inputRef_tienrutdao = useRef<HTMLInputElement | null>(null);
    const inputRef_phirutdao = useRef<HTMLInputElement | null>(null);
    const inputRef_tienship = useRef<HTMLInputElement | null>(null);
    const create = async () => {
        
        // console.log('stateString_tennhanvien: ', stateString_tennhanvien);

        //validate tên khách hàng
        if(error_tenkhachhang !== "✅"){ // nếu input ten khach hang xai thì focus về lại ô đó
            if (inputRef_tenkhachhang.current) {
                setError_tenkhachhang("❌")
                inputRef_tenkhachhang.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }
        else {
            setError_tenkhachhang("✅");
        }
        // console.log('stateString_tenkhachhang: ', stateString_tenkhachhang);
        //validate sdt
        if(error_sdt2 !== true){ // nếu input  xai thì focus về lại ô đó
            if (inputRef_sdt.current) {
                inputRef_sdt.current.focus();
                setError_sdt("❌")
            }
            return; // stop luôn ko cho chạy tiếp
        }

        //validate tiền rút đáo
        if(stateNumber_tienrutdao === undefined){ // nếu input xai thì focus về lại ô đó
            if (inputRef_tienrutdao.current) {
                setError_tienrutdao("❌");
                inputRef_tienrutdao.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }
        else {
            setError_tienrutdao("✅");
        }
        // console.log('stateNumber_tienrutdao: ', stateNumber_tienrutdao);
        //validate phí rút đáo
        if(stateNumber_phirutdao === undefined && stateNumber_tienrutdao > 5000000){ // nếu input xai thì focus về lại ô đó
            if (inputRef_phirutdao.current) {
                setError_phirutdao("❌");
                inputRef_phirutdao.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }
        else {
            setError_phirutdao("✅");
        }


        // validate tên ngân hàng
        if(stateString_tennganhang === ""){
            alert("Bạn vui lòng chọn tên ngân hàng !");
            return;
        }
        const obj = stateArrNganhang.find((obj) => obj.id === stateString_tennganhang); 
        if(obj === undefined || obj === null){
            alert("Tên ngân hàng bạn nhập không có trong hệ thống. Bạn vui lòng chọn lại ngân hàng");
            return;
        }
        
        // validate tenchinhanh
        if(stateString_chinhanh === "empty"){ // nếu input xai thì alert
            alert("Bạn vui lòng chọn tên Chi nhánh !");
            return; // stop luôn ko cho chạy tiếp
        }
        
        // validate tiền ship: tiền ship có thể null (khách đến cty trực tiếp), nhưng một khi có giá trị thì phải validate
        if(error_tienship === "❌"){ // nếu input xai thì alert
            if (inputRef_tienship.current) {
                setError_tienship("❌");
                inputRef_tienship.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }

        const now = getCurrentDateTimeVietNam()

        // validate giờ liên hệ lại
        if(stateString_nhucau === "Rút tiền"){
            // console.log('stateString_lienhelai: ', stateString_lienhelai); // yyyy-mm-dd
            if(isDateToday(stateString_lienhelai)) { // nếu ô input là ngày hôm nay thì phải check xem giờ có trước giờ hiện tại ko
                if(isTimeSoonerThanNow(stateString_giolienhelai)){
                    alert("Giờ dự kiến liên hệ lại phải bằng giờ hiện tại hoặc trễ hơn");
                    return;
                }
            }
        }

        // validate ngayhanthanhtoan
        let hanhthanhtoan = formatDate(stateString_hanthanhtoan); // đầu ra  2023-01-19T00:00:00Z hoặc null

        // khi nhu cầu là "Rút tiền" thì hạn thanh toán ko cần nên gán null cho nó, làm vầy cho nó chắc
        if(stateString_nhucau === "Rút tiền"){
            hanhthanhtoan = null;
        }
    
        const ngayGio_lienhelai = combine_yyyy_mm_dd_hh_mm_plus7(stateString_lienhelai, stateString_giolienhelai);
        
        let tienphi = Math.round(stateNumber_tienphi); // làm tròn số. ko hiểu sao js lúc tính toán nó lại ra lẻ
        // trường hợp số tiền rút đáo <= 5tr (visa, master) Tiền phí là 100,000 ko cần quan tâm phí rút đáo
        if(stateString_loaithe === "Visa" || stateString_loaithe === "Master card"){
            if(stateNumber_tienrutdao <= 5000000){
                tienphi = 100000;
            }
        }
        if(stateString_loaithe === "JCB" && stateNumber_tienrutdao <= 5000000){
            tienphi = 125000;
        }

        // validate phí rút đáo nếu chi nhánh là Bảo Lộc
        if (stateString_chinhanh === "Bảo Lộc" && stateNumber_tienrutdao <= 5000000){
            tienphi = 125000;
        }
        const objKhachhang = {
            "createdAt": now,
            "editedAt": null,
            "emailNVCSKHCreate": stateString_emailnhanvien,
            "fullNameNVCSKHcreate": stateString_tennhanvien,
            "emailNVCSKHEdit": "",
            "fullNameKH": stateString_tenkhachhang,
            "nguonKH": stateString_nguonKH,
            "city": nameCitySelected,
            "district": nameDistrictSelected,
            "ward": nameWardSelected,
            "address": "",
            "cccd": "",
            "ntns": null,
        }
        const objGiaodich = {
            "idKhachhang": 0,
            "createdAt": now,
            "editedAt": now,
            "emailNVCSKHCreate": stateString_emailnhanvien,
            "fullNameNVCSKHcreate": stateString_tennhanvien,
            "emailNVCSKHEdit": "",
            "emailNVRutdaoCreate": "",
            "fullNameNVRutdaoCreate": "",
            "emailNVRutdaoEdit": "",
            "nhucau": stateString_nhucau,
            "nganhangId": stateString_tennganhang,
            "tienrutdao": parseInt(stateNumber_tienrutdao+""),
            "phirutdao": stateNumber_phirutdao,
            "tienphi": tienphi,
            "tienship": parseInt(stateNumber_tienship+""),
            "tenchinhanh": stateString_chinhanh,
            "ngaydukienlienhelai": ngayGio_lienhelai,
            "ngayhanthanhtoan": hanhthanhtoan,
            "trangthaiCSKH": stateString_trangthai,
            "ghichurutdao": "",
            "ghichucskh": stateString_ghichu,
            "nhanvienship": "",
            "diemdenship": stateString_diachiship,
            "ngayrutdao": null,
            "loaigiaodich": stateString_loaigiaodich,
            "teamrutdaoreceivedAt": null,
            "teamrutdaothongbaotrangthai": "Chờ",
            "teamrutdaothongbaotrangthaiAt": null,
            "lydocuatrangthai": "",
            "noiDungChuyenTien": "",
            "tienShipNhanVien": 0,
        }
        const objPhone = {
            "idKhachhang": 0,
            "phone": stateString_sdt,
        }
        const objThe = {
            "idKhachhang": 0,
            "idGiaodich": 0,
            "loaiThe": stateString_loaithe,
            "tenTrenThe": "",
            "soThe": "",
            "soTaiKhoanNganHang": "",
            "soTaiKhoanTheTinDung": "",
            "hanMucTheTinDung": 0,
        }

        // console.log('objKhachhang: ', objKhachhang);
        // console.log('objGiaodich: ', objGiaodich);
        setStateIsDialogFinalCheckCskhAppear(true); // cho hiện dialog lên để final check
        setStateObj_khachhang(objKhachhang);
        setStateObj_Giaodich(objGiaodich);
        setStateObj_phone(objPhone);
        setStateObj_the(objThe);

    }
    const capNhatDuLieuKHCuVaTaoGiaoDichMoi = () => {
    
        
        //validate tên khách hàng
        if(error_tenkhachhang !== "✅"){ // nếu input ten khach hang xai thì focus về lại ô đó
            if (inputRef_tenkhachhang.current) {
                setError_tenkhachhang("❌")
                inputRef_tenkhachhang.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }
        else {
            setError_tenkhachhang("✅");
        }
        // console.log('stateString_tenkhachhang: ', stateString_tenkhachhang);
        //validate sdt
        if(error_sdt2 !== true){ // nếu input  xai thì focus về lại ô đó
            if (inputRef_sdt.current) {
                inputRef_sdt.current.focus();
                setError_sdt("❌")
            }
            return; // stop luôn ko cho chạy tiếp
        }

        //validate tiền rút đáo
        if(stateNumber_tienrutdao === undefined){ // nếu input xai thì focus về lại ô đó
            if (inputRef_tienrutdao.current) {
                setError_tienrutdao("❌");
                inputRef_tienrutdao.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }
        else {
            setError_tienrutdao("✅");
        }
        // console.log('stateNumber_tienrutdao: ', stateNumber_tienrutdao);
        //validate phí rút đáo
        if(stateNumber_phirutdao === undefined && stateNumber_tienrutdao >= 5000000){ // nếu input xai thì focus về lại ô đó
            if (inputRef_phirutdao.current) {
                setError_phirutdao("❌");
                inputRef_phirutdao.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }
        else {
            setError_phirutdao("✅");
        }
        
        // validate tenchinhanh
        if(stateString_chinhanh === "empty"){ // nếu input xai thì alert
            alert("Chi nhánh không được để trống");
            return; // stop luôn ko cho chạy tiếp
        }
        
        // validate tiền ship: tiền ship có thể null (khách đến cty trực tiếp), nhưng một khi có giá trị thì phải validate
        if(error_tienship === "❌"){ // nếu input xai thì alert
            if (inputRef_tienship.current) {
                setError_tienship("❌");
                inputRef_tienship.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }

        // validate tên ngân hàng
        if(stateString_tennganhang === ""){
            alert("Bạn vui lòng chọn tên ngân hàng !");
            return;
        }
        const obj = stateArrNganhang.find((obj) => obj.id === stateString_tennganhang); 
        if(obj === undefined || obj === null){
            alert("Tên ngân hàng bạn nhập không có trong hệ thống. Bạn vui lòng chọn lại ngân hàng");
            return;
        }

        // validate giờ liên hệ lại
        if(stateString_nhucau === "Rút tiền"){
            // console.log('stateString_lienhelai: ', stateString_lienhelai); // yyyy-mm-dd
            if(isDateToday(stateString_lienhelai)) { // nếu ô input là ngày hôm nay thì phải check xem giờ có trước giờ hiện tại ko
                if(isTimeSoonerThanNow(stateString_giolienhelai)){
                    alert("Giờ dự kiến liên hệ lại phải bằng giờ hiện tại hoặc trễ hơn");
                    return;
                }
            }
        }

        let hanhthanhtoan = formatDate(stateString_hanthanhtoan); // đầu ra  2023-01-19T00:00:00Z hoặc null

        // khi nhu cầu là "Rút tiền" thì hạn thanh toán ko cần nên gán null cho nó, làm vầy cho nó chắc
        if(stateString_nhucau === "Rút tiền"){
            hanhthanhtoan = null;
        }

        const now = getCurrentDateTimeVietNam()

        // const ngayGio_lienhelai1 = `${stateString_lienhelai}T${stateString_giolienhelai}:00Z`
        const ngayGio_lienhelai = combine_yyyy_mm_dd_hh_mm_plus7(stateString_lienhelai, stateString_giolienhelai);
        
        let tienphi = Math.round(stateNumber_tienphi); // làm tròn số. ko hiểu sao js lúc tính toán nó lại ra lẻ
        // trường hợp số tiền rút đáo < 5tr (visa, master) Tiền phí là 100,000 ko cần quan tâm phí rút đáo
        if(stateString_loaithe === "Visa" || stateString_loaithe === "Master card"){
            if(stateNumber_tienrutdao <= 5000000){
                tienphi = 100000;
            }
        }
        if(stateString_loaithe === "JCB" && stateNumber_tienrutdao <= 5000000){
            tienphi = 125000;
        }

        // validate phí rút đáo nếu chi nhánh là Bảo Lộc
        if (stateString_chinhanh === "Bảo Lộc" && stateNumber_tienrutdao <= 5000000){
            tienphi = 125000;
        }
        // thông tin khách hàng ko đc cập nhật, nếu thay số đt thì là chỉ cập nhật phần phone thôi
        let objKhachhang = {
            "editedAt": now,
            "emailNVCSKHCreate": stateString_emailnhanvien,
            "fullNameNVCSKHcreate": stateString_tennhanvien,
            "emailNVCSKHEdit": stateString_emailnhanvien,
            "fullNameKH": stateString_tenkhachhang,
            "nguonKH": stateString_nguonKH,
            "city": nameCitySelected,
            "district": nameDistrictSelected,
            "ward": nameWardSelected,
            "address": "",
            "cccd": "",
            "ntns": null,
        }
        // lúc này stateNumber_idKhachhang đã có khi mình click vào KH cũ
        const objGiaodich = {
            "idKhachhang": stateNumber_idKhachhang,
            "createdAt": now,
            "editedAt": now,
            "emailNVCSKHCreate": stateString_emailnhanvien,
            "fullNameNVCSKHcreate": stateString_tennhanvien,
            "emailNVCSKHEdit": "",
            "emailNVRutdaoCreate": "",
            "fullNameNVRutdaoCreate": "",
            "emailNVRutdaoEdit": "",
            "nhucau": stateString_nhucau,
            "nganhangId": stateString_tennganhang,
            "tienrutdao": parseInt(stateNumber_tienrutdao+""),
            "phirutdao": stateNumber_phirutdao,
            "tienphi": tienphi,
            "tienship": stateNumber_tienship,
            "tenchinhanh": stateString_chinhanh,
            "ngaydukienlienhelai": ngayGio_lienhelai,
            "ngayhanthanhtoan": hanhthanhtoan,
            "trangthaiCSKH": stateString_trangthai,
            "ghichurutdao": "",
            "ghichucskh": stateString_ghichu,
            "nhanvienship": "",
            "diemdenship": stateString_diachiship,
            "ngayrutdao": null,
            "loaigiaodich": stateString_loaigiaodich,
            "teamrutdaoreceivedAt": null,
            "teamrutdaothongbaotrangthai": "Chờ",
            "teamrutdaothongbaotrangthaiAt": null,
            "lydocuatrangthai": "",
            "noiDungChuyenTien": "",
            "tienShipNhanVien": 0,
        }
        const objPhone = {
            "idKhachhang": stateNumber_idKhachhang,
            "phone": stateString_sdt,
        }
        const objThe = {
            "idKhachhang": stateNumber_idKhachhang,
            "idGiaodich": 0,
            "loaiThe": stateString_loaithe,
            "tenTrenThe": "",
            "soThe": "",
            "soTaiKhoanNganHang": "",
            "soTaiKhoanTheTinDung": "",
            "hanMucTheTinDung": 0,
        }
        
        setStateIsDialogFinalCheckCskhAppear(true); // cho hiện dialog lên để final check
        setStateObj_khachhang_edit(objKhachhang);
        
        
        setStateObj_Giaodich_edit(objGiaodich);
        setStateObj_phone(objPhone);
        setStateObj_the(objThe);

        setStateObj_khachhang(objKhachhang);
        setStateObj_Giaodich(objGiaodich);

    }
    const capNhatDuLieuKHCuVaGiaoDichCu = async () => {

        //validate tên khách hàng
        if(error_tenkhachhang !== "✅"){ // nếu input ten khach hang xai thì focus về lại ô đó
            if (inputRef_tenkhachhang.current) {
                setError_tenkhachhang("❌")
                inputRef_tenkhachhang.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }
        else {
            setError_tenkhachhang("✅");
        }
        // console.log('stateString_tenkhachhang: ', stateString_tenkhachhang);
        //validate sdt
        if(error_sdt2 !== true){ // nếu input  xai thì focus về lại ô đó
            if (inputRef_sdt.current) {
                inputRef_sdt.current.focus();
                setError_sdt("❌")
            }
            return; // stop luôn ko cho chạy tiếp
        }

        //validate tiền rút đáo
        if(stateNumber_tienrutdao === undefined){ // nếu input xai thì focus về lại ô đó
            if (inputRef_tienrutdao.current) {
                setError_tienrutdao("❌");
                inputRef_tienrutdao.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }
        else {
            setError_tienrutdao("✅");
        }
        // console.log('stateNumber_tienrutdao: ', stateNumber_tienrutdao);
        //validate phí rút đáo
        if(stateNumber_phirutdao === undefined && stateNumber_tienrutdao >= 5000000){ // nếu input xai thì focus về lại ô đó
            if (inputRef_phirutdao.current) {
                setError_phirutdao("❌");
                inputRef_phirutdao.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }
        else {
            setError_phirutdao("✅");
        }
        
        // validate tenchinhanh
        if(stateString_chinhanh === "empty"){ // nếu input xai thì alert
            alert("Chi nhánh không được để trống");
            return; // stop luôn ko cho chạy tiếp
        }
        
        // validate tiền ship: tiền ship có thể null (khách đến cty trực tiếp), nhưng một khi có giá trị thì phải validate
        if(error_tienship === "❌"){ // nếu input xai thì alert
            if (inputRef_tienship.current) {
                setError_tienship("❌");
                inputRef_tienship.current.focus();
            }
            return; // stop luôn ko cho chạy tiếp
        }

        // validate tên ngân hàng
        if(stateString_tennganhang === ""){
            alert("Bạn vui lòng chọn tên ngân hàng !");
            return;
        }
        const obj = stateArrNganhang.find((obj) => obj.id === stateString_tennganhang); 
        if(obj === undefined || obj === null){
            alert("Tên ngân hàng bạn nhập không có trong hệ thống. Bạn vui lòng chọn lại ngân hàng");
            return;
        }

        // validate giờ liên hệ lại
        if(stateString_nhucau === "Rút tiền"){
            // console.log('stateString_lienhelai: ', stateString_lienhelai); // yyyy-mm-dd
            if(isDateToday(stateString_lienhelai)) { // nếu ô input là ngày hôm nay thì phải check xem giờ có trước giờ hiện tại ko
                if(isTimeSoonerThanNow(stateString_giolienhelai)){
                    alert("Giờ dự kiến liên hệ lại phải bằng giờ hiện tại hoặc trễ hơn");
                    return;
                }
            }
        }

        let hanhthanhtoan = formatDate(stateString_hanthanhtoan); // đầu ra  2023-01-19T00:00:00Z hoặc null

        // khi nhu cầu là "Rút tiền" thì hạn thanh toán ko cần nên gán null cho nó, làm vầy cho nó chắc
        if(stateString_nhucau === "Rút tiền"){
            hanhthanhtoan = null;
        }

        const now = getCurrentDateTimeVietNam()

        const ngayGio_lienhelai1 = `${stateString_lienhelai}T${stateString_giolienhelai}:00Z`
        const ngayGio_lienhelai = combine_yyyy_mm_dd_hh_mm_plus7(stateString_lienhelai, stateString_giolienhelai);
        
        let tienphi = Math.round(stateNumber_tienphi); // làm tròn số. ko hiểu sao js lúc tính toán nó lại ra lẻ
        // trường hợp số tiền rút đáo < 5tr (visa, master) Tiền phí là 100,000 ko cần quan tâm phí rút đáo
        if(stateString_loaithe === "Visa" || stateString_loaithe === "Master card" && stateNumber_tienrutdao < 5000000){
            tienphi = 100000;
        }
        if(stateString_loaithe === "JCB" && stateNumber_tienrutdao < 5000000){
            tienphi = 125000;
        }
        const objGiaodich = {
            "idKhachhang": 0,
            "editedAt": null,
            "emailNVCSKHCreate": stateString_emailnhanvien,
            "fullNameNVCSKHcreate": stateString_tennhanvien,
            "emailNVCSKHEdit": "",
            "emailNVRutdaoCreate": "",
            "fullNameNVRutdaoCreate": "",
            "emailNVRutdaoEdit": "",
            "nhucau": stateString_nhucau,
            "nganhangId": stateString_tennganhang,
            "loaiThe": stateString_loaithe,
            "tienrutdao": stateNumber_tienrutdao,
            "phirutdao": stateNumber_phirutdao,
            "tienphi": tienphi,
            "tienship": stateNumber_tienship,
            "tenchinhanh": stateString_chinhanh,
            "ngaydukienlienhelai": ngayGio_lienhelai,
            "ngayhanthanhtoan": hanhthanhtoan,
            "trangthaiCSKH": stateString_trangthai,
            "ghichurutdao": "",
            "ghichucskh": stateString_ghichu,
            "nhanvienship": "",
            "diemdenship": stateString_diachiship,
            "ngayrutdao": null,
            "loaigiaodich": stateString_loaigiaodich,
            "teamrutdaoreceivedAt": null,
            "teamrutdaothongbaotrangthai": "Chờ",
            "teamrutdaothongbaotrangthaiAt": null,
            "lydocuatrangthai": "",
            "noiDungChuyenTien": "",
            "tienShipNhanVien": 0,
        }
        const objPhone = {
            "idKhachhang": 0,
            "phone": stateString_sdt,
        }
        const objThe = {
            "idKhachhang": 0,
            "idGiaodich": 0,
            "loaiThe": stateString_loaithe,
            "tenTrenThe": "",
            "soThe": "",
            "soTaiKhoanNganHang": "",
            "soTaiKhoanTheTinDung": "",
            "hanMucTheTinDung": 0,
        }
        
        setStateIsDialogFinalCheckCskhAppear(true); // cho hiện dialog lên để final check
        setStateObj_Giaodich_edit(objGiaodich);
        setStateObj_phone(objPhone);
        setStateObj_the(objThe);
        //@ts-ignore
        objKhachhang.createdAt = stateString_createdAt; // thêm createdAt vào để hiện ra thôi, ko ảnh hưởng đến object post về backend, vì createdAt là tạo tự động
        
        setStateObj_Giaodich(objGiaodich);
    }

    const reset = () => {
        setStateString_tenkhachhang("");
        setError_tenkhachhang(null)
        
        setStateString_sdt("");
        setError_sdt(null)
        
        setStateNumber_tienrutdao(0);
        setError_tienrutdao(null)
        setStateNumber_tienrutdao_makeup("");

        setStateNumber_phirutdao(0);
        setError_phirutdao(null)
        
        setStateNumber_tienphi(0);
        setStateNumber_tienphi_makeup("");

        setStateNumber_tienship(0);
        setError_tienship(null)
        setStateNumber_tienship_makeup("");
        setStateString_ghichu("");

        setNameCitiSelected("");
        setNameDistrictSelected("");
        setNameWardSelected("");

        // cho các giá trị disable về false hết để người dùng có thể nhập trở lại
        setStateBool_isDisable_city(false);
        setStateBool_isDisable_district(false);
        setStateBool_isDisable_ward(false);
        setStateBool_isDisable_tenKhachHang(false);
        setStateBool_isDisable_nguonKH(false);
    }
    const refresh = () => {
        window.location.reload();
    }
    // dialog start---------------------------------------------------------------------------------
    type stateObj = { // đặt ngoài function
        [key: string]: any;
    };
    const [stateIsDialogFinalCheckCskhAppear, setStateIsDialogFinalCheckCskhAppear] = useState(false);

    const postKHNewGDNew = async () => {
        if (window.confirm("Bạn đã chắc chắn dữ chưa ?") === false) {
            return;
        }
        const token = checkAlreadySignIn1();

        try {
            let responseKhachhang = await collectionAPI.collectionAPI.createKhachhang(token, stateObj_khachhang);
            
            if (responseKhachhang.status !== 201) {
                alert("Chuyển dữ liệu không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào ! ");
                setStateIsDialogFinalCheckCskhAppear(false);
                return;
            }
            await wait(100); // dừng lại 0.1 giây để nó lấy id của khách hàng
            const idKhachhang = responseKhachhang.data.id
            // tạo phone mới
            const objPhone = stateObj_phone;
            objPhone.idKhachhang = idKhachhang;
            let responsePhone = await collectionAPI.collectionAPI.createPhone(token, objPhone);
            if(responsePhone.data.warning === "số điện thoại này trùng với số điện thoại của KH khác"){
                alert("Số điện thoại khách hàng này đã tồn tại, vui lòng kiểm tra lại số điện thoại");

                await wait(100); // dừng lại 0.1 giây để nó lấy id của khách hàng

                let responsedeleteKhachHangById = await collectionAPI.collectionAPI.deleteKhachHangById(token, idKhachhang);
                // console.log('responsedeleteKhachHangById: ', responsedeleteKhachHangById);
                return;

            }
            // tạo giao dịch mới
            const objGiaodich = stateObj_Giaodich;
            objGiaodich.idKhachhang = idKhachhang;
            let responseGiaodich = await collectionAPI.collectionAPI.createGiaodich(token, objGiaodich);

            if (responseGiaodich.status !== 201) {
                alert("Chuyển dữ liệu không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào ! ");
                setStateIsDialogFinalCheckCskhAppear(false);
                return;
            }
            await wait(100); // dừng lạo 0.1 giây để nó lấy id của giao dịch
            
            
            // tạo thẻ mới
            const objThe = stateObj_the;
            objThe.idKhachhang = idKhachhang;
            objThe.idGiaodich = responseGiaodich.data.id;
            let responseThe = await collectionAPI.collectionAPI.createThe(token, objThe);

            if (responseThe.status === 201) {
                alert("Đã chuyển dữ liệu sang team Rút đáo thành công");
                setStateIsDialogFinalCheckCskhAppear(false);
                selectTimeFrame("today");
                reset();
                setStateArrGiaodichsOfEmployee([]); // cho về rỗng, vì hết nhu cầu hiện nó ra
                setStateBool_showupAllGiaodichOfEmployee(false); // cho cái chỗ hiện các giaodich của nhân viên đc chọn tắt đi, vì mình vừa update, nó sẽ ko kịp render chỗ đó lại
                findAllGiaodich();
                findAllNganhang();
                findAllKhachHang();
                findAllNguonkh();
                

            } else {
                alert("Chuyển dữ liệu không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào: ");
                setStateIsDialogFinalCheckCskhAppear(false);
            }
        
        } catch (err) {
            // console.log('err:', err);
        }
    }

    const UpdateKHCuCreateGDMoi = async () => {

        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");

        if (window.confirm("Bạn đã chắc chắn chưa ?") === false) {
            return;
        }
        const token = checkAlreadySignIn1();

        try {
            // tạo giao dịch mới, đã có idKhachhang bên trong rồi
            const objGiaodich = stateObj_Giaodich;
            let responseGiaodich = await collectionAPI.collectionAPI.createGiaodich(token, objGiaodich);
            // console.log('responseGiaodich: ', responseGiaodich);

            if (responseGiaodich.status !== 201) {
                alert("Chuyển dữ liệu không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào ! ");
                setStateIsDialogFinalCheckCskhAppear(false);
                return;
            }
            await wait(100); // dừng lạo 0.1 giây để nó lấy id của giao dịch
            
            // tạo phone mới, đã có idKhachhang bên trong rồi
            const objPhone = stateObj_phone;
            let responsePhone = await collectionAPI.collectionAPI.createPhone(token, objPhone);
            // console.log('responsePhone: ', responsePhone);
            if(responsePhone.data.warning === "số điện thoại này trùng với số điện thoại của KH khác"){
                alert("Số điện thoại khách hàng này đã tồn tại, vui lòng chọn số khác");
                return;
            }
            // tạo thẻ mới, đã có idKhachhang bên trong rồi
            const objThe = stateObj_the;
            objThe.idGiaodich = responseGiaodich.data.id;
            // console.log('objThe: ', objThe);
            let responseThe = await collectionAPI.collectionAPI.createThe(token, objThe);
            // console.log('responseThe: ', responseThe);

            if (responseThe.status === 201) {
                alert("Đã chuyển dữ liệu sang team Rút đáo thành công");
                setStateIsDialogFinalCheckCskhAppear(false);
                selectTimeFrame("today");
                reset();
                setStateArrGiaodichsOfEmployee([]); // cho về rỗng, vì hết nhu cầu hiện nó ra
                setStateBool_showupAllGiaodichOfEmployee(false); // cho cái chỗ hiện các giaodich của nhân viên đc chọn tắt đi, vì mình vừa update, nó sẽ ko kịp render chỗ đó lại
                findAllGiaodich();
                setStateString_buttonAction("create new");
                findAllNganhang();
                findAllKhachHang();
                findAllNguonkh();

            } else {
                alert("Chuyển dữ liệu không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào: ");
                setStateIsDialogFinalCheckCskhAppear(false);
            }
        } catch (err) {
            // console.log('err:', err);
        }
    }
    const cancelDialog = () => {
        setStateIsDialogFinalCheckCskhAppear(false);
    }
    const cancelDialogSuggestions = () => {
        setStateBoolIsDialog_suggestionsCskhAppear(false);
    }

    const [stateString_startTime, setStateString_startTime] = useState<string>("");
    const [stateString_endTime, setStateString_endTime] = useState<string>("");

    const [stateArrNotSucceed, setStateArrNotSucceed] = useState<any[]>([]);
    const [stateArrSucceed, setStateArrSucceed] = useState<any[]>([]);


    const handleChange_timeFrame = async (e: any) => {
        let value = e.target.value
        let start = ""
        let end = ""
        if(value === "today"){
            start = getCurrentDateYYYYMMDD();
            end = addOneDay(start);
        }
        else if (value === "thisWeek"){
            let obj = getStartAndEndOfWeek();
            start = obj.startOfWeek;
            end = obj.endOfWeek;
        }
        else if (value === "thisMonth"){
            let obj = getFirstAndLastDayOfMonth();
            start = obj.firstDayOfMonth;
            end = obj.lastDayOfMonth;
        }
        else if (value === "thisYear"){
            let obj = getFirstAndLastDayOfYear();
            start = obj.firstDayOfYear;
            end = obj.lastDayOfYear;
        }
        setStateString_startTime(start);
        setStateString_endTime(end);
        findGiaodichRecordsByDateRange(start, end);
    }
    const selectTimeFrame = async (time: string) => {
        let start = ""
        let end = ""
        if(time === "today"){
            start = getCurrentDateYYYYMMDD();
            end = addOneDay(start);
        }
        else if (time === "thisWeek"){
            let obj = getStartAndEndOfWeek();
            start = obj.startOfWeek;
            end = obj.endOfWeek;
        }
        else if (time === "thisMonth"){
            let obj = getFirstAndLastDayOfMonth();
            start = obj.firstDayOfMonth;
            end = obj.lastDayOfMonth;
        }
        else if (time === "thisYear"){
            let obj = getFirstAndLastDayOfYear();
            start = obj.firstDayOfYear;
            end = obj.lastDayOfYear;
        }
        setStateString_startTime(start);
        setStateString_endTime(end);
        findGiaodichRecordsByDateRange(start, end);
    }
    const handleChange_startTime = (event:any) => { 
        // console.log('event.target.value: ', event.target.value);
        setStateString_startTime(event.target.value) 
    }; 
    const handleChange_endTime = (event:any) => { 
        // console.log('event.target.value: ', event.target.value);
        setStateString_endTime(event.target.value) 

        findGiaodichRecordsByDateRange(stateString_startTime, event.target.value);

    }; 
    const handleChange_FrameMonth = (event:any) => {
    
        const value = event.target.value;
        const {month, year} = splitMonthYear(value);
        const {firstDay, lastDay } = getFirstAndLastDayOfMonthOfYear(month, year);
        findGiaodichRecordsByDateRange(firstDay, lastDay);
        setStateString_startTime(firstDay);
        setStateString_endTime(lastDay);
        
    }
    const handleChange_FrameYear = (event:any) => {
        const value = event.target.value;
        const {firstDay, lastDay} = getFirstAndLastDayOfYearParameter(value)
        findGiaodichRecordsByDateRange(firstDay, lastDay);
        setStateString_startTime(firstDay);
        setStateString_endTime(lastDay);
    }
    const findGiaodichRecordsByDateRange = async (start: string, end: string) => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        const token = userExist.token;
        // console.log('start: ', start);
        // console.log('end: ', end);
        // console.log('stateString_nguonKH_tonghop: ', stateString_nguonKH_tonghop);
        try {
            let responsearrNotSucceed = await collectionAPI.collectionAPI.findGiaodichRecordsByDateRange_notSucceed(token, start, end, stateString_nguonKH_tonghop);
            // console.log('responsearrNotSucceed.data.data: ', responsearrNotSucceed.data.data);
            if(responsearrNotSucceed.data.data === undefined){

                setStateArrNotSucceed([]);
            }
            else {
                setStateArrNotSucceed(responsearrNotSucceed.data.data);
            }
            
            let responsearrSucceed = await collectionAPI.collectionAPI.findGiaodichRecordsByDateRange_succeed(token,start, end, stateString_nguonKH_tonghop);
            // console.log('responsearrSucceed.data.data: ', responsearrSucceed.data.data);
            if(responsearrSucceed.data.data === undefined){

                setStateArrSucceed([]);
            }
            else {
                setStateArrSucceed(responsearrSucceed.data.data);
            }
            
            // console.log('action: ', action);
        } catch (err) {
            // console.log('err:', err);
        }
    }
    const refAllGiaodich = useRef<HTMLInputElement | null>(null);
    const [stateBool_showupAllGiaodichOfEmployee, setStateBool_showupAllGiaodichOfEmployee] = useState(false); // boolean
    const [stateBool_showAllGiaodichOfEmployee, setStateBool_showAllGiaodichOfEmployee] = useState(false); // boolean
    const [stateArrGiaodichsOfEmployee, setStateArrGiaodichsOfEmployee] = useState<any[]>([]);
    const xemTatCaGiaodichCuaNhanVien = async(email: string, inputTrangThai: string) => {
        setStateBool_showAllGiaodichOfEmployee(true); // cho hiện cái phần xem tất cả giaodich của nhân viên mà mình chọn thông qua hàm này
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        const token = userExist.token;
        let response = await collectionAPI.collectionAPI.findGiaodichRecordsByStartEndEmailTrangThai(token, stateString_startTime, stateString_endTime, email, inputTrangThai, stateString_nguonKH_tonghop);
        // console.log('response.data 1593 xemTatCaGiaodichCuaNhanVien: ', response.data);
        setStateArrGiaodichsOfEmployee(response.data.data);
        setStateBool_showupAllGiaodichOfEmployee(true);
        refAllGiaodich.current?.focus();
        await wait(100); // cho nó chờ 1 milisecond thì nó mới bắt được cái current, nó mới di chuyển đi
        if (refAllGiaodich.current) {
            refAllGiaodich.current.focus();
        }
    
    }
    // dialog end---------------------------------------------------------------------------------

    useEffect(() => {
        selectTimeFrame("today");
    },[]);

    const refTop = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const [stateString_createdAt, setStateString_createdAt] = useState<string>('');
    const [stateString_email, setStateString_email] = useState<string>('');
    const [stateNumber_idGiaodich, setStateNumber_idGiaodich] = useState<number>(0);
    const [stateBool_create, setStateBool_create] = useState(true);
    const [stateString_buttonAction, setStateString_buttonAction] = useState<string>('create new');
    const [stateNumber_idKhachhang, setStateNumber_idKhachhang] = useState<number>(0);
    
    // tính năng này ẩn đi, vì ko cho nhân viên có quyền sửa
    const selectOldGiaodichToUpdate = async (id:string) => {
        setStateBool_create(false);
        setStateString_buttonAction("update giao dịch cũ");
        const obj = stateArrGiaodichsOfEmployee.find((item) => item.id === id); 
        
        // console.log('selectOldGiaodichToUpdate 1402: ', obj);

        setStateString_emailnhanvien(obj.emailNVCSKHCreate);
        setStateNumber_idGiaodich(obj.id);
        setStateString_createdAt(obj.createdAt);
        setStateString_tennhanvien(obj.fullNameNVCSKHcreate);
        setStateString_tenkhachhang(obj.khachhang.fullNameKH);
        setError_tenkhachhang("✅");

        setStateString_sdt(obj.khachhang.phone)
        setError_sdt("✅");
        setError_sdt2(true);
        setStateNumber_tienrutdao(obj.tienrutdao);
        setError_tienrutdao("✅");
        
        setStateNumber_tienrutdao_makeup(obj.tienrutdao)
        setStateNumber_phirutdao(obj.phirutdao);
        setError_phirutdao("✅");
        
        setStateNumber_tienphi(obj.tienphi);
        
        setStateNumber_tienphi_makeup(obj.tienphi);
        setStateNumber_tienship(obj.tienship);
        setError_tienship("✅");

        setStateNumber_tienship_makeup(obj.tienship)
        setStateString_nguonKH(obj.khachhang.nguonKH);
        setStateString_nhucau(obj.nhucau);
        setStateString_tennganhang(obj.nganhangId)
        setStateString_loaithe(obj.loaiThe);
        let ngaylienhelai = extractYYYY_MM_DD(obj.ngaydukienlienhelai);
        setStateString_lienhelai(ngaylienhelai); // chưa có trích giờ ra
        let giolienhelai = extractTimeFromDateString(obj.ngaydukienlienhelai);
        let hhmm = `${giolienhelai.hoursNumber}:${giolienhelai.minutesNumber}`
        setStateString_giolienhelai(hhmm);

        let ngayhanthanhtoan = extractYYYY_MM_DD(obj.ngayhanthanhtoan)
        setStateString_hanthanhtoan(ngayhanthanhtoan);
        setStateString_trangthai(obj.trangthaiCSKH);
        setStateString_ghichu(obj.ghichucskh);
        setStateString_chinhanh(obj.tenchinhanh);

        // console.log('obj.tenchinhanh: ', obj.tenchinhanh);
        setNameCitiSelected(obj.city);
        setNameDistrictSelected(obj.district);
        setNameWardSelected(obj.ward);

        await wait(100); // cho nó chờ 1 milisecond thì nó mới bắt được cái current, nó mới di chuyển đi
        if (refTop.current) {
            refTop.current.focus();
        }
    }
    
    const selectOldKHFromSuggestions = (id:number) => {
        setStateBool_isDisable_tenKhachHang(true); // sau khi đã chọn khách hàng cũ thì ko cho họ sửa tên KH cũ
        setStateBool_isDisable_nguonKH(true); // sau khi đã chọn khách hàng cũ thì ko cho họ sửa tên KH cũ
        setStateBool_isDisable_city(true)
        setStateBool_isDisable_district(true)
        setStateBool_isDisable_ward(true)
        setStateBoolIsDialog_suggestionsCskhAppear(false);
        setStateString_buttonAction("update KH được chọn và tạo giao dịch mới");
        setStateNumber_idKhachhang(id);
        const obj = stateArrKhachHang.find((item) => item.id === id); 
        // console.log('obj 1693: ', obj);
        setStateString_emailnhanvien(obj.emailNVCSKHCreate);
        setStateString_createdAt(obj.createdAt);
        setStateString_tennhanvien(obj.fullNameNVCSKHcreate);
        setStateString_tenkhachhang(obj.fullNameKH);
        setError_tenkhachhang("✅");

        setStateString_sdt(obj.phone[0].phone)
        setError_sdt("✅");
        setError_sdt2(true);
        setNameCitiSelected(obj.city)
        setNameDistrictSelected(obj.district)
        setNameWardSelected(obj.ward)
        setStateString_nguonKH(obj.nguonKH);

    }

    const [stateString_nguonKH_tonghop, setStateString_nguonKH_tonghop] = useState<string>('Tất cả');
    const handleChange_nguonKH_tonghop = (event: any) => {
        const value = event.target.value;
        // console.log('value: ', value);
        setStateString_nguonKH_tonghop(value);
    }

    const closeDialogSuggest = () => {
        setStateBoolIsDialog_suggestionsCskhAppear(false);
    }
    return (
        <>
            {/* <MenuBar/> */}
            <div className={clsx(styles.component_CskhThongTin)}>
                <input type="text"ref={refTop} className={clsx(styles.refInvisible)}></input>
                <h2 className={clsx(styles.title)}>CHĂM SÓC KHÁCH HÀNG</h2>
                <div className={clsx(styles.inputSection)}>
                    <div className={clsx(styles.heading1)}>
                        <p>Tên nhân viên CSKH</p>
                        <p>  SĐT Khách hàng &nbsp;
                            {
                                error_sdt2 &&
                                <span className={clsx(styles.success)}>{error_sdt}</span>
                            }
                            {
                                !error_sdt2 &&
                                <span className={clsx(styles.error)}>{error_sdt}</span>
                                
                            }
                        </p>
                        <p>Tên khách hàng <span className={clsx(styles.error)}>{error_tenkhachhang}</span></p>
                        <Tooltip title="1,000,000 <= tiền <= 900,000,000; Không phải số thập phân" placement="top">
                            <p>Số tiền rút/đáo <span className={clsx(styles.error)}>{error_tienrutdao}</span></p>
                        </Tooltip>
                        <Tooltip title="4.0 > phí > 0.5 ; số thập phân dùng dấu chấm (.)" placement="top">
                            <p>Phí rút/đáo (_._) % &nbsp;<span className={clsx(styles.error)}>{error_phirutdao}</span></p>
                        </Tooltip>
                        <Tooltip title="Tiền phí = Số tiền rút đáo x Phí rút đáo" placement="top">
                            <p>Tiền phí</p>
                        </Tooltip>
                        <Tooltip title="Không cần nhập dấu phẩy" placement="top">
                            <p>Tiền ship <span className={clsx(styles.notRequired)}>*</span>&nbsp;<span className={clsx(styles.error)}>{error_tienship}</span></p>
                        </Tooltip>

                    </div>
                    {/* end heading1 */}

                    <div className={clsx(styles.row1)}>
                        <input
                            type="text"
                            className={clsx(styles.tennhanvien)}
                            value={stateString_tennhanvien}
                            disabled
                            />
                        <input
                            type="text"
                            className={clsx(styles.sdt)}
                            value={stateString_sdt}
                            ref={inputRef_sdt}
                            onChange={handleInputChange_sdt}
                            placeholder='Số điện thoại...'
                            />
                        <input
                            type="text"
                            className={clsx(styles.tenkhachhang)}
                            value={stateString_tenkhachhang}
                            ref={inputRef_tenkhachhang}
                            onChange={handleInputChange_tenkhachhang}
                            placeholder='Tên khách hàng...'
                            disabled={stateBool_isDisable_tenKhachHang}
                            />

                        <input type="text" 
                            value={stateNumber_tienrutdao_makeup} 
                            ref={inputRef_tienrutdao}
                            onChange={handleChange_tienrutdao}
                            placeholder='Số tiền rút/đáo...'
                            
                            />

                        <input type="number" 
                            ref={inputRef_phirutdao}
                            step="0.1" 
                            value={stateNumber_phirutdao}
                            onChange={handleChange_phirutdao}
                            placeholder='Phí rút/đáo...'
                            
                            />

                        <input type="text" value={stateNumber_tienphi.toLocaleString('en-US')} disabled/>
                    
                        <input type="text" 
                            value={stateNumber_tienship_makeup} 
                            ref={inputRef_tienship}
                            
                            className={clsx(styles.phiship)} 
                            onChange={handleChange_tienship}/>

                        
                    </div>
                    {/* end row1 */}
                    <div className={clsx(styles.heading2)}>
                        <p>Nguồn khách hàng</p>
                        <p>Nhu cầu</p>
                        <p>Tên ngân hàng</p>
                        <p>Loại thẻ</p>
                        <p>Thời điểm KH liên hệ lại</p>
                        <Tooltip title="Ngày Deadline đáo hạn" placement="top">
                            <p>Ngày hạn thanh toán</p>
                        </Tooltip>
                        <p>Trạng thái</p>
                    </div>
                    {/* end heading2 */}

                    <div className={clsx(styles.row2)}>
                    {/* stateArrNguonkh */}
                        <select name="from" onChange={handleChange_nguonKH} disabled={stateBool_isDisable_nguonKH} className={clsx(styles.selectOption)} value={stateString_nguonKH}>
                            {
                                stateArrNguonkh.map((obj, index) => {
                                    return ( 
                                        <option key={obj.id} value={obj.id}>{obj.id}</option>
                                    )
                                })
                            }
                        </select>
                        <select name="from" onChange={handleChange_nhucau} className={clsx(styles.selectOption)} value={stateString_nhucau}>
                            <option value="Rút tiền">Rút tiền</option>
                            <option value="Đáo hạn">Đáo hạn</option>
                        </select>

                        <input type="text" id="searchInput" list="listNganhang" onChange={handleChange_tennganhang}/>
    
                        <datalist id="listNganhang">
                            {
                                stateArrNganhang.map((obj, index) => {
                                    return ( 
                                        <option key={obj.id} value={obj.id} />
                                    )
                                })
                            }
                        </datalist>

                        <select name="from" onChange={handleChange_loaithe} className={clsx(styles.selectOption)} value={stateString_loaithe}>
                            <option value="Visa">Visa</option>
                            <option value="Master card">Master card</option>
                            <option value="Evo">Evo</option>
                            <option value="JCB">JCB</option>
                            <option value="amex">amex</option>
                            <option value="Napas">Napas</option>
                            <option value="Lotte Finance">Lotte Finance</option>
                            <option value="Shinhan Finance">Shinhan Finance</option>
                        </select>
                        
                        <div className={clsx(styles.lienhelaiWrapper)}>

                            <input type="date" 
                                className={clsx(styles.inputDate)} 
                                min={new Date().toISOString().split('T')[0]} // min today
                                value={stateString_lienhelai} 
                                onChange={handleChange_lienhelai}/>

                            <select name="from" onChange={handleChange_giolienhelai} className={clsx(styles.selectOption)} value={stateString_giolienhelai}>
                                <option value="00:00">Giờ</option>
                                <option value="6:30">6:30</option>
                                <option value="7:00">7:00</option>
                                <option value="7:30">7:30</option>
                                <option value="8:00">8:00</option>
                                <option value="8:30">8:30</option>
                                <option value="9:00">9:00</option>
                                <option value="9:30">9:30</option>
                                <option value="10:00">10:00</option>
                                <option value="10:30">10:30</option>
                                <option value="11:00">11:00</option>
                                <option value="11:30">11:30</option>
                                <option value="12:00">12:00</option>
                                <option value="12:30">12:30</option>
                                <option value="13:00">13:00</option>
                                <option value="13:30">13:30</option>
                                <option value="14:00">14:00</option>
                                <option value="14:30">14:30</option>
                                <option value="15:00">15:00</option>
                                <option value="15:30">15:30</option>
                                <option value="16:00">16:00</option>
                                <option value="16:30">16:30</option>
                                <option value="17:00">17:00</option>
                                <option value="17:30">17:30</option>
                                <option value="18:00">18:00</option>
                                <option value="18:30">18:30</option>
                                <option value="19:00">19:00</option>
                                <option value="19:30">19:30</option>
                                <option value="20:00">20:00</option>
                                <option value="20:30">20:30</option>
                                <option value="21:00">21:00</option>
                                <option value="21:30">21:30</option>
                                <option value="22:00">22:00</option>
                                <option value="22:30">22:30</option>
                                <option value="23:00">23:00</option>
                            </select>
                        </div>
                        <input type="date" 
                            className={clsx(styles.inputDate)} 
                            min={new Date().toISOString().split('T')[0]} // min today
                            value={stateString_hanthanhtoan} 
                            onChange={handleChange_hanthanhtoan}/>

                        <select name="from" onChange={handleChange_trangthai} className={clsx(styles.selectOption)} value={stateString_trangthai}>
                            <option value="Đã chuyển sang team Rút đáo">Đã chuyển đến team Rút đáo</option>
                            <option value="Hẹn lại giờ khác">Hẹn lại giờ khác</option>
                        </select>
        
                    </div>
                    {/* end row2 */}

                    <div className={clsx(styles.heading3)}>
                        <Tooltip title="Chi nhánh khách đến làm việc" placement="top">
                            <p>Chi nhánh</p>
                        </Tooltip>
                        <Tooltip title="Không bắt buộc" placement="top">
                            <p>Thành phố/tỉnh<span className={clsx(styles.notRequired)}>&nbsp;*</span></p>
                        </Tooltip>
                        <Tooltip title="Không bắt buộc" placement="top">
                            <p>Quận<span className={clsx(styles.notRequired)}>&nbsp;*</span></p>
                        </Tooltip>
                        <Tooltip title="Không bắt buộc" placement="top">
                            <p>Phường<span className={clsx(styles.notRequired)}>&nbsp;*</span></p>
                        </Tooltip>
                        <Tooltip title="Không bắt buộc" placement="top">
                            <p>Ghi chú (CSKH)<span className={clsx(styles.notRequired)}>&nbsp;*</span></p>
                        </Tooltip>
                        <p>Loại giao dịch</p>
                        <p>Địa chỉ ship <span className={clsx(styles.notRequired)}>*</span></p>
                    </div>
                    <div className={clsx(styles.row3)}>

                        <div className={clsx(styles.selectWrapper)}>
                            <select name="citySelected" onChange={handleChiNhanh} className={clsx(styles.selectOption)} value={stateString_chinhanh}>
                                <option value="empty">Chọn chi nhánh...</option>
                                <option value="Q1">Q1</option>
                                <option value="Q4">Q4</option>
                                <option value="Q7">Q7</option>
                                <option value="Q10">Q10</option>
                                <option value="Q12">Q12</option>
                                <option value="Q. Tân Phú">Q. Tân Phú</option>
                                <option value="Dĩ An">Dĩ An</option>
                                <option value="Bảo Lộc">Bảo Lộc</option>
                                <option value="Vạn Phúc city">Vạn Phúc city</option>
                            </select>
                        </div>
                        <div className={clsx(styles.selectWrapper)}>
                            <select name="citySelected" onChange={handleCity} disabled={stateBool_isDisable_city} className={clsx(styles.selectOption)} value={nameCitySelected}>
                                <option value="empty">Please select a city...</option>
                                {citiesOptions}
                            </select>
                        </div>
                        <div className={clsx(styles.selectWrapper)}>
                            <select name="districtSelected" onChange={handleDistrict} disabled={stateBool_isDisable_district} className={clsx(styles.selectOption)} value={nameDistrictSelected}>
                                {districtsOptions}
                            </select>
                        </div>
                        <div className={clsx(styles.selectWrapper)}>
                            <select name="wardSelected" onChange={handleWard} disabled={stateBool_isDisable_ward} className={clsx(styles.selectOption)} value={nameWardSelected}>
                                {wardsOptions}
                            </select>
                        </div>
                        <textarea
                            id="yourTextArea"
                            name="yourTextArea"
                            value={stateString_ghichu}
                            onChange={handlechange_ghichu}
                            rows={1} // Set the number of rows as needed
                            cols={50} // Set the number of columns as needed
                        />
                        <div className={clsx(styles.selectWrapper)}>
                            <select name="wardSelected" onChange={handleLoaiKhachhang} className={clsx(styles.selectOption)} value={stateString_loaigiaodich}>
                                <option value="Giao dịch thật">Giao dịch thật</option>
                                <option value="Giao dịch tiềm năng">Giao dịch tiềm năng</option>
                            </select>
                        </div>
                        <input
                            className={clsx(styles.diachiship)}
                            type="text"
                            value={stateString_diachiship}
                            onChange={handleInputChange_diachiship}
                            />
                    </div>
                    <div className={clsx(styles.btnWrapperSectionInput)}>
                        <span></span>
                        <div className={clsx(styles.btnGroup)}>
                            {
                                (() => {
                                    if (stateString_buttonAction === "create new") {
                                        return (
                                            <>
                                                <p><button onClick={create} className={clsx(styles.btncreateReal)}>Create</button></p>
                                                <p><button onClick={reset} className={clsx(styles.btnReset)}>Reset</button></p>
                                            </>
                                        );
                                    }
                                    else if(stateString_buttonAction === "update KH được chọn và tạo giao dịch mới"){
                                        return (
                                            <>
                                                <p><button onClick={capNhatDuLieuKHCuVaTaoGiaoDichMoi} className={clsx(styles.btnedit)}>Cập nhật KH cũ được chọn và tạo giao dịch mới</button></p>
                                                <p><button onClick={refresh} className={clsx(styles.btnRefresh)}>Refresh</button></p>
                                            </>
                                        );
                                    }
                                })()
                            }
                        </div>
                        <p className={clsx(styles.ghichu)}><span className={clsx(styles.key)}>Ghi chú:</span> Dấu  <span className={clsx(styles.notRequired)}>*</span> không bắt buộc</p>
                    </div>
                
                </div>
                {/* end inputSection */}
                <div className={clsx(styles.divider)}>
                </div>
                <h3>TỔNG HỢP</h3>
                <div className={clsx(styles.timeFrame)}>

                    <div className={clsx(styles.nguonKHWrapper)}>
                        <label htmlFor="">Nguồn khách hàng</label>
                        <select name="nguonkh" onChange={handleChange_nguonKH_tonghop} className={clsx(styles.selectOption)}>
                            <option value="Tất cả">Tất cả</option>
                            {
                                stateArrNguonkh.map((obj, index) => {
                                    return ( 
                                        <option key={obj.id} value={obj.id}>{obj.id}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    {/* <p className={clsx(styles.today)}><button onClick={()=>selectTimeFrame('today')}>Hôm nay</button></p> */}
                    {/* <p className={clsx(styles.thisWeek)}><button onClick={()=>selectTimeFrame('thisWeek')}>Tuần này</button></p>
                    <p className={clsx(styles.thisMonth)}><button onClick={()=>selectTimeFrame('thisMonth')}>Tháng này</button></p>
                    <p className={clsx(styles.thisYear)}><button onClick={()=>selectTimeFrame('thisYear')}>Năm nay</button></p> */}
                    <div className={clsx(styles.monthWrapper)}>
                        <label htmlFor="">Chọn</label>
                        <select name="month" onChange={handleChange_timeFrame} className={clsx(styles.selectOption)}>
                            {/* <option value="null">Chọn tháng ...</option> */}
                            <option value="today">Hôm nay</option>
                            <option value="thisWeek">Tuần này</option>
                            <option value="thisMonth">Tháng này</option>
                            <option value="thisYear">Năm nay</option>
                        </select>
                    </div>
                    <div className={clsx(styles.monthWrapper)}>
                        <label htmlFor="">Tháng</label>
                        <select name="month" onChange={handleChange_FrameMonth} className={clsx(styles.selectOption)}>
                            <option value="null">Chọn tháng ...</option>
                            <option value="12-2023">12-2023</option>
                            <option value="01-2024">01-2024</option>
                            <option value="02-2024">02-2024</option>
                            <option value="03-2024">03-2024</option>
                            <option value="04-2024">04-2024</option>
                            <option value="05-2024">05-2024</option>
                            <option value="06-2024">06-2024</option>
                            <option value="07-2024">07-2024</option>
                            <option value="08-2024">08-2024</option>
                            <option value="09-2024">09-2024</option>
                            <option value="10-2024">10-2024</option>
                            <option value="11-2024">11-2024</option>
                            <option value="12-2024">12-2024</option>
                        </select>
                    </div>
                    <div className={clsx(styles.yearWrapper)}>
                        <label htmlFor="">Năm</label>
                        <select name="year" onChange={handleChange_FrameYear} className={clsx(styles.selectOption)}>
                            <option value="null">Chọn năm ...</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>
                    <div className={clsx(styles.range)}>
                        <div className={clsx(styles.startWrapper)}>
                            <label htmlFor="">Bắt đầu</label>
                            <input type="date" 
                                className={clsx(styles.inputDate)} 
                                value={stateString_startTime} 
                                onChange={handleChange_startTime}/>
                        </div>
                        <div className={clsx(styles.endWrapper)}>
                            <label htmlFor="">Kết thúc</label>

                            <input type="date" 
                                className={clsx(styles.inputDate)} 
                                value={stateString_endTime} 
                                onChange={handleChange_endTime}/>
                        </div>
                    </div>
                </div>
                {
                    managementGroup.includes(stateObj_signIn.role) === true &&
                    <div className={clsx(styles.timeFrameExport)}>

                        <button onClick={()=>btnExportToExcel('fileNameVuna')}>export To Excel</button>
                        <div className={clsx(styles.nguonKHWrapper)}>
                            <label htmlFor="">Nguồn khách hàng</label>
                            <select name="nguonkh" onChange={handleChange_nguonKH_tonghop_export} className={clsx(styles.selectOption)}>
                                <option value="Tất cả">Tất cả</option>
                                {
                                    stateArrNguonkh.map((obj, index) => {
                                        return ( 
                                            <option key={obj.id} value={obj.id}>{obj.id}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className={clsx(styles.timeWrapper)}>
                            <label htmlFor="">Thời gian</label>
                            <select name="nguonkh" onChange={handleChange_time} className={clsx(styles.selectOption)}>
                                <option value="Hôm nay">Hôm nay</option>
                                <option value="Tuần này">Tuần này</option>
                                <option value="Tháng này">Tháng này</option>
                                <option value="Năm nay">Năm nay</option>
                                <option value="12-2023">12-2023</option>
                                <option value="01-2024">01-2024</option>
                                <option value="02-2024">02-2024</option>
                                <option value="03-2024">03-2024</option>
                                <option value="04-2024">04-2024</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
                    </div>
                }
                <div className={clsx(styles.tonghopWrapper)}>
                    <div className={clsx(styles.left)}>
                        <h4>THÀNH CÔNG</h4>
                        <p className={clsx(styles.ghichu)}><span className={clsx(styles.key)}>Ghi chú:</span> Các lượt này đã được team Rút Đáo chuyển trạng thái thành "Thành công"</p>
                        <div className={clsx(styles.heading)}>
                            <p>STT</p>
                            <p>Tên nhân viên</p>
                            <Tooltip title="Chính là số tiền rút đáo" placement="top">
                                <p>Doanh thu</p>
                            </Tooltip>
                            <Tooltip title="Bằng doanh thu x phí rút đáo" placement="top">
                                <p>Phí thu được</p>
                            </Tooltip>
                            {/* <Tooltip title="Bao gồm phí ship và các phí khác nếu có" placement="top">
                                <p className={clsx(styles.money)}>Phí khác</p>
                            </Tooltip> */}
                            <Tooltip title="Tổng khách hàng liên hệ thành công" placement="top">
                                <p>Thành công</p>
                            </Tooltip>
                            <p></p>
                        </div>
                        {
                            (() => {
                                if (stateArrSucceed.length > 0) {
                                    return (
                                        stateArrSucceed.map((obj, index) => {
                                            return ( 
                                                <div className={clsx(styles.row)} key={obj.email}>
                                                    <p className={clsx(styles.cell)}>{index + 1}</p>
                                                    <p className={clsx(styles.fullNameNV)}>{obj.fullNameNV}</p>
                                                    <p className={clsx(styles.money)}>{formatThoudsand(obj.tienrutdao)}</p>
                                                    {/* <p className={clsx(styles.money)}>{formatThoudsand(obj.tienphi)}</p> */}
                                                    <p className={clsx(styles.money)}>{formatThoudsand(obj.tienship)}</p>
                                                    <p className={clsx(styles.cell)}>{obj.count}</p>
                                                    <p><button onClick={()=>xemTatCaGiaodichCuaNhanVien(obj.email, "Thành công")}>Xem</button></p>
                                                </div>
                                            )
                                        })
                                    );
                                } else {
                                    return (
                                        <></>
                                    );
                                }
                            })()
                        }
                        {/* {
                            stateArrSucceed.length > 0 &&
                            stateArrSucceed.map((obj, index) => {
                                return ( 
                                    <div className={clsx(styles.row)} key={obj.email}>
                                        <p className={clsx(styles.cell)}>{index + 1}</p>
                                        <p className={clsx(styles.fullNameNV)}>{obj.fullNameNV}</p>
                                        <p className={clsx(styles.money)}>{formatThoudsand(obj.tienrutdao)}</p>
                                        <p className={clsx(styles.money)}>{formatThoudsand(obj.tienphi)}</p>
                                        <p className={clsx(styles.money)}>{formatThoudsand(obj.tienship)}</p>
                                        <p className={clsx(styles.cell)}>{obj.count}</p>
                                        <p><button onClick={()=>xemTatCaGiaodichCuaNhanVien(obj.email, "Thành công")}>Xem</button></p>
                                    </div>
                                )
                            })
                        } */}
                    </div>
                    <div className={clsx(styles.right)}>
                        <h4>CHƯA THÀNH CÔNG</h4>
                        <p className={clsx(styles.ghichu)}><span className={clsx(styles.key)}>Ghi chú:</span> Các lượt này có thể do team Rút Đáo chưa xử lý, nên vẫn chưa chuyển trạng thái thành "Thành công"</p>
                        <div className={clsx(styles.heading)}>
                            <p>STT</p>
                            <p>Tên nhân viên</p>
                            <Tooltip title="Chính là số tiền rút đáo" placement="top">
                                <p className={clsx(styles.money)}>Doanh thu</p>
                            </Tooltip>
                            <Tooltip title="Bằng doanh thu x phí rút đáo" placement="top">
                                <p className={clsx(styles.money)}>Phí thu được</p>
                            </Tooltip>
                            {/* <Tooltip title="Bao gồm phí ship và các phí khác nếu có" placement="top">
                                <p className={clsx(styles.money)}>Phí khác</p>
                            </Tooltip> */}
                            <Tooltip title="Tổng khách hàng liên hệ chưa thành công" placement="top">
                                <p>Chưa thành công</p>
                            </Tooltip>
                            <p></p>
                        </div>
                        {
                            (() => {
                                if (stateArrNotSucceed.length > 0) {
                                    return (
                                        stateArrNotSucceed.map((obj, index) => {
                                            return ( 
                                                <div className={clsx(styles.row)} key={obj.email}>
                                                    <p className={clsx(styles.cell)}>{index + 1}</p>
                                                    <p className={clsx(styles.fullNameNV)}>{obj.fullNameNV}</p>
                                                    <p className={clsx(styles.money)}>{formatThoudsand(obj.tienrutdao)}</p>
                                                    {/* <p className={clsx(styles.money)}>{formatThoudsand(obj.tienphi)}</p> */}
                                                    <p className={clsx(styles.money)}>{formatThoudsand(obj.tienship)}</p>
                                                    <p className={clsx(styles.cell)}>{obj.count}</p>
                                                    <p><button onClick={()=>xemTatCaGiaodichCuaNhanVien(obj.email, "chưa thành công")}>Xem</button></p>
                                                </div>
                                            )
                                        })
                                    );
                                } else {
                                    return (
                                        <></>
                                    );
                                }
                            })()
                        }
                        {/* {
                            stateArrNotSucceed.map((obj, index) => {
                                return ( 
                                    <div className={clsx(styles.row)} key={obj.email}>
                                        <p className={clsx(styles.cell)}>{index + 1}</p>
                                        <p className={clsx(styles.fullNameNV)}>{obj.fullNameNV}</p>
                                        <p className={clsx(styles.money)}>{formatThoudsand(obj.tienrutdao)}</p>
                                        <p className={clsx(styles.money)}>{formatThoudsand(obj.tienphi)}</p>
                                        <p className={clsx(styles.money)}>{formatThoudsand(obj.tienship)}</p>
                                        <p className={clsx(styles.cell)}>{obj.count}</p>
                                        <p><button onClick={()=>xemTatCaGiaodichCuaNhanVien(obj.email, "chưa thành công")}>Xem</button></p>
                                    </div>
                                )
                            })
                        } */}
                        
                    </div>
                </div>
                {
                    stateBool_showupAllGiaodichOfEmployee &&
                    <div className={clsx(styles.allGiaodichofAnEmployee)} >
                        <h2>Các Giao dịch của nhân viên được chọn</h2>
                        <div className={clsx(styles.heading)}>
                            <p>STT</p>
                            <p>Tên Nhân viên</p>
                            <p>Tên khách hàng</p>
                            <p>phone</p>
                            <p>Chi nhánh</p>
                            <p>Tạo lúc</p>
                            <p>Kiểu KH</p>
                            <p>Trạng thái (Rút Đáo)</p>
                            <p>Lý do</p>
                            <p>Ngân hàng</p>
                            <p>liên hệ lại</p>
                            <p>Nguồn KH</p>
                            <p>Nhu cầu</p>
                            <p>Tiền rút đáo</p>
                            <p>Phí rút đáo</p>
                            <p>Tiền phí</p>
                            {/* <p>Sửa</p> */}
                        </div>
                        {
                            stateBool_showAllGiaodichOfEmployee &&
                            stateArrGiaodichsOfEmployee.map((obj, index) => {
                                return ( 
                                    <div className={clsx(styles.row)} key={obj.id}>
                                        <p>{index+1}</p>
                                        <p>{obj.fullNameNVCSKHcreate}</p>
                                        <p>{obj.khachhang.fullNameKH}</p>
                                        {/* <p>{obj.khachhang.phone[0]!.phone}</p> */}
                                        <p>
                                            {obj.khachhang.phone.map((obj:any, phoneIndex:any) => (
                                                <span key={phoneIndex}>{obj.phone}<br/></span>
                                            ))}
                                        </p>
                                        <p>{obj.tenchinhanh}</p>
                                        <p>{formatDateTime(obj.khachhang.createdAt)}</p>
                                        <p>{obj.loaigiaodich}</p>
                                        <p>{obj.teamrutdaothongbaotrangthai}</p>
                                        <p>{obj.lydocuatrangthai}</p>
                                        <p>{obj.nganhangId}</p>
                                        <p>{formatDateTime(obj.ngaydukienlienhelai)}</p>
                                        <p>{obj.khachhang.nguonKH}</p>
                                        <p>{obj.nhucau}</p>
                                        <p>{formatThoudsand(obj.tienrutdao)}</p>
                                        <p>{obj.phirutdao}</p>
                                        <p>{formatThoudsand(obj.tienphi)}</p>
                                        {/* <p><button onClick={()=>selectOldGiaodichToUpdate(obj.id)}>Sửa</button></p> */}
                                    </div>
                                )
                            })
                        }
                        <input type="text"ref={refAllGiaodich} className={clsx(styles.refAllGiaodich)}></input>
                    </div>
                }
                {/* Dialog finalCheck start */}
                {
                    stateIsDialogFinalCheckCskhAppear && //nó phải true thì mới chạy khối code này, tránh việc chạy ngầm dẫn đến lỗi khi chưa có dữ liệu
                    <>
                        {/* back shadow */}
                        <div className={clsx(styles.backShadow,{[styles.appear]: stateIsDialogFinalCheckCskhAppear})} onClick={cancelDialog}>
                        </div>
                        {/* dialog box */}
                        <div className={clsx(styles.dialogFinalCHeck, {[styles.appear]: stateIsDialogFinalCheckCskhAppear})}>
                            <h3>Kiểm tra lần cuối trước khi chuyển dữ liệu sang Team Rút Đáo</h3>
                            <div className={clsx(styles.btnWrapper)}>
                                {
                                    (() => {
                                        if (stateString_buttonAction === "create new") {
                                            return (
                                                <>
                                                    <button onClick={postKHNewGDNew} className={clsx(styles.submit)}>Chuyển đến team Rút Đáo: KH mới, giao dịch mới</button>
                                                </>
                                            );
                                        } 
                                        else if(stateString_buttonAction === "update KH được chọn và tạo giao dịch mới"){
                                            return (
                                                <>
                                                    <button onClick={UpdateKHCuCreateGDMoi} className={clsx(styles.btnedit)}>Chuyển đến team Rút Đáo: KH cũ, giao dịch mới</button>
                                                </>
                                            );
                                        }
                                    })()
                                }
                                <button onClick={cancelDialog} className={clsx(styles.edit)}>Chỉnh sửa lại</button>
                            </div>
                            {/* stateObj_khachhang
                            stateObj_Giaodich */}
                            <p className={clsx(styles.ghichu)}><span className={clsx(styles.key)}>Ghi chú:</span> Dấu  <span className={clsx(styles.notRequired)}>*</span> không bắt buộc</p>
                            <div className={clsx(styles.containerLR)}>
                                <div className={clsx(styles.left)}>
                                    {
                                        stateObj_khachhang.createdAt !== null &&
                                        <div className={clsx(styles.row)}>
                                            <p className={clsx(styles.key)}>Khởi tạo KH lúc:</p><p className={clsx(styles.value)}>{formatDateTime(stateObj_khachhang.createdAt)}</p>
                                        </div>
                                    }
                                    {
                                        stateObj_khachhang.editedAt !== null &&

                                        <div className={clsx(styles.row)}>
                                            <p className={clsx(styles.key)}>Sửa KH lúc:</p><p className={clsx(styles.value)}>{formatDateTime(stateObj_khachhang.editedAt)}</p>
                                        </div>
                                    }
                                    {
                                        stateObj_khachhang.emailNVCSKHEdit !== "" &&
                                        <div className={clsx(styles.row)}>
                                            <p className={clsx(styles.key)}>Người sửa:</p><p className={clsx(styles.value)}>{stateObj_khachhang.emailNVCSKHEdit}</p>
                                        </div>
                                    }
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Tên NV CSKH tạo KH này:</p><p className={clsx(styles.value)}>{stateObj_khachhang.fullNameNVCSKHcreate}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Tên khách hàng:</p><p className={clsx(styles.value)}>{stateObj_khachhang.fullNameKH}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Điện thoại:</p><p className={clsx(styles.value)}>{formatPhoneNumber(stateObj_phone.phone)}</p>
                                        </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Nguồn khách hàng:</p><p className={clsx(styles.value)}>{stateObj_khachhang.nguonKH}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Nhu cầu:</p><p className={clsx(styles.value)}>{stateObj_Giaodich.nhucau}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Ngân hàng:</p><p className={clsx(styles.value)}>{stateObj_Giaodich.nganhangId}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Loại thẻ:</p><p className={clsx(styles.value)}>{stateObj_the.loaiThe}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Số tiền rút đáo:</p><p className={clsx(styles.value)}>{formatThoudsand(stateObj_Giaodich.tienrutdao)} vnđ</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Điểm đến ship:</p><p className={clsx(styles.value)}>{stateObj_Giaodich.diemdenship}</p>
                                    </div>
                                </div>
                                <div className={clsx(styles.right)}>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Loại giao dịch:</p><p className={clsx(styles.value)}>{stateObj_Giaodich.loaigiaodich}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Phí rút đáo: <span className={clsx(styles.notRequired)}>*</span></p><p className={clsx(styles.value)}>{stateObj_Giaodich.phirutdao} %</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Chi nhánh:</p><p className={clsx(styles.value)}>{stateObj_Giaodich.tenchinhanh}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Tiền phí:</p><p className={clsx(styles.value)}>{formatThoudsand(stateObj_Giaodich.tienphi)} vnđ</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Tiền ship:</p><p className={clsx(styles.value)}>{formatThoudsand(stateObj_Giaodich.tienship)} vnđ</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Trạng thái:</p><p className={clsx(styles.value)}>{stateObj_Giaodich.trangthaiCSKH}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Ngày dự kiến liên hệ lại:</p><p className={clsx(styles.value)}>{formatDateTime(stateObj_Giaodich.ngaydukienlienhelai)}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Ngày hạn thanh toán:</p><p className={clsx(styles.value)}>{extractYYYY_MM_DD(stateObj_Giaodich.ngayhanthanhtoan)}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Ghi chú từ team CSKH: <span className={clsx(styles.notRequired)}>*</span></p><p className={clsx(styles.value)}>{stateObj_Giaodich.ghichucskh}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Thành phố: <span className={clsx(styles.notRequired)}>*</span></p><p className={clsx(styles.value)}>{stateObj_khachhang.city}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Quận: <span className={clsx(styles.notRequired)}>*</span></p><p className={clsx(styles.value)}>{stateObj_khachhang.district}</p>
                                    </div>
                                    <div className={clsx(styles.row)}>
                                        <p className={clsx(styles.key)}>Phường: <span className={clsx(styles.notRequired)}>*</span></p><p className={clsx(styles.value)}>{stateObj_khachhang.ward}</p>
                                    </div>
                                </div>
                            </div>
                
                        </div>
                    </>
                }
                {/* Dialog finalCheck end */}
                {/* Dialog suggestion start */}
                {
                    stateBoolIsDialog_suggestionsCskhAppear && //nó phải true thì mới chạy khối code này, tránh việc chạy ngầm dẫn đến lỗi khi chưa có dữ liệu
                    <>
                        {/* back shadow */}
                        <div className={clsx(styles.backShadowSuggestions,{[styles.appear]: stateBoolIsDialog_suggestionsCskhAppear})} onClick={cancelDialogSuggestions}>
                        </div>
                        {/* dialog box */}
                        <div className={clsx(styles.dialogSuggestions, {[styles.appear]: stateBoolIsDialog_suggestionsCskhAppear})}>
                            <h2>Gợi ý khách hàng cũ theo tên và số điện thoại <span className={clsx(styles.iconClose)} onClick={closeDialogSuggest}><CloseIcon/></span></h2>
                            <div className={clsx(styles.heading)}>
                                <p>STT</p>
                                <p>Tên Nhân viên</p>
                                <p>Tên khách hàng</p>
                                <p>SĐT KH</p>
                                <p>Tạo lúc</p>
                                <p>CCCD/CMND</p>
                                <p>Thành phố</p>
                                <p>Quận</p>
                                <p>Chọn</p>
                            </div>
                            {
                                stateArrGiaodichShow2.map((obj, index) => {
                                    return ( 
                                        <div className={clsx(styles.row)} key={obj.id}>
                                            <p>{index+1}</p>
                                            <p>{obj.fullNameNVCSKHcreate}</p>
                                            <p>{obj.fullNameKH}</p>
                                            <p>
                                                {obj.phone.map((obj:any, phoneIndex:any) => (
                                                    <span key={phoneIndex}>{obj.phone}<br/></span>
                                                ))}
                                            </p>
                                            <p>{formatDateString(obj.createdAt)}</p>
                                            <p>{obj.cccd}</p>
                                            <p>{obj.city}</p>
                                            <p>{obj.district}</p>
                                            <p><button onClick={()=>selectOldKHFromSuggestions(obj.id)}>Chọn</button></p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }
                {/* Dialog suggestion end */}

                
            </div>
        </>
    )
}

export default CskhThongTin
