import React, { useState } from 'react';
import { retrieveObjectFromLocalStorage, wait } from '../../share/sharedFunction';
import collectionAPI from '../../../API/collectionAPI';
import styles from './Rutdao.module.scss' 
import clsx from 'clsx';

const UploadPicture = ({ stateNum_idGiaodich, selectGiaodich }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length <= 0) {
        return;
    }
    const imageFile = droppedFiles[0];
    const maxSize = 1000 * 1024; // 500KB in bytes
    if (imageFile.size > maxSize) {
      alert('Hình ảnh phải có dung lượng dưới 1MB (1000KB). Vui lòng chọn hình khác');
      return;
    }
    // Do something with the dropped image file, for example, display it or upload it.
    // console.log('Dropped Image File:', imageFile);

    const formData = new FormData();
    formData.append('file', imageFile);
    const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
    if(userExist === null){
        return;
    }
    const token = userExist.token;

    try {
        // upload hình lên google cloud service storage
        let response1 = await collectionAPI.collectionAPI.uploadFile(token, formData);
        // upload xong nó sẽ trả ra cái mediaId và save vào bảng media
        const mediaId = response1.data.id;
        const objHinhGiaoDich = {
            giaodichId: stateNum_idGiaodich,
            mediaId: mediaId
        }
        // console.log('objHinhGiaoDich: ', objHinhGiaoDich);
        // update cái mediaId vừa có được kèm theo giaodichId của nó vào bảng HinhGiaoDich, sau này khi lấy giao dịch nào đó theo giaodichId, nó sẽ tự kèm theo hình có signedUrl
        let response2 = await collectionAPI.collectionAPI.createHinhGiaoDich(token, objHinhGiaoDich);
        // console.log('response2: ', response2);
        // ta update cái media vào giao dịch, phải lấy cả các mediaId cũ để truyền vào, nếu ko thì nó sẽ ghi đè
        // mỗi lần tìm giao dịch theo id, nó sẽ gửi mediaId lên GCS để tìm cái signedUrl để có đường dẫn hình hiện ra FE
        // let response2 = await collectionAPI.collectionAPI.updateGiaodichById(token, stateNum_idGiaodich, {
        //     hinhGiaoDich: [mediaId]
        // });
        await wait(1000); // phải chờ 5s để nó lưu trên GCS xong sau đó mới lấy trên đó về
        selectGiaodich(stateNum_idGiaodich);

    } catch (err) {
        console.log('err:', err);
    }
    
  };

  return (
        <div
            className={clsx('dropZone', { dragging: isDragging }, styles.dropZone)} // Use clsx to conditionally apply classes
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            // onClick={handleImageClick}
            >
            <p>Hình tổng quát</p>
        </div>
  );
};

export default UploadPicture;
