import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from './general.module.scss'

function NotYetSignIn() {

   const navigate = useNavigate(); //hook dùng để chuyển trang web

    const backToSignIn = () => {

        navigate("/signin", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />
        
    }
    return (
        <div className={clsx(styles.component_NotYetSignIn)}>
            <h1>Phần này cần phải đăng nhập mới được phép xem !</h1>
            <h3>
                Bấm vào đây để quay lại trang sign in
                <span className={clsx(styles.btnSignIn)} onClick={backToSignIn}>
                    Back to Sign in
                </span>
            </h3>

        </div>
    )
}
export default NotYetSignIn