import { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './KetoanTonghop.module.scss'
import { addOneDay, convertDateFormat_removeYear, convertDateFormat_removeYearHourMinute, formatThoudsand, getCurrentDate, getCurrentDateTimeVietNam, getCurrentDateYYYYMMDD, getFirstAndLastDayOfMonth, getFirstAndLastDayOfMonthOfYear, getFirstAndLastDayOfYear, getFirstAndLastDayOfYearParameter, getStartAndEndOfWeek, retrieveObjectFromLocalStorage, splitMonthYear, storeObjectInLocalStorage, wait } from '../../share/sharedFunction';
import Tooltip from '@mui/material/Tooltip';
import NotYetSignIn from '../general/NotYetSignIn';
import collectionAPI from '../../../API/collectionAPI';
import { arrEmail3 } from '../../share/arrRole';
import SearchIcon from '@mui/icons-material/Search';

function KetoanTonghop() {

    const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
        email: "",
        fullName: "",
        role: "",
        token:""
    })
    const checkSignin = () => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        // console.log('userExist: ', userExist);
        if(userExist === null){
            return
        }
        setStateObjSignIn(userExist);
    
    }
    const recallStartDateEndDateFromLocalStorage = () => {
    
        let obj = retrieveObjectFromLocalStorage("tonghopStartDateEndDate");
        const nhucau = retrieveObjectFromLocalStorage("tonghop_nhucau");

        if(obj === null || obj === "" || obj === undefined){
            let today = getCurrentDate();
            let end = addOneDay(today);
            obj = {
                startDate: '',
                endDate: ''
            }
            obj.startDate = today;
            obj.endDate = end;
            setStateString_startTime(obj.startDate);
            setStateString_endTime(obj.endDate);
            setStateString_singleDay(obj.startDate);
        }
    
        if(nhucau === null || nhucau === "" || nhucau === undefined){
            setStateString_nhucau("Đáo hạn");
        }
        else {
            setStateString_nhucau(nhucau);
        }
        // console.log('obj: ', obj);
        getDataByRange(obj.startDate, obj.endDate);

    }

    const loadDataThisWeekAtBeginning = () => {
    
        // this week
        const obj = getStartAndEndOfWeek()
        let start = obj.startOfWeek;
        let end = obj.endOfWeek;
        setStateString_timeFrame("Tuần này");
        setStateString_startTime(start);
        setStateString_endTime(end);
        getDataByRange(start, end);
    
    }
    useEffect(() => {
        loadDataThisWeekAtBeginning()
        // recallStartDateEndDateFromLocalStorage();
        checkSignin();
    },[]);

    const [stateString_singleDay, setStateString_singleDay] = useState<string>("");
    const [stateString_startTime, setStateString_startTime] = useState<string>("");
    const [stateString_endTime, setStateString_endTime] = useState<string>("");

    const handleChange_singleDay = async (event:any) => { 
        let start = event.target.value;
        let end = addOneDay(start);
        setStateString_startTime(start);
        setStateString_endTime(end); // chỉ để hiện ra giao diện
        setStateString_singleDay(start); // chỉ để hiện ra giao diện
        const obj = {
            startDate: start,
            endDate: end
        }
        localStorage.removeItem("tonghopStartDateEndDate"); // bỏ cái cũ đi
        storeObjectInLocalStorage("tonghopStartDateEndDate", obj, 72);
        // vào back end thì ngày sau sẽ lấy 23:59 phút nên nếu lấy ngày mai thì nó sẽ dư ra 1 ngày
        // ví dụ: Trường hợp đúng: ngày 20 thì start là 20 và end cũng là 20, nhưng vào BE thì start tính từ 00:00 ngày 20 còn end tính tới 23:59 ngày 20
        // ví dụ: Trường hợp sai: ngày 20 thì start là 20 và end là 21, nhưng vào BE thì start tính từ 00:00 ngày 20 còn end tính tới 23:59 ngày 21
        // => vậy trường hợp sai khi ta + thêm 1 ngày thì nó trái với ý định lấy ngày hôm nay của ta
        getDataByRange(start, start);
    };
    const handleChange_startTime = (event:any) => { 
        // console.log('event.target.value: ', event.target.value);
        setStateString_startTime(event.target.value) 
    }; 
    const handleChange_endTime = async (event:any) => { 
        // console.log('stateString_startTime: ', stateString_startTime);
        if(stateString_startTime === "" || stateString_startTime === null || stateString_startTime === undefined){
            alert("Vui lòng chọn ngày bắt đầu trước khi chọn ngày kết thúc ! Xin cảm ơn");
            return;
        }
        const endDate = event.target.value;
        if(endDate === "" || endDate === null || endDate === undefined){
            alert("Vui lòng chọn ngày ngày kết thúc ! Xin cảm ơn");
            return;
        }
        // console.log('endDate: ', endDate);
        setStateString_endTime(endDate);
        const obj = {
            startDate: stateString_startTime,
            endDate: endDate
        }
        localStorage.removeItem("tonghopStartDateEndDate"); // bỏ cái cũ đi
        storeObjectInLocalStorage("tonghopStartDateEndDate", obj, 72);

        getDataByRange(stateString_startTime, endDate)
        setStateString_singleDay("");
        setStateString_timeFrame("Chọn...");
    }; 
    const [stateString_timeFrame, setStateString_timeFrame] = useState<string>("Chọn...");

    const handleChange_timeFrame = async (event:any) => {
        
        const timeFrame = event.target.value;
        if(timeFrame === "Chọn..."){
            alert("Bạn phải chọn 1 khung thời gian cụ thể trong danh sách !");
            return;
        }
        setStateString_timeFrame(timeFrame);

        const listMonthYear = [
            "12-2023",
            "01-2024",
            "02-2024",
            "03-2024",
            "04-2024"
        ]

        let start = ""
        let end = ""
        if(timeFrame === "Hôm nay"){
            start = getCurrentDateYYYYMMDD();
            // vào back end thì ngày sau sẽ lấy 23:59 phút nên nếu lấy ngày mai thì nó sẽ dư ra 1 ngày
            // ví dụ: Trường hợp đúng: ngày 20 thì start là 20 và end cũng là 20, nhưng vào BE thì start tính từ 00:00 ngày 20 còn end tính tới 23:59 ngày 20
            // ví dụ: Trường hợp sai: ngày 20 thì start là 20 và end là 21, nhưng vào BE thì start tính từ 00:00 ngày 20 còn end tính tới 23:59 ngày 21
            // => vậy trường hợp sai khi ta + thêm 1 ngày thì nó trái với ý định lấy ngày hôm nay của ta

            end = getCurrentDateYYYYMMDD(); 
            // end = addOneDay(start);
        }
        else if (timeFrame === "Tuần này"){
            let obj = getStartAndEndOfWeek();
            start = obj.startOfWeek;
            end = obj.endOfWeek;
        }
        else if (timeFrame === "Tháng này"){
            let obj = getFirstAndLastDayOfMonth();
            start = obj.firstDayOfMonth;
            end = obj.lastDayOfMonth;
        }
        else if (timeFrame === "Năm nay"){
            let obj = getFirstAndLastDayOfYear();
            start = obj.firstDayOfYear;
            end = obj.lastDayOfYear;
        }
        else if (listMonthYear.includes(timeFrame)){
            const {month, year} = splitMonthYear(timeFrame)
            const {firstDay, lastDay} = getFirstAndLastDayOfMonthOfYear(month, year);

            start = firstDay;
            end = lastDay;
        }
        else if(timeFrame === "2024" || timeFrame === "2025"){
            const {firstDay, lastDay} = getFirstAndLastDayOfYearParameter(timeFrame);
            start = firstDay;
            end = lastDay;
        }

        setStateString_startTime(start);
        setStateString_endTime(end);
        setStateString_singleDay("");
        const obj = {  
            startDate: start,
            endDate: end
        }
        localStorage.removeItem("tonghopStartDateEndDate"); // bỏ cái cũ đi
        storeObjectInLocalStorage("tonghopStartDateEndDate", obj, 72);
        getDataByRange(start, end);
    
    };
    const [stateString_nhucau, setStateString_nhucau] = useState("Rút & Đáo"); // string
    const handleChange_nhucau = (e:any) => {
        const nhucau = e.target.value;
        setStateString_nhucau(nhucau);
        localStorage.removeItem("tonghop_nhucau"); // bỏ cái cũ đi
        storeObjectInLocalStorage("tonghop_nhucau", nhucau, 72);

    }

    const [stateArr_rut, setStateArr_rut] = useState<any[]>([]);
    const [stateArr_dao, setStateArr_dao] = useState<any[]>([]);
    const [stateArr_rutShow, setStateArr_rutShow] = useState<any[]>([]);
    const [stateArr_daoShow, setStateArr_daoShow] = useState<any[]>([]);

    const [checkedItems_dao, setCheckedItems_dao] = useState<{ [key: string]: boolean }>({}); // chứa các id true
    const [checkedItems_rut, setCheckedItems_rut] = useState<{ [key: string]: boolean }>({}); // chứa các id true

    const [stateNum_tienPhiPosSum_rut, setStateNum_tienPhiPosSum_rut] = useState(0);
    const [stateNum_tienPosVeSum_rut, setStateNum_tienPosVeSum_rut] = useState(0);
    const [stateNum_loiNhuanSum_rut, setStateNum_loiNhuanSum_rut] = useState(0);

    const [stateNum_tienPhiPosSum_dao, setStateNum_tienPhiPosSum_dao] = useState(0);
    const [stateNum_tienPosVeSum_dao, setStateNum_tienPosVeSum_dao] = useState(0);
    const [stateNum_loiNhuanSum_dao, setStateNum_loiNhuanSum_dao] = useState(0);

    const [stateNum_tienPhiPosSum_rut_dao, setStateNum_tienPhiPosSum_rut_dao] = useState(0);
    const [stateNum_tienPosVeSum_rut_dao, setStateNum_tienPosVeSum_rut_dao] = useState(0);
    const [stateNum_loiNhuanSum_rut_dao, setStateNum_loiNhuanSum_rut_dao] = useState(0);

    const [stateBool_haveDataRut, setStateBool_haveDataRut] = useState(false); // boolean
    const [stateBool_haveDataDao, setStateBool_haveDataDao] = useState(false); // boolean

    const getDataByRange = async (startDay: string, endDay: string) => {
        setStateBool_haveDataRut(false)
        setStateBool_haveDataDao(false)
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        let token = userExist.token;
        try {
            const response: any = await collectionAPI.collectionAPI.getRutDaoRecordsForDateRange(token, startDay, endDay);
            const objSumRut = response?.data?.objSumRut;
            const sortedArray_rut = response?.data?.arrGiaodichRut.sort((a: any, b: any) => a.id - b.id);
            // console.log('sortedArray_rut: ', sortedArray_rut);
            if(sortedArray_rut !== null || sortedArray_rut !== undefined){
                setStateBool_haveDataRut(true)
            }
            
            // let arrGiaoDichId_rut = [];
            let arrBoolRut = [];

            let tienPhiPosSum_rut = objSumRut.tienPhiPosSum_rut;
            let tienPosVeSum_rut = objSumRut.tienPosVeSum_rut;
            let loiNhuanSum_rut = objSumRut.loiNhuanSum_rut;

            for (let obj of sortedArray_rut) {
                // console.log('obj: ', obj);
                // arrGiaoDichId_rut.push(obj.giaodichId)
                let id = obj.id;
                // let bool = obj.giaodich.isApproved;
                let bool = obj.xacNhantienPosVe;
                if(obj.xacNhantienPosVe === true){
                    bool = true;
                }
                else if(obj.xacNhantienPosVe === false){
                    bool = false;

                }
                else if(obj.xacNhantienPosVe === null){
                    bool = false;
                }
                let objBool: { [key: string]: boolean } = {};
                objBool[id] = bool;
                arrBoolRut.push(objBool);

            }

            setStateNum_tienPhiPosSum_rut(tienPhiPosSum_rut);
            setStateNum_tienPosVeSum_rut(tienPosVeSum_rut);
            setStateNum_loiNhuanSum_rut(loiNhuanSum_rut);

            // Merge objects in arrBoolRut into a single object
            const mergedCheckedItems_rut = arrBoolRut.reduce((acc, obj) => {
                return { ...acc, ...obj };
            }, {});
            await wait (100);

            // console.log('mergedCheckedItems_rut: ', mergedCheckedItems_rut);

            setCheckedItems_rut(mergedCheckedItems_rut);

            setStateArr_rut(sortedArray_rut);
            setStateArr_rutShow(sortedArray_rut);


            // const responseDao: any = await collectionAPI.collectionAPI.getDaoRecordsForDateRange2(token, startDay, endDay);
            // console.log('response.data dao: ', responseDao.data);
            const objSumDao = response?.data?.objSumDao;

            const sortedArray_dao = response.data.arrGiaodichDao.sort((a: any, b: any) => a.id - b.id);
            // console.log('sortedArray_dao: ', sortedArray_dao);
            // let arrGiaoDichId_dao = [];
            let arrBoolDao = [];

            
            let tienPhiPosSum_dao = objSumDao.tienPhiPosSum_dao;
            let tienPosVeSum_dao = objSumDao.tienPosVeSum_dao;
            let loiNhuanSum_dao = objSumDao.loiNhuanSum_dao;

            for (let obj of sortedArray_dao) {
                // arrGiaoDichId_dao.push(obj.giaodichId)
                let id = obj.id;
                let bool = obj.xacNhantienPosVe;
                if(obj.xacNhantienPosVe === true){
                    bool = true;
                }
                else if(obj.xacNhantienPosVe === false){
                    bool = false;

                }
                else if(obj.xacNhantienPosVe === null){
                    bool = false;
                }
                let objBool: { [key: string]: boolean } = {};
                objBool[id] = bool;
                arrBoolDao.push(objBool);

            }
            setStateNum_tienPhiPosSum_dao(tienPhiPosSum_dao);
            setStateNum_tienPosVeSum_dao(tienPosVeSum_dao);
            setStateNum_loiNhuanSum_dao(loiNhuanSum_dao);

            const objSumRutDao = response?.data?.objSumRutDao;

            const tienPhiPosSum_rut_dao = objSumRutDao.tienPhiPosSum_rut_dao;
            const tienPosVeSum_rut_dao = objSumRutDao.tienPosVeSum_rut_dao;
            const loiNhuanSum_rut_dao = objSumRutDao.loiNhuanSum_rut_dao;


            setStateNum_tienPhiPosSum_rut_dao(tienPhiPosSum_rut_dao);
            setStateNum_tienPosVeSum_rut_dao(tienPosVeSum_rut_dao);
            setStateNum_loiNhuanSum_rut_dao(loiNhuanSum_rut_dao);

            // Merge objects in arrBoolDao into a single object
            const mergedCheckedItems_dao = arrBoolDao.reduce((acc, obj) => {
                return { ...acc, ...obj };
            }, {});
            await wait (100);

            setCheckedItems_dao(mergedCheckedItems_dao);

            setStateArr_dao(sortedArray_dao);
            setStateArr_daoShow(sortedArray_dao);
            if(sortedArray_dao !== null || sortedArray_dao !== undefined){
                setStateBool_haveDataDao(true)
            }
        }catch(err){
            // console.log('err:', err);
        }
    }
    // chỉ có kế toán đc check thôi
    const handleCheckboxChange_dao = async (event:any) => {

        let token = stateObjSignIn.token;
        let email = stateObjSignIn.email;
        if(!arrEmail3.includes(email)){
            alert("Chỉ có kế toán mới có quyền xác nhận hoàn thành, xin cảm ơn !");
            return;
        }
        const { name, checked } = event.target; // name chính là id của dòng dao đó

        setCheckedItems_dao({ ...checkedItems_dao, [name]: checked });
        // console.log('name: ', name); // name chính là id của dòng dao đó
        // console.log('checked: ', checked); // checked là true nếu check vào, là false nếu uncheck
        const now = getCurrentDateTimeVietNam();
        // STOP HERE
        try {
            if (checked === true) {
                await Promise.all([
                    collectionAPI.collectionAPI.updateFieldDaoById(token, name, "xacNhantienPosVe", "true"),
                    collectionAPI.collectionAPI.updateFieldDaoById(token, name, "confirmedAt", now),
                ]);
                setStateArr_dao(prevState => {
                    return prevState.map(item => {
                        if (item.id === name) {
                            return { ...item, xacNhantienPosVe: true };
                        }
                        return item;
                    });
                });

            } else {
                await Promise.all([
                    collectionAPI.collectionAPI.updateFieldDaoById(token, name, "xacNhantienPosVe", "false"),
                    collectionAPI.collectionAPI.updateFieldDaoById(token, name, "confirmedAt", now),
                ]);
        
                setStateArr_dao(prevState => {
                    return prevState.map(item => {
                        if (item.giaodichId === name) {
                            return { ...item, xacNhantienPosVe: true };
                        }
                        return item;
                    });
                });
            }
        } catch (err) {
            // console.log('err:', err);
        }

    };
    // chỉ có kế toán đc check thôi
    const handleCheckboxChange_rut = async (event:any) => {

        let token = stateObjSignIn.token;
        let email = stateObjSignIn.email;
        if(!arrEmail3.includes(email)){
            alert("Chỉ có kế toán mới có quyền xác nhận hoàn thành, xin cảm ơn !");
            return;
        }
        const { name, checked } = event.target; // name chính là giaodichId
        // console.log('name: ', name);
        // console.log('checked: ', checked);
        setCheckedItems_rut({ ...checkedItems_rut, [name]: checked });
        // console.log('name: ', name); // name chính là giaodichId
        // console.log('checked: ', checked); // checked là true nếu check vào, là false nếu uncheck
        const now = getCurrentDateTimeVietNam();

        try {
            if (checked === true) {
                await Promise.all([
                    collectionAPI.collectionAPI.updateFieldRutById(token, name, "xacNhantienPosVe", "true"),
                    collectionAPI.collectionAPI.updateFieldRutById(token, name, "confirmedAt", now),
                ]);
                setStateArr_rut(prevState => {
                    return prevState.map(item => {
                        if (item.id === name) {
                            return { ...item, xacNhantienPosVe: true };
                        }
                        return item;
                    });
                });

            } else {
                await Promise.all([
                    collectionAPI.collectionAPI.updateFieldRutById(token, name, "xacNhantienPosVe", "false"),
                    collectionAPI.collectionAPI.updateFieldRutById(token, name, "confirmedAt", now),
                ]);
        
                setStateArr_rut(prevState => {
                    return prevState.map(item => {
                        if (item.id === name) {
                            return { ...item, xacNhantienPosVe: false };
                        }
                        return item;
                    });
                });
            }
        } catch (err) {
            // console.log('err:', err);
        }

    };
    
    const [stateBool_isOpenDialog, setStateBool_isOpenDialog] = useState(false); // boolean
    const [stateString_selectedImg, setStateString_selectedImg] = useState<string>(''); // string
    const selectImg = (imgUrl: string) => {
    
        setStateBool_isOpenDialog(true);
        setStateString_selectedImg(imgUrl);
    
    }
    const cancelDialog = () => {
    
        setStateBool_isOpenDialog(false);
        setStateString_selectedImg('');
    
    }
    const [stateString_searchText, setStateString_searchText] = useState<string>('');

    const handleChangeSeachInput = (event: any) => {
        let searchText = event.target.value;
        // console.log('searchText: ', searchText);
        setStateString_searchText(searchText);

        let resultDao = stateArr_dao.filter(obj =>
            obj.giaodich.the[0]?.tenTrenThe.toLowerCase().includes(searchText.toLowerCase())||
            obj.soBill.toLowerCase().includes(searchText.toLowerCase())||
            obj.soLo.toLowerCase().includes(searchText.toLowerCase())
            );
        let resultRut = stateArr_rut.filter(obj =>
            obj.giaodich.the[0]?.tenTrenThe.toLowerCase().includes(searchText.toLowerCase())||
            obj.soBill.toLowerCase().includes(searchText.toLowerCase())||
            obj.soLo.toLowerCase().includes(searchText.toLowerCase())
        );
        // console.log('resultRut: ', resultRut);
        if(searchText === ""){

            setStateArr_daoShow(stateArr_dao);
            setStateArr_rutShow(stateArr_rut);
        }
        else {
            setStateArr_daoShow(resultDao);
            setStateArr_rutShow(resultRut);
        }
    };
    return (
        stateObjSignIn.token === "" ? <NotYetSignIn /> :
        <div className={clsx(styles.component_KetoanTonghop)}>
            <h4>TỔNG HỢP</h4>
            <div className={clsx(styles.navigation)}>
                <div className={clsx(styles.chooseDate)}>
                    <p className={clsx(styles.key)}>Ngày </p>
                    <input type="date" 
                        className={clsx(styles.inputDate)} 
                        value={stateString_singleDay} 
                        onChange={handleChange_singleDay}/>
                </div>
                <div className={clsx(styles.dateRange)}>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Bắt đầu</p>
                        <input type="date" 
                            className={clsx(styles.inputDate)} 
                            value={stateString_startTime} 
                            onChange={handleChange_startTime}/>
                    </div>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Kết thúc</p>
                        <input type="date" 
                            className={clsx(styles.inputDate)} 
                            value={stateString_endTime} 
                            onChange={handleChange_endTime}/>
                    </div>
                </div>
                <div className={clsx(styles.timeWrapper)}>
                    <p className={clsx(styles.key)}>Khung thời gian</p>

                    <select name="from" onChange={handleChange_timeFrame} className={clsx(styles.selectOption)} value={stateString_timeFrame}>
                        <option value="Chọn...">Chọn...</option>
                        <option value="Hôm nay">Hôm nay</option>
                        <option value="Tuần này">Tuần này</option>
                        <option value="Tháng này">Tháng này</option>
                        <option value="Năm nay">Năm nay</option>
                        <option value="01-2024">01-2024</option>
                        <option value="02-2024">02-2024</option>
                        <option value="03-2024">03-2024</option>
                        <option value="04-2024">04-2024</option>
                        <option value="05-2024">05-2024</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                    </select>
                </div>
        
                <div className={clsx(styles.nhucau)}>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="nhucau" checked={stateString_nhucau === "Rút & Đáo"} value="Rút & Đáo" className={clsx(styles.item)} 
                        onChange={handleChange_nhucau}/> <p>Rút & Đáo</p>
                    </div>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="nhucau" value="Đáo hạn" className={clsx(styles.item)} 
                        onChange={handleChange_nhucau}/> <p>Đáo hạn</p>
                    </div>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="nhucau" value="Rút tiền" className={clsx(styles.item)} 
                        onChange={handleChange_nhucau}/> <p>Rút tiền</p>
                    </div>
                </div>
                <div className={clsx(styles.searchWrapper)}>
                    <div className={clsx(styles.searchGroup)}>
                        <SearchIcon className={clsx(styles.iconSearch)} />
                        <input
                            type="text"
                            placeholder="Tìm tên trên thẻ, bill lô"
                            value={stateString_searchText}
                            onChange={handleChangeSeachInput}
                        />
                    </div>
                </div>
                <div className={clsx(styles.filter)}>
                    <div className={clsx(styles.box)}>
                        <p className={clsx(styles.label)}>Bắt đầu</p>
                        <p className={clsx(styles.value)}>{stateString_startTime}</p>
                    </div>
                    <div className={clsx(styles.box, styles.end)}>
                        <p className={clsx(styles.label)}>Kết thúc</p>
                        <p className={clsx(styles.value)}>{stateString_endTime}</p>
                    </div>
                </div>
            </div>
            {/* end navigation */}
            <div className={clsx(styles.table)}>
     
                <div className={clsx(styles.heading)}>
                    <p>ID <br />GD</p>
                    <Tooltip title="Trong 1 giao dịch sẽ có nhiều lần rút tiền qua máy POS, mỗi lần rút tiền sẽ có số Lô từ máy POS in ra " placement="top">
                        <p>Lô</p>
                    </Tooltip>
                    <Tooltip title="Trong 1 giao dịch sẽ có nhiều lần rút tiền qua máy POS, mỗi lần rút tiền sẽ có số Bill từ máy POS in ra " placement="top">
                        <p>Bill</p>
                    </Tooltip>
                    <p>Hình<br/>Lô Bill</p>
                    <p>Máy Pos</p>
                    <p>Tên trên <br />thẻ</p>
                    {/* <p>Số trên <br />thẻ tín dụng</p> */}
                    <Tooltip title="Số tiền khai báo trên máy pos để rút về " placement="top">
                        <p className={clsx(styles.number)}>Tiền rút</p>
                    </Tooltip>
                    <p className={clsx(styles.number)}>Phí Pos</p>
                    <Tooltip title="Tiền phí Pos = Tiền rút x Phí pos " placement="top">
                        <p className={clsx(styles.number)}>Tiền phí<br/> Pos</p>
                    </Tooltip>
                    <Tooltip title="Tiền pos về = Tiền rút - Tiền phí pos ; Số này là mình tự tính nội suy, thực tế có thể cao hơn hay thấp hơn chút xíu khi bank chuyển cho mình" placement="top">
                        <p className={clsx(styles.number)}>Tiền Pos về <br />(nội suy)</p>
                    </Tooltip>
                    <Tooltip title="Phí thu từ khách hàng khi sử dụng dịch vụ rút đáo / 1 giao dịch" placement="top">
                        <p className={clsx(styles.number)}>Phí khách</p>
                    </Tooltip>
                    <Tooltip title="Tiền phí khách = Tiền rút x Phí khách" placement="top">
                        <p className={clsx(styles.number)}>Tiền phí<br/> khách</p>
                    </Tooltip>
                    <Tooltip title="Lợi nhuận = Tiền phí khách - Tiền Pos về" placement="top">
                        <p className={clsx(styles.number)}>Lợi nhuận</p>
                    </Tooltip>
                    <p>Tạo lúc</p>
                    <Tooltip title="Bình thường hay âm thẻ..." placement="top">
                        <p>Trường<br/>hợp</p>
                    </Tooltip>
                    <Tooltip title="Kế toán cần xác nhận tiền Pos về có phù hợp không" placement="top">
                        <p>Tình<br/>trạng</p>
                    </Tooltip>
                </div>
                {
                    (() => {
                        if (stateString_nhucau === "Rút & Đáo") {
                            return (
                                <>

                                    <div className={clsx(styles.summary)}>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        {/* <div className={clsx(styles.number, styles.sum)}>{formatThoudsand(stateNum_tienPhiPosSum_rut+stateNum_tienPhiPosSum_dao)}</div> */}
                                        <div className={clsx(styles.number, styles.sum)}>{stateNum_tienPhiPosSum_rut_dao.toLocaleString('en-US')}</div>
                                        <div className={clsx(styles.number, styles.sum)}>{stateNum_tienPosVeSum_rut_dao.toLocaleString('en-US')}</div>
                                        <div></div>
                                        <div></div>
                                        <div className={clsx(styles.number, styles.sum)}>{stateNum_loiNhuanSum_rut_dao.toLocaleString('en-US')}</div>
                                        <div></div>
                                    </div>
                                    {
                                        stateBool_haveDataRut === true ?
                                        stateArr_rutShow.map((obj, index) => {
                                            let tienPhiPos = Math.floor((obj.tienRut * obj.maypos.phiMayPos)/100);
                                            let tienPosVe = Math.floor(obj.tienRut - tienPhiPos);
                                            let tienPhiKhach = Math.floor((obj.tienRut * obj.giaodich?.phirutdao)/100);
                                            let loiNhuan = Math.floor(tienPhiKhach - tienPhiPos);
                                            let dacbiet = false;
                                            if(obj.truongHopId !== "Bình thường" && obj.truongHopId !== null){
                                                dacbiet = true;
                                            }

                                            return (
                                                <div key={obj.id} className={clsx(styles.row, {[styles.dacBiet]: dacbiet === true})}>
                                                    <p>{obj?.giaodichId}</p>
                                                    <p>{obj?.soLo}</p>
                                                    <p>{obj?.soBill}</p>
                                                    <p>
                                                        {
                                                            obj.mediaId !== null ?
                                                            <img src={obj.signedUrl} 
                                                                onClick={()=>selectImg(obj.signedUrl)} 
                                                                className="hinh" 
                                                                alt="hinh" />
                                                            :
                                                            <span></span>
                                                        }
                                                    </p>
                                                    <p>{obj.maypos.name}</p>
                                                    <p className={clsx(styles.tenTrenThe)}>{obj.giaodich?.the[0]?.tenTrenThe}</p>
                                                    {/* <p>{obj.giaodich?.the[0]?.soThe}</p> */}
                                                    <p className={clsx(styles.number)}>{formatThoudsand(obj.tienRut)}</p>
                                                    <p className={clsx(styles.number)}>{obj.maypos.phiMayPos} %</p>
                                                    <p className={clsx(styles.number)}>{formatThoudsand(tienPhiPos)}</p>
                                                    <p className={clsx(styles.number)}>{formatThoudsand(tienPosVe)}</p>
                                                    <p className={clsx(styles.number)}>{obj.giaodich?.phirutdao} %</p>
                                                    <p className={clsx(styles.number)}>{formatThoudsand(tienPhiKhach)}</p>
                                                    <p className={clsx(styles.number, styles.bold)}>{formatThoudsand(loiNhuan)}</p>
                                                    <p>{convertDateFormat_removeYearHourMinute(obj.createdAt)}</p>
                                                    <p>{obj.truongHopId === null ? "Bình thường":obj.truongHopId}</p>
                                                    <p >
                                                        <input
                                                            type="checkbox"
                                                            name={obj.id} // Assuming each item has a unique identifier
                                                            checked={checkedItems_rut[obj.id.toString()] || false}
                                                            onChange={handleCheckboxChange_rut}
                                                        />
                                                    </p>
                                                </div>
                                            );
                                        })
                                        :
                                        <img src="../assets/svg/Spinner.svg" className={clsx(styles.loader)} alt="loader" />
                                    }
                                    {
                                        stateBool_haveDataDao === true ?
                                        stateArr_daoShow.map((obj, index) => {
                                            let tienPhiPos = Math.floor((obj.tienRut * obj.maypos.phiMayPos)/100);
                                            let tienPosVe = Math.floor(obj.tienRut - tienPhiPos);
                                            let tienPhiKhach = Math.floor((obj.tienRut * obj.giaodich?.phirutdao)/100);
                                            let loiNhuan = Math.floor(tienPhiKhach - tienPhiPos);
                                            let dacbiet = false;
                                            if(obj.truongHopId !== "Bình thường" && obj.truongHopId !== null){
                                                dacbiet = true;
                                            }
                                            return (
                                                <div key={obj.id} className={clsx(styles.row, {[styles.dacBiet]: dacbiet === true})}>
                                                    <p>{obj?.giaodichId}</p>
                                                    <p>{obj?.soLo}</p>
                                                    <p>{obj?.soBill}</p>
                                                    <p>
                                                        {
                                                            obj.mediaId_rut !== null ?
                                                            <img src={obj.signedUrl_rut} 
                                                                onClick={()=>selectImg(obj.signedUrl_rut)} 
                                                                className="hinh" 
                                                                alt="hinh" />
                                                            :
                                                            <span></span>
                                                        }
                                                    </p>
                                                    <p>{obj.maypos.name}</p>
                                                    <p className={clsx(styles.tenTrenThe)}>{obj.giaodich?.the[0]?.tenTrenThe}</p>
                                                    {/* <p>{obj.giaodich?.the[0]?.soThe}</p> */}
                                                    <p className={clsx(styles.number)}>{formatThoudsand(obj.tienRut)}</p>
                                                    <p className={clsx(styles.number)}>{obj.maypos.phiMayPos}</p>
                                                    <p className={clsx(styles.number)}>{formatThoudsand(tienPhiPos)}</p>
                                                    <p className={clsx(styles.number)}>{formatThoudsand(tienPosVe)}</p>
                                                    <p className={clsx(styles.number)}>{obj.giaodich?.phirutdao}</p>
                                                    <p className={clsx(styles.number)}>{formatThoudsand(tienPhiKhach)}</p>
                                                    <p className={clsx(styles.number, styles.bold)}>{formatThoudsand(loiNhuan)}</p>
                                                    <p>{convertDateFormat_removeYearHourMinute(obj.createdAt)}</p>
                                                    <p>{obj.truongHopId === null ? "Bình thường":obj.truongHopId}</p>
                                                    <p >
                                                        <input
                                                            type="checkbox"
                                                            name={obj.id} // Assuming each item has a unique identifier
                                                            checked={checkedItems_dao[obj.id.toString()] || false}
                                                            onChange={handleCheckboxChange_dao}
                                                        />
                                                    </p>
                                                </div>
                                            );
                                        })
                                        :
                                        <img src="../assets/svg/Spinner.svg" className={clsx(styles.loader)} alt="loader" />
                                    }
                                </>
                            );
                
                        } 
                        else if(stateString_nhucau === "Rút tiền") {
                            return (
                                <>
                                    <div className={clsx(styles.summary)}>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div className={clsx(styles.number, styles.sum)}>{formatThoudsand(stateNum_tienPhiPosSum_rut)}</div>
                                    <div className={clsx(styles.number, styles.sum)}>{formatThoudsand(stateNum_tienPosVeSum_rut)}</div>
                                    <div></div>
                                    <div></div>
                                    <div className={clsx(styles.number, styles.sum)}>{formatThoudsand(stateNum_loiNhuanSum_rut)}</div>
                                    <div></div>
                                </div>
                                {
                                    stateBool_haveDataRut === true ?
                                    stateArr_rutShow.map((obj, index) => {
                                        let tienPhiPos = Math.floor((obj.tienRut * obj.maypos.phiMayPos)/100);
                                        let tienPosVe = Math.floor(obj.tienRut - tienPhiPos);
                                        let tienPhiKhach = Math.floor((obj.tienRut * obj.giaodich?.phirutdao)/100);
                                        let loiNhuan = Math.floor(tienPhiKhach - tienPhiPos);
                                        let dacbiet = false;
                                        if(obj.truongHopId !== "Bình thường" && obj.truongHopId !== null){
                                            dacbiet = true;
                                        }
                                
                                        return (
                                            <div key={obj.id} className={clsx(styles.row, {[styles.dacBiet]: dacbiet === true})}>
                                                <p>{obj?.giaodichId}</p>
                                                <p>{obj.soLo}</p>
                                                <p>{obj.soBill}</p>
                                                <p>
                                                    {
                                                        obj.mediaId !== null ?
                                                        <img src={obj.signedUrl} 
                                                            onClick={()=>selectImg(obj.signedUrl)} 
                                                            className="hinh" 
                                                            alt="hinh" />
                                                        :
                                                        <span></span>
                                                    }
                                                </p>
                                                <p>{obj.maypos.name}</p>
                                                <p className={clsx(styles.tenTrenThe)}>{obj.giaodich?.the[0]?.tenTrenThe}</p>
                                                {/* <p>{obj.giaodich?.the[0]?.soThe}</p> */}
                                                <p className={clsx(styles.number)}>{formatThoudsand(obj.tienRut)}</p>
                                                <p className={clsx(styles.number)}>{obj.maypos.phiMayPos} %</p>
                                                <p className={clsx(styles.number)}>{formatThoudsand(tienPhiPos)}</p>
                                                <p className={clsx(styles.number)}>{formatThoudsand(tienPosVe)}</p>
                                                <p className={clsx(styles.number)}>{obj.giaodich?.phirutdao} %</p>
                                                <p className={clsx(styles.number)}>{formatThoudsand(tienPhiKhach)}</p>
                                                <p className={clsx(styles.number, styles.bold)}>{formatThoudsand(loiNhuan)}</p>
                                                <p >{convertDateFormat_removeYearHourMinute(obj.createdAt)}</p>
                                                <p>{obj.truongHopId === null ? "Bình thường":obj.truongHopId}</p>
                                                <p >
                                                    <input
                                                        type="checkbox"
                                                        name={obj.id} // Assuming each item has a unique identifier
                                                        checked={checkedItems_rut[obj.id.toString()] || false}
                                                        onChange={handleCheckboxChange_rut}
                                                    />
                                                </p>
                                            </div>
                                        );
                                    })
                                    :
                                    <img src="../assets/svg/Spinner.svg" className={clsx(styles.loader)} alt="loader" />
                                }
                                </>
                            );
                        }
                        else if(stateString_nhucau === "Đáo hạn") {
                            return (
                                <>
                                    <div className={clsx(styles.summary)}>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div className={clsx(styles.number, styles.sum)}>{formatThoudsand(stateNum_tienPhiPosSum_dao)}</div>
                                        <div className={clsx(styles.number, styles.sum)}>{formatThoudsand(stateNum_tienPosVeSum_dao)}</div>
                                        <div></div>
                                        <div></div>
                                        <div className={clsx(styles.number, styles.sum)}>{formatThoudsand(stateNum_loiNhuanSum_dao)}</div>
                                        <div></div>
                                    </div>
                                    {
                                        stateBool_haveDataDao === true ?
                                        stateArr_daoShow.map((obj, index) => {
                                            let tienPhiPos = Math.floor((obj.tienRut * obj.maypos.phiMayPos)/100);
                                            let tienPosVe = Math.floor(obj.tienRut - tienPhiPos);
                                            let tienPhiKhach = Math.floor((obj.tienRut * obj.giaodich?.phirutdao)/100);
                                            let loiNhuan = Math.floor(tienPhiKhach - tienPhiPos);
                                            let dacbiet = false;
                                            if(obj.truongHopId !== "Bình thường" && obj.truongHopId !== null){
                                                dacbiet = true;
                                            }
                                            return (
                                                <div key={obj.id} className={clsx(styles.row, {[styles.dacBiet]: dacbiet === true})}>
                                                    <p>{obj?.giaodichId}</p>
                                                    <p>{obj.soLo}</p>
                                                    <p>{obj.soBill}</p>
                                                    <p>
                                                        {
                                                            obj.mediaId_rut !== null ?
                                                            <img src={obj.signedUrl_rut} 
                                                                onClick={()=>selectImg(obj.signedUrl_rut)} 
                                                                className="hinh" 
                                                                alt="hinh" />
                                                            :
                                                            <span></span>
                                                        }
                                                    </p>
                                                    <p>{obj.maypos.name}</p>
                                                    <p className={clsx(styles.tenTrenThe)}>{obj.giaodich?.the[0]?.tenTrenThe}</p>
                                                    {/* <p>{obj.giaodich?.the[0]?.soThe}</p> */}
                                                    <p className={clsx(styles.number)}>{formatThoudsand(obj.tienRut)}</p>
                                                    <p className={clsx(styles.number)}>{obj.maypos.phiMayPos}</p>
                                                    <p className={clsx(styles.number)}>{formatThoudsand(tienPhiPos)}</p>
                                                    <p className={clsx(styles.number)}>{formatThoudsand(tienPosVe)}</p>
                                                    <p className={clsx(styles.number)}>{obj.giaodich?.phirutdao}</p>
                                                    <p className={clsx(styles.number)}>{formatThoudsand(tienPhiKhach)}</p>
                                                    <p className={clsx(styles.number, styles.bold)}>{formatThoudsand(loiNhuan)}</p>
                                                    <p>{convertDateFormat_removeYearHourMinute(obj.createdAt)}</p>
                                                    <p>{obj.truongHopId === null ? "Bình thường":obj.truongHopId}</p>

                                                    <p >
                                                        <input
                                                            type="checkbox"
                                                            name={obj.id} // Assuming each item has a unique identifier
                                                            checked={checkedItems_dao[obj.id.toString()] || false}
                                                            onChange={handleCheckboxChange_dao}
                                                        />
                                                    </p>
                                                </div>
                                            );
                                        })
                                        :
                                        <img src="../assets/svg/Spinner.svg" className={clsx(styles.loader)} alt="loader" />

                                    }
                                </>
                            );
                        }
                    })()
                }
            </div>
            <div className={clsx(styles.backShadow,{[styles.appear]: stateBool_isOpenDialog})} onClick={cancelDialog}>
            </div>
            <div className={clsx(styles.dialogImg, {[styles.appear]: stateBool_isOpenDialog})}>
                <img src={stateString_selectedImg}  alt="hinh anh" />
            </div>
        </div>
    )
}
export default KetoanTonghop