import { useState } from 'react';
import clsx from 'clsx';
import styles from './hr.module.scss'
import Tooltip from '@mui/material/Tooltip';
import collectionAPI from '../../../API/collectionAPI';
import {
   tinhTrungBinhLateTimeCheckOut,
   tinhTrungBinhLateTimeCheckIn,
   tinhSoGioTrungBinhCheckout,
   tinhSoGioTrungBinhCheckin,
   tinhSoNhanVienDiLam,
   tinhSoGioTrungBinhDiLam,
   fullMonth,
   demxuathien,
   getTotaldaysOfMonth,
   formatDateString,
} from '../../share/sharedFunction';
// mui
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// ApexChart
import ReactApexChart from 'react-apexcharts';

function HRBaocao() {
   const [stateArrGroupByEmail, setStateArrGroupByEmail] = useState<any[]>([]); //typescript array state
   const [stateArrNghiPhep, setStateArrNghiPhep] = useState<any[]>([]); //typescript array state
   const [stateTotalDaysOfMonth, setStateTotalDaysOfMonth] = useState<any[]>([]);
   const [stateReportSelected, setStateReportSelected] = useState<string>(''); // string
   const [stateBoolReportSelected, setStateBoolReportSelected] = useState(false); // boolean
   const [stateStringTimeReport, setStateStringTimeReport] = useState<string>(''); // string
   const [stateBoolShowStaffDetails, setStateBoolShowStaffDetails] = useState(false); // boolean
   const [stateObjStaff, setStateObjStaff] = useState<any>({})

   const arrReports = [
      {
         year: 2023,
         month: 11
      },
      {
         year: 2023,
         month: 12
      },
      {
         year: 2024,
         month: 1
      },
      {
         year: 2024,
         month: 2
      },
      {
         year: 2024,
         month: 3
      }

   ];
   const selectReport = (year:any, month: any) => {
      setStateStringTimeReport(`${month}-${year}`)
      MergeRecordsAndRequestsByYearMonth(year, month);
      const days = getTotaldaysOfMonth(year, month);
      setStateTotalDaysOfMonth(days);
      setStateBoolReportSelected(true);
   }
   const MergeRecordsAndRequestsByYearMonth = async (year: number,month: number) => {
      const response: any = await collectionAPI.collectionAPI.MergeRecordsAndRequestsByYearMonth(year, month); //phải có await nghĩa là khi nào có data rồi thì mới lấy
      // console.log('response.data: ', response.data);
      setStateArrGroupByEmail(response.data);
      const SoGioTrungBinhDiLam = tinhSoGioTrungBinhDiLam(response.data, year, month);
      const days = getTotaldaysOfMonth(year, month);
      // cập nhật chart line số giờ trung bình đi làm
      setChartLineData(
         {
            series: [{
            name: "Sale",
            data: SoGioTrungBinhDiLam
            }],
            options: {
               chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                     enabled: false
                     }
                  },
               dataLabels: {
                  enabled: true
               },
               stroke: {
                  curve: 'straight'
               },
               title: {
                  text: '',
                  align: 'left'
               },
               grid: {
                  row: {
                  colors: ['#f3f3f3', 'transparent'],
                  opacity: 0.5
                  },
               },
               xaxis: {
                  categories: days,
               }
            },
         }
      )
      // cập nhật chart line số lượng nhân viên đi làm
      const workingStaff = tinhSoNhanVienDiLam(response.data, year, month);
      setChartLineData2(
         {
            series: [{
            name: "Sale",
            data: workingStaff
            }],
            options: {
               chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                     enabled: false
                     }
                  },
               dataLabels: {
                  enabled: true
               },
               stroke: {
                  curve: 'straight'
               },
               title: {
                  text: '',
                  align: 'left'
               },
               grid: {
                  row: {
                  colors: ['#f3f3f3', 'transparent'],
                  opacity: 0.5
                  },
               },
               xaxis: {
                  categories: days,
               }
            },
         }
      )

      const averageTimeCheckin = tinhSoGioTrungBinhCheckin(response.data, year, month);
      setChartLineData3(
         {
            series: [{
            name: "Sale",
            data: averageTimeCheckin
            }],
            options: {
               chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                     enabled: false
                     }
                  },
               dataLabels: {
                  enabled: true
               },
               stroke: {
                  curve: 'straight'
               },
               title: {
                  text: '',
                  align: 'left'
               },
               grid: {
                  row: {
                  colors: ['#f3f3f3', 'transparent'],
                  opacity: 0.5
                  },
               },
               xaxis: {
                  categories: days,
               }
            },
         }
      )
      const averageTimeCheckout = tinhSoGioTrungBinhCheckout(response.data, year, month);
      setChartLineData4(
         {
            series: [{
            name: "Sale",
            data: averageTimeCheckout
            }],
            options: {
               chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                     enabled: false
                     }
                  },
               dataLabels: {
                  enabled: true
               },
               stroke: {
                  curve: 'straight'
               },
               title: {
                  text: '',
                  align: 'left'
               },
               grid: {
                  row: {
                  colors: ['#f3f3f3', 'transparent'],
                  opacity: 0.5
                  },
               },
               xaxis: {
                  categories: days,
               }
            },
         }
      )
      const averageLateCheckInTime = tinhTrungBinhLateTimeCheckIn(averageTimeCheckin);
      // console.log('averageLateCheckInTime: ', averageLateCheckInTime);

      setChartLineData5(
         {
            series: [{
            name: "Sale",
            data: averageLateCheckInTime
            }],
            options: {
               chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                     enabled: false
                     }
                  },
               dataLabels: {
                  enabled: true
               },
               stroke: {
                  curve: 'straight'
               },
               title: {
                  text: '',
                  align: 'left'
               },
               grid: {
                  row: {
                  colors: ['#f3f3f3', 'transparent'],
                  opacity: 0.5
                  },
               },
               xaxis: {
                  categories: days,
               }
            },
         }
      )
      const averageLateCheckOutTime = tinhTrungBinhLateTimeCheckOut(averageTimeCheckout,response.data);
      // console.log('averageLateCheckOutTime: ', averageLateCheckOutTime);
      setChartLineData6(
         {
            series: [{
            name: "Sale",
            data: averageLateCheckOutTime
            }],
            options: {
               chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                     enabled: false
                     }
                  },
               dataLabels: {
                  enabled: true
               },
               stroke: {
                  curve: 'straight'
               },
               title: {
                  text: '',
                  align: 'left'
               },
               grid: {
                  row: {
                  colors: ['#f3f3f3', 'transparent'],
                  opacity: 0.5
                  },
               },
               xaxis: {
                  categories: days,
               }
            },
         }
      )
      
   }

   const nameSelected = (email: string) => {
      setStateBoolShowStaffDetails(true)
      const obj = stateArrGroupByEmail.find((obj) => obj.email === email);
      setStateObjStaff(obj);
      // bigObj["1"] = {id: 1, qty: 2};
      let arr = obj.result;
      obj["leave_request_full_day"] = demxuathien(arr,"leave_request_full_day")
      obj["leave_request_afternoon_in_valid_out_valid"] = demxuathien(arr,"leave_request_afternoon_in_valid_out_valid")
      obj["leave_request_morning_in_valid_out_valid"] = demxuathien(arr,"leave_request_morning_in_valid_out_valid")
      obj["in_late_out_soon"] = demxuathien(arr,"in_late_out_soon")
      obj["in_late_out_late"] = demxuathien(arr,"in_late_out_late")
      obj["in_soon_out_soon"] = demxuathien(arr,"in_soon_out_soon")
      obj["in_soon_out_late"] = demxuathien(arr,"in_soon_out_late")
      obj["empty"] = demxuathien(arr,"empty")
      obj["leave_request_afternoon_in_invalid_out_valid"] = demxuathien(arr,"leave_request_afternoon_in_invalid_out_valid")
      obj["leave_request_afternoon_in_valid_out_invalid"] = demxuathien(arr,"leave_request_afternoon_in_valid_out_invalid")
      obj["leave_request_afternoon_in_invalid_out_invalid"] = demxuathien(arr,"leave_request_afternoon_in_invalid_out_invalid")
      obj["leave_request_morning_in_invalid_out_valid"] = demxuathien(arr,"leave_request_morning_in_invalid_out_valid")
      obj["leave_request_morning_in_valid_out_invalid"] = demxuathien(arr,"leave_request_morning_in_valid_out_invalid")
      obj["leave_request_morning_in_invalid_out_invalid"] = demxuathien(arr,"leave_request_morning_in_invalid_out_invalid")

      let viPham = obj.in_late_out_soon + obj.in_late_out_late + obj.in_soon_out_soon + obj.leave_request_afternoon_in_invalid_out_valid + obj.leave_request_afternoon_in_valid_out_invalid + obj.leave_request_afternoon_in_invalid_out_invalid + obj.leave_request_morning_in_invalid_out_valid + obj.leave_request_morning_in_valid_out_invalid + obj.leave_request_morning_in_invalid_out_invalid;
      let hopLe = obj.leave_request_full_day + obj.leave_request_afternoon_in_valid_out_valid + obj.leave_request_morning_in_valid_out_valid + obj.in_soon_out_late; 
      let percentHople = (hopLe / (viPham + hopLe))*100
      obj["viPham"] = viPham;
      obj["hopLe"] = hopLe;
      obj["percentHople"] = percentHople;
      // console.log('obj: ', obj);
   }

   const [chartLineData, setChartLineData] = useState<any>({
      series: [{
      name: "Sale",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }],
      options: {
         chart: {
               height: 350,
               type: 'line',
               zoom: {
               enabled: false
               }
            },
         dataLabels: {
            enabled: false
         },
         stroke: {
            curve: 'straight'
         },
         title: {
            text: 'Sales Trends by Month',
            align: 'left'
         },
         grid: {
            row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
            },
         },
         xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
         }
      },
   });
   const [chartLineData2, setChartLineData2] = useState<any>({
      series: [{
      name: "Sale",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }],
      options: {
         chart: {
               height: 350,
               type: 'line',
               zoom: {
               enabled: false
               }
            },
         dataLabels: {
            enabled: false
         },
         stroke: {
            curve: 'straight'
         },
         title: {
            text: 'Sales Trends by Month',
            align: 'left'
         },
         grid: {
            row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
            },
         },
         xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
         }
      },
   });
   const [chartLineData3, setChartLineData3] = useState<any>({
      series: [{
      name: "Sale",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }],
      options: {
         chart: {
               height: 350,
               type: 'line',
               zoom: {
               enabled: false
               }
            },
         dataLabels: {
            enabled: false
         },
         stroke: {
            curve: 'straight'
         },
         title: {
            text: 'Sales Trends by Month',
            align: 'left'
         },
         grid: {
            row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
            },
         },
         xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
         }
      },
   });
   const [chartLineData4, setChartLineData4] = useState<any>({
      series: [{
      name: "Sale",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }],
      options: {
         chart: {
               height: 350,
               type: 'line',
               zoom: {
               enabled: false
               }
            },
         dataLabels: {
            enabled: false
         },
         stroke: {
            curve: 'straight'
         },
         title: {
            text: 'Sales Trends by Month',
            align: 'left'
         },
         grid: {
            row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
            },
         },
         xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
         }
      },
   });
   const [chartLineData5, setChartLineData5] = useState<any>({
      series: [{
      name: "Sale",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }],
      options: {
         chart: {
               height: 350,
               type: 'line',
               zoom: {
               enabled: false
               }
            },
         dataLabels: {
            enabled: false
         },
         stroke: {
            curve: 'straight'
         },
         title: {
            text: 'Sales Trends by Month',
            align: 'left'
         },
         grid: {
            row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
            },
         },
         xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
         }
      },
   });
   const [chartLineData6, setChartLineData6] = useState<any>({
      series: [{
      name: "Sale",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }],
      options: {
         chart: {
               height: 350,
               type: 'line',
               zoom: {
               enabled: false
               }
            },
         dataLabels: {
            enabled: false
         },
         stroke: {
            curve: 'straight'
         },
         title: {
            text: 'Sales Trends by Month',
            align: 'left'
         },
         grid: {
            row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
            },
         },
         xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
         }
      },
   });
   // const [chartColumn1, setChartColumn1] = useState<any>({
   //    series: [{
   //       name: 'Inflation',
   //       data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
   //    }],
   //    options: {
   //    chart: {
   //       height: 350,
   //       type: 'bar',
   //    },
   //    plotOptions: {
   //       bar: {
   //          borderRadius: 10,
   //          dataLabels: {
   //          position: 'top', // top, center, bottom
   //          },
   //       }
   //    },
   //    dataLabels: {
   //       enabled: true,
   //       formatter: function (val:any) {
   //          return val + "%";
   //       },
   //       offsetY: -20,
   //       style: {
   //          fontSize: '12px',
   //          colors: ["#304758"]
   //       }
   //    },
      
   //    xaxis: {
   //       categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
   //       position: 'top',
   //       axisBorder: {
   //          show: false
   //       },
   //       axisTicks: {
   //          show: false
   //       },
   //       crosshairs: {
   //          fill: {
   //          type: 'gradient',
   //          gradient: {
   //             colorFrom: '#D8E3F0',
   //             colorTo: '#BED1E6',
   //             stops: [0, 100],
   //             opacityFrom: 0.4,
   //             opacityTo: 0.5,
   //          }
   //          }
   //       },
   //       tooltip: {
   //          enabled: true,
   //       }
   //    },
   //    yaxis: {
   //       axisBorder: {
   //          show: false
   //       },
   //       axisTicks: {
   //          show: false,
   //       },
   //       labels: {
   //          show: false,
   //          formatter: function (val:any) {
   //          return val + "%";
   //          }
   //       }
      
   //    },
   //    title: {
   //       text: 'Monthly Inflation in Argentina, 2002',
   //       floating: true,
   //       offsetY: 330,
   //       align: 'center',
   //       style: {
   //          color: '#444'
   //       }
   //    }
   //    },
   // });

   return (
      <div className={clsx(styles.component_HRBaocao)}>

         <p className={clsx(styles.category)}>Báo cáo đã có</p>
         <div className={clsx(styles.listReports)}>
            {
               arrReports.map((item, index) => {
                  const path = "../assets/icon/"+index+".png";
                  let keyName = `${item.year}${item.month}`;
                  let fullThang = fullMonth(item.month);
                  return ( 
                     <div key={keyName} className={clsx(styles.box)} onClick={()=>selectReport(item.year, item.month)}>
                        <img src={path} className="icon" alt="icon" />
                        <p className={clsx(styles.text)} >Báo cáo {fullThang}-{item.year}</p>
                     </div>
                  )
               })
            }
         </div>
         <hr />
         {stateBoolReportSelected &&
            <>
               <p className={clsx(styles.category)}>{stateReportSelected}</p>
               <div className={clsx(styles.legends)}>
                  <p className={clsx(styles.title)}>Hợp lệ</p>
                  <div className={clsx(styles.valid)}>
                     <p className={clsx(styles.green, styles.square)}></p>
                     <p className={clsx(styles.text)}>Hợp lệ, đi sớm về trễ</p>
                     <p className={clsx(styles.Gold, styles.square)}></p>
                     <p className={clsx(styles.text)}>Nghỉ phép cả ngày</p>
                     <p className={clsx(styles.Gold_bold, styles.square)}></p>
                     <p className={clsx(styles.text)}>Nghỉ phép nửa ngày</p>
                     
                  </div>
                  <p className={clsx(styles.title)}>Không hợp lệ</p>
      
                  <div className={clsx(styles.invalid)}>
                     <p className={clsx(styles.red, styles.square)}></p>
                     <p className={clsx(styles.text)}>Đi trễ về sớm</p>
                     <p className={clsx(styles.orange, styles.square)}></p>
                     <p className={clsx(styles.text)}>Đi trễ về trễ</p>
                     <p className={clsx(styles.Pink, styles.square)}></p>
                     <p className={clsx(styles.text)}>Đi sớm về sớm</p>
                     <p className={clsx(styles.gray, styles.square)}></p>
                     <p className={clsx(styles.text)}>Trống</p>
                     <p className={clsx(styles.xanhngoc, styles.square)}></p>
                     <p className={clsx(styles.text)}>Chủ nhật</p>
                     <p className={clsx(styles.Sky_Blue, styles.square)}></p>
                     <p className={clsx(styles.text)}>Nghỉ chiều nhưng checkin sau 08:30</p>
                     <p className={clsx(styles.Peach_Puff, styles.square)}></p>
                     <p className={clsx(styles.text)}>Nghỉ chiều nhưng checkout trước 12:00</p>
                  </div>
                  <div className={clsx(styles.invalid)}>
                     <p className={clsx(styles.Light_Salmon, styles.square)}></p>
                     <p className={clsx(styles.text)}>Nghỉ chiều nhưng checkin sau 08:30 và checkout trước 12:00</p>
                     <p className={clsx(styles.Khaki, styles.square)}></p>
                     <p className={clsx(styles.text)}>Nghỉ sáng nhưng checkin sau 12:00</p>
                     <p className={clsx(styles.Light_Cyan, styles.square)}></p>
                     <p className={clsx(styles.text)}>Nghỉ sáng nhưng checkout trước 16:30</p>
                     <p className={clsx(styles.Plum, styles.square)}></p>
                     <p className={clsx(styles.text)}>Nghỉ sáng nhưng checkin sau 12:00, checkout trước 16:30</p>
                  </div>
      
               </div>
               <hr></hr>
               <div className={clsx(styles.tableWrapper)}>
                  <h2>Báo cáo tháng {stateStringTimeReport}</h2>
                  <div className={clsx(styles.wrapper)}>
                     <div className={clsx(styles.table)}>
                        <div className={clsx(styles.heading,styles.row)}>
                           <p className={clsx(styles.stt)}>No</p>
                           <p className={clsx(styles.name)}>Full Name</p>
                           {
                              stateTotalDaysOfMonth.map((day, index) => {
                                 if (index === stateTotalDaysOfMonth.length - 1) {
                                    return (
                                       <div key={day} className={clsx(styles.lastDay)}>
                                          <p>{day}</p>
                                       </div>
                                    );
                                 } else {
                                    return (
                                       <p key={day} className={clsx(styles.day)}>
                                          {day}
                                       </p>
                                    );
                                 }
                              })
                           }
                        </div>
                        {
                           stateArrGroupByEmail.map((row: any, index: number) => {
                              return (
                                 <div className={clsx(styles.row)} key={row.email} onClick={()=>nameSelected(row.email)}>
                                    <p className={clsx(styles.stt)}>{index + 1}</p>
                                    <p className={clsx(styles.name)}>{row.fullName}</p>
                                    {
                                       row.result.map((item: string, innerIndex: number) => {
                                          const keys = `${item}+${innerIndex}`;

                                          let squareClassName = styles.gray; // Default class for "empty" item

                                          switch (item) {
                                             case "in_late_out_soon":
                                                squareClassName = clsx(styles.red, styles.square);
                                                break;
                                             case "in_late_out_late":
                                                squareClassName = clsx(styles.orange, styles.square);
                                                break;
                                             case "in_soon_out_soon":
                                                squareClassName = clsx(styles.Pink, styles.square);
                                                break;
                                             case "in_soon_out_late":
                                                squareClassName = clsx(styles.green, styles.square);
                                                break;
                                             case "empty":
                                                squareClassName = clsx(styles.gray, styles.square);
                                                break;
                                             case "sunday":
                                                squareClassName = clsx(styles.xanhngoc, styles.square);
                                                break;
                                             case "leave_request_full_day":
                                                squareClassName = clsx(styles.Gold, styles.square);
                                                break;
                                             case "leave_request_afternoon_in_invalid_out_valid":
                                                squareClassName = clsx(styles.Sky_Blue, styles.square);
                                                break;
                                             case "leave_request_afternoon_in_valid_out_invalid":
                                                squareClassName = clsx(styles.Peach_Puff, styles.square);
                                                break;
                                             case "leave_request_afternoon_in_invalid_out_invalid":
                                                squareClassName = clsx(styles.Light_Salmon, styles.square);
                                                break;
                                             case "leave_request_morning_in_invalid_out_valid":
                                                squareClassName = clsx(styles.Khaki, styles.square);
                                                break;
                                             case "leave_request_morning_in_valid_out_invalid":
                                                squareClassName = clsx(styles.Light_Cyan, styles.square);
                                                break;
                                             case "leave_request_morning_in_invalid_out_invalid":
                                                squareClassName = clsx(styles.Plum, styles.square);
                                                break;
                                             case "leave_request_afternoon_in_valid_out_valid":
                                                squareClassName = clsx(styles.Gold_bold, styles.square);
                                                break;
                                             case "leave_request_morning_in_valid_out_valid":
                                                squareClassName = clsx(styles.Gold_bold, styles.square);
                                                break;

                                             default:
                                                break;
                                          }
                                          let s = formatDateString(row.checkIn[innerIndex])
                                          let e = formatDateString(row.checkOut[innerIndex])
                                          let time = `check in: ${s}, check out: ${e}`
                                          return (
                                             <Tooltip key={keys} title={time}>

                                                <p  className={squareClassName}></p>
                                             </Tooltip>
                                          );
                                       })
                                    }
                                 </div>
                              );
                           })
                        }
                     </div>
                     <div className={clsx(styles.staffDetails)}>
                        {
                           stateBoolShowStaffDetails &&
                           <div className={clsx(styles.staff)}>
                              
                              <h3>Báo cáo nhân viên chi tiết</h3>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Email: </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.email}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Full name: </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.fullName}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Đi làm đúng giờ: </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.in_soon_out_late}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Bỏ trống: </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.empty}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Nghỉ phép cả ngày: </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.leave_request_full_day}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Nghỉ phép buổi chiều: </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.leave_request_afternoon_in_valid_out_valid}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Nghỉ phép buổi sáng: </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.leave_request_morning_in_valid_out_valid}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Đi trễ về sớm: </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.in_late_out_soon}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Đi trễ về trễ: </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.in_late_out_late}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Đi sớm về sớm : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.in_soon_out_soon}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Nghỉ chiều, checkin sau 08:30 : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.leave_request_afternoon_in_invalid_out_valid}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Nghỉ chiều, checkout trước 12:00 : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.leave_request_afternoon_in_valid_out_invalid}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Nghỉ chiều, checkin sau 08:30 và checkout trước 12:00 : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.leave_request_afternoon_in_invalid_out_invalid}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Nghỉ sáng, checkin sau 12:00 : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.leave_request_morning_in_invalid_out_valid}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Nghỉ sáng, checkout trước 16:30 : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.leave_request_morning_in_valid_out_invalid}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Nghỉ sáng, checkin sau 12:00, checkout trước 16:30 : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.leave_request_morning_in_invalid_out_invalid}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key, styles.bold)}>Tổng hợp : </p>
                                 <p className={clsx(styles.value)}></p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Hợp lệ : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.hopLe}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Vi phạm : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.viPham}</p>
                              </div>
                              <div className={clsx(styles.row)}>
                                 <p className={clsx(styles.key)}>Tỉ lệ % hợp lệ : </p>
                                 <p className={clsx(styles.value)}>{stateObjStaff.percentHople.toFixed(0)} %</p>
                              </div>
                           </div>
                        }

                     </div>
                  </div>
               </div>
               <hr />
               <div className={clsx(styles.accordion)}>
                  <Accordion>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                     >
                        <Typography>Số giờ trung bình đi làm</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           <div id="chartLine" className={clsx(styles.chartLine)}>
                              <ReactApexChart options={chartLineData.options} series={chartLineData.series} type="line" height={350} />
                           </div>
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                     >
                        <Typography>Số lượng nhân viên đi làm</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           <div id="chartLine2" className={clsx(styles.chartLine)}>
                              <ReactApexChart options={chartLineData2.options} series={chartLineData2.series} type="line" height={350} />
                           </div>
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                     >
                        <Typography>Trung bình giờ checkin</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           <div id="chartLine3" className={clsx(styles.chartLine)}>
                              <ReactApexChart options={chartLineData3.options} series={chartLineData3.series} type="line" height={350} />
                           </div>
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                     >
                        <Typography>Trung bình giờ checkout</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           <div id="chartLine4" className={clsx(styles.chartLine)}>
                              <ReactApexChart options={chartLineData4.options} series={chartLineData4.series} type="line" height={350} />
                           </div>
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5a-content"
                        id="panel5a-header"
                     >
                        <Typography>Trung bình số phút đi trễ</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           <div id="chartLine5" className={clsx(styles.chartLine)}>
                              <ReactApexChart options={chartLineData5.options} series={chartLineData5.series} type="line" height={350} />
                           </div>
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6a-content"
                        id="panel6a-header"
                     >
                        <Typography>Trung bình số phút về trễ</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                        <Typography>
                           <div id="chartLine6" className={clsx(styles.chartLine)}>
                              <ReactApexChart options={chartLineData6.options} series={chartLineData6.series} type="line" height={350} />
                           </div>
                        </Typography>
                     </AccordionDetails>
                  </Accordion>
                  <Accordion disabled>
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5a-content"
                        id="panel5a-header"
                     >
                        <Typography>Disabled Accordion</Typography>
                     </AccordionSummary>
                  </Accordion>
               </div>
            </>
         }
         <div>
         </div>
      </div>
   )
}
export default HRBaocao