import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './Quanly.module.scss'
import { QuanlySubMenuRoute  } from '../../share/route-option';
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import { arrRole_admin } from "../../share/arrRole";
function QuanlySubMenu() {
    const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
        email: "",
        fullName: "",
        role: "",
        token:""
    })
    const checkSignin = () => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        // console.log('userExist: ', userExist);
        if(userExist === null){
            return
        }
        setStateObjSignIn(userExist);
    
    }

    useEffect(() => {
        checkSignin();
    },[]);
    const [stateSubMenuItems, setStateSubMenuItems] = useState(QuanlySubMenuRoute); //tạo state toggle và cho nội dung của nó ban đầu là mảng AdminMenuRoute
    const [defaultView, setDefaultView] = useState(true); //lý do có state này là ban đầu cho cái default view nó hiện ra, chứ nếu ko là ban đầu nó hiện ra trang trắng, khi user lick vào các item bên sidebar thì state này đc set về false để ẩn đi default view nhường chỗ lại cho các trang khác hiện ra
    
    const [open, setOpen] = useState(true);

    const dispatch = useDispatch(); //hook phát đi đến GeneralSlice.js

    const navigate = useNavigate(); //hook dùng để chuyển trang web

    const [stateString_submenuItem, setStateString_submenuItem] = useState<string>(''); // string
    const select_subMenuItem = (title: string) => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return;
        }
        const email = userExist.email;
        switch (title) {
            case 'Cập nhật':
                // alert("Tính năng này đang phát triển ! Bạn sẽ đường điều hướng lại về trang Thông Tin");
                navigate("/quanly/capnhat", { replace: true }); 
                setStateString_submenuItem(title);
            break;
            case 'Người dùng':
                // if(!arrEmail2.includes(email)){
                //     break;
                // }
                // navigate("/ketoan", { replace: true }); 
                // setStateString_submenuItem(title);
                break;
            case 'Báo cáo':
                break;
            case 'Cài đặt':
                if(arrRole_admin.includes(stateObjSignIn.role)){
                    navigate("/quanly/caidat", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />
                    setStateString_submenuItem(title);
                }
                break;
            default:
                break;
        }
    }

    return (
        stateObjSignIn.token === "" ? <span></span> :

        <div className={clsx(styles.component_QuanlySubMenu)}>
            {
                stateSubMenuItems.map((obj, index) => {
                    let title: string = obj.title;
                    let status: Boolean = obj.status;
                    
                    if (title === "Cài đặt" && !arrRole_admin.includes(stateObjSignIn.role)) {
                        // If the title is "Cài đặt" and the user is not an admin, skip rendering
                        return null;
                    }

                    return (
                        <p key={obj.id} className={clsx(styles.subMenuItem, {
                            [styles.selectedSubMenuItem]: obj.title === stateString_submenuItem,
                        })} onClick={() => select_subMenuItem(obj.title)}>
                            {obj.title}
                        </p>
                    );
                })
            }
        </div>
    )
}
export default QuanlySubMenu