import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    Outlet,
    useNavigate,
} from "react-router-dom";
import styles from "./AgentMenuBar.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { AgentOutsideRoute } from "../../../share/route-option";
// Mui library
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { retrieveObjectFromLocalStorage } from "../../../share/sharedFunction";
import {
    arrEmail_backOffice,
    arrRole_Finance,
    arrRole_KT,
    arrRole_bgd,
} from "../../../share/arrRole";
import { isSignInAgent, setState_objSigninAgent, selectstateRedux_Bool_isSignedInAgent, selectstateRedux_ObjSigninAgent } from "../../../../features/generalSlice";
import collectionAPI from "../../../../API/collectionAPI";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getIconByName } from "../../../share/AppIcon";

type stateObj = {
    [key: string]: any;
};
// const emailPgsaru = ['vu@gmail.com','anh@gmail.com','dong@gmail.com','huong@gmail.com']

function AgentMenuBar() {
    let isUserSignInReduxAgent = useSelector(selectstateRedux_Bool_isSignedInAgent); // explain 1
    let ObjSignInFromReduxAgent = useSelector(selectstateRedux_ObjSigninAgent); // explain 1
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // kiểm tra đã sign in chưa ------------------------------------------start
    const [stateBool_isSignedIn, setStateBool_isSignedIn] = useState(false); // boolean
    const [stateObj_signIn, setStateObj_signIn] = useState<Record<string, any>>({
        token: "",
        email: "",
        fullName: "",
        role: "",
    });
    const dispatch = useDispatch();
    // console.log('stateBool_isSignedIn: ', stateBool_isSignedIn);
    // console.log('isUserSignInRedux: ', isUserSignInRedux);
    const checkAlreadySignIn1 = () => {
        // kiểm tra xem user đã sign in step 1 chưa

        const userExist = retrieveObjectFromLocalStorage("loginSuccessAgent");
        // console.log("userExist: ", userExist);
        if (userExist !== null) {
            setStateBool_isSignedIn(true);
            setStateObj_signIn(userExist);
            dispatch(isSignInAgent(true));
        } else {
            setStateBool_isSignedIn(false);
            dispatch(isSignInAgent(false));
        }
        return userExist;
    };

    useEffect(() => {
        checkAlreadySignIn1();
    }, []);
    // kiểm tra đã sign in chưa ------------------------------------------end

    // const handleSignOut = async () => {
    //    sessionStorage.clear(); // remove tất cả session
    //    localStorage.clear(); // remove tất cả localstorage
    //    localStorage.removeItem("loginSuccessAgent"); // bỏ cái cũ đi
    //    // window.location.reload();
    //    navigate("/signin", { replace: true }); //chuyển đến trang login <Route path="/" element={<SignIn/> } />
    //    dispatch(isSignIn(false));
    // }

    const changePassword = () => {
        navigate("/changepassword", { replace: true }); //chuyển đến trang login <Route path="/" element={<SignIn/> } />
    };

    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++submenu của All Jobs start
    // let userSignIn: any = getSession('sessionusersignin'); //Cách 1: lấy value của localStorage có key là userSignIn, và gán value đó cho biến userSignIn, userSignIn có kiểu object
    // console.log("userSignIn menubar: " + JSON.stringify(userSignIn, null, 4));
    // if (userSignIn !== null || userSignIn !== undefined) userSignIn = JSON.parse(userSignIn);  //Hàm chuyển từ chuỗi text qua dạng array object json, sau khi lấy từ localstorage (dạng string) ra ngoài thì phải chuyển thành object thì mới dùng được, phải cho userSignIn khác null thì mới parse để nó không lỗi nữa (nếu dùng typescript)

    const [stateMenuItems, setStateMenuItems] = useState(AgentOutsideRoute); //tạo state toggle và cho nội dung của nó ban đầu là mảng AdminMenuRoute

    const makeSelectedItemBlue = (title: string) => {
        //cho tất cả status về false lại như ban đầu
        AgentOutsideRoute.forEach((obj, index, arr) => {
            obj.status = false;
        });
        //object nào có title trùng với selectedSideBarItem thì cho status là true
        const newStateGroup = AgentOutsideRoute.map((obj) => {
            if (obj.title === title) {
                return { ...obj, status: true };
            }
            return obj;
        });
        setStateMenuItems(newStateGroup); //cập nhật lại state toggle bằng cái mảng mới đã chuyển cái status của title đc click vào thành true
    };

    const [stateUserSignIn, setStateUserSignIn] = useState<stateObj>({});
    const [stateUserSignInFinal, setStateUserSignInFinal] = useState<stateObj>(
        {}
    );

    const navigate = useNavigate(); //hook dùng để chuyển trang web
    const [stateString_menuItem, setStateString_menuItem] = useState<string>(""); // string
    const select_MenuItem = (title: string) => {
        // console.log('ObjSignInFromReduxAgent: ', ObjSignInFromReduxAgent);
        // console.log('isUserSignInRedux: ', isUserSignInRedux);
        let objSignIn: any = {};
        // console.log('ObjSignInFromReduxAgent.hasOwnProperty(token): ', ObjSignInFromReduxAgent.hasOwnProperty('token'));
        //@ts-ignore
        if (ObjSignInFromReduxAgent.hasOwnProperty("token") === false) {
            objSignIn = stateObj_signIn;
            // console.log('objSignIn: ', objSignIn);
        } else {
            objSignIn = ObjSignInFromReduxAgent;
        }
        // console.log('title: ', title);
        switch (title) {
            case "Khách hàng":
                navigate("/agent/khachhang", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />
                setStateString_menuItem(title);
                // if (
                //     arrRole_Finance.includes(objSignIn.role) ||
                //     arrEmail_backOffice.includes(objSignIn.email)
                // ) {
                //     navigate("/finance", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />
                //     setStateString_menuItem(title);
                // } else {
                //     alert(
                //         `Chỉ có BGĐ và nhân viên phòng ${title} mới được dùng tính năng này`
                //     );
                // }
                break;
            case "Link":
                navigate("/agent/Link", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />
                setStateString_menuItem(title);
                break;
            case "Hội viên":
                navigate("/agent/hoivien", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />
                setStateString_menuItem(title);
                break;
            case "signout":
                removeDataInBEWhenSignout();
                localStorage.clear(); // remove tất cả localstorage
                localStorage.removeItem("loginSuccessAgent"); // bỏ cái cũ đi

                dispatch(isSignInAgent(false)); // explain 1: để tắt ko hiện Menubar
                dispatch(setState_objSigninAgent({})); // explain 1: cho về rỗng
                navigate("/agent/signin", { replace: true }); //chuyển đến trang login <Route path="/" element={<SignIn/> } />
                break;
            default:
                break;
        }
    };
    const removeDataInBEWhenSignout = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessAgent");
        // console.log('userExist: ', userExist);
        if (userExist === null) {
            return;
        }
        const email = userExist.email;
        let response: any;
        try {
            response = await collectionAPI.collectionAPI_AuthController.signout(
                email
            );
            // console.log('response: ', response);
        } catch (err) {
            // console.log('err:', err);
        }
    };
    // const isEmailInPgsaru = emailPgsaru.includes(stateObj_signIn.email);
    return (
        // explain 1
        isUserSignInReduxAgent === false ? (
            <div></div>
        ) : (
            <div className={clsx(styles.component_AgentMenuBar)}>
                <ul>
                    {/* logo */}
                    <li>
                        {/* {isEmailInPgsaru ? (
                            <img className={clsx(styles.logo)} src="../assets/logo/pgsaru.png" alt="logo"
                            />
                        ) : (
                            <img className={clsx(styles.logo)} src="../assets/logo/newworld.png" alt="logo" />
                        )} */}
                    </li>
                    <div className={clsx(styles.menuItemWrapper)}>
                        {stateMenuItems.map((obj, index) => {
                            return (
                                <p
                                    key={obj.id}
                                    className={clsx(styles.menuItem, {
                                        [styles.selectedmenuItem]:
                                            obj.title === stateString_menuItem,
                                    })}
                                    onClick={() => select_MenuItem(obj.title)}
                                >
                                    {obj.title}
                                </p>
                            );
                        })}
                    </div>
                    <li>
                        {/* <React.Fragment> */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? "account-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                >
                                    {stateUserSignInFinal ? (
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={stateUserSignInFinal.urlavatar}
                                        />
                                    ) : (
                                        <AccountCircleIcon className={clsx(styles.accountIcon)} />
                                    )}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translateY(10%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: "center", vertical: "top" }}
                            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                            className={clsx(styles.Menu)}
                        >
                            {/* TRƯỜNG HỢP CHƯA SIGN IN THÌ HIỆN RA COMPONENT NÀY */}
                            {stateBool_isSignedIn === false && isUserSignInReduxAgent === false ? (
                                <MenuItem>
                                    {/* <Link to="/signin" style={{ textDecoration: 'none' }}>
                                     <ListItemIcon>
                                        {getIconByName("signin")}
                                     </ListItemIcon>
                                     Sign in
                                  </Link> */}
                                </MenuItem>
                            ) : (
                                // <MenuItem onClick={handleSignOut} >
                                <MenuItem onClick={() => select_MenuItem("signout")}>
                                    <p
                                        className={clsx(styles.signout)}
                                        style={{ margin: "0 0 0 0" }}
                                    >
                                        {getIconByName("signout")}
                                        <span
                                            className={clsx(styles.signout)}
                                            style={{
                                                margin: "0 0 0 1rem",
                                                color: "#0d6efd",
                                                padding: "0",
                                            }}
                                            
                                        >
                                            Sign out
                                        </span>
                                    </p>
                                </MenuItem>
                            )}
                            <MenuItem onClick={changePassword}>
                                <Link to="/signup" style={{ textDecoration: "none" }}>
                                    <ListItemIcon>{getIconByName("changePassword")}</ListItemIcon>
                                    <span>Change password</span>
                                </Link>
                            </MenuItem>
                            {/* <MenuItem >
                      <Link to="/customeraccount" style={{ textDecoration: 'none' }}>
                         <ListItemIcon>
                            {getIconByName("account")}
                         </ListItemIcon>
                         Account
                      </Link>
                   </MenuItem> */}
                            {/* TRƯỜNG HỢP SIGN IN RỒI THÌ HIỆN RA COMPONENT NÀY */}

                            {Object.keys(stateUserSignInFinal).length !== 0 &&
                                (stateUserSignInFinal.role === "admin" ||
                                    stateUserSignInFinal.role === "staff") ? (
                                <>
                                    <MenuItem>
                                        <Link to="/controller" style={{ textDecoration: "none" }}>
                                            <ListItemIcon>{getIconByName("dashboard")}</ListItemIcon>
                                            Controller Dashboard
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to="/employer" style={{ textDecoration: "none" }}>
                                            <ListItemIcon>{getIconByName("dashboard")}</ListItemIcon>
                                            Employer Dashboard
                                        </Link>
                                    </MenuItem>
                                </>
                            ) : (
                                ""
                            )}
                            {stateUserSignInFinal !== null &&
                                stateUserSignInFinal.status === "active" ? (
                                <MenuItem>
                                    <Link to="/" style={{ textDecoration: "none" }}>
                                        {/* chuyển về component ShowJob, vì chỗ này database ko có thuộc tính phân biệt giữa candidate và employer, 
                            nhưng may sao thằng status của candidate là active còn status của employer là enable
                            Nên nếu status là active thì chuyển về component ShowJob
                            */}
                                        <ListItemIcon>{getIconByName("dashboard")}</ListItemIcon>
                                        Candidate Dashboard
                                    </Link>
                                </MenuItem>
                            ) : (
                                ""
                            )}
                            {stateUserSignInFinal !== null &&
                                stateUserSignInFinal.status === "enable" ? (
                                <MenuItem>
                                    <Link to="/employer" style={{ textDecoration: "none" }}>
                                        {/* chuyển về component EmployerHome có route là /employer, vì chỗ này database ko có thuộc tính phân biệt giữa candidate và employer, 
                            nhưng may sao thằng status của candidate là active còn status của employer là enable
                            Nên nếu status là enable thì chuyển về component ShowJob
                            */}
                                        <ListItemIcon>{getIconByName("dashboard")}</ListItemIcon>
                                        Employer Dashboard
                                    </Link>
                                </MenuItem>
                            ) : (
                                ""
                            )}
                        </Menu>
                        {/* </React.Fragment> */}
                    </li>
                </ul>
            </div>
        )
    );
}
export default AgentMenuBar;
