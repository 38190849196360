import React from 'react';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PasswordIcon from '@mui/icons-material/Password';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PushPinIcon from '@mui/icons-material/PushPin';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DrawIcon from '@mui/icons-material/Draw';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import TableViewIcon from '@mui/icons-material/TableView';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

type IconSize = "small" | "medium" | "large" | "inherit";
export const GetIconByName = (iconName: string, inputWidth?: string, size?: IconSize) => {

   switch (iconName) {
      case '':
         return ''
      case 'dropDown':
         return <ArrowDropDownIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'dropUp':
         return <ArrowDropUpIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'cloud_uploud_material':
         return <CloudUploadOutlinedIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'cloud_uploud_font':
         return <img src="../../assets/icons/cloud_uploud.svg" style={{ width: inputWidth, height: '1.5rem' }} alt="avatar" />
      case '19':
         return <img src="../../assets/icons/19.svg" style={{ width: inputWidth, height: '1.5rem' }} alt="avatar" />
      case '91':
         return <img src="../../assets/icons/91.svg" style={{ width: inputWidth, height: '1.5rem' }} alt="avatar" />
         // return <svg style={{width:inputWidth}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path style={{width:inputWidth}}  d="M160 480c9 0 17.5-3.8 23.6-10.4l88-96c11.9-13 11.1-33.3-2-45.2s-33.3-11.1-45.2 2L192 365.7V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V365.7L95.6 330.4c-11.9-13-32.2-13.9-45.2-2s-13.9 32.2-2 45.2l88 96C142.5 476.2 151 480 160 480zM450.7 294c-8.3-6-19.1-7.7-28.8-4.4l-48 16c-16.8 5.6-25.8 23.7-20.2 40.5s23.7 25.8 40.5 20.2l5.9-2V416H384c-17.7 0-32 14.3-32 32s14.3 32 32 32h48 48c17.7 0 32-14.3 32-32s-14.3-32-32-32H464V320c0-10.3-4.9-19.9-13.3-26zM418.3 91a32 32 0 1 1 27.4 57.9A32 32 0 1 1 418.3 91zM405.1 203.8l-6.8 9.2c-10.5 14.2-7.5 34.2 6.7 44.8s34.2 7.5 44.8-6.7l48.8-65.8c14-18.9 21.5-41.7 21.5-65.2c0-48.6-39.4-88-88-88s-88 39.4-88 88c0 39.2 25.6 72.4 61.1 83.8z"/></svg>;
      case 'table':
         return <TableViewIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'verticalSplit':
         return <VerticalSplitIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'time':
         return <AccessTimeIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'subtract':
         return <RemoveIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'block':
         return <BlockOutlinedIcon style={{width:inputWidth}}/>
      case 'refresh':
         return <SyncOutlinedIcon style={{width:inputWidth}}/>
      case 'approve':
         return <CheckCircleOutlineOutlinedIcon style={{width:inputWidth}}/>
      case 'arrowRight':
         return <KeyboardArrowRightIcon fontSize="medium"/>
      case 'arrowDown':
         return <KeyboardArrowDownIcon fontSize="medium"/>
      
      case 'cost':
         return <img src="../../assets/icons/cost.png" style={{ width: '1.5rem' }} alt="avatar" />
      case 'vessel':
         return <img src="../../assets/icons/vessel.png" style={{ width: '1.5rem' }} alt="avatar" />
      case 'time':
         return <img src="../../assets/icons/time.png" style={{ width: '1.5rem' }} alt="avatar" />
      case 'job':
         return <img src="../../assets/icons/job.png" style={{ width: '1.5rem' }} alt="avatar" />
      case 'chiPhi':
         return <img src="../../assets/icons/chiPhi.png" style={{ width: '1.5rem' }} alt="avatar" />
      
      case 'tacVu':
         return <img src="../../assets/icons/tacVu.png" style={{ width: '1.5rem' }} alt="avatar" />
      
      case 'tamUng':
         return <img src="../../assets/icons/tamUng.png" style={{ width: '1.5rem' }} alt="avatar" />
      
      case 'thanhToan':
         return <img src="../../assets/icons/thanhToan.png" style={{ width: '1.5rem' }} alt="avatar" />
      
      case 'quyetToan':
         return <img src="../../assets/icons/quyetToan.png" style={{ width: '1.5rem' }} alt="avatar" />
      
      case 'baoCao':
         return <img src="../../assets/icons/baoCao.png" style={{ width: '1.5rem' }} alt="avatar" />
      
      case 'thongKe':
         return <img src="../../assets/icons/thongKe.png" style={{ width: '1.5rem' }} alt="avatar" />
      
      case 'add':
         return <AddIcon style={{width:inputWidth == null ? '1.5rem':inputWidth}}/>
      
      case 'alignBottom':
         return <VerticalAlignBottomOutlinedIcon style={{width:'1.5rem'}}/>
      
      case 'alignTop':
         return <VerticalAlignTopOutlinedIcon style={{width:'1.5rem'}}/>
      
      case 'refresh':
         return <RotateLeftOutlinedIcon style={{width:'1.5rem'}}/>
      case 'trash':
         return <DeleteForeverOutlinedIcon style={{width:inputWidth == null ? '1.5rem':inputWidth}} fontSize={size || "medium"}/>
      case 'copy':
         return <ContentCopyIcon style={{width:inputWidth}}/>
      case 'pin':
         return <PushPinIcon style={{width:inputWidth}}/>
      case 'submit':
         return <BackupOutlinedIcon style={{width: inputWidth}}/>
      case 'print':
         return <PrintOutlinedIcon style={{width: inputWidth}}/>
      case 'search':
         return <SearchIcon style={{width: inputWidth}}/>
      case 'close':
         return <CloseIcon style={{width: inputWidth}}/>
      case 'list': 
         return <FormatListNumberedIcon style={{width: inputWidth}}/>
      case 'edit':
         return <DrawIcon style={{width: inputWidth}}/>
      case 'trash2':
         return <DeleteOutlineIcon style={{width: inputWidth}}/>
      
      default:
         return <React.Fragment/>
   }
}
