import React, { useContext } from "react";
import { AgentStore } from "./AgentStore";
import { AgentSignUpStore } from "./AgentSignUpStore";
import { makeAutoObservable } from "mobx";
import { AgentSignInStore } from "./AgentSignInStore";
import { AgentLinkStore } from "./AgentLinkStore";
import { retrieveObjectFromLocalStorage } from "../components/share/sharedFunction";
import { AuthStore } from "./AuthStore";
import { AgentTreeStore } from "./AgentTreeStore";
import { AgentTreeOutsideStore } from "./AgentTreeOutsideStore";

export class AppStore {
    authStore: AuthStore = new AuthStore();
    agentStore: AgentStore;
    agentSignUpStore: AgentSignUpStore;
    agentLinkStore: AgentLinkStore;
    agentTreeStore: AgentTreeStore;
    agentTreeOutsideStore: AgentTreeOutsideStore;

    constructor() {
        this.agentStore = new AgentStore();
        this.agentLinkStore = new AgentLinkStore();
        this.agentSignUpStore = new AgentSignUpStore();
        this.agentTreeStore = new AgentTreeStore();
        this.agentTreeOutsideStore = new AgentTreeOutsideStore();
        makeAutoObservable(this);
    }
    
}

export const appStore = new AppStore();
export const AppStoreContext = React.createContext(appStore);

export const useAppStore = () => useContext(AppStoreContext);