import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './HoaDon.module.scss'
import { HoaDonSubMenuRoute  } from '../../share/route-option';
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import { arrRole_admin } from "../../share/arrRole";
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../../stores/AppStore';

export const HoaDonSubMenu = observer(() => {
    const { authStore } = useAppStore();
    const token = authStore.token;
    const emailSignIn = authStore.employee?.email;
    const roleSignIn = authStore.employee?.role;

    const navigate = useNavigate(); //hook dùng để chuyển trang web

    const [stateString_submenuItem, setStateString_submenuItem] = useState<string>(''); // string
    const select_subMenuItem = (title: string) => {
    
        switch (title) {
            case 'Hóa đơn bán lẻ':
                navigate("/hoadon/hoadonbanle", { replace: true }); 
                setStateString_submenuItem(title);
            break;
        
            default:
                break;
        }
    }

    return (
        emailSignIn === "" ? <span></span> :

        <div className={clsx(styles.component_HoaDonSubMenu)}>
            {   HoaDonSubMenuRoute.length > 0 &&
                HoaDonSubMenuRoute.map((obj, index) => {
                    return (
                        <p key={obj.id} className={clsx(styles.subMenuItem, {
                            [styles.selectedSubMenuItem]: obj.title === stateString_submenuItem,
                        })} onClick={() => select_subMenuItem(obj.title)}>
                            {obj.title}
                        </p>
                    );
                })
            }
        </div>
    )
})