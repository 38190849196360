import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import clsx from 'clsx';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, Outlet, useNavigate } from "react-router-dom"
import styles from './HoaDon.module.scss'
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../../stores/AppStore';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";

import { createSanPham, findAllSanPham } from '../../../API/HoaDon';
import { HangHoa } from '../../../types/HangHoa';
import { formatThoudsand, getRandomNumberInRange, numberToVietnameseWords } from '../../share/sharedFunction';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { GetIconByName } from '../../share/GetIconByName';

interface ProductState {
    sanPham: string;
    donVi: string;
    gia: number;
    giaChu?: string;
}
const initialProductState: ProductState = {
    sanPham: '',
    donVi: 'Quả',
    gia: 0,
    giaChu: '',
};
export const HoaDon = observer(() => {
    // Get authStore from useAppStore
    const { authStore } = useAppStore();
    const [stateObjProduct, setStateObjProduct] = useState<ProductState>(initialProductState);
    // Handle input changes
    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        if (name === 'giaChu') {
            const numericValue = value.replace(/,/g, ''); // Remove commas

            // Check if the numericValue contains only digits
            if (!/^\d*$/.test(numericValue)) {
                alert('Chỉ được nhập số ở ô giá');
                return;
            }

            const giaValue = Number(numericValue);

            setStateObjProduct((prevState) => ({
                ...prevState,
                gia: isNaN(giaValue) ? 0 : giaValue,
                giaChu: formatThousandSeparator(numericValue)
            }));
        } else {
            setStateObjProduct((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const { data: dataSanPham, isLoading, isError } = useQuery({
        queryKey: ['allSanPham'],
        queryFn: findAllSanPham,
    });

    // console.log('dataSanPham: ', dataSanPham);
    useEffect(() => {
        if (dataSanPham) {
            setStateArrHangHoa(dataSanPham);
        }
    }, [dataSanPham]);

    const formatThousandSeparator = (num: string): string => {
        return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    const [stateArrHangHoa, setStateArrHangHoa] = useState<HangHoa[]>([])
    const mutation = useMutation({
        mutationFn: createSanPham,
        onSuccess: (response: any) => {
            setStateArrHangHoa(response);
        },
        onError: (error: any) => {
        }
    });

    const addProductToDB = () => {
        if (
            stateObjProduct.sanPham === initialProductState.sanPham
        ) {
            alert('Vui lòng điền đầy đủ sản phẩm trước khi thêm');
            return;
        }
        if (
            stateObjProduct.gia === initialProductState.gia
        ) {
            alert('Vui lòng điền đầy đủ giá trước khi thêm');
            return;
        }

        let shallowClone = { ...stateObjProduct };
        delete shallowClone.giaChu; // bỏ property giaChu di, vì db ko có lưu cái này

        mutation.mutate(shallowClone);
    }

    const [arrChecked, setArrChecked] = useState<number[]>([]);

    const handleCheck = (id: number) => {
        setArrChecked((prev) => {
            const isChecked = prev.includes(id);

            if (isChecked) {
                // If the id is already checked, remove it from the array
                return prev.filter((itemId) => itemId !== id);
            } else {
                // If the id is not checked, add it to the array
                return [...prev, id];
            }
        });
    };

    function generateRandomNumber(): number {
        const min = 9000;
        const max = 9999;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const divToExportRef = useRef<HTMLDivElement>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const onPrint = async () => {
        const pdf = new jsPDF('portrait', 'pt', 'a5');
        const pdfElement = divToExportRef.current;
        if (!pdfElement) {
            console.error('Element with id "PDFViewCreateCV" not found');
            return;
        }
        try {
            const canvas = await html2canvas(pdfElement, {
                scale: 2, // Reduce scale for smaller file size
                useCORS: true, // Enable cross-origin images
            });
            const imgData = canvas.toDataURL('image/jpeg', 0.75); // Adjust quality to 75%
            const imgProperties = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);

            // Create a blob URL for the PDF
            const pdfBlob = pdf.output('blob');
            const pdfURL = URL.createObjectURL(pdfBlob);

            // Set the iframe source to the PDF URL
            if (iframeRef.current) {
                iframeRef.current.src = pdfURL;
                iframeRef.current.onload = () => {
                    iframeRef.current?.contentWindow?.print();
                };
            }
        } catch (error) {
            console.error('Error exporting to PDF:', error);
        }
    }

    // console.log('arrChecked: ', arrChecked);
    // hàm này lọc ra các sản phẩm trùng, giữ lai các object ko trùng, nhưng các object này mỗi lần render sẽ là khác nhau giữa các thằng trùng của nó
    // đơn giá mỗi sản phẩm trong ngày ko đổi
    function seedRandom(seed: number): () => number {
        return () => {
            const x = Math.sin(seed++) * 10000;
            return x - Math.floor(x);
        };
    }
    
    const filterAndRandomize = (items: HangHoa[]): HangHoa[] => {
        // Group items by sanPham
        const grouped = items.reduce((acc, item) => {
            if (!acc[item.sanPham]) {
                acc[item.sanPham] = [];
            }
            acc[item.sanPham].push(item);
            return acc;
        }, {} as Record<string, HangHoa[]>);
    
        // Get today's date as a seed
        const today = new Date();
        const seed = today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();
        const random = seedRandom(seed);
    
        // Select random item for each group using the seeded random generator
        const result = Object.values(grouped).flatMap(group => {
            const randomIndex = Math.floor(random() * group.length);
            return [group[randomIndex]];
        });
    
        return result;
    };
    
    const [stateBoolShowButton, setStateBoolShowButton] = useState(true); // boolean

    const [stateNumTotalTarget, setStateNumTotalTarget] = useState<number>(0); // number 
    const [stateStrTotalTarget, setStateStrTotalTarget] = useState<string>(''); // number 
                        
    const handleInputChange_tongTien = (e: any) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/,/g, ''); // Remove commas, numericValue type string 
        // Check if the numericValue contains only digits
        if (!/^\d*$/.test(numericValue)) {
            alert('Chỉ được nhập số');
            return;
        }

        const num = Number(numericValue);
        const thoudsandSeparator = formatThousandSeparator(numericValue); //string
        setStateStrTotalTarget(thoudsandSeparator);
        setStateNumTotalTarget(num);
    };
    
    const [stateArrFinalSanPham, setStateArrFinalSanPham] = useState<any[]>([]);

    
    const [stateNumTotal, setStateNumTotal] = useState<number>(0); // number 

    const findSoLuong = (array: HangHoa[], target: number) => {
        const arr = filterAndRandomize(array);
        console.log(arr);
        
        // tính tổng giá
        const sumGia = arr.reduce((total, item) => total + item.gia, 0);
        const sltb = Math.round(target / sumGia); // soLuongTrungBinh
        //tìm số ngẫu nhiên từ 1 đến 15 để trừ số lượng cho nó không bằng nhau
        const sl2 = sltb - getRandomNumberInRange(5, 15); //tìm số ngẫu nhiên từ 1 đến 15 để trừ số lượng cho nó không bằng nhau
        const sl3 = sltb - getRandomNumberInRange(5, 15); //tìm số ngẫu nhiên từ 1 đến 15 để trừ số lượng cho nó không bằng nhau
        const sl4 = sltb - getRandomNumberInRange(5, 15); //tìm số ngẫu nhiên từ 1 đến 15 để trừ số lượng cho nó không bằng nhau
        const sl5 = sltb - getRandomNumberInRange(5, 15); //tìm số ngẫu nhiên từ 1 đến 15 để trừ số lượng cho nó không bằng nhau
        const sl6 = sltb - getRandomNumberInRange(5, 15); //tìm số ngẫu nhiên từ 1 đến 15 để trừ số lượng cho nó không bằng nhau
        const sumGia5SanPhamcoGiaLonNhat = sl2*arr[1].gia + sl3*arr[2].gia +sl4*arr[3].gia +sl5*arr[4].gia +sl6*arr[5].gia ;

        const giaConLai = target - sumGia5SanPhamcoGiaLonNhat;
        const soLuongSanPhamDauTien = Math.floor(giaConLai / arr[0].gia); // tại sao lại lấy sản phẩm đầu tiên, vì đơn giá nó là thấp nhất, nên biên động thay đổi và khoảng gap nhỏ, số sát số target hơn
        const result = sumGia5SanPhamcoGiaLonNhat + soLuongSanPhamDauTien*arr[0].gia;
        // console.log('result: ', result);
        setStateNumTotal(result);
        const diff = target - result;
        // console.log('diff: ', diff);
        let arrSoLuong = [soLuongSanPhamDauTien, sl2,sl3,sl4,sl5,sl6]
        let arrNew = [];
        for (let i = 0; i < arr.length; i++){
            let obj = arr[i];
            let objClone = { ...arr[i] };
            (objClone as any).soLuong = arrSoLuong[i];
            arrNew.push(objClone);
        }  
        let lengthArrHaveValue = arrNew.length;
        let quantityEmptyRow = 14 - lengthArrHaveValue;
        for (let i = 0; i < quantityEmptyRow; i++){
            let obj = {
                id: 200+i,
                sanPham: '',
                donVi: '',
                soLuong: 0,
                gia: 0,
            }
            arrNew.push(obj);
        }  
        console.log('arrNew: ', arrNew);
        setStateArrFinalSanPham(arrNew);
    }
    if (isLoading) return <div>Loading...</div>;
    if (dataSanPham === null) return null;
    return (
        <div className={clsx(styles.component_HoaDon)}>
            <div className={clsx(styles.container)}>
                <div className={clsx(styles.left)}>
                    <div className={clsx(styles.boxPrint)} ref={divToExportRef} id="divToExport">
                        <div className={clsx(styles.boxTitle)}>
                            <div className={clsx(styles.left)}>
                                <span className={clsx(styles.key)}>Đơn vị:</span>
                                <span className={clsx(styles.value)}> HKD Bích Ngân CS1</span>
                            </div>
                            <h1>HÓA ĐƠN BÁN LẺ</h1>
                            <div className={clsx(styles.right)}>
                                <span className={clsx(styles.key)}>Số: </span>
                                <span className={clsx(styles.key)}>{generateRandomNumber()}</span>
                            </div>
                        </div>
                        <div className={clsx(styles.pair)}>
                            <span className={clsx(styles.key)}>Họ tên người mua hàng: </span>
                            <span className={clsx(styles.value)}>................................................................................................................................................</span>
                        </div>
                        <div className={clsx(styles.pair)}>
                            <span className={clsx(styles.key)}>Đơn vị cơ quan: </span>
                            <span className={clsx(styles.value)}>...................................................................................................................................................................</span>
                        </div>
                        <div className={clsx(styles.table)}>
                            <div className={clsx(styles.heading)}>
                                <span>Số TT</span>
                                <span>TÊN HÀNG & QUY CÁCH PHẨM CHẤT</span>
                                <span>Đ.V.T</span>
                                <span>S.L</span>
                                <span>Giá ĐV</span>
                                <span>Thành Tiền</span>
                            </div>
                            {stateArrFinalSanPham.length > 0 &&
                                stateArrFinalSanPham.map((obj, index) => {
                                    return (
                                        <div className={clsx(styles.row)} key={obj.id}>
                                            <span>{index+1}</span>
                                            <span>{obj.sanPham}</span>
                                            <span>{obj.donVi}</span>
                                            <span>{obj.soLuong === 0 ? '': formatThoudsand(obj.soLuong)}</span>
                                            <span>{obj.gia === 0 ? '': formatThoudsand(obj.gia)}</span>
                                            <span>{obj.gia === 0 ? '': formatThoudsand(obj.gia*obj.soLuong)}</span>
                                        </div>
                                    )
                                })
                            }
                            <div className={clsx(styles.totalRow)}>
                                <span>Cộng</span>
                                <span>{formatThoudsand(stateNumTotal)}</span>
                            </div>
                        </div>
                        <div className={clsx(styles.tienChu)}>
                            <span className={clsx(styles.key)}>Cộng thành tiền (viết bằng chữ): </span>
                            <span className={clsx(styles.value)}>{numberToVietnameseWords(stateNumTotal.toString())} đồng</span>
                        </div>
                        <div className={clsx(styles.date)}>
                            <span>Ngày .......... Tháng .......... Năm 202</span>
                        </div>
                        <div className={clsx(styles.titles)}>
                            <span>Người nhận hàng</span>
                            <span>Người nhận tiền</span>
                            <span>Người viết hóa đơn</span>
                        </div>
                        <div className={clsx(styles.boxBtnConfirmPrint)}>
                            <div className={clsx(styles.boxInner)}>
                                {
                                    stateBoolShowButton &&
                                    <div className={clsx(styles.submit, styles.boxAction)} onClick={onPrint}>
                                        {GetIconByName('print', '1rem')}
                                        <p className={clsx(styles.text)}>Print</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx(styles.right)}>
                    {/* <button onClick={onPrint}>Random</button> */}
                    <div className={clsx(styles.tongTien)}>
                        <span>Tổng tiền hóa đơn muốn tạo: </span>
                        <input
                            type="text"
                            value={stateStrTotalTarget}
                            onChange={handleInputChange_tongTien}
                            />
                        <div className={clsx(styles.btnWrapper)}>
                            {
                                stateArrHangHoa.length > 0 &&
                                <button onClick={()=>findSoLuong(stateArrHangHoa, stateNumTotalTarget)}>Tạo hóa đơn</button>
                            }
                        </div>
                    </div>
                    <iframe ref={iframeRef} style={{ display: 'none' }} title="PDF Print Frame"></iframe>

                    <h4>Dach sách hàng hóa</h4>
                    <div className={clsx(styles.boxInput)}>
                        <div className={clsx(styles.heading)}>
                            <span>Sản phẩm</span>
                            <span>Đơn vị</span>
                            <span>Giá</span>
                            <span></span>
                        </div>
                        <div className={clsx(styles.row)}>
                            <div className={clsx(styles.boxInput)}>
                                <input
                                    type="text"
                                    name="sanPham"
                                    value={stateObjProduct.sanPham}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className={clsx(styles.boxInput)}>
                                <select name="donVi" onChange={handleInputChange} value={stateObjProduct.donVi}>
                                    <option value="Quả">Quả</option>
                                    <option value="Cái">Cái</option>
                                    <option value="Bộ">Bộ</option>
                                    <option value="Chiếc">Chiếc</option>
                                    <option value="Đôi">Đôi</option>
                                    <option value="Lô">Lô</option>
                                    <option value="Kiện">Kiện</option>
                                    <option value="Chai">Chai</option>
                                    <option value="Hộp">Hộp</option>
                                    <option value="Gói">Gói</option>
                                    <option value="Thùng">Thùng</option>
                                    <option value="Lít">Lít</option>
                                    <option value="Gram">Gram</option>
                                    <option value="Kilogram">Kilogram</option>
                                </select>
                            </div>
                            <div className={clsx(styles.boxInput)}>
                                <input
                                    type="text"
                                    name="giaChu"
                                    value={stateObjProduct.giaChu}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className={clsx(styles.boxInput)}>
                                <button onClick={addProductToDB}>Thêm</button>
                            </div>

                        </div>
                    </div>
                    <div className={clsx(styles.boxListHangHoa)}>
                        <div className={clsx(styles.heading)}>
                            <span>Chọn</span>
                            <span>Sản phẩm</span>
                            <span>Đơn vị</span>
                            <span>Giá</span>
                        </div>
                        {stateArrHangHoa.length > 0 &&
                            stateArrHangHoa.map((obj, index) => {
                                return (
                                    <div className={clsx(styles.row)} key={obj.id}>
                                        <span>
                                            <input
                                                type="checkbox"
                                                onChange={() => handleCheck(obj.id)}
                                                checked={arrChecked.includes(obj.id)}
                                            />
                                        </span>
                                        <span>{obj.sanPham}</span>
                                        <span>{obj.donVi}</span>
                                        <span>{formatThoudsand(obj.gia)}</span>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        </div>
    )
})