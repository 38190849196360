import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './Ketoan.module.scss'
import { KetoanSubmenuRoute  } from '../../share/route-option';
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import { arrRole_KT } from "../../share/arrRole";
function KetoanSubMenu() {
    const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
        email: "",
        fullName: "",
        role: "",
        token:""
    })
    const checkSignin = () => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        // console.log('userExist: ', userExist);
        if(userExist === null){
            return
        }
        setStateObjSignIn(userExist);
    
    }

    useEffect(() => {
        checkSignin();
    },[]);
    const [stateSidebarItems, setStateSidebarItems] = useState(KetoanSubmenuRoute); //tạo state toggle và cho nội dung của nó ban đầu là mảng AdminMenuRoute
    
    const dispatch = useDispatch(); //hook phát đi đến GeneralSlice.js

    const navigate = useNavigate(); //hook dùng để chuyển trang web

    const [stateString_submenuItem, setStateString_submenuItem] = useState<string>(''); // string
    const select_subMenuItem = (title: string) => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return;
        }
        const role = userExist.role;
        switch (title) {
            case 'Tổng hợp':
                // alert("Tính năng này đang phát triển ! Bạn sẽ đường điều hướng lại về trang Thông Tin");
                navigate("/ketoan/tonghop", { replace: true }); 
                setStateString_submenuItem(title);
            break;
            case 'Quỹ':
                if(!arrRole_KT.includes(role)){
                    alert("arrRole_KTBạn không có quyền xem phần này");
                    return;
                }
                navigate("/ketoan/quy", { replace: true }); 
                setStateString_submenuItem(title);
            break;
            case 'Thu Chi':
                if(!arrRole_KT.includes(role)){
                    alert("Bạn không có quyền xem phần này");
                    return;
                }
                navigate("/ketoan/thuchi", { replace: true }); 
                setStateString_submenuItem(title);
            break;
            case 'Sao kê':
                if(!arrRole_KT.includes(role)){
                    alert("arrRole_KTBạn không có quyền xem phần này");
                    return;
                }
                navigate("/ketoan/saoke", { replace: true }); 
                setStateString_submenuItem(title);
            break;
            default:
                break;
        }
    }

    return (
        stateObjSignIn.token === "" ? <span></span> :

        <div className={clsx(styles.component_KetoanSubMenu)}>
            {
                stateSidebarItems.map((obj, index) => {
                    let title: string = obj.title;
                    let status: Boolean = obj.status;
                    return (
                        <p key={obj.id} className={clsx(styles.subMenuItem, {
                            [styles.selectedSubMenuItem]: obj.title === stateString_submenuItem,
                            })} onClick={()=>select_subMenuItem(obj.title)}>
                            {/* <Link to={object.path} 
                                onClick={() => tabClick(title)} 
                                className={clsx({[styles.selected]: status}, styles.subMenuItem)}>
                                {obj.title}
                            </Link> */}
                            {obj.title}
                        </p>
                    )
                })
            }
        </div>
    )
}
export default KetoanSubMenu