import { makeAutoObservable } from "mobx";
import Agent from "../API/Agent";

export class AgentTreeStore {
    arrData: any;
    arrNode: any;
    selectedNode: any;
    objView: {
        viewNodeDetails: boolean,
        viewAddAgentChild: boolean,
    }

    constructor() {
        this.arrData = [];
        this.arrNode = [];
        this.selectedNode = {};
        this.objView = {
            viewNodeDetails: false,
            viewAddAgentChild: false,
        }
        makeAutoObservable(this);
    }
    // @action
    async findTree() {
        const response = await Agent.findTree("0001");
        // console.log('response: ', response);
        let arr = [];
        arr.push(response[0]);
        this.arrData = arr;
        this.arrNode = response;
    }
    async findNodeByIdWithMemberAndUser(nodeId: number) {
        const response = await Agent.findNodeByIdWithMemberAndUser(nodeId);
        // console.log('response: ', response);
        this.selectedNode = response;
        this.objView.viewNodeDetails = true;
    }
}