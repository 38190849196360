import { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './Saoke.module.scss' 
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import collectionAPI from '../../../API/collectionAPI';
import NotYetSignIn from '../general/NotYetSignIn';
import { useQuery } from 'react-query';

function Saoke() {
    const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
        email: "",
        fullName: "",
        role: "",
        token:""
    })
    const checkSignin = () => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        setStateObjSignIn(userExist);
        
    
    }
    useEffect(() => {
        checkSignin();
    },[]);
    const dayAbbreviations: any = {
        Monday: 'M',
        Tuesday: 'T',
        Wednesday: 'W',
        Thursday: 'T',
        Friday: 'F',
        Saturday: 'S',
        Sunday: 'S'
    };
    const getCalendarOfYear = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return [];
        }
        let token = userExist.token;
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.getCalendarOfYear(token, 2024);
            // console.log('response: ', response);
            return response.data;
        }catch(err){
            console.log('err:', err);
        }
    }
   
    const { data: calendar } = useQuery('calendarLeft', getCalendarOfYear);
    // console.log('calendar: ', calendar);

    return (
        stateObjSignIn.token === "" ? <NotYetSignIn /> : 
        <div className={clsx(styles.component_Saoke)}>
            <h4>TỔNG HỢP SAO KÊ 2024</h4>
            <div className={clsx(styles.legends)}>
                <p className={clsx(styles.haveData)}></p>
                <p className={clsx(styles.explain)}>Có chuyển tiền ra và vào</p>
                <p className={clsx(styles.doing)}></p>
                <p className={clsx(styles.explain)}>Đang diễn ra</p>
                <p className={clsx(styles.noData)}></p>
                <p className={clsx(styles.explain)}>Không có dữ liệu</p>
                <p className={clsx(styles.empty)}></p>
                <p className={clsx(styles.explain)}>chưa download sao kê</p>
            </div>
            <div className={clsx(styles.title)}>
                <p className={clsx(styles.name)}>Ms. Ánh</p>
                <p className={clsx(styles.name)}>Ms. Xuân</p>
                <p className={clsx(styles.name)}>Mrs. Linh</p>
            </div>
            <div className={clsx(styles.container)}>
                <div className={clsx(styles.left)}>
                    {
                        
                        calendar !== undefined && 
                        Object.keys(calendar!.objYearAnh).map((keyMonth: string) => (
                            <div key={keyMonth} className={clsx(styles.monthWrapper)}>
                                <p className={clsx(styles.monthName)}>{keyMonth}</p>
                                <div className={clsx(styles.thu)}>
                                    {
                                        Object.entries(calendar!.objYearAnh[keyMonth]).map(([keyThu, value]) => (
                                            <div key={keyThu} className={clsx(styles.thuWrapper)}>
                                                <p className={clsx(styles.nameThu)}>{dayAbbreviations[keyThu]}</p>
                                                {/* <p>{JSON.stringify(value)}</p>  */}
                                                {
                                                    Array.isArray(value) ? (
                                                        value.map((obj: any, index: number) => (
                                                            <div key={index} className={clsx(styles.numberWrapper)}>
                                                                {
                                                                    Object.keys(obj).map((objKey: string) => (
                                                                        (() => {
                                                                            if (obj[objKey].haveData) {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.haveData)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                            else if (obj[objKey].accountNo !== "" && obj[objKey].isDone === true && obj[objKey].haveData === false) {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.noData)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                            else if (obj[objKey].accountNo !== "" && obj[objKey].isDone === false) {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.doing)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                            else if (obj[objKey].accountNo === "") {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.empty)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                        })()
                                                                    
                                                                    ))
                                                                }
                                                            </div>
                                                        ))
                                                    ) : (
                                                    <p className={clsx(styles.item)}>Value is not an array</p>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={clsx(styles.middle)}>
                    {
                        
                        calendar !== undefined && 
                        Object.keys(calendar!.objYearXuan).map((keyMonth: string) => (
                            <div key={keyMonth} className={clsx(styles.monthWrapper)}>
                                <p className={clsx(styles.monthName)}>{keyMonth}</p>
                                <div className={clsx(styles.thu)}>
                                    {
                                        Object.entries(calendar!.objYearXuan[keyMonth]).map(([keyThu, value]) => (
                                            <div key={keyThu} className={clsx(styles.thuWrapper)}>
                                                <p className={clsx(styles.nameThu)}>{dayAbbreviations[keyThu]}</p>
                                                {/* <p>{JSON.stringify(value)}</p>  */}
                                                {
                                                    Array.isArray(value) ? (
                                                        value.map((obj: any, index: number) => (
                                                            <div key={index} className={clsx(styles.numberWrapper)}>
                                                                {
                                                                    Object.keys(obj).map((objKey: string) => (
                                                                        (() => {
                                                                            if (obj[objKey].haveData) {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.haveData)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                            else if (obj[objKey].accountNo !== "" && obj[objKey].haveData === false) {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.noData)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                            else if (obj[objKey].accountNo !== "" && obj[objKey].isDone === false) {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.doing)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                            else if (obj[objKey].accountNo === "") {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.empty)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                        })()
                                                                    
                                                                    ))
                                                                }
                                                            </div>
                                                        ))
                                                    ) : (
                                                    <p className={clsx(styles.item)}>Value is not an array</p>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={clsx(styles.right)}>
                    {
                        
                        calendar !== undefined && 
                        Object.keys(calendar!.objYearLinh).map((keyMonth: string) => (
                            <div key={keyMonth} className={clsx(styles.monthWrapper)}>
                                <p className={clsx(styles.monthName)}>{keyMonth}</p>
                                <div className={clsx(styles.thu)}>
                                    {
                                        Object.entries(calendar!.objYearLinh[keyMonth]).map(([keyThu, value]) => (
                                            <div key={keyThu} className={clsx(styles.thuWrapper)}>
                                                <p className={clsx(styles.nameThu)}>{dayAbbreviations[keyThu]}</p>
                                                {/* <p>{JSON.stringify(value)}</p>  */}
                                                {
                                                    Array.isArray(value) ? (
                                                        value.map((obj: any, index: number) => (
                                                            <div key={index} className={clsx(styles.numberWrapper)}>
                                                                {
                                                                    Object.keys(obj).map((objKey: string) => (
                                                                        (() => {
                                                                            if (obj[objKey].haveData) {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.haveData)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                            else if (obj[objKey].accountNo !== "" && obj[objKey].haveData === false) {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.noData)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                            else if (obj[objKey].accountNo !== "" && obj[objKey].isDone === false) {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.doing)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                            else if (obj[objKey].accountNo === "") {
                                                                                return (
                                                                                    <p className={clsx(styles.item, styles.empty)} key={objKey}>
                                                                                        {parseInt(objKey+'') === 0 ? '': objKey}
                                                                                        {/* : {JSON.stringify(obj[objKey])} */}
                                                                                    </p>
                                                                                );
                                                                            } 
                                                                        })()
                                                                    
                                                                    ))
                                                                }
                                                            </div>
                                                        ))
                                                    ) : (
                                                    <p className={clsx(styles.item)}>Value is not an array</p>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}
export default Saoke