import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './Ketoan.module.scss'
import KetoanSubMenu from './KetoanSubMenu';

function KetoanHome() {
   const dispatch = useDispatch(); //hook phát đi đến GeneralSlice.js

   return (
      <div className={clsx(styles.component_KetoanHome)}>
         <KetoanSubMenu/>   
         <Outlet/>

      </div>
   )
}
export default KetoanHome


