import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './HoaDon.module.scss'
import { HoaDonSubMenu } from './HoaDonSubMenu';

function HoaDonHome() {

   return (
      <div className={clsx(styles.component_HoaDonHome)}>
         <HoaDonSubMenu/>   
         <Outlet/>

      </div>
   )
}
export default HoaDonHome


