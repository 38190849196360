import { makeAutoObservable } from "mobx";
import { AgentUser, SignInRequest, SignUpRequest } from "../types/AgentUser";
import Agent from "../API/Agent";
import { makePersistable } from "mobx-persist-store";
import { Employee } from "../types/Employee";

export class AuthStore {
    token: string = "";
    user: AgentUser | undefined = undefined;
    employee: Employee | undefined = undefined;
    constructor() {
        makeAutoObservable(this);
        // dùng thư viện makePersistable để lưu token và thông tin user vào local storage
        makePersistable(this, { name: 'AuthStore', properties: ['token', 'user', 'employee'], storage: window.localStorage });
    }

    async login(body: SignInRequest) {
        // const { access_token, user } = await login(body);
        const obj = await Agent.login(body);
        console.log('obj: ', obj);
        if(obj.status === "succeed"){
            this.token = obj.result.access_token;
            this.user = { phone: obj.result.user.phone, name: obj.result.user.name };
        }
        return obj;
    }

    async saveEmployeeToken(response: any) {
        console.log('response: ', response);
        this.token = response?.token;
        this.employee = {
            email: response.email,
            fullName: response.fullName,
            id: response.id,
            role: response.role,
            
        };
    }

    logout = () => {
        this.token = "";
        this.user = undefined;
    }

    signUp(body: SignUpRequest) {

    }
}