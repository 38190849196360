import instance from "./axios";
import { Prisma } from '@prisma/client';
import { AxiosResponse } from "axios";

interface CollectionAPI {
    findAllEmployees: (token: string) => Promise<AxiosResponse<any>>;
    findAllDepartments: () => Promise<AxiosResponse<any>>;
    findAllNganhang: (token: string) => Promise<AxiosResponse<any>>;
    findAllNguonKH: (token: string) => Promise<AxiosResponse<any>>;
    findAllGiaodich: (token: string) => Promise<AxiosResponse<any>>;
    findAllMayPos: (token: string) => Promise<AxiosResponse<any>>;
    findAllMayPos_exclude_phiMayPos: (token: string) => Promise<AxiosResponse<any>>;
    findAllThe: (token: string) => Promise<AxiosResponse<any>>;
    findAllkhachhang: (token: string) => Promise<AxiosResponse<any>>;
    findAllkhachhangByNguonKH: (token: string, nguonKH: string) => Promise<AxiosResponse<any>>;
    deleteKhachHangById: (token: string, id: number) => Promise<AxiosResponse<any>>;
    // deleteHinhById: (token: string, data: any) => Promise<AxiosResponse<any>>;
    deleteHinhGiaoDichById: (token: string, id: any) => Promise<AxiosResponse<any>>;

    findKhachhangByNameOrPhone: (token: string,type: string, value: string) => Promise<AxiosResponse<any>>;
    findGiaodichRecordsByDateRange_succeed: (token: string,startDay: string, endDay: string, nguonkh: string) => Promise<AxiosResponse<any>>;
    findGiaodichRecordsByDateRange_notSucceed: (token: string,startDay: string, endDay: string, nguonkh: string) => Promise<AxiosResponse<any>>;
    findGiaodichRecordsByDateRangeAndNguonKH: (token: string,startDay: string, endDay: string, nguonkh: string) => Promise<AxiosResponse<any>>;
    findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByASC: (token: string,startDate: string, endDate: string, nguonKH: string, teamrutdaothongbaotrangthai: string, nhucau: string) => Promise<AxiosResponse<any>>;
    findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByDESC: (token: string,startDate: string, endDate: string, nguonKH: string, teamrutdaothongbaotrangthai: string, nhucau: string) => Promise<AxiosResponse<any>>;
    findAllkhachhangByNguonKHByDateRange: (token: string,startDay: string, endDay: string, nguonKH: string) => Promise<AxiosResponse<any>>;
    findGiaodichRecordsByStartEndEmailTrangThai: (token: string, startDate: string, endDate: string, inputEmail: string, inputTrangThai: string, nguonkh: string) => Promise<AxiosResponse<any>>;
    findGiaoDichById: (token: string, id: number) => Promise<AxiosResponse<any>>;
    findKhachhangById: (token: string, id: number) => Promise<AxiosResponse<any>>;
    calculateSumTienNapAndTienRutByGiaodichId: (token: string, giaodichId: number) => Promise<AxiosResponse<any>>;
    calculateSumTienRutByGiaodichId: (token: string, giaodichId: number) => Promise<AxiosResponse<any>>;

    getRutRecordsForDate: (token: string, createdAt: string) => Promise<AxiosResponse<any>>;
    getDaoRecordsForDate: (token: string, createdAt: string, ) => Promise<AxiosResponse<any>>;
    getRutRecordsForDateRange: (token: string, startDay: string, endDay: string) => Promise<AxiosResponse<any>>;
    getDaoRecordsForDateRange: (token: string, startDay: string, endDay: string) => Promise<AxiosResponse<any>>;
    getRutDaoRecordsForDateRange: (token: string, startDay: string, endDay: string) => Promise<AxiosResponse<any>>;
    getRutRecordsForDateRange2: (token: string, startDay: string, endDay: string) => Promise<AxiosResponse<any>>;
    getDaoRecordsForDateRange2: (token: string, startDay: string, endDay: string) => Promise<AxiosResponse<any>>;
    findLatestRutByGiaodichId: (token: string, giaodichId: number) => Promise<AxiosResponse<any>>;
    findLatestDaoByGiaodichId: (token: string, giaodichId: number) => Promise<AxiosResponse<any>>;
    
    addChamCong: (data: any) => Promise<AxiosResponse<any>>;
    createKhachhang: (token: string, data: any) => Promise<AxiosResponse<any>>;
    createHinhGiaoDich: (token: string, data: any) => Promise<AxiosResponse<any>>;
    createGiaodich: (token: string, data: any) => Promise<AxiosResponse<any>>;
    deleteGiaodich: (token: string, id: number) => Promise<AxiosResponse<any>>;
    createPhone: (token: string, data: any) => Promise<AxiosResponse<any>>;
    checkPhone: (token: string, phone: string) => Promise<AxiosResponse<any>>;
    editPhone: (token: string,id: number, data: any) => Promise<AxiosResponse<any>>;
    updatePhoneField: (token: string,id: number, key: string, value: string) => Promise<AxiosResponse<any>>;
    deletePhone: (token: string,id: number) => Promise<AxiosResponse<any>>;
    createThe: (token: string, data: any) => Promise<AxiosResponse<any>>;
    createRequest: (data: any) => Promise<AxiosResponse<any>>;
    login1: (data: any) => Promise<AxiosResponse<any>>;
    removeAccount: (data: any) => Promise<AxiosResponse<any>>;
    checkEmailPasswordExist: (data: any) => Promise<AxiosResponse<any>>;
    login2: (data: any) => Promise<AxiosResponse<any>>;
    sendMultiEmail: (data: any) => Promise<AxiosResponse<any>>;
    
    createDao: (token: string, data: any) => Promise<AxiosResponse<any>>;
    updateDaoById: (token: string, id: number, data: any) => Promise<AxiosResponse<any>>;
    updateDaoById_partial: (token: string, id: number, data: any) => Promise<AxiosResponse<any>>;
    deleteDao: (token: string, id: number) => Promise<AxiosResponse<any>>;
    
    createRut: (token: string, data: any, email: string) => Promise<AxiosResponse<any>>;
    updateRutById: (token: string, id: number, data: any) => Promise<AxiosResponse<any>>;
    updateRutById_partial: (token: string, id: number, data: any) => Promise<AxiosResponse<any>>;
    deleteRut: (token: string, id: number) => Promise<AxiosResponse<any>>;

    updateKhachhangById: (token: string, id: number, data: any) => Promise<AxiosResponse<any>>;
    updateGiaodichById: (token: string, id: number, data: any) => Promise<AxiosResponse<any>>;
    updateTheById: (token: string, id: number, data: any) => Promise<AxiosResponse<any>>;
    updateTheById_idKhachhang_tenTrenThe: (token: string, idKhachhang: number, tenTrenThe: string, data: any) => Promise<AxiosResponse<any>>;

    updateGiaodichByIdOneField: (token: string, giaodichId: number, fieldToUpdate: string, value: any) => Promise<AxiosResponse<any>>;
    updateGiaodichWithApproval: (token: string, giaodichId: number, value: any) => Promise<AxiosResponse<any>>;
    updateKhachhangByIdOneField: (token: string, khachhangId: number, fieldToUpdate: string, value: any) => Promise<AxiosResponse<any>>;
    updateTheByIdByfield: (token: string, id: number, fieldToUpdate: string, value: any) => Promise<AxiosResponse<any>>;
    updateEmployeePasswordByEmail: (token: string, data: any) => Promise<AxiosResponse<any>>;
    updateFieldByPos: (token: string, pos: string, fieldName: string, fieldValue: string) => Promise<AxiosResponse<any>>;

    MergeRecordsAndRequestsByYearMonth: (year: number,month: number) => Promise<AxiosResponse<any>>;
    getEmployeesByRole: (token: string, role:string) => Promise<AxiosResponse<any>>;
    checkIfIdNumExists: (token: string, id:number, modelName: string) => Promise<AxiosResponse<any>>;
    
    removeAccessTokenRecordByEmail: (token: string, email:string) => Promise<AxiosResponse<any>>;
    uploadFile: (token: string, formData:any) => Promise<AxiosResponse<any>>;
    uploadFile2: (formData:any) => Promise<AxiosResponse<any>>;
    
    createQuy: (token: string, data:any) => Promise<AxiosResponse<any>>;
    getQuyById: (token: string, id:number) => Promise<AxiosResponse<any>>;
    getAllQuy: (token: string) => Promise<AxiosResponse<any>>;
    getQuyByEmployeeIdAndCreatedAt: (token: string,employeeId: string ,createdAt: string ) => Promise<AxiosResponse<any>>;
    updateQuy: (token: string,id: number, data:any) => Promise<AxiosResponse<any>>;
    deleteQuy: (token: string, id: number) => Promise<AxiosResponse<any>>;
    
    isHinhChuyenKhoanTienPhiNull: (token: string, giaodichId: number) => Promise<AxiosResponse<any>>;
    isPropertyOfKhachhangNull: (token: string, id: number, colName: string) => Promise<AxiosResponse<any>>;
    getTransactionsByDateAndAccount: (token: string, fromDate: string,toDate: string,accountNo: string) => Promise<AxiosResponse<any>>;
    deleteMediaById: (token: string, mediaId: string) => Promise<AxiosResponse<any>>;
    
    transactions: (data: any, user: string) => Promise<AxiosResponse<any>>;
    transactions2: (data: any, user: string, downloadDateId: number) => Promise<AxiosResponse<any>>;
    findDownloadDateByAccountAndDate: (token: string ,accountNo: string, downloadDate: string) => Promise<AxiosResponse<any>>;
    
    getCalendarOfYear: (token: string ,year: number) => Promise<AxiosResponse<any>>;
    updateFieldRutById: (token: string, id: number, fieldName: string, fieldValue: any) => Promise<AxiosResponse<any>>;
    updateFieldDaoById: (token: string, id: number, fieldName: string, fieldValue: any) => Promise<AxiosResponse<any>>;

    findTheByKhachhangId: (token: string, idKhachhang:number) => Promise<AxiosResponse<any>>;

}

//collectionAPI là 1 object chứa tất cả đường dẫn API
const collectionAPI: CollectionAPI = {
    findKhachhangByNameOrPhone: (token: string, type: string, value: string) => {
        const url = "/finance/findKhachhangByNameOrPhone/" + type + "/" + value;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findTheByKhachhangId: (token: string, idKhachhang:number) => {
        const url = "/finance/findTheByKhachhangId/" + idKhachhang;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    // Quy start-------------------------------------------------------------------------------------------------------------
    createQuy: (token, data) => {
        const url = "/quy";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url, data);
    },
    getQuyById: (token, id) => {
        const url = "/quy/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getAllQuy: (token) => {
        const url = "/quy";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getQuyByEmployeeIdAndCreatedAt: (token,employeeId ,createdAt) => {
        const url = "/quy/getQuyByEmployeeIdAndCreatedAt/"+employeeId+"/"+createdAt;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    updateQuy: (token, id, data) => {
        const url = "/quy/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },
    deleteQuy: (token, id) => {
        const url = "/quy/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    // Quy end-------------------------------------------------------------------------------------------------------------
    // finance start-------------------------------------------------------------------------------------------------------------
    // --------------------------------------get start
    uploadFile: (token, formData) => {
        const url = "/media";
        
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
    uploadFile2: (formData) => {
        const url = "/media2";
        return instance.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
    deleteMediaById: (token, mediaId) => {
        const url = "/media/deleteMediaById/"+mediaId;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    findAllNganhang: (token) => {
        const url = "/finance/findAllNganhang";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findAllNguonKH: (token) => {
        const url = "/finance/findAllNguonKH";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findAllGiaodich: (token) => {
        const url = "/finance/findAllGiaodich";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findAllMayPos: (token) => {
        const url = "/finance/findAllMayPos";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findAllMayPos_exclude_phiMayPos: (token) => {
        const url = "/finance/findAllMayPos_exclude_phiMayPos";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findAllThe: (token) => {
        const url = "/finance/findAllThe";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findAllkhachhang: (token) => {
        const url = "/finance/findAllkhachhang";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findAllkhachhangByNguonKH: (token: string, nguonKH: string) => {
        const url = "/finance/findAllkhachhangByNguonKH/" + nguonKH;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findGiaodichRecordsByDateRange_succeed: (token: string, startDay: string, endDay: string, nguonkh: string) => {
        const url = "/finance/findGiaodichRecordsByDateRange_succeed/" + startDay + "/" + endDay +"/" + nguonkh;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findGiaodichRecordsByDateRange_notSucceed: (token: string, startDay: string, endDay: string, nguonkh: string) => {
        const url = "/finance/findGiaodichRecordsByDateRange_notSucceed/" + startDay + "/" + endDay +"/" + nguonkh;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findGiaodichRecordsByDateRangeAndNguonKH: (token: string, startDay: string, endDay: string, nguonkh: string) => {
        const url = "/finance/findGiaodichRecordsByDateRangeAndNguonKH/" + startDay + "/" + endDay +"/" + nguonkh;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByASC: (token: string, startDay: string, endDay: string, nguonkh: string, teamrutdaothongbaotrangthai: string, nhucau: string) => {
        const url = "/finance/findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByASC/" + startDay + "/" + endDay +"/" + nguonkh+"/"+teamrutdaothongbaotrangthai+"/"+nhucau;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByDESC: (token: string, startDay: string, endDay: string, nguonkh: string, teamrutdaothongbaotrangthai: string, nhucau: string) => {
        const url = "/finance/findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByDESC/" + startDay + "/" + endDay +"/" + nguonkh+"/"+teamrutdaothongbaotrangthai+"/"+nhucau;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findAllkhachhangByNguonKHByDateRange: (token: string, startDay: string, endDay: string, nguonKH: string) => {
        const url = "/finance/findAllkhachhangByNguonKHByDateRange/" + startDay + "/" + endDay +"/" + nguonKH;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findGiaodichRecordsByStartEndEmailTrangThai: (token: string, startDate: string, endDate: string, inputEmail: string, inputTrangThai: string, nguonkh: string) => {
        const url = "/finance/findGiaodichRecordsByStartEndEmailTrangThai/" + startDate + "/" + endDate + "/" + inputEmail + "/" + inputTrangThai + "/" + nguonkh;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findGiaoDichById: (token: string, id: number) => {
        const url = "/finance/findGiaoDichById/" + id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },


    findLatestRutByGiaodichId: (token: string, giaodichId: number) => {
        const url = "/finance/findLatestRutByGiaodichId/" + giaodichId;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findLatestDaoByGiaodichId: (token: string, giaodichId: number) => {
        const url = "/finance/findLatestDaoByGiaodichId/" + giaodichId;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getRutRecordsForDate: (token: string, createdAt: string) => {
        const url = "/finance/getRutRecordsForDate/" + createdAt;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getDaoRecordsForDate: (token: string, createdAt: string) => {
        const url = "/finance/getDaoRecordsForDate/" + createdAt;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getRutRecordsForDateRange: (token: string, startDay, endDay) => {
        const url = "/finance/getRutRecordsForDateRange/" + startDay +"/"+ endDay;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getDaoRecordsForDateRange: (token: string, startDay, endDay) => {
        const url = "/finance/getDaoRecordsForDateRange/" + startDay +"/"+ endDay;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getRutRecordsForDateRange2: (token: string, startDay, endDay) => {
        const url = "/finance/getRutRecordsForDateRange2/" + startDay +"/"+ endDay;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getDaoRecordsForDateRange2: (token: string, startDay, endDay) => {
        const url = "/finance/getDaoRecordsForDateRange2/" + startDay +"/"+ endDay;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getRutDaoRecordsForDateRange: (token: string, startDay, endDay) => {
        const url = "/finance/getRutDaoRecordsForDateRange/" + startDay +"/"+ endDay;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },


    findKhachhangById: (token: string, id: number) => {
        const url = "/finance/findKhachhangById/" + id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    calculateSumTienNapAndTienRutByGiaodichId: (token: string, giaodichId: number) => {
        const url = "/finance/calculateSumTienNapAndTienRutByGiaodichId/" + giaodichId;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    calculateSumTienRutByGiaodichId: (token: string, giaodichId: number) => {
        const url = "/finance/calculateSumTienRutByGiaodichId/" + giaodichId;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    deleteKhachHangById : (token,id) => {
        const url = "/finance/deleteKhachHangById/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    // --------------------------------------get end
    // --------------------------------------post start
    createKhachhang : (token,data) => {
        const url = "/finance/createKhachhang";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url, data);
    },
    createHinhGiaoDich : (token,data) => {
        const url = "/finance/createHinhGiaoDich";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url, data);
    },
    // deleteHinhById : (token, data) => {
    //     console.log('data: ', data);
    //     const url = "/finance/deleteHinhById";
    //     instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    //     return instance.delete(url, data);
    // },
    deleteHinhGiaoDichById : (token, id: number) => {
        const url = "/finance/deleteHinhById/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    createPhone : (token, data) => {
        const url = "/finance/createPhone";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url, data);
    },
    checkPhone : (token, phone) => {
        const url = "/finance/checkPhone/"+phone;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    editPhone : (token, id, data) => {
        const url = "/finance/editPhone/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },
    deletePhone : (token, id) => {
        const url = "/finance/deletePhone/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    updatePhoneField : (token, id, key, value) => {
        const url = "/finance/updatePhoneField/"+id+"/"+key+"/"+value;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    createThe : (token, data) => {
        const url = "/finance/createThe";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url, data);
    },
    createGiaodich : (token,data) => {
        const url = "/finance/createGiaodich";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url, data);
    },
    deleteGiaodich : (token,id) => {
        const url = "/finance/deleteGiaodich/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    // --------------------------------------post end
    // --------------------------------------put start
    updateKhachhangById : (token, id, data) => {
        const url = "/finance/updateKhachhangById/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },
    updateGiaodichById : (token, id, data) => {
        const url = "/finance/updateGiaodichById/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },

    updateTheById : (token, id, data) => {
        const url = "/finance/updateTheById/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },

    updateTheById_idKhachhang_tenTrenThe : (token, idKhachhang,tenTrenThe, data) => {
        const url = "/finance/updateTheById_idKhachhang_tenTrenThe/"+idKhachhang+"/"+tenTrenThe;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },
    updateGiaodichByIdOneField : (token, giaodichId, fieldToUpdate, value) => {

        const url = "/finance/updateGiaodichByIdOneField/"+giaodichId+"/"+fieldToUpdate+"/"+value;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url);
    },

    updateGiaodichWithApproval : (token, giaodichId, value) => {

        const url = "/finance/updateGiaodichWithApproval/"+giaodichId+"/"+value;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url);
    },
    updateKhachhangByIdOneField : (token, khachhangId, fieldToUpdate, value) => {

        const url = "/finance/updateKhachhangByIdOneField/"+khachhangId+"/"+fieldToUpdate+"/"+value;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url);
    },
    updateTheByIdByfield : (token, id, fieldToUpdate, value) => {

        const url = "/finance/updateTheByIdByfield/"+id+"/"+fieldToUpdate+"/"+value;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url);
    },
    updateFieldRutById : (token, id, fieldName, fieldValue) => {

        const url = "/finance/updateFieldRutById/"+id+"/"+fieldName+"/"+fieldValue;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url);
    },
    updateFieldDaoById : (token, id, fieldName, fieldValue) => {

        const url = "/finance/updateFieldDaoById/"+id+"/"+fieldName+"/"+fieldValue;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url);
    },

    updateFieldByPos : (token, pos, fieldName, fieldValue) => {

        const url = "/finance/updateFieldByPos/"+pos+"/"+fieldName+"/"+fieldValue;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url);
    },
    // --------------------------------------put end
    // finance end-------------------------------------------------------------------------------------------------------------
    
    findAllEmployees: (token) => {
        const url = "/hr/employees";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findAllDepartments: () => {
        const url = "/hr/departments";
        return instance.get(url);
    },
    updateEmployeePasswordByEmail : (token, data) => {
        const url = "/hr/updateEmployeePasswordByEmail";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },

    // --------------------------------------findAll end
    // --------------------------------------post start
    addChamCong : (data) => {
        const url = "/hr/createManyRecord";
        return instance.post(url, data);
    },
    createRequest : (data) => {
        const url = "/hr/createManyRequest";
        return instance.post(url, data);
    },
    createDao : (token, data) => {
        const url = "/finance/createDao";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url, data);
    },
    updateDaoById : (token, id, data) => {
        const url = "/finance/updateDaoById/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },
    updateDaoById_partial : (token, id, data) => {
        const url = "/finance/updateDaoById_partial/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },
    deleteDao: (token: string, id: number) => {
        const url = "/finance/deleteDao/" + id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },

    createRut : (token, data, email) => {
        const url = "/finance/createRut/"+email;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url, data);
    },
    updateRutById : (token, id, data) => {
        const url = "/finance/updateRutById/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },
    updateRutById_partial : (token, id, data) => {
        const url = "/finance/updateRutById_partial/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url, data);
    },
    deleteRut: (token: string, id: number) => {
        const url = "/finance/deleteRut/" + id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    isHinhChuyenKhoanTienPhiNull: (token, giaodichId) => {
        const url = "/finance/isHinhChuyenKhoanTienPhiNull/"+giaodichId;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    isPropertyOfKhachhangNull: (token, id, colName) => {
   
        const url = `/finance/isPropertyOfKhachhangNull/${id}/${colName}`;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    sendMultiEmail : (data) => {
        const url = "/email/send";
        return instance.post(url, data);
    },
    login1 : (data) => {
        const url = "/auth/login1";
        return instance.post(url, data);
    },
    removeAccount : (data) => {
        const url = "/user/removeAccount";
        return instance.post(url, data);
    },
    checkEmailPasswordExist : (data) => {
        const url = "/auth/checkEmailPasswordExist";
        return instance.post(url, data);
    },
    login2 : (data) => {
        const url = "/auth/login2";
        return instance.post(url, data); 
    },
    // --------------------------------------post end
    // --------------------------------------PUT START
    // --------------------------------------PUT END
    MergeRecordsAndRequestsByYearMonth: (year: number,month: number) => {
        const url = "/hr/MergeRecordsAndRequestsByYearMonth/" + year + "/" + month;
        return instance.get(url);
    },
    getEmployeesByRole: (token: string, role: string) => {
        const url = "/hr/getEmployeesByRole/" + role;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    checkIfIdNumExists: (token: string, id: number, modelName: string) => {
        const url = "/finance/checkIfIdNumExists/" + id + "/" + modelName;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    removeAccessTokenRecordByEmail: (token: string, email: string) => {
        const url = "/auth/removeAccessTokenRecordByEmail/" + email;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    getTransactionsByDateAndAccount: (token: string, fromDate: string,toDate: string,accountNo: string) => {
        const url = "/finance/getTransactionsByDateAndAccount/" + fromDate + "/" + toDate+ "/" + accountNo;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    // --------------------------------------MBBANK START
    transactions : (data, user) => {
        const url = "/mbbank/transactions";
        return instance.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'user': user
            }
        } ); 
    },
    transactions2 : (data, user, downloadDateId) => {
        const url = "/mbbank/transactions2/"+downloadDateId;
        return instance.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'user': user
            }
        } ); 
    },
    // --------------------------------------MBBANK START
    findDownloadDateByAccountAndDate : (token, accountNo, downloadDate) => {
        const url = "/finance/findDownloadDateByAccountAndDate/"+ accountNo + "/" + downloadDate;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    getCalendarOfYear : (token, year) => {
        const url = "/finance/getCalendarOfYear/"+ year;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    // --------------------------------------MBBANK END
};
interface CollectionAPI_save_data {
    
    create_Kh_Giaodich_The_Stknh_Phone: (token:string,data:any) => Promise<AxiosResponse<any>>;
    update_Kh_Giaodich_The_Stknh: (token:string,data:any) => Promise<AxiosResponse<any>>;
    create_Giaodich_The_Stknh_Phone: (token:string,data:any) => Promise<AxiosResponse<any>>;

}
const collectionAPI_save_data: CollectionAPI_save_data = {
    create_Kh_Giaodich_The_Stknh_Phone: (token, data) => {
        const url = "/finance/create_Kh_Giaodich_The_Stknh_Phone";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url,data);
    },
    update_Kh_Giaodich_The_Stknh: (token, data) => {
        const url = "/finance/update_Kh_Giaodich_The_Stknh";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url,data);
    },
    create_Giaodich_The_Stknh_Phone: (token, data) => {
        const url = "/finance/create_Giaodich_The_Stknh_Phone";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url,data);
    },
};
interface CollectionAPI_SoTaiKhoanNganHang {
    
    create: (token:string,data:any) => Promise<AxiosResponse<any>>;
    put: (token:string,id: number, data:any) => Promise<AxiosResponse<any>>;
    delete: (token:string,id: number) => Promise<AxiosResponse<any>>;
    get: (token:string,id: number) => Promise<AxiosResponse<any>>;

}

const collectionAPI_SoTaiKhoanNganHang: CollectionAPI_SoTaiKhoanNganHang = {
    create: (token,data) => {
        const url = "/sotaikhoannganhang";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.post(url,data);
    },
    put: (token, id, data) => {
        const url = "/sotaikhoannganhang/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url,data);
    },
    delete: (token, id) => {
        const url = "/sotaikhoannganhang/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.delete(url);
    },
    get: (token, id) => {
        const url = "/sotaikhoannganhang/"+id;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
};
interface CollectionAPI_CreditCardCopy {
    
    creditcardcopy: (token:string,data:any) => Promise<AxiosResponse<any>>;

}

//collectionAPI là 1 object chứa tất cả đường dẫn API
const collectionAPI_CreditCardCopy: CollectionAPI_CreditCardCopy = {
    creditcardcopy: (token,data) => {
        const url = "/creditcardcopy";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.put(url,data);
    },
   
    // --------------------------------------MBBANK END
};
interface CollectionAPI_AuthController {
    signout: (email:string) => Promise<AxiosResponse<any>>;
    loginAgent: (data: any) => Promise<AxiosResponse<any>>;

}

//collectionAPI là 1 object chứa tất cả đường dẫn API
const collectionAPI_AuthController: CollectionAPI_AuthController = {
    signout: (email:string) => {
        const url = "/auth/signout/" + email;
        return instance.delete(url);
    },
    loginAgent : (data) => {
        const url = "/auth/loginAgent";
        // console.log('data: ', data);
        return instance.post(url, data);
    },
};
interface CollectionAPI_agent {
    create: (data:any) => Promise<AxiosResponse<any>>;
    // createChild: (data:any, nodeId_param: number) => Promise<AxiosResponse<any>>;
    createChild: (data:any, username: string) => Promise<AxiosResponse<any>>;
    findAllkhachhangByNguonAgentByDateRange: (token: string, startDay: string, endDay: string, userId: number) => Promise<AxiosResponse<any>>;
    findTree: (token:string, path:string) => Promise<AxiosResponse<any>>;

}
//collectionAPI là 1 object chứa tất cả đường dẫn API
const collectionAPI_agent: CollectionAPI_agent = {
    create: (data:string) => {
        const url = "/agent";
        return instance.post(url, data);
    },
    createChild: (data:string, username: string) => {
        const url = "/agent/"+username;
        return instance.post(url, data);
    },
    findAllkhachhangByNguonAgentByDateRange: (token: string, startDay: string, endDay: string, userId: number) => {
        const url = "/khachhang/findAllkhachhangByNguonAgentByDateRange/" + startDay + "/" + endDay +"/" + userId;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
    findTree: (token:string, path:string) => {
        const url = "/agent/findTree/"+path;
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },

};
interface CollectionAPI_truongHop {
    findAll: (token: string) => Promise<AxiosResponse<any>>;

}
//collectionAPI là 1 object chứa tất cả đường dẫn API
const collectionAPI_truongHop: CollectionAPI_truongHop = {
    findAll: (token: string) => {
        const url = "/truonghop";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
};
interface CollectionAPI_deletePermission {
    findAll: (token: string) => Promise<AxiosResponse<any>>;

}
//collectionAPI là 1 object chứa tất cả đường dẫn API
const collectionAPI_deletePermission: CollectionAPI_deletePermission = {
    findAll: (token: string) => {
        const url = "/deletepermission";
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return instance.get(url);
    },
};
export default {
    collectionAPI, 
    collectionAPI_SoTaiKhoanNganHang, 
    collectionAPI_CreditCardCopy,
    collectionAPI_AuthController,
    collectionAPI_agent,
    collectionAPI_save_data,
    collectionAPI_truongHop,
    collectionAPI_deletePermission
};