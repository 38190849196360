import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from './Giaodich.module.scss'
import {
    retrieveObjectFromLocalStorage,
    getFirstAndLastDayOfYearParameter,
    splitMonthYear,
    getFirstAndLastDayOfMonthOfYear,
    addOneDay,
    getFirstAndLastDayOfYear,
    getFirstAndLastDayOfMonth,
    getStartAndEndOfWeek,
    getCurrentDateYYYYMMDD,
    formatThoudsand,
    formatDateString,
    storeObjectInLocalStorage,
    replaceFirstFourLetters,
    formatPhoneNumber,
} from '../../share/sharedFunction';
import collectionAPI from '../../../API/collectionAPI';
import SearchIcon from '@mui/icons-material/Search';
import NotYetSignIn from '../general/NotYetSignIn';
import { update_khachHangId, update_giaoDichId } from "../../../features/generalSlice";
import { useDispatch } from 'react-redux';
function Giaodich() {

    const [stateArrGiaodich, setStateArrGiaodich] = useState<any[]>([]);
    const [stateArrGiaodich_show, setStateArrGiaodich_show] = useState<any[]>([]);
    const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
        email: "",
        fullName: "",
        role: "",
        token:""
    })
    const checkSignin = () => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        setStateObjSignIn(userExist);
    
    }
    const findAllGiaodich = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        const token = userExist.token;
        let start = "";
        let end = "";
        start = getCurrentDateYYYYMMDD();
        end = addOneDay(start);
        let nguonKH = "Tất cả";
        let teamrutdaothongbaotrangthai = "Tất cả";
        let nhucau = "Rút tiền";
        try {
            let response = await collectionAPI.collectionAPI.findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByDESC(token, start, end, nguonKH, teamrutdaothongbaotrangthai, nhucau);
            // console.log('all giao dich: ', response.data.data);
            setStateArrGiaodich(response.data.data)
            setStateArrGiaodich_show(response.data.data)
        } catch (err) {
            //console.log('err:', err);
        }
    }
    const [stateArrNguonkh, setStateArrNguonkh] = useState<any[]>([]);
    const idsToRemove = ["Tất cả", "Rút đáo", "CSKH"];
    const findAllNguonkh = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist !== null){
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI.findAllNguonKH(token);
                const newArray = response.data.filter((item: any) => !idsToRemove.includes(item.id));

                setStateArrNguonkh(newArray);

            } catch (err) {
                //console.log('err:', err);
            }
        }
    }

    const [stateString_timeFrame, setStateString_timeFrame] = useState<string>("Hôm nay");

    const showGiaodich_atBegin = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        //today
        // let start = getCurrentDateYYYYMMDD();
        // let end = addOneDay(start);
        //this month
        const obj = getFirstAndLastDayOfMonth()
        let start = obj.firstDayOfMonth;
        let end = obj.lastDayOfMonth;
        setStateString_startTime(start);
        setStateString_endTime(end);
        setStateString_timeFrame("Tháng này");

        //console.log('stateObjSignIn.token: ', stateObjSignIn.token);
        //console.log('stateString_startTime: ', start);
        //console.log('stateString_endTime: ', end);
        //console.log('stateString_nguonKH: ', stateString_nguonKH);
        //console.log('trangThai: ', stateString_teamrutdaothongbaotrangthai);
        //console.log('stateString_nhucau: ', stateString_nhucau);
        let response = await collectionAPI.collectionAPI.findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByDESC(userExist.token, start, end, stateString_nguonKH, stateString_teamrutdaothongbaotrangthai, stateString_nhucau);
        // console.log('response: ', response.data.data);
        if(response.data.data.length > 0){
            setStateArrGiaodich(response.data.data);
            setStateArrGiaodich_show(response.data.data);
        }
    
    }
    const handleChange_timeFrame = async (event:any) => {
        const timeFrame = event.target.value;
        if(timeFrame === "Chọn thời gian"){
            alert("Bạn vui lòng chọn thời gian cụ thể trong danh sách. Xin cảm ơn");
            return;
        }
        
        //console.log('timeFrame: ', timeFrame);
        setStateString_timeFrame(timeFrame);
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        const listMonthYear = [
            "12-2023",
            "01-2024",
            "02-2024",
            "03-2024",
            "04-2024"
        ]
        const token = userExist.token;

        let start = ""
        let end = ""
        if(timeFrame === "Hôm nay"){
            start = getCurrentDateYYYYMMDD();
            end = addOneDay(start);
        }
        else if (timeFrame === "Tuần này"){
            let obj = getStartAndEndOfWeek();
            start = obj.startOfWeek;
            end = obj.endOfWeek;
        }
        else if (timeFrame === "Tháng này"){
            let obj = getFirstAndLastDayOfMonth();
            start = obj.firstDayOfMonth;
            end = obj.lastDayOfMonth;
        }
        else if (timeFrame === "Năm nay"){
            let obj = getFirstAndLastDayOfYear();
            start = obj.firstDayOfYear;
            end = obj.lastDayOfYear;
        }
        else if (listMonthYear.includes(timeFrame)){
            const {month, year} = splitMonthYear(timeFrame)
            const {firstDay, lastDay} = getFirstAndLastDayOfMonthOfYear(month, year);

            start = firstDay;
            end = lastDay;
        }
        else if(timeFrame === "2024" || timeFrame === "2025"){
            const {firstDay, lastDay} = getFirstAndLastDayOfYearParameter(timeFrame);
            start = firstDay;
            end = lastDay;
        }
        setStateString_startTime(start);
        setStateString_endTime(end);
        getData(start, end, stateString_nguonKH, stateString_teamrutdaothongbaotrangthai, stateString_nhucau)
    
    };
    const [stateString_searchText, setStateString_searchText] = useState<string>('');

    const handleInputChange = (event: any) => {
        setStateString_searchText(event.target.value);
        let searchText = event.target.value;
        //console.log('searchText: ', searchText);
        let searchedContacts = stateArrGiaodich.filter(obj =>
            obj.khachhang.fullNameKH.toLowerCase().includes(searchText.toLowerCase()) ||
            obj.khachhang.phone.some((phoneObj: any) => phoneObj.phone.toLowerCase().includes(searchText.toLowerCase())) ||
            obj.khachhang.cccd.toString().includes(searchText.toLowerCase())
        );
        //console.log('searchedContacts: ', searchedContacts);
        setStateArrGiaodich_show(searchedContacts);
    };

    const [stateString_startTime, setStateString_startTime] = useState<string>("");
    const [stateString_endTime, setStateString_endTime] = useState<string>("");
    const handleChange_startTime = (event:any) => { 
        //console.log('event.target.value: ', event.target.value);
        setStateString_startTime(event.target.value) 
    }; 
    const handleChange_endTime = async (event:any) => { 
        const endDate = event.target.value
        setStateString_endTime(event.target.value);
        setStateString_timeFrame("Chọn thời gian");

        getData(stateString_startTime, endDate, stateString_nguonKH, stateString_teamrutdaothongbaotrangthai, stateString_nhucau)

    }; 

    
    const [stateString_nguonKH, setStateString_nguonKH] = useState<string>('Tất cả');
    const handleChange_nguonKH = async (event: any) => {
        const nguonKH = event.target.value;
        //console.log('value: ', nguonKH);
        setStateString_nguonKH(nguonKH);

        getData(stateString_startTime, stateString_endTime, nguonKH, stateString_teamrutdaothongbaotrangthai, stateString_nhucau)
    }
    const [stateString_nhucau, setStateString_nhucau] = useState("Rút tiền"); // string
    const handleChange_nhucau = (e:any) => {
        const nhucau = e.target.value;
        setStateString_nhucau(nhucau);
        getData(stateString_startTime, stateString_endTime, stateString_nguonKH, stateString_teamrutdaothongbaotrangthai, nhucau)
        
    }
    const [stateString_teamrutdaothongbaotrangthai, setStateString_trangthai] = useState("Tất cả"); // string
    const handleChange_teamrutdaothongbaotrangthai = async (e:any) => {
        const trangThai = e.target.value;
        //console.log('trangThai: ', trangThai);
        
        setStateString_trangthai(trangThai);
        //console.log('stateString_startTime: ', stateString_startTime);
        //console.log('stateString_endTime: ', stateString_endTime);
        //console.log('stateString_nguonKH: ', stateString_nguonKH);
        //console.log('trangThai: ', trangThai);
        //console.log('stateString_nhucau: ', stateString_nhucau);
        getData(stateString_startTime, stateString_endTime, stateString_nguonKH, trangThai, stateString_nhucau)

    }
 
    const [stateString_navigation_myWork_giaodich, setStateString_navigation_myWork_giaodich] = useState("All"); // string
    const handleChange_navigation_myWork_giaodich = (e:any) => {
        const myWork = e.target.value;
        // console.log('myWork: ', myWork);
        setStateString_navigation_myWork_giaodich(myWork);
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        let email = userExist.email;
        // console.log('email: ', email);
        let searchedGiaodich = [];

        if(myWork === "All"){
            searchedGiaodich = stateArrGiaodich;
        }
        else if(myWork === "Tôi tạo"){

            searchedGiaodich = stateArrGiaodich.filter((element) => {
                const emailNVCSKHCreate = element.emailNVCSKHCreate?.toLowerCase() || "";
                const emailNVRutdaoCreate = element.emailNVRutdaoCreate?.toLowerCase() || "";
                
                return emailNVCSKHCreate === email.toLowerCase() || emailNVRutdaoCreate === email.toLowerCase();
            });
        }
        else if(myWork == "Tôi sửa"){
            searchedGiaodich = stateArrGiaodich.filter(element => 
                (
                    element.emailNVCSKHEdit.toLowerCase() === email.toLowerCase() ||
                    element.emailNVRutdaoEdit.toLowerCase() === email.toLowerCase()
                )
            );
        }
        setStateArrGiaodich_show(searchedGiaodich);

    }
    
    const getData = async (start: string, end: string, nguonKH: string, trangThai: string, nhuCau: string) => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        let token = userExist.token;
        let email = userExist.email;
        // console.log('----------------------------------------',);
        // console.log('start: ', start);
        // console.log('end: ', end);
        // console.log('nguonKH: ', nguonKH);
        // console.log('trangThai: ', trangThai);
        // console.log('nhuCau: ', nhuCau);
        try {
            let response = await collectionAPI.collectionAPI.findGiaodichBy_Time_NguonKH_trangthai_nhucau_orderByDESC(token, start, end, nguonKH, trangThai, nhuCau);
            // console.log('response.data.data: ', response.data.data);
            // console.log('response.data: ', response.data);
            // console.log('response.data.data.length: ', response.data.data.length);
            if(response.data.data.length <= 0){
                setStateArrGiaodich([]);
                setStateArrGiaodich_show([]);

                return;
            }
            let arr = response.data.data;
            // console.log('arr: ', arr);
            // let sortedArr = arr.sort((a: any, b: any) => new Date(a.the[0].hanDao).getTime() - new Date(b.the[0].hanDao).getTime());
            // console.log('sortedArr: ', arr);

            let searchedGiaodich = [];

        if(stateString_navigation_myWork_giaodich === "All"){
            searchedGiaodich = arr;
        }
        else if(stateString_navigation_myWork_giaodich === "Tôi tạo"){

            searchedGiaodich = arr.filter((element: any) => {
                const emailNVCSKHCreate = element.emailNVCSKHCreate?.toLowerCase() || "";
                const emailNVRutdaoCreate = element.emailNVRutdaoCreate?.toLowerCase() || "";
                
                return emailNVCSKHCreate === email.toLowerCase() || emailNVRutdaoCreate === email.toLowerCase();
            });
        }
        else if(stateString_navigation_myWork_giaodich == "Tôi sửa"){
            searchedGiaodich = arr.filter((element: any) => 
                (
                    element.emailNVCSKHEdit.toLowerCase() === email.toLowerCase() ||
                    element.emailNVRutdaoEdit.toLowerCase() === email.toLowerCase()
                )
            );
        }

            setStateArrGiaodich(arr);
            setStateArrGiaodich_show(arr);
            // setStateString_navigation_myWork_giaodich("All")

            // setStateArr_khachHangByNguonKH(response.data.data); // lưu lại danh sách group nhân viên đc click vào, và giữ tạm ở đó
            // setStateArr_khachHangByNguonKHShow(response.data.data); // hiện ra màn hình
            
        } catch (err) {
            // console.log('err:', err);
        }
    
    }
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const selectGiaodich = (giaoDichId: number) => {
        
        let objGiaoDich:any = stateArrGiaodich.find((obj:any) => obj.id === giaoDichId);
        //console.log('objGiaoDich: ', objGiaoDich);
        let khachHangId = objGiaoDich.khachhang.id;
        dispatch(update_khachHangId(khachHangId));
        dispatch(update_giaoDichId(giaoDichId));
        storeObjectInLocalStorage("khachhangId", khachHangId, 8);
        storeObjectInLocalStorage("giaoDichId", giaoDichId, 8);
        // return;
        navigate("/finance/khachhang", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />

        // open in new tab: nhưng ko có lưu id giao dich va id khach hang nen nó ko hiện ra đúng giao dịch mình cần
        const newTabUrl = "/finance/khachhang"; // Relative path to your route
        window.open(newTabUrl, "_blank");
    }
    useEffect(() => {
        checkSignin();
        findAllGiaodich();
        findAllNguonkh();
        showGiaodich_atBegin();
    },[]);
    if (stateObjSignIn.token === "") {
        return (
            <NotYetSignIn/>
        );
    } 
    else {
        return (
            <div className={clsx(styles.component_Giaodich)}>
            <h2>DANH SÁCH GIAO DỊCH</h2>
            <div className={clsx(styles.navigation)}>
                <div className={clsx(styles.timeWrapper)}>
                    <select name="from" onChange={handleChange_timeFrame} className={clsx(styles.selectOption)} value={stateString_timeFrame}>
                        <option value="Chọn thời gian">Chọn thời gian...</option>
                        <option value="Hôm nay">Hôm nay</option>
                        <option value="Tuần này">Tuần này</option>
                        <option value="Tháng này">Tháng này</option>
                        <option value="Năm nay">Năm nay</option>
                        <option value="01-2024">01-2024</option>
                        <option value="02-2024">02-2024</option>
                        <option value="03-2024">03-2024</option>
                        <option value="04-2024">04-2024</option>
                        <option value="05-2024">05-2024</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                    </select>
                </div>
                <div className={clsx(styles.dateRange)}>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Bắt đầu</p>
                        <input type="date" 
                            className={clsx(styles.inputDate)} 
                            value={stateString_startTime} 
                            onChange={handleChange_startTime}/>
                    </div>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Kết thúc</p>
                        <input type="date" 
                            className={clsx(styles.inputDate)} 
                            value={stateString_endTime} 
                            onChange={handleChange_endTime}/>
                    </div>
                </div>
                <div className={clsx(styles.nhucau_trangthai)}>
                    {/* <div className={clsx(styles.nhucau)}>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="nhucau" checked={stateString_nhucau === "Rút tiền"} value="Rút tiền" className={clsx(styles.item)} 
                            onChange={handleChange_nhucau}/> <span>Rút tiền</span>
                        </div>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="nhucau" value="Đáo hạn" className={clsx(styles.item)} 
                            onChange={handleChange_nhucau}/> <span>Đáo hạn</span>
                        </div>
                    </div> */}
                    <div className={clsx(styles.nhucau_mywork)}>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="nhucau" checked={stateString_nhucau === "Rút tiền"} value="Rút tiền" className={clsx(styles.item)} 
                            onChange={handleChange_nhucau}/> <span>Rút tiền</span>
                        </div>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="nhucau" value="Đáo hạn" className={clsx(styles.item)} 
                            onChange={handleChange_nhucau}/> <span>Đáo hạn</span>
                        </div>
                        <div className={clsx(styles.box, styles.All)}>
                            <input type="radio" name="myWork_giaodich" checked={stateString_navigation_myWork_giaodich === "All"} value="All" className={clsx(styles.item)} 
                            onChange={handleChange_navigation_myWork_giaodich}/> <span>All</span>
                        </div>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="myWork_giaodich" value="Tôi tạo" className={clsx(styles.item)} 
                            onChange={handleChange_navigation_myWork_giaodich}/> <span>Tôi tạo</span>
                        </div>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="myWork_giaodich" value="Tôi sửa" className={clsx(styles.item)} 
                            onChange={handleChange_navigation_myWork_giaodich}/> <span>Tôi sửa</span>
                        </div>
                    </div>
                    <div className={clsx(styles.trangthai)}>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="trangthai" checked={stateString_teamrutdaothongbaotrangthai === "Tất cả"} value="Tất cả" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Tất cả</span>
                        </div>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="trangthai"  value="Chờ" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Chờ</span>
                        </div>
                        <div className={clsx(styles.box)}>
                            {
                                stateString_nhucau === "Rút tiền" ?
                                    <>
                                        <input type="radio" name="trangthai"  value="Đang Rút" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Đang Rút</span>
                                    </>
                                    :
                                    <>
                                        <input type="radio" name="trangthai"  value="Đang đáo" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Đang đáo</span>
                                    </>
                            }
    
                        </div>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="trangthai" value="Kế toán" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Kế toán</span>
                        </div>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="trangthai" value="Hỏng" className={clsx(styles.item)} onChange={handleChange_teamrutdaothongbaotrangthai}/> <span>Hỏng</span>
                        </div>
                    </div>
                </div>
                <div className={clsx(styles.nguonKHWrapper)}>
                        <label htmlFor="">Nguồn KH</label>
                        <select name="nguonkh" onChange={handleChange_nguonKH} className={clsx(styles.selectOption)}>
                            <option value="Tất cả">Tất cả</option>
                            <option value="Rút đáo">Rút đáo</option>
                            <option value="CSKH">CSKH</option>
                            {
                                stateArrNguonkh.map((obj, index) => {
                                    return ( 
                                        <option key={obj.id} value={obj.id}>{obj.id}</option>
                                    )
                                })
                            }
                        </select>
                </div>
                <div className={clsx(styles.searchWrapper)}>
                    <div className={clsx(styles.searchGroup)}>
                        <SearchIcon className={clsx(styles.iconSearch)}/>
                        <input
                            type="text"
                            placeholder="Tìm tên, sđt, cccd..."
                            value={stateString_searchText}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </div>
            {/* end navigation */}
            {
                stateArrGiaodich_show.length === 0 ?
                <div className={clsx(styles.report)}>

                    <p className={clsx(styles.title)}>Dữ liệu mà bạn tìm ----------------:</p>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Khung thời gian:</p>
                        <p className={clsx(styles.value)}>{stateString_timeFrame}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Nhu cầu:</p>
                        <p className={clsx(styles.value)}>{stateString_nhucau}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Người làm:</p>
                        <p className={clsx(styles.value)}>{stateString_navigation_myWork_giaodich}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Trạng thái:</p>
                        <p className={clsx(styles.value)}>{stateString_teamrutdaothongbaotrangthai}</p>
                    </div>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Nguồn khách hàng:</p>
                        <p className={clsx(styles.value)}>{stateString_nguonKH}</p>
                    </div>
                    <h3 className={clsx(styles.title)}>KHÔNG CÓ DỮ LIỆU THEO CÁC ĐIỀU KIỆN TRÊN</h3>
                </div>
                :

                <div className={clsx(styles.table)}>
                    <div className={clsx(styles.row, styles.heading)}>
                        <p>ID Giao dịch</p>
                        <p>Tạo lúc</p>
                        <p>Tên Khách hàng</p>
                        {/* <p>SĐT</p> */}
                        <p>Người tạo</p>
                        <p>CCCD/CMND</p>
                        <p>Tiền rút đáo</p>
                        <p>Phí rút đáo</p>
                        <p>Trạng thái</p>
                        <p>Tên chi nhánh</p>
                        <p>Tiền chuyển khách</p>
                        <p>Xem</p>
                    </div>
                    <div className={clsx(styles.rowWrapper)}>
                    {
                        
                        stateArrGiaodich_show.map((obj:any, index) => {
                            return ( 
                                <div className={clsx(styles.row)} key={obj.id}>
                                    <p>{obj.id}</p>
                                    <p>{formatDateString(obj.createdAt)}</p>
                                    <p>{obj.khachhang.fullNameKH}</p>
                                    <p>{obj?.emailNVCSKHCreate}</p>
                                    {/* <p>
                                        {obj.khachhang.phone.map((objPhone:any, phoneIndex:any) => (
                                            <span key={phoneIndex}>{replaceFirstFourLetters(formatPhoneNumber(objPhone.phone))}<br/></span>
                                        ))}
                                    </p> */}
                                    <p>{obj.khachhang.cccd}</p>
                                    <p>{formatThoudsand(obj.tienrutdao)}</p>
                                    <p>{obj.phirutdao}</p>
                                    <p>{obj.teamrutdaothongbaotrangthai}</p>
                                    <p>{obj.tenchinhanh}</p>
                                    <p>{obj.tienChuyenKhach}</p>
                                    <p><button onClick={()=>selectGiaodich(obj.id)}>Xem</button></p>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            }
        </div>
        );
    }

}
export default Giaodich