import { makeAutoObservable, toJS  } from "mobx";
import { validateCCCD, validateEmail, validatePassword, validatePhoneNumberBoolean, validateUsername } from "../components/share/share.validation";
import collectionAPI from "../API/collectionAPI";

export class AgentSignUpStore {
  nodeId: number;
  randomNumber: number;
  isShowPass: boolean;
  isShowPassRetype: boolean;
  usernameBoss: string;

  objSignUp: {
    nodeId: number;
    username: string;
    verifiedCode: string;
    name: string;
    password: string;
    passwordRetype: string;
    email: string;
    phone: string;
    city: string;
    district: string;
    ward: string;
    address: string;
    cccd: string;
    memberType: string;
    cccd_mt_mediaId: string;
    cccd_ms_mediaId: string;
  };
  objSignUp_error: {
    name: string;
    username: string;
    password: string;
    passwordRetype: string;
    email: string;
    phone: string;
    city: string;
    district: string;
    ward: string;
    address: string;
    cccd: string;
    cccd_mt_mediaId: string;
    cccd_ms_mediaId: string;
  };
  // data: any = [];

  constructor() {
    this.nodeId = 0;
    this.usernameBoss = '';
    this.randomNumber = 0;
    this.isShowPass = false;
    this.isShowPassRetype = false;
    this.objSignUp = {
      name: "",
      nodeId: 0,
      username: "",
      verifiedCode: "",
      password: "",
      passwordRetype: "",
      email: "",
      phone: "",
      city: "",
      district: "",
      ward: "",
      address: "",
      cccd: "",
      memberType: "Classic",
      cccd_mt_mediaId: "",
      cccd_ms_mediaId: "",
    };
  
    this.objSignUp_error = {
      name: "",
      username: "",
      password: "",
      passwordRetype: "",
      email: "",
      phone: "",
      city: "",
      district: "",
      ward: "",
      address: "",
      cccd: "",
      cccd_mt_mediaId: "",
      cccd_ms_mediaId: "",
    };
 
    makeAutoObservable(this);
  }

  setNodeId(value: number) {
    this.nodeId = parseInt(value+"");
  }
  setUsernameBoss(value: string) {
    this.usernameBoss = value;
  }
  setRandomNumber(value: number) {
    this.randomNumber = value;
  }
  setIsShowPass() {
    this.isShowPass = !this.isShowPass;
  }
  setIsShowPassRetype() {
    this.isShowPassRetype = !this.isShowPassRetype;
  }
  setObjSignUp(key: any, value: any){
    this.objSignUp = {
      ...this.objSignUp, // Spread the original object
      [key]: value // Update the value for the specified key
    };
  }
  setObjSignUp_error(key: any, value: any){
    this.objSignUp_error = {
      ...this.objSignUp_error, // Spread the original object
      [key]: value // Update the value for the specified key
    };
  }

  loadData() {
    // this.data = initialData;
    // console.log('action: ', this.nodeId);
  }
  // getObjSignUp(){
  //   return this.objSignUp;
  // }

  getNodeIdFromUrl(url: string) {
    const parts = url.split('/');
    const lastPart = parts[parts.length - 1];
    this.setNodeId(parseInt(lastPart+""));
  }
  getUsernameFromUrl(url: string) {
    const parts = url.split('/');
    const lastPart = parts[parts.length - 1];
    this.setUsernameBoss(lastPart);
  }
  getRandomNumber() {
    const newRandomNumber = Math.floor(Math.random() * 5) + 1; // random number between 1 and 5
    this.setRandomNumber(newRandomNumber);
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => { // phải dùng arrow function
    // console.log('event.target.value: ', event.target.value);
    // console.log('event.target.name: ', event.target.name);
    let value = event.target.value;
    let name = event.target.name;
    this.setObjSignUp(name, value);
    if(name === "name"){
      const result = value.length < 4 || value.length > 70 || !/[a-zA-Z]/.test(value)? false : true;

      if(!result){
        this.setObjSignUp_error(name,"Tên khách hàng phải có độ dài trong khoảng [4-70]")
      }
      else {
          this.setObjSignUp_error(name,"ok")
        }
    }
    else if(name === "email"){
      const result = validateEmail(value);

      if(!result){
        this.setObjSignUp_error(name,"Email chưa đúng định dạng")
      }
      else {
        this.setObjSignUp_error(name,"ok")
      }
    }
    else if(name === "phone"){
      const result = validatePhoneNumberBoolean(value);

      if(!result){
        this.setObjSignUp_error(name,"Số điện thoại chưa đúng")
      }
      else {
        this.setObjSignUp_error(name,"ok")
      }
    }
    else if(name === "username"){
      const result = validateUsername(value);

      if(!result){
        this.setObjSignUp_error(name,"Username nằm trong khoảng [4-20] ký tự, chỉ chứa số, chữ hoặc _")
      }
      else {
        this.setObjSignUp_error(name,"ok")
      }
    }
    else if(name === "password"){
      if(!validatePassword(value)){
        this.setObjSignUp_error(name,"Password từ [8-30] ký tự, 1 hoa, 1 thường, 1 số, 1 đặc biệt. Ví dụ: Password1@")
      }
      else {
        this.setObjSignUp_error(name,"ok")
      }
    }
    else if(name === "passwordRetype"){
        if(value !== this.objSignUp.password){
          this.setObjSignUp_error(name,"Password nhập lại phải giống password vừa tạo !")
        }
        else {
          this.setObjSignUp_error(name,"ok")
        }
    }
   

    // else if(name === "address"){
    //   if(value === ""){
    //     this.setObjSignUp_error(name,"Chưa nhập địa chỉ")
    //   }
    //   else {
    //     this.setObjSignUp_error(name,"ok")
    //   }
    // }
    // else if(name === "cccd"){
    //   const result = validateCCCD(value);

    //   if(!result){
    //     this.setObjSignUp_error(name,"CCCD chưa đúng")
    //   }
    //   else {
    //     this.setObjSignUp_error(name,"ok")
    //   }
    // }
  }

  async submit() {
    try {

      // validate name
      const result_name = this.objSignUp.name.length < 4 || this.objSignUp.name.length > 70 || !/[a-zA-Z]/.test(this.objSignUp.name)? false : true;

      if(!result_name){
        this.setObjSignUp_error("name","Tên khách hàng phải có độ dài trong khoảng [4-70]");
        return
      }
      else {
        this.setObjSignUp_error("name","ok")
      }

      // validate email
      const result_email = validateEmail(this.objSignUp.email);
      if(!result_email){
        this.setObjSignUp_error("email","Email chưa đúng định dạng")
        return; 
      }
      else {
        this.setObjSignUp_error("email","ok")
      }

      //validate phone
      const result_phone = validatePhoneNumberBoolean(this.objSignUp.phone);
      if(!result_phone){
        this.setObjSignUp_error("phone","Số điện thoại chưa đúng")
        return;
      }
      else {
        this.setObjSignUp_error("phone","ok")
      }
      //validate username
      const result_username = validateUsername(this.objSignUp.username);
      if(!result_username){
        this.setObjSignUp_error("username","Username nằm trong khoảng [4-20] ký tự, chỉ chứa số, chữ hoặc _")
        return;
      }
      else {
        this.setObjSignUp_error("username","ok")
      }
      // validate password
      if(!validatePassword(this.objSignUp.password)){
        this.setObjSignUp_error("password","Password từ [8-30] ký tự, 1 hoa, 1 thường, 1 số, 1 đặc biệt. Ví dụ: Password1@")
      }
      else {
        this.setObjSignUp_error("password","ok")
      }
      // validate password retype
      if(this.objSignUp.password !== this.objSignUp.passwordRetype){
        this.setObjSignUp_error("passwordRetype","Password nhập lại phải giống password vừa tạo !")
        return;
      }
      else {
        this.setObjSignUp_error("passwordRetype","ok")
      }


      // // validate city
      // if(this.objSignUp.city === ""){
      //   this.setObjSignUp_error("city","Chưa nhập thành phố")
      // }
      // else {
      //   this.setObjSignUp_error("city","ok")
      // }
      // // validate district
      // if(this.objSignUp.district === ""){
      //   this.setObjSignUp_error("district","Chưa nhập quận")
      // }
      // else {
      //   this.setObjSignUp_error("district","ok")
      // }
      // // validate ward
      // if(this.objSignUp.ward === ""){
      //   this.setObjSignUp_error("ward","Chưa nhập phường")
      // }
      // else {
      //   this.setObjSignUp_error("ward","ok")
      // }
      // // validate address
      // if(this.objSignUp.address === ""){
      //   this.setObjSignUp_error("address","Chưa nhập số nhà tên đường")
      // }
      // else {
      //   this.setObjSignUp_error("address","ok")
      // }

      // // validate cccd
      // const result_cccd = validateCCCD(this.objSignUp.cccd);
      // if(!result_cccd){
      //   this.setObjSignUp_error("cccd","CCCD chưa đúng")
      //   return;
      // }
      // else {
      //   this.setObjSignUp_error("cccd","ok")
      // }

      // // validate cccd mặt trước
      // if(this.objSignUp.cccd_mt_mediaId === ""){
      //   this.setObjSignUp_error("cccd_mt_mediaId","Chưa kéo thả hình CCCD mặt trước")
      //   return;
      // }
      // else {
      //   this.setObjSignUp_error("cccd_mt_mediaId","ok")
      // }

      // // validate cccd mặt sau
      // if(this.objSignUp.cccd_ms_mediaId === ""){
      //   this.setObjSignUp_error("cccd_ms_mediaId","Chưa kéo thả hình CCCD mặt sau")
      //   return;
      // }
      // else {
      //   this.setObjSignUp_error("cccd_ms_mediaId","ok")
      // }

      // thêm code vào
      this.setObjSignUp("nodeId", this.nodeId);

      const dataToJson = toJS(this.objSignUp)

      // console.log('dataToJson: ', dataToJson);
      let response: any;
      try {
        response = await collectionAPI.collectionAPI_agent.createChild(dataToJson, this.usernameBoss);
      } catch (error:any) {
          alert(response.response.data.message);
          // console.log('response.response.data.message in eror: ', response.response.data.message);
      }
      // console.log('response: ', response);
      // console.log('response.response.data.message: ', response.response?.data?.message);
      if(response.response?.status === 401 || response.response?.status === 500){
        alert(response.response.data.message);
        return false;
      }
      if(response?.status === 201){
        alert("THÀNH CÔNG: Đăng ký hội viên loại Classic thành công. Chúc mừng bạn. Bạn sẽ được điều hướng sang trang đăng nhập sign in để vào hệ thống của bạn");
        return true;
      }

      // const response:any = await collectionAPI.collectionAPI_agent.create(dataToJson);
      // console.log("response: " + JSON.stringify(response, null, 4));
      // if(response && response.data && 'error' in response.data){
      //   alert(response.data.error);
      //   return false;

      // }
      // else if(response.message){
      //   alert(response.message);
      //   return false;

      // }
      // else {
      //   alert("THÀNH CÔNG: Đăng ký hội viên loại Classic thành công. Chúc mừng bạn. Bạn sẽ được điều hướng sang trang đăng nhập sign in để vào hệ thống của bạn");
      //   this.reset_objSignUp();
      //   return true;
      // }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  reset_objSignUp() {
    this.objSignUp = {
      name: "",
      nodeId: this.nodeId,
      username: '',
      verifiedCode: "",
      password: "",
      passwordRetype: "",
      email: "",
      phone: "",
      city: "",
      district: "",
      ward: "",
      address: "",
      cccd: "",
      memberType: "Classic",
      cccd_mt_mediaId: "",
      cccd_ms_mediaId: "",
    };
  }
  // loadData() {
  //   // this.data = initialData;
  // }
}
