// recft_clsx_module_scss
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from './general.module.scss'     //file scss cùng cấp
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import { MenuRoute } from '../../share/route-option';
import { arrEmail_backOffice, arrRole_CSKH, arrRole_Finance, arrRole_KT } from '../../share/arrRole';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../../stores/AppStore';


export const Home = observer(() => {
   const { authStore } = useAppStore();
   const emailSignIn = authStore.employee?.email;
   const roleSignIn = authStore.employee?.role;

   const navigate = useNavigate(); //hook dùng để chuyển trang web

   const selectDepartment = (title: string) => {
      switch (title) {
         case 'HR':
            // if(arrRolesHR.includes(stateObj_signIn.role)){
            //    navigate("/hr", { replace: true }); 
            // }
            // else {
            //    alert(`Chỉ có BGĐ và nhân viên phòng ${title} mới được dùng tính năng này`);
            // }
            alert("Trang này đang được bảo trì nên hiện tại chưa dùng được. Xin cảm ơn.");
            break;
         case 'CSKH':
            if(arrRole_CSKH.includes(roleSignIn!)){
               navigate("/cskh", { replace: true }); 
            }
            else {
               alert(`Chỉ có BGĐ và nhân viên phòng ${title} mới được dùng tính năng này`);
            }
            break;
         case 'FINANCE':
            if(arrRole_Finance.includes(roleSignIn!) || arrEmail_backOffice.includes(emailSignIn!)){
               navigate("/finance", { replace: true }); 
            }
            else {
               alert(`Chỉ có BGĐ và nhân viên phòng ${title} mới được dùng tính năng này`);
            }
            break;
         case 'KẾ TOÁN':
            if(arrRole_KT.includes(roleSignIn!)){
               navigate("/ketoan", { replace: true }); 
            }
            else {
               alert(`Chỉ có BGĐ và nhân viên phòng ${title} mới được dùng tính năng này`);
            }
            break;
         case 'PHẢN ÁNH':
            alert("Trang này đang được xây dựng nên hiện tại chưa dùng được. Xin cảm ơn.");
            break;
         case 'QUẢN LÝ':
            navigate("/quanly", { replace: true }); 
            break;
            case 'HÓA ĐƠN':
      
            navigate("/hoadon/", { replace: true });
            break;
         default:
            break;
      }
   }
   const backToSignIn = () => {
   
      navigate("/signin", { replace: true }); 
      
   
   }

   if (emailSignIn! === "") {
      // User is not signed in, do not mount the component
      return (
         <div className={clsx(styles.component_Home)}>
            <h1>Phần này cần phải đăng nhập mới được phép xem !</h1>
            <h3>Bấm vào đây để quay lại trang sign in<span className={clsx(styles.btnSignIn)} onClick={backToSignIn}>Back to Sign in</span></h3>
         </div>
      )
   }
   else 
   {

      return (
         <>
            <div className={clsx(styles.component_Home)}>
               <img src="./assets/picture/wall2.jpg" className="background" alt="background" />
               
               <div className={clsx(styles.container)}>
                  
                  <h1>HỆ THỐNG QUẢN LÝ PHÒNG BAN</h1>
                  <div className={clsx(styles.departments)}>
                     {  MenuRoute.length > 0 &&
                        MenuRoute.slice(1).map((obj, index) => {
                           return ( 
                              <p className={clsx(styles.box)} key={obj.id} onClick={()=>selectDepartment(obj.title)}>{obj.title}</p>
                           )
                        })
                     }
                  </div>
               </div>
            </div>
         
         </>
      )
   }
})


