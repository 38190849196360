// recft_clsx_module_scss
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from '../general/general.module.scss'     //file scss cùng cấp

import collectionAPI from '../../../API/collectionAPI';
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import { validatePassword } from '../../share/share.validation';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

function Changepassword() {
   const [stateSignInFail, setStateSignInFail] = useState(false);
   
   const [stateFormLogin, setstateFormLogin] = useState({email: "", passwordOld: "", passwordNew: "", passwordRetypeNew:""});
   const navigate = useNavigate(); //hook dùng để chuyển trang web
   
     // kiểm tra đã sign in chưa ------------------------------------------start
   const [stateBool_isSignedIn, setStateBool_isSignedIn] = useState(false); // boolean   
   const [stateObj_signIn, setStateObj_signIn] = useState<Record<string, any>>({
      token: "",
      email: "",
      fullName: "",
      role: ""
   })
   
   const checkAlreadySignIn1 = () => { // kiểm tra xem user đã sign in step 1 chưa
      
      const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
      if(userExist !== null){
         setStateBool_isSignedIn(true);
         setStateObj_signIn(userExist);
      }
      else {
         setStateBool_isSignedIn(false);
      }
      return userExist;
   }

   useEffect(() => {
      checkAlreadySignIn1();
   },[]);
   // kiểm tra đã sign in chưa ------------------------------------------end
   const handleSubmit = async (e:any) => {
      e.preventDefault();

      let objEmailPassword = {
         email : stateFormLogin.email,
         password: stateFormLogin.passwordOld
      }
      try {

         let response1 = await collectionAPI.collectionAPI.checkEmailPasswordExist(objEmailPassword);

         // console.log('response1: ', response1);

         if(response1.data !== true){
            alert("Email hoặc password cũ bị sai, bạn vui lòng kiểm tra lại !");
            return;
         }

         let objEmailPasswordNew = {
            email : stateFormLogin.email,
            passwordNew: stateFormLogin.passwordNew
         }
         if(stateString_validate_passwordNew === "password hợp lệ !" && stateString_validate_passwordRetyNew === "password nhập lại hợp lệ !"){
            const userExist = checkAlreadySignIn1();
            const token = userExist.token;
            // console.log('userExist: ', userExist);
            let response2 = await collectionAPI.collectionAPI.updateEmployeePasswordByEmail(token, objEmailPasswordNew);
            // console.log('response2: ', response2);
            if(response2.data.message === "Password updated successfully" ){
               alert("Password đã được update thành công. Nếu bạn quên password vui lòng liên hệ Mr. Vũ (0982 547 769) để lấy password mới, Xin cảm ơn");
               // ko cần cập nhật lại localStorage của cái loginSuccessEmployee , vì thông tin như cũ, chỉ thay mỗi password, mà password nó nằm trong database, ko nằm trong localstorage
               reset();
               goToHome();
               setStateString_validate_passwordNew("");
               setStateString_validate_passwordRetyNew("");
            }
         }
      
   
      } catch (err) {
         // console.log('err:', err);
      }
   }
   const [stateString_validate_passwordNew, setStateString_validate_passwordNew] = useState(""); // string
   const [stateString_validate_passwordRetyNew, setStateString_validate_passwordRetyNew] = useState(""); // string
   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      
      const value = event.target.value;
      const name = event.target.name;
      if(name === "passwordNew"){
         let isValid = validatePassword(value);
         if(!validatePassword(value)){
            setStateString_validate_passwordNew("Password phải có từ [8-30] ký tự, có ít nhất 1 ký tự viết hoa, 1 ký tự viết thường, 1 số, 1 ký tự đặc biệt. Ví dụ: Password1@")
         }
         else {
            setStateString_validate_passwordNew("password hợp lệ !")
         }
      }
      if(name === "passwordRetypeNew"){
         let isValid = validatePassword(value);
         if(value !== stateFormLogin.passwordNew){
            setStateString_validate_passwordRetyNew("Password nhập lại phải giống password vừa tạo")
         }
         else {
            setStateString_validate_passwordRetyNew("password nhập lại hợp lệ !")
         }
      }

      // console.log('value: ', value);
      // console.log('event.target.name: ', event.target.name);
      setstateFormLogin({
         ...stateFormLogin,
         [event.target.name]: value,
      })
   }
   const reset = () => {
      setstateFormLogin({email: "", passwordOld: "", passwordNew: "", passwordRetypeNew:""})
   }


   const goToHome = () => {
      navigate("/home", { replace: true }); 
   }

   const [stateBool_showPassOld, setStateBool_showPassOld] = useState(false); // boolean
   const [stateBool_showPassNew, setStateBool_showPassNew] = useState(false); // boolean
   const [stateBool_showPassRetypeNew, setStateBool_showPassRetypeNew] = useState(false); // boolean
   const handleTogglePasswordOld = () => {
   
      setStateBool_showPassOld(!stateBool_showPassOld);
   
   }
   const handleTogglePasswordNew = () => {
   
      setStateBool_showPassNew(!stateBool_showPassNew);
      
   }
   
   const handleTogglePasswordRetypeNew = () => {
      
      setStateBool_showPassRetypeNew(!stateBool_showPassRetypeNew);
   
   }
   return (
      <div className={clsx(styles.component_ChangePassword)}>

         <div className={clsx(styles.formLogin)}>
            <form onSubmit = {handleSubmit}>
               {/* <HomeIcon className={clsx(styles.home)} onClick={goToHome}/> */}
               <h2>CHANGE PASSWORD</h2>
               <div className={clsx(styles.row)}>
                  <label>Email: </label>
                  <input type="text" name="email" value={stateFormLogin.email} onChange={handleChange} /><div></div>
                  <span className={clsx(styles.eyeWrapper)}></span>
               </div>
               <div className={clsx(styles.row, styles.report)}>
               </div>
               <div className={clsx(styles.row)}>
                  <label>Old Password: </label>
                  <input type={stateBool_showPassOld ? 'text' : 'password'} name="passwordOld" value={stateFormLogin.passwordOld} onChange={handleChange} /> 
                  <span className={clsx(styles.eyeWrapper)}><RemoveRedEyeIcon className={clsx(styles.eye)} onClick={handleTogglePasswordOld}/></span>
               </div>
               <div className={clsx(styles.row, styles.report)}>
               </div>
               <div className={clsx(styles.row)}>
                  <label>New Password: </label>
                  <input type={stateBool_showPassNew ? 'text' : 'password'} name="passwordNew" value={stateFormLogin.passwordNew} onChange={handleChange} /> 
                  <span className={clsx(styles.eyeWrapper)}><RemoveRedEyeIcon className={clsx(styles.eye)} onClick={handleTogglePasswordNew}/></span>
               </div>
               <div className={clsx(styles.row, styles.report)}>
                  <label></label>
                  {
                     stateString_validate_passwordNew === "password hợp lệ !" ? 
                     <span className={clsx(styles.valid)}>{stateString_validate_passwordNew}</span>
                     :
                     <span className={clsx(styles.inValid)}>{stateString_validate_passwordNew}</span>
                  }
                  <span className={clsx(styles.tail)}></span>
               </div>
               <div className={clsx(styles.row)}>
                  <label>Retype New Password: </label>
                  <input type={stateBool_showPassRetypeNew ? 'text' : 'password'} name="passwordRetypeNew" value={stateFormLogin.passwordRetypeNew} onChange={handleChange} /> 
                  <span className={clsx(styles.eyeWrapper)}><RemoveRedEyeIcon className={clsx(styles.eye)} onClick={handleTogglePasswordRetypeNew}/></span>
               </div>
               <div className={clsx(styles.row, styles.report)}>
                  <label></label>
                  {
                     stateString_validate_passwordRetyNew === "password nhập lại hợp lệ !" ? 
                     <span className={clsx(styles.valid)}>{stateString_validate_passwordRetyNew}</span>
                     :
                     <span className={clsx(styles.inValid)}>{stateString_validate_passwordRetyNew}</span>
                  }
                  <span className={clsx(styles.tail)}></span>
               </div>
               <div className={clsx(styles.btnWrapper)}>
                     
                  <button type="submit" >Submit</button>
                  <button type="reset" onClick={reset}>Reset</button>
               
               </div>
            </form>
         </div>
      </div>
   )
}
export default Changepassword