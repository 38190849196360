import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    stateRedux_Bool_isSignedIn: false,
    stateRedux_Bool_isSignedInAgent: false,
    stateRedux_number_khachHangId: 0,
    stateRedux_number_giaoDichId: 0,
    stateRedux_objSignIn:{},
    stateRedux_objSignInAgent:{},
    stateRedux_view: 0,

};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        isSignIn: (state, action) => {
            state.stateRedux_Bool_isSignedIn = action.payload; 
        },
        setState_objSignin: (state, action) => {
            // console.log('action.payload: ', action.payload);
            state.stateRedux_objSignIn = action.payload; 
        },
        isSignInAgent: (state, action) => {
            state.stateRedux_Bool_isSignedInAgent = action.payload; 
        },
        setState_objSigninAgent: (state, action) => {
            // console.log('action.payload: ', action.payload);
            state.stateRedux_objSignInAgent = action.payload; 
        },
        update_khachHangId: (state, action) => {
            state.stateRedux_number_khachHangId = action.payload; 
            // console.log('khachHangId: ', action.payload);
        },
        update_giaoDichId: (state, action) => {
            state.stateRedux_number_giaoDichId = action.payload; 
            // console.log('giaoDichId: ', action.payload);
        },
        update_view: (state, action) => {
            state.stateRedux_view = action.payload; 
            // console.log('stateRedux_view: ', action.payload);
        }
    },
});

export const {isSignIn, isSignInAgent, setState_objSigninAgent, update_khachHangId ,update_giaoDichId, setState_objSignin, update_view} = generalSlice.actions;

export const selectstateRedux_Bool_isSignedIn = (state) => state.general.stateRedux_Bool_isSignedIn;
export const selectstateRedux_Bool_isSignedInAgent = (state) => state.general.stateRedux_Bool_isSignedInAgent;
export const selectstateRedux_ObjSignin = (state) => state.general.stateRedux_objSignIn;
export const selectstateRedux_ObjSigninAgent = (state) => state.general.stateRedux_objSignInAgent;
export const selectstateRedux_number_khachHangId = (state) => state.general.stateRedux_number_khachHangId;
export const selectstateRedux_number_giaoDichId = (state) => state.general.stateRedux_number_giaoDichId;
export const selectstateRedux_number_view = (state) => state.general.stateRedux_view;

export default generalSlice.reducer;

/*
lưu ý: export default generalSlice.reducer;
nhưng ở file store.js thì lại là import generalReducer from '../features/generalSlice';


cái này ta phải nhập tay generalReducer (generalReducer.reducer: kết hợp giữa chữ general và reducer)
*/


/*
KẾT LUẬN:
-   File này export ra những cái như sau:
        +   todoSlice
        +   hàm addTodo
        +   hàm handleStatus
        +   state selectTodo
*/
