export const arr_vietnam =
    [
        {
            "id": "01",
            "name": "Thành phố Hà Nội",
            "districts": [
                    {
                        "id": "001",
                        "name": "Quận Ba Đình",
                        "Wards": [
                                {
                                    "id": "00001",
                                    "name": "Phường Phúc Xá"
                                },
                                {
                                    "id": "00004",
                                    "name": "Phường Trúc Bạch"
                                },
                                {
                                    "id": "00006",
                                    "name": "Phường Vĩnh Phúc"
                                },
                                {
                                    "id": "00007",
                                    "name": "Phường Cống Vị"
                                },
                                {
                                    "id": "00008",
                                    "name": "Phường Liễu Giai"
                                },
                                {
                                    "id": "00010",
                                    "name": "Phường Nguyễn Trung Trực"
                                },
                                {
                                    "id": "00013",
                                    "name": "Phường Quán Thánh"
                                },
                                {
                                    "id": "00016",
                                    "name": "Phường Ngọc Hà"
                                },
                                {
                                    "id": "00019",
                                    "name": "Phường Điện Biên"
                                },
                                {
                                    "id": "00022",
                                    "name": "Phường Đội Cấn"
                                },
                                {
                                    "id": "00025",
                                    "name": "Phường Ngọc Khánh"
                                },
                                {
                                    "id": "00028",
                                    "name": "Phường Kim Mã"
                                },
                                {
                                    "id": "00031",
                                    "name": "Phường Giảng Võ"
                                },
                                {
                                    "id": "00034",
                                    "name": "Phường Thành Công"
                                }
                        ]
                    },
                    {
                        "id": "002",
                        "name": "Quận Hoàn Kiếm",
                        "Wards": [
                                {
                                    "id": "00037",
                                    "name": "Phường Phúc Tân"
                                },
                                {
                                    "id": "00040",
                                    "name": "Phường Đồng Xuân"
                                },
                                {
                                    "id": "00043",
                                    "name": "Phường Hàng Mã"
                                },
                                {
                                    "id": "00046",
                                    "name": "Phường Hàng Buồm"
                                },
                                {
                                    "id": "00049",
                                    "name": "Phường Hàng Đào"
                                },
                                {
                                    "id": "00052",
                                    "name": "Phường Hàng Bồ"
                                },
                                {
                                    "id": "00055",
                                    "name": "Phường Cửa Đông"
                                },
                                {
                                    "id": "00058",
                                    "name": "Phường Lý Thái Tổ"
                                },
                                {
                                    "id": "00061",
                                    "name": "Phường Hàng Bạc"
                                },
                                {
                                    "id": "00064",
                                    "name": "Phường Hàng Gai"
                                },
                                {
                                    "id": "00067",
                                    "name": "Phường Chương Dương"
                                },
                                {
                                    "id": "00070",
                                    "name": "Phường Hàng Trống"
                                },
                                {
                                    "id": "00073",
                                    "name": "Phường Cửa Nam"
                                },
                                {
                                    "id": "00076",
                                    "name": "Phường Hàng Bông"
                                },
                                {
                                    "id": "00079",
                                    "name": "Phường Tràng Tiền"
                                },
                                {
                                    "id": "00082",
                                    "name": "Phường Trần Hưng Đạo"
                                },
                                {
                                    "id": "00085",
                                    "name": "Phường Phan Chu Trinh"
                                },
                                {
                                    "id": "00088",
                                    "name": "Phường Hàng Bài"
                                }
                        ]
                    },
                    {
                        "id": "003",
                        "name": "Quận Tây Hồ",
                        "Wards": [
                                {
                                    "id": "00091",
                                    "name": "Phường Phú Thượng"
                                },
                                {
                                    "id": "00094",
                                    "name": "Phường Nhật Tân"
                                },
                                {
                                    "id": "00097",
                                    "name": "Phường Tứ Liên"
                                },
                                {
                                    "id": "00100",
                                    "name": "Phường Quảng An"
                                },
                                {
                                    "id": "00103",
                                    "name": "Phường Xuân La"
                                },
                                {
                                    "id": "00106",
                                    "name": "Phường Yên Phụ"
                                },
                                {
                                    "id": "00109",
                                    "name": "Phường Bưởi"
                                },
                                {
                                    "id": "00112",
                                    "name": "Phường Thụy Khuê"
                                }
                        ]
                    },
                    {
                        "id": "004",
                        "name": "Quận Long Biên",
                        "Wards": [
                                {
                                    "id": "00115",
                                    "name": "Phường Thượng Thanh"
                                },
                                {
                                    "id": "00118",
                                    "name": "Phường Ngọc Thụy"
                                },
                                {
                                    "id": "00121",
                                    "name": "Phường Giang Biên"
                                },
                                {
                                    "id": "00124",
                                    "name": "Phường Đức Giang"
                                },
                                {
                                    "id": "00127",
                                    "name": "Phường Việt Hưng"
                                },
                                {
                                    "id": "00130",
                                    "name": "Phường Gia Thụy"
                                },
                                {
                                    "id": "00133",
                                    "name": "Phường Ngọc Lâm"
                                },
                                {
                                    "id": "00136",
                                    "name": "Phường Phúc Lợi"
                                },
                                {
                                    "id": "00139",
                                    "name": "Phường Bồ Đề"
                                },
                                {
                                    "id": "00142",
                                    "name": "Phường Sài Đồng"
                                },
                                {
                                    "id": "00145",
                                    "name": "Phường Long Biên"
                                },
                                {
                                    "id": "00148",
                                    "name": "Phường Thạch Bàn"
                                },
                                {
                                    "id": "00151",
                                    "name": "Phường Phúc Đồng"
                                },
                                {
                                    "id": "00154",
                                    "name": "Phường Cự Khối"
                                }
                        ]
                    },
                    {
                        "id": "005",
                        "name": "Quận Cầu Giấy",
                        "Wards": [
                                {
                                    "id": "00157",
                                    "name": "Phường Nghĩa Đô"
                                },
                                {
                                    "id": "00160",
                                    "name": "Phường Nghĩa Tân"
                                },
                                {
                                    "id": "00163",
                                    "name": "Phường Mai Dịch"
                                },
                                {
                                    "id": "00166",
                                    "name": "Phường Dịch Vọng"
                                },
                                {
                                    "id": "00167",
                                    "name": "Phường Dịch Vọng Hậu"
                                },
                                {
                                    "id": "00169",
                                    "name": "Phường Quan Hoa"
                                },
                                {
                                    "id": "00172",
                                    "name": "Phường Yên Hoà"
                                },
                                {
                                    "id": "00175",
                                    "name": "Phường Trung Hoà"
                                }
                        ]
                    },
                    {
                        "id": "006",
                        "name": "Quận Đống Đa",
                        "Wards": [
                                {
                                    "id": "00178",
                                    "name": "Phường Cát Linh"
                                },
                                {
                                    "id": "00181",
                                    "name": "Phường Văn Miếu"
                                },
                                {
                                    "id": "00184",
                                    "name": "Phường Quốc Tử Giám"
                                },
                                {
                                    "id": "00187",
                                    "name": "Phường Láng Thượng"
                                },
                                {
                                    "id": "00190",
                                    "name": "Phường Ô Chợ Dừa"
                                },
                                {
                                    "id": "00193",
                                    "name": "Phường Văn Chương"
                                },
                                {
                                    "id": "00196",
                                    "name": "Phường Hàng Bột"
                                },
                                {
                                    "id": "00199",
                                    "name": "Phường Láng Hạ"
                                },
                                {
                                    "id": "00202",
                                    "name": "Phường Khâm Thiên"
                                },
                                {
                                    "id": "00205",
                                    "name": "Phường Thổ Quan"
                                },
                                {
                                    "id": "00208",
                                    "name": "Phường Nam Đồng"
                                },
                                {
                                    "id": "00211",
                                    "name": "Phường Trung Phụng"
                                },
                                {
                                    "id": "00214",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "00217",
                                    "name": "Phường Trung Liệt"
                                },
                                {
                                    "id": "00220",
                                    "name": "Phường Phương Liên"
                                },
                                {
                                    "id": "00223",
                                    "name": "Phường Thịnh Quang"
                                },
                                {
                                    "id": "00226",
                                    "name": "Phường Trung Tự"
                                },
                                {
                                    "id": "00229",
                                    "name": "Phường Kim Liên"
                                },
                                {
                                    "id": "00232",
                                    "name": "Phường Phương Mai"
                                },
                                {
                                    "id": "00235",
                                    "name": "Phường Ngã Tư Sở"
                                },
                                {
                                    "id": "00238",
                                    "name": "Phường Khương Thượng"
                                }
                        ]
                    },
                    {
                        "id": "007",
                        "name": "Quận Hai Bà Trưng",
                        "Wards": [
                                {
                                    "id": "00241",
                                    "name": "Phường Nguyễn Du"
                                },
                                {
                                    "id": "00244",
                                    "name": "Phường Bạch Đằng"
                                },
                                {
                                    "id": "00247",
                                    "name": "Phường Phạm Đình Hổ"
                                },
                                {
                                    "id": "00256",
                                    "name": "Phường Lê Đại Hành"
                                },
                                {
                                    "id": "00259",
                                    "name": "Phường Đồng Nhân"
                                },
                                {
                                    "id": "00262",
                                    "name": "Phường Phố Huế"
                                },
                                {
                                    "id": "00265",
                                    "name": "Phường Đống Mác"
                                },
                                {
                                    "id": "00268",
                                    "name": "Phường Thanh Lương"
                                },
                                {
                                    "id": "00271",
                                    "name": "Phường Thanh Nhàn"
                                },
                                {
                                    "id": "00274",
                                    "name": "Phường Cầu Dền"
                                },
                                {
                                    "id": "00277",
                                    "name": "Phường Bách Khoa"
                                },
                                {
                                    "id": "00280",
                                    "name": "Phường Đồng Tâm"
                                },
                                {
                                    "id": "00283",
                                    "name": "Phường Vĩnh Tuy"
                                },
                                {
                                    "id": "00286",
                                    "name": "Phường Bạch Mai"
                                },
                                {
                                    "id": "00289",
                                    "name": "Phường Quỳnh Mai"
                                },
                                {
                                    "id": "00292",
                                    "name": "Phường Quỳnh Lôi"
                                },
                                {
                                    "id": "00295",
                                    "name": "Phường Minh Khai"
                                },
                                {
                                    "id": "00298",
                                    "name": "Phường Trương Định"
                                }
                        ]
                    },
                    {
                        "id": "008",
                        "name": "Quận Hoàng Mai",
                        "Wards": [
                                {
                                    "id": "00301",
                                    "name": "Phường Thanh Trì"
                                },
                                {
                                    "id": "00304",
                                    "name": "Phường Vĩnh Hưng"
                                },
                                {
                                    "id": "00307",
                                    "name": "Phường Định Công"
                                },
                                {
                                    "id": "00310",
                                    "name": "Phường Mai Động"
                                },
                                {
                                    "id": "00313",
                                    "name": "Phường Tương Mai"
                                },
                                {
                                    "id": "00316",
                                    "name": "Phường Đại Kim"
                                },
                                {
                                    "id": "00319",
                                    "name": "Phường Tân Mai"
                                },
                                {
                                    "id": "00322",
                                    "name": "Phường Hoàng Văn Thụ"
                                },
                                {
                                    "id": "00325",
                                    "name": "Phường Giáp Bát"
                                },
                                {
                                    "id": "00328",
                                    "name": "Phường Lĩnh Nam"
                                },
                                {
                                    "id": "00331",
                                    "name": "Phường Thịnh Liệt"
                                },
                                {
                                    "id": "00334",
                                    "name": "Phường Trần Phú"
                                },
                                {
                                    "id": "00337",
                                    "name": "Phường Hoàng Liệt"
                                },
                                {
                                    "id": "00340",
                                    "name": "Phường Yên Sở"
                                }
                        ]
                    },
                    {
                        "id": "009",
                        "name": "Quận Thanh Xuân",
                        "Wards": [
                                {
                                    "id": "00343",
                                    "name": "Phường Nhân Chính"
                                },
                                {
                                    "id": "00346",
                                    "name": "Phường Thượng Đình"
                                },
                                {
                                    "id": "00349",
                                    "name": "Phường Khương Trung"
                                },
                                {
                                    "id": "00352",
                                    "name": "Phường Khương Mai"
                                },
                                {
                                    "id": "00355",
                                    "name": "Phường Thanh Xuân Trung"
                                },
                                {
                                    "id": "00358",
                                    "name": "Phường Phương Liệt"
                                },
                                {
                                    "id": "00361",
                                    "name": "Phường Hạ Đình"
                                },
                                {
                                    "id": "00364",
                                    "name": "Phường Khương Đình"
                                },
                                {
                                    "id": "00367",
                                    "name": "Phường Thanh Xuân Bắc"
                                },
                                {
                                    "id": "00370",
                                    "name": "Phường Thanh Xuân Nam"
                                },
                                {
                                    "id": "00373",
                                    "name": "Phường Kim Giang"
                                }
                        ]
                    },
                    {
                        "id": "016",
                        "name": "Huyện Sóc Sơn",
                        "Wards": [
                                {
                                    "id": "00376",
                                    "name": "Thị trấn Sóc Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00379",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00382",
                                    "name": "Xã Minh Trí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00385",
                                    "name": "Xã Hồng Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00388",
                                    "name": "Xã Nam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00391",
                                    "name": "Xã Trung Giã",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00394",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00397",
                                    "name": "Xã Minh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00400",
                                    "name": "Xã Phù Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00403",
                                    "name": "Xã Bắc Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00406",
                                    "name": "Xã Tân Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00409",
                                    "name": "Xã Quang Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00412",
                                    "name": "Xã Hiền Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00415",
                                    "name": "Xã Tân Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00418",
                                    "name": "Xã Tiên Dược",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00421",
                                    "name": "Xã Việt Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00424",
                                    "name": "Xã Xuân Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00427",
                                    "name": "Xã Mai Đình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00430",
                                    "name": "Xã Đức Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00433",
                                    "name": "Xã Thanh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00436",
                                    "name": "Xã Đông Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00439",
                                    "name": "Xã Kim Lũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00442",
                                    "name": "Xã Phú Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00445",
                                    "name": "Xã Phú Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00448",
                                    "name": "Xã Phù Lỗ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00451",
                                    "name": "Xã Xuân Thu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "017",
                        "name": "Huyện Đông Anh",
                        "Wards": [
                                {
                                    "id": "00454",
                                    "name": "Thị trấn Đông Anh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00457",
                                    "name": "Xã Xuân Nộn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00460",
                                    "name": "Xã Thuỵ Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00463",
                                    "name": "Xã Bắc Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00466",
                                    "name": "Xã Nguyên Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00469",
                                    "name": "Xã Nam Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00472",
                                    "name": "Xã Tiên Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00475",
                                    "name": "Xã Vân Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00478",
                                    "name": "Xã Uy Nỗ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00481",
                                    "name": "Xã Vân Nội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00484",
                                    "name": "Xã Liên Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00487",
                                    "name": "Xã Việt Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00490",
                                    "name": "Xã Kim Nỗ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00493",
                                    "name": "Xã Kim Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00496",
                                    "name": "Xã Dục Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00499",
                                    "name": "Xã Đại Mạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00502",
                                    "name": "Xã Vĩnh Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00505",
                                    "name": "Xã Cổ Loa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00508",
                                    "name": "Xã Hải Bối",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00511",
                                    "name": "Xã Xuân Canh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00514",
                                    "name": "Xã Võng La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00517",
                                    "name": "Xã Tàm Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00520",
                                    "name": "Xã Mai Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00523",
                                    "name": "Xã Đông Hội",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "018",
                        "name": "Huyện Gia Lâm",
                        "Wards": [
                                {
                                    "id": "00526",
                                    "name": "Thị trấn Yên Viên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00529",
                                    "name": "Xã Yên Thường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00532",
                                    "name": "Xã Yên Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00535",
                                    "name": "Xã Ninh Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00538",
                                    "name": "Xã Đình Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00541",
                                    "name": "Xã Dương Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00544",
                                    "name": "Xã Phù Đổng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00547",
                                    "name": "Xã Trung Mầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00550",
                                    "name": "Xã Lệ Chi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00553",
                                    "name": "Xã Cổ Bi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00556",
                                    "name": "Xã Đặng Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00559",
                                    "name": "Xã Phú Thị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00562",
                                    "name": "Xã Kim Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00565",
                                    "name": "Thị trấn Trâu Quỳ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00568",
                                    "name": "Xã Dương Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00571",
                                    "name": "Xã Dương Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00574",
                                    "name": "Xã Đông Dư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00577",
                                    "name": "Xã Đa Tốn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00580",
                                    "name": "Xã Kiêu Kỵ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00583",
                                    "name": "Xã Bát Tràng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00586",
                                    "name": "Xã Kim Lan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00589",
                                    "name": "Xã Văn Đức",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "019",
                        "name": "Quận Nam Từ Liêm",
                        "Wards": [
                                {
                                    "id": "00592",
                                    "name": "Phường Cầu Diễn"
                                },
                                {
                                    "id": "00622",
                                    "name": "Phường Xuân Phương"
                                },
                                {
                                    "id": "00623",
                                    "name": "Phường Phương Canh"
                                },
                                {
                                    "id": "00625",
                                    "name": "Phường Mỹ Đình 1"
                                },
                                {
                                    "id": "00626",
                                    "name": "Phường Mỹ Đình 2"
                                },
                                {
                                    "id": "00628",
                                    "name": "Phường Tây Mỗ"
                                },
                                {
                                    "id": "00631",
                                    "name": "Phường Mễ Trì"
                                },
                                {
                                    "id": "00632",
                                    "name": "Phường Phú Đô"
                                },
                                {
                                    "id": "00634",
                                    "name": "Phường Đại Mỗ"
                                },
                                {
                                    "id": "00637",
                                    "name": "Phường Trung Văn"
                                }
                        ]
                    },
                    {
                        "id": "020",
                        "name": "Huyện Thanh Trì",
                        "Wards": [
                                {
                                    "id": "00640",
                                    "name": "Thị trấn Văn Điển",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00643",
                                    "name": "Xã Tân Triều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00646",
                                    "name": "Xã Thanh Liệt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00649",
                                    "name": "Xã Tả Thanh Oai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00652",
                                    "name": "Xã Hữu Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00655",
                                    "name": "Xã Tam Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00658",
                                    "name": "Xã Tứ Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00661",
                                    "name": "Xã Yên Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00664",
                                    "name": "Xã Vĩnh Quỳnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00667",
                                    "name": "Xã Ngũ Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00670",
                                    "name": "Xã Duyên Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00673",
                                    "name": "Xã Ngọc Hồi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00676",
                                    "name": "Xã Vạn Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00679",
                                    "name": "Xã Đại áng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00682",
                                    "name": "Xã Liên Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00685",
                                    "name": "Xã Đông Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "021",
                        "name": "Quận Bắc Từ Liêm",
                        "Wards": [
                                {
                                    "id": "00595",
                                    "name": "Phường Thượng Cát"
                                },
                                {
                                    "id": "00598",
                                    "name": "Phường Liên Mạc"
                                },
                                {
                                    "id": "00601",
                                    "name": "Phường Đông Ngạc"
                                },
                                {
                                    "id": "00602",
                                    "name": "Phường Đức Thắng"
                                },
                                {
                                    "id": "00604",
                                    "name": "Phường Thụy Phương"
                                },
                                {
                                    "id": "00607",
                                    "name": "Phường Tây Tựu"
                                },
                                {
                                    "id": "00610",
                                    "name": "Phường Xuân Đỉnh"
                                },
                                {
                                    "id": "00611",
                                    "name": "Phường Xuân Tảo"
                                },
                                {
                                    "id": "00613",
                                    "name": "Phường Minh Khai"
                                },
                                {
                                    "id": "00616",
                                    "name": "Phường Cổ Nhuế 1"
                                },
                                {
                                    "id": "00617",
                                    "name": "Phường Cổ Nhuế 2"
                                },
                                {
                                    "id": "00619",
                                    "name": "Phường Phú Diễn"
                                },
                                {
                                    "id": "00620",
                                    "name": "Phường Phúc Diễn"
                                }
                        ]
                    },
                    {
                        "id": "250",
                        "name": "Huyện Mê Linh",
                        "Wards": [
                                {
                                    "id": "08973",
                                    "name": "Thị trấn Chi Đông",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08974",
                                    "name": "Xã Đại Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08977",
                                    "name": "Xã Kim Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08980",
                                    "name": "Xã Thạch Đà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08983",
                                    "name": "Xã Tiến Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08986",
                                    "name": "Xã Tự Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08989",
                                    "name": "Thị trấn Quang Minh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08992",
                                    "name": "Xã Thanh Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08995",
                                    "name": "Xã Tam Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08998",
                                    "name": "Xã Liên Mạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09001",
                                    "name": "Xã Vạn Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09004",
                                    "name": "Xã Chu Phan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09007",
                                    "name": "Xã Tiến Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09010",
                                    "name": "Xã Mê Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09013",
                                    "name": "Xã Văn Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09016",
                                    "name": "Xã Hoàng Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09019",
                                    "name": "Xã Tiền Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09022",
                                    "name": "Xã Tráng Việt",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "268",
                        "name": "Quận Hà Đông",
                        "Wards": [
                                {
                                    "id": "09538",
                                    "name": "Phường Nguyễn Trãi"
                                },
                                {
                                    "id": "09541",
                                    "name": "Phường Mộ Lao"
                                },
                                {
                                    "id": "09542",
                                    "name": "Phường Văn Quán"
                                },
                                {
                                    "id": "09544",
                                    "name": "Phường Vạn Phúc"
                                },
                                {
                                    "id": "09547",
                                    "name": "Phường Yết Kiêu"
                                },
                                {
                                    "id": "09550",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "09551",
                                    "name": "Phường La Khê"
                                },
                                {
                                    "id": "09552",
                                    "name": "Phường Phú La"
                                },
                                {
                                    "id": "09553",
                                    "name": "Phường Phúc La"
                                },
                                {
                                    "id": "09556",
                                    "name": "Phường Hà Cầu"
                                },
                                {
                                    "id": "09562",
                                    "name": "Phường Yên Nghĩa"
                                },
                                {
                                    "id": "09565",
                                    "name": "Phường Kiến Hưng"
                                },
                                {
                                    "id": "09568",
                                    "name": "Phường Phú Lãm"
                                },
                                {
                                    "id": "09571",
                                    "name": "Phường Phú Lương"
                                },
                                {
                                    "id": "09886",
                                    "name": "Phường Dương Nội"
                                },
                                {
                                    "id": "10117",
                                    "name": "Phường Đồng Mai"
                                },
                                {
                                    "id": "10123",
                                    "name": "Phường Biên Giang"
                                }
                        ]
                    },
                    {
                        "id": "269",
                        "name": "Thị xã Sơn Tây",
                        "Wards": [
                                {
                                    "id": "09574",
                                    "name": "Phường Lê Lợi"
                                },
                                {
                                    "id": "09577",
                                    "name": "Phường Phú Thịnh"
                                },
                                {
                                    "id": "09580",
                                    "name": "Phường Ngô Quyền"
                                },
                                {
                                    "id": "09583",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "09586",
                                    "name": "Phường Sơn Lộc"
                                },
                                {
                                    "id": "09589",
                                    "name": "Phường Xuân Khanh"
                                },
                                {
                                    "id": "09592",
                                    "name": "Xã Đường Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09595",
                                    "name": "Phường Viên Sơn"
                                },
                                {
                                    "id": "09598",
                                    "name": "Xã Xuân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09601",
                                    "name": "Phường Trung Hưng"
                                },
                                {
                                    "id": "09604",
                                    "name": "Xã Thanh Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09607",
                                    "name": "Phường Trung Sơn Trầm"
                                },
                                {
                                    "id": "09610",
                                    "name": "Xã Kim Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09613",
                                    "name": "Xã Sơn Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09616",
                                    "name": "Xã Cổ Đông",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "271",
                        "name": "Huyện Ba Vì",
                        "Wards": [
                                {
                                    "id": "09619",
                                    "name": "Thị trấn Tây Đằng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09625",
                                    "name": "Xã Phú Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09628",
                                    "name": "Xã Cổ Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09631",
                                    "name": "Xã Tản Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09634",
                                    "name": "Xã Vạn Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09637",
                                    "name": "Xã Châu Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09640",
                                    "name": "Xã Phong Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09643",
                                    "name": "Xã Phú Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09646",
                                    "name": "Xã Phú Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09649",
                                    "name": "Xã Phú Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09652",
                                    "name": "Xã Thái Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09655",
                                    "name": "Xã Đồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09658",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09661",
                                    "name": "Xã Minh Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09664",
                                    "name": "Xã Vật Lại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09667",
                                    "name": "Xã Chu Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09670",
                                    "name": "Xã Tòng Bạt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09673",
                                    "name": "Xã Cẩm Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09676",
                                    "name": "Xã Sơn Đà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09679",
                                    "name": "Xã Đông Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09682",
                                    "name": "Xã Tiên Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09685",
                                    "name": "Xã Thụy An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09688",
                                    "name": "Xã Cam Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09691",
                                    "name": "Xã Thuần Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09694",
                                    "name": "Xã Tản Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09697",
                                    "name": "Xã Ba Trại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09700",
                                    "name": "Xã Minh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09703",
                                    "name": "Xã Ba Vì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09706",
                                    "name": "Xã Vân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09709",
                                    "name": "Xã Yên Bài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09712",
                                    "name": "Xã Khánh Thượng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "272",
                        "name": "Huyện Phúc Thọ",
                        "Wards": [
                                {
                                    "id": "09715",
                                    "name": "Thị trấn Phúc Thọ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09718",
                                    "name": "Xã Vân Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09721",
                                    "name": "Xã Vân Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09724",
                                    "name": "Xã Vân Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09727",
                                    "name": "Xã Xuân Đình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09733",
                                    "name": "Xã Sen Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09739",
                                    "name": "Xã Võng Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09742",
                                    "name": "Xã Thọ Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09745",
                                    "name": "Xã Long Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09748",
                                    "name": "Xã Thượng Cốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09751",
                                    "name": "Xã Hát Môn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09754",
                                    "name": "Xã Tích Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09757",
                                    "name": "Xã Thanh Đa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09760",
                                    "name": "Xã Trạch Mỹ Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09763",
                                    "name": "Xã Phúc Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09766",
                                    "name": "Xã Ngọc Tảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09769",
                                    "name": "Xã Phụng Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09772",
                                    "name": "Xã Tam Thuấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09775",
                                    "name": "Xã Tam Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09778",
                                    "name": "Xã Hiệp Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09781",
                                    "name": "Xã Liên Hiệp",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "273",
                        "name": "Huyện Đan Phượng",
                        "Wards": [
                                {
                                    "id": "09784",
                                    "name": "Thị trấn Phùng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09787",
                                    "name": "Xã Trung Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09790",
                                    "name": "Xã Thọ An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09793",
                                    "name": "Xã Thọ Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09796",
                                    "name": "Xã Hồng Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09799",
                                    "name": "Xã Liên Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09802",
                                    "name": "Xã Liên Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09805",
                                    "name": "Xã Hạ Mỗ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09808",
                                    "name": "Xã Liên Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09811",
                                    "name": "Xã Phương Đình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09814",
                                    "name": "Xã Thượng Mỗ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09817",
                                    "name": "Xã Tân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09820",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09823",
                                    "name": "Xã Đan Phượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09826",
                                    "name": "Xã Đồng Tháp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09829",
                                    "name": "Xã Song Phượng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "274",
                        "name": "Huyện Hoài Đức",
                        "Wards": [
                                {
                                    "id": "09832",
                                    "name": "Thị trấn Trạm Trôi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09835",
                                    "name": "Xã Đức Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09838",
                                    "name": "Xã Minh Khai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09841",
                                    "name": "Xã Dương Liễu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09844",
                                    "name": "Xã Di Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09847",
                                    "name": "Xã Đức Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09850",
                                    "name": "Xã Cát Quế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09853",
                                    "name": "Xã Kim Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09856",
                                    "name": "Xã Yên Sở",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09859",
                                    "name": "Xã Sơn Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09862",
                                    "name": "Xã Vân Canh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09865",
                                    "name": "Xã Đắc Sở",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09868",
                                    "name": "Xã Lại Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09871",
                                    "name": "Xã Tiền Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09874",
                                    "name": "Xã Song Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09877",
                                    "name": "Xã An Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09880",
                                    "name": "Xã An Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09883",
                                    "name": "Xã Vân Côn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09889",
                                    "name": "Xã La Phù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09892",
                                    "name": "Xã Đông La",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "275",
                        "name": "Huyện Quốc Oai",
                        "Wards": [
                                {
                                    "id": "04939",
                                    "name": "Xã Đông Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09895",
                                    "name": "Thị trấn Quốc Oai",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09898",
                                    "name": "Xã Sài Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09901",
                                    "name": "Xã Phượng Cách",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09904",
                                    "name": "Xã Yên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09907",
                                    "name": "Xã Ngọc Liệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09910",
                                    "name": "Xã Ngọc Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09913",
                                    "name": "Xã Liệp Tuyết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09916",
                                    "name": "Xã Thạch Thán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09919",
                                    "name": "Xã Đồng Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09922",
                                    "name": "Xã Phú Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09925",
                                    "name": "Xã Tuyết Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09928",
                                    "name": "Xã Nghĩa Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09931",
                                    "name": "Xã Cộng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09934",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09937",
                                    "name": "Xã Đại Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09940",
                                    "name": "Xã Phú Mãn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09943",
                                    "name": "Xã Cấn Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09946",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09949",
                                    "name": "Xã Hòa Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09952",
                                    "name": "Xã Đông Yên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "276",
                        "name": "Huyện Thạch Thất",
                        "Wards": [
                                {
                                    "id": "04927",
                                    "name": "Xã Yên Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04930",
                                    "name": "Xã Yên Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04936",
                                    "name": "Xã Tiến Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09955",
                                    "name": "Thị trấn Liên Quan",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09958",
                                    "name": "Xã Đại Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09961",
                                    "name": "Xã Cẩm Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09964",
                                    "name": "Xã Lại Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09967",
                                    "name": "Xã Phú Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09970",
                                    "name": "Xã Hương Ngải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09973",
                                    "name": "Xã Canh Nậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09976",
                                    "name": "Xã Kim Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09979",
                                    "name": "Xã Dị Nậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09982",
                                    "name": "Xã Bình Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09985",
                                    "name": "Xã Chàng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09988",
                                    "name": "Xã Thạch Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09991",
                                    "name": "Xã Cần Kiệm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09994",
                                    "name": "Xã Hữu Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09997",
                                    "name": "Xã Phùng Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10000",
                                    "name": "Xã Tân Xã",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10003",
                                    "name": "Xã Thạch Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10006",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10009",
                                    "name": "Xã Hạ Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10012",
                                    "name": "Xã Đồng Trúc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "277",
                        "name": "Huyện Chương Mỹ",
                        "Wards": [
                                {
                                    "id": "10015",
                                    "name": "Thị trấn Chúc Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10018",
                                    "name": "Thị trấn Xuân Mai",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10021",
                                    "name": "Xã Phụng Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10024",
                                    "name": "Xã Tiên Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10027",
                                    "name": "Xã Đông Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10030",
                                    "name": "Xã Đông Phương Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10033",
                                    "name": "Xã Phú Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10039",
                                    "name": "Xã Trường Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10042",
                                    "name": "Xã Ngọc Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10045",
                                    "name": "Xã Thủy Xuân Tiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10048",
                                    "name": "Xã Thanh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10051",
                                    "name": "Xã Trung Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10054",
                                    "name": "Xã Đại Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10057",
                                    "name": "Xã Thụy Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10060",
                                    "name": "Xã Tốt Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10063",
                                    "name": "Xã Lam Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10066",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10069",
                                    "name": "Xã Nam Phương Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10072",
                                    "name": "Xã Hợp Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10075",
                                    "name": "Xã Hoàng Văn Thụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10078",
                                    "name": "Xã Hoàng Diệu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10081",
                                    "name": "Xã Hữu Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10084",
                                    "name": "Xã Quảng Bị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10087",
                                    "name": "Xã Mỹ Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10090",
                                    "name": "Xã Thượng Vực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10093",
                                    "name": "Xã Hồng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10096",
                                    "name": "Xã Đồng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10099",
                                    "name": "Xã Trần Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10102",
                                    "name": "Xã Văn Võ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10105",
                                    "name": "Xã Đồng Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10108",
                                    "name": "Xã Hòa Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10111",
                                    "name": "Xã Phú Nam An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "278",
                        "name": "Huyện Thanh Oai",
                        "Wards": [
                                {
                                    "id": "10114",
                                    "name": "Thị trấn Kim Bài",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10120",
                                    "name": "Xã Cự Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10126",
                                    "name": "Xã Bích Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10129",
                                    "name": "Xã Mỹ Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10132",
                                    "name": "Xã Cao Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10135",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10138",
                                    "name": "Xã Tam Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10141",
                                    "name": "Xã Thanh Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10144",
                                    "name": "Xã Thanh Thùy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10147",
                                    "name": "Xã Thanh Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10150",
                                    "name": "Xã Thanh Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10153",
                                    "name": "Xã Đỗ Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10156",
                                    "name": "Xã Kim An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10159",
                                    "name": "Xã Kim Thư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10162",
                                    "name": "Xã Phương Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10165",
                                    "name": "Xã Tân Ước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10168",
                                    "name": "Xã Dân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10171",
                                    "name": "Xã Liên Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10174",
                                    "name": "Xã Cao Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10177",
                                    "name": "Xã Xuân Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10180",
                                    "name": "Xã Hồng Dương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "279",
                        "name": "Huyện Thường Tín",
                        "Wards": [
                                {
                                    "id": "10183",
                                    "name": "Thị trấn Thường Tín",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10186",
                                    "name": "Xã Ninh Sở",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10189",
                                    "name": "Xã Nhị Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10192",
                                    "name": "Xã Duyên Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10195",
                                    "name": "Xã Khánh Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10198",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10201",
                                    "name": "Xã Văn Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10204",
                                    "name": "Xã Hiền Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10207",
                                    "name": "Xã Hồng Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10210",
                                    "name": "Xã Vân Tảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10213",
                                    "name": "Xã Liên Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10216",
                                    "name": "Xã Văn Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10219",
                                    "name": "Xã Tự Nhiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10222",
                                    "name": "Xã Tiền Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10225",
                                    "name": "Xã Hà Hồi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10228",
                                    "name": "Xã Thư Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10231",
                                    "name": "Xã Nguyễn Trãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10234",
                                    "name": "Xã Quất Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10237",
                                    "name": "Xã Chương Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10240",
                                    "name": "Xã Tân Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10243",
                                    "name": "Xã Lê Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10246",
                                    "name": "Xã Thắng Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10249",
                                    "name": "Xã Dũng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10252",
                                    "name": "Xã Thống Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10255",
                                    "name": "Xã Nghiêm Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10258",
                                    "name": "Xã Tô Hiệu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10261",
                                    "name": "Xã Văn Tự",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10264",
                                    "name": "Xã Vạn Điểm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10267",
                                    "name": "Xã Minh Cường",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "280",
                        "name": "Huyện Phú Xuyên",
                        "Wards": [
                                {
                                    "id": "10270",
                                    "name": "Thị trấn Phú Minh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10273",
                                    "name": "Thị trấn Phú Xuyên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10276",
                                    "name": "Xã Hồng Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10279",
                                    "name": "Xã Phượng Dực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10282",
                                    "name": "Xã Nam Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10288",
                                    "name": "Xã Tri Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10291",
                                    "name": "Xã Đại Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10294",
                                    "name": "Xã Phú Túc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10297",
                                    "name": "Xã Văn Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10300",
                                    "name": "Xã Hồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10303",
                                    "name": "Xã Hoàng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10306",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10309",
                                    "name": "Xã Nam Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10312",
                                    "name": "Xã Nam Triều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10315",
                                    "name": "Xã Tân Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10318",
                                    "name": "Xã Sơn Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10321",
                                    "name": "Xã Chuyên Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10324",
                                    "name": "Xã Khai Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10327",
                                    "name": "Xã Phúc Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10330",
                                    "name": "Xã Vân Từ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10333",
                                    "name": "Xã Tri Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10336",
                                    "name": "Xã Đại Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10339",
                                    "name": "Xã Phú Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10342",
                                    "name": "Xã Bạch Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10345",
                                    "name": "Xã Quang Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10348",
                                    "name": "Xã Châu Can",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10351",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "281",
                        "name": "Huyện Ứng Hòa",
                        "Wards": [
                                {
                                    "id": "10354",
                                    "name": "Thị trấn Vân Đình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10357",
                                    "name": "Xã Viên An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10360",
                                    "name": "Xã Viên Nội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10363",
                                    "name": "Xã Hoa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10366",
                                    "name": "Xã Quảng Phú Cầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10369",
                                    "name": "Xã Trường Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10372",
                                    "name": "Xã Cao Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10375",
                                    "name": "Xã Liên Bạt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10378",
                                    "name": "Xã Sơn Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10381",
                                    "name": "Xã Đồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10384",
                                    "name": "Xã Phương Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10387",
                                    "name": "Xã Trung Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10390",
                                    "name": "Xã Đồng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10393",
                                    "name": "Xã Tảo Dương Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10396",
                                    "name": "Xã Vạn Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10399",
                                    "name": "Xã Minh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10402",
                                    "name": "Xã Hòa Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10405",
                                    "name": "Xã Hòa Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10408",
                                    "name": "Xã Trầm Lộng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10411",
                                    "name": "Xã Kim Đường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10414",
                                    "name": "Xã Hòa Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10417",
                                    "name": "Xã Hòa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10420",
                                    "name": "Xã Đội Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10423",
                                    "name": "Xã Đại Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10426",
                                    "name": "Xã Đông Lỗ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10429",
                                    "name": "Xã Phù Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10432",
                                    "name": "Xã Đại Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10435",
                                    "name": "Xã Lưu Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10438",
                                    "name": "Xã Hồng Quang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "282",
                        "name": "Huyện Mỹ Đức",
                        "Wards": [
                                {
                                    "id": "10441",
                                    "name": "Thị trấn Đại Nghĩa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10444",
                                    "name": "Xã Đồng Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10447",
                                    "name": "Xã Thượng Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10450",
                                    "name": "Xã Tuy Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10453",
                                    "name": "Xã Phúc Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10456",
                                    "name": "Xã Mỹ Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10459",
                                    "name": "Xã Bột Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10462",
                                    "name": "Xã An Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10465",
                                    "name": "Xã Hồng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10468",
                                    "name": "Xã Lê Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10471",
                                    "name": "Xã Xuy Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10474",
                                    "name": "Xã Phùng Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10477",
                                    "name": "Xã Phù Lưu Tế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10480",
                                    "name": "Xã Đại Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10483",
                                    "name": "Xã Vạn Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10486",
                                    "name": "Xã Đốc Tín",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10489",
                                    "name": "Xã Hương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10492",
                                    "name": "Xã Hùng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10495",
                                    "name": "Xã An Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10498",
                                    "name": "Xã Hợp Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10501",
                                    "name": "Xã Hợp Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10504",
                                    "name": "Xã An Phú",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "02",
            "name": "Tỉnh Hà Giang",
            "districts": [
                    {
                        "id": "024",
                        "name": "Thành phố Hà Giang",
                        "Wards": [
                                {
                                    "id": "00688",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "00691",
                                    "name": "Phường Trần Phú"
                                },
                                {
                                    "id": "00692",
                                    "name": "Phường Ngọc Hà"
                                },
                                {
                                    "id": "00694",
                                    "name": "Phường Nguyễn Trãi"
                                },
                                {
                                    "id": "00697",
                                    "name": "Phường Minh Khai"
                                },
                                {
                                    "id": "00700",
                                    "name": "Xã Ngọc Đường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00946",
                                    "name": "Xã Phương Độ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00949",
                                    "name": "Xã Phương Thiện",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "026",
                        "name": "Huyện Đồng Văn",
                        "Wards": [
                                {
                                    "id": "00712",
                                    "name": "Thị trấn Phó Bảng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00715",
                                    "name": "Xã Lũng Cú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00718",
                                    "name": "Xã Má Lé",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00721",
                                    "name": "Thị trấn Đồng Văn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00724",
                                    "name": "Xã Lũng Táo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00727",
                                    "name": "Xã Phố Là",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00730",
                                    "name": "Xã Thài Phìn Tủng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00733",
                                    "name": "Xã Sủng Là",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00736",
                                    "name": "Xã Xà Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00739",
                                    "name": "Xã Tả Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00742",
                                    "name": "Xã Tả Lủng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00745",
                                    "name": "Xã Phố Cáo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00748",
                                    "name": "Xã Sính Lủng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00751",
                                    "name": "Xã Sảng Tủng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00754",
                                    "name": "Xã Lũng Thầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00757",
                                    "name": "Xã Hố Quáng Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00760",
                                    "name": "Xã Vần Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00763",
                                    "name": "Xã Lũng Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00766",
                                    "name": "Xã Sủng Trái",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "027",
                        "name": "Huyện Mèo Vạc",
                        "Wards": [
                                {
                                    "id": "00769",
                                    "name": "Thị trấn Mèo Vạc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00772",
                                    "name": "Xã Thượng Phùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00775",
                                    "name": "Xã Pải Lủng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00778",
                                    "name": "Xã Xín Cái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00781",
                                    "name": "Xã Pả Vi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00784",
                                    "name": "Xã Giàng Chu Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00787",
                                    "name": "Xã Sủng Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00790",
                                    "name": "Xã Sủng Máng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00793",
                                    "name": "Xã Sơn Vĩ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00796",
                                    "name": "Xã Tả Lủng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00799",
                                    "name": "Xã Cán Chu Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00802",
                                    "name": "Xã Lũng Pù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00805",
                                    "name": "Xã Lũng Chinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00808",
                                    "name": "Xã Tát Ngà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00811",
                                    "name": "Xã Nậm Ban",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00814",
                                    "name": "Xã Khâu Vai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00815",
                                    "name": "Xã Niêm Tòng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00817",
                                    "name": "Xã Niêm Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "028",
                        "name": "Huyện Yên Minh",
                        "Wards": [
                                {
                                    "id": "00820",
                                    "name": "Thị trấn Yên Minh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00823",
                                    "name": "Xã Thắng Mố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00826",
                                    "name": "Xã Phú Lũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00829",
                                    "name": "Xã Sủng Tráng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00832",
                                    "name": "Xã Bạch Đích",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00835",
                                    "name": "Xã Na Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00838",
                                    "name": "Xã Sủng Thài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00841",
                                    "name": "Xã Hữu Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00844",
                                    "name": "Xã Lao Và Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00847",
                                    "name": "Xã Mậu Duệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00850",
                                    "name": "Xã Đông Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00853",
                                    "name": "Xã Mậu Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00856",
                                    "name": "Xã Ngam La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00859",
                                    "name": "Xã Ngọc Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00862",
                                    "name": "Xã Đường Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00865",
                                    "name": "Xã Lũng Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00868",
                                    "name": "Xã Du Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00871",
                                    "name": "Xã Du Già",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "029",
                        "name": "Huyện Quản Bạ",
                        "Wards": [
                                {
                                    "id": "00874",
                                    "name": "Thị trấn Tam Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00877",
                                    "name": "Xã Bát Đại Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00880",
                                    "name": "Xã Nghĩa Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00883",
                                    "name": "Xã Cán Tỷ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00886",
                                    "name": "Xã Cao Mã Pờ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00889",
                                    "name": "Xã Thanh Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00892",
                                    "name": "Xã Tùng Vài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00895",
                                    "name": "Xã Đông Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00898",
                                    "name": "Xã Quản Bạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00901",
                                    "name": "Xã Lùng Tám",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00904",
                                    "name": "Xã Quyết Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00907",
                                    "name": "Xã Tả Ván",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00910",
                                    "name": "Xã Thái An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "030",
                        "name": "Huyện Vị Xuyên",
                        "Wards": [
                                {
                                    "id": "00703",
                                    "name": "Xã Kim Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00706",
                                    "name": "Xã Phú Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00709",
                                    "name": "Xã Kim Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00913",
                                    "name": "Thị trấn Vị Xuyên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00916",
                                    "name": "Thị trấn Nông Trường Việt Lâm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00919",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00922",
                                    "name": "Xã Thuận Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00925",
                                    "name": "Xã Tùng Bá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00928",
                                    "name": "Xã Thanh Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00931",
                                    "name": "Xã Thanh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00934",
                                    "name": "Xã Phong Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00937",
                                    "name": "Xã Xín Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00940",
                                    "name": "Xã Phương Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00943",
                                    "name": "Xã Lao Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00952",
                                    "name": "Xã Cao Bồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00955",
                                    "name": "Xã Đạo Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00958",
                                    "name": "Xã Thượng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00961",
                                    "name": "Xã Linh Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00964",
                                    "name": "Xã Quảng Ngần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00967",
                                    "name": "Xã Việt Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00970",
                                    "name": "Xã Ngọc Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00973",
                                    "name": "Xã Ngọc Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00976",
                                    "name": "Xã Bạch Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00979",
                                    "name": "Xã Trung Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "031",
                        "name": "Huyện Bắc Mê",
                        "Wards": [
                                {
                                    "id": "00982",
                                    "name": "Xã Minh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00985",
                                    "name": "Xã Giáp Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00988",
                                    "name": "Xã Yên Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00991",
                                    "name": "Thị trấn Yên Phú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "00994",
                                    "name": "Xã Minh Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "00997",
                                    "name": "Xã Yên Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01000",
                                    "name": "Xã Lạc Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01003",
                                    "name": "Xã Phú Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01006",
                                    "name": "Xã Yên Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01009",
                                    "name": "Xã Thượng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01012",
                                    "name": "Xã Đường Âm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01015",
                                    "name": "Xã Đường Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01018",
                                    "name": "Xã Phiêng Luông",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "032",
                        "name": "Huyện Hoàng Su Phì",
                        "Wards": [
                                {
                                    "id": "01021",
                                    "name": "Thị trấn Vinh Quang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01024",
                                    "name": "Xã Bản Máy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01027",
                                    "name": "Xã Thàng Tín",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01030",
                                    "name": "Xã Thèn Chu Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01033",
                                    "name": "Xã Pố Lồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01036",
                                    "name": "Xã Bản Phùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01039",
                                    "name": "Xã Túng Sán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01042",
                                    "name": "Xã Chiến Phố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01045",
                                    "name": "Xã Đản Ván",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01048",
                                    "name": "Xã Tụ Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01051",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01054",
                                    "name": "Xã Nàng Đôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01057",
                                    "name": "Xã Pờ Ly Ngài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01060",
                                    "name": "Xã Sán Xả Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01063",
                                    "name": "Xã Bản Luốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01066",
                                    "name": "Xã Ngàm Đăng Vài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01069",
                                    "name": "Xã Bản Nhùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01072",
                                    "name": "Xã Tả Sử Choóng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01075",
                                    "name": "Xã Nậm Dịch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01081",
                                    "name": "Xã Hồ Thầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01084",
                                    "name": "Xã Nam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01087",
                                    "name": "Xã Nậm Tỵ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01090",
                                    "name": "Xã Thông Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01093",
                                    "name": "Xã Nậm Khòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "033",
                        "name": "Huyện Xín Mần",
                        "Wards": [
                                {
                                    "id": "01096",
                                    "name": "Thị trấn Cốc Pài",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01099",
                                    "name": "Xã Nàn Xỉn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01102",
                                    "name": "Xã Bản Díu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01105",
                                    "name": "Xã Chí Cà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01108",
                                    "name": "Xã Xín Mần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01114",
                                    "name": "Xã Thèn Phàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01117",
                                    "name": "Xã Trung Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01120",
                                    "name": "Xã Pà Vầy Sủ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01123",
                                    "name": "Xã Cốc Rế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01126",
                                    "name": "Xã Thu Tà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01129",
                                    "name": "Xã Nàn Ma",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01132",
                                    "name": "Xã Tả Nhìu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01135",
                                    "name": "Xã Bản Ngò",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01138",
                                    "name": "Xã Chế Là",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01141",
                                    "name": "Xã Nấm Dẩn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01144",
                                    "name": "Xã Quảng Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01147",
                                    "name": "Xã Nà Chì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01150",
                                    "name": "Xã Khuôn Lùng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "034",
                        "name": "Huyện Bắc Quang",
                        "Wards": [
                                {
                                    "id": "01153",
                                    "name": "Thị trấn Việt Quang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01156",
                                    "name": "Thị trấn Vĩnh Tuy",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01159",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01162",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01165",
                                    "name": "Xã Đồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01168",
                                    "name": "Xã Đồng Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01171",
                                    "name": "Xã Tân Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01174",
                                    "name": "Xã Thượng Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01177",
                                    "name": "Xã Hữu Sản",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01180",
                                    "name": "Xã Kim Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01183",
                                    "name": "Xã Việt Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01186",
                                    "name": "Xã Bằng Hành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01189",
                                    "name": "Xã Quang Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01192",
                                    "name": "Xã Liên Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01195",
                                    "name": "Xã Vô Điếm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01198",
                                    "name": "Xã Việt Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01201",
                                    "name": "Xã Hùng An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01204",
                                    "name": "Xã Đức Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01207",
                                    "name": "Xã Tiên Kiều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01210",
                                    "name": "Xã Vĩnh Hảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01213",
                                    "name": "Xã Vĩnh Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01216",
                                    "name": "Xã Đồng Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01219",
                                    "name": "Xã Đông Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "035",
                        "name": "Huyện Quang Bình",
                        "Wards": [
                                {
                                    "id": "01222",
                                    "name": "Xã Xuân Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01225",
                                    "name": "Xã Tiên Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01228",
                                    "name": "Xã Tân Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01231",
                                    "name": "Xã Bản Rịa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01234",
                                    "name": "Xã Yên Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01237",
                                    "name": "Thị trấn Yên Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01240",
                                    "name": "Xã Tân Trịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01243",
                                    "name": "Xã Tân Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01246",
                                    "name": "Xã Bằng Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01249",
                                    "name": "Xã Yên Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01252",
                                    "name": "Xã Hương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01255",
                                    "name": "Xã Xuân Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01258",
                                    "name": "Xã Nà Khương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01261",
                                    "name": "Xã Tiên Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01264",
                                    "name": "Xã Vĩ Thượng",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "04",
            "name": "Tỉnh Cao Bằng",
            "districts": [
                    {
                        "id": "040",
                        "name": "Thành phố Cao Bằng",
                        "Wards": [
                                {
                                    "id": "01267",
                                    "name": "Phường Sông Hiến"
                                },
                                {
                                    "id": "01270",
                                    "name": "Phường Sông Bằng"
                                },
                                {
                                    "id": "01273",
                                    "name": "Phường Hợp Giang"
                                },
                                {
                                    "id": "01276",
                                    "name": "Phường Tân Giang"
                                },
                                {
                                    "id": "01279",
                                    "name": "Phường Ngọc Xuân"
                                },
                                {
                                    "id": "01282",
                                    "name": "Phường Đề Thám"
                                },
                                {
                                    "id": "01285",
                                    "name": "Phường Hoà Chung"
                                },
                                {
                                    "id": "01288",
                                    "name": "Phường Duyệt Trung"
                                },
                                {
                                    "id": "01693",
                                    "name": "Xã Vĩnh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01705",
                                    "name": "Xã Hưng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01720",
                                    "name": "Xã Chu Trinh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "042",
                        "name": "Huyện Bảo Lâm",
                        "Wards": [
                                {
                                    "id": "01290",
                                    "name": "Thị trấn Pác Miầu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01291",
                                    "name": "Xã Đức Hạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01294",
                                    "name": "Xã Lý Bôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01296",
                                    "name": "Xã Nam Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01297",
                                    "name": "Xã Nam Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01300",
                                    "name": "Xã Vĩnh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01303",
                                    "name": "Xã Quảng Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01304",
                                    "name": "Xã Thạch Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01309",
                                    "name": "Xã Vĩnh Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01312",
                                    "name": "Xã Mông Ân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01315",
                                    "name": "Xã Thái Học",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01316",
                                    "name": "Xã Thái Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01318",
                                    "name": "Xã Yên Thổ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "043",
                        "name": "Huyện Bảo Lạc",
                        "Wards": [
                                {
                                    "id": "01321",
                                    "name": "Thị trấn Bảo Lạc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01324",
                                    "name": "Xã Cốc Pàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01327",
                                    "name": "Xã Thượng Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01330",
                                    "name": "Xã Cô Ba",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01333",
                                    "name": "Xã Bảo Toàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01336",
                                    "name": "Xã Khánh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01339",
                                    "name": "Xã Xuân Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01342",
                                    "name": "Xã Hồng Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01343",
                                    "name": "Xã Kim Cúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01345",
                                    "name": "Xã Phan Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01348",
                                    "name": "Xã Hồng An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01351",
                                    "name": "Xã Hưng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01352",
                                    "name": "Xã Hưng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01354",
                                    "name": "Xã Huy Giáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01357",
                                    "name": "Xã Đình Phùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01359",
                                    "name": "Xã Sơn Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01360",
                                    "name": "Xã Sơn Lộ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "045",
                        "name": "Huyện Hà Quảng",
                        "Wards": [
                                {
                                    "id": "01363",
                                    "name": "Thị trấn Thông Nông",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01366",
                                    "name": "Xã Cần Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01367",
                                    "name": "Xã Cần Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01372",
                                    "name": "Xã Lương Thông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01375",
                                    "name": "Xã Đa Thông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01378",
                                    "name": "Xã Ngọc Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01381",
                                    "name": "Xã Yên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01384",
                                    "name": "Xã Lương Can",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01387",
                                    "name": "Xã Thanh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01392",
                                    "name": "Thị trấn Xuân Hòa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01393",
                                    "name": "Xã Lũng Nặm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01399",
                                    "name": "Xã Trường Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01402",
                                    "name": "Xã Cải Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01411",
                                    "name": "Xã Nội Thôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01414",
                                    "name": "Xã Tổng Cọt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01417",
                                    "name": "Xã Sóc Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01420",
                                    "name": "Xã Thượng Thôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01429",
                                    "name": "Xã Hồng Sỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01432",
                                    "name": "Xã Quý Quân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01435",
                                    "name": "Xã Mã Ba",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01438",
                                    "name": "Xã Ngọc Đào",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "047",
                        "name": "Huyện Trùng Khánh",
                        "Wards": [
                                {
                                    "id": "01447",
                                    "name": "Thị trấn Trà Lĩnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01453",
                                    "name": "Xã Tri Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01456",
                                    "name": "Xã Quang Hán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01462",
                                    "name": "Xã Xuân Nội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01465",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01468",
                                    "name": "Xã Quang Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01471",
                                    "name": "Xã Cao Chương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01477",
                                    "name": "Thị trấn Trùng Khánh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01480",
                                    "name": "Xã Ngọc Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01481",
                                    "name": "Xã Ngọc Côn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01483",
                                    "name": "Xã Phong Nậm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01489",
                                    "name": "Xã Đình Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01495",
                                    "name": "Xã Đàm Thuỷ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01498",
                                    "name": "Xã Khâm Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01501",
                                    "name": "Xã Chí Viễn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01504",
                                    "name": "Xã Lăng Hiếu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01507",
                                    "name": "Xã Phong Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01516",
                                    "name": "Xã Trung Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01519",
                                    "name": "Xã Cao Thăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01522",
                                    "name": "Xã Đức Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01525",
                                    "name": "Xã Đoài Dương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "048",
                        "name": "Huyện Hạ Lang",
                        "Wards": [
                                {
                                    "id": "01534",
                                    "name": "Xã Minh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01537",
                                    "name": "Xã Lý Quốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01540",
                                    "name": "Xã Thắng Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01543",
                                    "name": "Xã Đồng Loan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01546",
                                    "name": "Xã Đức Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01549",
                                    "name": "Xã Kim Loan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01552",
                                    "name": "Xã Quang Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01555",
                                    "name": "Xã An Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01558",
                                    "name": "Thị trấn Thanh Nhật",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01561",
                                    "name": "Xã Vinh Quý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01564",
                                    "name": "Xã Thống Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01567",
                                    "name": "Xã Cô Ngân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01573",
                                    "name": "Xã Thị Hoa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "049",
                        "name": "Huyện Quảng Hòa",
                        "Wards": [
                                {
                                    "id": "01474",
                                    "name": "Xã Quốc Toản",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01576",
                                    "name": "Thị trấn Quảng Uyên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01579",
                                    "name": "Xã Phi Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01582",
                                    "name": "Xã Quảng Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01594",
                                    "name": "Xã Độc Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01597",
                                    "name": "Xã Cai Bộ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01603",
                                    "name": "Xã Phúc Sen",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01606",
                                    "name": "Xã Chí Thảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01609",
                                    "name": "Xã Tự Do",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01615",
                                    "name": "Xã Hồng Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01618",
                                    "name": "Xã Ngọc Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01624",
                                    "name": "Xã Hạnh Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01627",
                                    "name": "Thị trấn Tà Lùng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01630",
                                    "name": "Xã Bế Văn Đàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01636",
                                    "name": "Xã Cách Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01639",
                                    "name": "Xã Đại Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01645",
                                    "name": "Xã Tiên Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01648",
                                    "name": "Thị trấn Hoà Thuận",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01651",
                                    "name": "Xã Mỹ Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "051",
                        "name": "Huyện Hoà An",
                        "Wards": [
                                {
                                    "id": "01654",
                                    "name": "Thị trấn Nước Hai",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01657",
                                    "name": "Xã Dân Chủ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01660",
                                    "name": "Xã Nam Tuấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01666",
                                    "name": "Xã Đại Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01669",
                                    "name": "Xã Đức Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01672",
                                    "name": "Xã Ngũ Lão",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01675",
                                    "name": "Xã Trương Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01687",
                                    "name": "Xã Hồng Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01696",
                                    "name": "Xã Hoàng Tung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01699",
                                    "name": "Xã Nguyễn Huệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01702",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01708",
                                    "name": "Xã Bạch Đằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01711",
                                    "name": "Xã Bình Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01714",
                                    "name": "Xã Lê Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01723",
                                    "name": "Xã Hồng Nam",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "052",
                        "name": "Huyện Nguyên Bình",
                        "Wards": [
                                {
                                    "id": "01726",
                                    "name": "Thị trấn Nguyên Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01729",
                                    "name": "Thị trấn Tĩnh Túc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01732",
                                    "name": "Xã Yên Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01735",
                                    "name": "Xã Triệu Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01738",
                                    "name": "Xã Ca Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01744",
                                    "name": "Xã Vũ Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01747",
                                    "name": "Xã Minh Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01750",
                                    "name": "Xã Thể Dục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01756",
                                    "name": "Xã Mai Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01762",
                                    "name": "Xã Vũ Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01765",
                                    "name": "Xã Hoa Thám",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01768",
                                    "name": "Xã Phan Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01771",
                                    "name": "Xã Quang Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01774",
                                    "name": "Xã Tam Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01777",
                                    "name": "Xã Thành Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01780",
                                    "name": "Xã Thịnh Vượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01783",
                                    "name": "Xã Hưng Đạo",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "053",
                        "name": "Huyện Thạch An",
                        "Wards": [
                                {
                                    "id": "01786",
                                    "name": "Thị trấn Đông Khê",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01789",
                                    "name": "Xã Canh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01792",
                                    "name": "Xã Kim Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01795",
                                    "name": "Xã Minh Khai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01801",
                                    "name": "Xã Đức Thông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01804",
                                    "name": "Xã Thái Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01807",
                                    "name": "Xã Vân Trình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01810",
                                    "name": "Xã Thụy Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01813",
                                    "name": "Xã Quang Trọng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01816",
                                    "name": "Xã Trọng Con",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01819",
                                    "name": "Xã Lê Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01822",
                                    "name": "Xã Đức Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01828",
                                    "name": "Xã Lê Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01831",
                                    "name": "Xã Đức Xuân",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "06",
            "name": "Tỉnh Bắc Kạn",
            "districts": [
                    {
                        "id": "058",
                        "name": "Thành Phố Bắc Kạn",
                        "Wards": [
                                {
                                    "id": "01834",
                                    "name": "Phường Nguyễn Thị Minh Khai"
                                },
                                {
                                    "id": "01837",
                                    "name": "Phường Sông Cầu"
                                },
                                {
                                    "id": "01840",
                                    "name": "Phường Đức Xuân"
                                },
                                {
                                    "id": "01843",
                                    "name": "Phường Phùng Chí Kiên"
                                },
                                {
                                    "id": "01846",
                                    "name": "Phường Huyền Tụng"
                                },
                                {
                                    "id": "01849",
                                    "name": "Xã Dương Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01852",
                                    "name": "Xã Nông Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01855",
                                    "name": "Phường Xuất Hóa"
                                }
                        ]
                    },
                    {
                        "id": "060",
                        "name": "Huyện Pác Nặm",
                        "Wards": [
                                {
                                    "id": "01858",
                                    "name": "Xã Bằng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01861",
                                    "name": "Xã Nhạn Môn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01864",
                                    "name": "Xã Bộc Bố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01867",
                                    "name": "Xã Công Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01870",
                                    "name": "Xã Giáo Hiệu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01873",
                                    "name": "Xã Xuân La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01876",
                                    "name": "Xã An Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01879",
                                    "name": "Xã Cổ Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01882",
                                    "name": "Xã Nghiên Loan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01885",
                                    "name": "Xã Cao Tân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "061",
                        "name": "Huyện Ba Bể",
                        "Wards": [
                                {
                                    "id": "01888",
                                    "name": "Thị trấn Chợ Rã",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01891",
                                    "name": "Xã Bành Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01894",
                                    "name": "Xã Phúc Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01897",
                                    "name": "Xã Hà Hiệu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01900",
                                    "name": "Xã Cao Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01906",
                                    "name": "Xã Khang Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01909",
                                    "name": "Xã Nam Mẫu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01912",
                                    "name": "Xã Thượng Giáo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01915",
                                    "name": "Xã Địa Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01918",
                                    "name": "Xã Yến Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01921",
                                    "name": "Xã Chu Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01924",
                                    "name": "Xã Quảng Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01927",
                                    "name": "Xã Mỹ Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01930",
                                    "name": "Xã Hoàng Trĩ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01933",
                                    "name": "Xã Đồng Phúc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "062",
                        "name": "Huyện Ngân Sơn",
                        "Wards": [
                                {
                                    "id": "01936",
                                    "name": "Thị trấn Nà Phặc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01939",
                                    "name": "Xã Thượng Ân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01942",
                                    "name": "Xã Bằng Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01945",
                                    "name": "Xã Cốc Đán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01948",
                                    "name": "Xã Trung Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01951",
                                    "name": "Xã Đức Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01954",
                                    "name": "Xã Vân Tùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01957",
                                    "name": "Xã Thượng Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01960",
                                    "name": "Xã Hiệp Lực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01963",
                                    "name": "Xã Thuần Mang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "063",
                        "name": "Huyện Bạch Thông",
                        "Wards": [
                                {
                                    "id": "01969",
                                    "name": "Thị trấn Phủ Thông",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "01975",
                                    "name": "Xã Vi Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01978",
                                    "name": "Xã Sĩ Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01981",
                                    "name": "Xã Vũ Muộn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01984",
                                    "name": "Xã Đôn Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01990",
                                    "name": "Xã Lục Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01993",
                                    "name": "Xã Tân Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "01999",
                                    "name": "Xã Nguyên Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02002",
                                    "name": "Xã Cao Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02005",
                                    "name": "Xã Quân Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02008",
                                    "name": "Xã Cẩm Giàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02011",
                                    "name": "Xã Mỹ Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02014",
                                    "name": "Xã Dương Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02017",
                                    "name": "Xã Quang Thuận",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "064",
                        "name": "Huyện Chợ Đồn",
                        "Wards": [
                                {
                                    "id": "02020",
                                    "name": "Thị trấn Bằng Lũng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02023",
                                    "name": "Xã Xuân Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02026",
                                    "name": "Xã Nam Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02029",
                                    "name": "Xã Đồng Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02032",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02035",
                                    "name": "Xã Bản Thi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02038",
                                    "name": "Xã Quảng Bạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02041",
                                    "name": "Xã Bằng Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02044",
                                    "name": "Xã Yên Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02047",
                                    "name": "Xã Yên Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02050",
                                    "name": "Xã Phương Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02053",
                                    "name": "Xã Ngọc Phái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02059",
                                    "name": "Xã Đồng Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02062",
                                    "name": "Xã Lương Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02065",
                                    "name": "Xã Bằng Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02068",
                                    "name": "Xã Đại Sảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02071",
                                    "name": "Xã Nghĩa Tá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02077",
                                    "name": "Xã Yên Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02080",
                                    "name": "Xã Bình Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02083",
                                    "name": "Xã Yên Phong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "065",
                        "name": "Huyện Chợ Mới",
                        "Wards": [
                                {
                                    "id": "02086",
                                    "name": "Thị trấn Đồng Tâm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02089",
                                    "name": "Xã Tân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02092",
                                    "name": "Xã Thanh Vận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02095",
                                    "name": "Xã Mai Lạp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02098",
                                    "name": "Xã Hoà Mục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02101",
                                    "name": "Xã Thanh Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02104",
                                    "name": "Xã Cao Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02107",
                                    "name": "Xã Nông Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02110",
                                    "name": "Xã Yên Cư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02113",
                                    "name": "Xã Thanh Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02116",
                                    "name": "Xã Yên Hân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02122",
                                    "name": "Xã Như Cố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02125",
                                    "name": "Xã Bình Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02131",
                                    "name": "Xã Quảng Chu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "066",
                        "name": "Huyện Na Rì",
                        "Wards": [
                                {
                                    "id": "02137",
                                    "name": "Xã Văn Vũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02140",
                                    "name": "Xã Văn Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02143",
                                    "name": "Xã Lương Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02146",
                                    "name": "Xã Kim Hỷ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02152",
                                    "name": "Xã Cường Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02155",
                                    "name": "Thị trấn Yến Lạc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02158",
                                    "name": "Xã Kim Lư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02161",
                                    "name": "Xã Sơn Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02170",
                                    "name": "Xã Văn Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02173",
                                    "name": "Xã Côn Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02176",
                                    "name": "Xã Cư Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02179",
                                    "name": "Xã Trần Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02185",
                                    "name": "Xã Quang Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02188",
                                    "name": "Xã Dương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02191",
                                    "name": "Xã Xuân Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02194",
                                    "name": "Xã Đổng Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02197",
                                    "name": "Xã Liêm Thuỷ",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "08",
            "name": "Tỉnh Tuyên Quang",
            "districts": [
                    {
                        "id": "070",
                        "name": "Thành phố Tuyên Quang",
                        "Wards": [
                                {
                                    "id": "02200",
                                    "name": "Phường Phan Thiết"
                                },
                                {
                                    "id": "02203",
                                    "name": "Phường Minh Xuân"
                                },
                                {
                                    "id": "02206",
                                    "name": "Phường Tân Quang"
                                },
                                {
                                    "id": "02209",
                                    "name": "Xã Tràng Đà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02212",
                                    "name": "Phường Nông Tiến"
                                },
                                {
                                    "id": "02215",
                                    "name": "Phường Ỷ La"
                                },
                                {
                                    "id": "02216",
                                    "name": "Phường Tân Hà"
                                },
                                {
                                    "id": "02218",
                                    "name": "Phường Hưng Thành"
                                },
                                {
                                    "id": "02497",
                                    "name": "Xã Kim Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02503",
                                    "name": "Xã An Khang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02509",
                                    "name": "Phường Mỹ Lâm"
                                },
                                {
                                    "id": "02512",
                                    "name": "Phường An Tường"
                                },
                                {
                                    "id": "02515",
                                    "name": "Xã Lưỡng Vượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02521",
                                    "name": "Xã Thái Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02524",
                                    "name": "Phường Đội Cấn"
                                }
                        ]
                    },
                    {
                        "id": "071",
                        "name": "Huyện Lâm Bình",
                        "Wards": [
                                {
                                    "id": "02233",
                                    "name": "Xã Phúc Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02242",
                                    "name": "Xã Xuân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02251",
                                    "name": "Xã Khuôn Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02266",
                                    "name": "Xã Lăng Can",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02269",
                                    "name": "Xã Thượng Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02290",
                                    "name": "Xã Bình An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02293",
                                    "name": "Xã Hồng Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02296",
                                    "name": "Xã Thổ Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "072",
                        "name": "Huyện Na Hang",
                        "Wards": [
                                {
                                    "id": "02221",
                                    "name": "Thị trấn Na Hang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02227",
                                    "name": "Xã Sinh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02230",
                                    "name": "Xã Thượng Giáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02239",
                                    "name": "Xã Thượng Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02245",
                                    "name": "Xã Côn Lôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02248",
                                    "name": "Xã Yên Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02254",
                                    "name": "Xã Hồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02260",
                                    "name": "Xã Đà Vị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02263",
                                    "name": "Xã Khau Tinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02275",
                                    "name": "Xã Sơn Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02281",
                                    "name": "Xã Năng Khả",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02284",
                                    "name": "Xã Thanh Tương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "073",
                        "name": "Huyện Chiêm Hóa",
                        "Wards": [
                                {
                                    "id": "02287",
                                    "name": "Thị trấn Vĩnh Lộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02299",
                                    "name": "Xã Phúc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02302",
                                    "name": "Xã Minh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02305",
                                    "name": "Xã Trung Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02308",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02311",
                                    "name": "Xã Hà Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02314",
                                    "name": "Xã Hùng Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02317",
                                    "name": "Xã Yên Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02320",
                                    "name": "Xã Tân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02323",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02326",
                                    "name": "Xã Xuân Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02329",
                                    "name": "Xã Ngọc Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02332",
                                    "name": "Xã Phú Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02335",
                                    "name": "Xã Hòa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02338",
                                    "name": "Xã Phúc Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02341",
                                    "name": "Xã Kiên Đài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02344",
                                    "name": "Xã Tân Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02347",
                                    "name": "Xã Trung Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02350",
                                    "name": "Xã Kim Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02353",
                                    "name": "Xã Hòa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02356",
                                    "name": "Xã Vinh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02359",
                                    "name": "Xã Tri Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02362",
                                    "name": "Xã Nhân Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02365",
                                    "name": "Xã Yên Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02368",
                                    "name": "Xã Linh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02371",
                                    "name": "Xã Bình Nhân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "074",
                        "name": "Huyện Hàm Yên",
                        "Wards": [
                                {
                                    "id": "02374",
                                    "name": "Thị trấn Tân Yên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02377",
                                    "name": "Xã Yên Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02380",
                                    "name": "Xã Bạch Xa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02383",
                                    "name": "Xã Minh Khương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02386",
                                    "name": "Xã Yên Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02389",
                                    "name": "Xã Minh Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02392",
                                    "name": "Xã Phù Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02395",
                                    "name": "Xã Minh Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02398",
                                    "name": "Xã Yên Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02401",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02404",
                                    "name": "Xã Bình Xa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02407",
                                    "name": "Xã Thái Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02410",
                                    "name": "Xã Nhân Mục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02413",
                                    "name": "Xã Thành Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02416",
                                    "name": "Xã Bằng Cốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02419",
                                    "name": "Xã Thái Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02422",
                                    "name": "Xã Đức Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02425",
                                    "name": "Xã Hùng Đức",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "075",
                        "name": "Huyện Yên Sơn",
                        "Wards": [
                                {
                                    "id": "02431",
                                    "name": "Xã Quí Quân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02434",
                                    "name": "Xã Lực Hành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02437",
                                    "name": "Xã Kiến Thiết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02440",
                                    "name": "Xã Trung Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02443",
                                    "name": "Xã Chiêu Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02446",
                                    "name": "Xã Trung Trực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02449",
                                    "name": "Xã Xuân Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02452",
                                    "name": "Xã Phúc Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02455",
                                    "name": "Xã Hùng Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02458",
                                    "name": "Xã Trung Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02461",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02464",
                                    "name": "Xã Tứ Quận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02467",
                                    "name": "Xã Đạo Viện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02470",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02473",
                                    "name": "Xã Thắng Quân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02476",
                                    "name": "Xã Kim Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02479",
                                    "name": "Xã Lang Quán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02482",
                                    "name": "Xã Phú Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02485",
                                    "name": "Xã Công Đa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02488",
                                    "name": "Xã Trung Môn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02491",
                                    "name": "Xã Chân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02494",
                                    "name": "Xã Thái Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02500",
                                    "name": "Xã Tiến Bộ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02506",
                                    "name": "Xã Mỹ Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02518",
                                    "name": "Xã Hoàng Khai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02527",
                                    "name": "Xã Nhữ Hán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02530",
                                    "name": "Xã Nhữ Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02533",
                                    "name": "Xã Đội Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "076",
                        "name": "Huyện Sơn Dương",
                        "Wards": [
                                {
                                    "id": "02536",
                                    "name": "Thị trấn Sơn Dương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02539",
                                    "name": "Xã Trung Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02542",
                                    "name": "Xã Minh Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02545",
                                    "name": "Xã Tân Trào",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02548",
                                    "name": "Xã Vĩnh Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02551",
                                    "name": "Xã Thượng Ấm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02554",
                                    "name": "Xã Bình Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02557",
                                    "name": "Xã Lương Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02560",
                                    "name": "Xã Tú Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02563",
                                    "name": "Xã Cấp Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02566",
                                    "name": "Xã Hợp Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02569",
                                    "name": "Xã Phúc Ứng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02572",
                                    "name": "Xã Đông Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02575",
                                    "name": "Xã Kháng Nhật",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02578",
                                    "name": "Xã Hợp Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02584",
                                    "name": "Xã Quyết Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02587",
                                    "name": "Xã Đồng Quý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02590",
                                    "name": "Xã Tân Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02593",
                                    "name": "Xã Vân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02596",
                                    "name": "Xã Văn Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02599",
                                    "name": "Xã Chi Thiết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02602",
                                    "name": "Xã Đông Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02605",
                                    "name": "Xã Thiện Kế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02608",
                                    "name": "Xã Hồng Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02611",
                                    "name": "Xã Phú Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02614",
                                    "name": "Xã Ninh Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02617",
                                    "name": "Xã Đại Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02620",
                                    "name": "Xã Sơn Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02623",
                                    "name": "Xã Hào Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02626",
                                    "name": "Xã Tam Đa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02632",
                                    "name": "Xã Trường Sinh",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "10",
            "name": "Tỉnh Lào Cai",
            "districts": [
                    {
                        "id": "080",
                        "name": "Thành phố Lào Cai",
                        "Wards": [
                                {
                                    "id": "02635",
                                    "name": "Phường Duyên Hải"
                                },
                                {
                                    "id": "02641",
                                    "name": "Phường Lào Cai"
                                },
                                {
                                    "id": "02644",
                                    "name": "Phường Cốc Lếu"
                                },
                                {
                                    "id": "02647",
                                    "name": "Phường Kim Tân"
                                },
                                {
                                    "id": "02650",
                                    "name": "Phường Bắc Lệnh"
                                },
                                {
                                    "id": "02653",
                                    "name": "Phường Pom Hán"
                                },
                                {
                                    "id": "02656",
                                    "name": "Phường Xuân Tăng"
                                },
                                {
                                    "id": "02658",
                                    "name": "Phường Bình Minh"
                                },
                                {
                                    "id": "02659",
                                    "name": "Xã Thống Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02662",
                                    "name": "Xã Đồng Tuyển",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02665",
                                    "name": "Xã Vạn Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02668",
                                    "name": "Phường Bắc Cường"
                                },
                                {
                                    "id": "02671",
                                    "name": "Phường Nam Cường"
                                },
                                {
                                    "id": "02674",
                                    "name": "Xã Cam Đường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02677",
                                    "name": "Xã Tả Phời",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02680",
                                    "name": "Xã Hợp Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02746",
                                    "name": "Xã Cốc San",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "082",
                        "name": "Huyện Bát Xát",
                        "Wards": [
                                {
                                    "id": "02683",
                                    "name": "Thị trấn Bát Xát",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02686",
                                    "name": "Xã A Mú Sung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02689",
                                    "name": "Xã Nậm Chạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02692",
                                    "name": "Xã A Lù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02695",
                                    "name": "Xã Trịnh Tường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02701",
                                    "name": "Xã Y Tý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02704",
                                    "name": "Xã Cốc Mỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02707",
                                    "name": "Xã Dền Sáng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02710",
                                    "name": "Xã Bản Vược",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02713",
                                    "name": "Xã Sàng Ma Sáo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02716",
                                    "name": "Xã Bản Qua",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02719",
                                    "name": "Xã Mường Vi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02722",
                                    "name": "Xã Dền Thàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02725",
                                    "name": "Xã Bản Xèo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02728",
                                    "name": "Xã Mường Hum",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02731",
                                    "name": "Xã Trung Lèng Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02734",
                                    "name": "Xã Quang Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02737",
                                    "name": "Xã Pa Cheo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02740",
                                    "name": "Xã Nậm Pung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02743",
                                    "name": "Xã Phìn Ngan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02749",
                                    "name": "Xã Tòng Sành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "083",
                        "name": "Huyện Mường Khương",
                        "Wards": [
                                {
                                    "id": "02752",
                                    "name": "Xã Pha Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02755",
                                    "name": "Xã Tả Ngải Chồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02758",
                                    "name": "Xã Tung Chung Phố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02761",
                                    "name": "Thị trấn Mường Khương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02764",
                                    "name": "Xã Dìn Chin",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02767",
                                    "name": "Xã Tả Gia Khâu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02770",
                                    "name": "Xã Nậm Chảy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02773",
                                    "name": "Xã Nấm Lư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02776",
                                    "name": "Xã Lùng Khấu Nhin",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02779",
                                    "name": "Xã Thanh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02782",
                                    "name": "Xã Cao Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02785",
                                    "name": "Xã Lùng Vai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02788",
                                    "name": "Xã Bản Lầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02791",
                                    "name": "Xã La Pan Tẩn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02794",
                                    "name": "Xã Tả Thàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02797",
                                    "name": "Xã Bản Sen",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "084",
                        "name": "Huyện Si Ma Cai",
                        "Wards": [
                                {
                                    "id": "02800",
                                    "name": "Xã Nàn Sán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02803",
                                    "name": "Xã Thào Chư Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02806",
                                    "name": "Xã Bản Mế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02809",
                                    "name": "Thị trấn Si Ma Cai",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02812",
                                    "name": "Xã Sán Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02818",
                                    "name": "Xã Lùng Thẩn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02821",
                                    "name": "Xã Cán Cấu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02824",
                                    "name": "Xã Sín Chéng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02827",
                                    "name": "Xã Quan Hồ Thẩn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02836",
                                    "name": "Xã Nàn Xín",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "085",
                        "name": "Huyện Bắc Hà",
                        "Wards": [
                                {
                                    "id": "02839",
                                    "name": "Thị trấn Bắc Hà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02842",
                                    "name": "Xã Lùng Cải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02848",
                                    "name": "Xã Lùng Phình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02851",
                                    "name": "Xã Tả Van Chư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02854",
                                    "name": "Xã Tả Củ Tỷ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02857",
                                    "name": "Xã Thải Giàng Phố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02863",
                                    "name": "Xã Hoàng Thu Phố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02866",
                                    "name": "Xã Bản Phố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02869",
                                    "name": "Xã Bản Liền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02872",
                                    "name": "Xã Tà Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02875",
                                    "name": "Xã Na Hối",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02878",
                                    "name": "Xã Cốc Ly",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02881",
                                    "name": "Xã Nậm Mòn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02884",
                                    "name": "Xã Nậm Đét",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02887",
                                    "name": "Xã Nậm Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02890",
                                    "name": "Xã Bảo Nhai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02893",
                                    "name": "Xã Nậm Lúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02896",
                                    "name": "Xã Cốc Lầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02899",
                                    "name": "Xã Bản Cái",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "086",
                        "name": "Huyện Bảo Thắng",
                        "Wards": [
                                {
                                    "id": "02902",
                                    "name": "Thị trấn N.T Phong Hải",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02905",
                                    "name": "Thị trấn Phố Lu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02908",
                                    "name": "Thị trấn Tằng Loỏng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02911",
                                    "name": "Xã Bản Phiệt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02914",
                                    "name": "Xã Bản Cầm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02917",
                                    "name": "Xã Thái Niên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02920",
                                    "name": "Xã Phong Niên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02923",
                                    "name": "Xã Gia Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02926",
                                    "name": "Xã Xuân Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02929",
                                    "name": "Xã Sơn Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02932",
                                    "name": "Xã Xuân Giao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02935",
                                    "name": "Xã Trì Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02938",
                                    "name": "Xã Sơn Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02944",
                                    "name": "Xã Phú Nhuận",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "087",
                        "name": "Huyện Bảo Yên",
                        "Wards": [
                                {
                                    "id": "02947",
                                    "name": "Thị trấn Phố Ràng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "02950",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02953",
                                    "name": "Xã Nghĩa Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02956",
                                    "name": "Xã Vĩnh Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02959",
                                    "name": "Xã Điện Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02962",
                                    "name": "Xã Xuân Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02965",
                                    "name": "Xã Tân Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02968",
                                    "name": "Xã Thượng Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02971",
                                    "name": "Xã Kim Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02974",
                                    "name": "Xã Cam Cọn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02977",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02980",
                                    "name": "Xã Xuân Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02983",
                                    "name": "Xã Việt Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02986",
                                    "name": "Xã Yên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02989",
                                    "name": "Xã Bảo Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02992",
                                    "name": "Xã Lương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "02998",
                                    "name": "Xã Phúc Khánh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "088",
                        "name": "Thị xã Sa Pa",
                        "Wards": [
                                {
                                    "id": "03001",
                                    "name": "Phường Sa Pa"
                                },
                                {
                                    "id": "03002",
                                    "name": "Phường Sa Pả"
                                },
                                {
                                    "id": "03003",
                                    "name": "Phường Ô Quý Hồ"
                                },
                                {
                                    "id": "03004",
                                    "name": "Xã Ngũ Chỉ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03006",
                                    "name": "Phường Phan Si Păng"
                                },
                                {
                                    "id": "03010",
                                    "name": "Xã Trung Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03013",
                                    "name": "Xã Tả Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03016",
                                    "name": "Phường Hàm Rồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03019",
                                    "name": "Xã Hoàng Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03022",
                                    "name": "Xã Thanh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03028",
                                    "name": "Phường Cầu Mây"
                                },
                                {
                                    "id": "03037",
                                    "name": "Xã Mường Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03040",
                                    "name": "Xã Tả Van",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03043",
                                    "name": "Xã Mường Bo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03046",
                                    "name": "Xã Bản Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03052",
                                    "name": "Xã Liên Minh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "089",
                        "name": "Huyện Văn Bàn",
                        "Wards": [
                                {
                                    "id": "03055",
                                    "name": "Thị trấn Khánh Yên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03061",
                                    "name": "Xã Võ Lao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03064",
                                    "name": "Xã Sơn Thuỷ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03067",
                                    "name": "Xã Nậm Mả",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03070",
                                    "name": "Xã Tân Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03073",
                                    "name": "Xã Nậm Rạng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03076",
                                    "name": "Xã Nậm Chầy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03079",
                                    "name": "Xã Tân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03082",
                                    "name": "Xã Khánh Yên Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03085",
                                    "name": "Xã Nậm Xé",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03088",
                                    "name": "Xã Dần Thàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03091",
                                    "name": "Xã Chiềng Ken",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03094",
                                    "name": "Xã Làng Giàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03097",
                                    "name": "Xã Hoà Mạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03100",
                                    "name": "Xã Khánh Yên Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03103",
                                    "name": "Xã Khánh Yên Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03106",
                                    "name": "Xã Dương Quỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03109",
                                    "name": "Xã Nậm Tha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03112",
                                    "name": "Xã Minh Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03115",
                                    "name": "Xã Thẩm Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03118",
                                    "name": "Xã Liêm Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03121",
                                    "name": "Xã Nậm Xây",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "11",
            "name": "Tỉnh Điện Biên",
            "districts": [
                    {
                        "id": "094",
                        "name": "Thành phố Điện Biên Phủ",
                        "Wards": [
                                {
                                    "id": "03124",
                                    "name": "Phường Noong Bua"
                                },
                                {
                                    "id": "03127",
                                    "name": "Phường Him Lam"
                                },
                                {
                                    "id": "03130",
                                    "name": "Phường Thanh Bình"
                                },
                                {
                                    "id": "03133",
                                    "name": "Phường Tân Thanh"
                                },
                                {
                                    "id": "03136",
                                    "name": "Phường Mường Thanh"
                                },
                                {
                                    "id": "03139",
                                    "name": "Phường Nam Thanh"
                                },
                                {
                                    "id": "03142",
                                    "name": "Phường Thanh Trường"
                                },
                                {
                                    "id": "03145",
                                    "name": "Xã Thanh Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03316",
                                    "name": "Xã Nà Tấu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03317",
                                    "name": "Xã Nà Nhạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03325",
                                    "name": "Xã Mường Phăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03326",
                                    "name": "Xã Pá Khoang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "095",
                        "name": "Thị Xã Mường Lay",
                        "Wards": [
                                {
                                    "id": "03148",
                                    "name": "Phường Sông Đà"
                                },
                                {
                                    "id": "03151",
                                    "name": "Phường Na Lay"
                                },
                                {
                                    "id": "03184",
                                    "name": "Xã Lay Nưa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "096",
                        "name": "Huyện Mường Nhé",
                        "Wards": [
                                {
                                    "id": "03154",
                                    "name": "Xã Sín Thầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03155",
                                    "name": "Xã Sen Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03157",
                                    "name": "Xã Chung Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03158",
                                    "name": "Xã Leng Su Sìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03159",
                                    "name": "Xã Pá Mỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03160",
                                    "name": "Xã Mường Nhé",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03161",
                                    "name": "Xã Nậm Vì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03162",
                                    "name": "Xã Nậm Kè",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03163",
                                    "name": "Xã Mường Toong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03164",
                                    "name": "Xã Quảng Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03177",
                                    "name": "Xã Huổi Lếnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "097",
                        "name": "Huyện Mường Chà",
                        "Wards": [
                                {
                                    "id": "03172",
                                    "name": "Thị Trấn Mường Chà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03178",
                                    "name": "Xã Xá Tổng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03181",
                                    "name": "Xã Mường Tùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03190",
                                    "name": "Xã Hừa Ngài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03191",
                                    "name": "Xã Huổi Mí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03193",
                                    "name": "Xã Pa Ham",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03194",
                                    "name": "Xã Nậm Nèn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03196",
                                    "name": "Xã Huổi Lèng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03197",
                                    "name": "Xã Sa Lông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03200",
                                    "name": "Xã Ma Thì Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03201",
                                    "name": "Xã Na Sang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03202",
                                    "name": "Xã Mường Mươn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "098",
                        "name": "Huyện Tủa Chùa",
                        "Wards": [
                                {
                                    "id": "03217",
                                    "name": "Thị trấn Tủa Chùa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03220",
                                    "name": "Xã Huổi Só",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03223",
                                    "name": "Xã Xín Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03226",
                                    "name": "Xã Tả Sìn Thàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03229",
                                    "name": "Xã Lao Xả Phình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03232",
                                    "name": "Xã Tả Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03235",
                                    "name": "Xã Tủa Thàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03238",
                                    "name": "Xã Trung Thu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03241",
                                    "name": "Xã Sính Phình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03244",
                                    "name": "Xã Sáng Nhè",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03247",
                                    "name": "Xã Mường Đun",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03250",
                                    "name": "Xã Mường Báng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "099",
                        "name": "Huyện Tuần Giáo",
                        "Wards": [
                                {
                                    "id": "03253",
                                    "name": "Thị trấn Tuần Giáo",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03259",
                                    "name": "Xã Phình Sáng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03260",
                                    "name": "Xã Rạng Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03262",
                                    "name": "Xã Mùn Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03263",
                                    "name": "Xã Nà Tòng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03265",
                                    "name": "Xã Ta Ma",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03268",
                                    "name": "Xã Mường Mùn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03269",
                                    "name": "Xã Pú Xi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03271",
                                    "name": "Xã Pú Nhung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03274",
                                    "name": "Xã Quài Nưa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03277",
                                    "name": "Xã Mường Thín",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03280",
                                    "name": "Xã Tỏa Tình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03283",
                                    "name": "Xã Nà Sáy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03284",
                                    "name": "Xã Mường Khong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03289",
                                    "name": "Xã Quài Cang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03295",
                                    "name": "Xã Quài Tở",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03298",
                                    "name": "Xã Chiềng Sinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03299",
                                    "name": "Xã Chiềng Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03304",
                                    "name": "Xã Tênh Phông",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "100",
                        "name": "Huyện Điện Biên",
                        "Wards": [
                                {
                                    "id": "03319",
                                    "name": "Xã Mường Pồn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03322",
                                    "name": "Xã Thanh Nưa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03323",
                                    "name": "Xã Hua Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03328",
                                    "name": "Xã Thanh Luông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03331",
                                    "name": "Xã Thanh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03334",
                                    "name": "Xã Thanh Xương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03337",
                                    "name": "Xã Thanh Chăn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03340",
                                    "name": "Xã Pa Thơm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03343",
                                    "name": "Xã Thanh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03346",
                                    "name": "Xã Thanh Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03349",
                                    "name": "Xã Noong Luống",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03352",
                                    "name": "Xã Noọng Hẹt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03355",
                                    "name": "Xã Sam Mứn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03356",
                                    "name": "Xã Pom Lót",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03358",
                                    "name": "Xã Núa Ngam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03359",
                                    "name": "Xã Hẹ Muông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03361",
                                    "name": "Xã Na Ư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03364",
                                    "name": "Xã Mường Nhà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03365",
                                    "name": "Xã Na Tông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03367",
                                    "name": "Xã Mường Lói",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03368",
                                    "name": "Xã Phu Luông",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "101",
                        "name": "Huyện Điện Biên Đông",
                        "Wards": [
                                {
                                    "id": "03203",
                                    "name": "Thị trấn Điện Biên Đông",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03205",
                                    "name": "Xã Na Son",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03208",
                                    "name": "Xã Phì Nhừ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03211",
                                    "name": "Xã Chiềng Sơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03214",
                                    "name": "Xã Mường Luân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03370",
                                    "name": "Xã Pú Nhi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03371",
                                    "name": "Xã Nong U",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03373",
                                    "name": "Xã Xa Dung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03376",
                                    "name": "Xã Keo Lôm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03379",
                                    "name": "Xã Luân Giới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03382",
                                    "name": "Xã Phình Giàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03383",
                                    "name": "Xã Pú Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03384",
                                    "name": "Xã Tìa Dình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03385",
                                    "name": "Xã Háng Lìa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "102",
                        "name": "Huyện Mường Ảng",
                        "Wards": [
                                {
                                    "id": "03256",
                                    "name": "Thị trấn Mường Ảng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03286",
                                    "name": "Xã Mường Đăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03287",
                                    "name": "Xã Ngối Cáy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03292",
                                    "name": "Xã Ẳng Tở",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03301",
                                    "name": "Xã Búng Lao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03302",
                                    "name": "Xã Xuân Lao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03307",
                                    "name": "Xã Ẳng Nưa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03310",
                                    "name": "Xã Ẳng Cang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03312",
                                    "name": "Xã Nặm Lịch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03313",
                                    "name": "Xã Mường Lạn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "103",
                        "name": "Huyện Nậm Pồ",
                        "Wards": [
                                {
                                    "id": "03156",
                                    "name": "Xã Nậm Tin",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03165",
                                    "name": "Xã Pa Tần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03166",
                                    "name": "Xã Chà Cang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03167",
                                    "name": "Xã Na Cô Sa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03168",
                                    "name": "Xã Nà Khoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03169",
                                    "name": "Xã Nà Hỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03170",
                                    "name": "Xã Nà Bủng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03171",
                                    "name": "Xã Nậm Nhừ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03173",
                                    "name": "Xã Nậm Chua",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03174",
                                    "name": "Xã Nậm Khăn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03175",
                                    "name": "Xã Chà Tở",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03176",
                                    "name": "Xã Vàng Đán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03187",
                                    "name": "Xã Chà Nưa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03198",
                                    "name": "Xã Phìn Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03199",
                                    "name": "Xã Si Pa Phìn",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "12",
            "name": "Tỉnh Lai Châu",
            "districts": [
                    {
                        "id": "105",
                        "name": "Thành phố Lai Châu",
                        "Wards": [
                                {
                                    "id": "03386",
                                    "name": "Phường Quyết Thắng"
                                },
                                {
                                    "id": "03387",
                                    "name": "Phường Tân Phong"
                                },
                                {
                                    "id": "03388",
                                    "name": "Phường Quyết Tiến"
                                },
                                {
                                    "id": "03389",
                                    "name": "Phường Đoàn Kết"
                                },
                                {
                                    "id": "03403",
                                    "name": "Xã Sùng Phài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03408",
                                    "name": "Phường Đông Phong"
                                },
                                {
                                    "id": "03409",
                                    "name": "Xã San Thàng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "106",
                        "name": "Huyện Tam Đường",
                        "Wards": [
                                {
                                    "id": "03390",
                                    "name": "Thị trấn Tam Đường",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03394",
                                    "name": "Xã Thèn Sin",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03400",
                                    "name": "Xã Tả Lèng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03405",
                                    "name": "Xã Giang Ma",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03406",
                                    "name": "Xã Hồ Thầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03412",
                                    "name": "Xã Bình Lư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03413",
                                    "name": "Xã Sơn Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03415",
                                    "name": "Xã Nùng Nàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03418",
                                    "name": "Xã Bản Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03421",
                                    "name": "Xã Bản Hon",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03424",
                                    "name": "Xã Bản Bo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03427",
                                    "name": "Xã Nà Tăm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03430",
                                    "name": "Xã Khun Há",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "107",
                        "name": "Huyện Mường Tè",
                        "Wards": [
                                {
                                    "id": "03433",
                                    "name": "Thị trấn Mường Tè",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03436",
                                    "name": "Xã Thu Lũm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03439",
                                    "name": "Xã Ka Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03440",
                                    "name": "Xã Tá Bạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03442",
                                    "name": "Xã Pa ủ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03445",
                                    "name": "Xã Mường Tè",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03448",
                                    "name": "Xã Pa Vệ Sử",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03451",
                                    "name": "Xã Mù Cả",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03454",
                                    "name": "Xã Bum Tở",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03457",
                                    "name": "Xã Nậm Khao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03463",
                                    "name": "Xã Tà Tổng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03466",
                                    "name": "Xã Bum Nưa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03467",
                                    "name": "Xã Vàng San",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03469",
                                    "name": "Xã Kan Hồ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "108",
                        "name": "Huyện Sìn Hồ",
                        "Wards": [
                                {
                                    "id": "03478",
                                    "name": "Thị trấn Sìn Hồ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03487",
                                    "name": "Xã Chăn Nưa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03493",
                                    "name": "Xã Pa Tần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03496",
                                    "name": "Xã Phìn Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03499",
                                    "name": "Xã Hồng Thu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03505",
                                    "name": "Xã Phăng Sô Lin",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03508",
                                    "name": "Xã Ma Quai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03509",
                                    "name": "Xã Lùng Thàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03511",
                                    "name": "Xã Tả Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03514",
                                    "name": "Xã Sà Dề Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03517",
                                    "name": "Xã Nậm Tăm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03520",
                                    "name": "Xã Tả Ngảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03523",
                                    "name": "Xã Pu Sam Cáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03526",
                                    "name": "Xã Nậm Cha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03527",
                                    "name": "Xã Pa Khoá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03529",
                                    "name": "Xã Làng Mô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03532",
                                    "name": "Xã Noong Hẻo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03535",
                                    "name": "Xã Nậm Mạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03538",
                                    "name": "Xã Căn Co",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03541",
                                    "name": "Xã Tủa Sín Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03544",
                                    "name": "Xã Nậm Cuổi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03547",
                                    "name": "Xã Nậm Hăn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "109",
                        "name": "Huyện Phong Thổ",
                        "Wards": [
                                {
                                    "id": "03391",
                                    "name": "Xã Lả Nhì Thàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03490",
                                    "name": "Xã Huổi Luông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03549",
                                    "name": "Thị trấn Phong Thổ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03550",
                                    "name": "Xã Sì Lở Lầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03553",
                                    "name": "Xã Mồ Sì San",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03559",
                                    "name": "Xã Pa Vây Sử",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03562",
                                    "name": "Xã Vàng Ma Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03565",
                                    "name": "Xã Tông Qua Lìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03568",
                                    "name": "Xã Mù Sang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03571",
                                    "name": "Xã Dào San",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03574",
                                    "name": "Xã Ma Ly Pho",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03577",
                                    "name": "Xã Bản Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03580",
                                    "name": "Xã Hoang Thèn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03583",
                                    "name": "Xã Khổng Lào",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03586",
                                    "name": "Xã Nậm Xe",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03589",
                                    "name": "Xã Mường So",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03592",
                                    "name": "Xã Sin Suối Hồ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "110",
                        "name": "Huyện Than Uyên",
                        "Wards": [
                                {
                                    "id": "03595",
                                    "name": "Thị trấn Than Uyên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03618",
                                    "name": "Xã Phúc Than",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03619",
                                    "name": "Xã Mường Than",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03625",
                                    "name": "Xã Mường Mít",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03628",
                                    "name": "Xã Pha Mu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03631",
                                    "name": "Xã Mường Cang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03632",
                                    "name": "Xã Hua Nà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03634",
                                    "name": "Xã Tà Hừa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03637",
                                    "name": "Xã Mường Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03638",
                                    "name": "Xã Tà Mung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03640",
                                    "name": "Xã Tà Gia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03643",
                                    "name": "Xã Khoen On",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "111",
                        "name": "Huyện Tân Uyên",
                        "Wards": [
                                {
                                    "id": "03598",
                                    "name": "Thị trấn Tân Uyên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03601",
                                    "name": "Xã Mường Khoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03602",
                                    "name": "Xã Phúc Khoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03604",
                                    "name": "Xã Thân Thuộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03605",
                                    "name": "Xã Trung Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03607",
                                    "name": "Xã Hố Mít",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03610",
                                    "name": "Xã Nậm Cần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03613",
                                    "name": "Xã Nậm Sỏ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03616",
                                    "name": "Xã Pắc Ta",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03622",
                                    "name": "Xã Tà Mít",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "112",
                        "name": "Huyện Nậm Nhùn",
                        "Wards": [
                                {
                                    "id": "03434",
                                    "name": "Thị trấn Nậm Nhùn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03460",
                                    "name": "Xã Hua Bun",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03472",
                                    "name": "Xã Mường Mô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03473",
                                    "name": "Xã Nậm Chà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03474",
                                    "name": "Xã Nậm Manh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03475",
                                    "name": "Xã Nậm Hàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03481",
                                    "name": "Xã Lê Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03484",
                                    "name": "Xã Pú Đao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03488",
                                    "name": "Xã Nậm Pì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03502",
                                    "name": "Xã Nậm Ban",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03503",
                                    "name": "Xã Trung Chải",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "14",
            "name": "Tỉnh Sơn La",
            "districts": [
                    {
                        "id": "116",
                        "name": "Thành phố Sơn La",
                        "Wards": [
                                {
                                    "id": "03646",
                                    "name": "Phường Chiềng Lề"
                                },
                                {
                                    "id": "03649",
                                    "name": "Phường Tô Hiệu"
                                },
                                {
                                    "id": "03652",
                                    "name": "Phường Quyết Thắng"
                                },
                                {
                                    "id": "03655",
                                    "name": "Phường Quyết Tâm"
                                },
                                {
                                    "id": "03658",
                                    "name": "Xã Chiềng Cọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03661",
                                    "name": "Xã Chiềng Đen",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03664",
                                    "name": "Xã Chiềng Xôm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03667",
                                    "name": "Phường Chiềng An"
                                },
                                {
                                    "id": "03670",
                                    "name": "Phường Chiềng Cơi"
                                },
                                {
                                    "id": "03673",
                                    "name": "Xã Chiềng Ngần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03676",
                                    "name": "Xã Hua La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03679",
                                    "name": "Phường Chiềng Sinh"
                                }
                        ]
                    },
                    {
                        "id": "118",
                        "name": "Huyện Quỳnh Nhai",
                        "Wards": [
                                {
                                    "id": "03682",
                                    "name": "Xã Mường Chiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03685",
                                    "name": "Xã Cà Nàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03688",
                                    "name": "Xã Chiềng Khay",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03694",
                                    "name": "Xã Mường Giôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03697",
                                    "name": "Xã Pá Ma Pha Khinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03700",
                                    "name": "Xã Chiềng Ơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03703",
                                    "name": "Xã Mường Giàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03706",
                                    "name": "Xã Chiềng Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03709",
                                    "name": "Xã Mường Sại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03712",
                                    "name": "Xã Nậm ét",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03718",
                                    "name": "Xã Chiềng Khoang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "119",
                        "name": "Huyện Thuận Châu",
                        "Wards": [
                                {
                                    "id": "03721",
                                    "name": "Thị trấn Thuận Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03724",
                                    "name": "Xã Phổng Lái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03727",
                                    "name": "Xã Mường é",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03730",
                                    "name": "Xã Chiềng Pha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03733",
                                    "name": "Xã Chiềng La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03736",
                                    "name": "Xã Chiềng Ngàm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03739",
                                    "name": "Xã Liệp Tè",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03742",
                                    "name": "Xã é Tòng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03745",
                                    "name": "Xã Phổng Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03748",
                                    "name": "Xã Phổng Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03751",
                                    "name": "Xã Chiềng Ly",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03754",
                                    "name": "Xã Noong Lay",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03757",
                                    "name": "Xã Mường Khiêng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03760",
                                    "name": "Xã Mường Bám",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03763",
                                    "name": "Xã Long Hẹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03766",
                                    "name": "Xã Chiềng Bôm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03769",
                                    "name": "Xã Thôm Mòn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03772",
                                    "name": "Xã Tông Lạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03775",
                                    "name": "Xã Tông Cọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03778",
                                    "name": "Xã Bó Mười",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03781",
                                    "name": "Xã Co Mạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03784",
                                    "name": "Xã Púng Tra",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03787",
                                    "name": "Xã Chiềng Pấc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03790",
                                    "name": "Xã Nậm Lầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03793",
                                    "name": "Xã Bon Phặng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03796",
                                    "name": "Xã Co Tòng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03799",
                                    "name": "Xã Muổi Nọi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03802",
                                    "name": "Xã Pá Lông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03805",
                                    "name": "Xã Bản Lầm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "120",
                        "name": "Huyện Mường La",
                        "Wards": [
                                {
                                    "id": "03808",
                                    "name": "Thị trấn Ít Ong",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03811",
                                    "name": "Xã Nậm Giôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03814",
                                    "name": "Xã Chiềng Lao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03817",
                                    "name": "Xã Hua Trai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03820",
                                    "name": "Xã Ngọc Chiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03823",
                                    "name": "Xã Mường Trai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03826",
                                    "name": "Xã Nậm Păm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03829",
                                    "name": "Xã Chiềng Muôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03832",
                                    "name": "Xã Chiềng Ân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03835",
                                    "name": "Xã Pi Toong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03838",
                                    "name": "Xã Chiềng Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03841",
                                    "name": "Xã Tạ Bú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03844",
                                    "name": "Xã Chiềng San",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03847",
                                    "name": "Xã Mường Bú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03850",
                                    "name": "Xã Chiềng Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03853",
                                    "name": "Xã Mường Chùm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "121",
                        "name": "Huyện Bắc Yên",
                        "Wards": [
                                {
                                    "id": "03856",
                                    "name": "Thị trấn Bắc Yên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03859",
                                    "name": "Xã Phiêng Ban",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03862",
                                    "name": "Xã Hang Chú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03865",
                                    "name": "Xã Xím Vàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03868",
                                    "name": "Xã Tà Xùa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03869",
                                    "name": "Xã Háng Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03871",
                                    "name": "Xã Pắc Ngà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03874",
                                    "name": "Xã Làng Chếu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03877",
                                    "name": "Xã Chim Vàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03880",
                                    "name": "Xã Mường Khoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03883",
                                    "name": "Xã Song Pe",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03886",
                                    "name": "Xã Hồng Ngài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03889",
                                    "name": "Xã Tạ Khoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03890",
                                    "name": "Xã Hua Nhàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03892",
                                    "name": "Xã Phiêng Côn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03895",
                                    "name": "Xã Chiềng Sại",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "122",
                        "name": "Huyện Phù Yên",
                        "Wards": [
                                {
                                    "id": "03898",
                                    "name": "Thị trấn Phù Yên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03901",
                                    "name": "Xã Suối Tọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03904",
                                    "name": "Xã Mường Thải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03907",
                                    "name": "Xã Mường Cơi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03910",
                                    "name": "Xã Quang Huy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03913",
                                    "name": "Xã Huy Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03916",
                                    "name": "Xã Huy Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03919",
                                    "name": "Xã Tân Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03922",
                                    "name": "Xã Gia Phù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03925",
                                    "name": "Xã Tường Phù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03928",
                                    "name": "Xã Huy Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03931",
                                    "name": "Xã Huy Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03934",
                                    "name": "Xã Mường Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03937",
                                    "name": "Xã Suối Bau",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03940",
                                    "name": "Xã Huy Tường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03943",
                                    "name": "Xã Mường Do",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03946",
                                    "name": "Xã Sập Xa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03949",
                                    "name": "Xã Tường Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03952",
                                    "name": "Xã Tường Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03955",
                                    "name": "Xã Tường Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03958",
                                    "name": "Xã Tường Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03961",
                                    "name": "Xã Kim Bon",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03964",
                                    "name": "Xã Mường Bang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03967",
                                    "name": "Xã Đá Đỏ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03970",
                                    "name": "Xã Tân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03973",
                                    "name": "Xã Nam Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03976",
                                    "name": "Xã Bắc Phong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "123",
                        "name": "Huyện Mộc Châu",
                        "Wards": [
                                {
                                    "id": "03979",
                                    "name": "Thị trấn Mộc Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03982",
                                    "name": "Thị trấn NT Mộc Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "03985",
                                    "name": "Xã Chiềng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03988",
                                    "name": "Xã Tân Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03991",
                                    "name": "Xã Qui Hướng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "03997",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04000",
                                    "name": "Xã Nà Mường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04003",
                                    "name": "Xã Tà Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04012",
                                    "name": "Xã Chiềng Hắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04015",
                                    "name": "Xã Hua Păng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04024",
                                    "name": "Xã Chiềng Khừa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04027",
                                    "name": "Xã Mường Sang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04030",
                                    "name": "Xã Đông Sang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04033",
                                    "name": "Xã Phiêng Luông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04045",
                                    "name": "Xã Lóng Sập",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "124",
                        "name": "Huyện Yên Châu",
                        "Wards": [
                                {
                                    "id": "04060",
                                    "name": "Thị trấn Yên Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04063",
                                    "name": "Xã Chiềng Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04066",
                                    "name": "Xã Sập Vạt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04069",
                                    "name": "Xã Chiềng Sàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04072",
                                    "name": "Xã Chiềng Pằn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04075",
                                    "name": "Xã Viêng Lán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04078",
                                    "name": "Xã Chiềng Hặc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04081",
                                    "name": "Xã Mường Lựm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04084",
                                    "name": "Xã Chiềng On",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04087",
                                    "name": "Xã Yên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04090",
                                    "name": "Xã Chiềng Khoi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04093",
                                    "name": "Xã Tú Nang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04096",
                                    "name": "Xã Lóng Phiêng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04099",
                                    "name": "Xã Phiêng Khoài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04102",
                                    "name": "Xã Chiềng Tương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "125",
                        "name": "Huyện Mai Sơn",
                        "Wards": [
                                {
                                    "id": "04105",
                                    "name": "Thị trấn Hát Lót",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04108",
                                    "name": "Xã Chiềng Sung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04111",
                                    "name": "Xã Mường Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04114",
                                    "name": "Xã Chiềng Chăn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04117",
                                    "name": "Xã Mương Chanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04120",
                                    "name": "Xã Chiềng Ban",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04123",
                                    "name": "Xã Chiềng Mung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04126",
                                    "name": "Xã Mường Bon",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04129",
                                    "name": "Xã Chiềng Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04132",
                                    "name": "Xã Chiềng Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04135",
                                    "name": "Xã Hát Lót",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04136",
                                    "name": "Xã Nà Pó",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04138",
                                    "name": "Xã Cò  Nòi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04141",
                                    "name": "Xã Chiềng Nơi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04144",
                                    "name": "Xã Phiêng Cằm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04147",
                                    "name": "Xã Chiềng Dong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04150",
                                    "name": "Xã Chiềng Kheo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04153",
                                    "name": "Xã Chiềng Ve",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04156",
                                    "name": "Xã Chiềng Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04159",
                                    "name": "Xã Phiêng Pằn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04162",
                                    "name": "Xã Nà Ơt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04165",
                                    "name": "Xã Tà Hộc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "126",
                        "name": "Huyện Sông Mã",
                        "Wards": [
                                {
                                    "id": "04168",
                                    "name": "Thị trấn Sông Mã",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04171",
                                    "name": "Xã Bó Sinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04174",
                                    "name": "Xã Pú Pẩu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04177",
                                    "name": "Xã Chiềng Phung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04180",
                                    "name": "Xã Chiềng En",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04183",
                                    "name": "Xã Mường Lầm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04186",
                                    "name": "Xã Nậm Ty",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04189",
                                    "name": "Xã Đứa Mòn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04192",
                                    "name": "Xã Yên Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04195",
                                    "name": "Xã Chiềng Sơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04198",
                                    "name": "Xã Nà Nghịu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04201",
                                    "name": "Xã Nậm Mằn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04204",
                                    "name": "Xã Chiềng Khoong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04207",
                                    "name": "Xã Chiềng Cang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04210",
                                    "name": "Xã Huổi Một",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04213",
                                    "name": "Xã Mường Sai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04216",
                                    "name": "Xã Mường Cai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04219",
                                    "name": "Xã Mường Hung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04222",
                                    "name": "Xã Chiềng Khương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "127",
                        "name": "Huyện Sốp Cộp",
                        "Wards": [
                                {
                                    "id": "04225",
                                    "name": "Xã Sam Kha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04228",
                                    "name": "Xã Púng Bánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04231",
                                    "name": "Xã Sốp Cộp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04234",
                                    "name": "Xã Dồm Cang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04237",
                                    "name": "Xã Nậm Lạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04240",
                                    "name": "Xã Mường Lèo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04243",
                                    "name": "Xã Mường Và",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04246",
                                    "name": "Xã Mường Lạn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "128",
                        "name": "Huyện Vân Hồ",
                        "Wards": [
                                {
                                    "id": "03994",
                                    "name": "Xã Suối Bàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04006",
                                    "name": "Xã Song Khủa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04009",
                                    "name": "Xã Liên Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04018",
                                    "name": "Xã Tô Múa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04021",
                                    "name": "Xã Mường Tè",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04036",
                                    "name": "Xã Chiềng Khoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04039",
                                    "name": "Xã Mường Men",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04042",
                                    "name": "Xã Quang Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04048",
                                    "name": "Xã Vân Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04051",
                                    "name": "Xã Lóng Luông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04054",
                                    "name": "Xã Chiềng Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04056",
                                    "name": "Xã Chiềng Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04057",
                                    "name": "Xã Xuân Nha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04058",
                                    "name": "Xã Tân Xuân",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "15",
            "name": "Tỉnh Yên Bái",
            "districts": [
                    {
                        "id": "132",
                        "name": "Thành phố Yên Bái",
                        "Wards": [
                                {
                                    "id": "04249",
                                    "name": "Phường Yên Thịnh"
                                },
                                {
                                    "id": "04252",
                                    "name": "Phường Yên Ninh"
                                },
                                {
                                    "id": "04255",
                                    "name": "Phường Minh Tân"
                                },
                                {
                                    "id": "04258",
                                    "name": "Phường Nguyễn Thái Học"
                                },
                                {
                                    "id": "04261",
                                    "name": "Phường Đồng Tâm"
                                },
                                {
                                    "id": "04264",
                                    "name": "Phường Nguyễn Phúc"
                                },
                                {
                                    "id": "04267",
                                    "name": "Phường Hồng Hà"
                                },
                                {
                                    "id": "04270",
                                    "name": "Xã Minh Bảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04273",
                                    "name": "Phường Nam Cường"
                                },
                                {
                                    "id": "04276",
                                    "name": "Xã Tuy Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04279",
                                    "name": "Xã Tân Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04540",
                                    "name": "Xã Âu Lâu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04543",
                                    "name": "Xã Giới Phiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04546",
                                    "name": "Phường Hợp Minh"
                                },
                                {
                                    "id": "04558",
                                    "name": "Xã Văn Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "133",
                        "name": "Thị xã Nghĩa Lộ",
                        "Wards": [
                                {
                                    "id": "04282",
                                    "name": "Phường Pú Trạng"
                                },
                                {
                                    "id": "04285",
                                    "name": "Phường Trung Tâm"
                                },
                                {
                                    "id": "04288",
                                    "name": "Phường Tân An"
                                },
                                {
                                    "id": "04291",
                                    "name": "Phường Cầu Thia"
                                },
                                {
                                    "id": "04294",
                                    "name": "Xã Nghĩa Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04297",
                                    "name": "Xã Nghĩa Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04300",
                                    "name": "Xã Nghĩa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04624",
                                    "name": "Xã Nghĩa Lộ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04660",
                                    "name": "Xã Sơn A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04663",
                                    "name": "Xã Phù Nham",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04675",
                                    "name": "Xã Thanh Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04678",
                                    "name": "Xã Hạnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04681",
                                    "name": "Xã Phúc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04684",
                                    "name": "Xã Thạch Lương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "135",
                        "name": "Huyện Lục Yên",
                        "Wards": [
                                {
                                    "id": "04303",
                                    "name": "Thị trấn Yên Thế",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04306",
                                    "name": "Xã Tân Phượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04309",
                                    "name": "Xã Lâm Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04312",
                                    "name": "Xã Khánh Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04315",
                                    "name": "Xã Minh Chuẩn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04318",
                                    "name": "Xã Mai Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04321",
                                    "name": "Xã Khai Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04324",
                                    "name": "Xã Mường Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04327",
                                    "name": "Xã An Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04330",
                                    "name": "Xã Minh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04333",
                                    "name": "Xã Tô Mậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04336",
                                    "name": "Xã Tân Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04339",
                                    "name": "Xã Yên Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04342",
                                    "name": "Xã Khánh Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04345",
                                    "name": "Xã Vĩnh Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04348",
                                    "name": "Xã Liễu Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04351",
                                    "name": "Xã Động Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04354",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04357",
                                    "name": "Xã Minh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04360",
                                    "name": "Xã Trúc Lâu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04363",
                                    "name": "Xã Phúc Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04366",
                                    "name": "Xã Phan Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04369",
                                    "name": "Xã An Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04372",
                                    "name": "Xã Trung Tâm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "136",
                        "name": "Huyện Văn Yên",
                        "Wards": [
                                {
                                    "id": "04375",
                                    "name": "Thị trấn Mậu A",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04378",
                                    "name": "Xã Lang Thíp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04381",
                                    "name": "Xã Lâm Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04384",
                                    "name": "Xã Châu Quế Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04387",
                                    "name": "Xã Châu Quế Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04390",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04393",
                                    "name": "Xã Quang Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04396",
                                    "name": "Xã Đông An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04399",
                                    "name": "Xã Đông Cuông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04402",
                                    "name": "Xã Phong Dụ Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04405",
                                    "name": "Xã Mậu Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04408",
                                    "name": "Xã Ngòi A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04411",
                                    "name": "Xã Xuân Tầm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04414",
                                    "name": "Xã Tân Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04417",
                                    "name": "Xã An Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04420",
                                    "name": "Xã Yên Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04423",
                                    "name": "Xã Phong Dụ Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04426",
                                    "name": "Xã Yên Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04429",
                                    "name": "Xã Đại Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04435",
                                    "name": "Xã Đại Phác",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04438",
                                    "name": "Xã Yên Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04441",
                                    "name": "Xã Xuân Ái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04447",
                                    "name": "Xã Viễn Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04450",
                                    "name": "Xã Mỏ Vàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04453",
                                    "name": "Xã Nà Hẩu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "137",
                        "name": "Huyện Mù Căng Chải",
                        "Wards": [
                                {
                                    "id": "04456",
                                    "name": "Thị trấn Mù Căng Chải",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04459",
                                    "name": "Xã Hồ Bốn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04462",
                                    "name": "Xã Nậm Có",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04465",
                                    "name": "Xã Khao Mang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04468",
                                    "name": "Xã Mồ Dề",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04471",
                                    "name": "Xã Chế Cu Nha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04474",
                                    "name": "Xã Lao Chải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04477",
                                    "name": "Xã Kim Nọi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04480",
                                    "name": "Xã Cao Phạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04483",
                                    "name": "Xã La Pán Tẩn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04486",
                                    "name": "Xã Dế Su Phình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04489",
                                    "name": "Xã Chế Tạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04492",
                                    "name": "Xã Púng Luông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04495",
                                    "name": "Xã Nậm Khắt",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "138",
                        "name": "Huyện Trấn Yên",
                        "Wards": [
                                {
                                    "id": "04498",
                                    "name": "Thị trấn Cổ Phúc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04501",
                                    "name": "Xã Tân Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04504",
                                    "name": "Xã Báo Đáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04507",
                                    "name": "Xã Đào Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04510",
                                    "name": "Xã Việt Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04513",
                                    "name": "Xã Hòa Cuông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04516",
                                    "name": "Xã Minh Quán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04519",
                                    "name": "Xã Quy Mông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04522",
                                    "name": "Xã Cường Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04525",
                                    "name": "Xã Kiên Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04528",
                                    "name": "Xã Nga Quán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04531",
                                    "name": "Xã Y Can",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04537",
                                    "name": "Xã Lương Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04561",
                                    "name": "Xã Bảo Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04564",
                                    "name": "Xã Việt Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04567",
                                    "name": "Xã Minh Quân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04570",
                                    "name": "Xã Hồng Ca",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04573",
                                    "name": "Xã Hưng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04576",
                                    "name": "Xã Hưng Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04579",
                                    "name": "Xã Việt Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04582",
                                    "name": "Xã Vân Hội",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "139",
                        "name": "Huyện Trạm Tấu",
                        "Wards": [
                                {
                                    "id": "04585",
                                    "name": "Thị trấn Trạm Tấu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04588",
                                    "name": "Xã Túc Đán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04591",
                                    "name": "Xã Pá Lau",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04594",
                                    "name": "Xã Xà Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04597",
                                    "name": "Xã Phình Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04600",
                                    "name": "Xã Trạm Tấu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04603",
                                    "name": "Xã Tà Si Láng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04606",
                                    "name": "Xã Pá Hu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04609",
                                    "name": "Xã Làng Nhì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04612",
                                    "name": "Xã Bản Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04615",
                                    "name": "Xã Bản Mù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04618",
                                    "name": "Xã Hát Lìu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "140",
                        "name": "Huyện Văn Chấn",
                        "Wards": [
                                {
                                    "id": "04621",
                                    "name": "Thị trấn NT Liên Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04627",
                                    "name": "Thị trấn NT Trần Phú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04630",
                                    "name": "Xã Tú Lệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04633",
                                    "name": "Xã Nậm Búng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04636",
                                    "name": "Xã Gia Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04639",
                                    "name": "Xã Sùng Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04642",
                                    "name": "Xã Nậm Mười",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04645",
                                    "name": "Xã An Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04648",
                                    "name": "Xã Nậm Lành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04651",
                                    "name": "Xã Sơn Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04654",
                                    "name": "Xã Suối Quyền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04657",
                                    "name": "Xã Suối Giàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04666",
                                    "name": "Xã Nghĩa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04669",
                                    "name": "Xã Suối Bu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04672",
                                    "name": "Thị trấn Sơn Thịnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04687",
                                    "name": "Xã Đại Lịch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04690",
                                    "name": "Xã Đồng Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04693",
                                    "name": "Xã Cát Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04696",
                                    "name": "Xã Tân Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04699",
                                    "name": "Xã Chấn Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04702",
                                    "name": "Xã Bình Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04705",
                                    "name": "Xã Thượng Bằng La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04708",
                                    "name": "Xã Minh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04711",
                                    "name": "Xã Nghĩa Tâm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "141",
                        "name": "Huyện Yên Bình",
                        "Wards": [
                                {
                                    "id": "04714",
                                    "name": "Thị trấn Yên Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04717",
                                    "name": "Thị trấn Thác Bà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04720",
                                    "name": "Xã Xuân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04726",
                                    "name": "Xã Cảm Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04729",
                                    "name": "Xã Ngọc Chấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04732",
                                    "name": "Xã Tân Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04735",
                                    "name": "Xã Phúc Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04738",
                                    "name": "Xã Bảo Ái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04741",
                                    "name": "Xã Mỹ Gia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04744",
                                    "name": "Xã Xuân Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04747",
                                    "name": "Xã Mông Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04750",
                                    "name": "Xã Cảm Ân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04753",
                                    "name": "Xã Yên Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04756",
                                    "name": "Xã Tân Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04759",
                                    "name": "Xã Phúc An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04762",
                                    "name": "Xã Bạch Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04765",
                                    "name": "Xã Vũ Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04768",
                                    "name": "Xã Đại Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04771",
                                    "name": "Xã Vĩnh Kiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04774",
                                    "name": "Xã Yên Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04777",
                                    "name": "Xã Thịnh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04780",
                                    "name": "Xã Hán Đà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04783",
                                    "name": "Xã Phú Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04786",
                                    "name": "Xã Đại Minh",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "17",
            "name": "Tỉnh Hoà Bình",
            "districts": [
                    {
                        "id": "148",
                        "name": "Thành phố Hòa Bình",
                        "Wards": [
                                {
                                    "id": "04789",
                                    "name": "Phường Thái Bình"
                                },
                                {
                                    "id": "04792",
                                    "name": "Phường Tân Hòa"
                                },
                                {
                                    "id": "04795",
                                    "name": "Phường Thịnh Lang"
                                },
                                {
                                    "id": "04798",
                                    "name": "Phường Hữu Nghị"
                                },
                                {
                                    "id": "04801",
                                    "name": "Phường Tân Thịnh"
                                },
                                {
                                    "id": "04804",
                                    "name": "Phường Đồng Tiến"
                                },
                                {
                                    "id": "04807",
                                    "name": "Phường Phương Lâm"
                                },
                                {
                                    "id": "04813",
                                    "name": "Xã Yên Mông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04816",
                                    "name": "Xã Sủ Ngòi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04819",
                                    "name": "Phường Dân Chủ"
                                },
                                {
                                    "id": "04825",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04828",
                                    "name": "Phường Thống Nhất"
                                },
                                {
                                    "id": "04894",
                                    "name": "Phường Kỳ Sơn"
                                },
                                {
                                    "id": "04897",
                                    "name": "Xã Thịnh Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04903",
                                    "name": "Xã Hợp Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04906",
                                    "name": "Xã Quang Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04912",
                                    "name": "Xã Mông Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04918",
                                    "name": "Xã Trung Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04921",
                                    "name": "Xã Độc Lập",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "150",
                        "name": "Huyện Đà Bắc",
                        "Wards": [
                                {
                                    "id": "04831",
                                    "name": "Thị trấn Đà Bắc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04834",
                                    "name": "Xã Nánh Nghê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04840",
                                    "name": "Xã Giáp Đắt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04846",
                                    "name": "Xã Mường Chiềng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04849",
                                    "name": "Xã Tân Pheo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04852",
                                    "name": "Xã Đồng Chum",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04855",
                                    "name": "Xã Tân Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04858",
                                    "name": "Xã Đoàn Kết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04861",
                                    "name": "Xã Đồng Ruộng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04867",
                                    "name": "Xã Tú Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04870",
                                    "name": "Xã Trung Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04873",
                                    "name": "Xã Yên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04876",
                                    "name": "Xã Cao Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04879",
                                    "name": "Xã Toàn Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04885",
                                    "name": "Xã Hiền Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04888",
                                    "name": "Xã Tiền Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04891",
                                    "name": "Xã Vầy Nưa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "152",
                        "name": "Huyện Lương Sơn",
                        "Wards": [
                                {
                                    "id": "04924",
                                    "name": "Thị trấn Lương Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04942",
                                    "name": "Xã Lâm Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04945",
                                    "name": "Xã Hòa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04951",
                                    "name": "Xã Tân Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04954",
                                    "name": "Xã Nhuận Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04957",
                                    "name": "Xã Cao Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04960",
                                    "name": "Xã Cư Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04969",
                                    "name": "Xã Liên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05008",
                                    "name": "Xã Cao Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05041",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05047",
                                    "name": "Xã Thanh Cao",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "153",
                        "name": "Huyện Kim Bôi",
                        "Wards": [
                                {
                                    "id": "04978",
                                    "name": "Thị trấn Bo",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "04984",
                                    "name": "Xã Đú Sáng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04987",
                                    "name": "Xã Hùng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04990",
                                    "name": "Xã Bình Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "04999",
                                    "name": "Xã Tú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05005",
                                    "name": "Xã Vĩnh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05014",
                                    "name": "Xã Đông Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05017",
                                    "name": "Xã Xuân Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05026",
                                    "name": "Xã Vĩnh Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05035",
                                    "name": "Xã Kim Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05038",
                                    "name": "Xã Hợp Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05065",
                                    "name": "Xã Kim Bôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05068",
                                    "name": "Xã Nam Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05077",
                                    "name": "Xã Cuối Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05080",
                                    "name": "Xã Sào Báy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05083",
                                    "name": "Xã Mi Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05086",
                                    "name": "Xã Nuông Dăm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "154",
                        "name": "Huyện Cao Phong",
                        "Wards": [
                                {
                                    "id": "05089",
                                    "name": "Thị trấn Cao Phong",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05092",
                                    "name": "Xã Bình Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05095",
                                    "name": "Xã Thung Nai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05098",
                                    "name": "Xã Bắc Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05101",
                                    "name": "Xã Thu Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05104",
                                    "name": "Xã Hợp Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05110",
                                    "name": "Xã Tây Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05116",
                                    "name": "Xã Dũng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05119",
                                    "name": "Xã Nam Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05125",
                                    "name": "Xã Thạch Yên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "155",
                        "name": "Huyện Tân Lạc",
                        "Wards": [
                                {
                                    "id": "05128",
                                    "name": "Thị trấn Mãn Đức",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05134",
                                    "name": "Xã Suối Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05137",
                                    "name": "Xã Phú Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05140",
                                    "name": "Xã Phú Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05143",
                                    "name": "Xã Mỹ Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05152",
                                    "name": "Xã Quyết Chiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05158",
                                    "name": "Xã Phong Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05164",
                                    "name": "Xã Tử Nê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05167",
                                    "name": "Xã Thanh Hối",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05170",
                                    "name": "Xã Ngọc Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05173",
                                    "name": "Xã Đông Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05176",
                                    "name": "Xã Vân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05182",
                                    "name": "Xã Nhân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05191",
                                    "name": "Xã Lỗ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05194",
                                    "name": "Xã Ngổ Luông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05197",
                                    "name": "Xã Gia Mô",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "156",
                        "name": "Huyện Mai Châu",
                        "Wards": [
                                {
                                    "id": "04882",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05200",
                                    "name": "Thị trấn Mai Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05206",
                                    "name": "Xã Sơn Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05209",
                                    "name": "Xã Pà Cò",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05212",
                                    "name": "Xã Hang Kia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05221",
                                    "name": "Xã Đồng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05224",
                                    "name": "Xã Cun Pheo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05227",
                                    "name": "Xã Bao La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05233",
                                    "name": "Xã Tòng Đậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05242",
                                    "name": "Xã Nà Phòn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05245",
                                    "name": "Xã Săm Khóe",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05248",
                                    "name": "Xã Chiềng Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05251",
                                    "name": "Xã Mai Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05254",
                                    "name": "Xã Thành Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05257",
                                    "name": "Xã Mai Hịch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05263",
                                    "name": "Xã Vạn Mai",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "157",
                        "name": "Huyện Lạc Sơn",
                        "Wards": [
                                {
                                    "id": "05266",
                                    "name": "Thị trấn Vụ Bản",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05269",
                                    "name": "Xã Quý Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05272",
                                    "name": "Xã Miền Đồi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05275",
                                    "name": "Xã Mỹ Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05278",
                                    "name": "Xã Tuân Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05281",
                                    "name": "Xã Văn Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05284",
                                    "name": "Xã Văn Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05287",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05290",
                                    "name": "Xã Nhân Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05293",
                                    "name": "Xã Thượng Cốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05299",
                                    "name": "Xã Quyết Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05302",
                                    "name": "Xã Xuất Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05305",
                                    "name": "Xã Yên Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05308",
                                    "name": "Xã Bình Hẻm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05320",
                                    "name": "Xã Định Cư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05323",
                                    "name": "Xã Chí Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05329",
                                    "name": "Xã Ngọc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05332",
                                    "name": "Xã Hương Nhượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05335",
                                    "name": "Xã Vũ Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05338",
                                    "name": "Xã Tự Do",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05341",
                                    "name": "Xã Yên Nghiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05344",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05347",
                                    "name": "Xã Ân Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05350",
                                    "name": "Xã Ngọc Lâu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "158",
                        "name": "Huyện Yên Thủy",
                        "Wards": [
                                {
                                    "id": "05353",
                                    "name": "Thị trấn Hàng Trạm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05356",
                                    "name": "Xã Lạc Sỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05362",
                                    "name": "Xã Lạc Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05365",
                                    "name": "Xã Bảo Hiệu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05368",
                                    "name": "Xã Đa Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05371",
                                    "name": "Xã Hữu Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05374",
                                    "name": "Xã Lạc Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05380",
                                    "name": "Xã Đoàn Kết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05383",
                                    "name": "Xã Phú Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05386",
                                    "name": "Xã Yên Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05389",
                                    "name": "Xã Ngọc Lương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "159",
                        "name": "Huyện Lạc Thủy",
                        "Wards": [
                                {
                                    "id": "04981",
                                    "name": "Thị trấn Ba Hàng Đồi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05392",
                                    "name": "Thị trấn Chi Nê",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05395",
                                    "name": "Xã Phú Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05398",
                                    "name": "Xã Phú Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05404",
                                    "name": "Xã Hưng Thi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05413",
                                    "name": "Xã Khoan Dụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05419",
                                    "name": "Xã Đồng Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05422",
                                    "name": "Xã Yên Bồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05425",
                                    "name": "Xã Thống Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05428",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "19",
            "name": "Tỉnh Thái Nguyên",
            "districts": [
                    {
                        "id": "164",
                        "name": "Thành phố Thái Nguyên",
                        "Wards": [
                                {
                                    "id": "05431",
                                    "name": "Phường Quán Triều"
                                },
                                {
                                    "id": "05434",
                                    "name": "Phường Quang Vinh"
                                },
                                {
                                    "id": "05437",
                                    "name": "Phường Túc Duyên"
                                },
                                {
                                    "id": "05440",
                                    "name": "Phường Hoàng Văn Thụ"
                                },
                                {
                                    "id": "05443",
                                    "name": "Phường Trưng Vương"
                                },
                                {
                                    "id": "05446",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "05449",
                                    "name": "Phường Phan Đình Phùng"
                                },
                                {
                                    "id": "05452",
                                    "name": "Phường Tân Thịnh"
                                },
                                {
                                    "id": "05455",
                                    "name": "Phường Thịnh Đán"
                                },
                                {
                                    "id": "05458",
                                    "name": "Phường Đồng Quang"
                                },
                                {
                                    "id": "05461",
                                    "name": "Phường Gia Sàng"
                                },
                                {
                                    "id": "05464",
                                    "name": "Phường Tân Lập"
                                },
                                {
                                    "id": "05467",
                                    "name": "Phường Cam Giá"
                                },
                                {
                                    "id": "05470",
                                    "name": "Phường Phú Xá"
                                },
                                {
                                    "id": "05473",
                                    "name": "Phường Hương Sơn"
                                },
                                {
                                    "id": "05476",
                                    "name": "Phường Trung Thành"
                                },
                                {
                                    "id": "05479",
                                    "name": "Phường Tân Thành"
                                },
                                {
                                    "id": "05482",
                                    "name": "Phường Tân Long"
                                },
                                {
                                    "id": "05485",
                                    "name": "Xã Phúc Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05488",
                                    "name": "Xã Phúc Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05491",
                                    "name": "Xã Quyết Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05494",
                                    "name": "Xã Phúc Trìu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05497",
                                    "name": "Xã Thịnh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05500",
                                    "name": "Phường Tích Lương"
                                },
                                {
                                    "id": "05503",
                                    "name": "Xã Tân Cương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05653",
                                    "name": "Xã Sơn Cẩm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05659",
                                    "name": "Phường Chùa Hang"
                                },
                                {
                                    "id": "05695",
                                    "name": "Xã Cao Ngạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05701",
                                    "name": "Xã Linh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05710",
                                    "name": "Phường Đồng Bẩm"
                                },
                                {
                                    "id": "05713",
                                    "name": "Xã Huống Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05914",
                                    "name": "Xã Đồng Liên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "165",
                        "name": "Thành phố Sông Công",
                        "Wards": [
                                {
                                    "id": "05506",
                                    "name": "Phường Lương Sơn"
                                },
                                {
                                    "id": "05509",
                                    "name": "Phường Châu Sơn"
                                },
                                {
                                    "id": "05512",
                                    "name": "Phường Mỏ Chè"
                                },
                                {
                                    "id": "05515",
                                    "name": "Phường Cải Đan"
                                },
                                {
                                    "id": "05518",
                                    "name": "Phường Thắng Lợi"
                                },
                                {
                                    "id": "05521",
                                    "name": "Phường Phố Cò"
                                },
                                {
                                    "id": "05527",
                                    "name": "Xã Tân Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05528",
                                    "name": "Phường Bách Quang"
                                },
                                {
                                    "id": "05530",
                                    "name": "Xã Bình Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05533",
                                    "name": "Xã Bá Xuyên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "167",
                        "name": "Huyện Định Hóa",
                        "Wards": [
                                {
                                    "id": "05536",
                                    "name": "Thị trấn Chợ Chu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05539",
                                    "name": "Xã Linh Thông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05542",
                                    "name": "Xã Lam Vỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05545",
                                    "name": "Xã Quy Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05548",
                                    "name": "Xã Tân Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05551",
                                    "name": "Xã Kim Phượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05554",
                                    "name": "Xã Bảo Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05560",
                                    "name": "Xã Phúc Chu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05563",
                                    "name": "Xã Tân Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05566",
                                    "name": "Xã Phượng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05569",
                                    "name": "Xã Bảo Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05572",
                                    "name": "Xã Đồng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05575",
                                    "name": "Xã Định Biên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05578",
                                    "name": "Xã Thanh Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05581",
                                    "name": "Xã Trung Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05584",
                                    "name": "Xã Trung Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05587",
                                    "name": "Xã Bình Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05590",
                                    "name": "Xã Điềm Mặc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05593",
                                    "name": "Xã Phú Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05596",
                                    "name": "Xã Bộc Nhiêu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05599",
                                    "name": "Xã Sơn Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05602",
                                    "name": "Xã Phú Đình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05605",
                                    "name": "Xã Bình Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "168",
                        "name": "Huyện Phú Lương",
                        "Wards": [
                                {
                                    "id": "05608",
                                    "name": "Thị trấn Giang Tiên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05611",
                                    "name": "Thị trấn Đu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05614",
                                    "name": "Xã Yên Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05617",
                                    "name": "Xã Yên Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05620",
                                    "name": "Xã Yên Đổ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05623",
                                    "name": "Xã Yên Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05626",
                                    "name": "Xã Ôn Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05629",
                                    "name": "Xã Động Đạt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05632",
                                    "name": "Xã Phủ Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05635",
                                    "name": "Xã Phú Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05638",
                                    "name": "Xã Hợp Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05641",
                                    "name": "Xã Tức Tranh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05644",
                                    "name": "Xã Phấn Mễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05647",
                                    "name": "Xã Vô Tranh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05650",
                                    "name": "Xã Cổ Lũng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "169",
                        "name": "Huyện Đồng Hỷ",
                        "Wards": [
                                {
                                    "id": "05656",
                                    "name": "Thị trấn Sông Cầu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05662",
                                    "name": "Thị trấn Trại Cau",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05665",
                                    "name": "Xã Văn Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05668",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05671",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05674",
                                    "name": "Xã Quang Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05677",
                                    "name": "Xã Minh Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05680",
                                    "name": "Xã Văn Hán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05683",
                                    "name": "Xã Hóa Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05686",
                                    "name": "Xã Khe Mo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05689",
                                    "name": "Xã Cây Thị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05692",
                                    "name": "Xã Hóa Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05698",
                                    "name": "Xã Hợp Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05704",
                                    "name": "Xã Tân Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05707",
                                    "name": "Xã Nam Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "170",
                        "name": "Huyện Võ Nhai",
                        "Wards": [
                                {
                                    "id": "05716",
                                    "name": "Thị trấn Đình Cả",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05719",
                                    "name": "Xã Sảng Mộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05722",
                                    "name": "Xã Nghinh Tường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05725",
                                    "name": "Xã Thần Xa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05728",
                                    "name": "Xã Vũ Chấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05731",
                                    "name": "Xã Thượng Nung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05734",
                                    "name": "Xã Phú Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05737",
                                    "name": "Xã Cúc Đường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05740",
                                    "name": "Xã La Hiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05743",
                                    "name": "Xã Lâu Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05746",
                                    "name": "Xã Tràng Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05749",
                                    "name": "Xã Phương Giao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05752",
                                    "name": "Xã Liên Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05755",
                                    "name": "Xã Dân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05758",
                                    "name": "Xã Bình Long",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "171",
                        "name": "Huyện Đại Từ",
                        "Wards": [
                                {
                                    "id": "05761",
                                    "name": "Thị trấn Hùng Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05764",
                                    "name": "Thị trấn Quân Chu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05767",
                                    "name": "Xã Phúc Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05770",
                                    "name": "Xã Minh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05773",
                                    "name": "Xã Yên Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05776",
                                    "name": "Xã Đức Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05779",
                                    "name": "Xã Phú Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05782",
                                    "name": "Xã Na Mao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05785",
                                    "name": "Xã Phú Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05788",
                                    "name": "Xã Tân Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05791",
                                    "name": "Xã Phú Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05794",
                                    "name": "Xã Phục Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05797",
                                    "name": "Xã Phú Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05800",
                                    "name": "Xã Bản Ngoại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05803",
                                    "name": "Xã Tiên Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05809",
                                    "name": "Xã Cù Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05812",
                                    "name": "Xã Hà Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05815",
                                    "name": "Xã La Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05818",
                                    "name": "Xã Hoàng Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05821",
                                    "name": "Xã Khôi Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05824",
                                    "name": "Xã An Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05827",
                                    "name": "Xã Tân Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05830",
                                    "name": "Xã Bình Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05833",
                                    "name": "Xã Lục Ba",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05836",
                                    "name": "Xã Mỹ Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05839",
                                    "name": "Xã Vạn Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05842",
                                    "name": "Xã Văn Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05845",
                                    "name": "Xã Ký Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05848",
                                    "name": "Xã Cát Nê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05851",
                                    "name": "Xã Quân Chu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "172",
                        "name": "Thị xã Phổ Yên",
                        "Wards": [
                                {
                                    "id": "05854",
                                    "name": "Phường Bãi Bông"
                                },
                                {
                                    "id": "05857",
                                    "name": "Phường Bắc Sơn"
                                },
                                {
                                    "id": "05860",
                                    "name": "Phường Ba Hàng"
                                },
                                {
                                    "id": "05863",
                                    "name": "Xã Phúc Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05866",
                                    "name": "Xã Phúc Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05869",
                                    "name": "Xã Hồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05872",
                                    "name": "Xã Minh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05875",
                                    "name": "Xã Đắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05878",
                                    "name": "Phường Đồng Tiến"
                                },
                                {
                                    "id": "05881",
                                    "name": "Xã Thành Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05884",
                                    "name": "Xã Tiên Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05887",
                                    "name": "Xã Vạn Phái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05890",
                                    "name": "Xã Nam Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05893",
                                    "name": "Xã Tân Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05896",
                                    "name": "Xã Đông Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05899",
                                    "name": "Xã Trung Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05902",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05905",
                                    "name": "Xã Thuận Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "173",
                        "name": "Huyện Phú Bình",
                        "Wards": [
                                {
                                    "id": "05908",
                                    "name": "Thị trấn Hương Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05911",
                                    "name": "Xã Bàn Đạt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05917",
                                    "name": "Xã Tân Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05920",
                                    "name": "Xã Tân Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05923",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05926",
                                    "name": "Xã Đào Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05929",
                                    "name": "Xã Bảo Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05932",
                                    "name": "Xã Thượng Đình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05935",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05938",
                                    "name": "Xã Nhã Lộng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05941",
                                    "name": "Xã Điềm Thụy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05944",
                                    "name": "Xã Xuân Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05947",
                                    "name": "Xã Tân Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05950",
                                    "name": "Xã Úc Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05953",
                                    "name": "Xã Lương Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05956",
                                    "name": "Xã Nga My",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05959",
                                    "name": "Xã Kha Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05962",
                                    "name": "Xã Thanh Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05965",
                                    "name": "Xã Dương Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05968",
                                    "name": "Xã Hà Châu",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "20",
            "name": "Tỉnh Lạng Sơn",
            "districts": [
                    {
                        "id": "178",
                        "name": "Thành phố Lạng Sơn",
                        "Wards": [
                                {
                                    "id": "05971",
                                    "name": "Phường Hoàng Văn Thụ"
                                },
                                {
                                    "id": "05974",
                                    "name": "Phường Tam Thanh"
                                },
                                {
                                    "id": "05977",
                                    "name": "Phường Vĩnh Trại"
                                },
                                {
                                    "id": "05980",
                                    "name": "Phường Đông Kinh"
                                },
                                {
                                    "id": "05983",
                                    "name": "Phường Chi Lăng"
                                },
                                {
                                    "id": "05986",
                                    "name": "Xã Hoàng Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05989",
                                    "name": "Xã Quảng Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "05992",
                                    "name": "Xã Mai Pha",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "180",
                        "name": "Huyện Tràng Định",
                        "Wards": [
                                {
                                    "id": "05995",
                                    "name": "Thị trấn Thất Khê",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "05998",
                                    "name": "Xã Khánh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06001",
                                    "name": "Xã Đoàn Kết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06004",
                                    "name": "Xã Quốc Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06007",
                                    "name": "Xã Vĩnh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06010",
                                    "name": "Xã Cao Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06013",
                                    "name": "Xã Chí Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06016",
                                    "name": "Xã Tri Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06019",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06022",
                                    "name": "Xã Tân Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06025",
                                    "name": "Xã Đội Cấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06028",
                                    "name": "Xã Tân Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06031",
                                    "name": "Xã Kim Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06034",
                                    "name": "Xã Chi Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06037",
                                    "name": "Xã Trung Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06040",
                                    "name": "Xã Đại Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06043",
                                    "name": "Xã Đào Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06046",
                                    "name": "Xã Đề Thám",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06049",
                                    "name": "Xã Kháng Chiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06055",
                                    "name": "Xã Hùng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06058",
                                    "name": "Xã Quốc Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06061",
                                    "name": "Xã Hùng Việt",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "181",
                        "name": "Huyện Bình Gia",
                        "Wards": [
                                {
                                    "id": "06067",
                                    "name": "Xã Hưng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06070",
                                    "name": "Xã Vĩnh Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06073",
                                    "name": "Xã Hoa Thám",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06076",
                                    "name": "Xã Quý Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06079",
                                    "name": "Xã Hồng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06082",
                                    "name": "Xã Yên Lỗ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06085",
                                    "name": "Xã Thiện Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06088",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06091",
                                    "name": "Xã Thiện Thuật",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06094",
                                    "name": "Xã Minh Khai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06097",
                                    "name": "Xã Thiện Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06100",
                                    "name": "Xã Hoàng Văn Thụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06103",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06106",
                                    "name": "Xã Mông Ân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06109",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06112",
                                    "name": "Thị trấn Bình Gia",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06115",
                                    "name": "Xã Hồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06118",
                                    "name": "Xã Bình La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06121",
                                    "name": "Xã Tân Văn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "182",
                        "name": "Huyện Văn Lãng",
                        "Wards": [
                                {
                                    "id": "06124",
                                    "name": "Thị trấn Na Sầm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06127",
                                    "name": "Xã Trùng Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06133",
                                    "name": "Xã Bắc La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06136",
                                    "name": "Xã Thụy Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06139",
                                    "name": "Xã Bắc Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06142",
                                    "name": "Xã Tân Tác",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06148",
                                    "name": "Xã Thanh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06151",
                                    "name": "Xã Hội Hoan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06154",
                                    "name": "Xã Bắc Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06157",
                                    "name": "Xã Hoàng Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06160",
                                    "name": "Xã Gia Miễn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06163",
                                    "name": "Xã Thành Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06166",
                                    "name": "Xã Tân Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06172",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06175",
                                    "name": "Xã Hồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06178",
                                    "name": "Xã  Hoàng Văn Thụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06181",
                                    "name": "Xã Nhạc Kỳ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "183",
                        "name": "Huyện Cao Lộc",
                        "Wards": [
                                {
                                    "id": "06184",
                                    "name": "Thị trấn Đồng Đăng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06187",
                                    "name": "Thị trấn Cao Lộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06190",
                                    "name": "Xã Bảo Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06193",
                                    "name": "Xã Thanh Lòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06196",
                                    "name": "Xã Cao Lâu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06199",
                                    "name": "Xã Thạch Đạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06202",
                                    "name": "Xã Xuất Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06205",
                                    "name": "Xã Hồng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06208",
                                    "name": "Xã Thụy Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06211",
                                    "name": "Xã Lộc Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06214",
                                    "name": "Xã Phú Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06217",
                                    "name": "Xã Bình Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06220",
                                    "name": "Xã Hải Yến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06223",
                                    "name": "Xã Hòa Cư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06226",
                                    "name": "Xã Hợp Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06232",
                                    "name": "Xã Công Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06235",
                                    "name": "Xã Gia Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06238",
                                    "name": "Xã Mẫu Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06241",
                                    "name": "Xã Xuân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06244",
                                    "name": "Xã Tân Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06247",
                                    "name": "Xã Yên Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06250",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "184",
                        "name": "Huyện Văn Quan",
                        "Wards": [
                                {
                                    "id": "06253",
                                    "name": "Thị trấn Văn Quan",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06256",
                                    "name": "Xã Trấn Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06268",
                                    "name": "Xã Liên Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06274",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06277",
                                    "name": "Xã Tú Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06280",
                                    "name": "Xã Điềm He",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06283",
                                    "name": "Xã An Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06286",
                                    "name": "Xã Khánh Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06292",
                                    "name": "Xã Lương Năng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06295",
                                    "name": "Xã Đồng Giáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06298",
                                    "name": "Xã Bình Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06301",
                                    "name": "Xã Tràng Các",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06307",
                                    "name": "Xã Tân Đoàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06313",
                                    "name": "Xã Tri Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06316",
                                    "name": "Xã Tràng Phái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06319",
                                    "name": "Xã Yên Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06322",
                                    "name": "Xã Hữu Lễ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "185",
                        "name": "Huyện Bắc Sơn",
                        "Wards": [
                                {
                                    "id": "06325",
                                    "name": "Thị trấn Bắc Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06328",
                                    "name": "Xã Long Đống",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06331",
                                    "name": "Xã Vạn Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06337",
                                    "name": "Xã Đồng ý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06340",
                                    "name": "Xã Tân Tri",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06343",
                                    "name": "Xã Bắc Quỳnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06349",
                                    "name": "Xã Hưng Vũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06352",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06355",
                                    "name": "Xã Vũ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06358",
                                    "name": "Xã Chiêu Vũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06361",
                                    "name": "Xã Tân Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06364",
                                    "name": "Xã Chiến Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06367",
                                    "name": "Xã Vũ Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06370",
                                    "name": "Xã Trấn Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06373",
                                    "name": "Xã Vũ Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06376",
                                    "name": "Xã Nhất Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06379",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06382",
                                    "name": "Xã Nhất Tiến",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "186",
                        "name": "Huyện Hữu Lũng",
                        "Wards": [
                                {
                                    "id": "06385",
                                    "name": "Thị trấn Hữu Lũng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06388",
                                    "name": "Xã Hữu Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06391",
                                    "name": "Xã Yên Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06394",
                                    "name": "Xã Quyết Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06397",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06400",
                                    "name": "Xã Yên Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06403",
                                    "name": "Xã Yên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06406",
                                    "name": "Xã Thiện Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06412",
                                    "name": "Xã Yên Vượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06415",
                                    "name": "Xã Minh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06418",
                                    "name": "Xã Nhật Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06421",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06424",
                                    "name": "Xã Đồng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06427",
                                    "name": "Xã Cai Kinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06430",
                                    "name": "Xã Hòa Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06433",
                                    "name": "Xã Vân Nham",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06436",
                                    "name": "Xã Đồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06442",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06445",
                                    "name": "Xã Hòa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06448",
                                    "name": "Xã Minh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06451",
                                    "name": "Xã Hồ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06454",
                                    "name": "Xã Sơn Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06457",
                                    "name": "Xã Minh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06460",
                                    "name": "Xã Hòa Thắng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "187",
                        "name": "Huyện Chi Lăng",
                        "Wards": [
                                {
                                    "id": "06463",
                                    "name": "Thị trấn Đồng Mỏ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06466",
                                    "name": "Thị trấn Chi Lăng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06469",
                                    "name": "Xã Vân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06472",
                                    "name": "Xã Vân Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06475",
                                    "name": "Xã Gia Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06478",
                                    "name": "Xã Bắc Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06481",
                                    "name": "Xã Chiến Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06484",
                                    "name": "Xã Mai Sao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06487",
                                    "name": "Xã Bằng Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06490",
                                    "name": "Xã Thượng Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06493",
                                    "name": "Xã Bằng Mạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06496",
                                    "name": "Xã Nhân Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06499",
                                    "name": "Xã Lâm Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06502",
                                    "name": "Xã Liên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06505",
                                    "name": "Xã Vạn Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06508",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06514",
                                    "name": "Xã Hữu Kiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06517",
                                    "name": "Xã Quan Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06520",
                                    "name": "Xã Y Tịch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06523",
                                    "name": "Xã Chi Lăng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "188",
                        "name": "Huyện Lộc Bình",
                        "Wards": [
                                {
                                    "id": "06526",
                                    "name": "Thị trấn Na Dương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06529",
                                    "name": "Thị trấn Lộc Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06532",
                                    "name": "Xã Mẫu Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06541",
                                    "name": "Xã Yên Khoái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06544",
                                    "name": "Xã Khánh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06547",
                                    "name": "Xã Tú Mịch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06550",
                                    "name": "Xã Hữu Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06553",
                                    "name": "Xã Đồng Bục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06559",
                                    "name": "Xã Tam Gia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06562",
                                    "name": "Xã Tú Đoạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06565",
                                    "name": "Xã Khuất Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06574",
                                    "name": "Xã Tĩnh Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06577",
                                    "name": "Xã Thống Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06589",
                                    "name": "Xã Sàn Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06592",
                                    "name": "Xã Đông Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06595",
                                    "name": "Xã Minh Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06598",
                                    "name": "Xã Hữu Lân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06601",
                                    "name": "Xã Lợi Bác",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06604",
                                    "name": "Xã Nam Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06607",
                                    "name": "Xã Xuân Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06610",
                                    "name": "Xã Ái Quốc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "189",
                        "name": "Huyện Đình Lập",
                        "Wards": [
                                {
                                    "id": "06613",
                                    "name": "Thị trấn Đình Lập",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06616",
                                    "name": "Thị trấn NT Thái Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06619",
                                    "name": "Xã Bắc Xa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06622",
                                    "name": "Xã Bính Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06625",
                                    "name": "Xã Kiên Mộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06628",
                                    "name": "Xã Đình Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06631",
                                    "name": "Xã Thái Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06634",
                                    "name": "Xã Cường Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06637",
                                    "name": "Xã Châu Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06640",
                                    "name": "Xã Lâm Ca",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06643",
                                    "name": "Xã Đồng Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06646",
                                    "name": "Xã Bắc Lãng",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "22",
            "name": "Tỉnh Quảng Ninh",
            "districts": [
                    {
                        "id": "193",
                        "name": "Thành phố Hạ Long",
                        "Wards": [
                                {
                                    "id": "06649",
                                    "name": "Phường Hà Khánh"
                                },
                                {
                                    "id": "06652",
                                    "name": "Phường Hà Phong"
                                },
                                {
                                    "id": "06655",
                                    "name": "Phường Hà Khẩu"
                                },
                                {
                                    "id": "06658",
                                    "name": "Phường Cao Xanh"
                                },
                                {
                                    "id": "06661",
                                    "name": "Phường Giếng Đáy"
                                },
                                {
                                    "id": "06664",
                                    "name": "Phường Hà Tu"
                                },
                                {
                                    "id": "06667",
                                    "name": "Phường Hà Trung"
                                },
                                {
                                    "id": "06670",
                                    "name": "Phường Hà Lầm"
                                },
                                {
                                    "id": "06673",
                                    "name": "Phường Bãi Cháy"
                                },
                                {
                                    "id": "06676",
                                    "name": "Phường Cao Thắng"
                                },
                                {
                                    "id": "06679",
                                    "name": "Phường Hùng Thắng"
                                },
                                {
                                    "id": "06682",
                                    "name": "Phường Yết Kiêu"
                                },
                                {
                                    "id": "06685",
                                    "name": "Phường Trần Hưng Đạo"
                                },
                                {
                                    "id": "06688",
                                    "name": "Phường Hồng Hải"
                                },
                                {
                                    "id": "06691",
                                    "name": "Phường Hồng Gai"
                                },
                                {
                                    "id": "06694",
                                    "name": "Phường Bạch Đằng"
                                },
                                {
                                    "id": "06697",
                                    "name": "Phường Hồng Hà"
                                },
                                {
                                    "id": "06700",
                                    "name": "Phường Tuần Châu"
                                },
                                {
                                    "id": "06703",
                                    "name": "Phường Việt Hưng"
                                },
                                {
                                    "id": "06706",
                                    "name": "Phường Đại Yên"
                                },
                                {
                                    "id": "07030",
                                    "name": "Phường Hoành Bồ"
                                },
                                {
                                    "id": "07033",
                                    "name": "Xã Kỳ Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07036",
                                    "name": "Xã Đồng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07039",
                                    "name": "Xã Tân Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07042",
                                    "name": "Xã Đồng Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07045",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07048",
                                    "name": "Xã Vũ Oai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07051",
                                    "name": "Xã Dân Chủ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07054",
                                    "name": "Xã Quảng La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07057",
                                    "name": "Xã Bằng Cả",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07060",
                                    "name": "Xã Thống Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07063",
                                    "name": "Xã Sơn Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07066",
                                    "name": "Xã Lê Lợi",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "194",
                        "name": "Thành phố Móng Cái",
                        "Wards": [
                                {
                                    "id": "06709",
                                    "name": "Phường Ka Long"
                                },
                                {
                                    "id": "06712",
                                    "name": "Phường Trần Phú"
                                },
                                {
                                    "id": "06715",
                                    "name": "Phường Ninh Dương"
                                },
                                {
                                    "id": "06718",
                                    "name": "Phường Hoà Lạc"
                                },
                                {
                                    "id": "06721",
                                    "name": "Phường Trà Cổ"
                                },
                                {
                                    "id": "06724",
                                    "name": "Xã Hải Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06727",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06730",
                                    "name": "Xã Hải Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06733",
                                    "name": "Xã Hải Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06736",
                                    "name": "Phường Hải Yên"
                                },
                                {
                                    "id": "06739",
                                    "name": "Xã Quảng Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06742",
                                    "name": "Phường Hải Hoà"
                                },
                                {
                                    "id": "06745",
                                    "name": "Xã Hải Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06748",
                                    "name": "Xã Vạn Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06751",
                                    "name": "Phường Bình Ngọc"
                                },
                                {
                                    "id": "06754",
                                    "name": "Xã Vĩnh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06757",
                                    "name": "Xã Vĩnh Thực",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "195",
                        "name": "Thành phố Cẩm Phả",
                        "Wards": [
                                {
                                    "id": "06760",
                                    "name": "Phường Mông Dương"
                                },
                                {
                                    "id": "06763",
                                    "name": "Phường Cửa Ông"
                                },
                                {
                                    "id": "06766",
                                    "name": "Phường Cẩm Sơn"
                                },
                                {
                                    "id": "06769",
                                    "name": "Phường Cẩm Đông"
                                },
                                {
                                    "id": "06772",
                                    "name": "Phường Cẩm Phú"
                                },
                                {
                                    "id": "06775",
                                    "name": "Phường Cẩm Tây"
                                },
                                {
                                    "id": "06778",
                                    "name": "Phường Quang Hanh"
                                },
                                {
                                    "id": "06781",
                                    "name": "Phường Cẩm Thịnh"
                                },
                                {
                                    "id": "06784",
                                    "name": "Phường Cẩm Thủy"
                                },
                                {
                                    "id": "06787",
                                    "name": "Phường Cẩm Thạch"
                                },
                                {
                                    "id": "06790",
                                    "name": "Phường Cẩm Thành"
                                },
                                {
                                    "id": "06793",
                                    "name": "Phường Cẩm Trung"
                                },
                                {
                                    "id": "06796",
                                    "name": "Phường Cẩm Bình"
                                },
                                {
                                    "id": "06799",
                                    "name": "Xã Cộng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06802",
                                    "name": "Xã Cẩm Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06805",
                                    "name": "Xã Dương Huy",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "196",
                        "name": "Thành phố Uông Bí",
                        "Wards": [
                                {
                                    "id": "06808",
                                    "name": "Phường Vàng Danh"
                                },
                                {
                                    "id": "06811",
                                    "name": "Phường Thanh Sơn"
                                },
                                {
                                    "id": "06814",
                                    "name": "Phường Bắc Sơn"
                                },
                                {
                                    "id": "06817",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "06820",
                                    "name": "Phường Trưng Vương"
                                },
                                {
                                    "id": "06823",
                                    "name": "Phường Nam Khê"
                                },
                                {
                                    "id": "06826",
                                    "name": "Phường Yên Thanh"
                                },
                                {
                                    "id": "06829",
                                    "name": "Xã Thượng Yên Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06832",
                                    "name": "Phường Phương Đông"
                                },
                                {
                                    "id": "06835",
                                    "name": "Phường Phương Nam"
                                }
                        ]
                    },
                    {
                        "id": "198",
                        "name": "Huyện Bình Liêu",
                        "Wards": [
                                {
                                    "id": "06838",
                                    "name": "Thị trấn Bình Liêu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06841",
                                    "name": "Xã Hoành Mô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06844",
                                    "name": "Xã Đồng Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06847",
                                    "name": "Xã Đồng Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06853",
                                    "name": "Xã Vô Ngại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06856",
                                    "name": "Xã Lục Hồn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06859",
                                    "name": "Xã Húc Động",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "199",
                        "name": "Huyện Tiên Yên",
                        "Wards": [
                                {
                                    "id": "06862",
                                    "name": "Thị trấn Tiên Yên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06865",
                                    "name": "Xã Hà Lâu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06868",
                                    "name": "Xã Đại Dực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06871",
                                    "name": "Xã Phong Dụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06874",
                                    "name": "Xã Điền Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06877",
                                    "name": "Xã Đông Ngũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06880",
                                    "name": "Xã Yên Than",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06883",
                                    "name": "Xã Đông Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06886",
                                    "name": "Xã Hải Lạng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06889",
                                    "name": "Xã Tiên Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06892",
                                    "name": "Xã Đồng Rui",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "200",
                        "name": "Huyện Đầm Hà",
                        "Wards": [
                                {
                                    "id": "06895",
                                    "name": "Thị trấn Đầm Hà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06898",
                                    "name": "Xã Quảng Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06901",
                                    "name": "Xã Quảng An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06904",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06910",
                                    "name": "Xã Dực Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06913",
                                    "name": "Xã Quảng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06916",
                                    "name": "Xã Đầm Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06917",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06919",
                                    "name": "Xã Đại Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "201",
                        "name": "Huyện Hải Hà",
                        "Wards": [
                                {
                                    "id": "06922",
                                    "name": "Thị trấn Quảng Hà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06925",
                                    "name": "Xã Quảng Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06928",
                                    "name": "Xã Quảng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06931",
                                    "name": "Xã Quảng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06937",
                                    "name": "Xã Quảng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06940",
                                    "name": "Xã Quảng Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06943",
                                    "name": "Xã Quảng Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06946",
                                    "name": "Xã Quảng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06949",
                                    "name": "Xã Đường Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06952",
                                    "name": "Xã Quảng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06967",
                                    "name": "Xã Cái Chiên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "202",
                        "name": "Huyện Ba Chẽ",
                        "Wards": [
                                {
                                    "id": "06970",
                                    "name": "Thị trấn Ba Chẽ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06973",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06976",
                                    "name": "Xã Thanh Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06979",
                                    "name": "Xã Đạp Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06982",
                                    "name": "Xã Nam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06985",
                                    "name": "Xã Lương Mông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06988",
                                    "name": "Xã Đồn Đạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "06991",
                                    "name": "Xã Minh Cầm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "203",
                        "name": "Huyện Vân Đồn",
                        "Wards": [
                                {
                                    "id": "06994",
                                    "name": "Thị trấn Cái Rồng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "06997",
                                    "name": "Xã Đài Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07000",
                                    "name": "Xã Bình Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07003",
                                    "name": "Xã Vạn Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07006",
                                    "name": "Xã Minh Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07009",
                                    "name": "Xã Đoàn Kết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07012",
                                    "name": "Xã Hạ Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07015",
                                    "name": "Xã Đông Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07018",
                                    "name": "Xã Bản Sen",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07021",
                                    "name": "Xã Thắng Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07024",
                                    "name": "Xã Quan Lạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07027",
                                    "name": "Xã Ngọc Vừng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "205",
                        "name": "Thị xã Đông Triều",
                        "Wards": [
                                {
                                    "id": "07069",
                                    "name": "Phường Mạo Khê"
                                },
                                {
                                    "id": "07072",
                                    "name": "Phường Đông Triều"
                                },
                                {
                                    "id": "07075",
                                    "name": "Xã An Sinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07078",
                                    "name": "Xã Tràng Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07081",
                                    "name": "Xã Bình Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07084",
                                    "name": "Xã Việt Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07087",
                                    "name": "Xã Tân Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07090",
                                    "name": "Xã Bình Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07093",
                                    "name": "Phường Đức Chính"
                                },
                                {
                                    "id": "07096",
                                    "name": "Phường Tràng An"
                                },
                                {
                                    "id": "07099",
                                    "name": "Xã Nguyễn Huệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07102",
                                    "name": "Xã Thủy An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07105",
                                    "name": "Phường Xuân Sơn"
                                },
                                {
                                    "id": "07108",
                                    "name": "Xã Hồng Thái Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07111",
                                    "name": "Xã Hồng Thái Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07114",
                                    "name": "Phường Hoàng Quế"
                                },
                                {
                                    "id": "07117",
                                    "name": "Phường Yên Thọ"
                                },
                                {
                                    "id": "07120",
                                    "name": "Phường Hồng Phong"
                                },
                                {
                                    "id": "07123",
                                    "name": "Phường Kim Sơn"
                                },
                                {
                                    "id": "07126",
                                    "name": "Phường Hưng Đạo"
                                },
                                {
                                    "id": "07129",
                                    "name": "Xã Yên Đức",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "206",
                        "name": "Thị xã Quảng Yên",
                        "Wards": [
                                {
                                    "id": "07132",
                                    "name": "Phường Quảng Yên"
                                },
                                {
                                    "id": "07135",
                                    "name": "Phường Đông Mai"
                                },
                                {
                                    "id": "07138",
                                    "name": "Phường Minh Thành"
                                },
                                {
                                    "id": "07144",
                                    "name": "Xã Sông Khoai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07147",
                                    "name": "Xã Hiệp Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07150",
                                    "name": "Phường Cộng Hòa"
                                },
                                {
                                    "id": "07153",
                                    "name": "Xã Tiền An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07156",
                                    "name": "Xã Hoàng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07159",
                                    "name": "Phường Tân An"
                                },
                                {
                                    "id": "07162",
                                    "name": "Phường Yên Giang"
                                },
                                {
                                    "id": "07165",
                                    "name": "Phường Nam Hoà"
                                },
                                {
                                    "id": "07168",
                                    "name": "Phường Hà An"
                                },
                                {
                                    "id": "07171",
                                    "name": "Xã Cẩm La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07174",
                                    "name": "Phường Phong Hải"
                                },
                                {
                                    "id": "07177",
                                    "name": "Phường Yên Hải"
                                },
                                {
                                    "id": "07180",
                                    "name": "Xã Liên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07183",
                                    "name": "Phường Phong Cốc"
                                },
                                {
                                    "id": "07186",
                                    "name": "Xã Liên Vị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07189",
                                    "name": "Xã Tiền Phong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "207",
                        "name": "Huyện Cô Tô",
                        "Wards": [
                                {
                                    "id": "07192",
                                    "name": "Thị trấn Cô Tô",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07195",
                                    "name": "Xã Đồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07198",
                                    "name": "Xã Thanh Lân",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "24",
            "name": "Tỉnh Bắc Giang",
            "districts": [
                    {
                        "id": "213",
                        "name": "Thành phố Bắc Giang",
                        "Wards": [
                                {
                                    "id": "07201",
                                    "name": "Phường Thọ Xương"
                                },
                                {
                                    "id": "07204",
                                    "name": "Phường Trần Nguyên Hãn"
                                },
                                {
                                    "id": "07207",
                                    "name": "Phường Ngô Quyền"
                                },
                                {
                                    "id": "07210",
                                    "name": "Phường Hoàng Văn Thụ"
                                },
                                {
                                    "id": "07213",
                                    "name": "Phường Trần Phú"
                                },
                                {
                                    "id": "07216",
                                    "name": "Phường Mỹ Độ"
                                },
                                {
                                    "id": "07219",
                                    "name": "Phường Lê Lợi"
                                },
                                {
                                    "id": "07222",
                                    "name": "Xã Song Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07225",
                                    "name": "Phường Xương Giang"
                                },
                                {
                                    "id": "07228",
                                    "name": "Phường Đa Mai"
                                },
                                {
                                    "id": "07231",
                                    "name": "Phường Dĩnh Kế"
                                },
                                {
                                    "id": "07441",
                                    "name": "Xã Dĩnh Trì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07687",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07696",
                                    "name": "Xã Đồng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07699",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07705",
                                    "name": "Xã Song Khê",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "215",
                        "name": "Huyện Yên Thế",
                        "Wards": [
                                {
                                    "id": "07243",
                                    "name": "Xã Đồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07246",
                                    "name": "Xã Canh Nậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07249",
                                    "name": "Xã Xuân Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07252",
                                    "name": "Xã Tam Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07255",
                                    "name": "Xã Đồng Vương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07258",
                                    "name": "Xã Đồng Hưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07260",
                                    "name": "Xã Đồng Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07261",
                                    "name": "Xã Tam Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07264",
                                    "name": "Xã Tiến Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07267",
                                    "name": "Xã Hồng Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07270",
                                    "name": "Xã Đồng Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07273",
                                    "name": "Xã Đông Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07276",
                                    "name": "Xã Tân Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07279",
                                    "name": "Xã Hương Vĩ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07282",
                                    "name": "Xã Đồng Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07285",
                                    "name": "Xã An Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07288",
                                    "name": "Thị trấn Phồn Xương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07291",
                                    "name": "Xã Tân Sỏi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07294",
                                    "name": "Thị trấn Bố Hạ",
                                    "Level": "Thị trấn"
                                }
                        ]
                    },
                    {
                        "id": "216",
                        "name": "Huyện Tân Yên",
                        "Wards": [
                                {
                                    "id": "07303",
                                    "name": "Xã Lan Giới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07306",
                                    "name": "Thị trấn Nhã Nam",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07309",
                                    "name": "Xã Tân Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07312",
                                    "name": "Xã Đại Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07315",
                                    "name": "Xã Quang Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07318",
                                    "name": "Xã Phúc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07321",
                                    "name": "Xã An Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07324",
                                    "name": "Xã Phúc Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07327",
                                    "name": "Xã Liên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07330",
                                    "name": "Xã Hợp Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07333",
                                    "name": "Xã Lam Cốt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07336",
                                    "name": "Xã Cao Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07339",
                                    "name": "Thị trấn Cao Thượng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07342",
                                    "name": "Xã Việt Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07345",
                                    "name": "Xã Song Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07348",
                                    "name": "Xã Ngọc Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07351",
                                    "name": "Xã Ngọc Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07354",
                                    "name": "Xã Việt Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07357",
                                    "name": "Xã Liên Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07360",
                                    "name": "Xã Ngọc Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07363",
                                    "name": "Xã Ngọc Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07366",
                                    "name": "Xã Quế Nham",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "217",
                        "name": "Huyện Lạng Giang",
                        "Wards": [
                                {
                                    "id": "07375",
                                    "name": "Thị trấn Vôi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07378",
                                    "name": "Xã Nghĩa Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07381",
                                    "name": "Xã Nghĩa Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07384",
                                    "name": "Xã Quang Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07387",
                                    "name": "Xã Hương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07390",
                                    "name": "Xã Đào Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07393",
                                    "name": "Xã Tiên Lục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07396",
                                    "name": "Xã An Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07399",
                                    "name": "Thị trấn Kép",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07402",
                                    "name": "Xã Mỹ Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07405",
                                    "name": "Xã Hương Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07408",
                                    "name": "Xã Dương Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07411",
                                    "name": "Xã Tân Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07414",
                                    "name": "Xã Yên Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07417",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07420",
                                    "name": "Xã Mỹ Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07426",
                                    "name": "Xã Xương Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07429",
                                    "name": "Xã Xuân Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07432",
                                    "name": "Xã Tân Dĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07435",
                                    "name": "Xã Đại Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07438",
                                    "name": "Xã Thái Đào",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "218",
                        "name": "Huyện Lục Nam",
                        "Wards": [
                                {
                                    "id": "07444",
                                    "name": "Thị trấn Đồi Ngô",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07450",
                                    "name": "Xã Đông Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07453",
                                    "name": "Xã Đông Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07456",
                                    "name": "Xã Tam Dị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07459",
                                    "name": "Xã Bảo Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07462",
                                    "name": "Xã Bảo Đài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07465",
                                    "name": "Xã Thanh Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07468",
                                    "name": "Xã Tiên Nha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07471",
                                    "name": "Xã Trường Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07477",
                                    "name": "Xã Phương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07480",
                                    "name": "Xã Chu Điện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07483",
                                    "name": "Xã Cương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07486",
                                    "name": "Xã Nghĩa Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07489",
                                    "name": "Xã Vô Tranh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07492",
                                    "name": "Xã Bình Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07495",
                                    "name": "Xã Lan Mẫu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07498",
                                    "name": "Xã Yên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07501",
                                    "name": "Xã Khám Lạng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07504",
                                    "name": "Xã Huyền Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07507",
                                    "name": "Xã Trường Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07510",
                                    "name": "Xã Lục Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07513",
                                    "name": "Xã Bắc Lũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07516",
                                    "name": "Xã Vũ Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07519",
                                    "name": "Xã Cẩm Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07522",
                                    "name": "Xã Đan Hội",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "219",
                        "name": "Huyện Lục Ngạn",
                        "Wards": [
                                {
                                    "id": "07525",
                                    "name": "Thị trấn Chũ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07528",
                                    "name": "Xã Cấm Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07531",
                                    "name": "Xã Tân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07534",
                                    "name": "Xã Phong Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07537",
                                    "name": "Xã Phong Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07540",
                                    "name": "Xã Xa Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07543",
                                    "name": "Xã Hộ Đáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07546",
                                    "name": "Xã Sơn Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07549",
                                    "name": "Xã Thanh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07552",
                                    "name": "Xã Kiên Lao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07555",
                                    "name": "Xã Biên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07558",
                                    "name": "Xã Kiên Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07561",
                                    "name": "Xã Hồng Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07564",
                                    "name": "Xã Kim Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07567",
                                    "name": "Xã Tân Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07570",
                                    "name": "Xã Giáp Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07573",
                                    "name": "Xã Biển Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07576",
                                    "name": "Xã Quý Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07579",
                                    "name": "Xã Trù Hựu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07582",
                                    "name": "Xã Phì Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07588",
                                    "name": "Xã Tân Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07591",
                                    "name": "Xã Đồng Cốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07594",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07597",
                                    "name": "Xã Phú Nhuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07600",
                                    "name": "Xã Mỹ An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07603",
                                    "name": "Xã Nam Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07606",
                                    "name": "Xã Tân Mộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07609",
                                    "name": "Xã Đèo Gia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07612",
                                    "name": "Xã Phượng Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "220",
                        "name": "Huyện Sơn Động",
                        "Wards": [
                                {
                                    "id": "07615",
                                    "name": "Thị trấn An Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07616",
                                    "name": "Thị trấn Tây Yên Tử",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07621",
                                    "name": "Xã Vân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07624",
                                    "name": "Xã Hữu Sản",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07627",
                                    "name": "Xã Đại Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07630",
                                    "name": "Xã Phúc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07636",
                                    "name": "Xã Giáo Liêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07642",
                                    "name": "Xã Cẩm Đàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07645",
                                    "name": "Xã An Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07648",
                                    "name": "Xã Vĩnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07651",
                                    "name": "Xã Yên Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07654",
                                    "name": "Xã Lệ Viễn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07660",
                                    "name": "Xã An Bá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07663",
                                    "name": "Xã Tuấn Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07666",
                                    "name": "Xã Dương Hưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07672",
                                    "name": "Xã Long Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07678",
                                    "name": "Xã Thanh Luận",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "221",
                        "name": "Huyện Yên Dũng",
                        "Wards": [
                                {
                                    "id": "07681",
                                    "name": "Thị trấn Nham Biền",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07682",
                                    "name": "Thị trấn Tân An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07684",
                                    "name": "Xã Lão Hộ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07690",
                                    "name": "Xã Hương Gián",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07702",
                                    "name": "Xã Quỳnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07708",
                                    "name": "Xã Nội Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07711",
                                    "name": "Xã Tiền Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07714",
                                    "name": "Xã Xuân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07717",
                                    "name": "Xã Tân Liễu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07720",
                                    "name": "Xã Trí Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07723",
                                    "name": "Xã Lãng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07726",
                                    "name": "Xã Yên Lư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07729",
                                    "name": "Xã Tiến Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07735",
                                    "name": "Xã Đức Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07738",
                                    "name": "Xã Cảnh Thụy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07741",
                                    "name": "Xã Tư Mại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07747",
                                    "name": "Xã Đồng Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07750",
                                    "name": "Xã Đồng Phúc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "222",
                        "name": "Huyện Việt Yên",
                        "Wards": [
                                {
                                    "id": "07759",
                                    "name": "Xã Thượng Lan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07762",
                                    "name": "Xã Việt Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07765",
                                    "name": "Xã Nghĩa Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07768",
                                    "name": "Xã Minh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07771",
                                    "name": "Xã Hương Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07774",
                                    "name": "Xã Tự Lạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07777",
                                    "name": "Thị trấn Bích Động",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07780",
                                    "name": "Xã Trung Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07783",
                                    "name": "Xã Hồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07786",
                                    "name": "Xã Tiên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07789",
                                    "name": "Xã Tăng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07792",
                                    "name": "Xã Quảng Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07795",
                                    "name": "Thị trấn Nếnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07798",
                                    "name": "Xã Ninh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07801",
                                    "name": "Xã Vân Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07804",
                                    "name": "Xã Vân Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07807",
                                    "name": "Xã Quang Châu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "223",
                        "name": "Huyện Hiệp Hòa",
                        "Wards": [
                                {
                                    "id": "07813",
                                    "name": "Xã Đồng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07816",
                                    "name": "Xã Thanh Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07819",
                                    "name": "Xã Hoàng Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07822",
                                    "name": "Xã Hoàng Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07825",
                                    "name": "Xã Hoàng Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07828",
                                    "name": "Xã Hoàng An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07831",
                                    "name": "Xã Ngọc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07834",
                                    "name": "Xã Thái Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07837",
                                    "name": "Xã Hòa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07840",
                                    "name": "Thị trấn Thắng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07843",
                                    "name": "Xã Quang Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07846",
                                    "name": "Xã Lương Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07849",
                                    "name": "Xã Hùng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07852",
                                    "name": "Xã Đại Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07855",
                                    "name": "Xã Thường Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07858",
                                    "name": "Xã Hợp Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07861",
                                    "name": "Xã Danh Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07864",
                                    "name": "Xã Mai Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07867",
                                    "name": "Xã Đoan Bái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07870",
                                    "name": "Xã Bắc Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07873",
                                    "name": "Xã Xuân Cẩm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07876",
                                    "name": "Xã Hương Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07879",
                                    "name": "Xã Đông Lỗ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07882",
                                    "name": "Xã Châu Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07885",
                                    "name": "Xã Mai Đình",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "25",
            "name": "Tỉnh Phú Thọ",
            "districts": [
                    {
                        "id": "227",
                        "name": "Thành phố Việt Trì",
                        "Wards": [
                                {
                                    "id": "07888",
                                    "name": "Phường Dữu Lâu"
                                },
                                {
                                    "id": "07891",
                                    "name": "Phường Vân Cơ"
                                },
                                {
                                    "id": "07894",
                                    "name": "Phường Nông Trang"
                                },
                                {
                                    "id": "07897",
                                    "name": "Phường Tân Dân"
                                },
                                {
                                    "id": "07900",
                                    "name": "Phường Gia Cẩm"
                                },
                                {
                                    "id": "07903",
                                    "name": "Phường Tiên Cát"
                                },
                                {
                                    "id": "07906",
                                    "name": "Phường Thọ Sơn"
                                },
                                {
                                    "id": "07909",
                                    "name": "Phường Thanh Miếu"
                                },
                                {
                                    "id": "07912",
                                    "name": "Phường Bạch Hạc"
                                },
                                {
                                    "id": "07915",
                                    "name": "Phường Bến Gót"
                                },
                                {
                                    "id": "07918",
                                    "name": "Phường Vân Phú"
                                },
                                {
                                    "id": "07921",
                                    "name": "Xã Phượng Lâu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07924",
                                    "name": "Xã Thụy Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07927",
                                    "name": "Phường Minh Phương"
                                },
                                {
                                    "id": "07930",
                                    "name": "Xã Trưng Vương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07933",
                                    "name": "Phường Minh Nông"
                                },
                                {
                                    "id": "07936",
                                    "name": "Xã Sông Lô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08281",
                                    "name": "Xã Kim Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08287",
                                    "name": "Xã Hùng Lô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08503",
                                    "name": "Xã Hy Cương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08506",
                                    "name": "Xã Chu Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08515",
                                    "name": "Xã Thanh Đình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "228",
                        "name": "Thị xã Phú Thọ",
                        "Wards": [
                                {
                                    "id": "07942",
                                    "name": "Phường Hùng Vương"
                                },
                                {
                                    "id": "07945",
                                    "name": "Phường Phong Châu"
                                },
                                {
                                    "id": "07948",
                                    "name": "Phường Âu Cơ"
                                },
                                {
                                    "id": "07951",
                                    "name": "Xã Hà Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07954",
                                    "name": "Xã Phú Hộ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07957",
                                    "name": "Xã Văn Lung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07960",
                                    "name": "Xã Thanh Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07963",
                                    "name": "Xã Hà Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07966",
                                    "name": "Phường Thanh Vinh"
                                }
                        ]
                    },
                    {
                        "id": "230",
                        "name": "Huyện Đoan Hùng",
                        "Wards": [
                                {
                                    "id": "07969",
                                    "name": "Thị trấn Đoan Hùng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "07975",
                                    "name": "Xã Hùng Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07981",
                                    "name": "Xã Bằng Luân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07984",
                                    "name": "Xã Vân Du",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07987",
                                    "name": "Xã Phú Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07993",
                                    "name": "Xã Minh Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07996",
                                    "name": "Xã Bằng Doãn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "07999",
                                    "name": "Xã Chí Đám",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08005",
                                    "name": "Xã Phúc Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08008",
                                    "name": "Xã Ngọc Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08014",
                                    "name": "Xã Hợp Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08017",
                                    "name": "Xã Sóc Đăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08023",
                                    "name": "Xã Tây Cốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08026",
                                    "name": "Xã Yên Kiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08029",
                                    "name": "Xã Hùng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08032",
                                    "name": "Xã Vụ Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08035",
                                    "name": "Xã Vân Đồn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08038",
                                    "name": "Xã Tiêu Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08041",
                                    "name": "Xã Minh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08044",
                                    "name": "Xã Minh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08047",
                                    "name": "Xã Chân Mộng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08050",
                                    "name": "Xã Ca Đình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "231",
                        "name": "Huyện Hạ Hoà",
                        "Wards": [
                                {
                                    "id": "08053",
                                    "name": "Thị trấn Hạ Hoà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08056",
                                    "name": "Xã Đại Phạm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08062",
                                    "name": "Xã Đan Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08065",
                                    "name": "Xã Hà Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08071",
                                    "name": "Xã Tứ Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08080",
                                    "name": "Xã Hiền Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08089",
                                    "name": "Xã Phương Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08092",
                                    "name": "Xã Gia Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08095",
                                    "name": "Xã Ấm Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08104",
                                    "name": "Xã Hương Xạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08110",
                                    "name": "Xã Xuân Áng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08113",
                                    "name": "Xã Yên Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08119",
                                    "name": "Xã Minh Hạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08122",
                                    "name": "Xã Lang Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08125",
                                    "name": "Xã Bằng Giã",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08128",
                                    "name": "Xã Yên Luật",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08131",
                                    "name": "Xã Vô Tranh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08134",
                                    "name": "Xã Văn Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08140",
                                    "name": "Xã Minh Côi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08143",
                                    "name": "Xã Vĩnh Chân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "232",
                        "name": "Huyện Thanh Ba",
                        "Wards": [
                                {
                                    "id": "08152",
                                    "name": "Thị trấn Thanh Ba",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08156",
                                    "name": "Xã Vân Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08158",
                                    "name": "Xã Đông Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08161",
                                    "name": "Xã Đại An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08164",
                                    "name": "Xã Hanh Cù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08170",
                                    "name": "Xã Đồng Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08173",
                                    "name": "Xã Quảng Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08179",
                                    "name": "Xã Ninh Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08194",
                                    "name": "Xã Võ Lao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08197",
                                    "name": "Xã Khải Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08200",
                                    "name": "Xã Mạn Lạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08203",
                                    "name": "Xã Hoàng Cương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08206",
                                    "name": "Xã Chí Tiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08209",
                                    "name": "Xã Đông Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08215",
                                    "name": "Xã Sơn Cương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08218",
                                    "name": "Xã Thanh Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08221",
                                    "name": "Xã Đỗ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08224",
                                    "name": "Xã Đỗ Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08227",
                                    "name": "Xã Lương Lỗ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "233",
                        "name": "Huyện Phù Ninh",
                        "Wards": [
                                {
                                    "id": "08230",
                                    "name": "Thị trấn Phong Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08233",
                                    "name": "Xã Phú Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08234",
                                    "name": "Xã Lệ Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08236",
                                    "name": "Xã Liên Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08239",
                                    "name": "Xã Trạm Thản",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08242",
                                    "name": "Xã Trị Quận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08245",
                                    "name": "Xã Trung Giáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08248",
                                    "name": "Xã Tiên Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08251",
                                    "name": "Xã Hạ Giáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08254",
                                    "name": "Xã Bảo Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08257",
                                    "name": "Xã Phú Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08260",
                                    "name": "Xã Gia Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08263",
                                    "name": "Xã Tiên Du",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08266",
                                    "name": "Xã Phú Nham",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08272",
                                    "name": "Xã An Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08275",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08278",
                                    "name": "Xã Phù Ninh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "234",
                        "name": "Huyện Yên Lập",
                        "Wards": [
                                {
                                    "id": "08290",
                                    "name": "Thị trấn Yên Lập",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08293",
                                    "name": "Xã Mỹ Lung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08296",
                                    "name": "Xã Mỹ Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08299",
                                    "name": "Xã Lương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08302",
                                    "name": "Xã Xuân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08305",
                                    "name": "Xã Xuân Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08308",
                                    "name": "Xã Xuân Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08311",
                                    "name": "Xã Trung Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08314",
                                    "name": "Xã Hưng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08317",
                                    "name": "Xã Nga Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08320",
                                    "name": "Xã Đồng Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08323",
                                    "name": "Xã Thượng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08326",
                                    "name": "Xã Đồng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08329",
                                    "name": "Xã Phúc Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08332",
                                    "name": "Xã Minh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08335",
                                    "name": "Xã Ngọc Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08338",
                                    "name": "Xã Ngọc Đồng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "235",
                        "name": "Huyện Cẩm Khê",
                        "Wards": [
                                {
                                    "id": "08341",
                                    "name": "Thị trấn Cẩm Khê",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08344",
                                    "name": "Xã Tiên Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08347",
                                    "name": "Xã Tuy Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08350",
                                    "name": "Xã Ngô Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08353",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08356",
                                    "name": "Xã Phượng Vĩ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08362",
                                    "name": "Xã Thụy Liễu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08374",
                                    "name": "Xã Tùng Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08377",
                                    "name": "Xã Tam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08380",
                                    "name": "Xã Văn Bán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08383",
                                    "name": "Xã Cấp Dẫn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08389",
                                    "name": "Xã Xương Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08392",
                                    "name": "Xã Phú Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08395",
                                    "name": "Xã Sơn Tình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08398",
                                    "name": "Xã Yên Tập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08401",
                                    "name": "Xã Hương Lung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08404",
                                    "name": "Xã Tạ Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08407",
                                    "name": "Xã Phú Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08413",
                                    "name": "Xã Chương Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08416",
                                    "name": "Xã Hùng Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08419",
                                    "name": "Xã Văn Khúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08422",
                                    "name": "Xã Yên Dưỡng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08428",
                                    "name": "Xã Điêu Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08431",
                                    "name": "Xã Đồng Lương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "236",
                        "name": "Huyện Tam Nông",
                        "Wards": [
                                {
                                    "id": "08434",
                                    "name": "Thị trấn Hưng Hoá",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08440",
                                    "name": "Xã Hiền Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08443",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08446",
                                    "name": "Xã Thanh Uyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08461",
                                    "name": "Xã Lam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08467",
                                    "name": "Xã Vạn Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08470",
                                    "name": "Xã Quang Húc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08473",
                                    "name": "Xã Hương Nộn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08476",
                                    "name": "Xã Tề Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08479",
                                    "name": "Xã Thọ Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08482",
                                    "name": "Xã Dị Nậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08491",
                                    "name": "Xã Dân Quyền",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "237",
                        "name": "Huyện Lâm Thao",
                        "Wards": [
                                {
                                    "id": "08494",
                                    "name": "Thị trấn Lâm Thao",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08497",
                                    "name": "Xã Tiên Kiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08498",
                                    "name": "Thị trấn Hùng Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08500",
                                    "name": "Xã Xuân Lũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08509",
                                    "name": "Xã Xuân Huy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08512",
                                    "name": "Xã Thạch Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08518",
                                    "name": "Xã Sơn Vi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08521",
                                    "name": "Xã Phùng Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08527",
                                    "name": "Xã Cao Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08533",
                                    "name": "Xã Vĩnh Lại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08536",
                                    "name": "Xã Tứ Xã",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08539",
                                    "name": "Xã Bản Nguyên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "238",
                        "name": "Huyện Thanh Sơn",
                        "Wards": [
                                {
                                    "id": "08542",
                                    "name": "Thị trấn Thanh Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08563",
                                    "name": "Xã Sơn Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08572",
                                    "name": "Xã Địch Quả",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08575",
                                    "name": "Xã Giáp Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08581",
                                    "name": "Xã Thục Luyện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08584",
                                    "name": "Xã Võ Miếu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08587",
                                    "name": "Xã Thạch Khoán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08602",
                                    "name": "Xã Cự Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08605",
                                    "name": "Xã Tất Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08611",
                                    "name": "Xã Văn Miếu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08614",
                                    "name": "Xã Cự Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08623",
                                    "name": "Xã Thắng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08629",
                                    "name": "Xã Tân Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08632",
                                    "name": "Xã Hương Cần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08635",
                                    "name": "Xã Khả Cửu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08638",
                                    "name": "Xã Đông Cửu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08641",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08644",
                                    "name": "Xã Yên Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08647",
                                    "name": "Xã Yên Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08650",
                                    "name": "Xã Thượng Cửu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08653",
                                    "name": "Xã Lương Nha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08656",
                                    "name": "Xã Yên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08659",
                                    "name": "Xã Tinh Nhuệ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "239",
                        "name": "Huyện Thanh Thuỷ",
                        "Wards": [
                                {
                                    "id": "08662",
                                    "name": "Xã Đào Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08665",
                                    "name": "Xã Thạch Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08668",
                                    "name": "Xã Xuân Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08671",
                                    "name": "Xã Tân Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08674",
                                    "name": "Thị trấn Thanh Thủy",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08677",
                                    "name": "Xã Sơn Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08680",
                                    "name": "Xã Bảo Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08683",
                                    "name": "Xã Đoan Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08686",
                                    "name": "Xã Đồng Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08689",
                                    "name": "Xã Hoàng Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08701",
                                    "name": "Xã Tu Vũ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "240",
                        "name": "Huyện Tân Sơn",
                        "Wards": [
                                {
                                    "id": "08545",
                                    "name": "Xã Thu Cúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08548",
                                    "name": "Xã Thạch Kiệt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08551",
                                    "name": "Xã Thu Ngạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08554",
                                    "name": "Xã Kiệt Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08557",
                                    "name": "Xã Đồng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08560",
                                    "name": "Xã Lai Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08566",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08569",
                                    "name": "Xã Mỹ Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08578",
                                    "name": "Xã Tân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08590",
                                    "name": "Xã Xuân Đài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08593",
                                    "name": "Xã Minh Đài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08596",
                                    "name": "Xã Văn Luông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08599",
                                    "name": "Xã Xuân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08608",
                                    "name": "Xã Long Cốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08617",
                                    "name": "Xã Kim Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08620",
                                    "name": "Xã Tam Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08626",
                                    "name": "Xã Vinh Tiền",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "26",
            "name": "Tỉnh Vĩnh Phúc",
            "districts": [
                    {
                        "id": "243",
                        "name": "Thành phố Vĩnh Yên",
                        "Wards": [
                                {
                                    "id": "08707",
                                    "name": "Phường Tích Sơn"
                                },
                                {
                                    "id": "08710",
                                    "name": "Phường Liên Bảo"
                                },
                                {
                                    "id": "08713",
                                    "name": "Phường Hội Hợp"
                                },
                                {
                                    "id": "08716",
                                    "name": "Phường Đống Đa"
                                },
                                {
                                    "id": "08719",
                                    "name": "Phường Ngô Quyền"
                                },
                                {
                                    "id": "08722",
                                    "name": "Phường Đồng Tâm"
                                },
                                {
                                    "id": "08725",
                                    "name": "Xã Định Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08728",
                                    "name": "Phường Khai Quang"
                                },
                                {
                                    "id": "08731",
                                    "name": "Xã Thanh Trù",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "244",
                        "name": "Thành phố Phúc Yên",
                        "Wards": [
                                {
                                    "id": "08734",
                                    "name": "Phường Trưng Trắc"
                                },
                                {
                                    "id": "08737",
                                    "name": "Phường Hùng Vương"
                                },
                                {
                                    "id": "08740",
                                    "name": "Phường Trưng Nhị"
                                },
                                {
                                    "id": "08743",
                                    "name": "Phường Phúc Thắng"
                                },
                                {
                                    "id": "08746",
                                    "name": "Phường Xuân Hoà"
                                },
                                {
                                    "id": "08747",
                                    "name": "Phường Đồng Xuân"
                                },
                                {
                                    "id": "08749",
                                    "name": "Xã Ngọc Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08752",
                                    "name": "Xã Cao Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08755",
                                    "name": "Phường Nam Viêm"
                                },
                                {
                                    "id": "08758",
                                    "name": "Phường Tiền Châu"
                                }
                        ]
                    },
                    {
                        "id": "246",
                        "name": "Huyện Lập Thạch",
                        "Wards": [
                                {
                                    "id": "08761",
                                    "name": "Thị trấn Lập Thạch",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08764",
                                    "name": "Xã Quang Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08767",
                                    "name": "Xã Ngọc Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08770",
                                    "name": "Xã Hợp Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08785",
                                    "name": "Xã Bắc Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08788",
                                    "name": "Xã Thái Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08789",
                                    "name": "Thị trấn Hoa Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08791",
                                    "name": "Xã Liễn Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08794",
                                    "name": "Xã Xuân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08797",
                                    "name": "Xã Vân Trục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08812",
                                    "name": "Xã Liên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08815",
                                    "name": "Xã Tử Du",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08833",
                                    "name": "Xã Bàn Giản",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08836",
                                    "name": "Xã Xuân Lôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08839",
                                    "name": "Xã Đồng Ích",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08842",
                                    "name": "Xã Tiên Lữ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08845",
                                    "name": "Xã Văn Quán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08857",
                                    "name": "Xã Đình Chu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08863",
                                    "name": "Xã Triệu Đề",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08866",
                                    "name": "Xã Sơn Đông",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "247",
                        "name": "Huyện Tam Dương",
                        "Wards": [
                                {
                                    "id": "08869",
                                    "name": "Thị trấn Hợp Hòa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08872",
                                    "name": "Xã Hoàng Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08875",
                                    "name": "Xã Đồng Tĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08878",
                                    "name": "Xã Kim Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08881",
                                    "name": "Xã Hướng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08884",
                                    "name": "Xã Đạo Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08887",
                                    "name": "Xã An Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08890",
                                    "name": "Xã Thanh Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08893",
                                    "name": "Xã Duy Phiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08896",
                                    "name": "Xã Hoàng Đan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08899",
                                    "name": "Xã Hoàng Lâu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08902",
                                    "name": "Xã Vân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08905",
                                    "name": "Xã Hợp Thịnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "248",
                        "name": "Huyện Tam Đảo",
                        "Wards": [
                                {
                                    "id": "08908",
                                    "name": "Thị trấn Tam Đảo",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08911",
                                    "name": "Thị trấn Hợp Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08914",
                                    "name": "Xã Đạo Trù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08917",
                                    "name": "Xã Yên Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08920",
                                    "name": "Xã Bồ Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08923",
                                    "name": "Thị trấn Đại Đình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08926",
                                    "name": "Xã Tam Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08929",
                                    "name": "Xã Hồ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08932",
                                    "name": "Xã Minh Quang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "249",
                        "name": "Huyện Bình Xuyên",
                        "Wards": [
                                {
                                    "id": "08935",
                                    "name": "Thị trấn Hương Canh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08936",
                                    "name": "Thị trấn Gia Khánh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08938",
                                    "name": "Xã Trung Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08944",
                                    "name": "Thị trấn Bá Hiến",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08947",
                                    "name": "Xã Thiện Kế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08950",
                                    "name": "Xã Hương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08953",
                                    "name": "Xã Tam Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08956",
                                    "name": "Xã Quất Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08959",
                                    "name": "Xã Sơn Lôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08962",
                                    "name": "Thị trấn Đạo Đức",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08965",
                                    "name": "Xã Tân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08968",
                                    "name": "Thị trấn Thanh Lãng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08971",
                                    "name": "Xã Phú Xuân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "251",
                        "name": "Huyện Yên Lạc",
                        "Wards": [
                                {
                                    "id": "09025",
                                    "name": "Thị trấn Yên Lạc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09028",
                                    "name": "Xã Đồng Cương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09031",
                                    "name": "Xã Đồng Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09034",
                                    "name": "Xã Bình Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09037",
                                    "name": "Xã Trung Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09040",
                                    "name": "Xã Tề Lỗ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09043",
                                    "name": "Xã Tam Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09046",
                                    "name": "Xã Yên Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09049",
                                    "name": "Xã Văn Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09052",
                                    "name": "Xã Nguyệt Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09055",
                                    "name": "Xã Yên Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09058",
                                    "name": "Xã Hồng Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09061",
                                    "name": "Xã Trung Kiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09064",
                                    "name": "Xã Liên Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09067",
                                    "name": "Xã Đại Tự",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09070",
                                    "name": "Xã Hồng Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09073",
                                    "name": "Xã Trung Hà",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "252",
                        "name": "Huyện Vĩnh Tường",
                        "Wards": [
                                {
                                    "id": "09076",
                                    "name": "Thị trấn Vĩnh Tường",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09079",
                                    "name": "Xã Kim Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09082",
                                    "name": "Xã Yên Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09085",
                                    "name": "Xã Chấn Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09088",
                                    "name": "Xã Nghĩa Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09091",
                                    "name": "Xã Yên Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09094",
                                    "name": "Xã Việt Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09097",
                                    "name": "Xã Bồ Sao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09100",
                                    "name": "Xã Đại Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09103",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09106",
                                    "name": "Xã Lũng Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09109",
                                    "name": "Xã Cao Đại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09112",
                                    "name": "Thị Trấn Thổ Tang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09115",
                                    "name": "Xã Vĩnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09118",
                                    "name": "Xã Bình Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09124",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09127",
                                    "name": "Xã Thượng Trưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09130",
                                    "name": "Xã Vũ Di",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09133",
                                    "name": "Xã Lý Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09136",
                                    "name": "Xã Tuân Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09139",
                                    "name": "Xã Vân Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09142",
                                    "name": "Xã Tam Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09145",
                                    "name": "Thị trấn Tứ Trưng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09148",
                                    "name": "Xã Ngũ Kiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09151",
                                    "name": "Xã An Tường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09154",
                                    "name": "Xã Vĩnh Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09157",
                                    "name": "Xã Phú Đa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09160",
                                    "name": "Xã Vĩnh Ninh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "253",
                        "name": "Huyện Sông Lô",
                        "Wards": [
                                {
                                    "id": "08773",
                                    "name": "Xã Lãng Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08776",
                                    "name": "Xã Quang Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08779",
                                    "name": "Xã Bạch Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08782",
                                    "name": "Xã Hải Lựu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08800",
                                    "name": "Xã Đồng Quế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08803",
                                    "name": "Xã Nhân Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08806",
                                    "name": "Xã Đôn Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08809",
                                    "name": "Xã Phương Khoan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08818",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08821",
                                    "name": "Xã Nhạo Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08824",
                                    "name": "Thị trấn Tam Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "08827",
                                    "name": "Xã Như Thụy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08830",
                                    "name": "Xã Yên Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08848",
                                    "name": "Xã Đồng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08851",
                                    "name": "Xã Tứ Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08854",
                                    "name": "Xã Đức Bác",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "08860",
                                    "name": "Xã Cao Phong",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "27",
            "name": "Tỉnh Bắc Ninh",
            "districts": [
                    {
                        "id": "256",
                        "name": "Thành phố Bắc Ninh",
                        "Wards": [
                                {
                                    "id": "09163",
                                    "name": "Phường Vũ Ninh"
                                },
                                {
                                    "id": "09166",
                                    "name": "Phường Đáp Cầu"
                                },
                                {
                                    "id": "09169",
                                    "name": "Phường Thị Cầu"
                                },
                                {
                                    "id": "09172",
                                    "name": "Phường Kinh Bắc"
                                },
                                {
                                    "id": "09175",
                                    "name": "Phường Vệ An"
                                },
                                {
                                    "id": "09178",
                                    "name": "Phường Tiền An"
                                },
                                {
                                    "id": "09181",
                                    "name": "Phường Đại Phúc"
                                },
                                {
                                    "id": "09184",
                                    "name": "Phường Ninh Xá"
                                },
                                {
                                    "id": "09187",
                                    "name": "Phường Suối Hoa"
                                },
                                {
                                    "id": "09190",
                                    "name": "Phường Võ Cường"
                                },
                                {
                                    "id": "09214",
                                    "name": "Phường Hòa Long"
                                },
                                {
                                    "id": "09226",
                                    "name": "Phường Vạn An"
                                },
                                {
                                    "id": "09235",
                                    "name": "Phường Khúc Xuyên"
                                },
                                {
                                    "id": "09244",
                                    "name": "Phường Phong Khê"
                                },
                                {
                                    "id": "09256",
                                    "name": "Phường Kim Chân"
                                },
                                {
                                    "id": "09271",
                                    "name": "Phường Vân Dương"
                                },
                                {
                                    "id": "09286",
                                    "name": "Phường Nam Sơn"
                                },
                                {
                                    "id": "09325",
                                    "name": "Phường Khắc Niệm"
                                },
                                {
                                    "id": "09331",
                                    "name": "Phường Hạp Lĩnh"
                                }
                        ]
                    },
                    {
                        "id": "258",
                        "name": "Huyện Yên Phong",
                        "Wards": [
                                {
                                    "id": "09193",
                                    "name": "Thị trấn Chờ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09196",
                                    "name": "Xã Dũng Liệt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09199",
                                    "name": "Xã Tam Đa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09202",
                                    "name": "Xã Tam Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09205",
                                    "name": "Xã Yên Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09208",
                                    "name": "Xã Thụy Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09211",
                                    "name": "Xã Hòa Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09217",
                                    "name": "Xã Đông Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09220",
                                    "name": "Xã Yên Phụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09223",
                                    "name": "Xã Trung Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09229",
                                    "name": "Xã Đông Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09232",
                                    "name": "Xã Long Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09238",
                                    "name": "Xã Văn Môn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09241",
                                    "name": "Xã Đông Thọ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "259",
                        "name": "Huyện Quế Võ",
                        "Wards": [
                                {
                                    "id": "09247",
                                    "name": "Thị trấn Phố Mới",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09250",
                                    "name": "Xã Việt Thống",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09253",
                                    "name": "Xã Đại Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09259",
                                    "name": "Xã Nhân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09262",
                                    "name": "Xã Bằng An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09265",
                                    "name": "Xã Phương Liễu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09268",
                                    "name": "Xã Quế Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09274",
                                    "name": "Xã Phù Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09277",
                                    "name": "Xã Phù Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09280",
                                    "name": "Xã Phượng Mao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09283",
                                    "name": "Xã Việt Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09289",
                                    "name": "Xã Ngọc Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09292",
                                    "name": "Xã Châu Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09295",
                                    "name": "Xã Bồng Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09298",
                                    "name": "Xã Cách Bi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09301",
                                    "name": "Xã Đào Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09304",
                                    "name": "Xã Yên Giả",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09307",
                                    "name": "Xã Mộ Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09310",
                                    "name": "Xã Đức Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09313",
                                    "name": "Xã Chi Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09316",
                                    "name": "Xã Hán Quảng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "260",
                        "name": "Huyện Tiên Du",
                        "Wards": [
                                {
                                    "id": "09319",
                                    "name": "Thị trấn Lim",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09322",
                                    "name": "Xã Phú Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09328",
                                    "name": "Xã Nội Duệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09334",
                                    "name": "Xã Liên Bão",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09337",
                                    "name": "Xã Hiên Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09340",
                                    "name": "Xã Hoàn Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09343",
                                    "name": "Xã Lạc Vệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09346",
                                    "name": "Xã Việt Đoàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09349",
                                    "name": "Xã Phật Tích",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09352",
                                    "name": "Xã Tân Chi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09355",
                                    "name": "Xã Đại Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09358",
                                    "name": "Xã Tri Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09361",
                                    "name": "Xã Minh Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09364",
                                    "name": "Xã Cảnh Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "261",
                        "name": "Thị xã Từ Sơn",
                        "Wards": [
                                {
                                    "id": "09367",
                                    "name": "Phường Đông Ngàn"
                                },
                                {
                                    "id": "09370",
                                    "name": "Xã Tam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09373",
                                    "name": "Xã Hương Mạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09376",
                                    "name": "Xã Tương Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09379",
                                    "name": "Xã Phù Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09382",
                                    "name": "Phường Đồng Kỵ"
                                },
                                {
                                    "id": "09383",
                                    "name": "Phường Trang Hạ"
                                },
                                {
                                    "id": "09385",
                                    "name": "Phường Đồng Nguyên"
                                },
                                {
                                    "id": "09388",
                                    "name": "Phường Châu Khê"
                                },
                                {
                                    "id": "09391",
                                    "name": "Phường Tân Hồng"
                                },
                                {
                                    "id": "09394",
                                    "name": "Phường Đình Bảng"
                                },
                                {
                                    "id": "09397",
                                    "name": "Xã Phù Chẩn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "262",
                        "name": "Huyện Thuận Thành",
                        "Wards": [
                                {
                                    "id": "09400",
                                    "name": "Thị trấn Hồ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09403",
                                    "name": "Xã Hoài Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09406",
                                    "name": "Xã Đại Đồng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09409",
                                    "name": "Xã Mão Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09412",
                                    "name": "Xã Song Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09415",
                                    "name": "Xã Đình Tổ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09418",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09421",
                                    "name": "Xã Trí Quả",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09424",
                                    "name": "Xã Gia Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09427",
                                    "name": "Xã Thanh Khương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09430",
                                    "name": "Xã Trạm Lộ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09433",
                                    "name": "Xã Xuân Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09436",
                                    "name": "Xã Hà Mãn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09439",
                                    "name": "Xã Ngũ Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09442",
                                    "name": "Xã Nguyệt Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09445",
                                    "name": "Xã Ninh Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09448",
                                    "name": "Xã Nghĩa Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09451",
                                    "name": "Xã Song Liễu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "263",
                        "name": "Huyện Gia Bình",
                        "Wards": [
                                {
                                    "id": "09454",
                                    "name": "Thị trấn Gia Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09457",
                                    "name": "Xã Vạn Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09460",
                                    "name": "Xã Thái Bảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09463",
                                    "name": "Xã Giang Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09466",
                                    "name": "Xã Cao Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09469",
                                    "name": "Xã Đại Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09472",
                                    "name": "Xã Song Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09475",
                                    "name": "Xã Bình Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09478",
                                    "name": "Xã Lãng Ngâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09481",
                                    "name": "Xã Nhân Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09484",
                                    "name": "Xã Xuân Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09487",
                                    "name": "Xã Đông Cứu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09490",
                                    "name": "Xã Đại Bái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09493",
                                    "name": "Xã Quỳnh Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "264",
                        "name": "Huyện Lương Tài",
                        "Wards": [
                                {
                                    "id": "09496",
                                    "name": "Thị trấn Thứa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "09499",
                                    "name": "Xã An Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09502",
                                    "name": "Xã Trung Kênh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09505",
                                    "name": "Xã Phú Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09508",
                                    "name": "Xã Mỹ Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09511",
                                    "name": "Xã Tân Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09514",
                                    "name": "Xã Quảng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09517",
                                    "name": "Xã Trừng Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09520",
                                    "name": "Xã Lai Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09523",
                                    "name": "Xã Trung Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09526",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09529",
                                    "name": "Xã Bình Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09532",
                                    "name": "Xã Phú Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "09535",
                                    "name": "Xã Lâm Thao",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "30",
            "name": "Tỉnh Hải Dương",
            "districts": [
                    {
                        "id": "288",
                        "name": "Thành phố Hải Dương",
                        "Wards": [
                                {
                                    "id": "10507",
                                    "name": "Phường Cẩm Thượng"
                                },
                                {
                                    "id": "10510",
                                    "name": "Phường Bình Hàn"
                                },
                                {
                                    "id": "10513",
                                    "name": "Phường Ngọc Châu"
                                },
                                {
                                    "id": "10514",
                                    "name": "Phường Nhị Châu"
                                },
                                {
                                    "id": "10516",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "10519",
                                    "name": "Phường Nguyễn Trãi"
                                },
                                {
                                    "id": "10522",
                                    "name": "Phường Phạm Ngũ Lão"
                                },
                                {
                                    "id": "10525",
                                    "name": "Phường Trần Hưng Đạo"
                                },
                                {
                                    "id": "10528",
                                    "name": "Phường Trần Phú"
                                },
                                {
                                    "id": "10531",
                                    "name": "Phường Thanh Bình"
                                },
                                {
                                    "id": "10532",
                                    "name": "Phường Tân Bình"
                                },
                                {
                                    "id": "10534",
                                    "name": "Phường Lê Thanh Nghị"
                                },
                                {
                                    "id": "10537",
                                    "name": "Phường Hải Tân"
                                },
                                {
                                    "id": "10540",
                                    "name": "Phường Tứ Minh"
                                },
                                {
                                    "id": "10543",
                                    "name": "Phường Việt Hoà"
                                },
                                {
                                    "id": "10660",
                                    "name": "Phường Ái Quốc"
                                },
                                {
                                    "id": "10663",
                                    "name": "Xã An Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10672",
                                    "name": "Phường Nam Đồng"
                                },
                                {
                                    "id": "10822",
                                    "name": "Xã Quyết Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10837",
                                    "name": "Xã Tiền Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11002",
                                    "name": "Phường Thạch Khôi"
                                },
                                {
                                    "id": "11005",
                                    "name": "Xã Liên Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11011",
                                    "name": "Phường Tân Hưng"
                                },
                                {
                                    "id": "11017",
                                    "name": "Xã Gia Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11077",
                                    "name": "Xã Ngọc Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "290",
                        "name": "Thành phố Chí Linh",
                        "Wards": [
                                {
                                    "id": "10546",
                                    "name": "Phường Phả Lại"
                                },
                                {
                                    "id": "10549",
                                    "name": "Phường Sao Đỏ"
                                },
                                {
                                    "id": "10552",
                                    "name": "Phường Bến Tắm"
                                },
                                {
                                    "id": "10555",
                                    "name": "Xã Hoàng Hoa Thám",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10558",
                                    "name": "Xã Bắc An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10561",
                                    "name": "Xã Hưng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10564",
                                    "name": "Xã Lê Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10567",
                                    "name": "Phường Hoàng Tiến"
                                },
                                {
                                    "id": "10570",
                                    "name": "Phường Cộng Hoà"
                                },
                                {
                                    "id": "10573",
                                    "name": "Phường Hoàng Tân"
                                },
                                {
                                    "id": "10576",
                                    "name": "Phường Cổ Thành"
                                },
                                {
                                    "id": "10579",
                                    "name": "Phường Văn An"
                                },
                                {
                                    "id": "10582",
                                    "name": "Phường Chí Minh"
                                },
                                {
                                    "id": "10585",
                                    "name": "Phường Văn Đức"
                                },
                                {
                                    "id": "10588",
                                    "name": "Phường Thái Học"
                                },
                                {
                                    "id": "10591",
                                    "name": "Xã Nhân Huệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10594",
                                    "name": "Phường An Lạc"
                                },
                                {
                                    "id": "10600",
                                    "name": "Phường Đồng Lạc"
                                },
                                {
                                    "id": "10603",
                                    "name": "Phường Tân Dân"
                                }
                        ]
                    },
                    {
                        "id": "291",
                        "name": "Huyện Nam Sách",
                        "Wards": [
                                {
                                    "id": "10606",
                                    "name": "Thị trấn Nam Sách",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10609",
                                    "name": "Xã Nam Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10612",
                                    "name": "Xã Nam Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10615",
                                    "name": "Xã Hợp Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10618",
                                    "name": "Xã Hiệp Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10621",
                                    "name": "Xã Thanh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10624",
                                    "name": "Xã Quốc Tuấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10627",
                                    "name": "Xã Nam Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10630",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10633",
                                    "name": "Xã Nam Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10636",
                                    "name": "Xã An Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10639",
                                    "name": "Xã Cộng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10642",
                                    "name": "Xã Thái Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10645",
                                    "name": "Xã An Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10648",
                                    "name": "Xã Phú Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10651",
                                    "name": "Xã Nam Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10654",
                                    "name": "Xã Hồng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10657",
                                    "name": "Xã Đồng Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10666",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "292",
                        "name": "Thị xã Kinh Môn",
                        "Wards": [
                                {
                                    "id": "10675",
                                    "name": "Phường An Lưu"
                                },
                                {
                                    "id": "10678",
                                    "name": "Xã Bạch Đằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10681",
                                    "name": "Phường Thất Hùng"
                                },
                                {
                                    "id": "10684",
                                    "name": "Xã Lê Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10687",
                                    "name": "Xã Hoành Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10693",
                                    "name": "Phường Phạm Thái"
                                },
                                {
                                    "id": "10696",
                                    "name": "Phường Duy Tân"
                                },
                                {
                                    "id": "10699",
                                    "name": "Phường Tân Dân"
                                },
                                {
                                    "id": "10702",
                                    "name": "Phường Minh Tân"
                                },
                                {
                                    "id": "10705",
                                    "name": "Xã Quang Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10708",
                                    "name": "Xã Hiệp Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10714",
                                    "name": "Phường Phú Thứ"
                                },
                                {
                                    "id": "10717",
                                    "name": "Xã Thăng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10720",
                                    "name": "Xã Lạc Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10723",
                                    "name": "Phường An Sinh"
                                },
                                {
                                    "id": "10726",
                                    "name": "Phường Hiệp Sơn"
                                },
                                {
                                    "id": "10729",
                                    "name": "Xã Thượng Quận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10732",
                                    "name": "Phường An Phụ"
                                },
                                {
                                    "id": "10735",
                                    "name": "Phường Hiệp An"
                                },
                                {
                                    "id": "10738",
                                    "name": "Phường Long Xuyên"
                                },
                                {
                                    "id": "10741",
                                    "name": "Phường Thái Thịnh"
                                },
                                {
                                    "id": "10744",
                                    "name": "Phường Hiến Thành"
                                },
                                {
                                    "id": "10747",
                                    "name": "Xã Minh Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "293",
                        "name": "Huyện Kim Thành",
                        "Wards": [
                                {
                                    "id": "10750",
                                    "name": "Thị trấn Phú Thái",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10753",
                                    "name": "Xã Lai Vu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10756",
                                    "name": "Xã Cộng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10759",
                                    "name": "Xã Thượng Vũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10762",
                                    "name": "Xã Cổ Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10768",
                                    "name": "Xã Tuấn Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10771",
                                    "name": "Xã Kim Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10774",
                                    "name": "Xã Phúc Thành A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10777",
                                    "name": "Xã Ngũ Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10780",
                                    "name": "Xã Kim Anh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10783",
                                    "name": "Xã Kim Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10786",
                                    "name": "Xã Kim Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10792",
                                    "name": "Xã Kim Đính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10798",
                                    "name": "Xã Bình Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10801",
                                    "name": "Xã Tam Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10804",
                                    "name": "Xã Đồng Cẩm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10807",
                                    "name": "Xã Liên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10810",
                                    "name": "Xã Đại Đức",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "294",
                        "name": "Huyện Thanh Hà",
                        "Wards": [
                                {
                                    "id": "10813",
                                    "name": "Thị trấn Thanh Hà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10816",
                                    "name": "Xã Hồng Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10819",
                                    "name": "Xã Việt Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10825",
                                    "name": "Xã Tân Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10828",
                                    "name": "Xã Cẩm Chế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10831",
                                    "name": "Xã Thanh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10834",
                                    "name": "Xã Thanh Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10840",
                                    "name": "Xã Tân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10843",
                                    "name": "Xã Liên Mạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10846",
                                    "name": "Xã Thanh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10849",
                                    "name": "Xã Thanh Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10852",
                                    "name": "Xã Thanh Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10855",
                                    "name": "Xã Thanh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10861",
                                    "name": "Xã Thanh Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10864",
                                    "name": "Xã An Phượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10867",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10876",
                                    "name": "Xã Thanh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10879",
                                    "name": "Xã Thanh Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10882",
                                    "name": "Xã Thanh Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10885",
                                    "name": "Xã Vĩnh Lập",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "295",
                        "name": "Huyện Cẩm Giàng",
                        "Wards": [
                                {
                                    "id": "10888",
                                    "name": "Thị trấn Cẩm Giang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10891",
                                    "name": "Thị trấn Lai Cách",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10894",
                                    "name": "Xã Cẩm Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10897",
                                    "name": "Xã Cẩm Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10900",
                                    "name": "Xã Cẩm Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10903",
                                    "name": "Xã Ngọc Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10906",
                                    "name": "Xã Thạch Lỗi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10909",
                                    "name": "Xã Cẩm Vũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10912",
                                    "name": "Xã Đức Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10918",
                                    "name": "Xã Định Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10924",
                                    "name": "Xã Lương Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10927",
                                    "name": "Xã Cao An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10930",
                                    "name": "Xã Tân Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10933",
                                    "name": "Xã Cẩm Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10936",
                                    "name": "Xã Cẩm Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10939",
                                    "name": "Xã Cẩm Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10942",
                                    "name": "Xã Cẩm Đoài",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "296",
                        "name": "Huyện Bình Giang",
                        "Wards": [
                                {
                                    "id": "10945",
                                    "name": "Thị trấn Kẻ Sặt",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "10951",
                                    "name": "Xã Vĩnh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10954",
                                    "name": "Xã Hùng Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10960",
                                    "name": "Xã Vĩnh Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10963",
                                    "name": "Xã Long Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10966",
                                    "name": "Xã Tân Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10969",
                                    "name": "Xã Thúc Kháng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10972",
                                    "name": "Xã Tân Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10975",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10978",
                                    "name": "Xã Hồng Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10981",
                                    "name": "Xã Thái Học",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10984",
                                    "name": "Xã Cổ Bì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10987",
                                    "name": "Xã Nhân Quyền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10990",
                                    "name": "Xã Thái Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10993",
                                    "name": "Xã Thái Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "10996",
                                    "name": "Xã Bình Xuyên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "297",
                        "name": "Huyện Gia Lộc",
                        "Wards": [
                                {
                                    "id": "10999",
                                    "name": "Thị trấn Gia Lộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11008",
                                    "name": "Xã Thống Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11020",
                                    "name": "Xã Yết Kiêu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11029",
                                    "name": "Xã Gia Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11032",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11035",
                                    "name": "Xã Gia Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11038",
                                    "name": "Xã Gia Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11041",
                                    "name": "Xã Lê Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11044",
                                    "name": "Xã Toàn Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11047",
                                    "name": "Xã Hoàng Diệu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11050",
                                    "name": "Xã Hồng Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11053",
                                    "name": "Xã Phạm Trấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11056",
                                    "name": "Xã Đoàn Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11059",
                                    "name": "Xã Thống Kênh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11062",
                                    "name": "Xã Quang Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11065",
                                    "name": "Xã Đồng Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11068",
                                    "name": "Xã Nhật Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11071",
                                    "name": "Xã Đức Xương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "298",
                        "name": "Huyện Tứ Kỳ",
                        "Wards": [
                                {
                                    "id": "11074",
                                    "name": "Thị trấn Tứ Kỳ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11083",
                                    "name": "Xã Đại Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11086",
                                    "name": "Xã Hưng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11089",
                                    "name": "Xã Ngọc Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11092",
                                    "name": "Xã Bình Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11095",
                                    "name": "Xã Chí Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11098",
                                    "name": "Xã Tái Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11101",
                                    "name": "Xã Quang Phục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11110",
                                    "name": "Xã Dân Chủ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11113",
                                    "name": "Xã Tân Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11116",
                                    "name": "Xã Quang Khải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11119",
                                    "name": "Xã Đại Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11122",
                                    "name": "Xã Quảng Nghiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11125",
                                    "name": "Xã An Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11128",
                                    "name": "Xã Minh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11131",
                                    "name": "Xã Văn Tố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11134",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11137",
                                    "name": "Xã Phượng Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11140",
                                    "name": "Xã Cộng Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11143",
                                    "name": "Xã Tiên Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11146",
                                    "name": "Xã Nguyên Giáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11149",
                                    "name": "Xã Hà Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11152",
                                    "name": "Xã Hà Thanh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "299",
                        "name": "Huyện Ninh Giang",
                        "Wards": [
                                {
                                    "id": "11155",
                                    "name": "Thị trấn Ninh Giang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11161",
                                    "name": "Xã Ứng Hoè",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11164",
                                    "name": "Xã Nghĩa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11167",
                                    "name": "Xã Hồng Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11173",
                                    "name": "Xã An Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11176",
                                    "name": "Xã Vạn Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11179",
                                    "name": "Xã Tân Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11185",
                                    "name": "Xã Vĩnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11188",
                                    "name": "Xã Đông Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11197",
                                    "name": "Xã Tân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11200",
                                    "name": "Xã Ninh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11203",
                                    "name": "Xã Đồng Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11206",
                                    "name": "Xã Tân Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11209",
                                    "name": "Xã Kiến Quốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11215",
                                    "name": "Xã Hồng Dụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11218",
                                    "name": "Xã Văn Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11224",
                                    "name": "Xã Hồng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11227",
                                    "name": "Xã Hiệp Lực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11230",
                                    "name": "Xã Hồng Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11233",
                                    "name": "Xã Hưng Long",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "300",
                        "name": "Huyện Thanh Miện",
                        "Wards": [
                                {
                                    "id": "11239",
                                    "name": "Thị trấn Thanh Miện",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11242",
                                    "name": "Xã Thanh Tùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11245",
                                    "name": "Xã Phạm Kha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11248",
                                    "name": "Xã Ngô Quyền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11251",
                                    "name": "Xã Đoàn Tùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11254",
                                    "name": "Xã Hồng Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11257",
                                    "name": "Xã Tân Trào",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11260",
                                    "name": "Xã Lam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11263",
                                    "name": "Xã Đoàn Kết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11266",
                                    "name": "Xã Lê Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11269",
                                    "name": "Xã Tứ Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11275",
                                    "name": "Xã Ngũ Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11278",
                                    "name": "Xã Cao Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11281",
                                    "name": "Xã Chi Lăng Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11284",
                                    "name": "Xã Chi Lăng Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11287",
                                    "name": "Xã Thanh Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11293",
                                    "name": "Xã Hồng Phong",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "31",
            "name": "Thành phố Hải Phòng",
            "districts": [
                    {
                        "id": "303",
                        "name": "Quận Hồng Bàng",
                        "Wards": [
                                {
                                    "id": "11296",
                                    "name": "Phường Quán Toan"
                                },
                                {
                                    "id": "11299",
                                    "name": "Phường Hùng Vương"
                                },
                                {
                                    "id": "11302",
                                    "name": "Phường Sở Dầu"
                                },
                                {
                                    "id": "11305",
                                    "name": "Phường Thượng Lý"
                                },
                                {
                                    "id": "11308",
                                    "name": "Phường Hạ Lý"
                                },
                                {
                                    "id": "11311",
                                    "name": "Phường Minh Khai"
                                },
                                {
                                    "id": "11314",
                                    "name": "Phường Trại Chuối"
                                },
                                {
                                    "id": "11320",
                                    "name": "Phường Hoàng Văn Thụ"
                                },
                                {
                                    "id": "11323",
                                    "name": "Phường Phan Bội Châu"
                                }
                        ]
                    },
                    {
                        "id": "304",
                        "name": "Quận Ngô Quyền",
                        "Wards": [
                                {
                                    "id": "11329",
                                    "name": "Phường Máy Chai"
                                },
                                {
                                    "id": "11332",
                                    "name": "Phường Máy Tơ"
                                },
                                {
                                    "id": "11335",
                                    "name": "Phường Vạn Mỹ"
                                },
                                {
                                    "id": "11338",
                                    "name": "Phường Cầu Tre"
                                },
                                {
                                    "id": "11341",
                                    "name": "Phường Lạc Viên"
                                },
                                {
                                    "id": "11347",
                                    "name": "Phường Gia Viên"
                                },
                                {
                                    "id": "11350",
                                    "name": "Phường Đông Khê"
                                },
                                {
                                    "id": "11353",
                                    "name": "Phường Cầu Đất"
                                },
                                {
                                    "id": "11356",
                                    "name": "Phường Lê Lợi"
                                },
                                {
                                    "id": "11359",
                                    "name": "Phường Đằng Giang"
                                },
                                {
                                    "id": "11362",
                                    "name": "Phường Lạch Tray"
                                },
                                {
                                    "id": "11365",
                                    "name": "Phường Đổng Quốc Bình"
                                }
                        ]
                    },
                    {
                        "id": "305",
                        "name": "Quận Lê Chân",
                        "Wards": [
                                {
                                    "id": "11368",
                                    "name": "Phường Cát Dài"
                                },
                                {
                                    "id": "11371",
                                    "name": "Phường An Biên"
                                },
                                {
                                    "id": "11374",
                                    "name": "Phường Lam Sơn"
                                },
                                {
                                    "id": "11377",
                                    "name": "Phường An Dương"
                                },
                                {
                                    "id": "11380",
                                    "name": "Phường Trần Nguyên Hãn"
                                },
                                {
                                    "id": "11383",
                                    "name": "Phường Hồ Nam"
                                },
                                {
                                    "id": "11386",
                                    "name": "Phường Trại Cau"
                                },
                                {
                                    "id": "11389",
                                    "name": "Phường Dư Hàng"
                                },
                                {
                                    "id": "11392",
                                    "name": "Phường Hàng Kênh"
                                },
                                {
                                    "id": "11395",
                                    "name": "Phường Đông Hải"
                                },
                                {
                                    "id": "11398",
                                    "name": "Phường Niệm Nghĩa"
                                },
                                {
                                    "id": "11401",
                                    "name": "Phường Nghĩa Xá"
                                },
                                {
                                    "id": "11404",
                                    "name": "Phường Dư Hàng Kênh"
                                },
                                {
                                    "id": "11405",
                                    "name": "Phường Kênh Dương"
                                },
                                {
                                    "id": "11407",
                                    "name": "Phường Vĩnh Niệm"
                                }
                        ]
                    },
                    {
                        "id": "306",
                        "name": "Quận Hải An",
                        "Wards": [
                                {
                                    "id": "11410",
                                    "name": "Phường Đông Hải 1"
                                },
                                {
                                    "id": "11411",
                                    "name": "Phường Đông Hải 2"
                                },
                                {
                                    "id": "11413",
                                    "name": "Phường Đằng Lâm"
                                },
                                {
                                    "id": "11414",
                                    "name": "Phường Thành Tô"
                                },
                                {
                                    "id": "11416",
                                    "name": "Phường Đằng Hải"
                                },
                                {
                                    "id": "11419",
                                    "name": "Phường Nam Hải"
                                },
                                {
                                    "id": "11422",
                                    "name": "Phường Cát Bi"
                                },
                                {
                                    "id": "11425",
                                    "name": "Phường Tràng Cát"
                                }
                        ]
                    },
                    {
                        "id": "307",
                        "name": "Quận Kiến An",
                        "Wards": [
                                {
                                    "id": "11428",
                                    "name": "Phường Quán Trữ"
                                },
                                {
                                    "id": "11429",
                                    "name": "Phường Lãm Hà"
                                },
                                {
                                    "id": "11431",
                                    "name": "Phường Đồng Hoà"
                                },
                                {
                                    "id": "11434",
                                    "name": "Phường Bắc Sơn"
                                },
                                {
                                    "id": "11437",
                                    "name": "Phường Nam Sơn"
                                },
                                {
                                    "id": "11440",
                                    "name": "Phường Ngọc Sơn"
                                },
                                {
                                    "id": "11443",
                                    "name": "Phường Trần Thành Ngọ"
                                },
                                {
                                    "id": "11446",
                                    "name": "Phường Văn Đẩu"
                                },
                                {
                                    "id": "11449",
                                    "name": "Phường Phù Liễn"
                                },
                                {
                                    "id": "11452",
                                    "name": "Phường Tràng Minh"
                                }
                        ]
                    },
                    {
                        "id": "308",
                        "name": "Quận Đồ Sơn",
                        "Wards": [
                                {
                                    "id": "11455",
                                    "name": "Phường Ngọc Xuyên"
                                },
                                {
                                    "id": "11458",
                                    "name": "Phường Hải Sơn"
                                },
                                {
                                    "id": "11461",
                                    "name": "Phường Vạn Hương"
                                },
                                {
                                    "id": "11465",
                                    "name": "Phường Minh Đức"
                                },
                                {
                                    "id": "11467",
                                    "name": "Phường Bàng La"
                                },
                                {
                                    "id": "11737",
                                    "name": "Phường Hợp Đức"
                                }
                        ]
                    },
                    {
                        "id": "309",
                        "name": "Quận Dương Kinh",
                        "Wards": [
                                {
                                    "id": "11683",
                                    "name": "Phường Đa Phúc"
                                },
                                {
                                    "id": "11686",
                                    "name": "Phường Hưng Đạo"
                                },
                                {
                                    "id": "11689",
                                    "name": "Phường Anh Dũng"
                                },
                                {
                                    "id": "11692",
                                    "name": "Phường Hải Thành"
                                },
                                {
                                    "id": "11707",
                                    "name": "Phường Hoà Nghĩa"
                                },
                                {
                                    "id": "11740",
                                    "name": "Phường Tân Thành"
                                }
                        ]
                    },
                    {
                        "id": "311",
                        "name": "Huyện Thuỷ Nguyên",
                        "Wards": [
                                {
                                    "id": "11470",
                                    "name": "Thị trấn Núi Đèo",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11473",
                                    "name": "Thị trấn Minh Đức",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11476",
                                    "name": "Xã Lại Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11479",
                                    "name": "Xã An Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11482",
                                    "name": "Xã Kỳ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11485",
                                    "name": "Xã Liên Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11488",
                                    "name": "Xã Lưu Kiếm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11491",
                                    "name": "Xã Lưu Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11494",
                                    "name": "Xã Gia Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11497",
                                    "name": "Xã Gia Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11500",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11503",
                                    "name": "Xã Phù Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11506",
                                    "name": "Xã Quảng Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11509",
                                    "name": "Xã Chính Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11512",
                                    "name": "Xã Kênh Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11515",
                                    "name": "Xã Hợp Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11518",
                                    "name": "Xã Cao Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11521",
                                    "name": "Xã Mỹ Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11524",
                                    "name": "Xã Đông Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11527",
                                    "name": "Xã Hoà Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11530",
                                    "name": "Xã Trung Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11533",
                                    "name": "Xã An Lư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11536",
                                    "name": "Xã Thuỷ Triều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11539",
                                    "name": "Xã Ngũ Lão",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11542",
                                    "name": "Xã Phục Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11545",
                                    "name": "Xã Tam Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11548",
                                    "name": "Xã Phả Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11551",
                                    "name": "Xã Lập Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11554",
                                    "name": "Xã Kiền Bái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11557",
                                    "name": "Xã Thiên Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11560",
                                    "name": "Xã Thuỷ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11563",
                                    "name": "Xã Thuỷ Đường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11566",
                                    "name": "Xã Hoàng Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11569",
                                    "name": "Xã Lâm Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11572",
                                    "name": "Xã Hoa Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11575",
                                    "name": "Xã Tân Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11578",
                                    "name": "Xã Dương Quan",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "312",
                        "name": "Huyện An Dương",
                        "Wards": [
                                {
                                    "id": "11581",
                                    "name": "Thị trấn An Dương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11584",
                                    "name": "Xã Lê Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11587",
                                    "name": "Xã Đại Bản",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11590",
                                    "name": "Xã An Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11593",
                                    "name": "Xã Hồng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11596",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11599",
                                    "name": "Xã An Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11602",
                                    "name": "Xã An Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11605",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11608",
                                    "name": "Xã Nam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11611",
                                    "name": "Xã Lê Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11614",
                                    "name": "Xã Đặng Cương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11617",
                                    "name": "Xã Đồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11620",
                                    "name": "Xã Quốc Tuấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11623",
                                    "name": "Xã An Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11626",
                                    "name": "Xã Hồng Thái",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "313",
                        "name": "Huyện An Lão",
                        "Wards": [
                                {
                                    "id": "11629",
                                    "name": "Thị trấn An Lão",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11632",
                                    "name": "Xã Bát Trang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11635",
                                    "name": "Xã Trường Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11638",
                                    "name": "Xã Trường Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11641",
                                    "name": "Xã An Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11644",
                                    "name": "Xã Quang Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11647",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11650",
                                    "name": "Xã Quốc Tuấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11653",
                                    "name": "Xã An Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11656",
                                    "name": "Thị trấn Trường Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11659",
                                    "name": "Xã Tân Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11662",
                                    "name": "Xã Thái Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11665",
                                    "name": "Xã Tân Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11668",
                                    "name": "Xã Mỹ Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11671",
                                    "name": "Xã Chiến Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11674",
                                    "name": "Xã An Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11677",
                                    "name": "Xã An Thái",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "314",
                        "name": "Huyện Kiến Thuỵ",
                        "Wards": [
                                {
                                    "id": "11680",
                                    "name": "Thị trấn Núi Đối",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11695",
                                    "name": "Xã Đông Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11698",
                                    "name": "Xã Thuận Thiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11701",
                                    "name": "Xã Hữu Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11704",
                                    "name": "Xã Đại Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11710",
                                    "name": "Xã Ngũ Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11713",
                                    "name": "Xã Kiến Quốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11716",
                                    "name": "Xã Du Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11719",
                                    "name": "Xã Thuỵ Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11722",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11725",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11728",
                                    "name": "Xã Đại Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11731",
                                    "name": "Xã Ngũ Đoan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11734",
                                    "name": "Xã Tân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11743",
                                    "name": "Xã Tân Trào",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11746",
                                    "name": "Xã Đoàn Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11749",
                                    "name": "Xã Tú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11752",
                                    "name": "Xã Đại Hợp",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "315",
                        "name": "Huyện Tiên Lãng",
                        "Wards": [
                                {
                                    "id": "11755",
                                    "name": "Thị trấn Tiên Lãng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11758",
                                    "name": "Xã Đại Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11761",
                                    "name": "Xã Tiên Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11764",
                                    "name": "Xã Tự Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11770",
                                    "name": "Xã Quyết Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11773",
                                    "name": "Xã Khởi Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11776",
                                    "name": "Xã Tiên Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11779",
                                    "name": "Xã Cấp Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11782",
                                    "name": "Xã Kiến Thiết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11785",
                                    "name": "Xã Đoàn Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11788",
                                    "name": "Xã Bạch Đằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11791",
                                    "name": "Xã Quang Phục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11794",
                                    "name": "Xã Toàn Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11797",
                                    "name": "Xã Tiên Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11800",
                                    "name": "Xã Tiên Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11803",
                                    "name": "Xã Bắc Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11806",
                                    "name": "Xã Nam Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11809",
                                    "name": "Xã Hùng Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11812",
                                    "name": "Xã Tây Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11815",
                                    "name": "Xã Đông Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11821",
                                    "name": "Xã Vinh Quang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "316",
                        "name": "Huyện Vĩnh Bảo",
                        "Wards": [
                                {
                                    "id": "11824",
                                    "name": "Thị trấn Vĩnh Bảo",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11827",
                                    "name": "Xã Dũng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11830",
                                    "name": "Xã Giang Biên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11833",
                                    "name": "Xã Thắng Thuỷ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11836",
                                    "name": "Xã Trung Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11839",
                                    "name": "Xã Việt Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11842",
                                    "name": "Xã Vĩnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11845",
                                    "name": "Xã Vĩnh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11848",
                                    "name": "Xã Hiệp Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11851",
                                    "name": "Xã Hùng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11854",
                                    "name": "Xã An Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11857",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11860",
                                    "name": "Xã Tân Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11863",
                                    "name": "Xã Nhân Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11866",
                                    "name": "Xã Tam Đa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11869",
                                    "name": "Xã Hưng Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11872",
                                    "name": "Xã Vinh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11875",
                                    "name": "Xã Đồng Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11878",
                                    "name": "Xã Thanh Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11881",
                                    "name": "Xã Liên Am",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11884",
                                    "name": "Xã Lý Học",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11887",
                                    "name": "Xã Tam Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11890",
                                    "name": "Xã Hoà Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11893",
                                    "name": "Xã Tiền Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11896",
                                    "name": "Xã Vĩnh Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11899",
                                    "name": "Xã Cộng Hiền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11902",
                                    "name": "Xã Cao Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11905",
                                    "name": "Xã Cổ Am",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11908",
                                    "name": "Xã Vĩnh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11911",
                                    "name": "Xã Trấn Dương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "317",
                        "name": "Huyện Cát Hải",
                        "Wards": [
                                {
                                    "id": "11914",
                                    "name": "Thị trấn Cát Bà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11917",
                                    "name": "Thị trấn Cát Hải",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11920",
                                    "name": "Xã Nghĩa Lộ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11923",
                                    "name": "Xã Đồng Bài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11926",
                                    "name": "Xã Hoàng Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11929",
                                    "name": "Xã Văn Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11932",
                                    "name": "Xã Phù Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11935",
                                    "name": "Xã Gia Luận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11938",
                                    "name": "Xã Hiền Hào",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11941",
                                    "name": "Xã Trân Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11944",
                                    "name": "Xã Việt Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11947",
                                    "name": "Xã Xuân Đám",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "318",
                        "name": "Huyện Bạch Long Vĩ",
                        "Wards": [
                                {
                                    "Level": "Huyện"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "33",
            "name": "Tỉnh Hưng Yên",
            "districts": [
                    {
                        "id": "323",
                        "name": "Thành phố Hưng Yên",
                        "Wards": [
                                {
                                    "id": "11950",
                                    "name": "Phường Lam Sơn"
                                },
                                {
                                    "id": "11953",
                                    "name": "Phường Hiến Nam"
                                },
                                {
                                    "id": "11956",
                                    "name": "Phường An Tảo"
                                },
                                {
                                    "id": "11959",
                                    "name": "Phường Lê Lợi"
                                },
                                {
                                    "id": "11962",
                                    "name": "Phường Minh Khai"
                                },
                                {
                                    "id": "11965",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "11968",
                                    "name": "Phường Hồng Châu"
                                },
                                {
                                    "id": "11971",
                                    "name": "Xã Trung Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11974",
                                    "name": "Xã Liên Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11977",
                                    "name": "Xã Hồng Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11980",
                                    "name": "Xã Quảng Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11983",
                                    "name": "Xã Bảo Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12331",
                                    "name": "Xã Phú Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12334",
                                    "name": "Xã Hùng Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12382",
                                    "name": "Xã Phương Chiểu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12385",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12388",
                                    "name": "Xã Hoàng Hanh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "325",
                        "name": "Huyện Văn Lâm",
                        "Wards": [
                                {
                                    "id": "11986",
                                    "name": "Thị trấn Như Quỳnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "11989",
                                    "name": "Xã Lạc Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11992",
                                    "name": "Xã Chỉ Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11995",
                                    "name": "Xã Đại Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "11998",
                                    "name": "Xã Việt Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12001",
                                    "name": "Xã Tân Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12004",
                                    "name": "Xã Đình Dù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12007",
                                    "name": "Xã Minh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12010",
                                    "name": "Xã Lương Tài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12013",
                                    "name": "Xã Trưng Trắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12016",
                                    "name": "Xã Lạc Hồng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "326",
                        "name": "Huyện Văn Giang",
                        "Wards": [
                                {
                                    "id": "12019",
                                    "name": "Thị trấn Văn Giang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12022",
                                    "name": "Xã Xuân Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12025",
                                    "name": "Xã Cửu Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12028",
                                    "name": "Xã Phụng Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12031",
                                    "name": "Xã Nghĩa Trụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12034",
                                    "name": "Xã Long Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12037",
                                    "name": "Xã Vĩnh Khúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12040",
                                    "name": "Xã Liên Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12043",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12046",
                                    "name": "Xã Thắng Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12049",
                                    "name": "Xã Mễ Sở",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "327",
                        "name": "Huyện Yên Mỹ",
                        "Wards": [
                                {
                                    "id": "12052",
                                    "name": "Thị trấn Yên Mỹ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12055",
                                    "name": "Xã Giai Phạm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12058",
                                    "name": "Xã Nghĩa Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12061",
                                    "name": "Xã Đồng Than",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12064",
                                    "name": "Xã Ngọc Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12067",
                                    "name": "Xã Liêu Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12070",
                                    "name": "Xã Hoàn Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12073",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12076",
                                    "name": "Xã Thanh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12079",
                                    "name": "Xã Yên Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12082",
                                    "name": "Xã Việt Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12085",
                                    "name": "Xã Trung Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12088",
                                    "name": "Xã Yên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12091",
                                    "name": "Xã Minh Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12094",
                                    "name": "Xã Trung Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12097",
                                    "name": "Xã Lý Thường Kiệt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12100",
                                    "name": "Xã Tân Việt",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "328",
                        "name": "Thị xã Mỹ Hào",
                        "Wards": [
                                {
                                    "id": "12103",
                                    "name": "Phường Bần Yên Nhân"
                                },
                                {
                                    "id": "12106",
                                    "name": "Phường Phan Đình Phùng"
                                },
                                {
                                    "id": "12109",
                                    "name": "Xã Cẩm Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12112",
                                    "name": "Xã Dương Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12115",
                                    "name": "Xã Hòa Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12118",
                                    "name": "Phường Nhân Hòa"
                                },
                                {
                                    "id": "12121",
                                    "name": "Phường Dị Sử"
                                },
                                {
                                    "id": "12124",
                                    "name": "Phường Bạch Sam"
                                },
                                {
                                    "id": "12127",
                                    "name": "Phường Minh Đức"
                                },
                                {
                                    "id": "12130",
                                    "name": "Phường Phùng Chí Kiên"
                                },
                                {
                                    "id": "12133",
                                    "name": "Xã Xuân Dục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12136",
                                    "name": "Xã Ngọc Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12139",
                                    "name": "Xã Hưng Long",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "329",
                        "name": "Huyện Ân Thi",
                        "Wards": [
                                {
                                    "id": "12142",
                                    "name": "Thị trấn Ân Thi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12145",
                                    "name": "Xã Phù Ủng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12148",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12151",
                                    "name": "Xã Bãi Sậy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12154",
                                    "name": "Xã Đào Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12157",
                                    "name": "Xã Tân Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12160",
                                    "name": "Xã Vân Du",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12163",
                                    "name": "Xã Quang Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12166",
                                    "name": "Xã Xuân Trúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12169",
                                    "name": "Xã Hoàng Hoa Thám",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12172",
                                    "name": "Xã Quảng Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12175",
                                    "name": "Xã Văn Nhuệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12178",
                                    "name": "Xã Đặng Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12181",
                                    "name": "Xã Cẩm Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12184",
                                    "name": "Xã Nguyễn Trãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12187",
                                    "name": "Xã Đa Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12190",
                                    "name": "Xã Hồ Tùng Mậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12193",
                                    "name": "Xã Tiền Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12196",
                                    "name": "Xã Hồng Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12199",
                                    "name": "Xã Hồng Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12202",
                                    "name": "Xã Hạ Lễ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "330",
                        "name": "Huyện Khoái Châu",
                        "Wards": [
                                {
                                    "id": "12205",
                                    "name": "Thị trấn Khoái Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12208",
                                    "name": "Xã Đông Tảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12211",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12214",
                                    "name": "Xã Dạ Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12217",
                                    "name": "Xã Hàm Tử",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12220",
                                    "name": "Xã Ông Đình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12223",
                                    "name": "Xã Tân Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12226",
                                    "name": "Xã Tứ Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12229",
                                    "name": "Xã An Vĩ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12232",
                                    "name": "Xã Đông Kết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12235",
                                    "name": "Xã Bình Kiều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12238",
                                    "name": "Xã Dân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12241",
                                    "name": "Xã Đồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12244",
                                    "name": "Xã Hồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12247",
                                    "name": "Xã Tân Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12250",
                                    "name": "Xã Liên Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12253",
                                    "name": "Xã Phùng Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12256",
                                    "name": "Xã Việt Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12259",
                                    "name": "Xã Đông Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12262",
                                    "name": "Xã Đại Tập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12265",
                                    "name": "Xã Chí Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12268",
                                    "name": "Xã Đại Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12271",
                                    "name": "Xã Thuần Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12274",
                                    "name": "Xã Thành Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12277",
                                    "name": "Xã Nhuế Dương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "331",
                        "name": "Huyện Kim Động",
                        "Wards": [
                                {
                                    "id": "12280",
                                    "name": "Thị trấn Lương Bằng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12283",
                                    "name": "Xã Nghĩa Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12286",
                                    "name": "Xã Toàn Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12289",
                                    "name": "Xã Vĩnh Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12292",
                                    "name": "Xã Phạm Ngũ Lão",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12295",
                                    "name": "Xã Thọ Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12298",
                                    "name": "Xã Đồng Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12301",
                                    "name": "Xã Song Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12304",
                                    "name": "Xã Chính Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12307",
                                    "name": "Xã Nhân La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12310",
                                    "name": "Xã Phú Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12313",
                                    "name": "Xã Mai Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12316",
                                    "name": "Xã Đức Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12319",
                                    "name": "Xã Hùng An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12322",
                                    "name": "Xã Ngọc Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12325",
                                    "name": "Xã Vũ Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12328",
                                    "name": "Xã Hiệp Cường",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "332",
                        "name": "Huyện Tiên Lữ",
                        "Wards": [
                                {
                                    "id": "12337",
                                    "name": "Thị trấn Vương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12340",
                                    "name": "Xã Hưng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12343",
                                    "name": "Xã Ngô Quyền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12346",
                                    "name": "Xã Nhật Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12349",
                                    "name": "Xã Dị Chế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12352",
                                    "name": "Xã Lệ Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12355",
                                    "name": "Xã An Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12358",
                                    "name": "Xã Đức Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12361",
                                    "name": "Xã Trung Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12364",
                                    "name": "Xã Hải Triều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12367",
                                    "name": "Xã Thủ Sỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12370",
                                    "name": "Xã Thiện Phiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12373",
                                    "name": "Xã Thụy Lôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12376",
                                    "name": "Xã Cương Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12379",
                                    "name": "Xã Minh Phượng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "333",
                        "name": "Huyện Phù Cừ",
                        "Wards": [
                                {
                                    "id": "12391",
                                    "name": "Thị trấn Trần Cao",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12394",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12397",
                                    "name": "Xã Phan Sào Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12400",
                                    "name": "Xã Quang Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12403",
                                    "name": "Xã Minh Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12406",
                                    "name": "Xã Đoàn Đào",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12409",
                                    "name": "Xã Tống Phan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12412",
                                    "name": "Xã Đình Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12415",
                                    "name": "Xã Nhật Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12418",
                                    "name": "Xã Tiền Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12421",
                                    "name": "Xã Tam Đa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12424",
                                    "name": "Xã Minh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12427",
                                    "name": "Xã Nguyên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12430",
                                    "name": "Xã Tống Trân",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "34",
            "name": "Tỉnh Thái Bình",
            "districts": [
                    {
                        "id": "336",
                        "name": "Thành phố Thái Bình",
                        "Wards": [
                                {
                                    "id": "12433",
                                    "name": "Phường Lê Hồng Phong"
                                },
                                {
                                    "id": "12436",
                                    "name": "Phường Bồ Xuyên"
                                },
                                {
                                    "id": "12439",
                                    "name": "Phường Đề Thám"
                                },
                                {
                                    "id": "12442",
                                    "name": "Phường Kỳ Bá"
                                },
                                {
                                    "id": "12445",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "12448",
                                    "name": "Phường Phú Khánh"
                                },
                                {
                                    "id": "12451",
                                    "name": "Phường Tiền Phong"
                                },
                                {
                                    "id": "12452",
                                    "name": "Phường Trần Hưng Đạo"
                                },
                                {
                                    "id": "12454",
                                    "name": "Phường Trần Lãm"
                                },
                                {
                                    "id": "12457",
                                    "name": "Xã Đông Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12460",
                                    "name": "Phường Hoàng Diệu"
                                },
                                {
                                    "id": "12463",
                                    "name": "Xã Phú Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12466",
                                    "name": "Xã Vũ Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12469",
                                    "name": "Xã Vũ Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12817",
                                    "name": "Xã Đông Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12820",
                                    "name": "Xã Đông Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13084",
                                    "name": "Xã Vũ Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13108",
                                    "name": "Xã Vũ Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13225",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "338",
                        "name": "Huyện Quỳnh Phụ",
                        "Wards": [
                                {
                                    "id": "12472",
                                    "name": "Thị trấn Quỳnh Côi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12475",
                                    "name": "Xã An Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12478",
                                    "name": "Xã An Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12481",
                                    "name": "Xã Quỳnh Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12484",
                                    "name": "Xã Quỳnh Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12487",
                                    "name": "Xã Quỳnh Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12490",
                                    "name": "Xã An Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12493",
                                    "name": "Xã Quỳnh Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12496",
                                    "name": "Xã Quỳnh Giao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12499",
                                    "name": "Xã An Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12502",
                                    "name": "Xã An Cầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12505",
                                    "name": "Xã Quỳnh Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12508",
                                    "name": "Xã Quỳnh Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12511",
                                    "name": "Xã Quỳnh Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12514",
                                    "name": "Xã An Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12517",
                                    "name": "Xã Quỳnh Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12520",
                                    "name": "Xã Quỳnh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12523",
                                    "name": "Thị trấn An Bài",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12526",
                                    "name": "Xã An Ấp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12529",
                                    "name": "Xã Quỳnh Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12532",
                                    "name": "Xã Châu Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12535",
                                    "name": "Xã Quỳnh Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12538",
                                    "name": "Xã An Quí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12541",
                                    "name": "Xã An Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12547",
                                    "name": "Xã An Vũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12550",
                                    "name": "Xã An Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12553",
                                    "name": "Xã Quỳnh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12556",
                                    "name": "Xã Quỳnh Bảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12559",
                                    "name": "Xã An Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12562",
                                    "name": "Xã Quỳnh Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12565",
                                    "name": "Xã An Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12568",
                                    "name": "Xã Quỳnh Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12571",
                                    "name": "Xã An Dục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12574",
                                    "name": "Xã Đông Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12577",
                                    "name": "Xã Quỳnh Trang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12580",
                                    "name": "Xã An Tràng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12583",
                                    "name": "Xã Đồng Tiến",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "339",
                        "name": "Huyện Hưng Hà",
                        "Wards": [
                                {
                                    "id": "12586",
                                    "name": "Thị trấn Hưng Hà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12589",
                                    "name": "Xã Điệp Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12592",
                                    "name": "Xã Tân Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12595",
                                    "name": "Xã Cộng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12598",
                                    "name": "Xã Dân Chủ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12601",
                                    "name": "Xã Canh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12604",
                                    "name": "Xã Hòa Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12607",
                                    "name": "Xã Hùng Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12610",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12613",
                                    "name": "Thị trấn Hưng Nhân",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12616",
                                    "name": "Xã Đoan Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12619",
                                    "name": "Xã Duyên Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12622",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12625",
                                    "name": "Xã Văn Cẩm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12628",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12631",
                                    "name": "Xã Đông Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12634",
                                    "name": "Xã Phúc Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12637",
                                    "name": "Xã Liên Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12640",
                                    "name": "Xã Tây Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12643",
                                    "name": "Xã Thống Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12646",
                                    "name": "Xã Tiến Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12649",
                                    "name": "Xã Thái Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12652",
                                    "name": "Xã Thái Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12655",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12656",
                                    "name": "Xã Chi Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12658",
                                    "name": "Xã Minh Khai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12661",
                                    "name": "Xã Hồng An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12664",
                                    "name": "Xã Kim Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12667",
                                    "name": "Xã Hồng Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12670",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12673",
                                    "name": "Xã Văn Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12676",
                                    "name": "Xã Độc Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12679",
                                    "name": "Xã Chí Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12682",
                                    "name": "Xã Minh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12685",
                                    "name": "Xã Hồng Minh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "340",
                        "name": "Huyện Đông Hưng",
                        "Wards": [
                                {
                                    "id": "12688",
                                    "name": "Thị trấn Đông Hưng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12691",
                                    "name": "Xã Đô Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12694",
                                    "name": "Xã Đông Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12697",
                                    "name": "Xã Liên Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12700",
                                    "name": "Xã An Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12703",
                                    "name": "Xã Đông Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12706",
                                    "name": "Xã Đông Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12709",
                                    "name": "Xã Phú Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12712",
                                    "name": "Xã Mê Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12715",
                                    "name": "Xã Lô Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12718",
                                    "name": "Xã Đông La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12721",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12724",
                                    "name": "Xã Đông Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12727",
                                    "name": "Xã Chương Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12730",
                                    "name": "Xã Nguyên Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12733",
                                    "name": "Xã Phong Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12736",
                                    "name": "Xã Hợp Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12739",
                                    "name": "Xã Hồng Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12745",
                                    "name": "Xã Hà Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12748",
                                    "name": "Xã Đông Kinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12751",
                                    "name": "Xã Đông Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12754",
                                    "name": "Xã Thăng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12757",
                                    "name": "Xã Đông Các",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12760",
                                    "name": "Xã Phú Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12763",
                                    "name": "Xã Liên Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12769",
                                    "name": "Xã Đông Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12772",
                                    "name": "Xã Đông Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12775",
                                    "name": "Xã Đông Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12778",
                                    "name": "Xã Hồng Bạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12784",
                                    "name": "Xã Trọng Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12790",
                                    "name": "Xã Hồng Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12793",
                                    "name": "Xã Đông Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12796",
                                    "name": "Xã Đông Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12799",
                                    "name": "Xã Đông Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12802",
                                    "name": "Xã Đông Á",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12808",
                                    "name": "Xã Đông Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12811",
                                    "name": "Xã Đông Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12823",
                                    "name": "Xã Minh Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "341",
                        "name": "Huyện Thái Thụy",
                        "Wards": [
                                {
                                    "id": "12826",
                                    "name": "Thị trấn Diêm Điền",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12832",
                                    "name": "Xã Thụy Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12841",
                                    "name": "Xã Hồng Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12844",
                                    "name": "Xã Thụy Quỳnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12847",
                                    "name": "Xã An Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12850",
                                    "name": "Xã Thụy Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12853",
                                    "name": "Xã Thụy Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12856",
                                    "name": "Xã Thụy Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12859",
                                    "name": "Xã Thụy Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12862",
                                    "name": "Xã Thụy Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12865",
                                    "name": "Xã Dương Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12868",
                                    "name": "Xã Thụy Trình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12871",
                                    "name": "Xã Thụy Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12874",
                                    "name": "Xã Thụy Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12877",
                                    "name": "Xã Thụy Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12880",
                                    "name": "Xã Thụy Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12889",
                                    "name": "Xã Thụy Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12892",
                                    "name": "Xã Thụy Duyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12898",
                                    "name": "Xã Thụy Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12901",
                                    "name": "Xã Thụy Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12904",
                                    "name": "Xã Thụy Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12907",
                                    "name": "Xã Thái Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12910",
                                    "name": "Xã Thái Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12916",
                                    "name": "Xã Dương Hồng  Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12919",
                                    "name": "Xã Thái Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12922",
                                    "name": "Xã Hòa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12925",
                                    "name": "Xã Sơn Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12934",
                                    "name": "Xã Thái Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12937",
                                    "name": "Xã Thái Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12940",
                                    "name": "Xã Thái Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12943",
                                    "name": "Xã Thái Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12949",
                                    "name": "Xã  Mỹ Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12958",
                                    "name": "Xã Tân Học",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12961",
                                    "name": "Xã Thái Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12964",
                                    "name": "Xã Thuần Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12967",
                                    "name": "Xã Thái Thọ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "342",
                        "name": "Huyện Tiền Hải",
                        "Wards": [
                                {
                                    "id": "12970",
                                    "name": "Thị trấn Tiền Hải",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "12976",
                                    "name": "Xã Đông Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12979",
                                    "name": "Xã Đông Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12982",
                                    "name": "Xã Đông Quí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12985",
                                    "name": "Xã Vũ Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12988",
                                    "name": "Xã Đông Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12991",
                                    "name": "Xã Tây Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12994",
                                    "name": "Xã Tây Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "12997",
                                    "name": "Xã Đông Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13000",
                                    "name": "Xã Đông Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13003",
                                    "name": "Xã Đông Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13009",
                                    "name": "Xã Đông Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13012",
                                    "name": "Xã An Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13018",
                                    "name": "Xã Đông Cơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13021",
                                    "name": "Xã Tây Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13024",
                                    "name": "Xã Đông Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13027",
                                    "name": "Xã Phương Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13030",
                                    "name": "Xã Tây Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13033",
                                    "name": "Xã Tây Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13036",
                                    "name": "Xã Nam Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13039",
                                    "name": "Xã Vân Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13042",
                                    "name": "Xã Nam Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13045",
                                    "name": "Xã Nam Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13048",
                                    "name": "Xã Bắc Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13051",
                                    "name": "Xã Nam Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13054",
                                    "name": "Xã Nam Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13057",
                                    "name": "Xã Nam Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13060",
                                    "name": "Xã Nam Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13063",
                                    "name": "Xã Nam Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13066",
                                    "name": "Xã Nam Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13069",
                                    "name": "Xã Nam Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13072",
                                    "name": "Xã Nam Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "343",
                        "name": "Huyện Kiến Xương",
                        "Wards": [
                                {
                                    "id": "13075",
                                    "name": "Thị trấn Kiến Xương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13078",
                                    "name": "Xã Trà Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13081",
                                    "name": "Xã Quốc Tuấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13087",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13090",
                                    "name": "Xã Tây Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13093",
                                    "name": "Xã Hồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13096",
                                    "name": "Xã Bình Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13102",
                                    "name": "Xã Lê Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13111",
                                    "name": "Xã Vũ Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13114",
                                    "name": "Xã Thanh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13117",
                                    "name": "Xã Thượng Hiền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13120",
                                    "name": "Xã Nam Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13123",
                                    "name": "Xã Đình Phùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13126",
                                    "name": "Xã Vũ Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13129",
                                    "name": "Xã Vũ An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13132",
                                    "name": "Xã Quang Lịch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13135",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13138",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13141",
                                    "name": "Xã Vũ Quí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13144",
                                    "name": "Xã Quang Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13150",
                                    "name": "Xã Vũ Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13153",
                                    "name": "Xã Vũ Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13156",
                                    "name": "Xã Vũ Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13159",
                                    "name": "Xã Vũ Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13162",
                                    "name": "Xã Quang Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13165",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13171",
                                    "name": "Xã Minh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13174",
                                    "name": "Xã Vũ Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13177",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13180",
                                    "name": "Xã Nam Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13183",
                                    "name": "Xã Bình Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13186",
                                    "name": "Xã Bình Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13189",
                                    "name": "Xã Hồng Tiến",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "344",
                        "name": "Huyện Vũ Thư",
                        "Wards": [
                                {
                                    "id": "13192",
                                    "name": "Thị trấn Vũ Thư",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13195",
                                    "name": "Xã Hồng Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13198",
                                    "name": "Xã Đồng Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13201",
                                    "name": "Xã Xuân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13204",
                                    "name": "Xã Hiệp Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13207",
                                    "name": "Xã Phúc Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13210",
                                    "name": "Xã Tân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13213",
                                    "name": "Xã Song Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13216",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13219",
                                    "name": "Xã Việt Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13222",
                                    "name": "Xã Minh Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13228",
                                    "name": "Xã Minh Khai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13231",
                                    "name": "Xã Dũng Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13234",
                                    "name": "Xã Minh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13237",
                                    "name": "Xã Tam Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13240",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13243",
                                    "name": "Xã Bách Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13246",
                                    "name": "Xã Tự Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13249",
                                    "name": "Xã Song An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13252",
                                    "name": "Xã Trung An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13255",
                                    "name": "Xã Vũ Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13258",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13261",
                                    "name": "Xã Nguyên Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13264",
                                    "name": "Xã Việt Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13267",
                                    "name": "Xã Vũ Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13270",
                                    "name": "Xã Vũ Đoài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13273",
                                    "name": "Xã Vũ Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13276",
                                    "name": "Xã Vũ Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13279",
                                    "name": "Xã Duy Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13282",
                                    "name": "Xã Hồng Phong",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "35",
            "name": "Tỉnh Hà Nam",
            "districts": [
                    {
                        "id": "347",
                        "name": "Thành phố Phủ Lý",
                        "Wards": [
                                {
                                    "id": "13285",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "13288",
                                    "name": "Phường Lương Khánh Thiện"
                                },
                                {
                                    "id": "13291",
                                    "name": "Phường Lê Hồng Phong"
                                },
                                {
                                    "id": "13294",
                                    "name": "Phường Minh Khai"
                                },
                                {
                                    "id": "13297",
                                    "name": "Phường Hai Bà Trưng"
                                },
                                {
                                    "id": "13300",
                                    "name": "Phường Trần Hưng Đạo"
                                },
                                {
                                    "id": "13303",
                                    "name": "Phường Lam Hạ"
                                },
                                {
                                    "id": "13306",
                                    "name": "Xã Phù Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13309",
                                    "name": "Phường Liêm Chính"
                                },
                                {
                                    "id": "13312",
                                    "name": "Xã Liêm Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13315",
                                    "name": "Phường Thanh Châu"
                                },
                                {
                                    "id": "13318",
                                    "name": "Phường Châu Sơn"
                                },
                                {
                                    "id": "13366",
                                    "name": "Xã Tiên Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13372",
                                    "name": "Xã Tiên Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13381",
                                    "name": "Xã Tiên Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13426",
                                    "name": "Xã Kim Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13444",
                                    "name": "Xã Liêm Tuyền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13447",
                                    "name": "Xã Liêm Tiết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13459",
                                    "name": "Phường Thanh Tuyền"
                                },
                                {
                                    "id": "13507",
                                    "name": "Xã Đinh Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13513",
                                    "name": "Xã Trịnh Xá",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "349",
                        "name": "Thị xã Duy Tiên",
                        "Wards": [
                                {
                                    "id": "13321",
                                    "name": "Phường Đồng Văn"
                                },
                                {
                                    "id": "13324",
                                    "name": "Phường Hòa Mạc"
                                },
                                {
                                    "id": "13327",
                                    "name": "Xã Mộc Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13330",
                                    "name": "Phường Châu Giang"
                                },
                                {
                                    "id": "13333",
                                    "name": "Phường Bạch Thượng"
                                },
                                {
                                    "id": "13336",
                                    "name": "Phường Duy Minh"
                                },
                                {
                                    "id": "13339",
                                    "name": "Xã Mộc Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13342",
                                    "name": "Phường Duy Hải"
                                },
                                {
                                    "id": "13345",
                                    "name": "Xã Chuyên Ngoại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13348",
                                    "name": "Phường Yên Bắc"
                                },
                                {
                                    "id": "13351",
                                    "name": "Xã Trác Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13354",
                                    "name": "Phường Tiên Nội"
                                },
                                {
                                    "id": "13357",
                                    "name": "Phường Hoàng Đông"
                                },
                                {
                                    "id": "13360",
                                    "name": "Xã Yên Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13363",
                                    "name": "Xã Tiên Ngoại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13369",
                                    "name": "Xã Tiên Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "350",
                        "name": "Huyện Kim Bảng",
                        "Wards": [
                                {
                                    "id": "13384",
                                    "name": "Thị trấn Quế",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13387",
                                    "name": "Xã Nguyễn Úy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13390",
                                    "name": "Xã Đại Cương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13393",
                                    "name": "Xã Lê Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13396",
                                    "name": "Xã Tượng Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13399",
                                    "name": "Xã Nhật Tựu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13402",
                                    "name": "Xã Nhật Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13405",
                                    "name": "Xã Đồng Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13408",
                                    "name": "Xã Hoàng Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13411",
                                    "name": "Xã Tân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13414",
                                    "name": "Xã Thụy Lôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13417",
                                    "name": "Xã Văn Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13420",
                                    "name": "Xã Khả Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13423",
                                    "name": "Xã Ngọc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13429",
                                    "name": "Thị trấn Ba Sao",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13432",
                                    "name": "Xã Liên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13435",
                                    "name": "Xã Thi Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13438",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "351",
                        "name": "Huyện Thanh Liêm",
                        "Wards": [
                                {
                                    "id": "13441",
                                    "name": "Thị trấn Kiện Khê",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13450",
                                    "name": "Xã Liêm Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13453",
                                    "name": "Xã Thanh Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13456",
                                    "name": "Xã Liêm Cần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13465",
                                    "name": "Xã Liêm Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13468",
                                    "name": "Xã Thanh Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13471",
                                    "name": "Xã Thanh Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13474",
                                    "name": "Thị trấn Tân Thanh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13477",
                                    "name": "Xã Thanh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13480",
                                    "name": "Xã Liêm Túc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13483",
                                    "name": "Xã Liêm Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13486",
                                    "name": "Xã Thanh Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13489",
                                    "name": "Xã Thanh Nghị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13492",
                                    "name": "Xã Thanh Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13495",
                                    "name": "Xã Thanh Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13498",
                                    "name": "Xã Thanh Hải",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "352",
                        "name": "Huyện Bình Lục",
                        "Wards": [
                                {
                                    "id": "13501",
                                    "name": "Thị trấn Bình Mỹ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13504",
                                    "name": "Xã Bình Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13510",
                                    "name": "Xã Tràng An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13516",
                                    "name": "Xã Đồng Du",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13519",
                                    "name": "Xã Ngọc Lũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13522",
                                    "name": "Xã Hưng Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13525",
                                    "name": "Xã Đồn Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13528",
                                    "name": "Xã An Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13531",
                                    "name": "Xã Bồ Đề",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13534",
                                    "name": "Xã Bối Cầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13540",
                                    "name": "Xã An Nội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13543",
                                    "name": "Xã Vũ Bản",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13546",
                                    "name": "Xã Trung Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13552",
                                    "name": "Xã An Đổ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13555",
                                    "name": "Xã La Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13558",
                                    "name": "Xã Tiêu Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13561",
                                    "name": "Xã An Lão",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "353",
                        "name": "Huyện Lý Nhân",
                        "Wards": [
                                {
                                    "id": "13567",
                                    "name": "Xã Hợp Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13570",
                                    "name": "Xã Nguyên Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13573",
                                    "name": "Xã Chính Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13576",
                                    "name": "Xã Chân Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13579",
                                    "name": "Xã Đạo Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13582",
                                    "name": "Xã Công Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13585",
                                    "name": "Xã Văn Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13588",
                                    "name": "Xã Bắc Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13591",
                                    "name": "Xã Đức Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13594",
                                    "name": "Xã Trần Hưng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13597",
                                    "name": "Thị trấn Vĩnh Trụ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13600",
                                    "name": "Xã Nhân Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13606",
                                    "name": "Xã Nhân Khang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13609",
                                    "name": "Xã Nhân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13612",
                                    "name": "Xã Nhân Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13615",
                                    "name": "Xã Nhân Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13618",
                                    "name": "Xã Nhân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13621",
                                    "name": "Xã Phú Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13624",
                                    "name": "Xã Xuân Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13627",
                                    "name": "Xã Tiến Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13630",
                                    "name": "Xã Hòa Hậu",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "36",
            "name": "Tỉnh Nam Định",
            "districts": [
                    {
                        "id": "356",
                        "name": "Thành phố Nam Định",
                        "Wards": [
                                {
                                    "id": "13633",
                                    "name": "Phường Hạ Long"
                                },
                                {
                                    "id": "13636",
                                    "name": "Phường Trần Tế Xương"
                                },
                                {
                                    "id": "13639",
                                    "name": "Phường Vị Hoàng"
                                },
                                {
                                    "id": "13642",
                                    "name": "Phường Vị Xuyên"
                                },
                                {
                                    "id": "13645",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "13648",
                                    "name": "Phường Cửa Bắc"
                                },
                                {
                                    "id": "13651",
                                    "name": "Phường Nguyễn Du"
                                },
                                {
                                    "id": "13654",
                                    "name": "Phường Bà Triệu"
                                },
                                {
                                    "id": "13657",
                                    "name": "Phường Trường Thi"
                                },
                                {
                                    "id": "13660",
                                    "name": "Phường Phan Đình Phùng"
                                },
                                {
                                    "id": "13663",
                                    "name": "Phường Ngô Quyền"
                                },
                                {
                                    "id": "13666",
                                    "name": "Phường Trần Hưng Đạo"
                                },
                                {
                                    "id": "13669",
                                    "name": "Phường Trần Đăng Ninh"
                                },
                                {
                                    "id": "13672",
                                    "name": "Phường Năng Tĩnh"
                                },
                                {
                                    "id": "13675",
                                    "name": "Phường Văn Miếu"
                                },
                                {
                                    "id": "13678",
                                    "name": "Phường Trần Quang Khải"
                                },
                                {
                                    "id": "13681",
                                    "name": "Phường Thống Nhất"
                                },
                                {
                                    "id": "13684",
                                    "name": "Phường Lộc Hạ"
                                },
                                {
                                    "id": "13687",
                                    "name": "Phường Lộc Vượng"
                                },
                                {
                                    "id": "13690",
                                    "name": "Phường Cửa Nam"
                                },
                                {
                                    "id": "13693",
                                    "name": "Phường Lộc Hòa"
                                },
                                {
                                    "id": "13696",
                                    "name": "Xã Nam Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13699",
                                    "name": "Phường Mỹ Xá"
                                },
                                {
                                    "id": "13702",
                                    "name": "Xã Lộc An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13705",
                                    "name": "Xã Nam Vân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "358",
                        "name": "Huyện Mỹ Lộc",
                        "Wards": [
                                {
                                    "id": "13708",
                                    "name": "Thị trấn Mỹ Lộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13711",
                                    "name": "Xã Mỹ Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13714",
                                    "name": "Xã Mỹ Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13717",
                                    "name": "Xã Mỹ Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13720",
                                    "name": "Xã Mỹ Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13723",
                                    "name": "Xã Mỹ Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13726",
                                    "name": "Xã Mỹ Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13729",
                                    "name": "Xã Mỹ Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13732",
                                    "name": "Xã Mỹ Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13735",
                                    "name": "Xã Mỹ Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13738",
                                    "name": "Xã Mỹ Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "359",
                        "name": "Huyện Vụ Bản",
                        "Wards": [
                                {
                                    "id": "13741",
                                    "name": "Thị trấn Gôi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13744",
                                    "name": "Xã Minh Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13747",
                                    "name": "Xã Hiển Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13750",
                                    "name": "Xã Tân Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13753",
                                    "name": "Xã Hợp Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13756",
                                    "name": "Xã Đại An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13759",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13762",
                                    "name": "Xã Cộng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13765",
                                    "name": "Xã Trung Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13768",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13771",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13774",
                                    "name": "Xã Liên Bảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13777",
                                    "name": "Xã Thành Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13780",
                                    "name": "Xã Kim Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13783",
                                    "name": "Xã Liên Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13786",
                                    "name": "Xã Đại Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13789",
                                    "name": "Xã Tam Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13792",
                                    "name": "Xã Vĩnh Hào",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "360",
                        "name": "Huyện Ý Yên",
                        "Wards": [
                                {
                                    "id": "13795",
                                    "name": "Thị trấn Lâm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13798",
                                    "name": "Xã Yên Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13801",
                                    "name": "Xã Yên Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13804",
                                    "name": "Xã Yên Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13807",
                                    "name": "Xã Yên Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13810",
                                    "name": "Xã Yên Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13813",
                                    "name": "Xã Yên Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13816",
                                    "name": "Xã Yên Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13819",
                                    "name": "Xã Yên Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13822",
                                    "name": "Xã Yên Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13825",
                                    "name": "Xã Yên Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13828",
                                    "name": "Xã Yên Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13831",
                                    "name": "Xã Yên Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13834",
                                    "name": "Xã Yên Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13840",
                                    "name": "Xã Yên Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13843",
                                    "name": "Xã Yên Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13846",
                                    "name": "Xã Yên Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13849",
                                    "name": "Xã Yên Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13852",
                                    "name": "Xã Yên Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13855",
                                    "name": "Xã Yên Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13858",
                                    "name": "Xã Yên Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13861",
                                    "name": "Xã Yên Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13864",
                                    "name": "Xã Yên Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13867",
                                    "name": "Xã Yên Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13870",
                                    "name": "Xã Yên Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13873",
                                    "name": "Xã Yên Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13876",
                                    "name": "Xã Yên Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13879",
                                    "name": "Xã Yên Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13882",
                                    "name": "Xã Yên Khang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13885",
                                    "name": "Xã Yên Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13888",
                                    "name": "Xã Yên Trị",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "361",
                        "name": "Huyện Nghĩa Hưng",
                        "Wards": [
                                {
                                    "id": "13891",
                                    "name": "Thị trấn Liễu Đề",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13894",
                                    "name": "Thị trấn Rạng Đông",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13897",
                                    "name": "Xã Nghĩa Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13900",
                                    "name": "Xã Nghĩa Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13903",
                                    "name": "Xã Nghĩa Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13906",
                                    "name": "Xã Nghĩa Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13909",
                                    "name": "Xã Hoàng Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13912",
                                    "name": "Xã Nghĩa Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13915",
                                    "name": "Xã Nghĩa Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13918",
                                    "name": "Xã Nghĩa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13921",
                                    "name": "Xã Nghĩa Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13924",
                                    "name": "Xã Nghĩa Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13927",
                                    "name": "Xã Nghĩa Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13930",
                                    "name": "Xã Nghĩa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13933",
                                    "name": "Xã Nghĩa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13936",
                                    "name": "Thị trấn Quỹ Nhất",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13939",
                                    "name": "Xã Nghĩa Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13942",
                                    "name": "Xã Nghĩa Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13945",
                                    "name": "Xã Nghĩa Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13948",
                                    "name": "Xã Nghĩa Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13951",
                                    "name": "Xã Phúc Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13954",
                                    "name": "Xã Nghĩa Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13957",
                                    "name": "Xã Nghĩa Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13963",
                                    "name": "Xã Nam Điền",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "362",
                        "name": "Huyện Nam Trực",
                        "Wards": [
                                {
                                    "id": "13966",
                                    "name": "Thị trấn Nam Giang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "13969",
                                    "name": "Xã Nam Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13972",
                                    "name": "Xã Điền Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13975",
                                    "name": "Xã Nghĩa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13978",
                                    "name": "Xã Nam Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13981",
                                    "name": "Xã Nam Toàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13984",
                                    "name": "Xã Hồng Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13987",
                                    "name": "Xã Tân Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13990",
                                    "name": "Xã Nam Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13993",
                                    "name": "Xã Nam Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13996",
                                    "name": "Xã Nam Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "13999",
                                    "name": "Xã Nam Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14002",
                                    "name": "Xã Nam Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14005",
                                    "name": "Xã Nam Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14008",
                                    "name": "Xã Nam Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14011",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14014",
                                    "name": "Xã Đồng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14017",
                                    "name": "Xã Nam Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14020",
                                    "name": "Xã Nam Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14023",
                                    "name": "Xã Nam Thái",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "363",
                        "name": "Huyện Trực Ninh",
                        "Wards": [
                                {
                                    "id": "14026",
                                    "name": "Thị trấn Cổ Lễ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14029",
                                    "name": "Xã Phương Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14032",
                                    "name": "Xã Trực Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14035",
                                    "name": "Xã Trung Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14038",
                                    "name": "Xã Liêm Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14041",
                                    "name": "Xã Trực Tuấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14044",
                                    "name": "Xã Việt Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14047",
                                    "name": "Xã Trực Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14050",
                                    "name": "Xã Trực Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14053",
                                    "name": "Xã Trực Nội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14056",
                                    "name": "Thị trấn Cát Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14059",
                                    "name": "Xã Trực Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14062",
                                    "name": "Xã Trực Khang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14065",
                                    "name": "Xã Trực Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14068",
                                    "name": "Xã Trực Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14071",
                                    "name": "Xã Trực Đại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14074",
                                    "name": "Xã Trực Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14077",
                                    "name": "Thị trấn Ninh Cường",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14080",
                                    "name": "Xã Trực Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14083",
                                    "name": "Xã Trực Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14086",
                                    "name": "Xã Trực Thắng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "364",
                        "name": "Huyện Xuân Trường",
                        "Wards": [
                                {
                                    "id": "14089",
                                    "name": "Thị trấn Xuân Trường",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14092",
                                    "name": "Xã Xuân Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14095",
                                    "name": "Xã Xuân Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14098",
                                    "name": "Xã Xuân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14101",
                                    "name": "Xã Xuân Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14104",
                                    "name": "Xã Xuân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14107",
                                    "name": "Xã Xuân Đài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14110",
                                    "name": "Xã Xuân Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14113",
                                    "name": "Xã Xuân Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14116",
                                    "name": "Xã Xuân Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14119",
                                    "name": "Xã Xuân Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14122",
                                    "name": "Xã Xuân Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14125",
                                    "name": "Xã Thọ Nghiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14128",
                                    "name": "Xã Xuân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14131",
                                    "name": "Xã Xuân Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14134",
                                    "name": "Xã Xuân Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14137",
                                    "name": "Xã Xuân Kiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14140",
                                    "name": "Xã Xuân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14143",
                                    "name": "Xã Xuân Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14146",
                                    "name": "Xã Xuân Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "365",
                        "name": "Huyện Giao Thủy",
                        "Wards": [
                                {
                                    "id": "14149",
                                    "name": "Thị trấn Ngô Đồng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14152",
                                    "name": "Thị trấn Quất Lâm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14155",
                                    "name": "Xã Giao Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14158",
                                    "name": "Xã Hồng Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14161",
                                    "name": "Xã Giao Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14164",
                                    "name": "Xã Giao Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14167",
                                    "name": "Xã Hoành Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14170",
                                    "name": "Xã Bình Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14173",
                                    "name": "Xã Giao Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14176",
                                    "name": "Xã Giao Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14179",
                                    "name": "Xã Giao Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14182",
                                    "name": "Xã Giao An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14185",
                                    "name": "Xã Giao Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14188",
                                    "name": "Xã Giao Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14191",
                                    "name": "Xã Giao Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14194",
                                    "name": "Xã Giao Yến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14197",
                                    "name": "Xã Giao Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14200",
                                    "name": "Xã Giao Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14203",
                                    "name": "Xã Giao Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14206",
                                    "name": "Xã Bạch Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14209",
                                    "name": "Xã Giao Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14212",
                                    "name": "Xã Giao Phong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "366",
                        "name": "Huyện Hải Hậu",
                        "Wards": [
                                {
                                    "id": "14215",
                                    "name": "Thị trấn Yên Định",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14218",
                                    "name": "Thị trấn Cồn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14221",
                                    "name": "Thị trấn Thịnh Long",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14224",
                                    "name": "Xã Hải Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14227",
                                    "name": "Xã Hải Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14230",
                                    "name": "Xã Hải Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14233",
                                    "name": "Xã Hải Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14236",
                                    "name": "Xã Hải Anh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14239",
                                    "name": "Xã Hải Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14242",
                                    "name": "Xã Hải Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14245",
                                    "name": "Xã Hải Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14248",
                                    "name": "Xã Hải Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14251",
                                    "name": "Xã Hải Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14254",
                                    "name": "Xã Hải Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14257",
                                    "name": "Xã Hải Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14260",
                                    "name": "Xã Hải Đường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14263",
                                    "name": "Xã Hải Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14266",
                                    "name": "Xã Hải Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14269",
                                    "name": "Xã Hải Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14272",
                                    "name": "Xã Hải Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14275",
                                    "name": "Xã Hải Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14281",
                                    "name": "Xã Hải Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14284",
                                    "name": "Xã Hải An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14287",
                                    "name": "Xã Hải Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14290",
                                    "name": "Xã Hải Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14293",
                                    "name": "Xã Hải Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14296",
                                    "name": "Xã Hải Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14299",
                                    "name": "Xã Hải Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14302",
                                    "name": "Xã Hải Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14305",
                                    "name": "Xã Hải Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14308",
                                    "name": "Xã Hải Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14311",
                                    "name": "Xã Hải Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14314",
                                    "name": "Xã Hải Triều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14317",
                                    "name": "Xã Hải Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "37",
            "name": "Tỉnh Ninh Bình",
            "districts": [
                    {
                        "id": "369",
                        "name": "Thành phố Ninh Bình",
                        "Wards": [
                                {
                                    "id": "14320",
                                    "name": "Phường Đông Thành"
                                },
                                {
                                    "id": "14323",
                                    "name": "Phường Tân Thành"
                                },
                                {
                                    "id": "14326",
                                    "name": "Phường Thanh Bình"
                                },
                                {
                                    "id": "14329",
                                    "name": "Phường Vân Giang"
                                },
                                {
                                    "id": "14332",
                                    "name": "Phường Bích Đào"
                                },
                                {
                                    "id": "14335",
                                    "name": "Phường Phúc Thành"
                                },
                                {
                                    "id": "14338",
                                    "name": "Phường Nam Bình"
                                },
                                {
                                    "id": "14341",
                                    "name": "Phường Nam Thành"
                                },
                                {
                                    "id": "14344",
                                    "name": "Phường Ninh Khánh"
                                },
                                {
                                    "id": "14347",
                                    "name": "Xã Ninh Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14350",
                                    "name": "Xã Ninh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14353",
                                    "name": "Xã Ninh Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14356",
                                    "name": "Phường Ninh Sơn"
                                },
                                {
                                    "id": "14359",
                                    "name": "Phường Ninh Phong"
                                }
                        ]
                    },
                    {
                        "id": "370",
                        "name": "Thành phố Tam Điệp",
                        "Wards": [
                                {
                                    "id": "14362",
                                    "name": "Phường Bắc Sơn"
                                },
                                {
                                    "id": "14365",
                                    "name": "Phường Trung Sơn"
                                },
                                {
                                    "id": "14368",
                                    "name": "Phường Nam Sơn"
                                },
                                {
                                    "id": "14369",
                                    "name": "Phường Tây Sơn"
                                },
                                {
                                    "id": "14371",
                                    "name": "Xã Yên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14374",
                                    "name": "Phường Yên Bình"
                                },
                                {
                                    "id": "14375",
                                    "name": "Phường Tân Bình"
                                },
                                {
                                    "id": "14377",
                                    "name": "Xã Quang Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14380",
                                    "name": "Xã Đông Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "372",
                        "name": "Huyện Nho Quan",
                        "Wards": [
                                {
                                    "id": "14383",
                                    "name": "Thị trấn Nho Quan",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14386",
                                    "name": "Xã Xích Thổ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14389",
                                    "name": "Xã Gia Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14392",
                                    "name": "Xã Gia Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14395",
                                    "name": "Xã Thạch Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14398",
                                    "name": "Xã Gia Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14401",
                                    "name": "Xã Gia Tường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14404",
                                    "name": "Xã Cúc Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14407",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14410",
                                    "name": "Xã Đức Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14413",
                                    "name": "Xã Lạc Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14416",
                                    "name": "Xã Đồng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14419",
                                    "name": "Xã Yên Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14422",
                                    "name": "Xã Lạng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14425",
                                    "name": "Xã Thượng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14428",
                                    "name": "Xã Văn Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14431",
                                    "name": "Xã Văn Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14434",
                                    "name": "Xã Thanh Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14437",
                                    "name": "Xã Sơn Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14440",
                                    "name": "Xã Sơn Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14443",
                                    "name": "Xã Văn Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14446",
                                    "name": "Xã Phú Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14449",
                                    "name": "Xã Kỳ Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14452",
                                    "name": "Xã Quỳnh Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14455",
                                    "name": "Xã Sơn Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14458",
                                    "name": "Xã Phú Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14461",
                                    "name": "Xã Quảng Lạc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "373",
                        "name": "Huyện Gia Viễn",
                        "Wards": [
                                {
                                    "id": "14464",
                                    "name": "Thị trấn Me",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14467",
                                    "name": "Xã Gia Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14470",
                                    "name": "Xã Gia Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14473",
                                    "name": "Xã Liên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14476",
                                    "name": "Xã Gia Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14479",
                                    "name": "Xã Gia Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14482",
                                    "name": "Xã Gia Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14485",
                                    "name": "Xã Gia Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14488",
                                    "name": "Xã Gia Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14491",
                                    "name": "Xã Gia Vượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14494",
                                    "name": "Xã Gia Trấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14497",
                                    "name": "Xã Gia Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14500",
                                    "name": "Xã Gia Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14503",
                                    "name": "Xã Gia Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14506",
                                    "name": "Xã Gia Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14509",
                                    "name": "Xã Gia Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14512",
                                    "name": "Xã Gia Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14515",
                                    "name": "Xã Gia Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14518",
                                    "name": "Xã Gia Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14521",
                                    "name": "Xã Gia Sinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14524",
                                    "name": "Xã Gia Phong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "374",
                        "name": "Huyện Hoa Lư",
                        "Wards": [
                                {
                                    "id": "14527",
                                    "name": "Thị trấn Thiên Tôn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14530",
                                    "name": "Xã Ninh Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14533",
                                    "name": "Xã Trường Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14536",
                                    "name": "Xã Ninh Khang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14539",
                                    "name": "Xã Ninh Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14542",
                                    "name": "Xã Ninh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14545",
                                    "name": "Xã Ninh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14548",
                                    "name": "Xã Ninh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14551",
                                    "name": "Xã Ninh Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14554",
                                    "name": "Xã Ninh Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14557",
                                    "name": "Xã Ninh An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "375",
                        "name": "Huyện Yên Khánh",
                        "Wards": [
                                {
                                    "id": "14560",
                                    "name": "Thị trấn Yên Ninh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14563",
                                    "name": "Xã Khánh Tiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14566",
                                    "name": "Xã Khánh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14569",
                                    "name": "Xã Khánh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14572",
                                    "name": "Xã Khánh Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14575",
                                    "name": "Xã Khánh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14578",
                                    "name": "Xã Khánh Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14581",
                                    "name": "Xã Khánh Cư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14584",
                                    "name": "Xã Khánh Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14587",
                                    "name": "Xã Khánh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14590",
                                    "name": "Xã Khánh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14593",
                                    "name": "Xã Khánh Mậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14596",
                                    "name": "Xã Khánh Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14599",
                                    "name": "Xã Khánh Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14602",
                                    "name": "Xã Khánh Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14608",
                                    "name": "Xã Khánh Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14611",
                                    "name": "Xã Khánh Nhạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14614",
                                    "name": "Xã Khánh Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14617",
                                    "name": "Xã Khánh Hồng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "376",
                        "name": "Huyện Kim Sơn",
                        "Wards": [
                                {
                                    "id": "14620",
                                    "name": "Thị trấn Phát Diệm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14623",
                                    "name": "Thị trấn Bình Minh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14629",
                                    "name": "Xã Hồi Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14632",
                                    "name": "Xã Xuân Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14635",
                                    "name": "Xã Kim Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14638",
                                    "name": "Xã Ân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14641",
                                    "name": "Xã Hùng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14647",
                                    "name": "Xã Quang Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14650",
                                    "name": "Xã Như Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14653",
                                    "name": "Xã Chất Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14656",
                                    "name": "Xã Đồng Hướng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14659",
                                    "name": "Xã Kim Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14662",
                                    "name": "Xã Thượng Kiệm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14665",
                                    "name": "Xã Lưu Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14668",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14671",
                                    "name": "Xã Yên Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14674",
                                    "name": "Xã Lai Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14677",
                                    "name": "Xã Định Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14680",
                                    "name": "Xã Văn Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14683",
                                    "name": "Xã Kim Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14686",
                                    "name": "Xã Kim Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14689",
                                    "name": "Xã Cồn Thoi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14692",
                                    "name": "Xã Kim Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14695",
                                    "name": "Xã Kim Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14698",
                                    "name": "Xã Kim Đông",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "377",
                        "name": "Huyện Yên Mô",
                        "Wards": [
                                {
                                    "id": "14701",
                                    "name": "Thị trấn Yên Thịnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14704",
                                    "name": "Xã Khánh Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14707",
                                    "name": "Xã Khánh Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14710",
                                    "name": "Xã Mai Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14713",
                                    "name": "Xã Khánh Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14719",
                                    "name": "Xã Yên Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14722",
                                    "name": "Xã Yên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14725",
                                    "name": "Xã Yên Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14728",
                                    "name": "Xã Yên Từ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14731",
                                    "name": "Xã Yên Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14734",
                                    "name": "Xã Yên Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14737",
                                    "name": "Xã Yên Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14740",
                                    "name": "Xã Yên Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14743",
                                    "name": "Xã Yên Mạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14746",
                                    "name": "Xã Yên Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14749",
                                    "name": "Xã Yên Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14752",
                                    "name": "Xã Yên Lâm",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "38",
            "name": "Tỉnh Thanh Hóa",
            "districts": [
                    {
                        "id": "380",
                        "name": "Thành phố Thanh Hóa",
                        "Wards": [
                                {
                                    "id": "14755",
                                    "name": "Phường Hàm Rồng"
                                },
                                {
                                    "id": "14758",
                                    "name": "Phường Đông Thọ"
                                },
                                {
                                    "id": "14761",
                                    "name": "Phường Nam Ngạn"
                                },
                                {
                                    "id": "14764",
                                    "name": "Phường Trường Thi"
                                },
                                {
                                    "id": "14767",
                                    "name": "Phường Điện Biên"
                                },
                                {
                                    "id": "14770",
                                    "name": "Phường Phú Sơn"
                                },
                                {
                                    "id": "14773",
                                    "name": "Phường Lam Sơn"
                                },
                                {
                                    "id": "14776",
                                    "name": "Phường Ba Đình"
                                },
                                {
                                    "id": "14779",
                                    "name": "Phường Ngọc Trạo"
                                },
                                {
                                    "id": "14782",
                                    "name": "Phường Đông Vệ"
                                },
                                {
                                    "id": "14785",
                                    "name": "Phường Đông Sơn"
                                },
                                {
                                    "id": "14788",
                                    "name": "Phường Tân Sơn"
                                },
                                {
                                    "id": "14791",
                                    "name": "Phường Đông Cương"
                                },
                                {
                                    "id": "14794",
                                    "name": "Phường Đông Hương"
                                },
                                {
                                    "id": "14797",
                                    "name": "Phường Đông Hải"
                                },
                                {
                                    "id": "14800",
                                    "name": "Phường Quảng Hưng"
                                },
                                {
                                    "id": "14803",
                                    "name": "Phường Quảng Thắng"
                                },
                                {
                                    "id": "14806",
                                    "name": "Phường Quảng Thành"
                                },
                                {
                                    "id": "15850",
                                    "name": "Xã Thiệu Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15856",
                                    "name": "Xã Thiệu Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15859",
                                    "name": "Xã Thiệu Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15913",
                                    "name": "Phường Tào Xuyên"
                                },
                                {
                                    "id": "15922",
                                    "name": "Xã Long Anh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15925",
                                    "name": "Xã Hoằng Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15970",
                                    "name": "Xã Hoằng Đại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16396",
                                    "name": "Xã Đông Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16429",
                                    "name": "Xã Đông Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16432",
                                    "name": "Xã Đông Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16435",
                                    "name": "Phường An Hưng"
                                },
                                {
                                    "id": "16441",
                                    "name": "Xã Quảng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16459",
                                    "name": "Xã Quảng Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16507",
                                    "name": "Xã Quảng Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16522",
                                    "name": "Xã Quảng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16525",
                                    "name": "Xã Quảng Tâm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "381",
                        "name": "Thị xã Bỉm Sơn",
                        "Wards": [
                                {
                                    "id": "14809",
                                    "name": "Phường Bắc Sơn"
                                },
                                {
                                    "id": "14812",
                                    "name": "Phường Ba Đình"
                                },
                                {
                                    "id": "14815",
                                    "name": "Phường Lam Sơn"
                                },
                                {
                                    "id": "14818",
                                    "name": "Phường Ngọc Trạo"
                                },
                                {
                                    "id": "14821",
                                    "name": "Phường Đông Sơn"
                                },
                                {
                                    "id": "14823",
                                    "name": "Phường Phú Sơn"
                                },
                                {
                                    "id": "14824",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "382",
                        "name": "Thành phố Sầm Sơn",
                        "Wards": [
                                {
                                    "id": "14830",
                                    "name": "Phường Trung Sơn"
                                },
                                {
                                    "id": "14833",
                                    "name": "Phường Bắc Sơn"
                                },
                                {
                                    "id": "14836",
                                    "name": "Phường Trường Sơn"
                                },
                                {
                                    "id": "14839",
                                    "name": "Phường Quảng Cư"
                                },
                                {
                                    "id": "14842",
                                    "name": "Phường Quảng Tiến"
                                },
                                {
                                    "id": "16513",
                                    "name": "Xã Quảng Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16516",
                                    "name": "Xã Quảng Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16528",
                                    "name": "Phường Quảng Thọ"
                                },
                                {
                                    "id": "16531",
                                    "name": "Phường Quảng Châu"
                                },
                                {
                                    "id": "16534",
                                    "name": "Phường Quảng Vinh"
                                },
                                {
                                    "id": "16537",
                                    "name": "Xã Quảng Đại",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "384",
                        "name": "Huyện Mường Lát",
                        "Wards": [
                                {
                                    "id": "14845",
                                    "name": "Thị trấn Mường Lát",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14848",
                                    "name": "Xã Tam Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14854",
                                    "name": "Xã Mường Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14857",
                                    "name": "Xã Trung Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14860",
                                    "name": "Xã Quang Chiểu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14863",
                                    "name": "Xã Pù Nhi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14864",
                                    "name": "Xã Nhi Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14866",
                                    "name": "Xã Mường Chanh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "385",
                        "name": "Huyện Quan Hóa",
                        "Wards": [
                                {
                                    "id": "14869",
                                    "name": "Thị trấn Hồi Xuân",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14872",
                                    "name": "Xã Thành Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14875",
                                    "name": "Xã Trung Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14878",
                                    "name": "Xã Phú Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14881",
                                    "name": "Xã Trung Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14884",
                                    "name": "Xã Phú Lệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14887",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14890",
                                    "name": "Xã Phú Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14896",
                                    "name": "Xã Hiền Chung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14899",
                                    "name": "Xã Hiền Kiệt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14902",
                                    "name": "Xã Nam Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14908",
                                    "name": "Xã Thiên Phủ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14911",
                                    "name": "Xã Phú Nghiêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14914",
                                    "name": "Xã Nam Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14917",
                                    "name": "Xã Nam Động",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "386",
                        "name": "Huyện Bá Thước",
                        "Wards": [
                                {
                                    "id": "14923",
                                    "name": "Thị trấn Cành Nàng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "14926",
                                    "name": "Xã Điền Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14929",
                                    "name": "Xã Điền Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14932",
                                    "name": "Xã Điền Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14935",
                                    "name": "Xã Điền Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14938",
                                    "name": "Xã Thành Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14941",
                                    "name": "Xã Lương Ngoại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14944",
                                    "name": "Xã Ái Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14947",
                                    "name": "Xã Lương Nội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14950",
                                    "name": "Xã Điền Lư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14953",
                                    "name": "Xã Lương Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14956",
                                    "name": "Xã Lũng Niêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14959",
                                    "name": "Xã Lũng Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14962",
                                    "name": "Xã Hạ Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14965",
                                    "name": "Xã Cổ Lũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14968",
                                    "name": "Xã Thành Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14971",
                                    "name": "Xã Ban Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14974",
                                    "name": "Xã Kỳ Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14977",
                                    "name": "Xã Văn Nho",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14980",
                                    "name": "Xã Thiết Ống",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14986",
                                    "name": "Xã Thiết Kế",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "387",
                        "name": "Huyện Quan Sơn",
                        "Wards": [
                                {
                                    "id": "14995",
                                    "name": "Xã Trung Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14998",
                                    "name": "Xã Trung Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "14999",
                                    "name": "Xã Trung Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15001",
                                    "name": "Xã Trung Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15004",
                                    "name": "Xã Sơn Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15007",
                                    "name": "Xã Tam Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15010",
                                    "name": "Xã Sơn Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15013",
                                    "name": "Xã Na Mèo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15016",
                                    "name": "Thị trấn Sơn Lư",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15019",
                                    "name": "Xã Tam Lư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15022",
                                    "name": "Xã Sơn Điện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15025",
                                    "name": "Xã Mường Mìn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "388",
                        "name": "Huyện Lang Chánh",
                        "Wards": [
                                {
                                    "id": "15031",
                                    "name": "Xã Yên Khương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15034",
                                    "name": "Xã Yên Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15037",
                                    "name": "Xã Trí Nang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15040",
                                    "name": "Xã Giao An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15043",
                                    "name": "Xã Giao Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15046",
                                    "name": "Xã Tân Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15049",
                                    "name": "Xã Tam Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15052",
                                    "name": "Xã Lâm Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15055",
                                    "name": "Thị trấn Lang Chánh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15058",
                                    "name": "Xã Đồng Lương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "389",
                        "name": "Huyện Ngọc Lặc",
                        "Wards": [
                                {
                                    "id": "15061",
                                    "name": "Thị Trấn Ngọc Lặc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15064",
                                    "name": "Xã Lam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15067",
                                    "name": "Xã Mỹ Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15070",
                                    "name": "Xã Thúy Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15073",
                                    "name": "Xã Thạch Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15076",
                                    "name": "Xã Vân Âm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15079",
                                    "name": "Xã Cao Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15085",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15088",
                                    "name": "Xã Đồng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15091",
                                    "name": "Xã Ngọc Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15094",
                                    "name": "Xã Ngọc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15097",
                                    "name": "Xã Lộc Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15100",
                                    "name": "Xã Cao Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15103",
                                    "name": "Xã Ngọc Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15106",
                                    "name": "Xã Phùng Giáo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15109",
                                    "name": "Xã Phùng Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15112",
                                    "name": "Xã Phúc Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15115",
                                    "name": "Xã Nguyệt Ấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15118",
                                    "name": "Xã Kiên Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15121",
                                    "name": "Xã Minh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15124",
                                    "name": "Xã Minh Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "390",
                        "name": "Huyện Cẩm Thủy",
                        "Wards": [
                                {
                                    "id": "15127",
                                    "name": "Thị trấn Phong Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15133",
                                    "name": "Xã Cẩm Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15136",
                                    "name": "Xã Cẩm Quý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15139",
                                    "name": "Xã Cẩm Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15142",
                                    "name": "Xã Cẩm Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15145",
                                    "name": "Xã Cẩm Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15148",
                                    "name": "Xã Cẩm Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15151",
                                    "name": "Xã Cẩm Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15154",
                                    "name": "Xã Cẩm Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15160",
                                    "name": "Xã Cẩm Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15163",
                                    "name": "Xã Cẩm Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15169",
                                    "name": "Xã Cẩm Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15172",
                                    "name": "Xã Cẩm Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15175",
                                    "name": "Xã Cẩm Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15178",
                                    "name": "Xã Cẩm Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15181",
                                    "name": "Xã Cẩm Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15184",
                                    "name": "Xã Cẩm Vân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "391",
                        "name": "Huyện Thạch Thành",
                        "Wards": [
                                {
                                    "id": "15187",
                                    "name": "Thị trấn Kim Tân",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15190",
                                    "name": "Thị trấn Vân Du",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15196",
                                    "name": "Xã Thạch Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15199",
                                    "name": "Xã Thạch Quảng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15202",
                                    "name": "Xã Thạch Tượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15205",
                                    "name": "Xã Thạch Cẩm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15208",
                                    "name": "Xã Thạch Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15211",
                                    "name": "Xã Thạch Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15214",
                                    "name": "Xã Thạch Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15217",
                                    "name": "Xã Thạch Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15220",
                                    "name": "Xã Thạch Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15223",
                                    "name": "Xã Thành Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15226",
                                    "name": "Xã Thành Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15229",
                                    "name": "Xã Thành Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15232",
                                    "name": "Xã Thành Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15235",
                                    "name": "Xã Thành Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15238",
                                    "name": "Xã Thành Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15241",
                                    "name": "Xã Thành Trực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15247",
                                    "name": "Xã Thành Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15250",
                                    "name": "Xã Thành An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15253",
                                    "name": "Xã Thành Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15256",
                                    "name": "Xã Thành Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15259",
                                    "name": "Xã Thành Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15265",
                                    "name": "Xã Thành Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15268",
                                    "name": "Xã Ngọc Trạo",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "392",
                        "name": "Huyện Hà Trung",
                        "Wards": [
                                {
                                    "id": "15271",
                                    "name": "Thị trấn Hà Trung",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15274",
                                    "name": "Xã Hà Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15277",
                                    "name": "Xã Hà Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15280",
                                    "name": "Xã Hà Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15283",
                                    "name": "Xã Hoạt Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15286",
                                    "name": "Xã Yên Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15292",
                                    "name": "Xã Hà Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15298",
                                    "name": "Xã Lĩnh Toại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15304",
                                    "name": "Xã Hà Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15307",
                                    "name": "Xã Yến Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15313",
                                    "name": "Xã Hà Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15316",
                                    "name": "Xã Hà Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15319",
                                    "name": "Xã Hà Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15322",
                                    "name": "Xã Hà Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15325",
                                    "name": "Xã Hà Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15328",
                                    "name": "Xã Hà Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15331",
                                    "name": "Xã Hà Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15334",
                                    "name": "Xã Hà Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15340",
                                    "name": "Xã Hà Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15343",
                                    "name": "Xã Hà Hải",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "393",
                        "name": "Huyện Vĩnh Lộc",
                        "Wards": [
                                {
                                    "id": "15349",
                                    "name": "Thị trấn Vĩnh Lộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15352",
                                    "name": "Xã Vĩnh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15355",
                                    "name": "Xã Vĩnh Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15358",
                                    "name": "Xã Vĩnh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15361",
                                    "name": "Xã Vĩnh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15364",
                                    "name": "Xã Vĩnh Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15367",
                                    "name": "Xã Vĩnh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15376",
                                    "name": "Xã Vĩnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15379",
                                    "name": "Xã Vĩnh Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15382",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15385",
                                    "name": "Xã Ninh Khang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15388",
                                    "name": "Xã Vĩnh Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15391",
                                    "name": "Xã Vĩnh An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "394",
                        "name": "Huyện Yên Định",
                        "Wards": [
                                {
                                    "id": "15397",
                                    "name": "Thị trấn Thống Nhất",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15403",
                                    "name": "Xã Yên Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15406",
                                    "name": "Xã Yên Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15409",
                                    "name": "Xã Yên Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15412",
                                    "name": "Xã Quí Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15415",
                                    "name": "Xã Yên Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15418",
                                    "name": "Xã Yên Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15421",
                                    "name": "Xã Yên Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15427",
                                    "name": "Xã Yên Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15430",
                                    "name": "Xã Yên Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15433",
                                    "name": "Xã Yên Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15436",
                                    "name": "Xã Yên Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15439",
                                    "name": "Xã Yên Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15442",
                                    "name": "Xã Yên Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15445",
                                    "name": "Xã Định Tăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15448",
                                    "name": "Xã Định Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15451",
                                    "name": "Xã Định Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15454",
                                    "name": "Xã Định Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15457",
                                    "name": "Xã Định Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15460",
                                    "name": "Xã Định Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15463",
                                    "name": "Xã Định Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15466",
                                    "name": "Xã Định Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15469",
                                    "name": "Thị trấn Quán Lào",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15472",
                                    "name": "Xã Định Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15475",
                                    "name": "Xã Định Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15478",
                                    "name": "Xã Định Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "395",
                        "name": "Huyện Thọ Xuân",
                        "Wards": [
                                {
                                    "id": "15493",
                                    "name": "Xã Xuân Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15499",
                                    "name": "Thị trấn Thọ Xuân",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15502",
                                    "name": "Xã Bắc Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15505",
                                    "name": "Xã Nam Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15508",
                                    "name": "Xã Xuân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15511",
                                    "name": "Xã Thọ Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15514",
                                    "name": "Xã Xuân Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15517",
                                    "name": "Xã Xuân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15520",
                                    "name": "Xã Thọ Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15523",
                                    "name": "Xã Tây Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15526",
                                    "name": "Xã Xuân Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15532",
                                    "name": "Xã Xuân Sinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15535",
                                    "name": "Xã Xuân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15538",
                                    "name": "Xã Thọ Diên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15541",
                                    "name": "Xã Thọ Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15544",
                                    "name": "Xã Thọ Xương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15547",
                                    "name": "Xã Xuân Bái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15550",
                                    "name": "Xã Xuân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15553",
                                    "name": "Thị trấn Sao Vàng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15556",
                                    "name": "Thị trấn Lam Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15559",
                                    "name": "Xã Xuân Thiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15565",
                                    "name": "Xã Thuận Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15568",
                                    "name": "Xã Thọ Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15571",
                                    "name": "Xã Quảng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15574",
                                    "name": "Xã Xuân Tín",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15577",
                                    "name": "Xã Phú Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15583",
                                    "name": "Xã Xuân Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15586",
                                    "name": "Xã Xuân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15592",
                                    "name": "Xã Xuân Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15598",
                                    "name": "Xã Trường Xuân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "396",
                        "name": "Huyện Thường Xuân",
                        "Wards": [
                                {
                                    "id": "15607",
                                    "name": "Xã Bát Mọt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15610",
                                    "name": "Xã Yên Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15619",
                                    "name": "Xã Xuân Lẹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15622",
                                    "name": "Xã Vạn Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15628",
                                    "name": "Xã Lương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15631",
                                    "name": "Xã Xuân Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15634",
                                    "name": "Xã Luận Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15637",
                                    "name": "Xã Luận Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15640",
                                    "name": "Xã Xuân Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15643",
                                    "name": "Xã Xuân Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15646",
                                    "name": "Thị trấn Thường Xuân",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15649",
                                    "name": "Xã Xuân Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15652",
                                    "name": "Xã Thọ Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15655",
                                    "name": "Xã Ngọc Phụng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15658",
                                    "name": "Xã Xuân Chinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15661",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "397",
                        "name": "Huyện Triệu Sơn",
                        "Wards": [
                                {
                                    "id": "15664",
                                    "name": "Thị trấn Triệu Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15667",
                                    "name": "Xã Thọ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15670",
                                    "name": "Xã Thọ Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15673",
                                    "name": "Xã Thọ Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15676",
                                    "name": "Xã Hợp Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15679",
                                    "name": "Xã Hợp Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15682",
                                    "name": "Xã Hợp Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15685",
                                    "name": "Xã Triệu Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15688",
                                    "name": "Xã Hợp Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15691",
                                    "name": "Xã Minh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15700",
                                    "name": "Xã Dân Lực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15703",
                                    "name": "Xã Dân Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15706",
                                    "name": "Xã Dân Quyền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15709",
                                    "name": "Xã An Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15712",
                                    "name": "Xã Văn Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15715",
                                    "name": "Xã Thái Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15718",
                                    "name": "Thị trấn Nưa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15721",
                                    "name": "Xã Đồng Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15724",
                                    "name": "Xã Đồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15727",
                                    "name": "Xã Đồng Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15730",
                                    "name": "Xã Tiến Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15733",
                                    "name": "Xã Khuyến Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15736",
                                    "name": "Xã Xuân Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15739",
                                    "name": "Xã Xuân Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15742",
                                    "name": "Xã Thọ Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15745",
                                    "name": "Xã Xuân Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15748",
                                    "name": "Xã Thọ Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15751",
                                    "name": "Xã Thọ Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15754",
                                    "name": "Xã Thọ Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15757",
                                    "name": "Xã Thọ Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15760",
                                    "name": "Xã Thọ Vực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15763",
                                    "name": "Xã Thọ Thế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15766",
                                    "name": "Xã Nông Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15769",
                                    "name": "Xã Bình Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "398",
                        "name": "Huyện Thiệu Hóa",
                        "Wards": [
                                {
                                    "id": "15772",
                                    "name": "Thị trấn Thiệu Hóa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15775",
                                    "name": "Xã Thiệu Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15778",
                                    "name": "Xã Thiệu Vũ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15781",
                                    "name": "Xã Thiệu Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15784",
                                    "name": "Xã Thiệu Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15787",
                                    "name": "Xã Thiệu Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15790",
                                    "name": "Xã Thiệu Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15793",
                                    "name": "Xã Thiệu Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15796",
                                    "name": "Xã Thiệu Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15799",
                                    "name": "Xã Thiệu Duy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15802",
                                    "name": "Xã Thiệu Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15805",
                                    "name": "Xã Thiệu Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15808",
                                    "name": "Xã Thiệu Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15811",
                                    "name": "Xã Thiệu Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15814",
                                    "name": "Xã Thiệu Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15817",
                                    "name": "Xã Thiệu Toán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15820",
                                    "name": "Xã Thiệu Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15823",
                                    "name": "Xã Thiệu Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15829",
                                    "name": "Xã Minh Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15832",
                                    "name": "Xã Thiệu Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15835",
                                    "name": "Xã Thiệu Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15838",
                                    "name": "Xã Thiệu Vận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15841",
                                    "name": "Xã Thiệu Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15847",
                                    "name": "Xã Tân Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15853",
                                    "name": "Xã Thiệu Giao",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "399",
                        "name": "Huyện Hoằng Hóa",
                        "Wards": [
                                {
                                    "id": "15865",
                                    "name": "Thị trấn Bút Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "15871",
                                    "name": "Xã Hoằng Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15877",
                                    "name": "Xã Hoằng Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15880",
                                    "name": "Xã Hoằng Phượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15883",
                                    "name": "Xã Hoằng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15886",
                                    "name": "Xã Hoằng Quỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15889",
                                    "name": "Xã Hoằng Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15892",
                                    "name": "Xã Hoằng Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15895",
                                    "name": "Xã Hoằng Trinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15901",
                                    "name": "Xã Hoằng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15907",
                                    "name": "Xã Hoằng Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15910",
                                    "name": "Xã Hoằng Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15916",
                                    "name": "Xã Hoằng Quý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15919",
                                    "name": "Xã Hoằng Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15928",
                                    "name": "Xã Hoằng Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15937",
                                    "name": "Xã Hoằng Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15940",
                                    "name": "Xã Hoằng Đạt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15946",
                                    "name": "Xã Hoằng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15949",
                                    "name": "Xã Hoằng Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15952",
                                    "name": "Xã Hoằng Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15955",
                                    "name": "Xã Hoằng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15958",
                                    "name": "Xã Hoằng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15961",
                                    "name": "Xã Hoằng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15964",
                                    "name": "Xã Hoằng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15967",
                                    "name": "Xã Hoằng Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15973",
                                    "name": "Xã Hoằng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15976",
                                    "name": "Xã Hoằng Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15979",
                                    "name": "Xã Hoằng Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15982",
                                    "name": "Xã Hoằng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15985",
                                    "name": "Xã Hoằng Yến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15988",
                                    "name": "Xã Hoằng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15991",
                                    "name": "Xã Hoằng Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15994",
                                    "name": "Xã Hoằng Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "15997",
                                    "name": "Xã Hoằng Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16000",
                                    "name": "Xã Hoằng Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16003",
                                    "name": "Xã Hoằng Phụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16006",
                                    "name": "Xã Hoằng Trường",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "400",
                        "name": "Huyện Hậu Lộc",
                        "Wards": [
                                {
                                    "id": "16012",
                                    "name": "Thị trấn Hậu Lộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16015",
                                    "name": "Xã Đồng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16018",
                                    "name": "Xã Đại Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16021",
                                    "name": "Xã Triệu Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16027",
                                    "name": "Xã Tiến Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16030",
                                    "name": "Xã Lộc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16033",
                                    "name": "Xã Cầu Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16036",
                                    "name": "Xã Thành Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16039",
                                    "name": "Xã Tuy Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16042",
                                    "name": "Xã Phong Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16045",
                                    "name": "Xã Mỹ Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16048",
                                    "name": "Xã Thuần Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16057",
                                    "name": "Xã Xuân Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16063",
                                    "name": "Xã Hoa Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16066",
                                    "name": "Xã Liên Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16069",
                                    "name": "Xã Quang Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16072",
                                    "name": "Xã Phú Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16075",
                                    "name": "Xã Hòa Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16078",
                                    "name": "Xã Minh Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16081",
                                    "name": "Xã Hưng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16084",
                                    "name": "Xã Hải Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16087",
                                    "name": "Xã Đa Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16090",
                                    "name": "Xã Ngư Lộc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "401",
                        "name": "Huyện Nga Sơn",
                        "Wards": [
                                {
                                    "id": "16093",
                                    "name": "Thị trấn Nga Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16096",
                                    "name": "Xã Ba Đình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16099",
                                    "name": "Xã Nga Vịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16102",
                                    "name": "Xã Nga Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16105",
                                    "name": "Xã Nga Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16108",
                                    "name": "Xã Nga Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16114",
                                    "name": "Xã Nga Phượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16117",
                                    "name": "Xã Nga Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16120",
                                    "name": "Xã Nga Bạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16123",
                                    "name": "Xã Nga Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16132",
                                    "name": "Xã Nga Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16135",
                                    "name": "Xã Nga Giáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16138",
                                    "name": "Xã Nga Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16141",
                                    "name": "Xã Nga Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16144",
                                    "name": "Xã Nga An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16147",
                                    "name": "Xã Nga Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16150",
                                    "name": "Xã Nga Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16153",
                                    "name": "Xã Nga Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16156",
                                    "name": "Xã Nga Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16159",
                                    "name": "Xã Nga Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16162",
                                    "name": "Xã Nga Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16165",
                                    "name": "Xã Nga Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16168",
                                    "name": "Xã Nga Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16171",
                                    "name": "Xã Nga Trường",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "402",
                        "name": "Huyện Như Xuân",
                        "Wards": [
                                {
                                    "id": "16174",
                                    "name": "Thị trấn Yên Cát",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16177",
                                    "name": "Xã Bãi Trành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16180",
                                    "name": "Xã Xuân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16183",
                                    "name": "Xã Xuân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16186",
                                    "name": "Xã Hóa Quỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16195",
                                    "name": "Xã Cát Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16198",
                                    "name": "Xã Cát Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16201",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16204",
                                    "name": "Xã Bình Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16207",
                                    "name": "Xã Thanh Quân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16210",
                                    "name": "Xã Thanh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16213",
                                    "name": "Xã Thanh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16216",
                                    "name": "Xã Thanh Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16219",
                                    "name": "Xã Thanh Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16222",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16225",
                                    "name": "Xã Thượng Ninh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "403",
                        "name": "Huyện Như Thanh",
                        "Wards": [
                                {
                                    "id": "16228",
                                    "name": "Thị trấn Bến Sung",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16231",
                                    "name": "Xã Cán Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16234",
                                    "name": "Xã Xuân Du",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16240",
                                    "name": "Xã Phượng Nghi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16243",
                                    "name": "Xã Mậu Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16246",
                                    "name": "Xã Xuân Khang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16249",
                                    "name": "Xã Phú Nhuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16252",
                                    "name": "Xã Hải Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16258",
                                    "name": "Xã Xuân Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16261",
                                    "name": "Xã Xuân Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16264",
                                    "name": "Xã Yên Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16267",
                                    "name": "Xã Yên Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16273",
                                    "name": "Xã Thanh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16276",
                                    "name": "Xã Thanh Kỳ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "404",
                        "name": "Huyện Nông Cống",
                        "Wards": [
                                {
                                    "id": "16279",
                                    "name": "Thị trấn Nông Cống",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16282",
                                    "name": "Xã Tân Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16285",
                                    "name": "Xã Tân Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16288",
                                    "name": "Xã Hoàng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16291",
                                    "name": "Xã Tân Khang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16294",
                                    "name": "Xã Hoàng Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16297",
                                    "name": "Xã Trung Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16303",
                                    "name": "Xã Trung Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16309",
                                    "name": "Xã Tế Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16315",
                                    "name": "Xã Tế Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16318",
                                    "name": "Xã Tế Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16321",
                                    "name": "Xã Minh Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16324",
                                    "name": "Xã Minh Khôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16327",
                                    "name": "Xã Vạn Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16330",
                                    "name": "Xã Trường Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16333",
                                    "name": "Xã Vạn Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16336",
                                    "name": "Xã Trường Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16339",
                                    "name": "Xã Vạn Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16342",
                                    "name": "Xã Thăng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16345",
                                    "name": "Xã Trường Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16348",
                                    "name": "Xã Trường Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16351",
                                    "name": "Xã Thăng Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16354",
                                    "name": "Xã Công Liêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16357",
                                    "name": "Xã Tượng Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16360",
                                    "name": "Xã Thăng Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16363",
                                    "name": "Xã Tượng Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16366",
                                    "name": "Xã Tượng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16369",
                                    "name": "Xã Công Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16375",
                                    "name": "Xã Yên Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "405",
                        "name": "Huyện Đông Sơn",
                        "Wards": [
                                {
                                    "id": "16378",
                                    "name": "Thị trấn Rừng Thông",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16381",
                                    "name": "Xã Đông Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16384",
                                    "name": "Xã Đông Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16390",
                                    "name": "Xã Đông Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16393",
                                    "name": "Xã Đông Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16399",
                                    "name": "Xã Đông Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16402",
                                    "name": "Xã Đông Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16405",
                                    "name": "Xã Đông Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16408",
                                    "name": "Xã Đông Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16414",
                                    "name": "Xã Đông Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16417",
                                    "name": "Xã Đông Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16420",
                                    "name": "Xã Đông Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16423",
                                    "name": "Xã Đông Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16426",
                                    "name": "Xã Đông Quang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "406",
                        "name": "Huyện Quảng Xương",
                        "Wards": [
                                {
                                    "id": "16438",
                                    "name": "Thị trấn Tân Phong",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16447",
                                    "name": "Xã Quảng Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16453",
                                    "name": "Xã Quảng Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16456",
                                    "name": "Xã Quảng Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16462",
                                    "name": "Xã Quảng Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16465",
                                    "name": "Xã Quảng Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16468",
                                    "name": "Xã Quảng Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16471",
                                    "name": "Xã Quảng Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16474",
                                    "name": "Xã Quảng Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16477",
                                    "name": "Xã Quảng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16480",
                                    "name": "Xã Quảng Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16483",
                                    "name": "Xã Quảng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16489",
                                    "name": "Xã Quảng Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16492",
                                    "name": "Xã Quảng Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16495",
                                    "name": "Xã Quảng Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16498",
                                    "name": "Xã Quảng Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16501",
                                    "name": "Xã Quảng Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16510",
                                    "name": "Xã Quảng Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16519",
                                    "name": "Xã Quảng Giao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16540",
                                    "name": "Xã Quảng Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16543",
                                    "name": "Xã Quảng Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16546",
                                    "name": "Xã Quảng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16549",
                                    "name": "Xã Tiên Trang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16552",
                                    "name": "Xã Quảng Nham",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16555",
                                    "name": "Xã Quảng Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16558",
                                    "name": "Xã Quảng Thái",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "407",
                        "name": "Thị xã Nghi Sơn",
                        "Wards": [
                                {
                                    "id": "16561",
                                    "name": "Phường Hải Hòa"
                                },
                                {
                                    "id": "16564",
                                    "name": "Phường Hải Châu"
                                },
                                {
                                    "id": "16567",
                                    "name": "Xã Thanh Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16570",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16576",
                                    "name": "Phường Hải Ninh"
                                },
                                {
                                    "id": "16579",
                                    "name": "Xã Anh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16582",
                                    "name": "Xã Ngọc Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16585",
                                    "name": "Phường Hải An"
                                },
                                {
                                    "id": "16591",
                                    "name": "Xã Các Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16594",
                                    "name": "Phường Tân Dân"
                                },
                                {
                                    "id": "16597",
                                    "name": "Phường Hải Lĩnh"
                                },
                                {
                                    "id": "16600",
                                    "name": "Xã Định Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16603",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16606",
                                    "name": "Phường Ninh Hải"
                                },
                                {
                                    "id": "16609",
                                    "name": "Phường Nguyên Bình"
                                },
                                {
                                    "id": "16612",
                                    "name": "Xã Hải Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16618",
                                    "name": "Phường Bình Minh"
                                },
                                {
                                    "id": "16621",
                                    "name": "Phường Hải Thanh"
                                },
                                {
                                    "id": "16624",
                                    "name": "Xã Phú Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16627",
                                    "name": "Phường Xuân Lâm"
                                },
                                {
                                    "id": "16630",
                                    "name": "Phường Trúc Lâm"
                                },
                                {
                                    "id": "16633",
                                    "name": "Phường Hải Bình"
                                },
                                {
                                    "id": "16636",
                                    "name": "Xã Tân Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16639",
                                    "name": "Xã Tùng Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16642",
                                    "name": "Phường Tĩnh Hải"
                                },
                                {
                                    "id": "16645",
                                    "name": "Phường Mai Lâm"
                                },
                                {
                                    "id": "16648",
                                    "name": "Xã Trường Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16651",
                                    "name": "Xã Hải Yến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16654",
                                    "name": "Phường Hải Thượng"
                                },
                                {
                                    "id": "16657",
                                    "name": "Xã Nghi Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16660",
                                    "name": "Xã Hải Hà",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "40",
            "name": "Tỉnh Nghệ An",
            "districts": [
                    {
                        "id": "412",
                        "name": "Thành phố Vinh",
                        "Wards": [
                                {
                                    "id": "16663",
                                    "name": "Phường Đông Vĩnh"
                                },
                                {
                                    "id": "16666",
                                    "name": "Phường Hà Huy Tập"
                                },
                                {
                                    "id": "16669",
                                    "name": "Phường Lê Lợi"
                                },
                                {
                                    "id": "16670",
                                    "name": "Phường Quán Bàu"
                                },
                                {
                                    "id": "16672",
                                    "name": "Phường Hưng Bình"
                                },
                                {
                                    "id": "16673",
                                    "name": "Phường Hưng Phúc"
                                },
                                {
                                    "id": "16675",
                                    "name": "Phường Hưng Dũng"
                                },
                                {
                                    "id": "16678",
                                    "name": "Phường Cửa Nam"
                                },
                                {
                                    "id": "16681",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "16684",
                                    "name": "Phường Đội Cung"
                                },
                                {
                                    "id": "16687",
                                    "name": "Phường Lê Mao"
                                },
                                {
                                    "id": "16690",
                                    "name": "Phường Trường Thi"
                                },
                                {
                                    "id": "16693",
                                    "name": "Phường Bến Thủy"
                                },
                                {
                                    "id": "16696",
                                    "name": "Phường Hồng Sơn"
                                },
                                {
                                    "id": "16699",
                                    "name": "Phường Trung Đô"
                                },
                                {
                                    "id": "16702",
                                    "name": "Xã Nghi Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16705",
                                    "name": "Xã Hưng Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16708",
                                    "name": "Xã Hưng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16711",
                                    "name": "Xã Hưng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16714",
                                    "name": "Phường Vinh Tân"
                                },
                                {
                                    "id": "17908",
                                    "name": "Xã Nghi Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17914",
                                    "name": "Xã Nghi Ân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17920",
                                    "name": "Xã Nghi Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17923",
                                    "name": "Xã Nghi Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18013",
                                    "name": "Xã Hưng Chính",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "413",
                        "name": "Thị xã Cửa Lò",
                        "Wards": [
                                {
                                    "id": "16717",
                                    "name": "Phường Nghi Thuỷ"
                                },
                                {
                                    "id": "16720",
                                    "name": "Phường Nghi Tân"
                                },
                                {
                                    "id": "16723",
                                    "name": "Phường Thu Thuỷ"
                                },
                                {
                                    "id": "16726",
                                    "name": "Phường Nghi Hòa"
                                },
                                {
                                    "id": "16729",
                                    "name": "Phường Nghi Hải"
                                },
                                {
                                    "id": "16732",
                                    "name": "Phường Nghi Hương"
                                },
                                {
                                    "id": "16735",
                                    "name": "Phường Nghi Thu"
                                }
                        ]
                    },
                    {
                        "id": "414",
                        "name": "Thị xã Thái Hoà",
                        "Wards": [
                                {
                                    "id": "16939",
                                    "name": "Phường Hoà Hiếu"
                                },
                                {
                                    "id": "16993",
                                    "name": "Phường Quang Phong"
                                },
                                {
                                    "id": "16994",
                                    "name": "Phường Quang Tiến"
                                },
                                {
                                    "id": "17003",
                                    "name": "Phường Long Sơn"
                                },
                                {
                                    "id": "17005",
                                    "name": "Xã Nghĩa Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17008",
                                    "name": "Xã Nghĩa Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17011",
                                    "name": "Xã Tây Hiếu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17014",
                                    "name": "Xã Nghĩa Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17017",
                                    "name": "Xã Đông Hiếu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "415",
                        "name": "Huyện Quế Phong",
                        "Wards": [
                                {
                                    "id": "16738",
                                    "name": "Thị trấn Kim Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16741",
                                    "name": "Xã Thông Thụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16744",
                                    "name": "Xã Đồng Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16747",
                                    "name": "Xã Hạnh Dịch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16750",
                                    "name": "Xã Tiền Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16753",
                                    "name": "Xã Nậm Giải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16756",
                                    "name": "Xã Tri Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16759",
                                    "name": "Xã Châu Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16763",
                                    "name": "Xã Mường Nọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16765",
                                    "name": "Xã Châu Thôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16768",
                                    "name": "Xã Nậm Nhoóng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16771",
                                    "name": "Xã Quang Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16774",
                                    "name": "Xã Căm Muộn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "416",
                        "name": "Huyện Quỳ Châu",
                        "Wards": [
                                {
                                    "id": "16777",
                                    "name": "Thị trấn Tân Lạc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16780",
                                    "name": "Xã Châu Bính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16783",
                                    "name": "Xã Châu Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16786",
                                    "name": "Xã Châu Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16789",
                                    "name": "Xã Châu Nga",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16792",
                                    "name": "Xã Châu Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16795",
                                    "name": "Xã Châu Hạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16798",
                                    "name": "Xã Châu Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16801",
                                    "name": "Xã Châu Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16804",
                                    "name": "Xã Châu Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16807",
                                    "name": "Xã Châu Hoàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16810",
                                    "name": "Xã Diên Lãm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "417",
                        "name": "Huyện Kỳ Sơn",
                        "Wards": [
                                {
                                    "id": "16813",
                                    "name": "Thị trấn Mường Xén",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16816",
                                    "name": "Xã Mỹ Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16819",
                                    "name": "Xã Bắc Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16822",
                                    "name": "Xã Keng Đu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16825",
                                    "name": "Xã Đoọc Mạy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16828",
                                    "name": "Xã Huồi Tụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16831",
                                    "name": "Xã Mường Lống",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16834",
                                    "name": "Xã Na Loi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16837",
                                    "name": "Xã Nậm Cắn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16840",
                                    "name": "Xã Bảo Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16843",
                                    "name": "Xã Phà Đánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16846",
                                    "name": "Xã Bảo Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16849",
                                    "name": "Xã Hữu Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16852",
                                    "name": "Xã Tà Cạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16855",
                                    "name": "Xã Chiêu Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16858",
                                    "name": "Xã Mường Típ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16861",
                                    "name": "Xã Hữu Kiệm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16864",
                                    "name": "Xã Tây Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16867",
                                    "name": "Xã Mường Ải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16870",
                                    "name": "Xã Na Ngoi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16873",
                                    "name": "Xã Nậm Càn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "418",
                        "name": "Huyện Tương Dương",
                        "Wards": [
                                {
                                    "id": "16876",
                                    "name": "Thị trấn Thạch Giám",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16879",
                                    "name": "Xã Mai Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16882",
                                    "name": "Xã Nhôn Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16885",
                                    "name": "Xã Hữu Khuông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16900",
                                    "name": "Xã Yên Tĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16903",
                                    "name": "Xã Nga My",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16904",
                                    "name": "Xã Xiêng My",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16906",
                                    "name": "Xã Lưỡng Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16909",
                                    "name": "Xã Yên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16912",
                                    "name": "Xã Yên Na",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16915",
                                    "name": "Xã Lưu Kiền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16921",
                                    "name": "Xã Xá Lượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16924",
                                    "name": "Xã Tam Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16927",
                                    "name": "Xã Tam Đình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16930",
                                    "name": "Xã Yên Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16933",
                                    "name": "Xã Tam Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16936",
                                    "name": "Xã Tam Hợp",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "419",
                        "name": "Huyện Nghĩa Đàn",
                        "Wards": [
                                {
                                    "id": "16941",
                                    "name": "Thị trấn Nghĩa Đàn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "16942",
                                    "name": "Xã Nghĩa Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16945",
                                    "name": "Xã Nghĩa Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16948",
                                    "name": "Xã Nghĩa Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16951",
                                    "name": "Xã Nghĩa Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16954",
                                    "name": "Xã Nghĩa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16957",
                                    "name": "Xã Nghĩa Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16960",
                                    "name": "Xã Nghĩa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16963",
                                    "name": "Xã Nghĩa Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16966",
                                    "name": "Xã Nghĩa Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16969",
                                    "name": "Xã Nghĩa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16972",
                                    "name": "Xã Nghĩa Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16975",
                                    "name": "Xã Nghĩa Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16978",
                                    "name": "Xã Nghĩa Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16981",
                                    "name": "Xã Nghĩa Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16984",
                                    "name": "Xã Nghĩa Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16987",
                                    "name": "Xã Nghĩa Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "16996",
                                    "name": "Xã Nghĩa Hiếu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17020",
                                    "name": "Xã Nghĩa Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17023",
                                    "name": "Xã Nghĩa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17026",
                                    "name": "Xã Nghĩa Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17029",
                                    "name": "Xã Nghĩa Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17032",
                                    "name": "Xã Nghĩa Khánh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "420",
                        "name": "Huyện Quỳ Hợp",
                        "Wards": [
                                {
                                    "id": "17035",
                                    "name": "Thị trấn Quỳ Hợp",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17038",
                                    "name": "Xã Yên Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17041",
                                    "name": "Xã Châu Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17044",
                                    "name": "Xã Châu Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17047",
                                    "name": "Xã Đồng Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17050",
                                    "name": "Xã Châu Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17053",
                                    "name": "Xã Liên Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17056",
                                    "name": "Xã Châu Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17059",
                                    "name": "Xã Tam Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17062",
                                    "name": "Xã Châu Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17065",
                                    "name": "Xã Châu Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17068",
                                    "name": "Xã Thọ Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17071",
                                    "name": "Xã Minh Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17074",
                                    "name": "Xã Nghĩa Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17077",
                                    "name": "Xã Châu Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17080",
                                    "name": "Xã Châu Đình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17083",
                                    "name": "Xã Văn Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17086",
                                    "name": "Xã Nam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17089",
                                    "name": "Xã Châu Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17092",
                                    "name": "Xã Hạ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17095",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "421",
                        "name": "Huyện Quỳnh Lưu",
                        "Wards": [
                                {
                                    "id": "17098",
                                    "name": "Thị trấn Cầu Giát",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17101",
                                    "name": "Xã Quỳnh Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17119",
                                    "name": "Xã Quỳnh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17122",
                                    "name": "Xã Quỳnh Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17140",
                                    "name": "Xã Tân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17143",
                                    "name": "Xã Quỳnh Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17146",
                                    "name": "Xã Ngọc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17149",
                                    "name": "Xã Quỳnh Tam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17152",
                                    "name": "Xã Quỳnh Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17155",
                                    "name": "Xã Quỳnh Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17158",
                                    "name": "Xã Quỳnh Bảng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17161",
                                    "name": "Xã Quỳnh Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17164",
                                    "name": "Xã Quỳnh Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17167",
                                    "name": "Xã Quỳnh Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17170",
                                    "name": "Xã Quỳnh Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17173",
                                    "name": "Xã Quỳnh Đôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17176",
                                    "name": "Xã Quỳnh Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17179",
                                    "name": "Xã Quỳnh Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17182",
                                    "name": "Xã Quỳnh Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17185",
                                    "name": "Xã Quỳnh Bá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17188",
                                    "name": "Xã Quỳnh Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17191",
                                    "name": "Xã Quỳnh Diễn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17194",
                                    "name": "Xã Quỳnh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17197",
                                    "name": "Xã Quỳnh Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17200",
                                    "name": "Xã Quỳnh Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17203",
                                    "name": "Xã Quỳnh Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17206",
                                    "name": "Xã An Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17209",
                                    "name": "Xã Tiến Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17212",
                                    "name": "Xã Sơn Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17215",
                                    "name": "Xã Quỳnh Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17218",
                                    "name": "Xã Quỳnh Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17221",
                                    "name": "Xã Quỳnh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17224",
                                    "name": "Xã Tân Thắng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "422",
                        "name": "Huyện Con Cuông",
                        "Wards": [
                                {
                                    "id": "17227",
                                    "name": "Thị trấn Con Cuông",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17230",
                                    "name": "Xã Bình Chuẩn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17233",
                                    "name": "Xã Lạng Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17236",
                                    "name": "Xã Cam Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17239",
                                    "name": "Xã Thạch Ngàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17242",
                                    "name": "Xã Đôn Phục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17245",
                                    "name": "Xã Mậu Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17248",
                                    "name": "Xã Châu Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17251",
                                    "name": "Xã Chi Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17254",
                                    "name": "Xã Bồng Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17257",
                                    "name": "Xã Yên Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17260",
                                    "name": "Xã Lục Dạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17263",
                                    "name": "Xã Môn Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "423",
                        "name": "Huyện Tân Kỳ",
                        "Wards": [
                                {
                                    "id": "17266",
                                    "name": "Thị trấn Tân Kỳ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17269",
                                    "name": "Xã Tân Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17272",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17275",
                                    "name": "Xã Tân Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17278",
                                    "name": "Xã Giai Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17281",
                                    "name": "Xã Nghĩa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17284",
                                    "name": "Xã Nghĩa Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17287",
                                    "name": "Xã Đồng Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17290",
                                    "name": "Xã Nghĩa Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17293",
                                    "name": "Xã Nghĩa Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17296",
                                    "name": "Xã Nghĩa Hoàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17299",
                                    "name": "Xã Nghĩa Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17302",
                                    "name": "Xã Tiên Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17305",
                                    "name": "Xã Tân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17308",
                                    "name": "Xã Nghĩa Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17311",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17314",
                                    "name": "Xã Kỳ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17317",
                                    "name": "Xã Hương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17320",
                                    "name": "Xã Kỳ Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17323",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17325",
                                    "name": "Xã Tân Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17326",
                                    "name": "Xã Nghĩa Hành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "424",
                        "name": "Huyện Anh Sơn",
                        "Wards": [
                                {
                                    "id": "17329",
                                    "name": "Thị trấn Anh Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17332",
                                    "name": "Xã Thọ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17335",
                                    "name": "Xã Thành Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17338",
                                    "name": "Xã Bình Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17341",
                                    "name": "Xã Tam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17344",
                                    "name": "Xã Đỉnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17347",
                                    "name": "Xã Hùng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17350",
                                    "name": "Xã Cẩm Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17353",
                                    "name": "Xã Đức Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17356",
                                    "name": "Xã Tường Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17357",
                                    "name": "Xã Hoa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17359",
                                    "name": "Xã Tào Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17362",
                                    "name": "Xã Vĩnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17365",
                                    "name": "Xã Lạng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17368",
                                    "name": "Xã Hội Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17371",
                                    "name": "Xã Thạch Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17374",
                                    "name": "Xã Phúc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17377",
                                    "name": "Xã Long Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17380",
                                    "name": "Xã Khai Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17383",
                                    "name": "Xã Lĩnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17386",
                                    "name": "Xã Cao Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "425",
                        "name": "Huyện Diễn Châu",
                        "Wards": [
                                {
                                    "id": "17389",
                                    "name": "Thị trấn Diễn Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17392",
                                    "name": "Xã Diễn Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17395",
                                    "name": "Xã Diễn Đoài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17398",
                                    "name": "Xã Diễn Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17401",
                                    "name": "Xã Diễn Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17404",
                                    "name": "Xã Diễn Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17407",
                                    "name": "Xã Diễn Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17410",
                                    "name": "Xã Diễn Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17413",
                                    "name": "Xã Diễn Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17416",
                                    "name": "Xã Diễn Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17419",
                                    "name": "Xã Diễn Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17422",
                                    "name": "Xã Diễn Tháp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17425",
                                    "name": "Xã Diễn Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17428",
                                    "name": "Xã Diễn Vạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17431",
                                    "name": "Xã Diễn Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17434",
                                    "name": "Xã Diễn Kỷ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17437",
                                    "name": "Xã Diễn Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17440",
                                    "name": "Xã Diễn Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17443",
                                    "name": "Xã Diễn Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17446",
                                    "name": "Xã Diễn Bích",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17449",
                                    "name": "Xã Diễn Hạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17452",
                                    "name": "Xã Diễn Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17455",
                                    "name": "Xã Diễn Quảng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17458",
                                    "name": "Xã Diễn Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17461",
                                    "name": "Xã Diễn Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17464",
                                    "name": "Xã Diễn Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17467",
                                    "name": "Xã Diễn Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17476",
                                    "name": "Xã Diễn Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17479",
                                    "name": "Xã Diễn Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17482",
                                    "name": "Xã Diễn Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17485",
                                    "name": "Xã Minh Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17488",
                                    "name": "Xã Diễn Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17491",
                                    "name": "Xã Diễn Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17494",
                                    "name": "Xã Diễn Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17497",
                                    "name": "Xã Diễn Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17500",
                                    "name": "Xã Diễn An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17503",
                                    "name": "Xã Diễn Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "426",
                        "name": "Huyện Yên Thành",
                        "Wards": [
                                {
                                    "id": "17506",
                                    "name": "Thị trấn Yên Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17509",
                                    "name": "Xã Mã Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17510",
                                    "name": "Xã Tiến Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17512",
                                    "name": "Xã Lăng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17515",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17518",
                                    "name": "Xã Đức Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17521",
                                    "name": "Xã Kim Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17524",
                                    "name": "Xã Hậu Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17525",
                                    "name": "Xã Hùng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17527",
                                    "name": "Xã Đô Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17530",
                                    "name": "Xã Thọ Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17533",
                                    "name": "Xã Quang Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17536",
                                    "name": "Xã Tây Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17539",
                                    "name": "Xã Phúc Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17542",
                                    "name": "Xã Hồng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17545",
                                    "name": "Xã Đồng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17548",
                                    "name": "Xã Phú Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17551",
                                    "name": "Xã Hoa Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17554",
                                    "name": "Xã Tăng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17557",
                                    "name": "Xã Văn Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17560",
                                    "name": "Xã Thịnh Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17563",
                                    "name": "Xã Hợp Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17566",
                                    "name": "Xã Xuân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17569",
                                    "name": "Xã Bắc Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17572",
                                    "name": "Xã Nhân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17575",
                                    "name": "Xã Trung Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17578",
                                    "name": "Xã Long Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17581",
                                    "name": "Xã Minh Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17584",
                                    "name": "Xã Nam Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17587",
                                    "name": "Xã Vĩnh Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17590",
                                    "name": "Xã Lý Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17593",
                                    "name": "Xã Khánh Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17596",
                                    "name": "Xã Viên Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17599",
                                    "name": "Xã Đại Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17602",
                                    "name": "Xã Liên Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17605",
                                    "name": "Xã Bảo Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17608",
                                    "name": "Xã Mỹ Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17611",
                                    "name": "Xã Công Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17614",
                                    "name": "Xã Sơn Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "427",
                        "name": "Huyện Đô Lương",
                        "Wards": [
                                {
                                    "id": "17617",
                                    "name": "Thị trấn Đô Lương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17619",
                                    "name": "Xã Giang Sơn Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17620",
                                    "name": "Xã Giang Sơn Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17623",
                                    "name": "Xã Lam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17626",
                                    "name": "Xã Bồi Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17629",
                                    "name": "Xã Hồng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17632",
                                    "name": "Xã Bài Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17635",
                                    "name": "Xã Ngọc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17638",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17641",
                                    "name": "Xã Tràng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17644",
                                    "name": "Xã Thượng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17647",
                                    "name": "Xã Hòa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17650",
                                    "name": "Xã Đặng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17653",
                                    "name": "Xã Đông Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17656",
                                    "name": "Xã Nam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17659",
                                    "name": "Xã Lưu Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17662",
                                    "name": "Xã Yên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17665",
                                    "name": "Xã Văn Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17668",
                                    "name": "Xã Đà Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17671",
                                    "name": "Xã Lạc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17674",
                                    "name": "Xã Tân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17677",
                                    "name": "Xã Thái Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17680",
                                    "name": "Xã Quang Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17683",
                                    "name": "Xã Thịnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17686",
                                    "name": "Xã Trung Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17689",
                                    "name": "Xã Xuân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17692",
                                    "name": "Xã Minh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17695",
                                    "name": "Xã Thuận Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17698",
                                    "name": "Xã Nhân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17701",
                                    "name": "Xã Hiến Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17704",
                                    "name": "Xã Mỹ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17707",
                                    "name": "Xã Trù Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17710",
                                    "name": "Xã Đại Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "428",
                        "name": "Huyện Thanh Chương",
                        "Wards": [
                                {
                                    "id": "17713",
                                    "name": "Thị trấn Thanh Chương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17716",
                                    "name": "Xã Cát Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17719",
                                    "name": "Xã Thanh Nho",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17722",
                                    "name": "Xã Hạnh Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17723",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17725",
                                    "name": "Xã Thanh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17728",
                                    "name": "Xã Phong Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17731",
                                    "name": "Xã Thanh Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17734",
                                    "name": "Xã Thanh Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17737",
                                    "name": "Xã Thanh Tiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17743",
                                    "name": "Xã Thanh Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17749",
                                    "name": "Xã Đại Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17752",
                                    "name": "Xã Thanh Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17755",
                                    "name": "Xã Thanh Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17758",
                                    "name": "Xã Thanh Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17759",
                                    "name": "Xã Ngọc Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17761",
                                    "name": "Xã Thanh Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17764",
                                    "name": "Xã Đồng Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17767",
                                    "name": "Xã Ngọc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17770",
                                    "name": "Xã Thanh Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17773",
                                    "name": "Xã Thanh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17776",
                                    "name": "Xã Thanh Chi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17779",
                                    "name": "Xã Xuân Tường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17782",
                                    "name": "Xã Thanh Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17785",
                                    "name": "Xã Thanh Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17788",
                                    "name": "Xã Thanh Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17791",
                                    "name": "Xã Võ Liệt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17794",
                                    "name": "Xã Thanh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17797",
                                    "name": "Xã Thanh Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17800",
                                    "name": "Xã Thanh Khai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17803",
                                    "name": "Xã Thanh Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17806",
                                    "name": "Xã Thanh Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17809",
                                    "name": "Xã Thanh Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17812",
                                    "name": "Xã Thanh Tùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17815",
                                    "name": "Xã Thanh Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17818",
                                    "name": "Xã Thanh Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17821",
                                    "name": "Xã Thanh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17824",
                                    "name": "Xã Thanh Đức",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "429",
                        "name": "Huyện Nghi Lộc",
                        "Wards": [
                                {
                                    "id": "17827",
                                    "name": "Thị trấn Quán Hành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17830",
                                    "name": "Xã Nghi Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17833",
                                    "name": "Xã Nghi Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17836",
                                    "name": "Xã Nghi Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17839",
                                    "name": "Xã Nghi Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17842",
                                    "name": "Xã Nghi Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17845",
                                    "name": "Xã Nghi Thiết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17848",
                                    "name": "Xã Nghi Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17851",
                                    "name": "Xã Nghi Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17854",
                                    "name": "Xã Nghi Kiều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17857",
                                    "name": "Xã Nghi Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17860",
                                    "name": "Xã Nghi Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17863",
                                    "name": "Xã Nghi Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17866",
                                    "name": "Xã Nghi Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17869",
                                    "name": "Xã Nghi Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17875",
                                    "name": "Xã Nghi Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17878",
                                    "name": "Xã Khánh Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17881",
                                    "name": "Xã Nghi Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17884",
                                    "name": "Xã Nghi Công Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17887",
                                    "name": "Xã Nghi Công Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17890",
                                    "name": "Xã Nghi Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17893",
                                    "name": "Xã Nghi Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17896",
                                    "name": "Xã Nghi Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17899",
                                    "name": "Xã Nghi Diên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17902",
                                    "name": "Xã Nghi Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17905",
                                    "name": "Xã Nghi Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17911",
                                    "name": "Xã Nghi Vạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17917",
                                    "name": "Xã Phúc Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17926",
                                    "name": "Xã Nghi Thái",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "430",
                        "name": "Huyện Nam Đàn",
                        "Wards": [
                                {
                                    "id": "17932",
                                    "name": "Xã Nam Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17935",
                                    "name": "Xã Nam Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17938",
                                    "name": "Xã Nam Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17941",
                                    "name": "Xã Nam Anh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17944",
                                    "name": "Xã Nam Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17947",
                                    "name": "Xã Nam Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17950",
                                    "name": "Thị trấn Nam Đàn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "17953",
                                    "name": "Xã Nam Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17956",
                                    "name": "Xã Nam Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17959",
                                    "name": "Xã Xuân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17962",
                                    "name": "Xã Hùng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17968",
                                    "name": "Xã Thượng Tân Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17971",
                                    "name": "Xã Kim Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17977",
                                    "name": "Xã Hồng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17980",
                                    "name": "Xã Xuân Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17983",
                                    "name": "Xã Nam Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17986",
                                    "name": "Xã Khánh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17989",
                                    "name": "Xã Trung Phúc Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17998",
                                    "name": "Xã Nam Kim",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "431",
                        "name": "Huyện Hưng Nguyên",
                        "Wards": [
                                {
                                    "id": "18001",
                                    "name": "Thị trấn Hưng Nguyên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18004",
                                    "name": "Xã Hưng Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18007",
                                    "name": "Xã Hưng Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18008",
                                    "name": "Xã Hưng Yên Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18010",
                                    "name": "Xã Hưng Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18016",
                                    "name": "Xã Hưng Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18019",
                                    "name": "Xã Hưng Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18022",
                                    "name": "Xã Hưng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18025",
                                    "name": "Xã Hưng Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18028",
                                    "name": "Xã Hưng Thông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18031",
                                    "name": "Xã Hưng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18034",
                                    "name": "Xã Hưng Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18037",
                                    "name": "Xã Hưng Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18040",
                                    "name": "Xã Hưng Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18043",
                                    "name": "Xã Long Xá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18052",
                                    "name": "Xã Châu Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18055",
                                    "name": "Xã Xuân Lam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18064",
                                    "name": "Xã Hưng Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "432",
                        "name": "Thị xã Hoàng Mai",
                        "Wards": [
                                {
                                    "id": "17104",
                                    "name": "Xã Quỳnh Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17107",
                                    "name": "Xã Quỳnh Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17110",
                                    "name": "Phường Quỳnh Thiện"
                                },
                                {
                                    "id": "17113",
                                    "name": "Xã Quỳnh Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17116",
                                    "name": "Xã Quỳnh Trang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "17125",
                                    "name": "Phường Mai Hùng"
                                },
                                {
                                    "id": "17128",
                                    "name": "Phường Quỳnh Dị"
                                },
                                {
                                    "id": "17131",
                                    "name": "Phường Quỳnh Xuân"
                                },
                                {
                                    "id": "17134",
                                    "name": "Phường Quỳnh Phương"
                                },
                                {
                                    "id": "17137",
                                    "name": "Xã Quỳnh Liên",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "42",
            "name": "Tỉnh Hà Tĩnh",
            "districts": [
                    {
                        "id": "436",
                        "name": "Thành phố Hà Tĩnh",
                        "Wards": [
                                {
                                    "id": "18070",
                                    "name": "Phường Trần Phú"
                                },
                                {
                                    "id": "18073",
                                    "name": "Phường Nam Hà"
                                },
                                {
                                    "id": "18076",
                                    "name": "Phường Bắc Hà"
                                },
                                {
                                    "id": "18077",
                                    "name": "Phường Nguyễn Du"
                                },
                                {
                                    "id": "18079",
                                    "name": "Phường Tân Giang"
                                },
                                {
                                    "id": "18082",
                                    "name": "Phường Đại Nài"
                                },
                                {
                                    "id": "18085",
                                    "name": "Phường Hà Huy Tập"
                                },
                                {
                                    "id": "18088",
                                    "name": "Xã Thạch Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18091",
                                    "name": "Phường Thạch Quý"
                                },
                                {
                                    "id": "18094",
                                    "name": "Phường Thạch Linh"
                                },
                                {
                                    "id": "18097",
                                    "name": "Phường Văn Yên"
                                },
                                {
                                    "id": "18100",
                                    "name": "Xã Thạch Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18103",
                                    "name": "Xã Đồng Môn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18109",
                                    "name": "Xã Thạch Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18112",
                                    "name": "Xã Thạch Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "437",
                        "name": "Thị xã Hồng Lĩnh",
                        "Wards": [
                                {
                                    "id": "18115",
                                    "name": "Phường Bắc Hồng"
                                },
                                {
                                    "id": "18118",
                                    "name": "Phường Nam Hồng"
                                },
                                {
                                    "id": "18121",
                                    "name": "Phường Trung Lương"
                                },
                                {
                                    "id": "18124",
                                    "name": "Phường Đức Thuận"
                                },
                                {
                                    "id": "18127",
                                    "name": "Phường Đậu Liêu"
                                },
                                {
                                    "id": "18130",
                                    "name": "Xã Thuận Lộc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "439",
                        "name": "Huyện Hương Sơn",
                        "Wards": [
                                {
                                    "id": "18133",
                                    "name": "Thị trấn Phố Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18136",
                                    "name": "Thị trấn  Tây Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18139",
                                    "name": "Xã Sơn Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18142",
                                    "name": "Xã Sơn Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18145",
                                    "name": "Xã Sơn Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18148",
                                    "name": "Xã Sơn Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18157",
                                    "name": "Xã Sơn Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18160",
                                    "name": "Xã Sơn Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18163",
                                    "name": "Xã An Hòa Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18172",
                                    "name": "Xã Sơn Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18175",
                                    "name": "Xã Sơn Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18178",
                                    "name": "Xã Sơn Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18181",
                                    "name": "Xã Tân Mỹ Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18184",
                                    "name": "Xã Quang Diệm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18187",
                                    "name": "Xã Sơn Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18190",
                                    "name": "Xã Sơn Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18193",
                                    "name": "Xã Sơn Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18196",
                                    "name": "Xã Sơn Kim 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18199",
                                    "name": "Xã Sơn Kim 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18202",
                                    "name": "Xã Sơn Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18205",
                                    "name": "Xã Sơn Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18211",
                                    "name": "Xã Kim Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18214",
                                    "name": "Xã Sơn Hàm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18217",
                                    "name": "Xã Sơn Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18223",
                                    "name": "Xã Sơn Trường",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "440",
                        "name": "Huyện Đức Thọ",
                        "Wards": [
                                {
                                    "id": "18229",
                                    "name": "Thị trấn Đức Thọ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18235",
                                    "name": "Xã Quang Vĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18241",
                                    "name": "Xã Tùng Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18244",
                                    "name": "Xã Trường Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18247",
                                    "name": "Xã Liên Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18253",
                                    "name": "Xã Yên Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18259",
                                    "name": "Xã Tùng Ảnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18262",
                                    "name": "Xã Bùi La Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18274",
                                    "name": "Xã Thanh Bình Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18277",
                                    "name": "Xã Lâm Trung Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18280",
                                    "name": "Xã Hòa Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18283",
                                    "name": "Xã Tân Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18298",
                                    "name": "Xã An Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18304",
                                    "name": "Xã Đức Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18307",
                                    "name": "Xã Đức Lạng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18310",
                                    "name": "Xã Tân Hương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "441",
                        "name": "Huyện Vũ Quang",
                        "Wards": [
                                {
                                    "id": "18313",
                                    "name": "Thị trấn Vũ Quang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18316",
                                    "name": "Xã Ân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18319",
                                    "name": "Xã Đức Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18322",
                                    "name": "Xã Đức Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18325",
                                    "name": "Xã Thọ Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18328",
                                    "name": "Xã Đức Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18331",
                                    "name": "Xã Đức Bồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18334",
                                    "name": "Xã Đức Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18340",
                                    "name": "Xã Hương Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18343",
                                    "name": "Xã Quang Thọ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "442",
                        "name": "Huyện Nghi Xuân",
                        "Wards": [
                                {
                                    "id": "18352",
                                    "name": "Thị trấn Xuân An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18355",
                                    "name": "Xã Xuân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18358",
                                    "name": "Xã Đan Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18364",
                                    "name": "Xã Xuân Phổ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18367",
                                    "name": "Xã Xuân Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18370",
                                    "name": "Xã Xuân Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18373",
                                    "name": "Thị trấn Tiên Điền",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18376",
                                    "name": "Xã Xuân Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18379",
                                    "name": "Xã Xuân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18382",
                                    "name": "Xã Xuân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18385",
                                    "name": "Xã Xuân Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18388",
                                    "name": "Xã Xuân Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18391",
                                    "name": "Xã Cỗ Đạm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18394",
                                    "name": "Xã Xuân Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18397",
                                    "name": "Xã Xuân Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18400",
                                    "name": "Xã Xuân Lam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18403",
                                    "name": "Xã Cương Gián",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "443",
                        "name": "Huyện Can Lộc",
                        "Wards": [
                                {
                                    "id": "18406",
                                    "name": "Thị trấn Nghèn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18415",
                                    "name": "Xã Thiên Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18418",
                                    "name": "Xã Thuần Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18427",
                                    "name": "Xã Vượng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18433",
                                    "name": "Xã Thanh Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18436",
                                    "name": "Xã Kim Song Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18439",
                                    "name": "Xã Thường Nga",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18445",
                                    "name": "Xã Tùng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18454",
                                    "name": "Xã Phú Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18463",
                                    "name": "Xã Gia Hanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18466",
                                    "name": "Xã Khánh Vĩnh Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18472",
                                    "name": "Xã Trung Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18475",
                                    "name": "Xã Xuân Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18478",
                                    "name": "Xã Thượng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18481",
                                    "name": "Xã Quang Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18484",
                                    "name": "Thị trấn Đồng Lộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18487",
                                    "name": "Xã Mỹ Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18490",
                                    "name": "Xã Sơn Lộc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "444",
                        "name": "Huyện Hương Khê",
                        "Wards": [
                                {
                                    "id": "18496",
                                    "name": "Thị trấn Hương Khê",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18499",
                                    "name": "Xã Điền Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18502",
                                    "name": "Xã Hà Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18505",
                                    "name": "Xã Hương Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18508",
                                    "name": "Xã Hòa Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18514",
                                    "name": "Xã Phúc Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18517",
                                    "name": "Xã Hương Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18520",
                                    "name": "Xã Lộc Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18523",
                                    "name": "Xã Hương Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18526",
                                    "name": "Xã Hương Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18529",
                                    "name": "Xã Phú Gia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18532",
                                    "name": "Xã Gia Phố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18535",
                                    "name": "Xã Phú Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18538",
                                    "name": "Xã Hương Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18541",
                                    "name": "Xã Hương Vĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18544",
                                    "name": "Xã Hương Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18547",
                                    "name": "Xã Phúc Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18550",
                                    "name": "Xã Hương Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18553",
                                    "name": "Xã Hương Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18556",
                                    "name": "Xã Hương Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18559",
                                    "name": "Xã Hương Liên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "445",
                        "name": "Huyện Thạch Hà",
                        "Wards": [
                                {
                                    "id": "18562",
                                    "name": "Thị trấn Thạch Hà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18565",
                                    "name": "Xã Ngọc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18571",
                                    "name": "Xã Thạch Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18586",
                                    "name": "Xã Thạch Kênh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18589",
                                    "name": "Xã Thạch Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18592",
                                    "name": "Xã Thạch Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18595",
                                    "name": "Xã Đỉnh Bàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18601",
                                    "name": "Xã Việt Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18604",
                                    "name": "Xã Thạch Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18607",
                                    "name": "Xã Thạch Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18619",
                                    "name": "Xã Thạch Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18622",
                                    "name": "Xã Thạch Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18625",
                                    "name": "Xã Thạch Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18628",
                                    "name": "Xã Tượng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18631",
                                    "name": "Xã Thạch Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18634",
                                    "name": "Xã Lưu Vĩnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18637",
                                    "name": "Xã Thạch Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18643",
                                    "name": "Xã Thạch Đài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18649",
                                    "name": "Xã Thạch Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18652",
                                    "name": "Xã Tân Lâm Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18658",
                                    "name": "Xã Thạch Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18667",
                                    "name": "Xã Nam Điền",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "446",
                        "name": "Huyện Cẩm Xuyên",
                        "Wards": [
                                {
                                    "id": "18673",
                                    "name": "Thị trấn Cẩm Xuyên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18676",
                                    "name": "Thị trấn Thiên Cầm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18679",
                                    "name": "Xã Yên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18682",
                                    "name": "Xã Cẩm Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18685",
                                    "name": "Xã Cẩm Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18691",
                                    "name": "Xã Cẩm Vĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18694",
                                    "name": "Xã Cẩm Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18697",
                                    "name": "Xã Cẩm Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18706",
                                    "name": "Xã Cẩm Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18709",
                                    "name": "Xã Cẩm Nhượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18712",
                                    "name": "Xã Nam Phúc Thăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18715",
                                    "name": "Xã Cẩm Duệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18721",
                                    "name": "Xã Cẩm Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18724",
                                    "name": "Xã Cẩm Quan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18727",
                                    "name": "Xã Cẩm Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18730",
                                    "name": "Xã Cẩm Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18733",
                                    "name": "Xã Cẩm Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18736",
                                    "name": "Xã Cẩm Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18739",
                                    "name": "Xã Cẩm Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18742",
                                    "name": "Xã Cẩm Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18745",
                                    "name": "Xã Cẩm Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18748",
                                    "name": "Xã Cẩm Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18751",
                                    "name": "Xã Cẩm Minh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "447",
                        "name": "Huyện Kỳ Anh",
                        "Wards": [
                                {
                                    "id": "18757",
                                    "name": "Xã Kỳ Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18760",
                                    "name": "Xã Kỳ Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18763",
                                    "name": "Xã Kỳ Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18766",
                                    "name": "Xã Kỳ Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18769",
                                    "name": "Xã Kỳ Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18772",
                                    "name": "Xã Kỳ Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18775",
                                    "name": "Xã Kỳ Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18778",
                                    "name": "Xã Kỳ Khang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18784",
                                    "name": "Xã Kỳ Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18787",
                                    "name": "Xã Kỳ Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18790",
                                    "name": "Xã Kỳ Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18793",
                                    "name": "Xã Kỳ Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18799",
                                    "name": "Xã Kỳ Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18802",
                                    "name": "Xã Kỳ Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18805",
                                    "name": "Xã Kỳ Thư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18811",
                                    "name": "Xã Kỳ Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18814",
                                    "name": "Xã Kỳ Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18838",
                                    "name": "Xã Lâm Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18844",
                                    "name": "Xã Kỳ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18850",
                                    "name": "Xã Kỳ Lạc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "448",
                        "name": "Huyện Lộc Hà",
                        "Wards": [
                                {
                                    "id": "18409",
                                    "name": "Xã Tân Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18412",
                                    "name": "Xã Hồng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18421",
                                    "name": "Xã Thịnh Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18430",
                                    "name": "Xã Bình An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18457",
                                    "name": "Xã Ích Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18493",
                                    "name": "Xã Phù Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18568",
                                    "name": "Thị trấn Lộc Hà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18577",
                                    "name": "Xã Thạch Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18580",
                                    "name": "Xã Thạch Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18583",
                                    "name": "Xã Thạch Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18598",
                                    "name": "Xã Hộ Độ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18670",
                                    "name": "Xã Mai Phụ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "449",
                        "name": "Thị xã Kỳ Anh",
                        "Wards": [
                                {
                                    "id": "18754",
                                    "name": "Phường Hưng Trí"
                                },
                                {
                                    "id": "18781",
                                    "name": "Xã Kỳ Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18796",
                                    "name": "Xã Kỳ Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18808",
                                    "name": "Xã Kỳ Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18820",
                                    "name": "Phường Kỳ Trinh"
                                },
                                {
                                    "id": "18823",
                                    "name": "Phường Kỳ Thịnh"
                                },
                                {
                                    "id": "18829",
                                    "name": "Xã Kỳ Hoa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18832",
                                    "name": "Phường Kỳ Phương"
                                },
                                {
                                    "id": "18835",
                                    "name": "Phường Kỳ Long"
                                },
                                {
                                    "id": "18841",
                                    "name": "Phường Kỳ Liên"
                                },
                                {
                                    "id": "18847",
                                    "name": "Xã Kỳ Nam",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "44",
            "name": "Tỉnh Quảng Bình",
            "districts": [
                    {
                        "id": "450",
                        "name": "Thành Phố Đồng Hới",
                        "Wards": [
                                {
                                    "id": "18853",
                                    "name": "Phường Hải Thành"
                                },
                                {
                                    "id": "18856",
                                    "name": "Phường Đồng Phú"
                                },
                                {
                                    "id": "18859",
                                    "name": "Phường Bắc Lý"
                                },
                                {
                                    "id": "18865",
                                    "name": "Phường Nam Lý"
                                },
                                {
                                    "id": "18868",
                                    "name": "Phường Đồng Hải"
                                },
                                {
                                    "id": "18871",
                                    "name": "Phường Đồng Sơn"
                                },
                                {
                                    "id": "18874",
                                    "name": "Phường Phú Hải"
                                },
                                {
                                    "id": "18877",
                                    "name": "Phường Bắc Nghĩa"
                                },
                                {
                                    "id": "18880",
                                    "name": "Phường Đức Ninh Đông"
                                },
                                {
                                    "id": "18883",
                                    "name": "Xã Quang Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18886",
                                    "name": "Xã Lộc Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18889",
                                    "name": "Xã Bảo Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18892",
                                    "name": "Xã Nghĩa Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18895",
                                    "name": "Xã Thuận Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18898",
                                    "name": "Xã Đức Ninh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "452",
                        "name": "Huyện Minh Hóa",
                        "Wards": [
                                {
                                    "id": "18901",
                                    "name": "Thị trấn Quy Đạt",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18904",
                                    "name": "Xã Dân Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18907",
                                    "name": "Xã Trọng Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18910",
                                    "name": "Xã Hóa Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18913",
                                    "name": "Xã Hồng Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18916",
                                    "name": "Xã Hóa Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18919",
                                    "name": "Xã Hóa Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18922",
                                    "name": "Xã Hóa Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18925",
                                    "name": "Xã Xuân Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18928",
                                    "name": "Xã Yên Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18931",
                                    "name": "Xã Minh Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18934",
                                    "name": "Xã Tân Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18937",
                                    "name": "Xã Hóa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18943",
                                    "name": "Xã Trung Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18946",
                                    "name": "Xã Thượng Hóa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "453",
                        "name": "Huyện Tuyên Hóa",
                        "Wards": [
                                {
                                    "id": "18949",
                                    "name": "Thị trấn Đồng Lê",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "18952",
                                    "name": "Xã Hương Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18955",
                                    "name": "Xã Kim Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18958",
                                    "name": "Xã Thanh Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18961",
                                    "name": "Xã Thanh Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18964",
                                    "name": "Xã Thuận Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18967",
                                    "name": "Xã Lâm Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18970",
                                    "name": "Xã Lê Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18973",
                                    "name": "Xã Sơn Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18976",
                                    "name": "Xã Đồng Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18979",
                                    "name": "Xã Ngư Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18985",
                                    "name": "Xã Thạch Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18988",
                                    "name": "Xã Đức Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18991",
                                    "name": "Xã Phong Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18994",
                                    "name": "Xã Mai Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "18997",
                                    "name": "Xã Tiến Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19000",
                                    "name": "Xã Châu Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19003",
                                    "name": "Xã Cao Quảng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19006",
                                    "name": "Xã Văn Hóa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "454",
                        "name": "Huyện Quảng Trạch",
                        "Wards": [
                                {
                                    "id": "19012",
                                    "name": "Xã Quảng Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19015",
                                    "name": "Xã Quảng Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19018",
                                    "name": "Xã Quảng Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19021",
                                    "name": "Xã Quảng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19024",
                                    "name": "Xã Quảng Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19027",
                                    "name": "Xã Quảng Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19030",
                                    "name": "Xã Quảng Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19033",
                                    "name": "Xã Quảng Tùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19036",
                                    "name": "Xã Cảnh Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19039",
                                    "name": "Xã Quảng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19042",
                                    "name": "Xã Quảng Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19045",
                                    "name": "Xã Quảng Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19048",
                                    "name": "Xã Cảnh Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19051",
                                    "name": "Xã Liên Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19057",
                                    "name": "Xã Quảng Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19063",
                                    "name": "Xã Phù Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19072",
                                    "name": "Xã Quảng Thanh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "455",
                        "name": "Huyện Bố Trạch",
                        "Wards": [
                                {
                                    "id": "19111",
                                    "name": "Thị trấn Hoàn Lão",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19114",
                                    "name": "Thị trấn NT Việt Trung",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19117",
                                    "name": "Xã Xuân Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19120",
                                    "name": "Xã Mỹ Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19123",
                                    "name": "Xã Hạ Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19126",
                                    "name": "Xã Bắc Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19129",
                                    "name": "Xã Lâm Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19132",
                                    "name": "Xã Thanh Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19135",
                                    "name": "Xã Liên Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19138",
                                    "name": "Xã Phúc Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19141",
                                    "name": "Xã Cự Nẫm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19144",
                                    "name": "Xã Hải Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19147",
                                    "name": "Xã Thượng Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19150",
                                    "name": "Xã Sơn Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19156",
                                    "name": "Xã Hưng Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19159",
                                    "name": "Xã Đồng Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19162",
                                    "name": "Xã Đức Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19165",
                                    "name": "Thị trấn Phong Nha",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19168",
                                    "name": "Xã Vạn Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19174",
                                    "name": "Xã Phú Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19177",
                                    "name": "Xã Trung Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19180",
                                    "name": "Xã Tây Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19183",
                                    "name": "Xã Hòa Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19186",
                                    "name": "Xã Đại Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19189",
                                    "name": "Xã Nhân Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19192",
                                    "name": "Xã Tân Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19195",
                                    "name": "Xã Nam Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19198",
                                    "name": "Xã Lý Trạch",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "456",
                        "name": "Huyện Quảng Ninh",
                        "Wards": [
                                {
                                    "id": "19201",
                                    "name": "Thị trấn Quán Hàu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19204",
                                    "name": "Xã Trường Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19207",
                                    "name": "Xã Lương Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19210",
                                    "name": "Xã Vĩnh Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19213",
                                    "name": "Xã Võ Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19216",
                                    "name": "Xã Hải Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19219",
                                    "name": "Xã Hàm Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19222",
                                    "name": "Xã Duy Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19225",
                                    "name": "Xã Gia Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19228",
                                    "name": "Xã Trường Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19231",
                                    "name": "Xã Hiền Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19234",
                                    "name": "Xã Tân Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19237",
                                    "name": "Xã Xuân Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19240",
                                    "name": "Xã An Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19243",
                                    "name": "Xã Vạn Ninh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "457",
                        "name": "Huyện Lệ Thủy",
                        "Wards": [
                                {
                                    "id": "19246",
                                    "name": "Thị trấn NT Lệ Ninh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19249",
                                    "name": "Thị trấn Kiến Giang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19252",
                                    "name": "Xã Hồng Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19255",
                                    "name": "Xã Ngư Thủy Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19258",
                                    "name": "Xã Hoa Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19261",
                                    "name": "Xã Thanh Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19264",
                                    "name": "Xã An Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19267",
                                    "name": "Xã Phong Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19270",
                                    "name": "Xã Cam Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19273",
                                    "name": "Xã Ngân Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19276",
                                    "name": "Xã Sơn Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19279",
                                    "name": "Xã Lộc Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19285",
                                    "name": "Xã Liên Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19288",
                                    "name": "Xã Hưng Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19291",
                                    "name": "Xã Dương Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19294",
                                    "name": "Xã Tân Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19297",
                                    "name": "Xã Phú Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19300",
                                    "name": "Xã Xuân Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19303",
                                    "name": "Xã Mỹ Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19306",
                                    "name": "Xã Ngư Thủy ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19309",
                                    "name": "Xã Mai Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19312",
                                    "name": "Xã Sen Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19315",
                                    "name": "Xã Thái Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19318",
                                    "name": "Xã Kim Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19321",
                                    "name": "Xã Trường Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19327",
                                    "name": "Xã Lâm Thủy",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "458",
                        "name": "Thị xã Ba Đồn",
                        "Wards": [
                                {
                                    "id": "19009",
                                    "name": "Phường Ba Đồn"
                                },
                                {
                                    "id": "19060",
                                    "name": "Phường Quảng Long"
                                },
                                {
                                    "id": "19066",
                                    "name": "Phường Quảng Thọ"
                                },
                                {
                                    "id": "19069",
                                    "name": "Xã Quảng Tiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19075",
                                    "name": "Xã Quảng Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19078",
                                    "name": "Phường Quảng Phong"
                                },
                                {
                                    "id": "19081",
                                    "name": "Phường Quảng Thuận"
                                },
                                {
                                    "id": "19084",
                                    "name": "Xã Quảng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19087",
                                    "name": "Xã Quảng Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19090",
                                    "name": "Xã Quảng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19093",
                                    "name": "Xã Quảng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19096",
                                    "name": "Xã Quảng Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19099",
                                    "name": "Xã Quảng Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19102",
                                    "name": "Phường Quảng Phúc"
                                },
                                {
                                    "id": "19105",
                                    "name": "Xã Quảng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19108",
                                    "name": "Xã Quảng Minh",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "45",
            "name": "Tỉnh Quảng Trị",
            "districts": [
                    {
                        "id": "461",
                        "name": "Thành phố Đông Hà",
                        "Wards": [
                                {
                                    "id": "19330",
                                    "name": "Phường Đông Giang"
                                },
                                {
                                    "id": "19333",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "19336",
                                    "name": "Phường Đông Lễ"
                                },
                                {
                                    "id": "19339",
                                    "name": "Phường Đông Thanh"
                                },
                                {
                                    "id": "19342",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "19345",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "19348",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "19351",
                                    "name": "Phường Đông Lương"
                                },
                                {
                                    "id": "19354",
                                    "name": "Phường 3"
                                }
                        ]
                    },
                    {
                        "id": "462",
                        "name": "Thị xã Quảng Trị",
                        "Wards": [
                                {
                                    "id": "19357",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "19358",
                                    "name": "Phường An Đôn"
                                },
                                {
                                    "id": "19360",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "19361",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "19705",
                                    "name": "Xã Hải Lệ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "464",
                        "name": "Huyện Vĩnh Linh",
                        "Wards": [
                                {
                                    "id": "19363",
                                    "name": "Thị trấn Hồ Xá",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19366",
                                    "name": "Thị trấn Bến Quan",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19369",
                                    "name": "Xã Vĩnh Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19372",
                                    "name": "Xã Vĩnh Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19375",
                                    "name": "Xã Vĩnh Chấp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19378",
                                    "name": "Xã Trung Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19384",
                                    "name": "Xã Kim Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19387",
                                    "name": "Xã Vĩnh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19393",
                                    "name": "Xã Vĩnh Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19396",
                                    "name": "Xã Vĩnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19402",
                                    "name": "Xã Vĩnh Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19405",
                                    "name": "Xã Vĩnh Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19408",
                                    "name": "Xã Hiền Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19414",
                                    "name": "Thị trấn Cửa Tùng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19417",
                                    "name": "Xã Vĩnh Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19420",
                                    "name": "Xã Vĩnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19423",
                                    "name": "Xã Vĩnh Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19426",
                                    "name": "Xã Vĩnh Ô",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "465",
                        "name": "Huyện Hướng Hóa",
                        "Wards": [
                                {
                                    "id": "19429",
                                    "name": "Thị trấn Khe Sanh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19432",
                                    "name": "Thị trấn Lao Bảo",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19435",
                                    "name": "Xã Hướng Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19438",
                                    "name": "Xã Hướng Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19441",
                                    "name": "Xã Hướng Phùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19444",
                                    "name": "Xã Hướng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19447",
                                    "name": "Xã Hướng Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19450",
                                    "name": "Xã Tân Hợp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19453",
                                    "name": "Xã Hướng Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19456",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19459",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19462",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19465",
                                    "name": "Xã Tân Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19468",
                                    "name": "Xã Húc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19471",
                                    "name": "Xã Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19474",
                                    "name": "Xã Hướng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19477",
                                    "name": "Xã Ba Tầng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19480",
                                    "name": "Xã Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19483",
                                    "name": "Xã  A Dơi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19489",
                                    "name": "Xã Lìa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19492",
                                    "name": "Xã Xy",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "466",
                        "name": "Huyện Gio Linh",
                        "Wards": [
                                {
                                    "id": "19495",
                                    "name": "Thị trấn Gio Linh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19496",
                                    "name": "Thị trấn Cửa Việt",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19498",
                                    "name": "Xã Trung Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19501",
                                    "name": "Xã Trung Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19504",
                                    "name": "Xã Trung Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19507",
                                    "name": "Xã Phong Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19510",
                                    "name": "Xã Gio Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19519",
                                    "name": "Xã Gio Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19522",
                                    "name": "Xã Gio An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19525",
                                    "name": "Xã Gio Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19531",
                                    "name": "Xã Gio Việt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19534",
                                    "name": "Xã Linh Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19537",
                                    "name": "Xã Gio Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19543",
                                    "name": "Xã Gio Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19546",
                                    "name": "Xã Hải Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19549",
                                    "name": "Xã Linh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19552",
                                    "name": "Xã Gio Quang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "467",
                        "name": "Huyện Đa Krông",
                        "Wards": [
                                {
                                    "id": "19555",
                                    "name": "Thị trấn Krông Klang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19558",
                                    "name": "Xã Mò Ó",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19561",
                                    "name": "Xã Hướng Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19564",
                                    "name": "Xã Đa Krông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19567",
                                    "name": "Xã Triệu Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19570",
                                    "name": "Xã Ba Lòng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19576",
                                    "name": "Xã Ba Nang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19579",
                                    "name": "Xã Tà Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19582",
                                    "name": "Xã Húc Nghì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19585",
                                    "name": "Xã A Vao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19588",
                                    "name": "Xã Tà Rụt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19591",
                                    "name": "Xã A Bung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19594",
                                    "name": "Xã A Ngo",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "468",
                        "name": "Huyện Cam Lộ",
                        "Wards": [
                                {
                                    "id": "19597",
                                    "name": "Thị trấn Cam Lộ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19600",
                                    "name": "Xã Cam Tuyền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19603",
                                    "name": "Xã Thanh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19606",
                                    "name": "Xã Cam Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19612",
                                    "name": "Xã Cam Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19615",
                                    "name": "Xã Cam Hiếu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19618",
                                    "name": "Xã Cam Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19621",
                                    "name": "Xã Cam Nghĩa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "469",
                        "name": "Huyện Triệu Phong",
                        "Wards": [
                                {
                                    "id": "19624",
                                    "name": "Thị Trấn Ái Tử",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19627",
                                    "name": "Xã Triệu An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19630",
                                    "name": "Xã Triệu Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19633",
                                    "name": "Xã Triệu Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19636",
                                    "name": "Xã Triệu Độ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19639",
                                    "name": "Xã Triệu Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19642",
                                    "name": "Xã Triệu Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19645",
                                    "name": "Xã Triệu Đại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19648",
                                    "name": "Xã Triệu Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19651",
                                    "name": "Xã Triệu Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19654",
                                    "name": "Xã Triệu Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19657",
                                    "name": "Xã Triệu Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19660",
                                    "name": "Xã Triệu Tài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19666",
                                    "name": "Xã Triệu Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19669",
                                    "name": "Xã Triệu Ái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19672",
                                    "name": "Xã Triệu Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19675",
                                    "name": "Xã Triệu Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19678",
                                    "name": "Xã Triệu Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "470",
                        "name": "Huyện Hải Lăng",
                        "Wards": [
                                {
                                    "id": "19681",
                                    "name": "Thị trấn Diên Sanh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19684",
                                    "name": "Xã Hải An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19687",
                                    "name": "Xã Hải Ba",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19693",
                                    "name": "Xã Hải Quy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19696",
                                    "name": "Xã Hải Quế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19699",
                                    "name": "Xã Hải Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19702",
                                    "name": "Xã Hải Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19708",
                                    "name": "Xã Hải Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19711",
                                    "name": "Xã Hải Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19714",
                                    "name": "Xã Hải Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19717",
                                    "name": "Xã Hải Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19726",
                                    "name": "Xã Hải Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19729",
                                    "name": "Xã Hải Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19735",
                                    "name": "Xã Hải Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19738",
                                    "name": "Xã Hải Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19741",
                                    "name": "Xã Hải Khê",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "471",
                        "name": "Huyện Cồn Cỏ",
                        "Wards": [
                                {
                                    "Level": "Huyện"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "46",
            "name": "Tỉnh Thừa Thiên Huế",
            "districts": [
                    {
                        "id": "474",
                        "name": "Thành phố Huế",
                        "Wards": [
                                {
                                    "id": "19744",
                                    "name": "Phường Phú Thuận"
                                },
                                {
                                    "id": "19747",
                                    "name": "Phường Phú Bình"
                                },
                                {
                                    "id": "19750",
                                    "name": "Phường Tây Lộc"
                                },
                                {
                                    "id": "19753",
                                    "name": "Phường Thuận Lộc"
                                },
                                {
                                    "id": "19756",
                                    "name": "Phường Phú Hiệp"
                                },
                                {
                                    "id": "19759",
                                    "name": "Phường Phú Hậu"
                                },
                                {
                                    "id": "19762",
                                    "name": "Phường Thuận Hòa"
                                },
                                {
                                    "id": "19765",
                                    "name": "Phường Thuận Thành"
                                },
                                {
                                    "id": "19768",
                                    "name": "Phường Phú Hòa"
                                },
                                {
                                    "id": "19771",
                                    "name": "Phường Phú Cát"
                                },
                                {
                                    "id": "19774",
                                    "name": "Phường Kim Long"
                                },
                                {
                                    "id": "19777",
                                    "name": "Phường Vĩ Dạ"
                                },
                                {
                                    "id": "19780",
                                    "name": "Phường Phường Đúc"
                                },
                                {
                                    "id": "19783",
                                    "name": "Phường Vĩnh Ninh"
                                },
                                {
                                    "id": "19786",
                                    "name": "Phường Phú Hội"
                                },
                                {
                                    "id": "19789",
                                    "name": "Phường Phú Nhuận"
                                },
                                {
                                    "id": "19792",
                                    "name": "Phường Xuân Phú"
                                },
                                {
                                    "id": "19795",
                                    "name": "Phường Trường An"
                                },
                                {
                                    "id": "19798",
                                    "name": "Phường Phước Vĩnh"
                                },
                                {
                                    "id": "19801",
                                    "name": "Phường An Cựu"
                                },
                                {
                                    "id": "19803",
                                    "name": "Phường An Hòa"
                                },
                                {
                                    "id": "19804",
                                    "name": "Phường Hương Sơ"
                                },
                                {
                                    "id": "19807",
                                    "name": "Phường Thuỷ Biều"
                                },
                                {
                                    "id": "19810",
                                    "name": "Phường Hương Long"
                                },
                                {
                                    "id": "19813",
                                    "name": "Phường Thuỷ Xuân"
                                },
                                {
                                    "id": "19815",
                                    "name": "Phường An Đông"
                                },
                                {
                                    "id": "19816",
                                    "name": "Phường An Tây"
                                }
                        ]
                    },
                    {
                        "id": "476",
                        "name": "Huyện Phong Điền",
                        "Wards": [
                                {
                                    "id": "19819",
                                    "name": "Thị trấn Phong Điền",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19822",
                                    "name": "Xã Điền Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19825",
                                    "name": "Xã Điền Môn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19828",
                                    "name": "Xã Điền Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19831",
                                    "name": "Xã Phong Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19834",
                                    "name": "Xã Điền Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19837",
                                    "name": "Xã Phong Chương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19840",
                                    "name": "Xã Phong Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19843",
                                    "name": "Xã Điền Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19846",
                                    "name": "Xã Phong Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19849",
                                    "name": "Xã Phong Thu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19852",
                                    "name": "Xã Phong Hiền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19855",
                                    "name": "Xã Phong Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19858",
                                    "name": "Xã Phong An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19861",
                                    "name": "Xã Phong Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19864",
                                    "name": "Xã Phong Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "477",
                        "name": "Huyện Quảng Điền",
                        "Wards": [
                                {
                                    "id": "19867",
                                    "name": "Thị trấn Sịa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19870",
                                    "name": "Xã Quảng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19873",
                                    "name": "Xã Quảng Ngạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19876",
                                    "name": "Xã Quảng Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19879",
                                    "name": "Xã Quảng Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19882",
                                    "name": "Xã Quảng Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19885",
                                    "name": "Xã Quảng Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19888",
                                    "name": "Xã Quảng An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19891",
                                    "name": "Xã Quảng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19894",
                                    "name": "Xã Quảng Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19897",
                                    "name": "Xã Quảng Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "478",
                        "name": "Huyện Phú Vang",
                        "Wards": [
                                {
                                    "id": "19900",
                                    "name": "Thị trấn Thuận An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19903",
                                    "name": "Xã Phú Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19906",
                                    "name": "Xã Phú Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19909",
                                    "name": "Xã Phú Mậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19912",
                                    "name": "Xã Phú An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19915",
                                    "name": "Xã Phú Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19918",
                                    "name": "Xã Phú Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19921",
                                    "name": "Xã Phú Diên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19924",
                                    "name": "Xã Phú Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19927",
                                    "name": "Xã Phú Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19930",
                                    "name": "Xã Phú Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19933",
                                    "name": "Xã Phú Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19936",
                                    "name": "Xã Vinh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19939",
                                    "name": "Xã Phú Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19942",
                                    "name": "Thị trấn Phú Đa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "19945",
                                    "name": "Xã Vinh Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19948",
                                    "name": "Xã Vinh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19954",
                                    "name": "Xã Phú Gia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19957",
                                    "name": "Xã Vinh Hà",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "479",
                        "name": "Thị xã Hương Thủy",
                        "Wards": [
                                {
                                    "id": "19960",
                                    "name": "Phường Phú Bài"
                                },
                                {
                                    "id": "19963",
                                    "name": "Xã Thủy Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19966",
                                    "name": "Xã Thủy Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19969",
                                    "name": "Phường Thủy Dương"
                                },
                                {
                                    "id": "19972",
                                    "name": "Phường Thủy Phương"
                                },
                                {
                                    "id": "19975",
                                    "name": "Phường Thủy Châu"
                                },
                                {
                                    "id": "19978",
                                    "name": "Phường Thủy Lương"
                                },
                                {
                                    "id": "19981",
                                    "name": "Xã Thủy Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19984",
                                    "name": "Xã Thủy Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19987",
                                    "name": "Xã Thủy Phù",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19990",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "19993",
                                    "name": "Xã Dương Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "480",
                        "name": "Thị xã Hương Trà",
                        "Wards": [
                                {
                                    "id": "19996",
                                    "name": "Phường Tứ Hạ"
                                },
                                {
                                    "id": "19999",
                                    "name": "Xã Hải Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20002",
                                    "name": "Xã Hương Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20005",
                                    "name": "Xã Hương Toàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20008",
                                    "name": "Phường Hương Vân"
                                },
                                {
                                    "id": "20011",
                                    "name": "Phường Hương Văn"
                                },
                                {
                                    "id": "20014",
                                    "name": "Xã Hương Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20017",
                                    "name": "Phường Hương Xuân"
                                },
                                {
                                    "id": "20020",
                                    "name": "Phường Hương Chữ"
                                },
                                {
                                    "id": "20023",
                                    "name": "Phường Hương An"
                                },
                                {
                                    "id": "20026",
                                    "name": "Xã Hương Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20029",
                                    "name": "Phường Hương Hồ"
                                },
                                {
                                    "id": "20032",
                                    "name": "Xã Hương Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20035",
                                    "name": "Xã Bình Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20041",
                                    "name": "Xã Bình Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "481",
                        "name": "Huyện A Lưới",
                        "Wards": [
                                {
                                    "id": "20044",
                                    "name": "Thị trấn A Lưới",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20047",
                                    "name": "Xã Hồng Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20050",
                                    "name": "Xã Hồng Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20053",
                                    "name": "Xã Hồng Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20056",
                                    "name": "Xã Trung Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20059",
                                    "name": "Xã Hương Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20065",
                                    "name": "Xã Hồng Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20068",
                                    "name": "Xã A Ngo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20071",
                                    "name": "Xã Sơn Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20074",
                                    "name": "Xã Phú Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20080",
                                    "name": "Xã Hương Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20083",
                                    "name": "Xã Quảng Nhâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20086",
                                    "name": "Xã Hồng Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20089",
                                    "name": "Xã Hồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20095",
                                    "name": "Xã A Roằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20098",
                                    "name": "Xã Đông Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20101",
                                    "name": "Xã Lâm Đớt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20104",
                                    "name": "Xã Hồng Thủy",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "482",
                        "name": "Huyện Phú Lộc",
                        "Wards": [
                                {
                                    "id": "20107",
                                    "name": "Thị trấn Phú Lộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20110",
                                    "name": "Thị trấn Lăng Cô",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20113",
                                    "name": "Xã Vinh Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20116",
                                    "name": "Xã Vinh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20122",
                                    "name": "Xã Giang Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20125",
                                    "name": "Xã Vinh Hiền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20128",
                                    "name": "Xã Lộc Bổn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20131",
                                    "name": "Xã Lộc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20134",
                                    "name": "Xã Lộc Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20137",
                                    "name": "Xã Lộc Vĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20140",
                                    "name": "Xã Lộc An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20143",
                                    "name": "Xã Lộc Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20146",
                                    "name": "Xã Lộc Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20149",
                                    "name": "Xã Lộc Trì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20152",
                                    "name": "Xã Lộc Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20155",
                                    "name": "Xã Lộc Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20158",
                                    "name": "Xã Xuân Lộc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "483",
                        "name": "Huyện Nam Đông",
                        "Wards": [
                                {
                                    "id": "20161",
                                    "name": "Thị trấn Khe Tre",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20164",
                                    "name": "Xã Hương Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20167",
                                    "name": "Xã Hương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20170",
                                    "name": "Xã Hương Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20173",
                                    "name": "Xã Thượng Quảng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20179",
                                    "name": "Xã Hương Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20182",
                                    "name": "Xã Hương Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20185",
                                    "name": "Xã Thượng Lộ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20188",
                                    "name": "Xã Thượng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20191",
                                    "name": "Xã Thượng Nhật",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "48",
            "name": "Thành phố Đà Nẵng",
            "districts": [
                    {
                        "id": "490",
                        "name": "Quận Liên Chiểu",
                        "Wards": [
                                {
                                    "id": "20194",
                                    "name": "Phường Hòa Hiệp Bắc"
                                },
                                {
                                    "id": "20195",
                                    "name": "Phường Hòa Hiệp Nam"
                                },
                                {
                                    "id": "20197",
                                    "name": "Phường Hòa Khánh Bắc"
                                },
                                {
                                    "id": "20198",
                                    "name": "Phường Hòa Khánh Nam"
                                },
                                {
                                    "id": "20200",
                                    "name": "Phường Hòa Minh"
                                }
                        ]
                    },
                    {
                        "id": "491",
                        "name": "Quận Thanh Khê",
                        "Wards": [
                                {
                                    "id": "20203",
                                    "name": "Phường Tam Thuận"
                                },
                                {
                                    "id": "20206",
                                    "name": "Phường Thanh Khê Tây"
                                },
                                {
                                    "id": "20207",
                                    "name": "Phường Thanh Khê Đông"
                                },
                                {
                                    "id": "20209",
                                    "name": "Phường Xuân Hà"
                                },
                                {
                                    "id": "20212",
                                    "name": "Phường Tân Chính"
                                },
                                {
                                    "id": "20215",
                                    "name": "Phường Chính Gián"
                                },
                                {
                                    "id": "20218",
                                    "name": "Phường Vĩnh Trung"
                                },
                                {
                                    "id": "20221",
                                    "name": "Phường Thạc Gián"
                                },
                                {
                                    "id": "20224",
                                    "name": "Phường An Khê"
                                },
                                {
                                    "id": "20225",
                                    "name": "Phường Hòa Khê"
                                }
                        ]
                    },
                    {
                        "id": "492",
                        "name": "Quận Hải Châu",
                        "Wards": [
                                {
                                    "id": "20227",
                                    "name": "Phường Thanh Bình"
                                },
                                {
                                    "id": "20230",
                                    "name": "Phường Thuận Phước"
                                },
                                {
                                    "id": "20233",
                                    "name": "Phường Thạch Thang"
                                },
                                {
                                    "id": "20236",
                                    "name": "Phường Hải Châu  I"
                                },
                                {
                                    "id": "20239",
                                    "name": "Phường Hải Châu II"
                                },
                                {
                                    "id": "20242",
                                    "name": "Phường Phước Ninh"
                                },
                                {
                                    "id": "20245",
                                    "name": "Phường Hòa Thuận Tây"
                                },
                                {
                                    "id": "20246",
                                    "name": "Phường Hòa Thuận Đông"
                                },
                                {
                                    "id": "20248",
                                    "name": "Phường Nam Dương"
                                },
                                {
                                    "id": "20251",
                                    "name": "Phường Bình Hiên"
                                },
                                {
                                    "id": "20254",
                                    "name": "Phường Bình Thuận"
                                },
                                {
                                    "id": "20257",
                                    "name": "Phường Hòa Cường Bắc"
                                },
                                {
                                    "id": "20258",
                                    "name": "Phường Hòa Cường Nam"
                                }
                        ]
                    },
                    {
                        "id": "493",
                        "name": "Quận Sơn Trà",
                        "Wards": [
                                {
                                    "id": "20263",
                                    "name": "Phường Thọ Quang"
                                },
                                {
                                    "id": "20266",
                                    "name": "Phường Nại Hiên Đông"
                                },
                                {
                                    "id": "20269",
                                    "name": "Phường Mân Thái"
                                },
                                {
                                    "id": "20272",
                                    "name": "Phường An Hải Bắc"
                                },
                                {
                                    "id": "20275",
                                    "name": "Phường Phước Mỹ"
                                },
                                {
                                    "id": "20278",
                                    "name": "Phường An Hải Tây"
                                },
                                {
                                    "id": "20281",
                                    "name": "Phường An Hải Đông"
                                }
                        ]
                    },
                    {
                        "id": "494",
                        "name": "Quận Ngũ Hành Sơn",
                        "Wards": [
                                {
                                    "id": "20284",
                                    "name": "Phường Mỹ An"
                                },
                                {
                                    "id": "20285",
                                    "name": "Phường Khuê Mỹ"
                                },
                                {
                                    "id": "20287",
                                    "name": "Phường Hoà Quý"
                                },
                                {
                                    "id": "20290",
                                    "name": "Phường Hoà Hải"
                                }
                        ]
                    },
                    {
                        "id": "495",
                        "name": "Quận Cẩm Lệ",
                        "Wards": [
                                {
                                    "id": "20260",
                                    "name": "Phường Khuê Trung"
                                },
                                {
                                    "id": "20305",
                                    "name": "Phường Hòa Phát"
                                },
                                {
                                    "id": "20306",
                                    "name": "Phường Hòa An"
                                },
                                {
                                    "id": "20311",
                                    "name": "Phường Hòa Thọ Tây"
                                },
                                {
                                    "id": "20312",
                                    "name": "Phường Hòa Thọ Đông"
                                },
                                {
                                    "id": "20314",
                                    "name": "Phường Hòa Xuân"
                                }
                        ]
                    },
                    {
                        "id": "497",
                        "name": "Huyện Hòa Vang",
                        "Wards": [
                                {
                                    "id": "20293",
                                    "name": "Xã Hòa Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20296",
                                    "name": "Xã Hòa Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20299",
                                    "name": "Xã Hòa Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20302",
                                    "name": "Xã Hòa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20308",
                                    "name": "Xã Hòa Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20317",
                                    "name": "Xã Hòa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20320",
                                    "name": "Xã Hòa Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20323",
                                    "name": "Xã Hòa Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20326",
                                    "name": "Xã Hòa Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20329",
                                    "name": "Xã Hòa Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20332",
                                    "name": "Xã Hòa Khương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "498",
                        "name": "Huyện Hoàng Sa",
                        "Wards": [
                                {
                                    "Level": "Huyện"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "49",
            "name": "Tỉnh Quảng Nam",
            "districts": [
                    {
                        "id": "502",
                        "name": "Thành phố Tam Kỳ",
                        "Wards": [
                                {
                                    "id": "20335",
                                    "name": "Phường Tân Thạnh"
                                },
                                {
                                    "id": "20338",
                                    "name": "Phường Phước Hòa"
                                },
                                {
                                    "id": "20341",
                                    "name": "Phường An Mỹ"
                                },
                                {
                                    "id": "20344",
                                    "name": "Phường Hòa Hương"
                                },
                                {
                                    "id": "20347",
                                    "name": "Phường An Xuân"
                                },
                                {
                                    "id": "20350",
                                    "name": "Phường An Sơn"
                                },
                                {
                                    "id": "20353",
                                    "name": "Phường Trường Xuân"
                                },
                                {
                                    "id": "20356",
                                    "name": "Phường An Phú"
                                },
                                {
                                    "id": "20359",
                                    "name": "Xã Tam Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20362",
                                    "name": "Xã Tam Thăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20371",
                                    "name": "Xã Tam Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20375",
                                    "name": "Phường Hoà Thuận"
                                },
                                {
                                    "id": "20389",
                                    "name": "Xã Tam Ngọc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "503",
                        "name": "Thành phố Hội An",
                        "Wards": [
                                {
                                    "id": "20398",
                                    "name": "Phường Minh An"
                                },
                                {
                                    "id": "20401",
                                    "name": "Phường Tân An"
                                },
                                {
                                    "id": "20404",
                                    "name": "Phường Cẩm Phô"
                                },
                                {
                                    "id": "20407",
                                    "name": "Phường Thanh Hà"
                                },
                                {
                                    "id": "20410",
                                    "name": "Phường Sơn Phong"
                                },
                                {
                                    "id": "20413",
                                    "name": "Phường Cẩm Châu"
                                },
                                {
                                    "id": "20416",
                                    "name": "Phường Cửa Đại"
                                },
                                {
                                    "id": "20419",
                                    "name": "Phường Cẩm An"
                                },
                                {
                                    "id": "20422",
                                    "name": "Xã Cẩm Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20425",
                                    "name": "Xã Cẩm Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20428",
                                    "name": "Phường Cẩm Nam"
                                },
                                {
                                    "id": "20431",
                                    "name": "Xã Cẩm Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20434",
                                    "name": "Xã Tân Hiệp",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "504",
                        "name": "Huyện Tây Giang",
                        "Wards": [
                                {
                                    "id": "20437",
                                    "name": "Xã Ch'ơm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20440",
                                    "name": "Xã Ga Ri",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20443",
                                    "name": "Xã A Xan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20446",
                                    "name": "Xã Tr'Hy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20449",
                                    "name": "Xã Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20452",
                                    "name": "Xã A Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20455",
                                    "name": "Xã A Tiêng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20458",
                                    "name": "Xã Bha Lê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20461",
                                    "name": "Xã A Vương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20464",
                                    "name": "Xã Dang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "505",
                        "name": "Huyện Đông Giang",
                        "Wards": [
                                {
                                    "id": "20467",
                                    "name": "Thị trấn P Rao",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20470",
                                    "name": "Xã Tà Lu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20473",
                                    "name": "Xã Sông Kôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20476",
                                    "name": "Xã Jơ Ngây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20479",
                                    "name": "Xã A Ting",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20482",
                                    "name": "Xã  Tư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20485",
                                    "name": "Xã Ba",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20488",
                                    "name": "Xã A Rooi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20491",
                                    "name": "Xã Za Hung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20494",
                                    "name": "Xã Mà Cooi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20497",
                                    "name": "Xã Ka Dăng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "506",
                        "name": "Huyện Đại Lộc",
                        "Wards": [
                                {
                                    "id": "20500",
                                    "name": "Thị Trấn Ái Nghĩa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20503",
                                    "name": "Xã Đại Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20506",
                                    "name": "Xã Đại Lãnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20509",
                                    "name": "Xã Đại Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20512",
                                    "name": "Xã Đại Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20515",
                                    "name": "Xã Đại Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20518",
                                    "name": "Xã Đại Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20521",
                                    "name": "Xã Đại Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20524",
                                    "name": "Xã Đại Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20527",
                                    "name": "Xã Đại Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20530",
                                    "name": "Xã Đại Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20533",
                                    "name": "Xã Đại Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20536",
                                    "name": "Xã Đại Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20539",
                                    "name": "Xã Đại Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20542",
                                    "name": "Xã Đại Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20545",
                                    "name": "Xã Đại Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20547",
                                    "name": "Xã Đại An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20548",
                                    "name": "Xã Đại Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "507",
                        "name": "Thị xã Điện Bàn",
                        "Wards": [
                                {
                                    "id": "20551",
                                    "name": "Phường Vĩnh Điện"
                                },
                                {
                                    "id": "20554",
                                    "name": "Xã Điện Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20557",
                                    "name": "Xã Điện Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20560",
                                    "name": "Xã Điện Thắng Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20561",
                                    "name": "Xã Điện Thắng Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20562",
                                    "name": "Xã Điện Thắng Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20563",
                                    "name": "Phường Điện Ngọc"
                                },
                                {
                                    "id": "20566",
                                    "name": "Xã Điện Hồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20569",
                                    "name": "Xã Điện Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20572",
                                    "name": "Xã Điện Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20575",
                                    "name": "Phường Điện An"
                                },
                                {
                                    "id": "20578",
                                    "name": "Phường Điện Nam Bắc"
                                },
                                {
                                    "id": "20579",
                                    "name": "Phường Điện Nam Trung"
                                },
                                {
                                    "id": "20580",
                                    "name": "Phường Điện Nam Đông"
                                },
                                {
                                    "id": "20581",
                                    "name": "Phường Điện Dương"
                                },
                                {
                                    "id": "20584",
                                    "name": "Xã Điện Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20587",
                                    "name": "Xã Điện Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20590",
                                    "name": "Xã Điện Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20593",
                                    "name": "Xã Điện Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20596",
                                    "name": "Xã Điện Phương",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "508",
                        "name": "Huyện Duy Xuyên",
                        "Wards": [
                                {
                                    "id": "20599",
                                    "name": "Thị trấn Nam Phước",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20602",
                                    "name": "Xã Duy Thu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20605",
                                    "name": "Xã Duy Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20608",
                                    "name": "Xã Duy Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20611",
                                    "name": "Xã Duy Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20614",
                                    "name": "Xã Duy Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20617",
                                    "name": "Xã Duy Trinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20620",
                                    "name": "Xã Duy Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20623",
                                    "name": "Xã Duy Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20626",
                                    "name": "Xã Duy Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20629",
                                    "name": "Xã Duy Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20632",
                                    "name": "Xã Duy Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20635",
                                    "name": "Xã Duy Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20638",
                                    "name": "Xã Duy Hải",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "509",
                        "name": "Huyện Quế Sơn",
                        "Wards": [
                                {
                                    "id": "20641",
                                    "name": "Thị trấn Đông Phú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20644",
                                    "name": "Xã Quế Xuân 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20647",
                                    "name": "Xã Quế Xuân 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20650",
                                    "name": "Xã Quế Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20651",
                                    "name": "Thị trấn Hương An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20659",
                                    "name": "Xã Quế Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20662",
                                    "name": "Xã Quế Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20665",
                                    "name": "Xã Quế Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20677",
                                    "name": "Xã Quế Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20680",
                                    "name": "Xã Quế Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20683",
                                    "name": "Xã Quế Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20686",
                                    "name": "Xã Quế An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20689",
                                    "name": "Xã Quế Minh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "510",
                        "name": "Huyện Nam Giang",
                        "Wards": [
                                {
                                    "id": "20695",
                                    "name": "Thị trấn Thạnh Mỹ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20698",
                                    "name": "Xã Laêê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20699",
                                    "name": "Xã Chơ Chun",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20701",
                                    "name": "Xã Zuôich",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20702",
                                    "name": "Xã Tà Pơơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20704",
                                    "name": "Xã La Dêê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20705",
                                    "name": "Xã Đắc Tôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20707",
                                    "name": "Xã Chà Vàl",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20710",
                                    "name": "Xã Tà Bhinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20713",
                                    "name": "Xã Cà Dy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20716",
                                    "name": "Xã Đắc Pre",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20719",
                                    "name": "Xã Đắc Pring",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "511",
                        "name": "Huyện Phước Sơn",
                        "Wards": [
                                {
                                    "id": "20722",
                                    "name": "Thị trấn Khâm Đức",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20725",
                                    "name": "Xã Phước Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20728",
                                    "name": "Xã Phước Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20729",
                                    "name": "Xã Phước Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20731",
                                    "name": "Xã Phước Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20734",
                                    "name": "Xã Phước Năng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20737",
                                    "name": "Xã Phước Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20740",
                                    "name": "Xã Phước Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20743",
                                    "name": "Xã Phước Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20746",
                                    "name": "Xã Phước Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20749",
                                    "name": "Xã Phước Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20752",
                                    "name": "Xã Phước Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "512",
                        "name": "Huyện Hiệp Đức",
                        "Wards": [
                                {
                                    "id": "20758",
                                    "name": "Xã Hiệp Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20761",
                                    "name": "Xã Hiệp Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20764",
                                    "name": "Xã Quế Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20767",
                                    "name": "Xã Bình Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20770",
                                    "name": "Xã Sông Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20773",
                                    "name": "Xã Phước Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20776",
                                    "name": "Xã Phước Gia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20779",
                                    "name": "Thị trấn Tân Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20782",
                                    "name": "Xã Quế Lưu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20785",
                                    "name": "Xã Thăng Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20788",
                                    "name": "Xã Bình Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "513",
                        "name": "Huyện Thăng Bình",
                        "Wards": [
                                {
                                    "id": "20791",
                                    "name": "Thị trấn Hà Lam",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20794",
                                    "name": "Xã Bình Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20797",
                                    "name": "Xã Bình Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20800",
                                    "name": "Xã Bình Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20803",
                                    "name": "Xã Bình Phục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20806",
                                    "name": "Xã Bình Triều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20809",
                                    "name": "Xã Bình Đào",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20812",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20815",
                                    "name": "Xã Bình Lãnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20818",
                                    "name": "Xã Bình Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20821",
                                    "name": "Xã Bình Định Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20822",
                                    "name": "Xã Bình Định Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20824",
                                    "name": "Xã Bình Quý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20827",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20830",
                                    "name": "Xã Bình Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20833",
                                    "name": "Xã Bình Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20836",
                                    "name": "Xã Bình Sa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20839",
                                    "name": "Xã Bình Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20842",
                                    "name": "Xã Bình Quế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20845",
                                    "name": "Xã Bình An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20848",
                                    "name": "Xã Bình Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20851",
                                    "name": "Xã Bình Nam",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "514",
                        "name": "Huyện Tiên Phước",
                        "Wards": [
                                {
                                    "id": "20854",
                                    "name": "Thị trấn Tiên Kỳ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20857",
                                    "name": "Xã Tiên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20860",
                                    "name": "Xã Tiên Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20863",
                                    "name": "Xã Tiên Cẩm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20866",
                                    "name": "Xã Tiên Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20869",
                                    "name": "Xã Tiên Lãnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20872",
                                    "name": "Xã Tiên Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20875",
                                    "name": "Xã Tiên Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20878",
                                    "name": "Xã Tiên Cảnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20881",
                                    "name": "Xã Tiên Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20884",
                                    "name": "Xã Tiên Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20887",
                                    "name": "Xã Tiên Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20890",
                                    "name": "Xã Tiên An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20893",
                                    "name": "Xã Tiên Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20896",
                                    "name": "Xã Tiên Lập",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "515",
                        "name": "Huyện Bắc Trà My",
                        "Wards": [
                                {
                                    "id": "20899",
                                    "name": "Thị trấn Trà My",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20900",
                                    "name": "Xã Trà Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20902",
                                    "name": "Xã Trà Kót",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20905",
                                    "name": "Xã Trà Nú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20908",
                                    "name": "Xã Trà Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20911",
                                    "name": "Xã Trà Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20914",
                                    "name": "Xã Trà Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20917",
                                    "name": "Xã Trà Bui",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20920",
                                    "name": "Xã Trà Đốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20923",
                                    "name": "Xã Trà Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20926",
                                    "name": "Xã Trà Giác",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20929",
                                    "name": "Xã Trà Giáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20932",
                                    "name": "Xã Trà Ka",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "516",
                        "name": "Huyện Nam Trà My",
                        "Wards": [
                                {
                                    "id": "20935",
                                    "name": "Xã Trà Leng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20938",
                                    "name": "Xã Trà Dơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20941",
                                    "name": "Xã Trà Tập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20944",
                                    "name": "Xã Trà Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20947",
                                    "name": "Xã Trà Cang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20950",
                                    "name": "Xã Trà Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20953",
                                    "name": "Xã Trà Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20956",
                                    "name": "Xã Trà Don",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20959",
                                    "name": "Xã Trà Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20962",
                                    "name": "Xã Trà Vinh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "517",
                        "name": "Huyện Núi Thành",
                        "Wards": [
                                {
                                    "id": "20965",
                                    "name": "Thị trấn Núi Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20968",
                                    "name": "Xã Tam Xuân I",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20971",
                                    "name": "Xã Tam Xuân II",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20974",
                                    "name": "Xã Tam Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20977",
                                    "name": "Xã Tam Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20980",
                                    "name": "Xã Tam Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20983",
                                    "name": "Xã Tam Anh Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20984",
                                    "name": "Xã Tam Anh Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20986",
                                    "name": "Xã Tam Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20989",
                                    "name": "Xã Tam Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20992",
                                    "name": "Xã Tam Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20995",
                                    "name": "Xã Tam Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20998",
                                    "name": "Xã Tam Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21001",
                                    "name": "Xã Tam Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21004",
                                    "name": "Xã Tam Mỹ Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21005",
                                    "name": "Xã Tam Mỹ Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21007",
                                    "name": "Xã Tam Trà",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "518",
                        "name": "Huyện Phú Ninh",
                        "Wards": [
                                {
                                    "id": "20364",
                                    "name": "Thị trấn Phú Thịnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "20365",
                                    "name": "Xã Tam Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20368",
                                    "name": "Xã Tam An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20374",
                                    "name": "Xã Tam Đàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20377",
                                    "name": "Xã Tam Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20380",
                                    "name": "Xã Tam Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20383",
                                    "name": "Xã Tam Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20386",
                                    "name": "Xã Tam Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20387",
                                    "name": "Xã Tam Đại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20392",
                                    "name": "Xã Tam Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20395",
                                    "name": "Xã Tam Lãnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "519",
                        "name": "Huyện Nông Sơn",
                        "Wards": [
                                {
                                    "id": "20656",
                                    "name": "Xã Quế Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20668",
                                    "name": "Xã Ninh Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20669",
                                    "name": "Xã Phước Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20671",
                                    "name": "Xã Quế Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20672",
                                    "name": "Xã Sơn Viên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "20692",
                                    "name": "Xã Quế Lâm",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "51",
            "name": "Tỉnh Quảng Ngãi",
            "districts": [
                    {
                        "id": "522",
                        "name": "Thành phố Quảng Ngãi",
                        "Wards": [
                                {
                                    "id": "21010",
                                    "name": "Phường Lê Hồng Phong"
                                },
                                {
                                    "id": "21013",
                                    "name": "Phường Trần Phú"
                                },
                                {
                                    "id": "21016",
                                    "name": "Phường Quảng Phú"
                                },
                                {
                                    "id": "21019",
                                    "name": "Phường Nghĩa Chánh"
                                },
                                {
                                    "id": "21022",
                                    "name": "Phường Trần Hưng Đạo"
                                },
                                {
                                    "id": "21025",
                                    "name": "Phường Nguyễn Nghiêm"
                                },
                                {
                                    "id": "21028",
                                    "name": "Phường Nghĩa Lộ"
                                },
                                {
                                    "id": "21031",
                                    "name": "Phường Chánh Lộ"
                                },
                                {
                                    "id": "21034",
                                    "name": "Xã Nghĩa Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21037",
                                    "name": "Xã Nghĩa Dõng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21172",
                                    "name": "Phường Trương Quang Trọng"
                                },
                                {
                                    "id": "21187",
                                    "name": "Xã Tịnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21190",
                                    "name": "Xã Tịnh Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21199",
                                    "name": "Xã Tịnh Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21202",
                                    "name": "Xã Tịnh Ấn Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21208",
                                    "name": "Xã Tịnh Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21211",
                                    "name": "Xã Tịnh Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21214",
                                    "name": "Xã Tịnh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21223",
                                    "name": "Xã Tịnh Ấn Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21232",
                                    "name": "Xã Tịnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21253",
                                    "name": "Xã Nghĩa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21256",
                                    "name": "Xã Nghĩa Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21262",
                                    "name": "Xã Nghĩa An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "524",
                        "name": "Huyện Bình Sơn",
                        "Wards": [
                                {
                                    "id": "21040",
                                    "name": "Thị Trấn Châu Ổ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21043",
                                    "name": "Xã Bình Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21046",
                                    "name": "Xã Bình Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21049",
                                    "name": "Xã Bình Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21052",
                                    "name": "Xã Bình Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21055",
                                    "name": "Xã Bình Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21058",
                                    "name": "Xã Bình Khương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21061",
                                    "name": "Xã Bình Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21064",
                                    "name": "Xã Bình An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21067",
                                    "name": "Xã Bình Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21070",
                                    "name": "Xã Bình Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21073",
                                    "name": "Xã Bình Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21079",
                                    "name": "Xã Bình Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21082",
                                    "name": "Xã Bình Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21085",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21088",
                                    "name": "Xã Bình Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21091",
                                    "name": "Xã Bình Thanh ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21100",
                                    "name": "Xã Bình Chương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21103",
                                    "name": "Xã Bình Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21106",
                                    "name": "Xã Bình Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21109",
                                    "name": "Xã Bình Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21112",
                                    "name": "Xã Bình Châu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "525",
                        "name": "Huyện Trà Bồng",
                        "Wards": [
                                {
                                    "id": "21115",
                                    "name": "Thị trấn Trà Xuân",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21118",
                                    "name": "Xã Trà Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21121",
                                    "name": "Xã Trà Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21124",
                                    "name": "Xã Trà Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21127",
                                    "name": "Xã Trà Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21130",
                                    "name": "Xã Trà Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21133",
                                    "name": "Xã Trà Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21136",
                                    "name": "Xã Trà Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21139",
                                    "name": "Xã Trà Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21142",
                                    "name": "Xã Trà Bùi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21145",
                                    "name": "Xã Trà Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21148",
                                    "name": "Xã Sơn Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21154",
                                    "name": "Xã Trà Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21157",
                                    "name": "Xã Hương Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21163",
                                    "name": "Xã Trà Xinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21166",
                                    "name": "Xã Trà Tây",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "527",
                        "name": "Huyện Sơn Tịnh",
                        "Wards": [
                                {
                                    "id": "21175",
                                    "name": "Xã Tịnh Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21178",
                                    "name": "Xã Tịnh Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21181",
                                    "name": "Xã Tịnh Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21184",
                                    "name": "Xã Tịnh Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21193",
                                    "name": "Xã Tịnh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21196",
                                    "name": "Xã Tịnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21205",
                                    "name": "Xã Tịnh Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21217",
                                    "name": "Xã Tịnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21220",
                                    "name": "Xã Tịnh Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21226",
                                    "name": "Xã Tịnh Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21229",
                                    "name": "Xã Tịnh Minh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "528",
                        "name": "Huyện Tư Nghĩa",
                        "Wards": [
                                {
                                    "id": "21235",
                                    "name": "Thị trấn La Hà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21238",
                                    "name": "Thị trấn Sông Vệ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21241",
                                    "name": "Xã Nghĩa Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21244",
                                    "name": "Xã Nghĩa Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21247",
                                    "name": "Xã Nghĩa Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21250",
                                    "name": "Xã Nghĩa Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21259",
                                    "name": "Xã Nghĩa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21268",
                                    "name": "Xã Nghĩa Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21271",
                                    "name": "Xã Nghĩa Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21274",
                                    "name": "Xã Nghĩa Thương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21277",
                                    "name": "Xã Nghĩa Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21280",
                                    "name": "Xã Nghĩa Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21283",
                                    "name": "Xã Nghĩa Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21286",
                                    "name": "Xã Nghĩa Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "529",
                        "name": "Huyện Sơn Hà",
                        "Wards": [
                                {
                                    "id": "21289",
                                    "name": "Thị trấn Di Lăng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21292",
                                    "name": "Xã Sơn Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21295",
                                    "name": "Xã Sơn Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21298",
                                    "name": "Xã Sơn Nham",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21301",
                                    "name": "Xã Sơn Bao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21304",
                                    "name": "Xã Sơn Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21307",
                                    "name": "Xã Sơn Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21310",
                                    "name": "Xã Sơn Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21313",
                                    "name": "Xã Sơn Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21316",
                                    "name": "Xã Sơn Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21319",
                                    "name": "Xã Sơn Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21322",
                                    "name": "Xã Sơn Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21325",
                                    "name": "Xã Sơn Kỳ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21328",
                                    "name": "Xã Sơn Ba",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "530",
                        "name": "Huyện Sơn Tây",
                        "Wards": [
                                {
                                    "id": "21331",
                                    "name": "Xã Sơn Bua",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21334",
                                    "name": "Xã Sơn Mùa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21335",
                                    "name": "Xã Sơn Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21337",
                                    "name": "Xã Sơn Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21338",
                                    "name": "Xã Sơn Màu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21340",
                                    "name": "Xã Sơn Dung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21341",
                                    "name": "Xã Sơn Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21343",
                                    "name": "Xã Sơn Tinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21346",
                                    "name": "Xã Sơn Lập",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "531",
                        "name": "Huyện Minh Long",
                        "Wards": [
                                {
                                    "id": "21349",
                                    "name": "Xã Long Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21352",
                                    "name": "Xã Long Mai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21355",
                                    "name": "Xã Thanh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21358",
                                    "name": "Xã Long Môn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21361",
                                    "name": "Xã Long Hiệp",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "532",
                        "name": "Huyện Nghĩa Hành",
                        "Wards": [
                                {
                                    "id": "21364",
                                    "name": "Thị trấn Chợ Chùa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21367",
                                    "name": "Xã Hành Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21370",
                                    "name": "Xã Hành Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21373",
                                    "name": "Xã Hành Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21376",
                                    "name": "Xã Hành Nhân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21379",
                                    "name": "Xã Hành Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21382",
                                    "name": "Xã Hành Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21385",
                                    "name": "Xã Hành Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21388",
                                    "name": "Xã Hành Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21391",
                                    "name": "Xã Hành Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21394",
                                    "name": "Xã Hành Tín Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21397",
                                    "name": "Xã Hành Tín  Đông",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "533",
                        "name": "Huyện Mộ Đức",
                        "Wards": [
                                {
                                    "id": "21400",
                                    "name": "Thị trấn Mộ Đức",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21403",
                                    "name": "Xã Đức Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21406",
                                    "name": "Xã Đức Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21409",
                                    "name": "Xã Đức Nhuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21412",
                                    "name": "Xã Đức Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21415",
                                    "name": "Xã Đức Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21418",
                                    "name": "Xã Đức Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21421",
                                    "name": "Xã Đức Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21424",
                                    "name": "Xã Đức Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21427",
                                    "name": "Xã Đức Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21430",
                                    "name": "Xã Đức Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21433",
                                    "name": "Xã Đức Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21436",
                                    "name": "Xã Đức Lân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "534",
                        "name": "Thị xã Đức Phổ",
                        "Wards": [
                                {
                                    "id": "21439",
                                    "name": "Phường Nguyễn Nghiêm"
                                },
                                {
                                    "id": "21442",
                                    "name": "Xã Phổ An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21445",
                                    "name": "Xã Phổ Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21448",
                                    "name": "Xã Phổ Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21451",
                                    "name": "Phường Phổ Văn"
                                },
                                {
                                    "id": "21454",
                                    "name": "Phường Phổ Quang"
                                },
                                {
                                    "id": "21457",
                                    "name": "Xã Phổ Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21460",
                                    "name": "Phường Phổ Ninh"
                                },
                                {
                                    "id": "21463",
                                    "name": "Phường Phổ Minh"
                                },
                                {
                                    "id": "21466",
                                    "name": "Phường Phổ Vinh"
                                },
                                {
                                    "id": "21469",
                                    "name": "Phường Phổ Hòa"
                                },
                                {
                                    "id": "21472",
                                    "name": "Xã Phổ Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21475",
                                    "name": "Xã Phổ Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21478",
                                    "name": "Phường Phổ Thạnh"
                                },
                                {
                                    "id": "21481",
                                    "name": "Xã Phổ Châu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "535",
                        "name": "Huyện Ba Tơ",
                        "Wards": [
                                {
                                    "id": "21484",
                                    "name": "Thị trấn Ba Tơ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21487",
                                    "name": "Xã Ba Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21490",
                                    "name": "Xã Ba Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21493",
                                    "name": "Xã Ba Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21496",
                                    "name": "Xã Ba Động",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21499",
                                    "name": "Xã Ba Dinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21500",
                                    "name": "Xã Ba Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21502",
                                    "name": "Xã Ba Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21505",
                                    "name": "Xã Ba Ngạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21508",
                                    "name": "Xã Ba Khâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21511",
                                    "name": "Xã Ba Cung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21517",
                                    "name": "Xã Ba Tiêu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21520",
                                    "name": "Xã Ba Trang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21523",
                                    "name": "Xã Ba Tô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21526",
                                    "name": "Xã Ba Bích",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21529",
                                    "name": "Xã Ba Vì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21532",
                                    "name": "Xã Ba Lế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21535",
                                    "name": "Xã Ba Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21538",
                                    "name": "Xã Ba Xa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "536",
                        "name": "Huyện Lý Sơn",
                        "Wards": [
                                {
                                    "Level": "Huyện"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "52",
            "name": "Tỉnh Bình Định",
            "districts": [
                    {
                        "id": "540",
                        "name": "Thành phố Quy Nhơn",
                        "Wards": [
                                {
                                    "id": "21550",
                                    "name": "Phường Nhơn Bình"
                                },
                                {
                                    "id": "21553",
                                    "name": "Phường Nhơn Phú"
                                },
                                {
                                    "id": "21556",
                                    "name": "Phường Đống Đa"
                                },
                                {
                                    "id": "21559",
                                    "name": "Phường Trần Quang Diệu"
                                },
                                {
                                    "id": "21562",
                                    "name": "Phường Hải Cảng"
                                },
                                {
                                    "id": "21565",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "21568",
                                    "name": "Phường Thị Nại"
                                },
                                {
                                    "id": "21571",
                                    "name": "Phường Lê Hồng Phong"
                                },
                                {
                                    "id": "21574",
                                    "name": "Phường Trần Hưng Đạo"
                                },
                                {
                                    "id": "21577",
                                    "name": "Phường Ngô Mây"
                                },
                                {
                                    "id": "21580",
                                    "name": "Phường Lý Thường Kiệt"
                                },
                                {
                                    "id": "21583",
                                    "name": "Phường Lê Lợi"
                                },
                                {
                                    "id": "21586",
                                    "name": "Phường Trần Phú"
                                },
                                {
                                    "id": "21589",
                                    "name": "Phường Bùi Thị Xuân"
                                },
                                {
                                    "id": "21592",
                                    "name": "Phường Nguyễn Văn Cừ"
                                },
                                {
                                    "id": "21595",
                                    "name": "Phường Ghềnh Ráng"
                                },
                                {
                                    "id": "21598",
                                    "name": "Xã Nhơn Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21601",
                                    "name": "Xã Nhơn Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21604",
                                    "name": "Xã Nhơn Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21607",
                                    "name": "Xã Nhơn Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21991",
                                    "name": "Xã Phước Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "542",
                        "name": "Huyện An Lão",
                        "Wards": [
                                {
                                    "id": "21609",
                                    "name": "Thị trấn An Lão",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21610",
                                    "name": "Xã An Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21613",
                                    "name": "Xã An Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21616",
                                    "name": "Xã An Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21619",
                                    "name": "Xã An Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21622",
                                    "name": "Xã An Toàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21625",
                                    "name": "Xã An Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21628",
                                    "name": "Xã An Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21631",
                                    "name": "Xã An Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21634",
                                    "name": "Xã An Nghĩa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "543",
                        "name": "Thị xã Hoài Nhơn",
                        "Wards": [
                                {
                                    "id": "21637",
                                    "name": "Phường Tam Quan"
                                },
                                {
                                    "id": "21640",
                                    "name": "Phường Bồng Sơn"
                                },
                                {
                                    "id": "21643",
                                    "name": "Xã Hoài Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21646",
                                    "name": "Xã Hoài Châu Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21649",
                                    "name": "Xã Hoài Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21652",
                                    "name": "Xã Hoài Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21655",
                                    "name": "Phường Tam Quan Bắc"
                                },
                                {
                                    "id": "21658",
                                    "name": "Phường Tam Quan Nam"
                                },
                                {
                                    "id": "21661",
                                    "name": "Phường Hoài Hảo"
                                },
                                {
                                    "id": "21664",
                                    "name": "Phường Hoài Thanh Tây"
                                },
                                {
                                    "id": "21667",
                                    "name": "Phường Hoài Thanh"
                                },
                                {
                                    "id": "21670",
                                    "name": "Phường Hoài Hương"
                                },
                                {
                                    "id": "21673",
                                    "name": "Phường Hoài Tân"
                                },
                                {
                                    "id": "21676",
                                    "name": "Xã Hoài Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21679",
                                    "name": "Phường Hoài Xuân"
                                },
                                {
                                    "id": "21682",
                                    "name": "Xã Hoài Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21685",
                                    "name": "Phường Hoài Đức"
                                }
                        ]
                    },
                    {
                        "id": "544",
                        "name": "Huyện Hoài Ân",
                        "Wards": [
                                {
                                    "id": "21688",
                                    "name": "Thị trấn Tăng Bạt Hổ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21690",
                                    "name": "Xã Ân Hảo Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21691",
                                    "name": "Xã Ân Hảo Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21694",
                                    "name": "Xã Ân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21697",
                                    "name": "Xã Ân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21700",
                                    "name": "Xã Đak Mang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21703",
                                    "name": "Xã Ân Tín",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21706",
                                    "name": "Xã Ân Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21709",
                                    "name": "Xã Ân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21712",
                                    "name": "Xã Ân Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21715",
                                    "name": "Xã Ân Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21718",
                                    "name": "Xã Bok Tới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21721",
                                    "name": "Xã Ân Tường Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21724",
                                    "name": "Xã Ân Tường Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21727",
                                    "name": "Xã Ân Nghĩa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "545",
                        "name": "Huyện Phù Mỹ",
                        "Wards": [
                                {
                                    "id": "21730",
                                    "name": "Thị trấn Phù Mỹ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21733",
                                    "name": "Thị trấn Bình Dương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21736",
                                    "name": "Xã Mỹ Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21739",
                                    "name": "Xã Mỹ Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21742",
                                    "name": "Xã Mỹ Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21745",
                                    "name": "Xã Mỹ Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21748",
                                    "name": "Xã Mỹ Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21751",
                                    "name": "Xã Mỹ An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21754",
                                    "name": "Xã Mỹ Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21757",
                                    "name": "Xã Mỹ Trinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21760",
                                    "name": "Xã Mỹ Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21763",
                                    "name": "Xã Mỹ Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21766",
                                    "name": "Xã Mỹ Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21769",
                                    "name": "Xã Mỹ Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21772",
                                    "name": "Xã Mỹ Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21775",
                                    "name": "Xã Mỹ Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21778",
                                    "name": "Xã Mỹ Tài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21781",
                                    "name": "Xã Mỹ Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21784",
                                    "name": "Xã Mỹ Chánh Tây",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "546",
                        "name": "Huyện Vĩnh Thạnh",
                        "Wards": [
                                {
                                    "id": "21786",
                                    "name": "Thị trấn Vĩnh Thạnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21787",
                                    "name": "Xã Vĩnh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21790",
                                    "name": "Xã Vĩnh Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21796",
                                    "name": "Xã Vĩnh Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21799",
                                    "name": "Xã Vĩnh Hảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21801",
                                    "name": "Xã Vĩnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21802",
                                    "name": "Xã Vĩnh Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21804",
                                    "name": "Xã Vĩnh Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21805",
                                    "name": "Xã Vĩnh Quang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "547",
                        "name": "Huyện Tây Sơn",
                        "Wards": [
                                {
                                    "id": "21808",
                                    "name": "Thị trấn Phú Phong",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21811",
                                    "name": "Xã Bình Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21814",
                                    "name": "Xã Tây Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21817",
                                    "name": "Xã Bình Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21820",
                                    "name": "Xã Tây Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21823",
                                    "name": "Xã Bình Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21826",
                                    "name": "Xã Tây An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21829",
                                    "name": "Xã Bình Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21832",
                                    "name": "Xã Tây Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21835",
                                    "name": "Xã Bình Tường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21838",
                                    "name": "Xã Tây Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21841",
                                    "name": "Xã Vĩnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21844",
                                    "name": "Xã Tây Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21847",
                                    "name": "Xã Bình Nghi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21850",
                                    "name": "Xã Tây Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "548",
                        "name": "Huyện Phù Cát",
                        "Wards": [
                                {
                                    "id": "21853",
                                    "name": "Thị trấn Ngô Mây",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21856",
                                    "name": "Xã Cát Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21859",
                                    "name": "Xã Cát Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21862",
                                    "name": "Xã Cát Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21865",
                                    "name": "Xã Cát Tài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21868",
                                    "name": "Xã Cát Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21871",
                                    "name": "Xã Cát Hanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21874",
                                    "name": "Xã Cát Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21877",
                                    "name": "Xã Cát Trinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21880",
                                    "name": "Xã Cát Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21883",
                                    "name": "Xã Cát Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21886",
                                    "name": "Xã Cát Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21889",
                                    "name": "Xã Cát Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21892",
                                    "name": "Xã Cát Tường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21895",
                                    "name": "Xã Cát Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21898",
                                    "name": "Xã Cát Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21901",
                                    "name": "Xã Cát Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21904",
                                    "name": "Xã Cát Chánh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "549",
                        "name": "Thị xã An Nhơn",
                        "Wards": [
                                {
                                    "id": "21907",
                                    "name": "Phường Bình Định"
                                },
                                {
                                    "id": "21910",
                                    "name": "Phường Đập Đá"
                                },
                                {
                                    "id": "21913",
                                    "name": "Xã Nhơn Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21916",
                                    "name": "Phường Nhơn Thành"
                                },
                                {
                                    "id": "21919",
                                    "name": "Xã Nhơn Hạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21922",
                                    "name": "Xã Nhơn Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21925",
                                    "name": "Xã Nhơn Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21928",
                                    "name": "Xã Nhơn An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21931",
                                    "name": "Xã Nhơn Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21934",
                                    "name": "Phường Nhơn Hưng"
                                },
                                {
                                    "id": "21937",
                                    "name": "Xã Nhơn Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21940",
                                    "name": "Xã Nhơn Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21943",
                                    "name": "Phường Nhơn Hoà"
                                },
                                {
                                    "id": "21946",
                                    "name": "Xã Nhơn Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21949",
                                    "name": "Xã Nhơn Thọ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "550",
                        "name": "Huyện Tuy Phước",
                        "Wards": [
                                {
                                    "id": "21952",
                                    "name": "Thị trấn Tuy Phước",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21955",
                                    "name": "Thị trấn Diêu Trì",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21958",
                                    "name": "Xã Phước Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21961",
                                    "name": "Xã Phước Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21964",
                                    "name": "Xã Phước Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21967",
                                    "name": "Xã Phước Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21970",
                                    "name": "Xã Phước Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21973",
                                    "name": "Xã Phước Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21976",
                                    "name": "Xã Phước Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21979",
                                    "name": "Xã Phước Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21982",
                                    "name": "Xã Phước Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21985",
                                    "name": "Xã Phước An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "21988",
                                    "name": "Xã Phước Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "551",
                        "name": "Huyện Vân Canh",
                        "Wards": [
                                {
                                    "id": "21994",
                                    "name": "Thị trấn Vân Canh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "21997",
                                    "name": "Xã Canh Liên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22000",
                                    "name": "Xã Canh Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22003",
                                    "name": "Xã Canh Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22006",
                                    "name": "Xã Canh Hiển",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22009",
                                    "name": "Xã Canh Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22012",
                                    "name": "Xã Canh Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "54",
            "name": "Tỉnh Phú Yên",
            "districts": [
                    {
                        "id": "555",
                        "name": "Thành phố Tuy Hoà",
                        "Wards": [
                                {
                                    "id": "22015",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "22018",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "22021",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "22024",
                                    "name": "Phường 9"
                                },
                                {
                                    "id": "22027",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "22030",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "22033",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "22036",
                                    "name": "Phường 7"
                                },
                                {
                                    "id": "22039",
                                    "name": "Phường 6"
                                },
                                {
                                    "id": "22040",
                                    "name": "Phường Phú Thạnh"
                                },
                                {
                                    "id": "22041",
                                    "name": "Phường Phú Đông"
                                },
                                {
                                    "id": "22042",
                                    "name": "Xã Hòa Kiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22045",
                                    "name": "Xã Bình Kiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22048",
                                    "name": "Xã Bình Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22162",
                                    "name": "Xã An Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22240",
                                    "name": "Phường Phú Lâm"
                                }
                        ]
                    },
                    {
                        "id": "557",
                        "name": "Thị xã Sông Cầu",
                        "Wards": [
                                {
                                    "id": "22051",
                                    "name": "Phường Xuân Phú"
                                },
                                {
                                    "id": "22052",
                                    "name": "Xã Xuân Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22053",
                                    "name": "Phường Xuân Thành"
                                },
                                {
                                    "id": "22054",
                                    "name": "Xã Xuân Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22057",
                                    "name": "Xã Xuân Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22060",
                                    "name": "Xã Xuân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22066",
                                    "name": "Xã Xuân Cảnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22069",
                                    "name": "Xã Xuân Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22072",
                                    "name": "Xã Xuân Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22073",
                                    "name": "Phường Xuân Yên"
                                },
                                {
                                    "id": "22075",
                                    "name": "Xã Xuân Thọ 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22076",
                                    "name": "Phường Xuân Đài"
                                },
                                {
                                    "id": "22078",
                                    "name": "Xã Xuân Thọ 2",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "558",
                        "name": "Huyện Đồng Xuân",
                        "Wards": [
                                {
                                    "id": "22081",
                                    "name": "Thị trấn La Hai",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22084",
                                    "name": "Xã Đa Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22087",
                                    "name": "Xã Phú Mỡ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22090",
                                    "name": "Xã Xuân Lãnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22093",
                                    "name": "Xã Xuân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22096",
                                    "name": "Xã Xuân Quang 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22099",
                                    "name": "Xã Xuân Sơn Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22102",
                                    "name": "Xã Xuân Quang 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22105",
                                    "name": "Xã Xuân Sơn Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22108",
                                    "name": "Xã Xuân Quang 3",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22111",
                                    "name": "Xã Xuân Phước",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "559",
                        "name": "Huyện Tuy An",
                        "Wards": [
                                {
                                    "id": "22114",
                                    "name": "Thị trấn Chí Thạnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22117",
                                    "name": "Xã An Dân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22120",
                                    "name": "Xã An Ninh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22123",
                                    "name": "Xã An Ninh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22126",
                                    "name": "Xã An Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22129",
                                    "name": "Xã An Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22132",
                                    "name": "Xã An Nghiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22138",
                                    "name": "Xã An Cư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22141",
                                    "name": "Xã An Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22144",
                                    "name": "Xã An Lĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22147",
                                    "name": "Xã An Hòa Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22150",
                                    "name": "Xã An Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22153",
                                    "name": "Xã An Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22156",
                                    "name": "Xã An Chấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22159",
                                    "name": "Xã An Thọ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "560",
                        "name": "Huyện Sơn Hòa",
                        "Wards": [
                                {
                                    "id": "22165",
                                    "name": "Thị trấn Củng Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22168",
                                    "name": "Xã Phước Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22171",
                                    "name": "Xã Sơn Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22174",
                                    "name": "Xã Sơn Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22177",
                                    "name": "Xã Sơn Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22180",
                                    "name": "Xã Cà Lúi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22183",
                                    "name": "Xã Sơn Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22186",
                                    "name": "Xã Sơn Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22189",
                                    "name": "Xã Sơn Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22192",
                                    "name": "Xã Eachà Rang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22195",
                                    "name": "Xã Krông Pa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22198",
                                    "name": "Xã Suối Bạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22201",
                                    "name": "Xã Sơn Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22204",
                                    "name": "Xã Suối Trai",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "561",
                        "name": "Huyện Sông Hinh",
                        "Wards": [
                                {
                                    "id": "22207",
                                    "name": "Thị trấn Hai Riêng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22210",
                                    "name": "Xã Ea Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22213",
                                    "name": "Xã Đức Bình Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22216",
                                    "name": "Xã Ea Bá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22219",
                                    "name": "Xã Sơn Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22222",
                                    "name": "Xã Đức Bình Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22225",
                                    "name": "Xã EaBar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22228",
                                    "name": "Xã EaBia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22231",
                                    "name": "Xã EaTrol",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22234",
                                    "name": "Xã Sông Hinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22237",
                                    "name": "Xã Ealy",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "562",
                        "name": "Huyện Tây Hoà",
                        "Wards": [
                                {
                                    "id": "22249",
                                    "name": "Xã Sơn Thành Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22250",
                                    "name": "Xã Sơn Thành Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22252",
                                    "name": "Xã Hòa Bình 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22255",
                                    "name": "Thị trấn Phú Thứ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22264",
                                    "name": "Xã Hòa Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22270",
                                    "name": "Xã Hòa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22273",
                                    "name": "Xã Hòa Tân Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22276",
                                    "name": "Xã Hòa Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22285",
                                    "name": "Xã Hòa Mỹ Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22288",
                                    "name": "Xã Hòa Mỹ Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22294",
                                    "name": "Xã Hòa Thịnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "563",
                        "name": "Huyện Phú Hoà",
                        "Wards": [
                                {
                                    "id": "22303",
                                    "name": "Xã Hòa Quang Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22306",
                                    "name": "Xã Hòa Quang Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22309",
                                    "name": "Xã Hòa Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22312",
                                    "name": "Xã Hòa Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22315",
                                    "name": "Xã Hòa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22318",
                                    "name": "Xã Hòa Định Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22319",
                                    "name": "Thị Trấn Phú Hoà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22321",
                                    "name": "Xã Hòa Định Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22324",
                                    "name": "Xã Hòa Thắng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "564",
                        "name": "Thị xã Đông Hòa",
                        "Wards": [
                                {
                                    "id": "22243",
                                    "name": "Xã Hòa Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22246",
                                    "name": "Phường Hòa Hiệp Bắc"
                                },
                                {
                                    "id": "22258",
                                    "name": "Phường Hoà Vinh"
                                },
                                {
                                    "id": "22261",
                                    "name": "Phường Hoà Hiệp Trung"
                                },
                                {
                                    "id": "22267",
                                    "name": "Xã Hòa Tân Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22279",
                                    "name": "Phường Hòa Xuân Tây"
                                },
                                {
                                    "id": "22282",
                                    "name": "Phường Hòa Hiệp Nam"
                                },
                                {
                                    "id": "22291",
                                    "name": "Xã Hòa Xuân Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22297",
                                    "name": "Xã Hòa Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22300",
                                    "name": "Xã Hòa Xuân Nam",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "56",
            "name": "Tỉnh Khánh Hòa",
            "districts": [
                    {
                        "id": "568",
                        "name": "Thành phố Nha Trang",
                        "Wards": [
                                {
                                    "id": "22327",
                                    "name": "Phường Vĩnh Hòa"
                                },
                                {
                                    "id": "22330",
                                    "name": "Phường Vĩnh Hải"
                                },
                                {
                                    "id": "22333",
                                    "name": "Phường Vĩnh Phước"
                                },
                                {
                                    "id": "22336",
                                    "name": "Phường Ngọc Hiệp"
                                },
                                {
                                    "id": "22339",
                                    "name": "Phường Vĩnh Thọ"
                                },
                                {
                                    "id": "22342",
                                    "name": "Phường Xương Huân"
                                },
                                {
                                    "id": "22345",
                                    "name": "Phường Vạn Thắng"
                                },
                                {
                                    "id": "22348",
                                    "name": "Phường Vạn Thạnh"
                                },
                                {
                                    "id": "22351",
                                    "name": "Phường Phương Sài"
                                },
                                {
                                    "id": "22354",
                                    "name": "Phường Phương Sơn"
                                },
                                {
                                    "id": "22357",
                                    "name": "Phường Phước Hải"
                                },
                                {
                                    "id": "22360",
                                    "name": "Phường Phước Tân"
                                },
                                {
                                    "id": "22363",
                                    "name": "Phường Lộc Thọ"
                                },
                                {
                                    "id": "22366",
                                    "name": "Phường Phước Tiến"
                                },
                                {
                                    "id": "22369",
                                    "name": "Phường Tân Lập"
                                },
                                {
                                    "id": "22372",
                                    "name": "Phường Phước Hòa"
                                },
                                {
                                    "id": "22375",
                                    "name": "Phường Vĩnh Nguyên"
                                },
                                {
                                    "id": "22378",
                                    "name": "Phường Phước Long"
                                },
                                {
                                    "id": "22381",
                                    "name": "Phường Vĩnh Trường"
                                },
                                {
                                    "id": "22384",
                                    "name": "Xã Vĩnh Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22387",
                                    "name": "Xã Vĩnh Phương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22390",
                                    "name": "Xã Vĩnh Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22393",
                                    "name": "Xã Vĩnh Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22396",
                                    "name": "Xã Vĩnh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22399",
                                    "name": "Xã Vĩnh Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22402",
                                    "name": "Xã Vĩnh Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22405",
                                    "name": "Xã Phước Đồng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "569",
                        "name": "Thành phố Cam Ranh",
                        "Wards": [
                                {
                                    "id": "22408",
                                    "name": "Phường Cam Nghĩa"
                                },
                                {
                                    "id": "22411",
                                    "name": "Phường Cam Phúc Bắc"
                                },
                                {
                                    "id": "22414",
                                    "name": "Phường Cam Phúc Nam"
                                },
                                {
                                    "id": "22417",
                                    "name": "Phường Cam Lộc"
                                },
                                {
                                    "id": "22420",
                                    "name": "Phường Cam Phú"
                                },
                                {
                                    "id": "22423",
                                    "name": "Phường Ba Ngòi"
                                },
                                {
                                    "id": "22426",
                                    "name": "Phường Cam Thuận"
                                },
                                {
                                    "id": "22429",
                                    "name": "Phường Cam Lợi"
                                },
                                {
                                    "id": "22432",
                                    "name": "Phường Cam Linh"
                                },
                                {
                                    "id": "22468",
                                    "name": "Xã Cam Thành Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22474",
                                    "name": "Xã Cam Phước Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22477",
                                    "name": "Xã Cam Thịnh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22480",
                                    "name": "Xã Cam Thịnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22483",
                                    "name": "Xã Cam Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22486",
                                    "name": "Xã Cam Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "570",
                        "name": "Huyện Cam Lâm",
                        "Wards": [
                                {
                                    "id": "22435",
                                    "name": "Xã Cam Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22438",
                                    "name": "Xã Cam Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22441",
                                    "name": "Xã Cam Hải Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22444",
                                    "name": "Xã Cam Hải Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22447",
                                    "name": "Xã Sơn Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22450",
                                    "name": "Xã Cam Hiệp Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22453",
                                    "name": "Thị trấn Cam Đức",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22456",
                                    "name": "Xã Cam Hiệp Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22459",
                                    "name": "Xã Cam Phước Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22462",
                                    "name": "Xã Cam Thành Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22465",
                                    "name": "Xã Cam An Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22471",
                                    "name": "Xã Cam An Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22708",
                                    "name": "Xã Suối Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22711",
                                    "name": "Xã Suối Tân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "571",
                        "name": "Huyện Vạn Ninh",
                        "Wards": [
                                {
                                    "id": "22489",
                                    "name": "Thị trấn Vạn Giã",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22492",
                                    "name": "Xã Đại Lãnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22495",
                                    "name": "Xã Vạn Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22498",
                                    "name": "Xã Vạn Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22501",
                                    "name": "Xã Vạn Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22504",
                                    "name": "Xã Vạn Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22507",
                                    "name": "Xã Vạn Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22510",
                                    "name": "Xã Vạn Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22513",
                                    "name": "Xã Vạn Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22516",
                                    "name": "Xã Vạn Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22519",
                                    "name": "Xã Vạn Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22522",
                                    "name": "Xã Xuân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22525",
                                    "name": "Xã Vạn Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "572",
                        "name": "Thị xã Ninh Hòa",
                        "Wards": [
                                {
                                    "id": "22528",
                                    "name": "Phường Ninh Hiệp"
                                },
                                {
                                    "id": "22531",
                                    "name": "Xã Ninh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22534",
                                    "name": "Xã Ninh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22537",
                                    "name": "Xã Ninh Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22540",
                                    "name": "Xã Ninh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22543",
                                    "name": "Phường Ninh Hải"
                                },
                                {
                                    "id": "22546",
                                    "name": "Xã Ninh Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22549",
                                    "name": "Xã Ninh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22552",
                                    "name": "Xã Ninh Sim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22555",
                                    "name": "Xã Ninh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22558",
                                    "name": "Xã Ninh Thân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22561",
                                    "name": "Phường Ninh Diêm"
                                },
                                {
                                    "id": "22564",
                                    "name": "Xã Ninh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22567",
                                    "name": "Phường Ninh Thủy"
                                },
                                {
                                    "id": "22570",
                                    "name": "Phường Ninh Đa"
                                },
                                {
                                    "id": "22573",
                                    "name": "Xã Ninh Phụng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22576",
                                    "name": "Xã Ninh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22579",
                                    "name": "Xã Ninh Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22582",
                                    "name": "Xã Ninh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22585",
                                    "name": "Xã Ninh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22588",
                                    "name": "Xã Ninh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22591",
                                    "name": "Phường Ninh Giang"
                                },
                                {
                                    "id": "22594",
                                    "name": "Phường Ninh Hà"
                                },
                                {
                                    "id": "22597",
                                    "name": "Xã Ninh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22600",
                                    "name": "Xã Ninh Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22603",
                                    "name": "Xã Ninh Ích",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22606",
                                    "name": "Xã Ninh Vân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "573",
                        "name": "Huyện Khánh Vĩnh",
                        "Wards": [
                                {
                                    "id": "22609",
                                    "name": "Thị trấn Khánh Vĩnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22612",
                                    "name": "Xã Khánh Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22615",
                                    "name": "Xã Khánh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22618",
                                    "name": "Xã Khánh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22621",
                                    "name": "Xã Khánh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22624",
                                    "name": "Xã Khánh Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22627",
                                    "name": "Xã Khánh Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22630",
                                    "name": "Xã Sông Cầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22633",
                                    "name": "Xã Giang Ly",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22636",
                                    "name": "Xã Cầu Bà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22639",
                                    "name": "Xã Liên Sang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22642",
                                    "name": "Xã Khánh Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22645",
                                    "name": "Xã Khánh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22648",
                                    "name": "Xã Sơn Thái",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "574",
                        "name": "Huyện Diên Khánh",
                        "Wards": [
                                {
                                    "id": "22651",
                                    "name": "Thị trấn Diên Khánh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22654",
                                    "name": "Xã Diên Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22657",
                                    "name": "Xã Diên Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22660",
                                    "name": "Xã Diên Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22663",
                                    "name": "Xã Diên Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22666",
                                    "name": "Xã Diên Đồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22669",
                                    "name": "Xã Diên Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22672",
                                    "name": "Xã Diên Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22675",
                                    "name": "Xã Diên Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22678",
                                    "name": "Xã Diên Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22681",
                                    "name": "Xã Diên Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22684",
                                    "name": "Xã Diên Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22687",
                                    "name": "Xã Diên Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22690",
                                    "name": "Xã Diên Toàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22693",
                                    "name": "Xã Diên An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22696",
                                    "name": "Xã Bình Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22702",
                                    "name": "Xã Suối Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22705",
                                    "name": "Xã Suối Tiên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "575",
                        "name": "Huyện Khánh Sơn",
                        "Wards": [
                                {
                                    "id": "22714",
                                    "name": "Thị trấn Tô Hạp",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22717",
                                    "name": "Xã Thành Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22720",
                                    "name": "Xã Sơn Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22723",
                                    "name": "Xã Sơn Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22726",
                                    "name": "Xã Sơn Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22729",
                                    "name": "Xã Sơn Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22732",
                                    "name": "Xã Ba Cụm Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22735",
                                    "name": "Xã Ba Cụm Nam",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "576",
                        "name": "Huyện Trường Sa",
                        "Wards": [
                                {
                                    "id": "22736",
                                    "name": "Thị trấn Trường Sa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22737",
                                    "name": "Xã Song Tử Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22739",
                                    "name": "Xã Sinh Tồn",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "58",
            "name": "Tỉnh Ninh Thuận",
            "districts": [
                    {
                        "id": "582",
                        "name": "Thành phố Phan Rang-Tháp Chàm",
                        "Wards": [
                                {
                                    "id": "22738",
                                    "name": "Phường Đô Vinh"
                                },
                                {
                                    "id": "22741",
                                    "name": "Phường Phước Mỹ"
                                },
                                {
                                    "id": "22744",
                                    "name": "Phường Bảo An"
                                },
                                {
                                    "id": "22747",
                                    "name": "Phường Phủ Hà"
                                },
                                {
                                    "id": "22750",
                                    "name": "Phường Thanh Sơn"
                                },
                                {
                                    "id": "22753",
                                    "name": "Phường Mỹ Hương"
                                },
                                {
                                    "id": "22756",
                                    "name": "Phường Tấn Tài"
                                },
                                {
                                    "id": "22759",
                                    "name": "Phường Kinh Dinh"
                                },
                                {
                                    "id": "22762",
                                    "name": "Phường Đạo Long"
                                },
                                {
                                    "id": "22765",
                                    "name": "Phường Đài Sơn"
                                },
                                {
                                    "id": "22768",
                                    "name": "Phường Đông Hải"
                                },
                                {
                                    "id": "22771",
                                    "name": "Phường Mỹ Đông"
                                },
                                {
                                    "id": "22774",
                                    "name": "Xã Thành Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22777",
                                    "name": "Phường Văn Hải"
                                },
                                {
                                    "id": "22779",
                                    "name": "Phường Mỹ Bình"
                                },
                                {
                                    "id": "22780",
                                    "name": "Phường Mỹ Hải"
                                }
                        ]
                    },
                    {
                        "id": "584",
                        "name": "Huyện Bác Ái",
                        "Wards": [
                                {
                                    "id": "22783",
                                    "name": "Xã Phước Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22786",
                                    "name": "Xã Phước Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22789",
                                    "name": "Xã Phước Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22792",
                                    "name": "Xã Phước Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22795",
                                    "name": "Xã Phước Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22798",
                                    "name": "Xã Phước Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22801",
                                    "name": "Xã Phước Đại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22804",
                                    "name": "Xã Phước Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22807",
                                    "name": "Xã Phước Trung",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "585",
                        "name": "Huyện Ninh Sơn",
                        "Wards": [
                                {
                                    "id": "22810",
                                    "name": "Thị trấn Tân Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22813",
                                    "name": "Xã Lâm Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22816",
                                    "name": "Xã Lương Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22819",
                                    "name": "Xã Quảng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22822",
                                    "name": "Xã Mỹ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22825",
                                    "name": "Xã Hòa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22828",
                                    "name": "Xã Ma Nới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22831",
                                    "name": "Xã Nhơn Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "586",
                        "name": "Huyện Ninh Hải",
                        "Wards": [
                                {
                                    "id": "22834",
                                    "name": "Thị trấn Khánh Hải",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22846",
                                    "name": "Xã Vĩnh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22852",
                                    "name": "Xã Phương Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22855",
                                    "name": "Xã Tân Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22858",
                                    "name": "Xã Xuân Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22861",
                                    "name": "Xã Hộ Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22864",
                                    "name": "Xã Tri Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22867",
                                    "name": "Xã Nhơn Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22868",
                                    "name": "Xã Thanh Hải",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "587",
                        "name": "Huyện Ninh Phước",
                        "Wards": [
                                {
                                    "id": "22870",
                                    "name": "Thị trấn Phước Dân",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22873",
                                    "name": "Xã Phước Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22876",
                                    "name": "Xã Phước Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22879",
                                    "name": "Xã Phước Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22882",
                                    "name": "Xã Phước Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22888",
                                    "name": "Xã An Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22891",
                                    "name": "Xã Phước Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22894",
                                    "name": "Xã Phước Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22912",
                                    "name": "Xã Phước Vinh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "588",
                        "name": "Huyện Thuận Bắc",
                        "Wards": [
                                {
                                    "id": "22837",
                                    "name": "Xã Phước Chiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22840",
                                    "name": "Xã Công Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22843",
                                    "name": "Xã Phước Kháng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22849",
                                    "name": "Xã Lợi Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22853",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22856",
                                    "name": "Xã Bắc Phong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "589",
                        "name": "Huyện Thuận Nam",
                        "Wards": [
                                {
                                    "id": "22885",
                                    "name": "Xã Phước Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22897",
                                    "name": "Xã Phước Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22898",
                                    "name": "Xã Phước Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22900",
                                    "name": "Xã Nhị Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22903",
                                    "name": "Xã Phước Dinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22906",
                                    "name": "Xã Phước Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22909",
                                    "name": "Xã Phước Diêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22910",
                                    "name": "Xã Cà Ná",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "60",
            "name": "Tỉnh Bình Thuận",
            "districts": [
                    {
                        "id": "593",
                        "name": "Thành phố Phan Thiết",
                        "Wards": [
                                {
                                    "id": "22915",
                                    "name": "Phường Mũi Né"
                                },
                                {
                                    "id": "22918",
                                    "name": "Phường Hàm Tiến"
                                },
                                {
                                    "id": "22921",
                                    "name": "Phường Phú Hài"
                                },
                                {
                                    "id": "22924",
                                    "name": "Phường Phú Thủy"
                                },
                                {
                                    "id": "22927",
                                    "name": "Phường Phú Tài"
                                },
                                {
                                    "id": "22930",
                                    "name": "Phường Phú Trinh"
                                },
                                {
                                    "id": "22933",
                                    "name": "Phường Xuân An"
                                },
                                {
                                    "id": "22936",
                                    "name": "Phường Thanh Hải"
                                },
                                {
                                    "id": "22939",
                                    "name": "Phường Bình Hưng"
                                },
                                {
                                    "id": "22942",
                                    "name": "Phường Đức Nghĩa"
                                },
                                {
                                    "id": "22945",
                                    "name": "Phường Lạc Đạo"
                                },
                                {
                                    "id": "22948",
                                    "name": "Phường Đức Thắng"
                                },
                                {
                                    "id": "22951",
                                    "name": "Phường Hưng Long"
                                },
                                {
                                    "id": "22954",
                                    "name": "Phường Đức Long"
                                },
                                {
                                    "id": "22957",
                                    "name": "Xã Thiện Nghiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22960",
                                    "name": "Xã Phong Nẫm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22963",
                                    "name": "Xã Tiến Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22966",
                                    "name": "Xã Tiến Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "594",
                        "name": "Thị xã La Gi",
                        "Wards": [
                                {
                                    "id": "23231",
                                    "name": "Phường Phước Hội"
                                },
                                {
                                    "id": "23232",
                                    "name": "Phường Phước Lộc"
                                },
                                {
                                    "id": "23234",
                                    "name": "Phường Tân Thiện"
                                },
                                {
                                    "id": "23235",
                                    "name": "Phường Tân An"
                                },
                                {
                                    "id": "23237",
                                    "name": "Phường Bình Tân"
                                },
                                {
                                    "id": "23245",
                                    "name": "Xã Tân Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23246",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23248",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23268",
                                    "name": "Xã Tân Phước",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "595",
                        "name": "Huyện Tuy Phong",
                        "Wards": [
                                {
                                    "id": "22969",
                                    "name": "Thị trấn Liên Hương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22972",
                                    "name": "Thị trấn Phan Rí Cửa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "22975",
                                    "name": "Xã Phan Dũng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22978",
                                    "name": "Xã Phong Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22981",
                                    "name": "Xã Vĩnh Hảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22984",
                                    "name": "Xã Vĩnh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22987",
                                    "name": "Xã Phú Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22990",
                                    "name": "Xã Phước Thể",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22993",
                                    "name": "Xã Hòa Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22996",
                                    "name": "Xã Chí Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "22999",
                                    "name": "Xã Bình Thạnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "596",
                        "name": "Huyện Bắc Bình",
                        "Wards": [
                                {
                                    "id": "23005",
                                    "name": "Thị trấn Chợ Lầu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23008",
                                    "name": "Xã Phan Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23011",
                                    "name": "Xã Phan Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23014",
                                    "name": "Xã Bình An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23017",
                                    "name": "Xã Phan Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23020",
                                    "name": "Xã Hải Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23023",
                                    "name": "Xã Sông Lũy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23026",
                                    "name": "Xã Phan Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23029",
                                    "name": "Xã Sông Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23032",
                                    "name": "Thị trấn Lương Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23035",
                                    "name": "Xã Phan Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23038",
                                    "name": "Xã Phan Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23041",
                                    "name": "Xã Hồng Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23044",
                                    "name": "Xã Phan Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23047",
                                    "name": "Xã Bình Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23050",
                                    "name": "Xã Phan Rí Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23053",
                                    "name": "Xã Hòa Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23056",
                                    "name": "Xã Hồng Phong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "597",
                        "name": "Huyện Hàm Thuận Bắc",
                        "Wards": [
                                {
                                    "id": "23059",
                                    "name": "Thị trấn Ma Lâm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23062",
                                    "name": "Thị trấn Phú Long",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23065",
                                    "name": "Xã La Dạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23068",
                                    "name": "Xã Đông Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23071",
                                    "name": "Xã Thuận Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23074",
                                    "name": "Xã Đông Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23077",
                                    "name": "Xã Hàm Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23080",
                                    "name": "Xã Hồng Liêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23083",
                                    "name": "Xã Thuận Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23086",
                                    "name": "Xã Hồng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23089",
                                    "name": "Xã Hàm Trí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23092",
                                    "name": "Xã Hàm Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23095",
                                    "name": "Xã Hàm Liêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23098",
                                    "name": "Xã Hàm Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23101",
                                    "name": "Xã Hàm Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23104",
                                    "name": "Xã Hàm Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23107",
                                    "name": "Xã Đa Mi",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "598",
                        "name": "Huyện Hàm Thuận Nam",
                        "Wards": [
                                {
                                    "id": "23110",
                                    "name": "Thị trấn Thuận Nam",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23113",
                                    "name": "Xã Mỹ Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23116",
                                    "name": "Xã Hàm Cần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23119",
                                    "name": "Xã Mương Mán",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23122",
                                    "name": "Xã Hàm Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23125",
                                    "name": "Xã Hàm Kiệm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23128",
                                    "name": "Xã Hàm Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23131",
                                    "name": "Xã Hàm Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23134",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23137",
                                    "name": "Xã Hàm Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23140",
                                    "name": "Xã Thuận Quí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23143",
                                    "name": "Xã Tân Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23146",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "599",
                        "name": "Huyện Tánh Linh",
                        "Wards": [
                                {
                                    "id": "23149",
                                    "name": "Thị trấn Lạc Tánh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23152",
                                    "name": "Xã Bắc Ruộng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23158",
                                    "name": "Xã Nghị Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23161",
                                    "name": "Xã La Ngâu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23164",
                                    "name": "Xã Huy Khiêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23167",
                                    "name": "Xã Măng Tố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23170",
                                    "name": "Xã Đức Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23173",
                                    "name": "Xã Đồng Kho",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23176",
                                    "name": "Xã Gia An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23179",
                                    "name": "Xã Đức Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23182",
                                    "name": "Xã Gia Huynh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23185",
                                    "name": "Xã Đức Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23188",
                                    "name": "Xã Suối Kiết",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "600",
                        "name": "Huyện Đức Linh",
                        "Wards": [
                                {
                                    "id": "23191",
                                    "name": "Thị trấn Võ Xu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23194",
                                    "name": "Thị trấn Đức Tài",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23197",
                                    "name": "Xã Đa Kai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23200",
                                    "name": "Xã Sùng Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23203",
                                    "name": "Xã Mê Pu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23206",
                                    "name": "Xã Nam Chính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23212",
                                    "name": "Xã Đức Hạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23215",
                                    "name": "Xã Đức Tín",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23218",
                                    "name": "Xã Vũ Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23221",
                                    "name": "Xã Tân Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23224",
                                    "name": "Xã Đông Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23227",
                                    "name": "Xã Trà Tân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "601",
                        "name": "Huyện Hàm Tân",
                        "Wards": [
                                {
                                    "id": "23230",
                                    "name": "Thị trấn Tân Minh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23236",
                                    "name": "Thị trấn Tân Nghĩa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23239",
                                    "name": "Xã Sông Phan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23242",
                                    "name": "Xã Tân Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23251",
                                    "name": "Xã Tân Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23254",
                                    "name": "Xã Tân Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23255",
                                    "name": "Xã Thắng Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23257",
                                    "name": "Xã Tân Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23260",
                                    "name": "Xã Tân Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23266",
                                    "name": "Xã Sơn Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "602",
                        "name": "Huyện Phú Quí",
                        "Wards": [
                                {
                                    "id": "23272",
                                    "name": "Xã Ngũ Phụng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23275",
                                    "name": "Xã Long Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23278",
                                    "name": "Xã Tam Thanh",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "62",
            "name": "Tỉnh Kon Tum",
            "districts": [
                    {
                        "id": "608",
                        "name": "Thành phố Kon Tum",
                        "Wards": [
                                {
                                    "id": "23281",
                                    "name": "Phường Quang Trung"
                                },
                                {
                                    "id": "23284",
                                    "name": "Phường Duy Tân"
                                },
                                {
                                    "id": "23287",
                                    "name": "Phường Quyết Thắng"
                                },
                                {
                                    "id": "23290",
                                    "name": "Phường Trường Chinh"
                                },
                                {
                                    "id": "23293",
                                    "name": "Phường Thắng Lợi"
                                },
                                {
                                    "id": "23296",
                                    "name": "Phường Ngô Mây"
                                },
                                {
                                    "id": "23299",
                                    "name": "Phường Thống Nhất"
                                },
                                {
                                    "id": "23302",
                                    "name": "Phường Lê Lợi"
                                },
                                {
                                    "id": "23305",
                                    "name": "Phường Nguyễn Trãi"
                                },
                                {
                                    "id": "23308",
                                    "name": "Phường Trần Hưng Đạo"
                                },
                                {
                                    "id": "23311",
                                    "name": "Xã Đắk Cấm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23314",
                                    "name": "Xã Kroong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23317",
                                    "name": "Xã Ngọk Bay",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23320",
                                    "name": "Xã Vinh Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23323",
                                    "name": "Xã Đắk Blà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23326",
                                    "name": "Xã Ia Chim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23327",
                                    "name": "Xã Đăk Năng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23329",
                                    "name": "Xã Đoàn Kết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23332",
                                    "name": "Xã Chư Hreng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23335",
                                    "name": "Xã Đắk Rơ Wa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23338",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "610",
                        "name": "Huyện Đắk Glei",
                        "Wards": [
                                {
                                    "id": "23341",
                                    "name": "Thị trấn Đắk Glei",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23344",
                                    "name": "Xã Đắk Blô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23347",
                                    "name": "Xã Đắk Man",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23350",
                                    "name": "Xã Đắk Nhoong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23353",
                                    "name": "Xã Đắk Pék",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23356",
                                    "name": "Xã Đắk Choong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23359",
                                    "name": "Xã Xốp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23362",
                                    "name": "Xã Mường Hoong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23365",
                                    "name": "Xã Ngọc Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23368",
                                    "name": "Xã Đắk Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23371",
                                    "name": "Xã Đắk KRoong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23374",
                                    "name": "Xã Đắk Môn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "611",
                        "name": "Huyện Ngọc Hồi",
                        "Wards": [
                                {
                                    "id": "23377",
                                    "name": "Thị trấn Plei Kần",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23380",
                                    "name": "Xã Đắk Ang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23383",
                                    "name": "Xã Đắk Dục",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23386",
                                    "name": "Xã Đắk Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23389",
                                    "name": "Xã Đắk Xú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23392",
                                    "name": "Xã Đắk Kan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23395",
                                    "name": "Xã Bờ Y",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23398",
                                    "name": "Xã Sa Loong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "612",
                        "name": "Huyện Đắk Tô",
                        "Wards": [
                                {
                                    "id": "23401",
                                    "name": "Thị trấn Đắk Tô",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23427",
                                    "name": "Xã Đắk Rơ Nga",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23428",
                                    "name": "Xã Ngọk Tụ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23430",
                                    "name": "Xã Đắk Trăm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23431",
                                    "name": "Xã Văn Lem",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23434",
                                    "name": "Xã Kon Đào",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23437",
                                    "name": "Xã Tân Cảnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23440",
                                    "name": "Xã Diên Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23443",
                                    "name": "Xã Pô Kô",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "613",
                        "name": "Huyện Kon Plông",
                        "Wards": [
                                {
                                    "id": "23452",
                                    "name": "Xã Đắk Nên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23455",
                                    "name": "Xã Đắk Ring",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23458",
                                    "name": "Xã Măng Buk",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23461",
                                    "name": "Xã Đắk Tăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23464",
                                    "name": "Xã Ngok Tem",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23467",
                                    "name": "Xã Pờ Ê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23470",
                                    "name": "Xã Măng Cành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23473",
                                    "name": "Thị trấn Măng Đen",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23476",
                                    "name": "Xã Hiếu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "614",
                        "name": "Huyện Kon Rẫy",
                        "Wards": [
                                {
                                    "id": "23479",
                                    "name": "Thị trấn Đắk Rve",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23482",
                                    "name": "Xã Đắk Kôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23485",
                                    "name": "Xã Đắk Tơ Lung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23488",
                                    "name": "Xã Đắk Ruồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23491",
                                    "name": "Xã Đắk Pne",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23494",
                                    "name": "Xã Đắk Tờ Re",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23497",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "615",
                        "name": "Huyện Đắk Hà",
                        "Wards": [
                                {
                                    "id": "23500",
                                    "name": "Thị trấn Đắk Hà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23503",
                                    "name": "Xã Đắk PXi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23504",
                                    "name": "Xã Đăk Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23506",
                                    "name": "Xã Đắk HRing",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23509",
                                    "name": "Xã Đắk Ui",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23510",
                                    "name": "Xã Đăk Ngọk",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23512",
                                    "name": "Xã Đắk Mar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23515",
                                    "name": "Xã Ngok Wang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23518",
                                    "name": "Xã Ngok Réo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23521",
                                    "name": "Xã Hà Mòn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23524",
                                    "name": "Xã Đắk La",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "616",
                        "name": "Huyện Sa Thầy",
                        "Wards": [
                                {
                                    "id": "23527",
                                    "name": "Thị trấn Sa Thầy",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23530",
                                    "name": "Xã Rơ Kơi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23533",
                                    "name": "Xã Sa Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23534",
                                    "name": "Xã Hơ Moong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23536",
                                    "name": "Xã Mô Rai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23539",
                                    "name": "Xã Sa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23542",
                                    "name": "Xã Sa Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23545",
                                    "name": "Xã Sa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23548",
                                    "name": "Xã Ya Xiêr",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23551",
                                    "name": "Xã Ya Tăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23554",
                                    "name": "Xã Ya ly",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "617",
                        "name": "Huyện Tu Mơ Rông",
                        "Wards": [
                                {
                                    "id": "23404",
                                    "name": "Xã Ngọc Lây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23407",
                                    "name": "Xã Đắk Na",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23410",
                                    "name": "Xã Măng Ri",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23413",
                                    "name": "Xã Ngọc Yêu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23416",
                                    "name": "Xã Đắk Sao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23417",
                                    "name": "Xã Đắk Rơ Ông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23419",
                                    "name": "Xã Đắk Tờ Kan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23422",
                                    "name": "Xã Tu Mơ Rông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23425",
                                    "name": "Xã Đắk Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23446",
                                    "name": "Xã Tê Xăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23449",
                                    "name": "Xã Văn Xuôi",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "618",
                        "name": "Huyện Ia H' Drai",
                        "Wards": [
                                {
                                    "id": "23535",
                                    "name": "Xã Ia Đal",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23537",
                                    "name": "Xã Ia Dom",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23538",
                                    "name": "Xã Ia Tơi",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "64",
            "name": "Tỉnh Gia Lai",
            "districts": [
                    {
                        "id": "622",
                        "name": "Thành phố Pleiku",
                        "Wards": [
                                {
                                    "id": "23557",
                                    "name": "Phường Yên Đỗ"
                                },
                                {
                                    "id": "23560",
                                    "name": "Phường Diên Hồng"
                                },
                                {
                                    "id": "23563",
                                    "name": "Phường Ia Kring"
                                },
                                {
                                    "id": "23566",
                                    "name": "Phường Hội Thương"
                                },
                                {
                                    "id": "23569",
                                    "name": "Phường Hội Phú"
                                },
                                {
                                    "id": "23570",
                                    "name": "Phường Phù Đổng"
                                },
                                {
                                    "id": "23572",
                                    "name": "Phường Hoa Lư"
                                },
                                {
                                    "id": "23575",
                                    "name": "Phường Tây Sơn"
                                },
                                {
                                    "id": "23578",
                                    "name": "Phường Thống Nhất"
                                },
                                {
                                    "id": "23579",
                                    "name": "Phường Đống Đa"
                                },
                                {
                                    "id": "23581",
                                    "name": "Phường Trà Bá"
                                },
                                {
                                    "id": "23582",
                                    "name": "Phường Thắng Lợi"
                                },
                                {
                                    "id": "23584",
                                    "name": "Phường Yên Thế"
                                },
                                {
                                    "id": "23586",
                                    "name": "Phường Chi Lăng"
                                },
                                {
                                    "id": "23590",
                                    "name": "Xã Biển Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23593",
                                    "name": "Xã Tân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23596",
                                    "name": "Xã Trà Đa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23599",
                                    "name": "Xã Chư Á",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23602",
                                    "name": "Xã An Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23605",
                                    "name": "Xã Diên Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23608",
                                    "name": "Xã Ia Kênh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23611",
                                    "name": "Xã Gào",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "623",
                        "name": "Thị xã An Khê",
                        "Wards": [
                                {
                                    "id": "23614",
                                    "name": "Phường An Bình"
                                },
                                {
                                    "id": "23617",
                                    "name": "Phường Tây Sơn"
                                },
                                {
                                    "id": "23620",
                                    "name": "Phường An Phú"
                                },
                                {
                                    "id": "23623",
                                    "name": "Phường An Tân"
                                },
                                {
                                    "id": "23626",
                                    "name": "Xã Tú An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23627",
                                    "name": "Xã Xuân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23629",
                                    "name": "Xã Cửu An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23630",
                                    "name": "Phường An Phước"
                                },
                                {
                                    "id": "23632",
                                    "name": "Xã Song An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23633",
                                    "name": "Phường Ngô Mây"
                                },
                                {
                                    "id": "23635",
                                    "name": "Xã Thành An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "624",
                        "name": "Thị xã Ayun Pa",
                        "Wards": [
                                {
                                    "id": "24041",
                                    "name": "Phường Cheo Reo"
                                },
                                {
                                    "id": "24042",
                                    "name": "Phường Hòa Bình"
                                },
                                {
                                    "id": "24044",
                                    "name": "Phường Đoàn Kết"
                                },
                                {
                                    "id": "24045",
                                    "name": "Phường Sông Bờ"
                                },
                                {
                                    "id": "24064",
                                    "name": "Xã Ia RBol",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24065",
                                    "name": "Xã Chư Băh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24070",
                                    "name": "Xã Ia RTô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24073",
                                    "name": "Xã Ia Sao",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "625",
                        "name": "Huyện KBang",
                        "Wards": [
                                {
                                    "id": "23638",
                                    "name": "Thị trấn KBang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23641",
                                    "name": "Xã Kon Pne",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23644",
                                    "name": "Xã Đăk Roong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23647",
                                    "name": "Xã Sơn Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23650",
                                    "name": "Xã KRong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23653",
                                    "name": "Xã Sơ Pai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23656",
                                    "name": "Xã Lơ Ku",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23659",
                                    "name": "Xã Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23660",
                                    "name": "Xã Đak SMar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23662",
                                    "name": "Xã Nghĩa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23665",
                                    "name": "Xã Tơ Tung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23668",
                                    "name": "Xã Kông Lơng Khơng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23671",
                                    "name": "Xã Kông Pla",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23674",
                                    "name": "Xã Đăk HLơ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "626",
                        "name": "Huyện Đăk Đoa",
                        "Wards": [
                                {
                                    "id": "23677",
                                    "name": "Thị trấn Đăk Đoa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23680",
                                    "name": "Xã Hà Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23683",
                                    "name": "Xã Đăk Sơmei",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23684",
                                    "name": "Xã Đăk Krong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23686",
                                    "name": "Xã Hải Yang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23689",
                                    "name": "Xã Kon Gang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23692",
                                    "name": "Xã Hà Bầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23695",
                                    "name": "Xã Nam Yang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23698",
                                    "name": "Xã K' Dang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23701",
                                    "name": "Xã H' Neng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23704",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23707",
                                    "name": "Xã Glar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23710",
                                    "name": "Xã A Dơk",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23713",
                                    "name": "Xã Trang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23714",
                                    "name": "Xã HNol",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23716",
                                    "name": "Xã Ia Pết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23719",
                                    "name": "Xã Ia Băng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "627",
                        "name": "Huyện Chư Păh",
                        "Wards": [
                                {
                                    "id": "23722",
                                    "name": "Thị trấn Phú Hòa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23725",
                                    "name": "Xã Hà Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23728",
                                    "name": "Xã Ia Khươl",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23731",
                                    "name": "Xã Ia Phí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23734",
                                    "name": "Thị trấn Ia Ly",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23737",
                                    "name": "Xã Ia Mơ Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23738",
                                    "name": "Xã Ia Kreng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23740",
                                    "name": "Xã Đăk Tơ Ver",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23743",
                                    "name": "Xã Hòa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23746",
                                    "name": "Xã Chư Đăng Ya",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23749",
                                    "name": "Xã Ia Ka",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23752",
                                    "name": "Xã Ia Nhin",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23755",
                                    "name": "Xã Nghĩa Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23761",
                                    "name": "Xã Nghĩa Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "628",
                        "name": "Huyện Ia Grai",
                        "Wards": [
                                {
                                    "id": "23764",
                                    "name": "Thị trấn Ia Kha",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23767",
                                    "name": "Xã Ia Sao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23768",
                                    "name": "Xã Ia Yok",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23770",
                                    "name": "Xã Ia Hrung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23771",
                                    "name": "Xã Ia Bă",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23773",
                                    "name": "Xã Ia Khai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23776",
                                    "name": "Xã Ia KRai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23778",
                                    "name": "Xã Ia Grăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23779",
                                    "name": "Xã Ia Tô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23782",
                                    "name": "Xã Ia O",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23785",
                                    "name": "Xã Ia Dêr",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23788",
                                    "name": "Xã Ia Chia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23791",
                                    "name": "Xã Ia Pếch",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "629",
                        "name": "Huyện Mang Yang",
                        "Wards": [
                                {
                                    "id": "23794",
                                    "name": "Thị trấn Kon Dơng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23797",
                                    "name": "Xã Ayun",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23798",
                                    "name": "Xã Đak Jơ Ta",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23799",
                                    "name": "Xã Đak Ta Ley",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23800",
                                    "name": "Xã Hra",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23803",
                                    "name": "Xã Đăk Yă",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23806",
                                    "name": "Xã Đăk Djrăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23809",
                                    "name": "Xã Lơ Pang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23812",
                                    "name": "Xã Kon Thụp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23815",
                                    "name": "Xã Đê Ar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23818",
                                    "name": "Xã Kon Chiêng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23821",
                                    "name": "Xã Đăk Trôi",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "630",
                        "name": "Huyện Kông Chro",
                        "Wards": [
                                {
                                    "id": "23824",
                                    "name": "Thị trấn Kông Chro",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23827",
                                    "name": "Xã Chư Krêy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23830",
                                    "name": "Xã An Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23833",
                                    "name": "Xã Kông Yang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23836",
                                    "name": "Xã Đăk Tơ Pang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23839",
                                    "name": "Xã SRó",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23840",
                                    "name": "Xã Đắk Kơ Ning",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23842",
                                    "name": "Xã Đăk Song",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23843",
                                    "name": "Xã Đăk Pling",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23845",
                                    "name": "Xã Yang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23846",
                                    "name": "Xã Đăk Pơ Pho",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23848",
                                    "name": "Xã Ya Ma",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23851",
                                    "name": "Xã Chơ Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23854",
                                    "name": "Xã Yang Nam",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "631",
                        "name": "Huyện Đức Cơ",
                        "Wards": [
                                {
                                    "id": "23857",
                                    "name": "Thị trấn Chư Ty",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23860",
                                    "name": "Xã Ia Dơk",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23863",
                                    "name": "Xã Ia Krêl",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23866",
                                    "name": "Xã Ia Din",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23869",
                                    "name": "Xã Ia Kla",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23872",
                                    "name": "Xã Ia Dom",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23875",
                                    "name": "Xã Ia Lang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23878",
                                    "name": "Xã Ia Kriêng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23881",
                                    "name": "Xã Ia Pnôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23884",
                                    "name": "Xã Ia Nan",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "632",
                        "name": "Huyện Chư Prông",
                        "Wards": [
                                {
                                    "id": "23887",
                                    "name": "Thị trấn Chư Prông",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23888",
                                    "name": "Xã Ia Kly",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23890",
                                    "name": "Xã Bình Giáo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23893",
                                    "name": "Xã Ia Drăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23896",
                                    "name": "Xã Thăng Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23899",
                                    "name": "Xã Bàu Cạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23902",
                                    "name": "Xã Ia Phìn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23905",
                                    "name": "Xã Ia Băng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23908",
                                    "name": "Xã Ia Tôr",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23911",
                                    "name": "Xã Ia Boòng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23914",
                                    "name": "Xã Ia O",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23917",
                                    "name": "Xã Ia Púch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23920",
                                    "name": "Xã Ia Me",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23923",
                                    "name": "Xã Ia Vê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23924",
                                    "name": "Xã Ia Bang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23926",
                                    "name": "Xã Ia Pia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23929",
                                    "name": "Xã Ia Ga",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23932",
                                    "name": "Xã Ia Lâu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23935",
                                    "name": "Xã Ia Piơr",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23938",
                                    "name": "Xã Ia Mơ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "633",
                        "name": "Huyện Chư Sê",
                        "Wards": [
                                {
                                    "id": "23941",
                                    "name": "Thị trấn Chư Sê",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23944",
                                    "name": "Xã Ia Tiêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23945",
                                    "name": "Xã Chư Pơng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23946",
                                    "name": "Xã Bar Măih",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23947",
                                    "name": "Xã Bờ Ngoong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23950",
                                    "name": "Xã Ia Glai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23953",
                                    "name": "Xã AL Bá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23954",
                                    "name": "Xã Kông HTok",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23956",
                                    "name": "Xã AYun",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23959",
                                    "name": "Xã Ia HLốp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23962",
                                    "name": "Xã Ia Blang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23965",
                                    "name": "Xã Dun",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23966",
                                    "name": "Xã Ia Pal",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23968",
                                    "name": "Xã H Bông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23977",
                                    "name": "Xã Ia Ko",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "634",
                        "name": "Huyện Đăk Pơ",
                        "Wards": [
                                {
                                    "id": "23989",
                                    "name": "Xã Hà Tam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23992",
                                    "name": "Xã An Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23995",
                                    "name": "Thị trấn Đak Pơ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23998",
                                    "name": "Xã Yang Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24001",
                                    "name": "Xã Cư An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24004",
                                    "name": "Xã Tân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24007",
                                    "name": "Xã Phú An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24010",
                                    "name": "Xã Ya Hội",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "635",
                        "name": "Huyện Ia Pa",
                        "Wards": [
                                {
                                    "id": "24013",
                                    "name": "Xã Pờ Tó",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24016",
                                    "name": "Xã Chư Răng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24019",
                                    "name": "Xã Ia KDăm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24022",
                                    "name": "Xã Kim Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24025",
                                    "name": "Xã Chư Mố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24028",
                                    "name": "Xã Ia Tul",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24031",
                                    "name": "Xã Ia Ma Rơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24034",
                                    "name": "Xã Ia Broăi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24037",
                                    "name": "Xã Ia Trok",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "637",
                        "name": "Huyện Krông Pa",
                        "Wards": [
                                {
                                    "id": "24076",
                                    "name": "Thị trấn Phú Túc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24079",
                                    "name": "Xã Ia RSai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24082",
                                    "name": "Xã Ia RSươm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24085",
                                    "name": "Xã Chư Gu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24088",
                                    "name": "Xã Đất Bằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24091",
                                    "name": "Xã Ia Mláh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24094",
                                    "name": "Xã Chư Drăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24097",
                                    "name": "Xã Phú Cần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24100",
                                    "name": "Xã Ia HDreh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24103",
                                    "name": "Xã Ia RMok",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24106",
                                    "name": "Xã Chư Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24109",
                                    "name": "Xã Uar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24112",
                                    "name": "Xã Chư Rcăm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24115",
                                    "name": "Xã Krông Năng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "638",
                        "name": "Huyện Phú Thiện",
                        "Wards": [
                                {
                                    "id": "24043",
                                    "name": "Thị trấn Phú Thiện",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24046",
                                    "name": "Xã Chư A Thai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24048",
                                    "name": "Xã Ayun Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24049",
                                    "name": "Xã Ia Ake",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24052",
                                    "name": "Xã Ia Sol",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24055",
                                    "name": "Xã Ia Piar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24058",
                                    "name": "Xã Ia Peng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24060",
                                    "name": "Xã Chrôh Pơnan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24061",
                                    "name": "Xã Ia Hiao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24067",
                                    "name": "Xã Ia Yeng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "639",
                        "name": "Huyện Chư Pưh",
                        "Wards": [
                                {
                                    "id": "23942",
                                    "name": "Thị trấn Nhơn Hoà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "23971",
                                    "name": "Xã Ia Hrú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23972",
                                    "name": "Xã Ia Rong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23974",
                                    "name": "Xã Ia Dreng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23978",
                                    "name": "Xã Ia Hla",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23980",
                                    "name": "Xã Chư Don",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23983",
                                    "name": "Xã Ia Phang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23986",
                                    "name": "Xã Ia Le",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "23987",
                                    "name": "Xã Ia BLứ",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "66",
            "name": "Tỉnh Đắk Lắk",
            "districts": [
                    {
                        "id": "643",
                        "name": "Thành phố Buôn Ma Thuột",
                        "Wards": [
                                {
                                    "id": "24118",
                                    "name": "Phường Tân Lập"
                                },
                                {
                                    "id": "24121",
                                    "name": "Phường Tân Hòa"
                                },
                                {
                                    "id": "24124",
                                    "name": "Phường Tân An"
                                },
                                {
                                    "id": "24127",
                                    "name": "Phường Thống Nhất"
                                },
                                {
                                    "id": "24130",
                                    "name": "Phường Thành Nhất"
                                },
                                {
                                    "id": "24133",
                                    "name": "Phường Thắng Lợi"
                                },
                                {
                                    "id": "24136",
                                    "name": "Phường Tân Lợi"
                                },
                                {
                                    "id": "24139",
                                    "name": "Phường Thành Công"
                                },
                                {
                                    "id": "24142",
                                    "name": "Phường Tân Thành"
                                },
                                {
                                    "id": "24145",
                                    "name": "Phường Tân Tiến"
                                },
                                {
                                    "id": "24148",
                                    "name": "Phường Tự An"
                                },
                                {
                                    "id": "24151",
                                    "name": "Phường Ea Tam"
                                },
                                {
                                    "id": "24154",
                                    "name": "Phường Khánh Xuân"
                                },
                                {
                                    "id": "24157",
                                    "name": "Xã Hòa Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24160",
                                    "name": "Xã Cư ÊBur",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24163",
                                    "name": "Xã Ea Tu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24166",
                                    "name": "Xã Hòa Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24169",
                                    "name": "Xã Ea Kao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24172",
                                    "name": "Xã Hòa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24175",
                                    "name": "Xã Hòa Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24178",
                                    "name": "Xã Hòa Xuân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "644",
                        "name": "Thị Xã Buôn Hồ",
                        "Wards": [
                                {
                                    "id": "24305",
                                    "name": "Phường An Lạc"
                                },
                                {
                                    "id": "24308",
                                    "name": "Phường An Bình"
                                },
                                {
                                    "id": "24311",
                                    "name": "Phường Thiện An"
                                },
                                {
                                    "id": "24318",
                                    "name": "Phường Đạt Hiếu"
                                },
                                {
                                    "id": "24322",
                                    "name": "Phường Đoàn Kết"
                                },
                                {
                                    "id": "24325",
                                    "name": "Xã Ea Blang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24328",
                                    "name": "Xã Ea Drông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24331",
                                    "name": "Phường Thống Nhất"
                                },
                                {
                                    "id": "24332",
                                    "name": "Phường Bình Tân"
                                },
                                {
                                    "id": "24334",
                                    "name": "Xã Ea Siên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24337",
                                    "name": "Xã Bình Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24340",
                                    "name": "Xã Cư Bao",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "645",
                        "name": "Huyện Ea H'leo",
                        "Wards": [
                                {
                                    "id": "24181",
                                    "name": "Thị trấn Ea Drăng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24184",
                                    "name": "Xã Ea H'leo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24187",
                                    "name": "Xã Ea Sol",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24190",
                                    "name": "Xã Ea Ral",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24193",
                                    "name": "Xã Ea Wy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24194",
                                    "name": "Xã Cư A Mung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24196",
                                    "name": "Xã Cư Mốt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24199",
                                    "name": "Xã Ea Hiao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24202",
                                    "name": "Xã Ea Khal",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24205",
                                    "name": "Xã Dliê Yang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24207",
                                    "name": "Xã Ea Tir",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24208",
                                    "name": "Xã Ea Nam",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "646",
                        "name": "Huyện Ea Súp",
                        "Wards": [
                                {
                                    "id": "24211",
                                    "name": "Thị trấn Ea Súp",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24214",
                                    "name": "Xã Ia Lốp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24215",
                                    "name": "Xã Ia JLơi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24217",
                                    "name": "Xã Ea Rốk",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24220",
                                    "name": "Xã Ya Tờ Mốt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24221",
                                    "name": "Xã Ia RVê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24223",
                                    "name": "Xã Ea Lê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24226",
                                    "name": "Xã Cư KBang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24229",
                                    "name": "Xã Ea Bung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24232",
                                    "name": "Xã Cư M'Lan",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "647",
                        "name": "Huyện Buôn Đôn",
                        "Wards": [
                                {
                                    "id": "24235",
                                    "name": "Xã Krông Na",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24238",
                                    "name": "Xã Ea Huar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24241",
                                    "name": "Xã Ea Wer",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24244",
                                    "name": "Xã Tân Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24247",
                                    "name": "Xã Cuôr KNia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24250",
                                    "name": "Xã Ea Bar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24253",
                                    "name": "Xã Ea Nuôl",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "648",
                        "name": "Huyện Cư M'gar",
                        "Wards": [
                                {
                                    "id": "24256",
                                    "name": "Thị trấn Ea Pốk",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24259",
                                    "name": "Thị trấn Quảng Phú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24262",
                                    "name": "Xã Quảng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24264",
                                    "name": "Xã Ea Kuêh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24265",
                                    "name": "Xã Ea Kiết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24268",
                                    "name": "Xã Ea Tar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24271",
                                    "name": "Xã Cư Dliê M'nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24274",
                                    "name": "Xã Ea H'đinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24277",
                                    "name": "Xã Ea Tul",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24280",
                                    "name": "Xã Ea KPam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24283",
                                    "name": "Xã Ea M'DRóh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24286",
                                    "name": "Xã Quảng Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24289",
                                    "name": "Xã Cư M'gar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24292",
                                    "name": "Xã Ea D'Rơng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24295",
                                    "name": "Xã Ea M'nang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24298",
                                    "name": "Xã Cư Suê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24301",
                                    "name": "Xã Cuor Đăng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "649",
                        "name": "Huyện Krông Búk",
                        "Wards": [
                                {
                                    "id": "24307",
                                    "name": "Xã Cư Né",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24310",
                                    "name": "Xã Chư KBô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24313",
                                    "name": "Xã Cư Pơng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24314",
                                    "name": "Xã Ea Sin",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24316",
                                    "name": "Xã Pơng Drang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24317",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24319",
                                    "name": "Xã Ea Ngai",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "650",
                        "name": "Huyện Krông Năng",
                        "Wards": [
                                {
                                    "id": "24343",
                                    "name": "Thị trấn Krông Năng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24346",
                                    "name": "Xã ĐLiê Ya",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24349",
                                    "name": "Xã Ea Tóh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24352",
                                    "name": "Xã Ea Tam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24355",
                                    "name": "Xã Phú Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24358",
                                    "name": "Xã Tam Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24359",
                                    "name": "Xã Ea Puk",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24360",
                                    "name": "Xã Ea Dăh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24361",
                                    "name": "Xã Ea Hồ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24364",
                                    "name": "Xã Phú Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24367",
                                    "name": "Xã Cư Klông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24370",
                                    "name": "Xã Ea Tân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "651",
                        "name": "Huyện Ea Kar",
                        "Wards": [
                                {
                                    "id": "24373",
                                    "name": "Thị trấn Ea Kar",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24376",
                                    "name": "Thị trấn Ea Knốp",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24379",
                                    "name": "Xã Ea Sô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24380",
                                    "name": "Xã Ea Sar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24382",
                                    "name": "Xã Xuân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24385",
                                    "name": "Xã Cư Huê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24388",
                                    "name": "Xã Ea Tih",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24391",
                                    "name": "Xã Ea Đar",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24394",
                                    "name": "Xã Ea Kmút",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24397",
                                    "name": "Xã Cư Ni",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24400",
                                    "name": "Xã Ea Păl",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24401",
                                    "name": "Xã Cư Prông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24403",
                                    "name": "Xã Ea Ô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24404",
                                    "name": "Xã Cư ELang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24406",
                                    "name": "Xã Cư Bông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24409",
                                    "name": "Xã Cư Jang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "652",
                        "name": "Huyện M'Đrắk",
                        "Wards": [
                                {
                                    "id": "24412",
                                    "name": "Thị trấn M'Đrắk",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24415",
                                    "name": "Xã Cư Prao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24418",
                                    "name": "Xã Ea Pil",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24421",
                                    "name": "Xã Ea Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24424",
                                    "name": "Xã Ea H'MLay",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24427",
                                    "name": "Xã Krông Jing",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24430",
                                    "name": "Xã Ea M' Doal",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24433",
                                    "name": "Xã Ea Riêng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24436",
                                    "name": "Xã Cư M'ta",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24439",
                                    "name": "Xã Cư K Róa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24442",
                                    "name": "Xã Krông Á",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24444",
                                    "name": "Xã Cư San",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24445",
                                    "name": "Xã Ea Trang",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "653",
                        "name": "Huyện Krông Bông",
                        "Wards": [
                                {
                                    "id": "24448",
                                    "name": "Thị trấn Krông Kmar",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24451",
                                    "name": "Xã Dang Kang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24454",
                                    "name": "Xã Cư KTy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24457",
                                    "name": "Xã Hòa Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24460",
                                    "name": "Xã Hòa Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24463",
                                    "name": "Xã Hòa Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24466",
                                    "name": "Xã Hòa Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24469",
                                    "name": "Xã Yang Reh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24472",
                                    "name": "Xã Ea Trul",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24475",
                                    "name": "Xã Khuê Ngọc Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24478",
                                    "name": "Xã Cư Pui",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24481",
                                    "name": "Xã Hòa Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24484",
                                    "name": "Xã Cư Drăm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24487",
                                    "name": "Xã Yang Mao",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "654",
                        "name": "Huyện Krông Pắc",
                        "Wards": [
                                {
                                    "id": "24490",
                                    "name": "Thị trấn Phước An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24493",
                                    "name": "Xã KRông Búk",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24496",
                                    "name": "Xã Ea Kly",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24499",
                                    "name": "Xã Ea Kênh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24502",
                                    "name": "Xã Ea Phê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24505",
                                    "name": "Xã Ea KNuec",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24508",
                                    "name": "Xã Ea Yông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24511",
                                    "name": "Xã Hòa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24514",
                                    "name": "Xã Ea Kuăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24517",
                                    "name": "Xã Hòa Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24520",
                                    "name": "Xã Ea Hiu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24523",
                                    "name": "Xã Hòa Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24526",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24529",
                                    "name": "Xã Vụ Bổn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24532",
                                    "name": "Xã Ea Uy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24535",
                                    "name": "Xã Ea Yiêng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "655",
                        "name": "Huyện Krông A Na",
                        "Wards": [
                                {
                                    "id": "24538",
                                    "name": "Thị trấn Buôn Trấp",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24556",
                                    "name": "Xã Dray Sáp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24559",
                                    "name": "Xã Ea Na",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24565",
                                    "name": "Xã Ea Bông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24568",
                                    "name": "Xã Băng A Drênh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24571",
                                    "name": "Xã Dur KMăl",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24574",
                                    "name": "Xã Bình Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24577",
                                    "name": "Xã Quảng Điền",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "656",
                        "name": "Huyện Lắk",
                        "Wards": [
                                {
                                    "id": "24580",
                                    "name": "Thị trấn Liên Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24583",
                                    "name": "Xã Yang Tao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24586",
                                    "name": "Xã Bông Krang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24589",
                                    "name": "Xã Đắk Liêng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24592",
                                    "name": "Xã Buôn Triết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24595",
                                    "name": "Xã Buôn Tría",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24598",
                                    "name": "Xã Đắk Phơi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24601",
                                    "name": "Xã Đắk Nuê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24604",
                                    "name": "Xã Krông Nô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24607",
                                    "name": "Xã Nam Ka",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24610",
                                    "name": "Xã Ea R'Bin",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "657",
                        "name": "Huyện Cư Kuin",
                        "Wards": [
                                {
                                    "id": "24540",
                                    "name": "Xã Ea Ning",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24541",
                                    "name": "Xã Cư Ê Wi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24544",
                                    "name": "Xã Ea Ktur",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24547",
                                    "name": "Xã Ea Tiêu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24550",
                                    "name": "Xã Ea BHốk",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24553",
                                    "name": "Xã Ea Hu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24561",
                                    "name": "Xã Dray Bhăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24562",
                                    "name": "Xã Hòa Hiệp",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "67",
            "name": "Tỉnh Đắk Nông",
            "districts": [
                    {
                        "id": "660",
                        "name": "Thành phố Gia Nghĩa",
                        "Wards": [
                                {
                                    "id": "24611",
                                    "name": "Phường Nghĩa Đức"
                                },
                                {
                                    "id": "24612",
                                    "name": "Phường Nghĩa Thành"
                                },
                                {
                                    "id": "24614",
                                    "name": "Phường Nghĩa Phú"
                                },
                                {
                                    "id": "24615",
                                    "name": "Phường Nghĩa Tân"
                                },
                                {
                                    "id": "24617",
                                    "name": "Phường Nghĩa Trung"
                                },
                                {
                                    "id": "24618",
                                    "name": "Xã Đăk R'Moan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24619",
                                    "name": "Phường Quảng Thành"
                                },
                                {
                                    "id": "24628",
                                    "name": "Xã Đắk Nia",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "661",
                        "name": "Huyện Đăk Glong",
                        "Wards": [
                                {
                                    "id": "24616",
                                    "name": "Xã Quảng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24620",
                                    "name": "Xã Quảng Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24622",
                                    "name": "Xã Đắk Ha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24625",
                                    "name": "Xã Đắk R'Măng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24631",
                                    "name": "Xã Quảng Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24634",
                                    "name": "Xã Đắk Plao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24637",
                                    "name": "Xã Đắk Som",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "662",
                        "name": "Huyện Cư Jút",
                        "Wards": [
                                {
                                    "id": "24640",
                                    "name": "Thị trấn Ea T'Ling",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24643",
                                    "name": "Xã Đắk Wil",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24646",
                                    "name": "Xã Ea Pô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24649",
                                    "name": "Xã Nam Dong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24652",
                                    "name": "Xã Đắk DRông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24655",
                                    "name": "Xã Tâm Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24658",
                                    "name": "Xã Cư Knia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24661",
                                    "name": "Xã Trúc Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "663",
                        "name": "Huyện Đắk Mil",
                        "Wards": [
                                {
                                    "id": "24664",
                                    "name": "Thị trấn Đắk Mil",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24667",
                                    "name": "Xã  Đắk Lao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24670",
                                    "name": "Xã Đắk R'La",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24673",
                                    "name": "Xã Đắk Gằn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24676",
                                    "name": "Xã Đức Mạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24677",
                                    "name": "Xã Đắk N'Drót",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24678",
                                    "name": "Xã Long Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24679",
                                    "name": "Xã Đắk Sắk",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24682",
                                    "name": "Xã Thuận An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24685",
                                    "name": "Xã Đức Minh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "664",
                        "name": "Huyện Krông Nô",
                        "Wards": [
                                {
                                    "id": "24688",
                                    "name": "Thị trấn Đắk Mâm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24691",
                                    "name": "Xã Đắk Sôr",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24692",
                                    "name": "Xã Nam Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24694",
                                    "name": "Xã Buôn Choah",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24697",
                                    "name": "Xã Nam Đà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24699",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24700",
                                    "name": "Xã Đắk Drô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24703",
                                    "name": "Xã Nâm Nung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24706",
                                    "name": "Xã Đức Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24709",
                                    "name": "Xã Đắk Nang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24712",
                                    "name": "Xã Quảng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24715",
                                    "name": "Xã Nâm N'Đir",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "665",
                        "name": "Huyện Đắk Song",
                        "Wards": [
                                {
                                    "id": "24717",
                                    "name": "Thị trấn Đức An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24718",
                                    "name": "Xã Đắk Môl",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24719",
                                    "name": "Xã Đắk Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24721",
                                    "name": "Xã Nam Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24722",
                                    "name": "Xã Thuận Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24724",
                                    "name": "Xã Thuận Hạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24727",
                                    "name": "Xã Đắk N'Dung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24728",
                                    "name": "Xã Nâm N'Jang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24730",
                                    "name": "Xã Trường Xuân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "666",
                        "name": "Huyện Đắk R'Lấp",
                        "Wards": [
                                {
                                    "id": "24733",
                                    "name": "Thị trấn Kiến Đức",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24745",
                                    "name": "Xã Quảng Tín",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24750",
                                    "name": "Xã Đắk Wer",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24751",
                                    "name": "Xã Nhân Cơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24754",
                                    "name": "Xã Kiến Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24756",
                                    "name": "Xã Nghĩa Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24757",
                                    "name": "Xã Đạo Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24760",
                                    "name": "Xã Đắk Sin",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24761",
                                    "name": "Xã Hưng Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24763",
                                    "name": "Xã Đắk Ru",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24766",
                                    "name": "Xã Nhân Đạo",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "667",
                        "name": "Huyện Tuy Đức",
                        "Wards": [
                                {
                                    "id": "24736",
                                    "name": "Xã Quảng Trực",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24739",
                                    "name": "Xã Đắk Búk So",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24740",
                                    "name": "Xã Quảng Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24742",
                                    "name": "Xã Đắk R'Tíh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24746",
                                    "name": "Xã Đắk Ngo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24748",
                                    "name": "Xã Quảng Tân",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "68",
            "name": "Tỉnh Lâm Đồng",
            "districts": [
                    {
                        "id": "672",
                        "name": "Thành phố Đà Lạt",
                        "Wards": [
                                {
                                    "id": "24769",
                                    "name": "Phường 7"
                                },
                                {
                                    "id": "24772",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "24775",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "24778",
                                    "name": "Phường 9"
                                },
                                {
                                    "id": "24781",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "24784",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "24787",
                                    "name": "Phường 6"
                                },
                                {
                                    "id": "24790",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "24793",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "24796",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "24799",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "24802",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "24805",
                                    "name": "Xã Xuân Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24808",
                                    "name": "Xã Tà Nung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24810",
                                    "name": "Xã Trạm Hành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24811",
                                    "name": "Xã Xuân Trường",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "673",
                        "name": "Thành phố Bảo Lộc",
                        "Wards": [
                                {
                                    "id": "24814",
                                    "name": "Phường Lộc Phát"
                                },
                                {
                                    "id": "24817",
                                    "name": "Phường Lộc Tiến"
                                },
                                {
                                    "id": "24820",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "24823",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "24826",
                                    "name": "Phường B'lao"
                                },
                                {
                                    "id": "24829",
                                    "name": "Phường Lộc Sơn"
                                },
                                {
                                    "id": "24832",
                                    "name": "Xã Đạm Bri",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24835",
                                    "name": "Xã Lộc Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24838",
                                    "name": "Xã Lộc Nga",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24841",
                                    "name": "Xã Lộc Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24844",
                                    "name": "Xã Đại Lào",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "674",
                        "name": "Huyện Đam Rông",
                        "Wards": [
                                {
                                    "id": "24853",
                                    "name": "Xã Đạ Tông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24856",
                                    "name": "Xã Đạ Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24859",
                                    "name": "Xã Đạ M' Rong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24874",
                                    "name": "Xã Liêng Srônh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24875",
                                    "name": "Xã Đạ Rsal",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24877",
                                    "name": "Xã Rô Men",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24886",
                                    "name": "Xã Phi Liêng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24889",
                                    "name": "Xã Đạ K' Nàng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "675",
                        "name": "Huyện Lạc Dương",
                        "Wards": [
                                {
                                    "id": "24846",
                                    "name": "Thị trấn Lạc Dương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24847",
                                    "name": "Xã Đạ Chais",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24848",
                                    "name": "Xã Đạ Nhim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24850",
                                    "name": "Xã Đưng KNớ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24862",
                                    "name": "Xã Lát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24865",
                                    "name": "Xã Đạ Sar",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "676",
                        "name": "Huyện Lâm Hà",
                        "Wards": [
                                {
                                    "id": "24868",
                                    "name": "Thị trấn Nam Ban",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24871",
                                    "name": "Thị trấn Đinh Văn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24880",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24883",
                                    "name": "Xã Phi Tô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24892",
                                    "name": "Xã Mê Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24895",
                                    "name": "Xã Đạ Đờn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24898",
                                    "name": "Xã Phúc Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24901",
                                    "name": "Xã Đông Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24904",
                                    "name": "Xã Gia Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24907",
                                    "name": "Xã Tân Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24910",
                                    "name": "Xã Tân Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24913",
                                    "name": "Xã Hoài Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24916",
                                    "name": "Xã Tân Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24919",
                                    "name": "Xã Liên Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24922",
                                    "name": "Xã Đan Phượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24925",
                                    "name": "Xã Nam Hà",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "677",
                        "name": "Huyện Đơn Dương",
                        "Wards": [
                                {
                                    "id": "24928",
                                    "name": "Thị trấn D'Ran",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24931",
                                    "name": "Thị trấn Thạnh Mỹ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24934",
                                    "name": "Xã Lạc Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24937",
                                    "name": "Xã Đạ Ròn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24940",
                                    "name": "Xã Lạc Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24943",
                                    "name": "Xã Ka Đô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24946",
                                    "name": "Xã Quảng Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24949",
                                    "name": "Xã Ka Đơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24952",
                                    "name": "Xã Tu Tra",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24955",
                                    "name": "Xã Pró",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "678",
                        "name": "Huyện Đức Trọng",
                        "Wards": [
                                {
                                    "id": "24958",
                                    "name": "Thị trấn Liên Nghĩa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "24961",
                                    "name": "Xã Hiệp An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24964",
                                    "name": "Xã Liên Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24967",
                                    "name": "Xã Hiệp Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24970",
                                    "name": "Xã Bình Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24973",
                                    "name": "Xã N'Thol Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24976",
                                    "name": "Xã Tân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24979",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24982",
                                    "name": "Xã Phú Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24985",
                                    "name": "Xã Ninh Gia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24988",
                                    "name": "Xã Tà Năng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24989",
                                    "name": "Xã Đa Quyn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24991",
                                    "name": "Xã Tà Hine",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24994",
                                    "name": "Xã Đà Loan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "24997",
                                    "name": "Xã Ninh Loan",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "679",
                        "name": "Huyện Di Linh",
                        "Wards": [
                                {
                                    "id": "25000",
                                    "name": "Thị trấn Di Linh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25003",
                                    "name": "Xã Đinh Trang Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25006",
                                    "name": "Xã Tân Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25007",
                                    "name": "Xã Tân Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25009",
                                    "name": "Xã Tân Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25012",
                                    "name": "Xã Tân Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25015",
                                    "name": "Xã Gia Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25018",
                                    "name": "Xã Đinh Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25021",
                                    "name": "Xã Tam Bố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25024",
                                    "name": "Xã Đinh Trang Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25027",
                                    "name": "Xã Liên Đầm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25030",
                                    "name": "Xã Gung Ré",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25033",
                                    "name": "Xã Bảo Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25036",
                                    "name": "Xã Hòa Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25039",
                                    "name": "Xã Hòa Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25042",
                                    "name": "Xã Hòa Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25045",
                                    "name": "Xã Hòa Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25048",
                                    "name": "Xã Sơn Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25051",
                                    "name": "Xã Gia Bắc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "680",
                        "name": "Huyện Bảo Lâm",
                        "Wards": [
                                {
                                    "id": "25054",
                                    "name": "Thị trấn Lộc Thắng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25057",
                                    "name": "Xã Lộc Bảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25060",
                                    "name": "Xã Lộc Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25063",
                                    "name": "Xã Lộc Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25066",
                                    "name": "Xã Lộc Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25069",
                                    "name": "Xã B' Lá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25072",
                                    "name": "Xã Lộc Ngãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25075",
                                    "name": "Xã Lộc Quảng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25078",
                                    "name": "Xã Lộc Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25081",
                                    "name": "Xã Lộc Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25084",
                                    "name": "Xã Lộc An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25087",
                                    "name": "Xã Tân Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25090",
                                    "name": "Xã Lộc Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25093",
                                    "name": "Xã Lộc Nam",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "681",
                        "name": "Huyện Đạ Huoai",
                        "Wards": [
                                {
                                    "id": "25096",
                                    "name": "Thị trấn Đạ M'ri",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25099",
                                    "name": "Thị trấn Ma Đa Guôi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25105",
                                    "name": "Xã Hà Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25108",
                                    "name": "Xã Đạ Tồn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25111",
                                    "name": "Xã Đạ Oai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25114",
                                    "name": "Xã Đạ Ploa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25117",
                                    "name": "Xã Ma Đa Guôi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25120",
                                    "name": "Xã Đoàn Kết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25123",
                                    "name": "Xã Phước Lộc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "682",
                        "name": "Huyện Đạ Tẻh",
                        "Wards": [
                                {
                                    "id": "25126",
                                    "name": "Thị trấn Đạ Tẻh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25129",
                                    "name": "Xã An Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25132",
                                    "name": "Xã Quốc Oai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25135",
                                    "name": "Xã Mỹ Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25138",
                                    "name": "Xã Quảng Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25141",
                                    "name": "Xã Đạ Lây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25147",
                                    "name": "Xã Triệu Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25153",
                                    "name": "Xã Đạ Kho",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25156",
                                    "name": "Xã Đạ Pal",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "683",
                        "name": "Huyện Cát Tiên",
                        "Wards": [
                                {
                                    "id": "25159",
                                    "name": "Thị trấn Cát Tiên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25162",
                                    "name": "Xã Tiên Hoàng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25165",
                                    "name": "Xã Phước Cát 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25168",
                                    "name": "Xã Gia Viễn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25171",
                                    "name": "Xã Nam Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25180",
                                    "name": "Thị trấn Phước Cát ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25183",
                                    "name": "Xã Đức Phổ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25189",
                                    "name": "Xã Quảng Ngãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25192",
                                    "name": "Xã Đồng Nai Thượng",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "70",
            "name": "Tỉnh Bình Phước",
            "districts": [
                    {
                        "id": "688",
                        "name": "Thị xã Phước Long",
                        "Wards": [
                                {
                                    "id": "25216",
                                    "name": "Phường Thác Mơ"
                                },
                                {
                                    "id": "25217",
                                    "name": "Phường Long Thủy"
                                },
                                {
                                    "id": "25219",
                                    "name": "Phường Phước Bình"
                                },
                                {
                                    "id": "25220",
                                    "name": "Phường Long Phước"
                                },
                                {
                                    "id": "25237",
                                    "name": "Phường Sơn Giang"
                                },
                                {
                                    "id": "25245",
                                    "name": "Xã Long Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25249",
                                    "name": "Xã Phước Tín",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "689",
                        "name": "Thành phố Đồng Xoài",
                        "Wards": [
                                {
                                    "id": "25195",
                                    "name": "Phường Tân Phú"
                                },
                                {
                                    "id": "25198",
                                    "name": "Phường Tân Đồng"
                                },
                                {
                                    "id": "25201",
                                    "name": "Phường Tân Bình"
                                },
                                {
                                    "id": "25204",
                                    "name": "Phường Tân Xuân"
                                },
                                {
                                    "id": "25205",
                                    "name": "Phường Tân Thiện"
                                },
                                {
                                    "id": "25207",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25210",
                                    "name": "Phường Tiến Thành"
                                },
                                {
                                    "id": "25213",
                                    "name": "Xã Tiến Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "690",
                        "name": "Thị xã Bình Long",
                        "Wards": [
                                {
                                    "id": "25320",
                                    "name": "Phường Hưng Chiến"
                                },
                                {
                                    "id": "25324",
                                    "name": "Phường An Lộc"
                                },
                                {
                                    "id": "25325",
                                    "name": "Phường Phú Thịnh"
                                },
                                {
                                    "id": "25326",
                                    "name": "Phường Phú Đức"
                                },
                                {
                                    "id": "25333",
                                    "name": "Xã Thanh Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25336",
                                    "name": "Xã Thanh Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "691",
                        "name": "Huyện Bù Gia Mập",
                        "Wards": [
                                {
                                    "id": "25222",
                                    "name": "Xã Bù Gia Mập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25225",
                                    "name": "Xã Đak Ơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25228",
                                    "name": "Xã Đức Hạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25229",
                                    "name": "Xã Phú Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25231",
                                    "name": "Xã Đa Kia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25232",
                                    "name": "Xã Phước Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25234",
                                    "name": "Xã Bình Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25267",
                                    "name": "Xã Phú Nghĩa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "692",
                        "name": "Huyện Lộc Ninh",
                        "Wards": [
                                {
                                    "id": "25270",
                                    "name": "Thị trấn Lộc Ninh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25273",
                                    "name": "Xã Lộc Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25276",
                                    "name": "Xã Lộc An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25279",
                                    "name": "Xã Lộc Tấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25280",
                                    "name": "Xã Lộc Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25282",
                                    "name": "Xã Lộc Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25285",
                                    "name": "Xã Lộc Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25288",
                                    "name": "Xã Lộc Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25291",
                                    "name": "Xã Lộc Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25292",
                                    "name": "Xã Lộc Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25294",
                                    "name": "Xã Lộc Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25297",
                                    "name": "Xã Lộc Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25300",
                                    "name": "Xã Lộc Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25303",
                                    "name": "Xã Lộc Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25305",
                                    "name": "Xã Lộc Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25306",
                                    "name": "Xã Lộc Khánh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "693",
                        "name": "Huyện Bù Đốp",
                        "Wards": [
                                {
                                    "id": "25308",
                                    "name": "Thị trấn Thanh Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25309",
                                    "name": "Xã Hưng Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25310",
                                    "name": "Xã Phước Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25312",
                                    "name": "Xã Thiện Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25315",
                                    "name": "Xã Thanh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25318",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25321",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "694",
                        "name": "Huyện Hớn Quản",
                        "Wards": [
                                {
                                    "id": "25327",
                                    "name": "Xã Thanh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25330",
                                    "name": "Xã An Khương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25339",
                                    "name": "Xã An Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25342",
                                    "name": "Xã Tân Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25345",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25348",
                                    "name": "Xã Minh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25349",
                                    "name": "Xã Minh Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25351",
                                    "name": "Xã Phước An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25354",
                                    "name": "Xã Thanh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25357",
                                    "name": "Thị trấn Tân Khai",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25360",
                                    "name": "Xã Đồng Nơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25361",
                                    "name": "Xã Tân Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25438",
                                    "name": "Xã Tân Quan",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "695",
                        "name": "Huyện Đồng Phú",
                        "Wards": [
                                {
                                    "id": "25363",
                                    "name": "Thị trấn Tân Phú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25366",
                                    "name": "Xã Thuận Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25369",
                                    "name": "Xã Đồng Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25372",
                                    "name": "Xã Tân Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25375",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25378",
                                    "name": "Xã Tân Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25381",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25384",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25387",
                                    "name": "Xã Thuận Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25390",
                                    "name": "Xã Đồng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25393",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "696",
                        "name": "Huyện Bù Đăng",
                        "Wards": [
                                {
                                    "id": "25396",
                                    "name": "Thị trấn Đức Phong",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25398",
                                    "name": "Xã Đường 10",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25399",
                                    "name": "Xã Đak Nhau",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25400",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25402",
                                    "name": "Xã Thọ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25404",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25405",
                                    "name": "Xã Bom Bo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25408",
                                    "name": "Xã Minh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25411",
                                    "name": "Xã Đoàn Kết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25414",
                                    "name": "Xã Đồng Nai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25417",
                                    "name": "Xã Đức Liễu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25420",
                                    "name": "Xã Thống Nhất",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25423",
                                    "name": "Xã Nghĩa Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25424",
                                    "name": "Xã Nghĩa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25426",
                                    "name": "Xã Đăng Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25429",
                                    "name": "Xã Phước Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "697",
                        "name": "Huyện Chơn Thành",
                        "Wards": [
                                {
                                    "id": "25432",
                                    "name": "Thị trấn Chơn Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25433",
                                    "name": "Xã Thành Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25435",
                                    "name": "Xã Minh Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25439",
                                    "name": "Xã Quang Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25441",
                                    "name": "Xã Minh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25444",
                                    "name": "Xã Minh Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25447",
                                    "name": "Xã Minh Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25450",
                                    "name": "Xã Nha Bích",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25453",
                                    "name": "Xã Minh Thắng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "698",
                        "name": "Huyện Phú Riềng",
                        "Wards": [
                                {
                                    "id": "25240",
                                    "name": "Xã Long Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25243",
                                    "name": "Xã Bình Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25244",
                                    "name": "Xã Bình Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25246",
                                    "name": "Xã Long Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25250",
                                    "name": "Xã Phước Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25252",
                                    "name": "Xã Bù Nho",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25255",
                                    "name": "Xã Long Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25258",
                                    "name": "Xã Long Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25261",
                                    "name": "Xã Phú Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25264",
                                    "name": "Xã Phú Riềng",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "72",
            "name": "Tỉnh Tây Ninh",
            "districts": [
                    {
                        "id": "703",
                        "name": "Thành phố Tây Ninh",
                        "Wards": [
                                {
                                    "id": "25456",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "25459",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "25462",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "25465",
                                    "name": "Phường Hiệp Ninh"
                                },
                                {
                                    "id": "25468",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "25471",
                                    "name": "Xã Thạnh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25474",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25477",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25480",
                                    "name": "Phường Ninh Sơn"
                                },
                                {
                                    "id": "25483",
                                    "name": "Phường Ninh Thạnh"
                                }
                        ]
                    },
                    {
                        "id": "705",
                        "name": "Huyện Tân Biên",
                        "Wards": [
                                {
                                    "id": "25486",
                                    "name": "Thị trấn Tân Biên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25489",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25492",
                                    "name": "Xã Thạnh Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25495",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25498",
                                    "name": "Xã Thạnh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25501",
                                    "name": "Xã Thạnh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25504",
                                    "name": "Xã Hòa Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25507",
                                    "name": "Xã Tân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25510",
                                    "name": "Xã Mỏ Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25513",
                                    "name": "Xã Trà Vong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "706",
                        "name": "Huyện Tân Châu",
                        "Wards": [
                                {
                                    "id": "25516",
                                    "name": "Thị trấn Tân Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25519",
                                    "name": "Xã Tân Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25522",
                                    "name": "Xã Tân Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25525",
                                    "name": "Xã Tân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25528",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25531",
                                    "name": "Xã Suối Ngô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25534",
                                    "name": "Xã Suối Dây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25537",
                                    "name": "Xã Tân Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25540",
                                    "name": "Xã Thạnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25543",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25546",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25549",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "707",
                        "name": "Huyện Dương Minh Châu",
                        "Wards": [
                                {
                                    "id": "25552",
                                    "name": "Thị trấn Dương Minh Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25555",
                                    "name": "Xã Suối Đá",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25558",
                                    "name": "Xã Phan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25561",
                                    "name": "Xã Phước Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25564",
                                    "name": "Xã Phước Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25567",
                                    "name": "Xã Bàu Năng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25570",
                                    "name": "Xã Chà Là",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25573",
                                    "name": "Xã Cầu Khởi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25576",
                                    "name": "Xã Bến Củi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25579",
                                    "name": "Xã Lộc Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25582",
                                    "name": "Xã Truông Mít",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "708",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "25585",
                                    "name": "Thị trấn Châu Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25588",
                                    "name": "Xã Hảo Đước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25591",
                                    "name": "Xã Phước Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25594",
                                    "name": "Xã Đồng Khởi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25597",
                                    "name": "Xã Thái Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25600",
                                    "name": "Xã An Cơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25603",
                                    "name": "Xã Biên Giới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25606",
                                    "name": "Xã Hòa Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25609",
                                    "name": "Xã Trí Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25612",
                                    "name": "Xã Hòa Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25615",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25618",
                                    "name": "Xã Thanh Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25621",
                                    "name": "Xã Thành Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25624",
                                    "name": "Xã Ninh Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25627",
                                    "name": "Xã Long Vĩnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "709",
                        "name": "Thị xã Hòa Thành",
                        "Wards": [
                                {
                                    "id": "25630",
                                    "name": "Phường Long Hoa"
                                },
                                {
                                    "id": "25633",
                                    "name": "Phường Hiệp Tân"
                                },
                                {
                                    "id": "25636",
                                    "name": "Phường Long Thành Bắc"
                                },
                                {
                                    "id": "25639",
                                    "name": "Xã Trường Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25642",
                                    "name": "Xã Trường Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25645",
                                    "name": "Phường Long Thành Trung"
                                },
                                {
                                    "id": "25648",
                                    "name": "Xã Trường Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25651",
                                    "name": "Xã Long Thành Nam",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "710",
                        "name": "Huyện Gò Dầu",
                        "Wards": [
                                {
                                    "id": "25654",
                                    "name": "Thị trấn Gò Dầu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25657",
                                    "name": "Xã Thạnh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25660",
                                    "name": "Xã Cẩm Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25663",
                                    "name": "Xã Hiệp Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25666",
                                    "name": "Xã Bàu Đồn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25669",
                                    "name": "Xã Phước Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25672",
                                    "name": "Xã Phước Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25675",
                                    "name": "Xã Phước Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25678",
                                    "name": "Xã Thanh Phước",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "711",
                        "name": "Huyện Bến Cầu",
                        "Wards": [
                                {
                                    "id": "25681",
                                    "name": "Thị trấn Bến Cầu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25684",
                                    "name": "Xã Long Chữ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25687",
                                    "name": "Xã Long Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25690",
                                    "name": "Xã Long Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25693",
                                    "name": "Xã Tiên Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25696",
                                    "name": "Xã Long Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25699",
                                    "name": "Xã Lợi Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25702",
                                    "name": "Xã Long Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25705",
                                    "name": "Xã An Thạnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "712",
                        "name": "Thị xã Trảng Bàng",
                        "Wards": [
                                {
                                    "id": "25708",
                                    "name": "Phường Trảng Bàng"
                                },
                                {
                                    "id": "25711",
                                    "name": "Xã Đôn Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25714",
                                    "name": "Xã Hưng Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25717",
                                    "name": "Phường Lộc Hưng"
                                },
                                {
                                    "id": "25720",
                                    "name": "Phường Gia Lộc"
                                },
                                {
                                    "id": "25723",
                                    "name": "Phường Gia Bình"
                                },
                                {
                                    "id": "25729",
                                    "name": "Xã Phước Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25732",
                                    "name": "Phường An Tịnh"
                                },
                                {
                                    "id": "25735",
                                    "name": "Phường An Hòa"
                                },
                                {
                                    "id": "25738",
                                    "name": "Xã Phước Chỉ",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "74",
            "name": "Tỉnh Bình Dương",
            "districts": [
                    {
                        "id": "718",
                        "name": "Thành phố Thủ Dầu Một",
                        "Wards": [
                                {
                                    "id": "25741",
                                    "name": "Phường Hiệp Thành"
                                },
                                {
                                    "id": "25744",
                                    "name": "Phường Phú Lợi"
                                },
                                {
                                    "id": "25747",
                                    "name": "Phường Phú Cường"
                                },
                                {
                                    "id": "25750",
                                    "name": "Phường Phú Hòa"
                                },
                                {
                                    "id": "25753",
                                    "name": "Phường Phú Thọ"
                                },
                                {
                                    "id": "25756",
                                    "name": "Phường Chánh Nghĩa"
                                },
                                {
                                    "id": "25759",
                                    "name": "Phường Định Hoà"
                                },
                                {
                                    "id": "25760",
                                    "name": "Phường Hoà Phú"
                                },
                                {
                                    "id": "25762",
                                    "name": "Phường Phú Mỹ"
                                },
                                {
                                    "id": "25763",
                                    "name": "Phường Phú Tân"
                                },
                                {
                                    "id": "25765",
                                    "name": "Phường Tân An"
                                },
                                {
                                    "id": "25768",
                                    "name": "Phường Hiệp An"
                                },
                                {
                                    "id": "25771",
                                    "name": "Phường Tương Bình Hiệp"
                                },
                                {
                                    "id": "25774",
                                    "name": "Phường Chánh Mỹ"
                                }
                        ]
                    },
                    {
                        "id": "719",
                        "name": "Huyện Bàu Bàng",
                        "Wards": [
                                {
                                    "id": "25816",
                                    "name": "Xã Trừ Văn Thố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25819",
                                    "name": "Xã Cây Trường II",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25822",
                                    "name": "Thị trấn Lai Uyên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25825",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25828",
                                    "name": "Xã Long Nguyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25831",
                                    "name": "Xã Hưng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25834",
                                    "name": "Xã Lai Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "720",
                        "name": "Huyện Dầu Tiếng",
                        "Wards": [
                                {
                                    "id": "25777",
                                    "name": "Thị trấn Dầu Tiếng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25780",
                                    "name": "Xã Minh Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25783",
                                    "name": "Xã Minh Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25786",
                                    "name": "Xã Minh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25789",
                                    "name": "Xã Định An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25792",
                                    "name": "Xã Long Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25795",
                                    "name": "Xã Định Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25798",
                                    "name": "Xã Định Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25801",
                                    "name": "Xã An Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25804",
                                    "name": "Xã Long Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25807",
                                    "name": "Xã Thanh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25810",
                                    "name": "Xã Thanh Tuyền",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "721",
                        "name": "Thị xã Bến Cát",
                        "Wards": [
                                {
                                    "id": "25813",
                                    "name": "Phường Mỹ Phước"
                                },
                                {
                                    "id": "25837",
                                    "name": "Phường Chánh Phú Hòa"
                                },
                                {
                                    "id": "25840",
                                    "name": "Xã An Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25843",
                                    "name": "Xã An Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25846",
                                    "name": "Phường Thới Hòa"
                                },
                                {
                                    "id": "25849",
                                    "name": "Phường Hòa Lợi"
                                },
                                {
                                    "id": "25852",
                                    "name": "Phường Tân Định"
                                },
                                {
                                    "id": "25855",
                                    "name": "Xã Phú An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "722",
                        "name": "Huyện Phú Giáo",
                        "Wards": [
                                {
                                    "id": "25858",
                                    "name": "Thị trấn Phước Vĩnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25861",
                                    "name": "Xã An Linh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25864",
                                    "name": "Xã Phước Sang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25865",
                                    "name": "Xã An Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25867",
                                    "name": "Xã An Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25870",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25873",
                                    "name": "Xã Tân Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25876",
                                    "name": "Xã Tam Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25879",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25882",
                                    "name": "Xã Vĩnh Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25885",
                                    "name": "Xã Phước Hoà",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "723",
                        "name": "Thị xã Tân Uyên",
                        "Wards": [
                                {
                                    "id": "25888",
                                    "name": "Phường Uyên Hưng"
                                },
                                {
                                    "id": "25891",
                                    "name": "Phường Tân Phước Khánh"
                                },
                                {
                                    "id": "25912",
                                    "name": "Phường Vĩnh Tân"
                                },
                                {
                                    "id": "25915",
                                    "name": "Phường Hội Nghĩa"
                                },
                                {
                                    "id": "25920",
                                    "name": "Phường Tân Hiệp"
                                },
                                {
                                    "id": "25921",
                                    "name": "Phường Khánh Bình"
                                },
                                {
                                    "id": "25924",
                                    "name": "Phường Phú Chánh"
                                },
                                {
                                    "id": "25930",
                                    "name": "Xã Bạch Đằng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25933",
                                    "name": "Phường Tân Vĩnh Hiệp"
                                },
                                {
                                    "id": "25936",
                                    "name": "Phường Thạnh Phước"
                                },
                                {
                                    "id": "25937",
                                    "name": "Xã Thạnh Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25939",
                                    "name": "Phường Thái Hòa"
                                }
                        ]
                    },
                    {
                        "id": "724",
                        "name": "Thành phố Dĩ An",
                        "Wards": [
                                {
                                    "id": "25942",
                                    "name": "Phường Dĩ An"
                                },
                                {
                                    "id": "25945",
                                    "name": "Phường Tân Bình"
                                },
                                {
                                    "id": "25948",
                                    "name": "Phường Tân Đông Hiệp"
                                },
                                {
                                    "id": "25951",
                                    "name": "Phường Bình An"
                                },
                                {
                                    "id": "25954",
                                    "name": "Phường Bình Thắng"
                                },
                                {
                                    "id": "25957",
                                    "name": "Phường Đông Hòa"
                                },
                                {
                                    "id": "25960",
                                    "name": "Phường An Bình"
                                }
                        ]
                    },
                    {
                        "id": "725",
                        "name": "Thành phố Thuận An",
                        "Wards": [
                                {
                                    "id": "25963",
                                    "name": "Phường An Thạnh"
                                },
                                {
                                    "id": "25966",
                                    "name": "Phường Lái Thiêu"
                                },
                                {
                                    "id": "25969",
                                    "name": "Phường Bình Chuẩn"
                                },
                                {
                                    "id": "25972",
                                    "name": "Phường Thuận Giao"
                                },
                                {
                                    "id": "25975",
                                    "name": "Phường An Phú"
                                },
                                {
                                    "id": "25978",
                                    "name": "Phường Hưng Định"
                                },
                                {
                                    "id": "25981",
                                    "name": "Xã An Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25984",
                                    "name": "Phường Bình Nhâm"
                                },
                                {
                                    "id": "25987",
                                    "name": "Phường Bình Hòa"
                                },
                                {
                                    "id": "25990",
                                    "name": "Phường Vĩnh Phú"
                                }
                        ]
                    },
                    {
                        "id": "726",
                        "name": "Huyện Bắc Tân Uyên",
                        "Wards": [
                                {
                                    "id": "25894",
                                    "name": "Xã Tân Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25897",
                                    "name": "Xã Bình Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25900",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25903",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25906",
                                    "name": "Thị trấn Tân Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "25907",
                                    "name": "Xã Đất Cuốc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25908",
                                    "name": "Xã Hiếu Liêm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25909",
                                    "name": "Xã Lạc An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25918",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "25927",
                                    "name": "Xã Thường Tân",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "75",
            "name": "Tỉnh Đồng Nai",
            "districts": [
                    {
                        "id": "731",
                        "name": "Thành phố Biên Hòa",
                        "Wards": [
                                {
                                    "id": "25993",
                                    "name": "Phường Trảng Dài"
                                },
                                {
                                    "id": "25996",
                                    "name": "Phường Tân Phong"
                                },
                                {
                                    "id": "25999",
                                    "name": "Phường Tân Biên"
                                },
                                {
                                    "id": "26002",
                                    "name": "Phường Hố Nai"
                                },
                                {
                                    "id": "26005",
                                    "name": "Phường Tân Hòa"
                                },
                                {
                                    "id": "26008",
                                    "name": "Phường Tân Hiệp"
                                },
                                {
                                    "id": "26011",
                                    "name": "Phường Bửu Long"
                                },
                                {
                                    "id": "26014",
                                    "name": "Phường Tân Tiến"
                                },
                                {
                                    "id": "26017",
                                    "name": "Phường Tam Hiệp"
                                },
                                {
                                    "id": "26020",
                                    "name": "Phường Long Bình"
                                },
                                {
                                    "id": "26023",
                                    "name": "Phường Quang Vinh"
                                },
                                {
                                    "id": "26026",
                                    "name": "Phường Tân Mai"
                                },
                                {
                                    "id": "26029",
                                    "name": "Phường Thống Nhất"
                                },
                                {
                                    "id": "26032",
                                    "name": "Phường Trung Dũng"
                                },
                                {
                                    "id": "26035",
                                    "name": "Phường Tam Hòa"
                                },
                                {
                                    "id": "26038",
                                    "name": "Phường Hòa Bình"
                                },
                                {
                                    "id": "26041",
                                    "name": "Phường Quyết Thắng"
                                },
                                {
                                    "id": "26044",
                                    "name": "Phường Thanh Bình"
                                },
                                {
                                    "id": "26047",
                                    "name": "Phường Bình Đa"
                                },
                                {
                                    "id": "26050",
                                    "name": "Phường An Bình"
                                },
                                {
                                    "id": "26053",
                                    "name": "Phường Bửu Hòa"
                                },
                                {
                                    "id": "26056",
                                    "name": "Phường Long Bình Tân"
                                },
                                {
                                    "id": "26059",
                                    "name": "Phường Tân Vạn"
                                },
                                {
                                    "id": "26062",
                                    "name": "Phường Tân Hạnh"
                                },
                                {
                                    "id": "26065",
                                    "name": "Phường Hiệp Hòa"
                                },
                                {
                                    "id": "26068",
                                    "name": "Phường Hóa An"
                                },
                                {
                                    "id": "26371",
                                    "name": "Phường An Hòa"
                                },
                                {
                                    "id": "26374",
                                    "name": "Phường Tam Phước"
                                },
                                {
                                    "id": "26377",
                                    "name": "Phường Phước Tân"
                                },
                                {
                                    "id": "26380",
                                    "name": "Xã Long Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "732",
                        "name": "Thành phố Long Khánh",
                        "Wards": [
                                {
                                    "id": "26071",
                                    "name": "Phường Xuân Trung"
                                },
                                {
                                    "id": "26074",
                                    "name": "Phường Xuân Thanh"
                                },
                                {
                                    "id": "26077",
                                    "name": "Phường Xuân Bình"
                                },
                                {
                                    "id": "26080",
                                    "name": "Phường Xuân An"
                                },
                                {
                                    "id": "26083",
                                    "name": "Phường Xuân Hoà"
                                },
                                {
                                    "id": "26086",
                                    "name": "Phường Phú Bình"
                                },
                                {
                                    "id": "26089",
                                    "name": "Xã Bình Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26092",
                                    "name": "Xã Bảo Quang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26095",
                                    "name": "Phường Suối Tre"
                                },
                                {
                                    "id": "26098",
                                    "name": "Phường Bảo Vinh"
                                },
                                {
                                    "id": "26101",
                                    "name": "Phường Xuân Lập"
                                },
                                {
                                    "id": "26104",
                                    "name": "Phường Bàu Sen"
                                },
                                {
                                    "id": "26107",
                                    "name": "Xã Bàu Trâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26110",
                                    "name": "Phường Xuân Tân"
                                },
                                {
                                    "id": "26113",
                                    "name": "Xã Hàng Gòn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "734",
                        "name": "Huyện Tân Phú",
                        "Wards": [
                                {
                                    "id": "26116",
                                    "name": "Thị trấn Tân Phú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26119",
                                    "name": "Xã Dak Lua",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26122",
                                    "name": "Xã Nam Cát Tiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26125",
                                    "name": "Xã Phú An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26128",
                                    "name": "Xã Núi Tượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26131",
                                    "name": "Xã Tà Lài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26134",
                                    "name": "Xã Phú Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26137",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26140",
                                    "name": "Xã Phú Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26143",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26146",
                                    "name": "Xã Phú Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26149",
                                    "name": "Xã Phú Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26152",
                                    "name": "Xã Phú Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26155",
                                    "name": "Xã Phú Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26158",
                                    "name": "Xã Phú Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26161",
                                    "name": "Xã Phú Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26164",
                                    "name": "Xã Trà Cổ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26167",
                                    "name": "Xã Phú Điền",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "735",
                        "name": "Huyện Vĩnh Cửu",
                        "Wards": [
                                {
                                    "id": "26170",
                                    "name": "Thị trấn Vĩnh An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26173",
                                    "name": "Xã Phú Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26176",
                                    "name": "Xã Trị An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26179",
                                    "name": "Xã Tân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26182",
                                    "name": "Xã Vĩnh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26185",
                                    "name": "Xã Bình Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26188",
                                    "name": "Xã Thạnh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26191",
                                    "name": "Xã Thiện Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26194",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26197",
                                    "name": "Xã Bình Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26200",
                                    "name": "Xã Mã Đà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26203",
                                    "name": "Xã Hiếu Liêm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "736",
                        "name": "Huyện Định Quán",
                        "Wards": [
                                {
                                    "id": "26206",
                                    "name": "Thị trấn Định Quán",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26209",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26212",
                                    "name": "Xã Phú Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26215",
                                    "name": "Xã Phú Vinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26218",
                                    "name": "Xã Phú Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26221",
                                    "name": "Xã Phú Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26224",
                                    "name": "Xã Ngọc Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26227",
                                    "name": "Xã La Ngà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26230",
                                    "name": "Xã Gia Canh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26233",
                                    "name": "Xã Phú Ngọc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26236",
                                    "name": "Xã Phú Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26239",
                                    "name": "Xã Túc Trưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26242",
                                    "name": "Xã Phú Túc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26245",
                                    "name": "Xã Suối Nho",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "737",
                        "name": "Huyện Trảng Bom",
                        "Wards": [
                                {
                                    "id": "26248",
                                    "name": "Thị trấn Trảng Bom",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26251",
                                    "name": "Xã Thanh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26254",
                                    "name": "Xã Cây Gáo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26257",
                                    "name": "Xã Bàu Hàm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26260",
                                    "name": "Xã Sông Thao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26263",
                                    "name": "Xã Sông Trầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26266",
                                    "name": "Xã Đông Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26269",
                                    "name": "Xã Bắc Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26272",
                                    "name": "Xã Hố Nai 3",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26275",
                                    "name": "Xã Tây Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26278",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26281",
                                    "name": "Xã Trung Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26284",
                                    "name": "Xã Đồi 61",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26287",
                                    "name": "Xã Hưng Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26290",
                                    "name": "Xã Quảng Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26293",
                                    "name": "Xã Giang Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26296",
                                    "name": "Xã An Viễn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "738",
                        "name": "Huyện Thống Nhất",
                        "Wards": [
                                {
                                    "id": "26299",
                                    "name": "Xã Gia Tân 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26302",
                                    "name": "Xã Gia Tân 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26305",
                                    "name": "Xã Gia Tân 3",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26308",
                                    "name": "Xã Gia Kiệm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26311",
                                    "name": "Xã Quang Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26314",
                                    "name": "Xã Bàu Hàm 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26317",
                                    "name": "Xã Hưng Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26320",
                                    "name": "Xã Lộ 25",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26323",
                                    "name": "Xã Xuân Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26326",
                                    "name": "Thị trấn Dầu Giây",
                                    "Level": "Thị trấn"
                                }
                        ]
                    },
                    {
                        "id": "739",
                        "name": "Huyện Cẩm Mỹ",
                        "Wards": [
                                {
                                    "id": "26329",
                                    "name": "Xã Sông Nhạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26332",
                                    "name": "Xã Xuân Quế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26335",
                                    "name": "Xã Nhân Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26338",
                                    "name": "Xã Xuân Đường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26341",
                                    "name": "Xã Long Giao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26344",
                                    "name": "Xã Xuân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26347",
                                    "name": "Xã Thừa Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26350",
                                    "name": "Xã Bảo Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26353",
                                    "name": "Xã Xuân Bảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26356",
                                    "name": "Xã Xuân Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26359",
                                    "name": "Xã Xuân Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26362",
                                    "name": "Xã Sông Ray",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26365",
                                    "name": "Xã Lâm San",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "740",
                        "name": "Huyện Long Thành",
                        "Wards": [
                                {
                                    "id": "26368",
                                    "name": "Thị trấn Long Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26383",
                                    "name": "Xã An Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26386",
                                    "name": "Xã Bình An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26389",
                                    "name": "Xã Long Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26392",
                                    "name": "Xã Lộc An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26395",
                                    "name": "Xã Bình Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26398",
                                    "name": "Xã Tam An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26401",
                                    "name": "Xã Cẩm Đường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26404",
                                    "name": "Xã Long An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26410",
                                    "name": "Xã Bàu Cạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26413",
                                    "name": "Xã Long Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26416",
                                    "name": "Xã Phước Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26419",
                                    "name": "Xã Tân Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26422",
                                    "name": "Xã Phước Thái",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "741",
                        "name": "Huyện Xuân Lộc",
                        "Wards": [
                                {
                                    "id": "26425",
                                    "name": "Thị trấn Gia Ray",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26428",
                                    "name": "Xã Xuân Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26431",
                                    "name": "Xã Suối Cao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26434",
                                    "name": "Xã Xuân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26437",
                                    "name": "Xã Xuân Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26440",
                                    "name": "Xã Xuân Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26443",
                                    "name": "Xã Xuân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26446",
                                    "name": "Xã Xuân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26449",
                                    "name": "Xã Xuân Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26452",
                                    "name": "Xã Suối Cát",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26455",
                                    "name": "Xã Xuân Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26458",
                                    "name": "Xã Xuân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26461",
                                    "name": "Xã Xuân Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26464",
                                    "name": "Xã Bảo Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26467",
                                    "name": "Xã Lang Minh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "742",
                        "name": "Huyện Nhơn Trạch",
                        "Wards": [
                                {
                                    "id": "26470",
                                    "name": "Xã Phước Thiền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26473",
                                    "name": "Xã Long Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26476",
                                    "name": "Xã Đại Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26479",
                                    "name": "Thị trấn Hiệp Phước",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26482",
                                    "name": "Xã Phú Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26485",
                                    "name": "Xã Phú Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26488",
                                    "name": "Xã Phú Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26491",
                                    "name": "Xã Phú Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26494",
                                    "name": "Xã Long Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26497",
                                    "name": "Xã Vĩnh Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26500",
                                    "name": "Xã Phước Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26503",
                                    "name": "Xã Phước An",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "77",
            "name": "Tỉnh Bà Rịa - Vũng Tàu",
            "districts": [
                    {
                        "id": "747",
                        "name": "Thành phố Vũng Tàu",
                        "Wards": [
                                {
                                    "id": "26506",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "26508",
                                    "name": "Phường Thắng Tam"
                                },
                                {
                                    "id": "26509",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "26512",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "26515",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "26518",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "26521",
                                    "name": "Phường Thắng Nhì"
                                },
                                {
                                    "id": "26524",
                                    "name": "Phường 7"
                                },
                                {
                                    "id": "26526",
                                    "name": "Phường Nguyễn An Ninh"
                                },
                                {
                                    "id": "26527",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "26530",
                                    "name": "Phường 9"
                                },
                                {
                                    "id": "26533",
                                    "name": "Phường Thắng Nhất"
                                },
                                {
                                    "id": "26535",
                                    "name": "Phường Rạch Dừa"
                                },
                                {
                                    "id": "26536",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "26539",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "26542",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "26545",
                                    "name": "Xã Long Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "748",
                        "name": "Thành phố Bà Rịa",
                        "Wards": [
                                {
                                    "id": "26548",
                                    "name": "Phường Phước Hưng"
                                },
                                {
                                    "id": "26551",
                                    "name": "Phường Phước Hiệp"
                                },
                                {
                                    "id": "26554",
                                    "name": "Phường Phước Nguyên"
                                },
                                {
                                    "id": "26557",
                                    "name": "Phường Long Toàn"
                                },
                                {
                                    "id": "26558",
                                    "name": "Phường Long Tâm"
                                },
                                {
                                    "id": "26560",
                                    "name": "Phường Phước Trung"
                                },
                                {
                                    "id": "26563",
                                    "name": "Phường Long Hương"
                                },
                                {
                                    "id": "26566",
                                    "name": "Phường Kim Dinh"
                                },
                                {
                                    "id": "26567",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26569",
                                    "name": "Xã Long Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26572",
                                    "name": "Xã Hoà Long",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "750",
                        "name": "Huyện Châu Đức",
                        "Wards": [
                                {
                                    "id": "26574",
                                    "name": "Xã Bàu Chinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26575",
                                    "name": "Thị trấn Ngãi Giao",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26578",
                                    "name": "Xã Bình Ba",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26581",
                                    "name": "Xã Suối Nghệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26584",
                                    "name": "Xã Xuân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26587",
                                    "name": "Xã Sơn Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26590",
                                    "name": "Xã Bình Giã",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26593",
                                    "name": "Xã Bình Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26596",
                                    "name": "Xã Xà Bang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26599",
                                    "name": "Xã Cù Bị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26602",
                                    "name": "Xã Láng Lớn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26605",
                                    "name": "Xã Quảng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26608",
                                    "name": "Xã Kim Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26611",
                                    "name": "Xã Suối Rao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26614",
                                    "name": "Xã Đá Bạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26617",
                                    "name": "Xã Nghĩa Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "751",
                        "name": "Huyện Xuyên Mộc",
                        "Wards": [
                                {
                                    "id": "26620",
                                    "name": "Thị trấn Phước Bửu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26623",
                                    "name": "Xã Phước Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26626",
                                    "name": "Xã Phước Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26629",
                                    "name": "Xã Xuyên Mộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26632",
                                    "name": "Xã Bông Trang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26635",
                                    "name": "Xã Tân Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26638",
                                    "name": "Xã Bàu Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26641",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26644",
                                    "name": "Xã Hòa Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26647",
                                    "name": "Xã Hòa Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26650",
                                    "name": "Xã Hòa Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26653",
                                    "name": "Xã Bưng Riềng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26656",
                                    "name": "Xã Bình Châu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "752",
                        "name": "Huyện Long Điền",
                        "Wards": [
                                {
                                    "id": "26659",
                                    "name": "Thị trấn Long Điền",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26662",
                                    "name": "Thị trấn Long Hải",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26665",
                                    "name": "Xã An Ngãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26668",
                                    "name": "Xã Tam Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26671",
                                    "name": "Xã An Nhứt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26674",
                                    "name": "Xã Phước Tỉnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26677",
                                    "name": "Xã Phước Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "753",
                        "name": "Huyện Đất Đỏ",
                        "Wards": [
                                {
                                    "id": "26680",
                                    "name": "Thị trấn Đất Đỏ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26683",
                                    "name": "Xã Phước Long Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26686",
                                    "name": "Xã Phước Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26689",
                                    "name": "Xã Long Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26692",
                                    "name": "Thị trấn Phước Hải",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "26695",
                                    "name": "Xã Long Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26698",
                                    "name": "Xã Láng Dài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26701",
                                    "name": "Xã Lộc An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "754",
                        "name": "Thị xã Phú Mỹ",
                        "Wards": [
                                {
                                    "id": "26704",
                                    "name": "Phường Phú Mỹ"
                                },
                                {
                                    "id": "26707",
                                    "name": "Xã Tân Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26710",
                                    "name": "Xã Tân Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26713",
                                    "name": "Phường Phước Hoà"
                                },
                                {
                                    "id": "26716",
                                    "name": "Phường Tân Phước"
                                },
                                {
                                    "id": "26719",
                                    "name": "Phường Mỹ Xuân"
                                },
                                {
                                    "id": "26722",
                                    "name": "Xã Sông Xoài",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26725",
                                    "name": "Phường Hắc Dịch"
                                },
                                {
                                    "id": "26728",
                                    "name": "Xã Châu Pha",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "26731",
                                    "name": "Xã Tóc Tiên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "755",
                        "name": "Huyện Côn Đảo",
                        "Wards": [
                                {
                                    "Level": "Huyện"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "79",
            "name": "Thành phố Hồ Chí Minh",
            "districts": [
                    {
                        "id": "760",
                        "name": "Quận 1",
                        "Wards": [
                                {
                                    "id": "26734",
                                    "name": "Phường Tân Định"
                                },
                                {
                                    "id": "26737",
                                    "name": "Phường Đa Kao"
                                },
                                {
                                    "id": "26740",
                                    "name": "Phường Bến Nghé"
                                },
                                {
                                    "id": "26743",
                                    "name": "Phường Bến Thành"
                                },
                                {
                                    "id": "26746",
                                    "name": "Phường Nguyễn Thái Bình"
                                },
                                {
                                    "id": "26749",
                                    "name": "Phường Phạm Ngũ Lão"
                                },
                                {
                                    "id": "26752",
                                    "name": "Phường Cầu Ông Lãnh"
                                },
                                {
                                    "id": "26755",
                                    "name": "Phường Cô Giang"
                                },
                                {
                                    "id": "26758",
                                    "name": "Phường Nguyễn Cư Trinh"
                                },
                                {
                                    "id": "26761",
                                    "name": "Phường Cầu Kho"
                                }
                        ]
                    },
                    {
                        "id": "761",
                        "name": "Quận 12",
                        "Wards": [
                                {
                                    "id": "26764",
                                    "name": "Phường Thạnh Xuân"
                                },
                                {
                                    "id": "26767",
                                    "name": "Phường Thạnh Lộc"
                                },
                                {
                                    "id": "26770",
                                    "name": "Phường Hiệp Thành"
                                },
                                {
                                    "id": "26773",
                                    "name": "Phường Thới An"
                                },
                                {
                                    "id": "26776",
                                    "name": "Phường Tân Chánh Hiệp"
                                },
                                {
                                    "id": "26779",
                                    "name": "Phường An Phú Đông"
                                },
                                {
                                    "id": "26782",
                                    "name": "Phường Tân Thới Hiệp"
                                },
                                {
                                    "id": "26785",
                                    "name": "Phường Trung Mỹ Tây"
                                },
                                {
                                    "id": "26787",
                                    "name": "Phường Tân Hưng Thuận"
                                },
                                {
                                    "id": "26788",
                                    "name": "Phường Đông Hưng Thuận"
                                },
                                {
                                    "id": "26791",
                                    "name": "Phường Tân Thới Nhất"
                                }
                        ]
                    },
                    {
                        "id": "762",
                        "name": "Quận Thủ Đức",
                        "Wards": [
                                {
                                    "id": "26794",
                                    "name": "Phường Linh Xuân"
                                },
                                {
                                    "id": "26797",
                                    "name": "Phường Bình Chiểu"
                                },
                                {
                                    "id": "26800",
                                    "name": "Phường Linh Trung"
                                },
                                {
                                    "id": "26803",
                                    "name": "Phường Tam Bình"
                                },
                                {
                                    "id": "26806",
                                    "name": "Phường Tam Phú"
                                },
                                {
                                    "id": "26809",
                                    "name": "Phường Hiệp Bình Phước"
                                },
                                {
                                    "id": "26812",
                                    "name": "Phường Hiệp Bình Chánh"
                                },
                                {
                                    "id": "26815",
                                    "name": "Phường Linh Chiểu"
                                },
                                {
                                    "id": "26818",
                                    "name": "Phường Linh Tây"
                                },
                                {
                                    "id": "26821",
                                    "name": "Phường Linh Đông"
                                },
                                {
                                    "id": "26824",
                                    "name": "Phường Bình Thọ"
                                },
                                {
                                    "id": "26827",
                                    "name": "Phường Trường Thọ"
                                }
                        ]
                    },
                    {
                        "id": "763",
                        "name": "Quận 9",
                        "Wards": [
                                {
                                    "id": "26830",
                                    "name": "Phường Long Bình"
                                },
                                {
                                    "id": "26833",
                                    "name": "Phường Long Thạnh Mỹ"
                                },
                                {
                                    "id": "26836",
                                    "name": "Phường Tân Phú"
                                },
                                {
                                    "id": "26839",
                                    "name": "Phường Hiệp Phú"
                                },
                                {
                                    "id": "26842",
                                    "name": "Phường Tăng Nhơn Phú A"
                                },
                                {
                                    "id": "26845",
                                    "name": "Phường Tăng Nhơn Phú B"
                                },
                                {
                                    "id": "26848",
                                    "name": "Phường Phước Long B"
                                },
                                {
                                    "id": "26851",
                                    "name": "Phường Phước Long A"
                                },
                                {
                                    "id": "26854",
                                    "name": "Phường Trường Thạnh"
                                },
                                {
                                    "id": "26857",
                                    "name": "Phường Long Phước"
                                },
                                {
                                    "id": "26860",
                                    "name": "Phường Long Trường"
                                },
                                {
                                    "id": "26863",
                                    "name": "Phường Phước Bình"
                                },
                                {
                                    "id": "26866",
                                    "name": "Phường Phú Hữu"
                                }
                        ]
                    },
                    {
                        "id": "764",
                        "name": "Quận Gò Vấp",
                        "Wards": [
                                {
                                    "id": "26869",
                                    "name": "Phường 15"
                                },
                                {
                                    "id": "26872",
                                    "name": "Phường 13"
                                },
                                {
                                    "id": "26875",
                                    "name": "Phường 17"
                                },
                                {
                                    "id": "26876",
                                    "name": "Phường 6"
                                },
                                {
                                    "id": "26878",
                                    "name": "Phường 16"
                                },
                                {
                                    "id": "26881",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "26882",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "26884",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "26887",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "26890",
                                    "name": "Phường 07"
                                },
                                {
                                    "id": "26893",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "26896",
                                    "name": "Phường 01"
                                },
                                {
                                    "id": "26897",
                                    "name": "Phường 9"
                                },
                                {
                                    "id": "26898",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "26899",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "26902",
                                    "name": "Phường 03"
                                }
                        ]
                    },
                    {
                        "id": "765",
                        "name": "Quận Bình Thạnh",
                        "Wards": [
                                {
                                    "id": "26905",
                                    "name": "Phường 13"
                                },
                                {
                                    "id": "26908",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "26911",
                                    "name": "Phường 27"
                                },
                                {
                                    "id": "26914",
                                    "name": "Phường 26"
                                },
                                {
                                    "id": "26917",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "26920",
                                    "name": "Phường 25"
                                },
                                {
                                    "id": "26923",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "26926",
                                    "name": "Phường 07"
                                },
                                {
                                    "id": "26929",
                                    "name": "Phường 24"
                                },
                                {
                                    "id": "26932",
                                    "name": "Phường 06"
                                },
                                {
                                    "id": "26935",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "26938",
                                    "name": "Phường 15"
                                },
                                {
                                    "id": "26941",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "26944",
                                    "name": "Phường 01"
                                },
                                {
                                    "id": "26947",
                                    "name": "Phường 03"
                                },
                                {
                                    "id": "26950",
                                    "name": "Phường 17"
                                },
                                {
                                    "id": "26953",
                                    "name": "Phường 21"
                                },
                                {
                                    "id": "26956",
                                    "name": "Phường 22"
                                },
                                {
                                    "id": "26959",
                                    "name": "Phường 19"
                                },
                                {
                                    "id": "26962",
                                    "name": "Phường 28"
                                }
                        ]
                    },
                    {
                        "id": "766",
                        "name": "Quận Tân Bình",
                        "Wards": [
                                {
                                    "id": "26965",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "26968",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "26971",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "26974",
                                    "name": "Phường 13"
                                },
                                {
                                    "id": "26977",
                                    "name": "Phường 01"
                                },
                                {
                                    "id": "26980",
                                    "name": "Phường 03"
                                },
                                {
                                    "id": "26983",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "26986",
                                    "name": "Phường 07"
                                },
                                {
                                    "id": "26989",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "26992",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "26995",
                                    "name": "Phường 06"
                                },
                                {
                                    "id": "26998",
                                    "name": "Phường 08"
                                },
                                {
                                    "id": "27001",
                                    "name": "Phường 09"
                                },
                                {
                                    "id": "27004",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "27007",
                                    "name": "Phường 15"
                                }
                        ]
                    },
                    {
                        "id": "767",
                        "name": "Quận Tân Phú",
                        "Wards": [
                                {
                                    "id": "27010",
                                    "name": "Phường Tân Sơn Nhì"
                                },
                                {
                                    "id": "27013",
                                    "name": "Phường Tây Thạnh"
                                },
                                {
                                    "id": "27016",
                                    "name": "Phường Sơn Kỳ"
                                },
                                {
                                    "id": "27019",
                                    "name": "Phường Tân Quý"
                                },
                                {
                                    "id": "27022",
                                    "name": "Phường Tân Thành"
                                },
                                {
                                    "id": "27025",
                                    "name": "Phường Phú Thọ Hòa"
                                },
                                {
                                    "id": "27028",
                                    "name": "Phường Phú Thạnh"
                                },
                                {
                                    "id": "27031",
                                    "name": "Phường Phú Trung"
                                },
                                {
                                    "id": "27034",
                                    "name": "Phường Hòa Thạnh"
                                },
                                {
                                    "id": "27037",
                                    "name": "Phường Hiệp Tân"
                                },
                                {
                                    "id": "27040",
                                    "name": "Phường Tân Thới Hòa"
                                }
                        ]
                    },
                    {
                        "id": "768",
                        "name": "Quận Phú Nhuận",
                        "Wards": [
                                {
                                    "id": "27043",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "27046",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "27049",
                                    "name": "Phường 09"
                                },
                                {
                                    "id": "27052",
                                    "name": "Phường 07"
                                },
                                {
                                    "id": "27055",
                                    "name": "Phường 03"
                                },
                                {
                                    "id": "27058",
                                    "name": "Phường 01"
                                },
                                {
                                    "id": "27061",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "27064",
                                    "name": "Phường 08"
                                },
                                {
                                    "id": "27067",
                                    "name": "Phường 15"
                                },
                                {
                                    "id": "27070",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "27073",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "27076",
                                    "name": "Phường 17"
                                },
                                {
                                    "id": "27079",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "27082",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "27085",
                                    "name": "Phường 13"
                                }
                        ]
                    },
                    {
                        "id": "769",
                        "name": "Quận 2",
                        "Wards": [
                                {
                                    "id": "27088",
                                    "name": "Phường Thảo Điền"
                                },
                                {
                                    "id": "27091",
                                    "name": "Phường An Phú"
                                },
                                {
                                    "id": "27094",
                                    "name": "Phường Bình An"
                                },
                                {
                                    "id": "27097",
                                    "name": "Phường Bình Trưng Đông"
                                },
                                {
                                    "id": "27100",
                                    "name": "Phường Bình Trưng Tây"
                                },
                                {
                                    "id": "27103",
                                    "name": "Phường Bình Khánh"
                                },
                                {
                                    "id": "27106",
                                    "name": "Phường An Khánh"
                                },
                                {
                                    "id": "27109",
                                    "name": "Phường Cát Lái"
                                },
                                {
                                    "id": "27112",
                                    "name": "Phường Thạnh Mỹ Lợi"
                                },
                                {
                                    "id": "27115",
                                    "name": "Phường An Lợi Đông"
                                },
                                {
                                    "id": "27118",
                                    "name": "Phường Thủ Thiêm"
                                }
                        ]
                    },
                    {
                        "id": "770",
                        "name": "Quận 3",
                        "Wards": [
                                {
                                    "id": "27121",
                                    "name": "Phường 08"
                                },
                                {
                                    "id": "27124",
                                    "name": "Phường 07"
                                },
                                {
                                    "id": "27127",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "27130",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "27133",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "27136",
                                    "name": "Phường 13"
                                },
                                {
                                    "id": "27139",
                                    "name": "Phường 06"
                                },
                                {
                                    "id": "27142",
                                    "name": "Phường 09"
                                },
                                {
                                    "id": "27145",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "27148",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "27151",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "27154",
                                    "name": "Phường 03"
                                },
                                {
                                    "id": "27157",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "27160",
                                    "name": "Phường 01"
                                }
                        ]
                    },
                    {
                        "id": "771",
                        "name": "Quận 10",
                        "Wards": [
                                {
                                    "id": "27163",
                                    "name": "Phường 15"
                                },
                                {
                                    "id": "27166",
                                    "name": "Phường 13"
                                },
                                {
                                    "id": "27169",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "27172",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "27175",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "27178",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "27181",
                                    "name": "Phường 09"
                                },
                                {
                                    "id": "27184",
                                    "name": "Phường 01"
                                },
                                {
                                    "id": "27187",
                                    "name": "Phường 08"
                                },
                                {
                                    "id": "27190",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "27193",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "27196",
                                    "name": "Phường 07"
                                },
                                {
                                    "id": "27199",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "27202",
                                    "name": "Phường 06"
                                },
                                {
                                    "id": "27205",
                                    "name": "Phường 03"
                                }
                        ]
                    },
                    {
                        "id": "772",
                        "name": "Quận 11",
                        "Wards": [
                                {
                                    "id": "27208",
                                    "name": "Phường 15"
                                },
                                {
                                    "id": "27211",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "27214",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "27217",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "27220",
                                    "name": "Phường 03"
                                },
                                {
                                    "id": "27223",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "27226",
                                    "name": "Phường 13"
                                },
                                {
                                    "id": "27229",
                                    "name": "Phường 08"
                                },
                                {
                                    "id": "27232",
                                    "name": "Phường 09"
                                },
                                {
                                    "id": "27235",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "27238",
                                    "name": "Phường 07"
                                },
                                {
                                    "id": "27241",
                                    "name": "Phường 06"
                                },
                                {
                                    "id": "27244",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "27247",
                                    "name": "Phường 01"
                                },
                                {
                                    "id": "27250",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "27253",
                                    "name": "Phường 16"
                                }
                        ]
                    },
                    {
                        "id": "773",
                        "name": "Quận 4",
                        "Wards": [
                                {
                                    "id": "27256",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "27259",
                                    "name": "Phường 13"
                                },
                                {
                                    "id": "27262",
                                    "name": "Phường 09"
                                },
                                {
                                    "id": "27265",
                                    "name": "Phường 06"
                                },
                                {
                                    "id": "27268",
                                    "name": "Phường 08"
                                },
                                {
                                    "id": "27271",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "27274",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "27277",
                                    "name": "Phường 18"
                                },
                                {
                                    "id": "27280",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "27283",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "27286",
                                    "name": "Phường 03"
                                },
                                {
                                    "id": "27289",
                                    "name": "Phường 16"
                                },
                                {
                                    "id": "27292",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "27295",
                                    "name": "Phường 15"
                                },
                                {
                                    "id": "27298",
                                    "name": "Phường 01"
                                }
                        ]
                    },
                    {
                        "id": "774",
                        "name": "Quận 5",
                        "Wards": [
                                {
                                    "id": "27301",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "27304",
                                    "name": "Phường 09"
                                },
                                {
                                    "id": "27307",
                                    "name": "Phường 03"
                                },
                                {
                                    "id": "27310",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "27313",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "27316",
                                    "name": "Phường 08"
                                },
                                {
                                    "id": "27319",
                                    "name": "Phường 15"
                                },
                                {
                                    "id": "27322",
                                    "name": "Phường 07"
                                },
                                {
                                    "id": "27325",
                                    "name": "Phường 01"
                                },
                                {
                                    "id": "27328",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "27331",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "27334",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "27337",
                                    "name": "Phường 06"
                                },
                                {
                                    "id": "27340",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "27343",
                                    "name": "Phường 13"
                                }
                        ]
                    },
                    {
                        "id": "775",
                        "name": "Quận 6",
                        "Wards": [
                                {
                                    "id": "27346",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "27349",
                                    "name": "Phường 13"
                                },
                                {
                                    "id": "27352",
                                    "name": "Phường 09"
                                },
                                {
                                    "id": "27355",
                                    "name": "Phường 06"
                                },
                                {
                                    "id": "27358",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "27361",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "27364",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "27367",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "27370",
                                    "name": "Phường 01"
                                },
                                {
                                    "id": "27373",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "27376",
                                    "name": "Phường 08"
                                },
                                {
                                    "id": "27379",
                                    "name": "Phường 03"
                                },
                                {
                                    "id": "27382",
                                    "name": "Phường 07"
                                },
                                {
                                    "id": "27385",
                                    "name": "Phường 10"
                                }
                        ]
                    },
                    {
                        "id": "776",
                        "name": "Quận 8",
                        "Wards": [
                                {
                                    "id": "27388",
                                    "name": "Phường 08"
                                },
                                {
                                    "id": "27391",
                                    "name": "Phường 02"
                                },
                                {
                                    "id": "27394",
                                    "name": "Phường 01"
                                },
                                {
                                    "id": "27397",
                                    "name": "Phường 03"
                                },
                                {
                                    "id": "27400",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "27403",
                                    "name": "Phường 09"
                                },
                                {
                                    "id": "27406",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "27409",
                                    "name": "Phường 04"
                                },
                                {
                                    "id": "27412",
                                    "name": "Phường 13"
                                },
                                {
                                    "id": "27415",
                                    "name": "Phường 12"
                                },
                                {
                                    "id": "27418",
                                    "name": "Phường 05"
                                },
                                {
                                    "id": "27421",
                                    "name": "Phường 14"
                                },
                                {
                                    "id": "27424",
                                    "name": "Phường 06"
                                },
                                {
                                    "id": "27427",
                                    "name": "Phường 15"
                                },
                                {
                                    "id": "27430",
                                    "name": "Phường 16"
                                },
                                {
                                    "id": "27433",
                                    "name": "Phường 07"
                                }
                        ]
                    },
                    {
                        "id": "777",
                        "name": "Quận Bình Tân",
                        "Wards": [
                                {
                                    "id": "27436",
                                    "name": "Phường Bình Hưng Hòa"
                                },
                                {
                                    "id": "27439",
                                    "name": "Phường Bình Hưng Hoà A"
                                },
                                {
                                    "id": "27442",
                                    "name": "Phường Bình Hưng Hoà B"
                                },
                                {
                                    "id": "27445",
                                    "name": "Phường Bình Trị Đông"
                                },
                                {
                                    "id": "27448",
                                    "name": "Phường Bình Trị Đông A"
                                },
                                {
                                    "id": "27451",
                                    "name": "Phường Bình Trị Đông B"
                                },
                                {
                                    "id": "27454",
                                    "name": "Phường Tân Tạo"
                                },
                                {
                                    "id": "27457",
                                    "name": "Phường Tân Tạo A"
                                },
                                {
                                    "id": "27460",
                                    "name": "Phường  An Lạc"
                                },
                                {
                                    "id": "27463",
                                    "name": "Phường An Lạc A"
                                }
                        ]
                    },
                    {
                        "id": "778",
                        "name": "Quận 7",
                        "Wards": [
                                {
                                    "id": "27466",
                                    "name": "Phường Tân Thuận Đông"
                                },
                                {
                                    "id": "27469",
                                    "name": "Phường Tân Thuận Tây"
                                },
                                {
                                    "id": "27472",
                                    "name": "Phường Tân Kiểng"
                                },
                                {
                                    "id": "27475",
                                    "name": "Phường Tân Hưng"
                                },
                                {
                                    "id": "27478",
                                    "name": "Phường Bình Thuận"
                                },
                                {
                                    "id": "27481",
                                    "name": "Phường Tân Quy"
                                },
                                {
                                    "id": "27484",
                                    "name": "Phường Phú Thuận"
                                },
                                {
                                    "id": "27487",
                                    "name": "Phường Tân Phú"
                                },
                                {
                                    "id": "27490",
                                    "name": "Phường Tân Phong"
                                },
                                {
                                    "id": "27493",
                                    "name": "Phường Phú Mỹ"
                                }
                        ]
                    },
                    {
                        "id": "783",
                        "name": "Huyện Củ Chi",
                        "Wards": [
                                {
                                    "id": "27496",
                                    "name": "Thị trấn Củ Chi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27499",
                                    "name": "Xã Phú Mỹ Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27502",
                                    "name": "Xã An Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27505",
                                    "name": "Xã Trung Lập Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27508",
                                    "name": "Xã An Nhơn Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27511",
                                    "name": "Xã Nhuận Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27514",
                                    "name": "Xã Phạm Văn Cội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27517",
                                    "name": "Xã Phú Hòa Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27520",
                                    "name": "Xã Trung Lập Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27523",
                                    "name": "Xã Trung An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27526",
                                    "name": "Xã Phước Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27529",
                                    "name": "Xã Phước Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27532",
                                    "name": "Xã Tân An Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27535",
                                    "name": "Xã Phước Vĩnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27538",
                                    "name": "Xã Thái Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27541",
                                    "name": "Xã Tân Thạnh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27544",
                                    "name": "Xã Hòa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27547",
                                    "name": "Xã Tân Thạnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27550",
                                    "name": "Xã Bình Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27553",
                                    "name": "Xã Tân Phú Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27556",
                                    "name": "Xã Tân Thông Hội",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "784",
                        "name": "Huyện Hóc Môn",
                        "Wards": [
                                {
                                    "id": "27559",
                                    "name": "Thị trấn Hóc Môn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27562",
                                    "name": "Xã Tân Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27565",
                                    "name": "Xã Nhị Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27568",
                                    "name": "Xã Đông Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27571",
                                    "name": "Xã Tân Thới Nhì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27574",
                                    "name": "Xã Thới Tam Thôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27577",
                                    "name": "Xã Xuân Thới Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27580",
                                    "name": "Xã Tân Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27583",
                                    "name": "Xã Xuân Thới Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27586",
                                    "name": "Xã Trung Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27589",
                                    "name": "Xã Xuân Thới Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27592",
                                    "name": "Xã Bà Điểm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "785",
                        "name": "Huyện Bình Chánh",
                        "Wards": [
                                {
                                    "id": "27595",
                                    "name": "Thị trấn Tân Túc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27598",
                                    "name": "Xã Phạm Văn Hai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27601",
                                    "name": "Xã Vĩnh Lộc A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27604",
                                    "name": "Xã Vĩnh Lộc B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27607",
                                    "name": "Xã Bình Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27610",
                                    "name": "Xã Lê Minh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27613",
                                    "name": "Xã Tân Nhựt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27616",
                                    "name": "Xã Tân Kiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27619",
                                    "name": "Xã Bình Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27622",
                                    "name": "Xã Phong Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27625",
                                    "name": "Xã An Phú Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27628",
                                    "name": "Xã Hưng Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27631",
                                    "name": "Xã Đa Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27634",
                                    "name": "Xã Tân Quý Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27637",
                                    "name": "Xã Bình Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27640",
                                    "name": "Xã Quy Đức",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "786",
                        "name": "Huyện Nhà Bè",
                        "Wards": [
                                {
                                    "id": "27643",
                                    "name": "Thị trấn Nhà Bè",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27646",
                                    "name": "Xã Phước Kiển",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27649",
                                    "name": "Xã Phước Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27652",
                                    "name": "Xã Nhơn Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27655",
                                    "name": "Xã Phú Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27658",
                                    "name": "Xã Long Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27661",
                                    "name": "Xã Hiệp Phước",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "787",
                        "name": "Huyện Cần Giờ",
                        "Wards": [
                                {
                                    "id": "27664",
                                    "name": "Thị trấn Cần Thạnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27667",
                                    "name": "Xã Bình Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27670",
                                    "name": "Xã Tam Thôn Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27673",
                                    "name": "Xã An Thới Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27676",
                                    "name": "Xã Thạnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27679",
                                    "name": "Xã Long Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27682",
                                    "name": "Xã Lý Nhơn",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "80",
            "name": "Tỉnh Long An",
            "districts": [
                    {
                        "id": "794",
                        "name": "Thành phố Tân An",
                        "Wards": [
                                {
                                    "id": "27685",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "27688",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "27691",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "27692",
                                    "name": "Phường Tân Khánh"
                                },
                                {
                                    "id": "27694",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "27697",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "27698",
                                    "name": "Phường 7"
                                },
                                {
                                    "id": "27700",
                                    "name": "Phường 6"
                                },
                                {
                                    "id": "27703",
                                    "name": "Xã Hướng Thọ Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27706",
                                    "name": "Xã Nhơn Thạnh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27709",
                                    "name": "Xã Lợi Bình Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27712",
                                    "name": "Xã Bình Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27715",
                                    "name": "Phường Khánh Hậu"
                                },
                                {
                                    "id": "27718",
                                    "name": "Xã An Vĩnh Ngãi",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "795",
                        "name": "Thị xã Kiến Tường",
                        "Wards": [
                                {
                                    "id": "27787",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "27788",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "27790",
                                    "name": "Xã Thạnh Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27793",
                                    "name": "Xã Bình Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27799",
                                    "name": "Xã Bình Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27805",
                                    "name": "Xã Tuyên Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27806",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "27817",
                                    "name": "Xã Thạnh Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "796",
                        "name": "Huyện Tân Hưng",
                        "Wards": [
                                {
                                    "id": "27721",
                                    "name": "Thị trấn Tân Hưng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27724",
                                    "name": "Xã Hưng Hà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27727",
                                    "name": "Xã Hưng Điền B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27730",
                                    "name": "Xã Hưng Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27733",
                                    "name": "Xã Thạnh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27736",
                                    "name": "Xã Hưng Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27739",
                                    "name": "Xã Vĩnh Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27742",
                                    "name": "Xã Vĩnh Châu B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27745",
                                    "name": "Xã Vĩnh Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27748",
                                    "name": "Xã Vĩnh Đại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27751",
                                    "name": "Xã Vĩnh Châu A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27754",
                                    "name": "Xã Vĩnh Bửu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "797",
                        "name": "Huyện Vĩnh Hưng",
                        "Wards": [
                                {
                                    "id": "27757",
                                    "name": "Thị trấn Vĩnh Hưng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27760",
                                    "name": "Xã Hưng Điền A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27763",
                                    "name": "Xã Khánh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27766",
                                    "name": "Xã Thái Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27769",
                                    "name": "Xã Vĩnh Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27772",
                                    "name": "Xã Thái Bình Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27775",
                                    "name": "Xã Vĩnh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27778",
                                    "name": "Xã Vĩnh Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27781",
                                    "name": "Xã Tuyên Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27784",
                                    "name": "Xã Tuyên Bình Tây",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "798",
                        "name": "Huyện Mộc Hóa",
                        "Wards": [
                                {
                                    "id": "27796",
                                    "name": "Xã Bình Hòa Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27802",
                                    "name": "Xã Bình Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27808",
                                    "name": "Xã Bình Hòa Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27811",
                                    "name": "Xã Bình Hòa Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27814",
                                    "name": "Thị trấn Bình Phong Thạnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27820",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27823",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "799",
                        "name": "Huyện Tân Thạnh",
                        "Wards": [
                                {
                                    "id": "27826",
                                    "name": "Thị trấn Tân Thạnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27829",
                                    "name": "Xã Bắc Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27832",
                                    "name": "Xã Hậu Thạnh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27835",
                                    "name": "Xã Nhơn Hòa Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27838",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27841",
                                    "name": "Xã Hậu Thạnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27844",
                                    "name": "Xã Nhơn Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27847",
                                    "name": "Xã Kiến Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27850",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27853",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27856",
                                    "name": "Xã Tân Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27859",
                                    "name": "Xã Nhơn Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27862",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "800",
                        "name": "Huyện Thạnh Hóa",
                        "Wards": [
                                {
                                    "id": "27865",
                                    "name": "Thị trấn Thạnh Hóa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27868",
                                    "name": "Xã Tân Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27871",
                                    "name": "Xã Thuận Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27874",
                                    "name": "Xã Thạnh Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27877",
                                    "name": "Xã Thạnh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27880",
                                    "name": "Xã Thuận Nghĩa Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27883",
                                    "name": "Xã Thủy Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27886",
                                    "name": "Xã Thủy Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27889",
                                    "name": "Xã Tân Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27892",
                                    "name": "Xã Tân Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27895",
                                    "name": "Xã Thạnh An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "801",
                        "name": "Huyện Đức Huệ",
                        "Wards": [
                                {
                                    "id": "27898",
                                    "name": "Thị trấn Đông Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27901",
                                    "name": "Xã Mỹ Quý Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27904",
                                    "name": "Xã Mỹ Thạnh Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27907",
                                    "name": "Xã Mỹ Quý Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27910",
                                    "name": "Xã Mỹ Thạnh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27913",
                                    "name": "Xã Mỹ Thạnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27916",
                                    "name": "Xã Bình Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27919",
                                    "name": "Xã Bình Hòa Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27922",
                                    "name": "Xã Bình Hòa Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27925",
                                    "name": "Xã Bình Hòa Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27928",
                                    "name": "Xã Mỹ Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "802",
                        "name": "Huyện Đức Hòa",
                        "Wards": [
                                {
                                    "id": "27931",
                                    "name": "Thị trấn Hậu Nghĩa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27934",
                                    "name": "Thị trấn Hiệp Hòa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27937",
                                    "name": "Thị trấn Đức Hòa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27940",
                                    "name": "Xã Lộc Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27943",
                                    "name": "Xã An Ninh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27946",
                                    "name": "Xã An Ninh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27949",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27952",
                                    "name": "Xã Hiệp Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27955",
                                    "name": "Xã Đức Lập Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27958",
                                    "name": "Xã Đức Lập Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27961",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27964",
                                    "name": "Xã Mỹ Hạnh Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27967",
                                    "name": "Xã Đức Hòa Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27970",
                                    "name": "Xã Hòa Khánh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27973",
                                    "name": "Xã Hòa Khánh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27976",
                                    "name": "Xã Mỹ Hạnh Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27979",
                                    "name": "Xã Hòa Khánh Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27982",
                                    "name": "Xã Đức Hòa Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27985",
                                    "name": "Xã Đức Hòa Hạ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27988",
                                    "name": "Xã Hựu Thạnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "803",
                        "name": "Huyện Bến Lức",
                        "Wards": [
                                {
                                    "id": "27991",
                                    "name": "Thị trấn Bến Lức",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "27994",
                                    "name": "Xã Thạnh Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "27997",
                                    "name": "Xã Lương Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28000",
                                    "name": "Xã Thạnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28003",
                                    "name": "Xã Lương Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28006",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28009",
                                    "name": "Xã Tân Bửu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28012",
                                    "name": "Xã An Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28015",
                                    "name": "Xã Bình Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28018",
                                    "name": "Xã Mỹ Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28021",
                                    "name": "Xã Thanh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28024",
                                    "name": "Xã Long Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28027",
                                    "name": "Xã Thạnh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28030",
                                    "name": "Xã Phước Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28033",
                                    "name": "Xã Nhựt Chánh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "804",
                        "name": "Huyện Thủ Thừa",
                        "Wards": [
                                {
                                    "id": "28036",
                                    "name": "Thị trấn Thủ Thừa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28039",
                                    "name": "Xã Long Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28042",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28045",
                                    "name": "Xã Long Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28048",
                                    "name": "Xã Mỹ Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28051",
                                    "name": "Xã Mỹ Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28054",
                                    "name": "Xã Bình An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28057",
                                    "name": "Xã Nhị Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28060",
                                    "name": "Xã Mỹ An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28063",
                                    "name": "Xã Bình Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28066",
                                    "name": "Xã Mỹ Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28072",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "805",
                        "name": "Huyện Tân Trụ",
                        "Wards": [
                                {
                                    "id": "28075",
                                    "name": "Thị trấn Tân Trụ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28078",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28084",
                                    "name": "Xã Quê Mỹ Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28087",
                                    "name": "Xã Lạc Tấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28090",
                                    "name": "Xã Bình Trinh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28093",
                                    "name": "Xã Tân Phước Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28096",
                                    "name": "Xã Bình Lãng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28099",
                                    "name": "Xã Bình Tịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28102",
                                    "name": "Xã Đức Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28105",
                                    "name": "Xã Nhựt Ninh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "806",
                        "name": "Huyện Cần Đước",
                        "Wards": [
                                {
                                    "id": "28108",
                                    "name": "Thị trấn Cần Đước",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28111",
                                    "name": "Xã Long Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28114",
                                    "name": "Xã Long Khê",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28117",
                                    "name": "Xã Long Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28120",
                                    "name": "Xã Phước Vân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28123",
                                    "name": "Xã Long Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28126",
                                    "name": "Xã Long Cang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28129",
                                    "name": "Xã Long Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28132",
                                    "name": "Xã Tân Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28135",
                                    "name": "Xã Mỹ Lệ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28138",
                                    "name": "Xã Tân Lân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28141",
                                    "name": "Xã Phước Tuy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28144",
                                    "name": "Xã Long Hựu Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28147",
                                    "name": "Xã Tân Ân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28150",
                                    "name": "Xã Phước Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28153",
                                    "name": "Xã Long Hựu Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28156",
                                    "name": "Xã Tân Chánh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "807",
                        "name": "Huyện Cần Giuộc",
                        "Wards": [
                                {
                                    "id": "28159",
                                    "name": "Thị trấn Cần Giuộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28162",
                                    "name": "Xã Phước Lý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28165",
                                    "name": "Xã Long Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28168",
                                    "name": "Xã Long Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28174",
                                    "name": "Xã Phước Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28177",
                                    "name": "Xã Mỹ Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28180",
                                    "name": "Xã Phước Lại",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28183",
                                    "name": "Xã Phước Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28189",
                                    "name": "Xã Thuận Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28192",
                                    "name": "Xã Phước Vĩnh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28195",
                                    "name": "Xã Phước Vĩnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28198",
                                    "name": "Xã Long An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28201",
                                    "name": "Xã Long Phụng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28204",
                                    "name": "Xã Đông Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28207",
                                    "name": "Xã Tân Tập",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "808",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "28210",
                                    "name": "Thị trấn Tầm Vu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28213",
                                    "name": "Xã Bình Quới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28216",
                                    "name": "Xã Hòa Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28219",
                                    "name": "Xã Phú Ngãi Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28222",
                                    "name": "Xã Vĩnh Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28225",
                                    "name": "Xã Thuận Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28228",
                                    "name": "Xã Hiệp Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28231",
                                    "name": "Xã Phước Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28234",
                                    "name": "Xã Thanh Phú Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28237",
                                    "name": "Xã Dương Xuân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28240",
                                    "name": "Xã An Lục Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28243",
                                    "name": "Xã Long Trì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28246",
                                    "name": "Xã Thanh Vĩnh Đông",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "82",
            "name": "Tỉnh Tiền Giang",
            "districts": [
                    {
                        "id": "815",
                        "name": "Thành phố Mỹ Tho",
                        "Wards": [
                                {
                                    "id": "28249",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "28252",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "28255",
                                    "name": "Phường 7"
                                },
                                {
                                    "id": "28258",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "28261",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "28264",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "28267",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "28270",
                                    "name": "Phường 6"
                                },
                                {
                                    "id": "28273",
                                    "name": "Phường 9"
                                },
                                {
                                    "id": "28276",
                                    "name": "Phường 10"
                                },
                                {
                                    "id": "28279",
                                    "name": "Phường Tân Long"
                                },
                                {
                                    "id": "28282",
                                    "name": "Xã Đạo Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28285",
                                    "name": "Xã Trung An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28288",
                                    "name": "Xã Mỹ Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28291",
                                    "name": "Xã Tân Mỹ Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28567",
                                    "name": "Xã Phước Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28591",
                                    "name": "Xã Thới Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "816",
                        "name": "Thị xã Gò Công",
                        "Wards": [
                                {
                                    "id": "28294",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "28297",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "28300",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "28303",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "28306",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "28309",
                                    "name": "Xã Long Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28312",
                                    "name": "Xã Long Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28315",
                                    "name": "Xã Long Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28318",
                                    "name": "Xã Long Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28708",
                                    "name": "Xã Bình Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28717",
                                    "name": "Xã Bình Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28729",
                                    "name": "Xã Tân Trung",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "817",
                        "name": "Thị xã Cai Lậy",
                        "Wards": [
                                {
                                    "id": "28435",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "28436",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "28437",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "28439",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "28440",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "28447",
                                    "name": "Xã Mỹ Phước Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28450",
                                    "name": "Xã Mỹ Hạnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28453",
                                    "name": "Xã Mỹ Hạnh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28459",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28462",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28468",
                                    "name": "Xã Tân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28474",
                                    "name": "Phường Nhị Mỹ"
                                },
                                {
                                    "id": "28477",
                                    "name": "Xã Nhị Quý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28480",
                                    "name": "Xã Thanh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28483",
                                    "name": "Xã Phú Quý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28486",
                                    "name": "Xã Long Khánh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "818",
                        "name": "Huyện Tân Phước",
                        "Wards": [
                                {
                                    "id": "28321",
                                    "name": "Thị trấn Mỹ Phước",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28324",
                                    "name": "Xã Tân Hòa Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28327",
                                    "name": "Xã Thạnh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28330",
                                    "name": "Xã Thạnh Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28333",
                                    "name": "Xã Thạnh Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28336",
                                    "name": "Xã Phú Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28339",
                                    "name": "Xã Tân Hòa Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28342",
                                    "name": "Xã Hưng Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28345",
                                    "name": "Xã Tân Lập 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28348",
                                    "name": "Xã Tân Hòa Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28354",
                                    "name": "Xã Tân Lập 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28357",
                                    "name": "Xã Phước Lập",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "819",
                        "name": "Huyện Cái Bè",
                        "Wards": [
                                {
                                    "id": "28360",
                                    "name": "Thị trấn Cái Bè",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28363",
                                    "name": "Xã Hậu Mỹ Bắc B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28366",
                                    "name": "Xã Hậu Mỹ Bắc A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28369",
                                    "name": "Xã Mỹ Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28372",
                                    "name": "Xã Hậu Mỹ Trinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28375",
                                    "name": "Xã Hậu Mỹ Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28378",
                                    "name": "Xã Mỹ Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28381",
                                    "name": "Xã Mỹ Lợi B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28384",
                                    "name": "Xã Thiện Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28387",
                                    "name": "Xã Mỹ Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28390",
                                    "name": "Xã An Cư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28393",
                                    "name": "Xã Hậu Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28396",
                                    "name": "Xã Mỹ Lợi A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28399",
                                    "name": "Xã Hòa Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28402",
                                    "name": "Xã Thiện Trí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28405",
                                    "name": "Xã Mỹ Đức Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28408",
                                    "name": "Xã Mỹ Đức Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28411",
                                    "name": "Xã Đông Hòa Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28414",
                                    "name": "Xã An Thái Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28417",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28420",
                                    "name": "Xã Mỹ Lương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28423",
                                    "name": "Xã Tân Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28426",
                                    "name": "Xã An Thái Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28429",
                                    "name": "Xã An Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28432",
                                    "name": "Xã Hòa Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "820",
                        "name": "Huyện Cai Lậy",
                        "Wards": [
                                {
                                    "id": "28438",
                                    "name": "Xã Thạnh Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28441",
                                    "name": "Xã Mỹ Thành Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28444",
                                    "name": "Xã Phú Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28456",
                                    "name": "Xã Mỹ Thành Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28465",
                                    "name": "Xã Phú Nhuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28471",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28489",
                                    "name": "Xã Cẩm Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28492",
                                    "name": "Xã Phú An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28495",
                                    "name": "Xã Mỹ Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28498",
                                    "name": "Xã Long Tiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28501",
                                    "name": "Xã Hiệp Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28504",
                                    "name": "Xã Long Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28507",
                                    "name": "Xã Hội Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28510",
                                    "name": "Xã Tân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28513",
                                    "name": "Xã Tam Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28516",
                                    "name": "Xã Ngũ Hiệp",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "821",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "28519",
                                    "name": "Thị trấn Tân Hiệp",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28522",
                                    "name": "Xã Tân Hội Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28525",
                                    "name": "Xã Tân Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28528",
                                    "name": "Xã Tân Lý Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28531",
                                    "name": "Xã Tân Lý Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28534",
                                    "name": "Xã Thân Cửu Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28537",
                                    "name": "Xã Tam Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28540",
                                    "name": "Xã Điềm Hy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28543",
                                    "name": "Xã Nhị Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28546",
                                    "name": "Xã Dưỡng Điềm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28549",
                                    "name": "Xã Đông Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28552",
                                    "name": "Xã Long Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28555",
                                    "name": "Xã Hữu Đạo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28558",
                                    "name": "Xã Long An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28561",
                                    "name": "Xã Long Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28564",
                                    "name": "Xã Bình Trưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28570",
                                    "name": "Xã Thạnh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28573",
                                    "name": "Xã Bàn Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28576",
                                    "name": "Xã Vĩnh Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28579",
                                    "name": "Xã Bình Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28582",
                                    "name": "Xã Song Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28585",
                                    "name": "Xã Kim Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28588",
                                    "name": "Xã Phú Phong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "822",
                        "name": "Huyện Chợ Gạo",
                        "Wards": [
                                {
                                    "id": "28594",
                                    "name": "Thị trấn Chợ Gạo",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28597",
                                    "name": "Xã Trung Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28600",
                                    "name": "Xã Hòa Tịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28603",
                                    "name": "Xã Mỹ Tịnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28606",
                                    "name": "Xã Tân Bình Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28609",
                                    "name": "Xã Phú Kiết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28612",
                                    "name": "Xã Lương Hòa Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28615",
                                    "name": "Xã Thanh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28618",
                                    "name": "Xã Quơn Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28621",
                                    "name": "Xã Bình Phục Nhứt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28624",
                                    "name": "Xã Đăng Hưng Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28627",
                                    "name": "Xã Tân Thuận Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28630",
                                    "name": "Xã Song Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28633",
                                    "name": "Xã Bình Phan",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28636",
                                    "name": "Xã Long Bình Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28639",
                                    "name": "Xã An Thạnh Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28642",
                                    "name": "Xã Xuân Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28645",
                                    "name": "Xã Hòa Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28648",
                                    "name": "Xã Bình Ninh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "823",
                        "name": "Huyện Gò Công Tây",
                        "Wards": [
                                {
                                    "id": "28651",
                                    "name": "Thị trấn Vĩnh Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28654",
                                    "name": "Xã Đồng Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28657",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28660",
                                    "name": "Xã Đồng Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28663",
                                    "name": "Xã Thành Công",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28666",
                                    "name": "Xã Bình Nhì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28669",
                                    "name": "Xã Yên Luông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28672",
                                    "name": "Xã Thạnh Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28675",
                                    "name": "Xã Thạnh Nhựt",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28678",
                                    "name": "Xã Long Vĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28681",
                                    "name": "Xã Bình Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28684",
                                    "name": "Xã Vĩnh Hựu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28687",
                                    "name": "Xã Long Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "824",
                        "name": "Huyện Gò Công Đông",
                        "Wards": [
                                {
                                    "id": "28702",
                                    "name": "Thị trấn Tân Hòa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28705",
                                    "name": "Xã Tăng Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28711",
                                    "name": "Xã Tân Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28714",
                                    "name": "Xã Gia Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28720",
                                    "name": "Thị trấn Vàm Láng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28723",
                                    "name": "Xã Tân Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28726",
                                    "name": "Xã Kiểng Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28732",
                                    "name": "Xã Tân Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28735",
                                    "name": "Xã Bình Ân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28738",
                                    "name": "Xã Tân Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28741",
                                    "name": "Xã Bình Nghị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28744",
                                    "name": "Xã Phước Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28747",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "825",
                        "name": "Huyện Tân Phú Đông",
                        "Wards": [
                                {
                                    "id": "28690",
                                    "name": "Xã Tân Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28693",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28696",
                                    "name": "Xã Phú Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28699",
                                    "name": "Xã Tân Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28750",
                                    "name": "Xã Phú Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28753",
                                    "name": "Xã Phú Tân",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "83",
            "name": "Tỉnh Bến Tre",
            "districts": [
                    {
                        "id": "829",
                        "name": "Thành phố Bến Tre",
                        "Wards": [
                                {
                                    "id": "28756",
                                    "name": "Phường Phú Khương"
                                },
                                {
                                    "id": "28757",
                                    "name": "Phường Phú Tân"
                                },
                                {
                                    "id": "28759",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "28762",
                                    "name": "Phường 6"
                                },
                                {
                                    "id": "28765",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "28768",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "28777",
                                    "name": "Phường An Hội"
                                },
                                {
                                    "id": "28780",
                                    "name": "Phường 7"
                                },
                                {
                                    "id": "28783",
                                    "name": "Xã Sơn Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28786",
                                    "name": "Xã Phú Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28789",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28792",
                                    "name": "Xã Mỹ Thạnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28795",
                                    "name": "Xã Nhơn Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28798",
                                    "name": "Xã Phú Nhuận",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "831",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "28801",
                                    "name": "Thị trấn Châu Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28804",
                                    "name": "Xã Tân Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28807",
                                    "name": "Xã Qưới Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28810",
                                    "name": "Xã An Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28813",
                                    "name": "Xã Giao Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28819",
                                    "name": "Xã Phú Túc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28822",
                                    "name": "Xã Phú Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28825",
                                    "name": "Xã Phú An Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28828",
                                    "name": "Xã An Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28831",
                                    "name": "Xã Tam Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28834",
                                    "name": "Xã Thành Triệu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28837",
                                    "name": "Xã Tường Đa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28840",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28843",
                                    "name": "Xã Quới Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28846",
                                    "name": "Xã Phước Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28849",
                                    "name": "Xã An Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28852",
                                    "name": "Xã Tiên Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28855",
                                    "name": "Xã An Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28858",
                                    "name": "Xã Hữu Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28861",
                                    "name": "Xã Tiên Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28864",
                                    "name": "Xã Sơn Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "832",
                        "name": "Huyện Chợ Lách",
                        "Wards": [
                                {
                                    "id": "28870",
                                    "name": "Thị trấn Chợ Lách",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28873",
                                    "name": "Xã Phú Phụng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28876",
                                    "name": "Xã Sơn Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28879",
                                    "name": "Xã Vĩnh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28882",
                                    "name": "Xã Hòa Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28885",
                                    "name": "Xã Long Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28888",
                                    "name": "Xã Phú Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28891",
                                    "name": "Xã Tân Thiềng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28894",
                                    "name": "Xã Vĩnh Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28897",
                                    "name": "Xã Vĩnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28900",
                                    "name": "Xã Hưng Khánh Trung B",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "833",
                        "name": "Huyện Mỏ Cày Nam",
                        "Wards": [
                                {
                                    "id": "28903",
                                    "name": "Thị trấn Mỏ Cày",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28930",
                                    "name": "Xã Định Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28939",
                                    "name": "Xã Đa Phước Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28940",
                                    "name": "Xã Tân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28942",
                                    "name": "Xã Phước Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28945",
                                    "name": "Xã Bình Khánh ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28951",
                                    "name": "Xã An Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28957",
                                    "name": "Xã An Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28960",
                                    "name": "Xã Thành Thới B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28963",
                                    "name": "Xã Tân Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28966",
                                    "name": "Xã An Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28969",
                                    "name": "Xã Thành Thới A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28972",
                                    "name": "Xã Minh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28975",
                                    "name": "Xã Ngãi Đăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28978",
                                    "name": "Xã Cẩm Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28981",
                                    "name": "Xã Hương Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "834",
                        "name": "Huyện Giồng Trôm",
                        "Wards": [
                                {
                                    "id": "28984",
                                    "name": "Thị trấn Giồng Trôm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "28987",
                                    "name": "Xã Phong Nẫm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28993",
                                    "name": "Xã Mỹ Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28996",
                                    "name": "Xã Châu Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28999",
                                    "name": "Xã Lương Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29002",
                                    "name": "Xã Lương Quới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29005",
                                    "name": "Xã Lương Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29008",
                                    "name": "Xã Châu Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29011",
                                    "name": "Xã Thuận Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29014",
                                    "name": "Xã Sơn Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29017",
                                    "name": "Xã Bình Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29020",
                                    "name": "Xã Phước Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29023",
                                    "name": "Xã Hưng Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29026",
                                    "name": "Xã Long Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29029",
                                    "name": "Xã Tân Hào",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29032",
                                    "name": "Xã Bình Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29035",
                                    "name": "Xã Tân Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29038",
                                    "name": "Xã Tân Lợi Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29041",
                                    "name": "Xã Thạnh Phú Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29044",
                                    "name": "Xã Hưng Nhượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29047",
                                    "name": "Xã Hưng Lễ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "835",
                        "name": "Huyện Bình Đại",
                        "Wards": [
                                {
                                    "id": "29050",
                                    "name": "Thị trấn Bình Đại",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29053",
                                    "name": "Xã Tam Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29056",
                                    "name": "Xã Long Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29059",
                                    "name": "Xã Long Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29062",
                                    "name": "Xã Phú Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29065",
                                    "name": "Xã Vang Quới Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29068",
                                    "name": "Xã Vang Quới Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29071",
                                    "name": "Xã Châu Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29074",
                                    "name": "Xã Phú Vang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29077",
                                    "name": "Xã Lộc Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29080",
                                    "name": "Xã Định Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29083",
                                    "name": "Xã Thới Lai",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29086",
                                    "name": "Xã Bình Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29089",
                                    "name": "Xã Phú Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29092",
                                    "name": "Xã Bình Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29095",
                                    "name": "Xã Thạnh Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29098",
                                    "name": "Xã Đại Hòa Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29101",
                                    "name": "Xã Thừa Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29104",
                                    "name": "Xã Thạnh Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29107",
                                    "name": "Xã Thới Thuận",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "836",
                        "name": "Huyện Ba Tri",
                        "Wards": [
                                {
                                    "id": "29110",
                                    "name": "Thị trấn Ba Tri",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29113",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29116",
                                    "name": "Xã Mỹ Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29119",
                                    "name": "Xã Tân Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29122",
                                    "name": "Xã Mỹ Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29125",
                                    "name": "Xã Bảo Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29128",
                                    "name": "Xã An Phú Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29131",
                                    "name": "Xã Mỹ Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29134",
                                    "name": "Xã Mỹ Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29137",
                                    "name": "Xã Phước Ngãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29143",
                                    "name": "Xã An Ngãi Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29146",
                                    "name": "Xã Phú Lễ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29149",
                                    "name": "Xã An Bình Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29152",
                                    "name": "Xã Bảo Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29155",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29158",
                                    "name": "Xã An Ngãi Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29161",
                                    "name": "Xã An Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29164",
                                    "name": "Xã Vĩnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29167",
                                    "name": "Xã Tân Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29170",
                                    "name": "Xã Vĩnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29173",
                                    "name": "Xã An Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29176",
                                    "name": "Xã An Hòa Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29179",
                                    "name": "Xã An Thủy",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "837",
                        "name": "Huyện Thạnh Phú",
                        "Wards": [
                                {
                                    "id": "29182",
                                    "name": "Thị trấn Thạnh Phú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29185",
                                    "name": "Xã Phú Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29188",
                                    "name": "Xã Đại Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29191",
                                    "name": "Xã Quới Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29194",
                                    "name": "Xã Tân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29197",
                                    "name": "Xã Mỹ Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29200",
                                    "name": "Xã An Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29203",
                                    "name": "Xã Thới Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29206",
                                    "name": "Xã Hòa Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29209",
                                    "name": "Xã An Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29212",
                                    "name": "Xã Bình Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29215",
                                    "name": "Xã An Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29218",
                                    "name": "Xã An Quy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29221",
                                    "name": "Xã Thạnh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29224",
                                    "name": "Xã An Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29227",
                                    "name": "Xã Giao Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29230",
                                    "name": "Xã Thạnh Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29233",
                                    "name": "Xã Mỹ An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "838",
                        "name": "Huyện Mỏ Cày Bắc",
                        "Wards": [
                                {
                                    "id": "28889",
                                    "name": "Xã Phú Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28901",
                                    "name": "Xã Hưng Khánh Trung A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28906",
                                    "name": "Xã Thanh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28909",
                                    "name": "Xã Thạnh Ngãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28912",
                                    "name": "Xã Tân Phú Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28915",
                                    "name": "Xã Phước Mỹ Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28918",
                                    "name": "Xã Tân Thành Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28921",
                                    "name": "Xã Thành An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28924",
                                    "name": "Xã Hòa Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28927",
                                    "name": "Xã Tân Thanh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28933",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28936",
                                    "name": "Xã Nhuận Phú Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "28948",
                                    "name": "Xã Khánh Thạnh Tân",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "84",
            "name": "Tỉnh Trà Vinh",
            "districts": [
                    {
                        "id": "842",
                        "name": "Thành phố Trà Vinh",
                        "Wards": [
                                {
                                    "id": "29236",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "29239",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "29242",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "29245",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "29248",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "29251",
                                    "name": "Phường 6"
                                },
                                {
                                    "id": "29254",
                                    "name": "Phường 7"
                                },
                                {
                                    "id": "29257",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "29260",
                                    "name": "Phường 9"
                                },
                                {
                                    "id": "29263",
                                    "name": "Xã Long Đức",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "844",
                        "name": "Huyện Càng Long",
                        "Wards": [
                                {
                                    "id": "29266",
                                    "name": "Thị trấn Càng Long",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29269",
                                    "name": "Xã Mỹ Cẩm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29272",
                                    "name": "Xã An Trường A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29275",
                                    "name": "Xã An Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29278",
                                    "name": "Xã Huyền Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29281",
                                    "name": "Xã Tân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29284",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29287",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29290",
                                    "name": "Xã Phương Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29293",
                                    "name": "Xã Đại Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29296",
                                    "name": "Xã Đại Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29299",
                                    "name": "Xã Nhị Long Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29302",
                                    "name": "Xã Nhị Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29305",
                                    "name": "Xã Đức Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "845",
                        "name": "Huyện Cầu Kè",
                        "Wards": [
                                {
                                    "id": "29308",
                                    "name": "Thị trấn Cầu Kè",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29311",
                                    "name": "Xã Hòa Ân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29314",
                                    "name": "Xã Châu Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29317",
                                    "name": "Xã An Phú Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29320",
                                    "name": "Xã Hoà Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29323",
                                    "name": "Xã Ninh Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29326",
                                    "name": "Xã Phong Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29329",
                                    "name": "Xã Phong Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29332",
                                    "name": "Xã Tam Ngãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29335",
                                    "name": "Xã Thông Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29338",
                                    "name": "Xã Thạnh Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "846",
                        "name": "Huyện Tiểu Cần",
                        "Wards": [
                                {
                                    "id": "29341",
                                    "name": "Thị trấn Tiểu Cần",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29344",
                                    "name": "Thị trấn Cầu Quan",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29347",
                                    "name": "Xã Phú Cần",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29350",
                                    "name": "Xã Hiếu Tử",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29353",
                                    "name": "Xã Hiếu Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29356",
                                    "name": "Xã Long Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29359",
                                    "name": "Xã Hùng Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29362",
                                    "name": "Xã Tân Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29365",
                                    "name": "Xã Tập Ngãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29368",
                                    "name": "Xã Ngãi Hùng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29371",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "847",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "29374",
                                    "name": "Thị trấn Châu Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29377",
                                    "name": "Xã Đa Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29380",
                                    "name": "Xã Mỹ Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29383",
                                    "name": "Xã Thanh Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29386",
                                    "name": "Xã Lương Hoà A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29389",
                                    "name": "Xã Lương Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29392",
                                    "name": "Xã Song Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29395",
                                    "name": "Xã Nguyệt Hóa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29398",
                                    "name": "Xã Hòa Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29401",
                                    "name": "Xã Hòa Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29404",
                                    "name": "Xã Phước Hảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29407",
                                    "name": "Xã Hưng Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29410",
                                    "name": "Xã Hòa Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29413",
                                    "name": "Xã Long Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "848",
                        "name": "Huyện Cầu Ngang",
                        "Wards": [
                                {
                                    "id": "29416",
                                    "name": "Thị trấn Cầu Ngang",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29419",
                                    "name": "Thị trấn Mỹ Long",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29422",
                                    "name": "Xã Mỹ Long Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29425",
                                    "name": "Xã Mỹ Long Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29428",
                                    "name": "Xã Mỹ Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29431",
                                    "name": "Xã Vĩnh Kim",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29434",
                                    "name": "Xã Kim Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29437",
                                    "name": "Xã Hiệp Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29440",
                                    "name": "Xã Thuận Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29443",
                                    "name": "Xã Long Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29446",
                                    "name": "Xã Nhị Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29449",
                                    "name": "Xã Trường Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29452",
                                    "name": "Xã Hiệp Mỹ Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29455",
                                    "name": "Xã Hiệp Mỹ Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29458",
                                    "name": "Xã Thạnh Hòa Sơn",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "849",
                        "name": "Huyện Trà Cú",
                        "Wards": [
                                {
                                    "id": "29461",
                                    "name": "Thị trấn Trà Cú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29462",
                                    "name": "Thị trấn Định An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29464",
                                    "name": "Xã Phước Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29467",
                                    "name": "Xã Tập Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29470",
                                    "name": "Xã Tân Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29473",
                                    "name": "Xã An Quảng Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29476",
                                    "name": "Xã Lưu Nghiệp Anh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29479",
                                    "name": "Xã Ngãi Xuyên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29482",
                                    "name": "Xã Kim Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29485",
                                    "name": "Xã Thanh Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29488",
                                    "name": "Xã Hàm Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29489",
                                    "name": "Xã Hàm Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29491",
                                    "name": "Xã Đại An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29494",
                                    "name": "Xã Định An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29503",
                                    "name": "Xã Ngọc Biên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29506",
                                    "name": "Xã Long Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29509",
                                    "name": "Xã Tân Hiệp",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "850",
                        "name": "Huyện Duyên Hải",
                        "Wards": [
                                {
                                    "id": "29497",
                                    "name": "Xã Đôn Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29500",
                                    "name": "Xã Đôn Châu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29513",
                                    "name": "Thị trấn Long Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29521",
                                    "name": "Xã Long Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29530",
                                    "name": "Xã Ngũ Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29533",
                                    "name": "Xã Long Vĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29536",
                                    "name": "Xã Đông Hải",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "851",
                        "name": "Thị xã Duyên Hải",
                        "Wards": [
                                {
                                    "id": "29512",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "29515",
                                    "name": "Xã Long Toàn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29516",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "29518",
                                    "name": "Xã Long Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29524",
                                    "name": "Xã Dân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29527",
                                    "name": "Xã Trường Long Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29539",
                                    "name": "Xã Hiệp Thạnh",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "86",
            "name": "Tỉnh Vĩnh Long",
            "districts": [
                    {
                        "id": "855",
                        "name": "Thành phố Vĩnh Long",
                        "Wards": [
                                {
                                    "id": "29542",
                                    "name": "Phường 9"
                                },
                                {
                                    "id": "29545",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "29548",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "29551",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "29554",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "29557",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "29560",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "29563",
                                    "name": "Phường Tân Ngãi"
                                },
                                {
                                    "id": "29566",
                                    "name": "Phường Tân Hòa"
                                },
                                {
                                    "id": "29569",
                                    "name": "Phường Tân Hội"
                                },
                                {
                                    "id": "29572",
                                    "name": "Phường Trường An"
                                }
                        ]
                    },
                    {
                        "id": "857",
                        "name": "Huyện Long Hồ",
                        "Wards": [
                                {
                                    "id": "29575",
                                    "name": "Thị trấn Long Hồ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29578",
                                    "name": "Xã Đồng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29581",
                                    "name": "Xã Bình Hòa Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29584",
                                    "name": "Xã Hòa Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29587",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29590",
                                    "name": "Xã Thanh Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29593",
                                    "name": "Xã Tân Hạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29596",
                                    "name": "Xã Phước Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29599",
                                    "name": "Xã Long Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29602",
                                    "name": "Xã Phú Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29605",
                                    "name": "Xã Lộc Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29608",
                                    "name": "Xã Long An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29611",
                                    "name": "Xã Phú Quới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29614",
                                    "name": "Xã Thạnh Quới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29617",
                                    "name": "Xã Hòa Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "858",
                        "name": "Huyện Mang Thít",
                        "Wards": [
                                {
                                    "id": "29623",
                                    "name": "Xã Mỹ An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29626",
                                    "name": "Xã Mỹ Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29629",
                                    "name": "Xã An Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29632",
                                    "name": "Xã Nhơn Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29635",
                                    "name": "Xã Long Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29638",
                                    "name": "Xã Hòa Tịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29641",
                                    "name": "Thị trấn Cái Nhum",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29644",
                                    "name": "Xã Bình Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29647",
                                    "name": "Xã Chánh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29650",
                                    "name": "Xã Tân An Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29653",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29656",
                                    "name": "Xã Tân Long Hội",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "859",
                        "name": "Huyện  Vũng Liêm",
                        "Wards": [
                                {
                                    "id": "29659",
                                    "name": "Thị trấn Vũng Liêm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29662",
                                    "name": "Xã Tân Quới Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29665",
                                    "name": "Xã Quới Thiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29668",
                                    "name": "Xã Quới An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29671",
                                    "name": "Xã Trung Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29674",
                                    "name": "Xã Tân An Luông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29677",
                                    "name": "Xã Thanh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29680",
                                    "name": "Xã Trung Thành Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29683",
                                    "name": "Xã Trung Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29686",
                                    "name": "Xã Hiếu Phụng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29689",
                                    "name": "Xã Trung Thành Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29692",
                                    "name": "Xã Trung Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29695",
                                    "name": "Xã Trung Hiếu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29698",
                                    "name": "Xã Trung Ngãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29701",
                                    "name": "Xã Hiếu Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29704",
                                    "name": "Xã Trung Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29707",
                                    "name": "Xã Trung An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29710",
                                    "name": "Xã Hiếu Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29713",
                                    "name": "Xã Hiếu Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29716",
                                    "name": "Xã Hiếu Nghĩa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "860",
                        "name": "Huyện Tam Bình",
                        "Wards": [
                                {
                                    "id": "29719",
                                    "name": "Thị trấn Tam Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29722",
                                    "name": "Xã Tân Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29725",
                                    "name": "Xã Phú Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29728",
                                    "name": "Xã Hậu Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29731",
                                    "name": "Xã Hòa Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29734",
                                    "name": "Xã Hoà Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29737",
                                    "name": "Xã Phú Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29740",
                                    "name": "Xã Song Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29743",
                                    "name": "Xã Hòa Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29746",
                                    "name": "Xã Mỹ Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29749",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29752",
                                    "name": "Xã Long Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29755",
                                    "name": "Xã Mỹ Thạnh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29758",
                                    "name": "Xã Tường Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29761",
                                    "name": "Xã Loan Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29764",
                                    "name": "Xã Ngãi Tứ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29767",
                                    "name": "Xã Bình Ninh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "861",
                        "name": "Thị xã Bình Minh",
                        "Wards": [
                                {
                                    "id": "29770",
                                    "name": "Phường Cái Vồn"
                                },
                                {
                                    "id": "29771",
                                    "name": "Phường Thành Phước"
                                },
                                {
                                    "id": "29806",
                                    "name": "Xã Thuận An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29809",
                                    "name": "Xã Đông Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29812",
                                    "name": "Xã Đông Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29813",
                                    "name": "Phường Đông Thuận"
                                },
                                {
                                    "id": "29815",
                                    "name": "Xã Mỹ Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29818",
                                    "name": "Xã Đông Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "862",
                        "name": "Huyện Trà Ôn",
                        "Wards": [
                                {
                                    "id": "29821",
                                    "name": "Thị trấn Trà Ôn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29824",
                                    "name": "Xã Xuân Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29827",
                                    "name": "Xã Nhơn Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29830",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29833",
                                    "name": "Xã Thới Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29836",
                                    "name": "Xã Trà Côn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29839",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29842",
                                    "name": "Xã Hựu Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29845",
                                    "name": "Xã Vĩnh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29848",
                                    "name": "Xã Thuận Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29851",
                                    "name": "Xã Phú Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29854",
                                    "name": "Xã Thiện Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29857",
                                    "name": "Xã Lục Sỹ Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29860",
                                    "name": "Xã Tích Thiện",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "863",
                        "name": "Huyện Bình Tân",
                        "Wards": [
                                {
                                    "id": "29773",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29776",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29779",
                                    "name": "Xã Thành Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29782",
                                    "name": "Xã Tân An Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29785",
                                    "name": "Xã Tân Lược",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29788",
                                    "name": "Xã Nguyễn Văn Thảnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29791",
                                    "name": "Xã Thành Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29794",
                                    "name": "Xã Mỹ Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29797",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29800",
                                    "name": "Thị trấn Tân Quới",
                                    "Level": "Thị trấn"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "87",
            "name": "Tỉnh Đồng Tháp",
            "districts": [
                    {
                        "id": "866",
                        "name": "Thành phố Cao Lãnh",
                        "Wards": [
                                {
                                    "id": "29863",
                                    "name": "Phường 11"
                                },
                                {
                                    "id": "29866",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "29869",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "29872",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "29875",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "29878",
                                    "name": "Phường 6"
                                },
                                {
                                    "id": "29881",
                                    "name": "Xã Mỹ Ngãi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29884",
                                    "name": "Xã Mỹ Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29887",
                                    "name": "Xã Mỹ Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29888",
                                    "name": "Phường Mỹ Phú"
                                },
                                {
                                    "id": "29890",
                                    "name": "Xã Tân Thuận Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29892",
                                    "name": "Phường Hoà Thuận"
                                },
                                {
                                    "id": "29893",
                                    "name": "Xã Hòa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29896",
                                    "name": "Xã Tân Thuận Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29899",
                                    "name": "Xã Tịnh Thới",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "867",
                        "name": "Thành phố Sa Đéc",
                        "Wards": [
                                {
                                    "id": "29902",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "29905",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "29908",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "29911",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "29914",
                                    "name": "Xã Tân Khánh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29917",
                                    "name": "Phường Tân Quy Đông"
                                },
                                {
                                    "id": "29919",
                                    "name": "Phường An Hoà"
                                },
                                {
                                    "id": "29920",
                                    "name": "Xã Tân Quy Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29923",
                                    "name": "Xã Tân Phú Đông",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "868",
                        "name": "Thành phố Hồng Ngự",
                        "Wards": [
                                {
                                    "id": "29954",
                                    "name": "Phường An Lộc"
                                },
                                {
                                    "id": "29955",
                                    "name": "Phường An Thạnh"
                                },
                                {
                                    "id": "29959",
                                    "name": "Xã Bình Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29965",
                                    "name": "Xã Tân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29978",
                                    "name": "Phường An Lạc"
                                },
                                {
                                    "id": "29986",
                                    "name": "Phường An Bình B"
                                },
                                {
                                    "id": "29989",
                                    "name": "Phường An Bình A"
                                }
                        ]
                    },
                    {
                        "id": "869",
                        "name": "Huyện Tân Hồng",
                        "Wards": [
                                {
                                    "id": "29926",
                                    "name": "Thị trấn Sa Rài",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29929",
                                    "name": "Xã Tân Hộ Cơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29932",
                                    "name": "Xã Thông Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29935",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29938",
                                    "name": "Xã Tân Thành A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29941",
                                    "name": "Xã Tân Thành B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29944",
                                    "name": "Xã Tân Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29947",
                                    "name": "Xã Tân Công Chí",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29950",
                                    "name": "Xã An Phước",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "870",
                        "name": "Huyện Hồng Ngự",
                        "Wards": [
                                {
                                    "id": "29956",
                                    "name": "Xã Thường Phước 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29962",
                                    "name": "Xã Thường Thới Hậu A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29971",
                                    "name": "Thị trấn Thường Thới Tiền",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "29974",
                                    "name": "Xã Thường Phước 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29977",
                                    "name": "Xã Thường Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29980",
                                    "name": "Xã Long Khánh A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29983",
                                    "name": "Xã Long Khánh B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29992",
                                    "name": "Xã Long Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29995",
                                    "name": "Xã Phú Thuận B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "29998",
                                    "name": "Xã Phú Thuận A",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "871",
                        "name": "Huyện Tam Nông",
                        "Wards": [
                                {
                                    "id": "30001",
                                    "name": "Thị trấn Tràm Chim",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30004",
                                    "name": "Xã Hoà Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30007",
                                    "name": "Xã Tân Công Sính",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30010",
                                    "name": "Xã Phú Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30013",
                                    "name": "Xã Phú Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30016",
                                    "name": "Xã Phú Thành B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30019",
                                    "name": "Xã An Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30022",
                                    "name": "Xã An Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30025",
                                    "name": "Xã Phú Cường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30028",
                                    "name": "Xã Phú Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30031",
                                    "name": "Xã Phú Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30034",
                                    "name": "Xã Phú Thành A",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "872",
                        "name": "Huyện Tháp Mười",
                        "Wards": [
                                {
                                    "id": "30037",
                                    "name": "Thị trấn Mỹ An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30040",
                                    "name": "Xã Thạnh Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30043",
                                    "name": "Xã Hưng Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30046",
                                    "name": "Xã Trường Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30049",
                                    "name": "Xã Tân Kiều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30052",
                                    "name": "Xã Mỹ Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30055",
                                    "name": "Xã Mỹ Quý",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30058",
                                    "name": "Xã Mỹ Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30061",
                                    "name": "Xã Đốc Binh Kiều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30064",
                                    "name": "Xã Mỹ An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30067",
                                    "name": "Xã Phú Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30070",
                                    "name": "Xã Láng Biển",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30073",
                                    "name": "Xã Thanh Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "873",
                        "name": "Huyện Cao Lãnh",
                        "Wards": [
                                {
                                    "id": "30076",
                                    "name": "Thị trấn Mỹ Thọ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30079",
                                    "name": "Xã Gáo Giồng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30082",
                                    "name": "Xã Phương Thịnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30085",
                                    "name": "Xã Ba Sao",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30088",
                                    "name": "Xã Phong Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30091",
                                    "name": "Xã Tân Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30094",
                                    "name": "Xã Phương Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30097",
                                    "name": "Xã Nhị Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30100",
                                    "name": "Xã Mỹ Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30103",
                                    "name": "Xã Tân Hội Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30106",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30109",
                                    "name": "Xã Mỹ Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30112",
                                    "name": "Xã Mỹ Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30115",
                                    "name": "Xã Mỹ Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30118",
                                    "name": "Xã Bình Hàng Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30121",
                                    "name": "Xã Mỹ Xương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30124",
                                    "name": "Xã Bình Hàng Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30127",
                                    "name": "Xã Bình Thạnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "874",
                        "name": "Huyện Thanh Bình",
                        "Wards": [
                                {
                                    "id": "30130",
                                    "name": "Thị trấn Thanh Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30133",
                                    "name": "Xã Tân Quới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30136",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30139",
                                    "name": "Xã An Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30142",
                                    "name": "Xã Phú Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30145",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30148",
                                    "name": "Xã Bình Tấn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30151",
                                    "name": "Xã Tân Huề",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30154",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30157",
                                    "name": "Xã Tân Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30160",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30163",
                                    "name": "Xã Bình Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30166",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "875",
                        "name": "Huyện Lấp Vò",
                        "Wards": [
                                {
                                    "id": "30169",
                                    "name": "Thị trấn Lấp Vò",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30172",
                                    "name": "Xã Mỹ An Hưng A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30175",
                                    "name": "Xã Tân Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30178",
                                    "name": "Xã Mỹ An Hưng B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30181",
                                    "name": "Xã Tân  Khánh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30184",
                                    "name": "Xã Long Hưng A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30187",
                                    "name": "Xã Vĩnh Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30190",
                                    "name": "Xã Long Hưng B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30193",
                                    "name": "Xã Bình Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30196",
                                    "name": "Xã Định An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30199",
                                    "name": "Xã Định Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30202",
                                    "name": "Xã Hội An Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30205",
                                    "name": "Xã Bình Thạnh Trung",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "876",
                        "name": "Huyện Lai Vung",
                        "Wards": [
                                {
                                    "id": "30208",
                                    "name": "Thị trấn Lai Vung",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30211",
                                    "name": "Xã Tân Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30214",
                                    "name": "Xã Hòa Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30217",
                                    "name": "Xã Long Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30220",
                                    "name": "Xã Tân Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30223",
                                    "name": "Xã Hòa Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30226",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30229",
                                    "name": "Xã Long Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30232",
                                    "name": "Xã Vĩnh Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30235",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30238",
                                    "name": "Xã Định Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30241",
                                    "name": "Xã Phong Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "877",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "30244",
                                    "name": "Thị trấn Cái Tàu Hạ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30247",
                                    "name": "Xã An Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30250",
                                    "name": "Xã An Nhơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30253",
                                    "name": "Xã Tân Nhuận Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30256",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30259",
                                    "name": "Xã Tân Phú Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30262",
                                    "name": "Xã Phú Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30265",
                                    "name": "Xã An Phú Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30268",
                                    "name": "Xã Phú Hựu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30271",
                                    "name": "Xã An Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30274",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30277",
                                    "name": "Xã Hòa Tân",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "89",
            "name": "Tỉnh An Giang",
            "districts": [
                    {
                        "id": "883",
                        "name": "Thành phố Long Xuyên",
                        "Wards": [
                                {
                                    "id": "30280",
                                    "name": "Phường Mỹ Bình"
                                },
                                {
                                    "id": "30283",
                                    "name": "Phường Mỹ Long"
                                },
                                {
                                    "id": "30285",
                                    "name": "Phường Đông Xuyên"
                                },
                                {
                                    "id": "30286",
                                    "name": "Phường Mỹ Xuyên"
                                },
                                {
                                    "id": "30289",
                                    "name": "Phường Bình Đức"
                                },
                                {
                                    "id": "30292",
                                    "name": "Phường Bình Khánh"
                                },
                                {
                                    "id": "30295",
                                    "name": "Phường Mỹ Phước"
                                },
                                {
                                    "id": "30298",
                                    "name": "Phường Mỹ Quý"
                                },
                                {
                                    "id": "30301",
                                    "name": "Phường Mỹ Thới"
                                },
                                {
                                    "id": "30304",
                                    "name": "Phường Mỹ Thạnh"
                                },
                                {
                                    "id": "30307",
                                    "name": "Phường Mỹ Hòa"
                                },
                                {
                                    "id": "30310",
                                    "name": "Xã Mỹ Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30313",
                                    "name": "Xã Mỹ Hoà Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "884",
                        "name": "Thành phố Châu Đốc",
                        "Wards": [
                                {
                                    "id": "30316",
                                    "name": "Phường Châu Phú B"
                                },
                                {
                                    "id": "30319",
                                    "name": "Phường Châu Phú A"
                                },
                                {
                                    "id": "30322",
                                    "name": "Phường Vĩnh Mỹ"
                                },
                                {
                                    "id": "30325",
                                    "name": "Phường Núi Sam"
                                },
                                {
                                    "id": "30328",
                                    "name": "Phường Vĩnh Ngươn"
                                },
                                {
                                    "id": "30331",
                                    "name": "Xã Vĩnh Tế",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30334",
                                    "name": "Xã Vĩnh Châu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "886",
                        "name": "Huyện An Phú",
                        "Wards": [
                                {
                                    "id": "30337",
                                    "name": "Thị trấn An Phú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30340",
                                    "name": "Xã Khánh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30341",
                                    "name": "Thị Trấn Long Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30343",
                                    "name": "Xã Khánh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30346",
                                    "name": "Xã Quốc Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30349",
                                    "name": "Xã Nhơn Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30352",
                                    "name": "Xã Phú Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30355",
                                    "name": "Xã Phú Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30358",
                                    "name": "Xã Phước Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30361",
                                    "name": "Xã Vĩnh Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30364",
                                    "name": "Xã Vĩnh Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30367",
                                    "name": "Xã Vĩnh Trường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30370",
                                    "name": "Xã Vĩnh Hội Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30373",
                                    "name": "Xã Đa Phước",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "887",
                        "name": "Thị xã Tân Châu",
                        "Wards": [
                                {
                                    "id": "30376",
                                    "name": "Phường Long Thạnh"
                                },
                                {
                                    "id": "30377",
                                    "name": "Phường Long Hưng"
                                },
                                {
                                    "id": "30378",
                                    "name": "Phường Long Châu"
                                },
                                {
                                    "id": "30379",
                                    "name": "Xã Phú Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30382",
                                    "name": "Xã Vĩnh Xương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30385",
                                    "name": "Xã Vĩnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30387",
                                    "name": "Xã Tân Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30388",
                                    "name": "Xã Tân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30391",
                                    "name": "Xã Long An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30394",
                                    "name": "Phường Long Phú"
                                },
                                {
                                    "id": "30397",
                                    "name": "Xã Châu Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30400",
                                    "name": "Xã Phú Vĩnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30403",
                                    "name": "Xã Lê Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30412",
                                    "name": "Phường Long Sơn"
                                }
                        ]
                    },
                    {
                        "id": "888",
                        "name": "Huyện Phú Tân",
                        "Wards": [
                                {
                                    "id": "30406",
                                    "name": "Thị trấn Phú Mỹ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30409",
                                    "name": "Thị trấn Chợ Vàm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30415",
                                    "name": "Xã Long Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30418",
                                    "name": "Xã Phú Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30421",
                                    "name": "Xã Phú Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30424",
                                    "name": "Xã Phú Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30427",
                                    "name": "Xã Phú Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30430",
                                    "name": "Xã Hoà Lạc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30433",
                                    "name": "Xã Phú Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30436",
                                    "name": "Xã Phú An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30439",
                                    "name": "Xã Phú Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30442",
                                    "name": "Xã Hiệp Xương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30445",
                                    "name": "Xã Phú Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30448",
                                    "name": "Xã Phú Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30451",
                                    "name": "Xã Phú Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30454",
                                    "name": "Xã Bình Thạnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30457",
                                    "name": "Xã Tân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30460",
                                    "name": "Xã Tân Trung",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "889",
                        "name": "Huyện Châu Phú",
                        "Wards": [
                                {
                                    "id": "30463",
                                    "name": "Thị trấn Cái Dầu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30466",
                                    "name": "Xã Khánh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30469",
                                    "name": "Xã Mỹ Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30472",
                                    "name": "Xã Mỹ Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30475",
                                    "name": "Xã Ô Long Vỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30478",
                                    "name": "Xã Vĩnh Thạnh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30481",
                                    "name": "Xã Thạnh Mỹ Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30484",
                                    "name": "Xã Bình Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30487",
                                    "name": "Xã Bình Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30490",
                                    "name": "Xã Bình Thủy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30493",
                                    "name": "Xã Đào Hữu Cảnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30496",
                                    "name": "Xã Bình Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30499",
                                    "name": "Xã Bình Chánh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "890",
                        "name": "Huyện Tịnh Biên",
                        "Wards": [
                                {
                                    "id": "30502",
                                    "name": "Thị trấn Nhà Bàng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30505",
                                    "name": "Thị trấn Chi Lăng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30508",
                                    "name": "Xã Núi Voi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30511",
                                    "name": "Xã Nhơn Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30514",
                                    "name": "Xã An Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30517",
                                    "name": "Xã Thới Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30520",
                                    "name": "Thị trấn Tịnh Biên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30523",
                                    "name": "Xã Văn Giáo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30526",
                                    "name": "Xã An Cư",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30529",
                                    "name": "Xã An Nông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30532",
                                    "name": "Xã Vĩnh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30535",
                                    "name": "Xã Tân Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30538",
                                    "name": "Xã An Hảo",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30541",
                                    "name": "Xã Tân Lập",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "891",
                        "name": "Huyện Tri Tôn",
                        "Wards": [
                                {
                                    "id": "30544",
                                    "name": "Thị trấn Tri Tôn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30547",
                                    "name": "Thị trấn Ba Chúc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30550",
                                    "name": "Xã Lạc Quới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30553",
                                    "name": "Xã Lê Trì",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30556",
                                    "name": "Xã Vĩnh Gia",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30559",
                                    "name": "Xã Vĩnh Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30562",
                                    "name": "Xã Châu Lăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30565",
                                    "name": "Xã Lương Phi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30568",
                                    "name": "Xã Lương An Trà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30571",
                                    "name": "Xã Tà Đảnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30574",
                                    "name": "Xã Núi Tô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30577",
                                    "name": "Xã An Tức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30580",
                                    "name": "Xã Cô Tô",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30583",
                                    "name": "Xã Tân Tuyến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30586",
                                    "name": "Xã Ô Lâm",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "892",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "30589",
                                    "name": "Thị trấn An Châu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30592",
                                    "name": "Xã An Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30595",
                                    "name": "Xã Cần Đăng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30598",
                                    "name": "Xã Vĩnh Hanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30601",
                                    "name": "Xã Bình Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30604",
                                    "name": "Xã Vĩnh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30607",
                                    "name": "Xã Bình Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30610",
                                    "name": "Xã Vĩnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30613",
                                    "name": "Xã Hòa Bình Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30616",
                                    "name": "Xã Vĩnh Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30619",
                                    "name": "Xã Vĩnh Nhuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30622",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30625",
                                    "name": "Xã Vĩnh Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "893",
                        "name": "Huyện Chợ Mới",
                        "Wards": [
                                {
                                    "id": "30628",
                                    "name": "Thị trấn Chợ Mới",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30631",
                                    "name": "Thị trấn Mỹ Luông",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30634",
                                    "name": "Xã Kiến An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30637",
                                    "name": "Xã Mỹ Hội Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30640",
                                    "name": "Xã Long Điền A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30643",
                                    "name": "Xã Tấn Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30646",
                                    "name": "Xã Long Điền B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30649",
                                    "name": "Xã Kiến Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30652",
                                    "name": "Xã Mỹ Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30655",
                                    "name": "Xã Mỹ An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30658",
                                    "name": "Xã Nhơn Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30661",
                                    "name": "Xã Long Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30664",
                                    "name": "Xã Long Kiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30667",
                                    "name": "Xã Bình Phước Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30670",
                                    "name": "Xã An Thạnh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30673",
                                    "name": "Xã Hội An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30676",
                                    "name": "Xã Hòa Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30679",
                                    "name": "Xã Hòa An",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "894",
                        "name": "Huyện Thoại Sơn",
                        "Wards": [
                                {
                                    "id": "30682",
                                    "name": "Thị trấn Núi Sập",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30685",
                                    "name": "Thị trấn Phú Hoà",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30688",
                                    "name": "Thị Trấn Óc Eo",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30691",
                                    "name": "Xã Tây Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30692",
                                    "name": "Xã An Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30694",
                                    "name": "Xã Vĩnh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30697",
                                    "name": "Xã Vĩnh Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30700",
                                    "name": "Xã Phú Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30703",
                                    "name": "Xã Vĩnh Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30706",
                                    "name": "Xã Định Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30709",
                                    "name": "Xã Định Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30712",
                                    "name": "Xã Mỹ Phú Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30715",
                                    "name": "Xã Vọng Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30718",
                                    "name": "Xã Vĩnh Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30721",
                                    "name": "Xã Thoại Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30724",
                                    "name": "Xã Bình Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30727",
                                    "name": "Xã Vọng Thê",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "91",
            "name": "Tỉnh Kiên Giang",
            "districts": [
                    {
                        "id": "899",
                        "name": "Thành phố Rạch Giá",
                        "Wards": [
                                {
                                    "id": "30730",
                                    "name": "Phường Vĩnh Thanh Vân"
                                },
                                {
                                    "id": "30733",
                                    "name": "Phường Vĩnh Thanh"
                                },
                                {
                                    "id": "30736",
                                    "name": "Phường Vĩnh Quang"
                                },
                                {
                                    "id": "30739",
                                    "name": "Phường Vĩnh Hiệp"
                                },
                                {
                                    "id": "30742",
                                    "name": "Phường Vĩnh Bảo"
                                },
                                {
                                    "id": "30745",
                                    "name": "Phường Vĩnh Lạc"
                                },
                                {
                                    "id": "30748",
                                    "name": "Phường An Hòa"
                                },
                                {
                                    "id": "30751",
                                    "name": "Phường An Bình"
                                },
                                {
                                    "id": "30754",
                                    "name": "Phường Rạch Sỏi"
                                },
                                {
                                    "id": "30757",
                                    "name": "Phường Vĩnh Lợi"
                                },
                                {
                                    "id": "30760",
                                    "name": "Phường Vĩnh Thông"
                                },
                                {
                                    "id": "30763",
                                    "name": "Xã Phi Thông",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "900",
                        "name": "Thành phố Hà Tiên",
                        "Wards": [
                                {
                                    "id": "30766",
                                    "name": "Phường Tô Châu"
                                },
                                {
                                    "id": "30769",
                                    "name": "Phường Đông Hồ"
                                },
                                {
                                    "id": "30772",
                                    "name": "Phường Bình San"
                                },
                                {
                                    "id": "30775",
                                    "name": "Phường Pháo Đài"
                                },
                                {
                                    "id": "30778",
                                    "name": "Phường Mỹ Đức"
                                },
                                {
                                    "id": "30781",
                                    "name": "Xã Tiên Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30784",
                                    "name": "Xã Thuận Yên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "902",
                        "name": "Huyện Kiên Lương",
                        "Wards": [
                                {
                                    "id": "30787",
                                    "name": "Thị trấn Kiên Lương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30790",
                                    "name": "Xã Kiên Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30802",
                                    "name": "Xã Hòa Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30805",
                                    "name": "Xã Dương Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30808",
                                    "name": "Xã Bình An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30809",
                                    "name": "Xã Bình Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30811",
                                    "name": "Xã Sơn Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30814",
                                    "name": "Xã Hòn Nghệ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "903",
                        "name": "Huyện Hòn Đất",
                        "Wards": [
                                {
                                    "id": "30817",
                                    "name": "Thị trấn Hòn Đất",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30820",
                                    "name": "Thị trấn Sóc Sơn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30823",
                                    "name": "Xã Bình Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30826",
                                    "name": "Xã Bình Giang",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30828",
                                    "name": "Xã Mỹ Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30829",
                                    "name": "Xã Nam Thái Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30832",
                                    "name": "Xã Mỹ Hiệp Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30835",
                                    "name": "Xã Sơn Kiên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30836",
                                    "name": "Xã Sơn Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30838",
                                    "name": "Xã Mỹ Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30840",
                                    "name": "Xã Lình Huỳnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30841",
                                    "name": "Xã Thổ Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30844",
                                    "name": "Xã Mỹ Lâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30847",
                                    "name": "Xã Mỹ Phước",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "904",
                        "name": "Huyện Tân Hiệp",
                        "Wards": [
                                {
                                    "id": "30850",
                                    "name": "Thị trấn Tân Hiệp",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30853",
                                    "name": "Xã Tân Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30856",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30859",
                                    "name": "Xã Tân Hiệp B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30860",
                                    "name": "Xã Tân Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30862",
                                    "name": "Xã Thạnh Đông B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30865",
                                    "name": "Xã Thạnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30868",
                                    "name": "Xã Tân Hiệp A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30871",
                                    "name": "Xã Tân An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30874",
                                    "name": "Xã Thạnh Đông A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30877",
                                    "name": "Xã Thạnh Trị",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "905",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "30880",
                                    "name": "Thị trấn Minh Lương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30883",
                                    "name": "Xã Mong Thọ A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30886",
                                    "name": "Xã Mong Thọ B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30887",
                                    "name": "Xã Mong Thọ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30889",
                                    "name": "Xã Giục Tượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30892",
                                    "name": "Xã Vĩnh Hòa Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30893",
                                    "name": "Xã Vĩnh Hoà Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30895",
                                    "name": "Xã Minh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30898",
                                    "name": "Xã Bình An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30901",
                                    "name": "Xã Thạnh Lộc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "906",
                        "name": "Huyện Giồng Riềng",
                        "Wards": [
                                {
                                    "id": "30904",
                                    "name": "Thị Trấn Giồng Riềng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30907",
                                    "name": "Xã Thạnh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30910",
                                    "name": "Xã Thạnh Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30913",
                                    "name": "Xã Thạnh Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30916",
                                    "name": "Xã Thạnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30917",
                                    "name": "Xã Thạnh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30919",
                                    "name": "Xã Bàn Thạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30922",
                                    "name": "Xã Bàn Tân Định",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30925",
                                    "name": "Xã Ngọc Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30928",
                                    "name": "Xã Ngọc Chúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30931",
                                    "name": "Xã Ngọc Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30934",
                                    "name": "Xã Hòa Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30937",
                                    "name": "Xã Hoà Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30940",
                                    "name": "Xã Hoà An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30943",
                                    "name": "Xã Long Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30946",
                                    "name": "Xã Vĩnh Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30947",
                                    "name": "Xã Vĩnh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30949",
                                    "name": "Xã  Hòa Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30950",
                                    "name": "Xã Ngọc Hoà",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "907",
                        "name": "Huyện Gò Quao",
                        "Wards": [
                                {
                                    "id": "30952",
                                    "name": "Thị trấn Gò Quao",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30955",
                                    "name": "Xã Vĩnh Hòa Hưng Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30958",
                                    "name": "Xã Định Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30961",
                                    "name": "Xã Thới Quản",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30964",
                                    "name": "Xã Định An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30967",
                                    "name": "Xã Thủy Liễu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30970",
                                    "name": "Xã Vĩnh Hòa Hưng Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30973",
                                    "name": "Xã Vĩnh Phước A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30976",
                                    "name": "Xã Vĩnh Phước B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30979",
                                    "name": "Xã Vĩnh Tuy",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30982",
                                    "name": "Xã Vĩnh Thắng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "908",
                        "name": "Huyện An Biên",
                        "Wards": [
                                {
                                    "id": "30985",
                                    "name": "Thị trấn Thứ Ba",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "30988",
                                    "name": "Xã Tây Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30991",
                                    "name": "Xã Tây Yên A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30994",
                                    "name": "Xã Nam Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30997",
                                    "name": "Xã Hưng Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31000",
                                    "name": "Xã Nam Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31003",
                                    "name": "Xã Nam Thái A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31006",
                                    "name": "Xã Đông Thái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31009",
                                    "name": "Xã Đông Yên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "909",
                        "name": "Huyện An Minh",
                        "Wards": [
                                {
                                    "id": "31018",
                                    "name": "Thị trấn Thứ Mười Một",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31021",
                                    "name": "Xã Thuận Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31024",
                                    "name": "Xã Đông Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31030",
                                    "name": "Xã Đông Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31031",
                                    "name": "Xã Tân Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31033",
                                    "name": "Xã Đông Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31036",
                                    "name": "Xã Đông Hưng A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31039",
                                    "name": "Xã Đông Hưng B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31042",
                                    "name": "Xã Vân Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31045",
                                    "name": "Xã Vân Khánh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31048",
                                    "name": "Xã Vân Khánh Tây",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "910",
                        "name": "Huyện Vĩnh Thuận",
                        "Wards": [
                                {
                                    "id": "31051",
                                    "name": "Thị trấn Vĩnh Thuận",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31060",
                                    "name": "Xã Vĩnh Bình Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31063",
                                    "name": "Xã Vĩnh Bình Nam",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31064",
                                    "name": "Xã Bình Minh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31069",
                                    "name": "Xã Vĩnh Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31072",
                                    "name": "Xã Tân Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31074",
                                    "name": "Xã Phong Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31075",
                                    "name": "Xã Vĩnh Phong",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "911",
                        "name": "Thành phố Phú Quốc",
                        "Wards": [
                                {
                                    "id": "31078",
                                    "name": "Phường Dương Đông"
                                },
                                {
                                    "id": "31081",
                                    "name": "Phường An Thới"
                                },
                                {
                                    "id": "31084",
                                    "name": "Xã Cửa Cạn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31087",
                                    "name": "Xã Gành Dầu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31090",
                                    "name": "Xã Cửa Dương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31093",
                                    "name": "Xã Hàm Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31096",
                                    "name": "Xã Dương Tơ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31102",
                                    "name": "Xã Bãi Thơm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31105",
                                    "name": "Xã Thổ Châu",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "912",
                        "name": "Huyện Kiên Hải",
                        "Wards": [
                                {
                                    "id": "31108",
                                    "name": "Xã Hòn Tre",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31111",
                                    "name": "Xã Lại Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31114",
                                    "name": "Xã An Sơn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31115",
                                    "name": "Xã Nam Du",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "913",
                        "name": "Huyện U Minh Thượng",
                        "Wards": [
                                {
                                    "id": "31012",
                                    "name": "Xã Thạnh Yên",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31015",
                                    "name": "Xã Thạnh Yên A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31027",
                                    "name": "Xã An Minh Bắc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31054",
                                    "name": "Xã Vĩnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31057",
                                    "name": "Xã Hoà Chánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31066",
                                    "name": "Xã Minh Thuận",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "914",
                        "name": "Huyện Giang Thành",
                        "Wards": [
                                {
                                    "id": "30791",
                                    "name": "Xã Vĩnh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30793",
                                    "name": "Xã Vĩnh Điều",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30796",
                                    "name": "Xã Tân Khánh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30797",
                                    "name": "Xã Phú Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "30799",
                                    "name": "Xã Phú Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "92",
            "name": "Thành phố Cần Thơ",
            "districts": [
                    {
                        "id": "916",
                        "name": "Quận Ninh Kiều",
                        "Wards": [
                                {
                                    "id": "31117",
                                    "name": "Phường Cái Khế"
                                },
                                {
                                    "id": "31120",
                                    "name": "Phường An Hòa"
                                },
                                {
                                    "id": "31123",
                                    "name": "Phường Thới Bình"
                                },
                                {
                                    "id": "31126",
                                    "name": "Phường An Nghiệp"
                                },
                                {
                                    "id": "31129",
                                    "name": "Phường An Cư"
                                },
                                {
                                    "id": "31135",
                                    "name": "Phường Tân An"
                                },
                                {
                                    "id": "31141",
                                    "name": "Phường An Phú"
                                },
                                {
                                    "id": "31144",
                                    "name": "Phường Xuân Khánh"
                                },
                                {
                                    "id": "31147",
                                    "name": "Phường Hưng Lợi"
                                },
                                {
                                    "id": "31149",
                                    "name": "Phường An Khánh"
                                },
                                {
                                    "id": "31150",
                                    "name": "Phường An Bình"
                                }
                        ]
                    },
                    {
                        "id": "917",
                        "name": "Quận Ô Môn",
                        "Wards": [
                                {
                                    "id": "31153",
                                    "name": "Phường Châu Văn Liêm"
                                },
                                {
                                    "id": "31154",
                                    "name": "Phường Thới Hòa"
                                },
                                {
                                    "id": "31156",
                                    "name": "Phường Thới Long"
                                },
                                {
                                    "id": "31157",
                                    "name": "Phường Long Hưng"
                                },
                                {
                                    "id": "31159",
                                    "name": "Phường Thới An"
                                },
                                {
                                    "id": "31162",
                                    "name": "Phường Phước Thới"
                                },
                                {
                                    "id": "31165",
                                    "name": "Phường Trường Lạc"
                                }
                        ]
                    },
                    {
                        "id": "918",
                        "name": "Quận Bình Thuỷ",
                        "Wards": [
                                {
                                    "id": "31168",
                                    "name": "Phường Bình Thủy"
                                },
                                {
                                    "id": "31169",
                                    "name": "Phường Trà An"
                                },
                                {
                                    "id": "31171",
                                    "name": "Phường Trà Nóc"
                                },
                                {
                                    "id": "31174",
                                    "name": "Phường Thới An Đông"
                                },
                                {
                                    "id": "31177",
                                    "name": "Phường An Thới"
                                },
                                {
                                    "id": "31178",
                                    "name": "Phường Bùi Hữu Nghĩa"
                                },
                                {
                                    "id": "31180",
                                    "name": "Phường Long Hòa"
                                },
                                {
                                    "id": "31183",
                                    "name": "Phường Long Tuyền"
                                }
                        ]
                    },
                    {
                        "id": "919",
                        "name": "Quận Cái Răng",
                        "Wards": [
                                {
                                    "id": "31186",
                                    "name": "Phường Lê Bình"
                                },
                                {
                                    "id": "31189",
                                    "name": "Phường Hưng Phú"
                                },
                                {
                                    "id": "31192",
                                    "name": "Phường Hưng Thạnh"
                                },
                                {
                                    "id": "31195",
                                    "name": "Phường Ba Láng"
                                },
                                {
                                    "id": "31198",
                                    "name": "Phường Thường Thạnh"
                                },
                                {
                                    "id": "31201",
                                    "name": "Phường Phú Thứ"
                                },
                                {
                                    "id": "31204",
                                    "name": "Phường Tân Phú"
                                }
                        ]
                    },
                    {
                        "id": "923",
                        "name": "Quận Thốt Nốt",
                        "Wards": [
                                {
                                    "id": "31207",
                                    "name": "Phường Thốt Nốt"
                                },
                                {
                                    "id": "31210",
                                    "name": "Phường Thới Thuận"
                                },
                                {
                                    "id": "31212",
                                    "name": "Phường Thuận An"
                                },
                                {
                                    "id": "31213",
                                    "name": "Phường Tân Lộc"
                                },
                                {
                                    "id": "31216",
                                    "name": "Phường Trung Nhứt"
                                },
                                {
                                    "id": "31217",
                                    "name": "Phường Thạnh Hoà"
                                },
                                {
                                    "id": "31219",
                                    "name": "Phường Trung Kiên"
                                },
                                {
                                    "id": "31227",
                                    "name": "Phường Tân Hưng"
                                },
                                {
                                    "id": "31228",
                                    "name": "Phường Thuận Hưng"
                                }
                        ]
                    },
                    {
                        "id": "924",
                        "name": "Huyện Vĩnh Thạnh",
                        "Wards": [
                                {
                                    "id": "31211",
                                    "name": "Xã Vĩnh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31231",
                                    "name": "Thị trấn Thanh An",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31232",
                                    "name": "Thị trấn Vĩnh Thạnh",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31234",
                                    "name": "Xã Thạnh Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31237",
                                    "name": "Xã Vĩnh Trinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31240",
                                    "name": "Xã Thạnh An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31241",
                                    "name": "Xã Thạnh Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31243",
                                    "name": "Xã Thạnh Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31244",
                                    "name": "Xã Thạnh Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31246",
                                    "name": "Xã Thạnh Qưới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31252",
                                    "name": "Xã Thạnh Lộc",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "925",
                        "name": "Huyện Cờ Đỏ",
                        "Wards": [
                                {
                                    "id": "31222",
                                    "name": "Xã Trung An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31225",
                                    "name": "Xã Trung Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31249",
                                    "name": "Xã Thạnh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31255",
                                    "name": "Xã Trung Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31261",
                                    "name": "Thị trấn Cờ Đỏ",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31264",
                                    "name": "Xã Thới Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31273",
                                    "name": "Xã Đông Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31274",
                                    "name": "Xã Đông Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31276",
                                    "name": "Xã Thới Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31277",
                                    "name": "Xã Thới Xuân",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "926",
                        "name": "Huyện Phong Điền",
                        "Wards": [
                                {
                                    "id": "31299",
                                    "name": "Thị trấn Phong Điền",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31300",
                                    "name": "Xã Nhơn Ái",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31303",
                                    "name": "Xã Giai Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31306",
                                    "name": "Xã Tân Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31309",
                                    "name": "Xã Trường Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31312",
                                    "name": "Xã Mỹ Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31315",
                                    "name": "Xã Nhơn Nghĩa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "927",
                        "name": "Huyện Thới Lai",
                        "Wards": [
                                {
                                    "id": "31258",
                                    "name": "Thị trấn Thới Lai",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31267",
                                    "name": "Xã Thới Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31268",
                                    "name": "Xã Tân Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31270",
                                    "name": "Xã Xuân Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31279",
                                    "name": "Xã Đông Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31282",
                                    "name": "Xã Đông Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31285",
                                    "name": "Xã Thới Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31286",
                                    "name": "Xã Trường Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31288",
                                    "name": "Xã Định Môn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31291",
                                    "name": "Xã Trường Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31294",
                                    "name": "Xã Trường Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31297",
                                    "name": "Xã Trường Xuân A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31298",
                                    "name": "Xã Trường Xuân B",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "93",
            "name": "Tỉnh Hậu Giang",
            "districts": [
                    {
                        "id": "930",
                        "name": "Thành phố Vị Thanh",
                        "Wards": [
                                {
                                    "id": "31318",
                                    "name": "Phường I"
                                },
                                {
                                    "id": "31321",
                                    "name": "Phường III"
                                },
                                {
                                    "id": "31324",
                                    "name": "Phường IV"
                                },
                                {
                                    "id": "31327",
                                    "name": "Phường V"
                                },
                                {
                                    "id": "31330",
                                    "name": "Phường VII"
                                },
                                {
                                    "id": "31333",
                                    "name": "Xã Vị Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31336",
                                    "name": "Xã Hoả Lựu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31338",
                                    "name": "Xã Tân Tiến",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31339",
                                    "name": "Xã Hoả Tiến",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "931",
                        "name": "Thành phố Ngã Bảy",
                        "Wards": [
                                {
                                    "id": "31340",
                                    "name": "Phường Ngã Bảy"
                                },
                                {
                                    "id": "31341",
                                    "name": "Phường Lái Hiếu"
                                },
                                {
                                    "id": "31343",
                                    "name": "Phường Hiệp Thành"
                                },
                                {
                                    "id": "31344",
                                    "name": "Phường Hiệp Lợi"
                                },
                                {
                                    "id": "31411",
                                    "name": "Xã Đại Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31414",
                                    "name": "Xã Tân Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "932",
                        "name": "Huyện Châu Thành A",
                        "Wards": [
                                {
                                    "id": "31342",
                                    "name": "Thị trấn Một Ngàn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31345",
                                    "name": "Xã Tân Hoà",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31346",
                                    "name": "Thị trấn Bảy Ngàn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31348",
                                    "name": "Xã Trường Long Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31351",
                                    "name": "Xã Trường Long A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31357",
                                    "name": "Xã Nhơn Nghĩa A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31359",
                                    "name": "Thị trấn Rạch Gòi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31360",
                                    "name": "Xã Thạnh Xuân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31362",
                                    "name": "Thị trấn Cái Tắc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31363",
                                    "name": "Xã Tân Phú Thạnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "933",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "31366",
                                    "name": "Thị Trấn Ngã Sáu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31369",
                                    "name": "Xã Đông Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31375",
                                    "name": "Xã Đông Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31378",
                                    "name": "Xã Phú Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31379",
                                    "name": "Xã Phú Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31381",
                                    "name": "Thị trấn Mái Dầm",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31384",
                                    "name": "Xã Đông Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31387",
                                    "name": "Xã Đông Phước A",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "934",
                        "name": "Huyện Phụng Hiệp",
                        "Wards": [
                                {
                                    "id": "31393",
                                    "name": "Thị trấn Kinh Cùng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31396",
                                    "name": "Thị trấn Cây Dương",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31399",
                                    "name": "Xã Tân Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31402",
                                    "name": "Xã Bình Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31405",
                                    "name": "Xã Thạnh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31408",
                                    "name": "Xã Long Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31417",
                                    "name": "Xã Phụng Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31420",
                                    "name": "Xã Hòa Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31423",
                                    "name": "Xã Hòa An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31426",
                                    "name": "Xã Phương Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31429",
                                    "name": "Xã Hiệp Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31432",
                                    "name": "Xã Tân Phước Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31433",
                                    "name": "Thị trấn Búng Tàu",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31435",
                                    "name": "Xã Phương Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31438",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "935",
                        "name": "Huyện Vị Thuỷ",
                        "Wards": [
                                {
                                    "id": "31441",
                                    "name": "Thị trấn Nàng Mau",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31444",
                                    "name": "Xã Vị Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31447",
                                    "name": "Xã Vị Thuỷ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31450",
                                    "name": "Xã Vị Thắng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31453",
                                    "name": "Xã Vĩnh Thuận Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31456",
                                    "name": "Xã Vĩnh Trung",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31459",
                                    "name": "Xã Vĩnh Tường",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31462",
                                    "name": "Xã Vị Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31465",
                                    "name": "Xã Vị Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31468",
                                    "name": "Xã Vị Bình",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "936",
                        "name": "Huyện Long Mỹ",
                        "Wards": [
                                {
                                    "id": "31483",
                                    "name": "Xã Thuận Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31484",
                                    "name": "Xã Thuận Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31486",
                                    "name": "Xã Vĩnh Thuận Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31489",
                                    "name": "Thị trấn Vĩnh Viễn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31490",
                                    "name": "Xã Vĩnh Viễn A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31492",
                                    "name": "Xã Lương Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31493",
                                    "name": "Xã Lương Nghĩa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31495",
                                    "name": "Xã Xà Phiên",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "937",
                        "name": "Thị xã Long Mỹ",
                        "Wards": [
                                {
                                    "id": "31471",
                                    "name": "Phường Thuận An"
                                },
                                {
                                    "id": "31472",
                                    "name": "Phường Trà Lồng"
                                },
                                {
                                    "id": "31473",
                                    "name": "Phường Bình Thạnh"
                                },
                                {
                                    "id": "31474",
                                    "name": "Xã Long Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31475",
                                    "name": "Phường Vĩnh Tường"
                                },
                                {
                                    "id": "31477",
                                    "name": "Xã Long Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31478",
                                    "name": "Xã Long Trị A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31480",
                                    "name": "Xã Long Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31481",
                                    "name": "Xã Tân Phú",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "94",
            "name": "Tỉnh Sóc Trăng",
            "districts": [
                    {
                        "id": "941",
                        "name": "Thành phố Sóc Trăng",
                        "Wards": [
                                {
                                    "id": "31498",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "31501",
                                    "name": "Phường 7"
                                },
                                {
                                    "id": "31504",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "31507",
                                    "name": "Phường 6"
                                },
                                {
                                    "id": "31510",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "31513",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "31516",
                                    "name": "Phường 4"
                                },
                                {
                                    "id": "31519",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "31522",
                                    "name": "Phường 9"
                                },
                                {
                                    "id": "31525",
                                    "name": "Phường 10"
                                }
                        ]
                    },
                    {
                        "id": "942",
                        "name": "Huyện Châu Thành",
                        "Wards": [
                                {
                                    "id": "31569",
                                    "name": "Thị trấn Châu Thành",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31570",
                                    "name": "Xã Hồ Đắc Kiện",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31573",
                                    "name": "Xã Phú Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31576",
                                    "name": "Xã Thuận Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31582",
                                    "name": "Xã Phú Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31585",
                                    "name": "Xã Thiện Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31594",
                                    "name": "Xã An Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31600",
                                    "name": "Xã An Ninh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "943",
                        "name": "Huyện Kế Sách",
                        "Wards": [
                                {
                                    "id": "31528",
                                    "name": "Thị trấn Kế Sách",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31531",
                                    "name": "Thị trấn An Lạc Thôn",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31534",
                                    "name": "Xã Xuân Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31537",
                                    "name": "Xã Phong Nẫm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31540",
                                    "name": "Xã An Lạc Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31543",
                                    "name": "Xã Trinh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31546",
                                    "name": "Xã Ba Trinh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31549",
                                    "name": "Xã Thới An Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31552",
                                    "name": "Xã Nhơn Mỹ",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31555",
                                    "name": "Xã Kế Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31558",
                                    "name": "Xã Kế An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31561",
                                    "name": "Xã Đại Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31564",
                                    "name": "Xã An Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "944",
                        "name": "Huyện Mỹ Tú",
                        "Wards": [
                                {
                                    "id": "31567",
                                    "name": "Thị trấn Huỳnh Hữu Nghĩa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31579",
                                    "name": "Xã Long Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31588",
                                    "name": "Xã Hưng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31591",
                                    "name": "Xã Mỹ Hương",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31597",
                                    "name": "Xã Mỹ Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31603",
                                    "name": "Xã Mỹ Phước",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31606",
                                    "name": "Xã Thuận Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31609",
                                    "name": "Xã Mỹ Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31612",
                                    "name": "Xã Phú Mỹ",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "945",
                        "name": "Huyện Cù Lao Dung",
                        "Wards": [
                                {
                                    "id": "31615",
                                    "name": "Thị trấn Cù Lao Dung",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31618",
                                    "name": "Xã An Thạnh 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31621",
                                    "name": "Xã An Thạnh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31624",
                                    "name": "Xã An Thạnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31627",
                                    "name": "Xã Đại Ân 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31630",
                                    "name": "Xã An Thạnh 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31633",
                                    "name": "Xã An Thạnh 3",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31636",
                                    "name": "Xã An Thạnh Nam",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "946",
                        "name": "Huyện Long Phú",
                        "Wards": [
                                {
                                    "id": "31639",
                                    "name": "Thị trấn Long Phú",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31642",
                                    "name": "Xã Song Phụng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31645",
                                    "name": "Thị trấn Đại Ngãi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31648",
                                    "name": "Xã Hậu Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31651",
                                    "name": "Xã Long Đức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31654",
                                    "name": "Xã Trường Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31657",
                                    "name": "Xã Phú Hữu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31660",
                                    "name": "Xã Tân Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31663",
                                    "name": "Xã Châu Khánh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31666",
                                    "name": "Xã Tân Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31669",
                                    "name": "Xã Long Phú",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "947",
                        "name": "Huyện Mỹ Xuyên",
                        "Wards": [
                                {
                                    "id": "31684",
                                    "name": "Thị trấn Mỹ Xuyên",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31690",
                                    "name": "Xã Đại Tâm",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31693",
                                    "name": "Xã Tham Đôn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31708",
                                    "name": "Xã Thạnh Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31711",
                                    "name": "Xã Ngọc Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31714",
                                    "name": "Xã Thạnh Quới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31717",
                                    "name": "Xã Hòa Tú 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31720",
                                    "name": "Xã Gia Hòa 1",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31723",
                                    "name": "Xã Ngọc Tố",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31726",
                                    "name": "Xã Gia Hòa 2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31729",
                                    "name": "Xã Hòa Tú II",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "948",
                        "name": "Thị xã Ngã Năm",
                        "Wards": [
                                {
                                    "id": "31732",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "31735",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "31738",
                                    "name": "Xã Vĩnh Quới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31741",
                                    "name": "Xã Tân Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31744",
                                    "name": "Xã Long Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31747",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "31750",
                                    "name": "Xã Mỹ Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31753",
                                    "name": "Xã Mỹ Quới",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "949",
                        "name": "Huyện Thạnh Trị",
                        "Wards": [
                                {
                                    "id": "31756",
                                    "name": "Thị trấn Phú Lộc",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31757",
                                    "name": "Thị trấn Hưng Lợi",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31759",
                                    "name": "Xã Lâm Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31762",
                                    "name": "Xã Thạnh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31765",
                                    "name": "Xã Lâm Kiết",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31768",
                                    "name": "Xã Tuân Tức",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31771",
                                    "name": "Xã Vĩnh Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31774",
                                    "name": "Xã Thạnh Trị",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31777",
                                    "name": "Xã Vĩnh Lợi",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31780",
                                    "name": "Xã Châu Hưng",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "950",
                        "name": "Thị xã Vĩnh Châu",
                        "Wards": [
                                {
                                    "id": "31783",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "31786",
                                    "name": "Xã Hòa Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31789",
                                    "name": "Phường Khánh Hòa"
                                },
                                {
                                    "id": "31792",
                                    "name": "Xã Vĩnh Hiệp",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31795",
                                    "name": "Xã Vĩnh Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31798",
                                    "name": "Xã Lạc Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31801",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "31804",
                                    "name": "Phường Vĩnh Phước"
                                },
                                {
                                    "id": "31807",
                                    "name": "Xã Vĩnh Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31810",
                                    "name": "Xã Lai Hòa",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "951",
                        "name": "Huyện Trần Đề",
                        "Wards": [
                                {
                                    "id": "31672",
                                    "name": "Xã Đại Ân  2",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31673",
                                    "name": "Thị trấn Trần Đề",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31675",
                                    "name": "Xã Liêu Tú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31678",
                                    "name": "Xã Lịch Hội Thượng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31679",
                                    "name": "Thị trấn Lịch Hội Thượng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31681",
                                    "name": "Xã Trung Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31687",
                                    "name": "Xã Tài Văn",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31696",
                                    "name": "Xã Viên An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31699",
                                    "name": "Xã Thạnh Thới An",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31702",
                                    "name": "Xã Thạnh Thới Thuận",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31705",
                                    "name": "Xã Viên Bình",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
            "id": "95",
            "name": "Tỉnh Bạc Liêu",
            "districts": [
                    {
                        "id": "954",
                        "name": "Thành phố Bạc Liêu",
                        "Wards": [
                                {
                                    "id": "31813",
                                    "name": "Phường 2"
                                },
                                {
                                    "id": "31816",
                                    "name": "Phường 3"
                                },
                                {
                                    "id": "31819",
                                    "name": "Phường 5"
                                },
                                {
                                    "id": "31822",
                                    "name": "Phường 7"
                                },
                                {
                                    "id": "31825",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "31828",
                                    "name": "Phường 8"
                                },
                                {
                                    "id": "31831",
                                    "name": "Phường Nhà Mát"
                                },
                                {
                                    "id": "31834",
                                    "name": "Xã Vĩnh Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31837",
                                    "name": "Xã Vĩnh Trạch Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31840",
                                    "name": "Xã Hiệp Thành",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "956",
                        "name": "Huyện Hồng Dân",
                        "Wards": [
                                {
                                    "id": "31843",
                                    "name": "Thị trấn Ngan Dừa",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31846",
                                    "name": "Xã Ninh Quới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31849",
                                    "name": "Xã Ninh Quới A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31852",
                                    "name": "Xã Ninh Hòa",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31855",
                                    "name": "Xã Lộc Ninh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31858",
                                    "name": "Xã Vĩnh Lộc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31861",
                                    "name": "Xã Vĩnh Lộc A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31863",
                                    "name": "Xã Ninh Thạnh Lợi A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31864",
                                    "name": "Xã Ninh Thạnh Lợi",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "957",
                        "name": "Huyện Phước Long",
                        "Wards": [
                                {
                                    "id": "31867",
                                    "name": "Thị trấn Phước Long",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31870",
                                    "name": "Xã Vĩnh Phú Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31873",
                                    "name": "Xã Vĩnh Phú Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31876",
                                    "name": "Xã Phước Long",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31879",
                                    "name": "Xã Hưng Phú",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31882",
                                    "name": "Xã Vĩnh Thanh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31885",
                                    "name": "Xã Phong Thạnh Tây A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31888",
                                    "name": "Xã Phong Thạnh Tây B",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "958",
                        "name": "Huyện Vĩnh Lợi",
                        "Wards": [
                                {
                                    "id": "31894",
                                    "name": "Xã Vĩnh Hưng",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31897",
                                    "name": "Xã Vĩnh Hưng A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31900",
                                    "name": "Thị trấn Châu Hưng",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31903",
                                    "name": "Xã Châu Hưng A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31906",
                                    "name": "Xã Hưng Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31909",
                                    "name": "Xã Hưng Hội",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31912",
                                    "name": "Xã Châu Thới",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31921",
                                    "name": "Xã Long Thạnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "959",
                        "name": "Thị xã Giá Rai",
                        "Wards": [
                                {
                                    "id": "31942",
                                    "name": "Phường 1"
                                },
                                {
                                    "id": "31945",
                                    "name": "Phường Hộ Phòng"
                                },
                                {
                                    "id": "31948",
                                    "name": "Xã Phong Thạnh Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31951",
                                    "name": "Phường Láng Tròn"
                                },
                                {
                                    "id": "31954",
                                    "name": "Xã Phong Tân",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31957",
                                    "name": "Xã Tân Phong",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31960",
                                    "name": "Xã Phong Thạnh",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31963",
                                    "name": "Xã Phong Thạnh A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31966",
                                    "name": "Xã Phong Thạnh Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31969",
                                    "name": "Xã Tân Thạnh",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "960",
                        "name": "Huyện Đông Hải",
                        "Wards": [
                                {
                                    "id": "31972",
                                    "name": "Thị trấn Gành Hào",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31975",
                                    "name": "Xã Long Điền Đông",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31978",
                                    "name": "Xã Long Điền Đông A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31981",
                                    "name": "Xã Long Điền",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31984",
                                    "name": "Xã Long Điền Tây",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31985",
                                    "name": "Xã Điền Hải",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31987",
                                    "name": "Xã An Trạch",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31988",
                                    "name": "Xã An Trạch A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31990",
                                    "name": "Xã An Phúc",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31993",
                                    "name": "Xã Định Thành",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31996",
                                    "name": "Xã Định Thành A",
                                    "Level": "Xã"
                                }
                        ]
                    },
                    {
                        "id": "961",
                        "name": "Huyện Hoà Bình",
                        "Wards": [
                                {
                                    "id": "31891",
                                    "name": "Thị trấn Hòa Bình",
                                    "Level": "Thị trấn"
                                },
                                {
                                    "id": "31915",
                                    "name": "Xã Minh Diệu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31918",
                                    "name": "Xã Vĩnh Bình",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31924",
                                    "name": "Xã Vĩnh Mỹ B",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31927",
                                    "name": "Xã Vĩnh Hậu",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31930",
                                    "name": "Xã Vĩnh Hậu A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31933",
                                    "name": "Xã Vĩnh Mỹ A",
                                    "Level": "Xã"
                                },
                                {
                                    "id": "31936",
                                    "name": "Xã Vĩnh Thịnh",
                                    "Level": "Xã"
                                }
                        ]
                    }
            ]
        },
        {
        "id": "96",
        "name": "Tỉnh Cà Mau",
        "districts": [
                {
                    "id": "964",
                    "name": "Thành phố Cà Mau",
                    "Wards": [
                            {
                                "id": "31999",
                                "name": "Phường 9",
                                "Level": "Phường"
                            },
                            {
                                "id": "32002",
                                "name": "Phường 4",
                                "Level": "Phường"
                            },
                            {
                                "id": "32005",
                                "name": "Phường 1",
                                "Level": "Phường"
                            },
                            {
                                "id": "32008",
                                "name": "Phường 5",
                                "Level": "Phường"
                            },
                            {
                                "id": "32011",
                                "name": "Phường 2",
                                "Level": "Phường"
                            },
                            {
                                "id": "32014",
                                "name": "Phường 8",
                                "Level": "Phường"
                            },
                            {
                                "id": "32017",
                                "name": "Phường 6",
                                "Level": "Phường"
                            },
                            {
                                "id": "32020",
                                "name": "Phường 7",
                                "Level": "Phường"
                            },
                            {
                                "id": "32022",
                                "name": "Phường Tân Xuyên",
                                "Level": "Phường"
                            },
                            {
                                "id": "32023",
                                "name": "Xã An Xuyên",
                                "Level": "Xã"
                            },
                            {
                                "id": "32025",
                                "name": "Phường Tân Thành",
                                "Level": "Phường"
                            },
                            {
                                "id": "32026",
                                "name": "Xã Tân Thành",
                                "Level": "Xã"
                            },
                            {
                                "id": "32029",
                                "name": "Xã Tắc Vân",
                                "Level": "Xã"
                            },
                            {
                                "id": "32032",
                                "name": "Xã Lý Văn Lâm",
                                "Level": "Xã"
                            },
                            {
                                "id": "32035",
                                "name": "Xã Định Bình",
                                "Level": "Xã"
                            },
                            {
                                "id": "32038",
                                "name": "Xã Hòa Thành",
                                "Level": "Xã"
                            },
                            {
                                "id": "32041",
                                "name": "Xã Hòa Tân",
                                "Level": "Xã"
                            }
                    ]
                },
                {
                    "id": "966",
                    "name": "Huyện U Minh",
                    "Wards": [
                            {
                                "id": "32044",
                                "name": "Thị trấn U Minh",
                                "Level": "Thị trấn"
                            },
                            {
                                "id": "32047",
                                "name": "Xã Khánh Hòa",
                                "Level": "Xã"
                            },
                            {
                                "id": "32048",
                                "name": "Xã Khánh Thuận",
                                "Level": "Xã"
                            },
                            {
                                "id": "32050",
                                "name": "Xã Khánh Tiến",
                                "Level": "Xã"
                            },
                            {
                                "id": "32053",
                                "name": "Xã Nguyễn Phích",
                                "Level": "Xã"
                            },
                            {
                                "id": "32056",
                                "name": "Xã Khánh Lâm",
                                "Level": "Xã"
                            },
                            {
                                "id": "32059",
                                "name": "Xã Khánh An",
                                "Level": "Xã"
                            },
                            {
                                "id": "32062",
                                "name": "Xã Khánh Hội",
                                "Level": "Xã"
                            }
                    ]
                },
                {
                    "id": "967",
                    "name": "Huyện Thới Bình",
                    "Wards": [
                            {
                                "id": "32065",
                                "name": "Thị trấn Thới Bình",
                                "Level": "Thị trấn"
                            },
                            {
                                "id": "32068",
                                "name": "Xã Biển Bạch",
                                "Level": "Xã"
                            },
                            {
                                "id": "32069",
                                "name": "Xã Tân Bằng",
                                "Level": "Xã"
                            },
                            {
                                "id": "32071",
                                "name": "Xã Trí Phải",
                                "Level": "Xã"
                            },
                            {
                                "id": "32072",
                                "name": "Xã Trí Lực",
                                "Level": "Xã"
                            },
                            {
                                "id": "32074",
                                "name": "Xã Biển Bạch Đông",
                                "Level": "Xã"
                            },
                            {
                                "id": "32077",
                                "name": "Xã Thới Bình",
                                "Level": "Xã"
                            },
                            {
                                "id": "32080",
                                "name": "Xã Tân Phú",
                                "Level": "Xã"
                            },
                            {
                                "id": "32083",
                                "name": "Xã Tân Lộc Bắc",
                                "Level": "Xã"
                            },
                            {
                                "id": "32086",
                                "name": "Xã Tân Lộc",
                                "Level": "Xã"
                            },
                            {
                                "id": "32089",
                                "name": "Xã Tân Lộc Đông",
                                "Level": "Xã"
                            },
                            {
                                "id": "32092",
                                "name": "Xã Hồ Thị Kỷ",
                                "Level": "Xã"
                            }
                    ]
                },
                {
                    "id": "968",
                    "name": "Huyện Trần Văn Thời",
                    "Wards": [
                            {
                                "id": "32095",
                                "name": "Thị trấn Trần Văn Thời",
                                "Level": "Thị trấn"
                            },
                            {
                                "id": "32098",
                                "name": "Thị trấn Sông Đốc",
                                "Level": "Thị trấn"
                            },
                            {
                                "id": "32101",
                                "name": "Xã Khánh Bình Tây Bắc",
                                "Level": "Xã"
                            },
                            {
                                "id": "32104",
                                "name": "Xã Khánh Bình Tây",
                                "Level": "Xã"
                            },
                            {
                                "id": "32107",
                                "name": "Xã Trần Hợi",
                                "Level": "Xã"
                            },
                            {
                                "id": "32108",
                                "name": "Xã Khánh Lộc",
                                "Level": "Xã"
                            },
                            {
                                "id": "32110",
                                "name": "Xã Khánh Bình",
                                "Level": "Xã"
                            },
                            {
                                "id": "32113",
                                "name": "Xã Khánh Hưng",
                                "Level": "Xã"
                            },
                            {
                                "id": "32116",
                                "name": "Xã Khánh Bình Đông",
                                "Level": "Xã"
                            },
                            {
                                "id": "32119",
                                "name": "Xã Khánh Hải",
                                "Level": "Xã"
                            },
                            {
                                "id": "32122",
                                "name": "Xã Lợi An",
                                "Level": "Xã"
                            },
                            {
                                "id": "32124",
                                "name": "Xã Phong Điền",
                                "Level": "Xã"
                            },
                            {
                                "id": "32125",
                                "name": "Xã Phong Lạc",
                                "Level": "Xã"
                            }
                    ]
                },
                {
                    "id": "969",
                    "name": "Huyện Cái Nước",
                    "Wards": [
                            {
                                "id": "32128",
                                "name": "Thị trấn Cái Nước",
                                "Level": "Thị trấn"
                            },
                            {
                                "id": "32130",
                                "name": "Xã Thạnh Phú",
                                "Level": "Xã"
                            },
                            {
                                "id": "32131",
                                "name": "Xã Lương Thế Trân",
                                "Level": "Xã"
                            },
                            {
                                "id": "32134",
                                "name": "Xã Phú Hưng",
                                "Level": "Xã"
                            },
                            {
                                "id": "32137",
                                "name": "Xã Tân Hưng",
                                "Level": "Xã"
                            },
                            {
                                "id": "32140",
                                "name": "Xã Hưng Mỹ",
                                "Level": "Xã"
                            },
                            {
                                "id": "32141",
                                "name": "Xã Hoà Mỹ",
                                "Level": "Xã"
                            },
                            {
                                "id": "32142",
                                "name": "Xã Đông Hưng",
                                "Level": "Xã"
                            },
                            {
                                "id": "32143",
                                "name": "Xã Đông Thới",
                                "Level": "Xã"
                            },
                            {
                                "id": "32146",
                                "name": "Xã Tân Hưng Đông",
                                "Level": "Xã"
                            },
                            {
                                "id": "32149",
                                "name": "Xã Trần Thới",
                                "Level": "Xã"
                            }
                    ]
                },
                {
                    "id": "970",
                    "name": "Huyện Đầm Dơi",
                    "Wards": [
                            {
                                "id": "32152",
                                "name": "Thị trấn Đầm Dơi",
                                "Level": "Thị trấn"
                            },
                            {
                                "id": "32155",
                                "name": "Xã Tạ An Khương",
                                "Level": "Xã"
                            },
                            {
                                "id": "32158",
                                "name": "Xã Tạ An Khương  Đông",
                                "Level": "Xã"
                            },
                            {
                                "id": "32161",
                                "name": "Xã Trần Phán",
                                "Level": "Xã"
                            },
                            {
                                "id": "32162",
                                "name": "Xã Tân Trung",
                                "Level": "Xã"
                            },
                            {
                                "id": "32164",
                                "name": "Xã Tân Đức",
                                "Level": "Xã"
                            },
                            {
                                "id": "32167",
                                "name": "Xã Tân Thuận",
                                "Level": "Xã"
                            },
                            {
                                "id": "32170",
                                "name": "Xã Tạ An Khương  Nam",
                                "Level": "Xã"
                            },
                            {
                                "id": "32173",
                                "name": "Xã Tân Duyệt",
                                "Level": "Xã"
                            },
                            {
                                "id": "32174",
                                "name": "Xã Tân Dân",
                                "Level": "Xã"
                            },
                            {
                                "id": "32176",
                                "name": "Xã Tân Tiến",
                                "Level": "Xã"
                            },
                            {
                                "id": "32179",
                                "name": "Xã Quách Phẩm Bắc",
                                "Level": "Xã"
                            },
                            {
                                "id": "32182",
                                "name": "Xã Quách Phẩm",
                                "Level": "Xã"
                            },
                            {
                                "id": "32185",
                                "name": "Xã Thanh Tùng",
                                "Level": "Xã"
                            },
                            {
                                "id": "32186",
                                "name": "Xã Ngọc Chánh",
                                "Level": "Xã"
                            },
                            {
                                "id": "32188",
                                "name": "Xã Nguyễn Huân",
                                "Level": "Xã"
                            }
                    ]
                },
                {
                    "id": "971",
                    "name": "Huyện Năm Căn",
                    "Wards": [
                            {
                                "id": "32191",
                                "name": "Thị Trấn Năm Căn",
                                "Level": "Thị trấn"
                            },
                            {
                                "id": "32194",
                                "name": "Xã Hàm Rồng",
                                "Level": "Xã"
                            },
                            {
                                "id": "32197",
                                "name": "Xã Hiệp Tùng",
                                "Level": "Xã"
                            },
                            {
                                "id": "32200",
                                "name": "Xã Đất Mới",
                                "Level": "Xã"
                            },
                            {
                                "id": "32201",
                                "name": "Xã Lâm Hải",
                                "Level": "Xã"
                            },
                            {
                                "id": "32203",
                                "name": "Xã Hàng Vịnh",
                                "Level": "Xã"
                            },
                            {
                                "id": "32206",
                                "name": "Xã Tam Giang",
                                "Level": "Xã"
                            },
                            {
                                "id": "32209",
                                "name": "Xã Tam Giang Đông",
                                "Level": "Xã"
                            }
                    ]
                },
                {
                    "id": "972",
                    "name": "Huyện Phú Tân",
                    "Wards": [
                            {
                                "id": "32212",
                                "name": "Thị trấn Cái Đôi Vàm",
                                "Level": "Thị trấn"
                            },
                            {
                                "id": "32214",
                                "name": "Xã Phú Thuận",
                                "Level": "Xã"
                            },
                            {
                                "id": "32215",
                                "name": "Xã Phú Mỹ",
                                "Level": "Xã"
                            },
                            {
                                "id": "32218",
                                "name": "Xã Phú Tân",
                                "Level": "Xã"
                            },
                            {
                                "id": "32221",
                                "name": "Xã Tân Hải",
                                "Level": "Xã"
                            },
                            {
                                "id": "32224",
                                "name": "Xã Việt Thắng",
                                "Level": "Xã"
                            },
                            {
                                "id": "32227",
                                "name": "Xã Tân Hưng Tây",
                                "Level": "Xã"
                            },
                            {
                                "id": "32228",
                                "name": "Xã Rạch Chèo",
                                "Level": "Xã"
                            },
                            {
                                "id": "32230",
                                "name": "Xã Nguyễn Việt Khái",
                                "Level": "Xã"
                            }
                    ]
                },
                {
                    "id": "973",
                    "name": "Huyện Ngọc Hiển",
                    "Wards": [
                            {
                                "id": "32233",
                                "name": "Xã Tam Giang Tây",
                                "Level": "Xã"
                            },
                            {
                                "id": "32236",
                                "name": "Xã Tân Ân Tây",
                                "Level": "Xã"
                            },
                            {
                                "id": "32239",
                                "name": "Xã Viên An Đông",
                                "Level": "Xã"
                            },
                            {
                                "id": "32242",
                                "name": "Xã Viên An",
                                "Level": "Xã"
                            },
                            {
                                "id": "32244",
                                "name": "Thị trấn Rạch Gốc",
                                "Level": "Thị trấn"
                            },
                            {
                                "id": "32245",
                                "name": "Xã Tân Ân",
                                "Level": "Xã"
                            },
                            {
                                "id": "32248",
                                "name": "Xã Đất Mũi",
                                "Level": "Xã"
                            }
                    ]
                }
        ]
    }
]
export default {
    arr_vietnam,
}
