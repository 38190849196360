import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, Outlet, useNavigate } from "react-router-dom"
import styles from './AgentLink.module.scss'
import { useSelector, useDispatch } from 'react-redux';
import { observer } from 'mobx-react-lite';
import { appStore, useAppStore } from '../../../../../stores/AppStore';
import { makeAutoObservable, toJS } from "mobx";

export const AgentLink =
    observer(() => {
        const {
            agentLinkStore,
            agentLinkStore: { nodeId},
        } = useAppStore();

        const copyToClipboard = (textToCopy: string) => {
            navigator.clipboard.writeText(textToCopy+nodeId)
            .then(() => {
                alert(`Đã copy đoạn text vào clipboard, bạn có thể dán tùy ý`);
            })
            .catch(err => {
                alert('Unable to copy text to clipboard');
            });
        }
        useEffect(() => {
            agentLinkStore.checkSignin();
        },[]);
        return (
            <div className={clsx(styles.component_AgentLink)}>
                <div className={clsx(styles.boxLink)}>
                    <p className={clsx(styles.key)}>Link: </p>
                    <p className={clsx(styles.value)}>
                        <span className={clsx(styles.url)}>
                            https://charm.io.vn/agent/signup/
                        </span>
                        <span className={clsx(styles.nodeId)}>
                            {nodeId}
                        </span>
                            
                    </p>
                    <div className={clsx(styles.boxButton)}>
                        <button onClick={()=>copyToClipboard('https://charm.io.vn/agent/signup/')}>Copy</button>
                    </div>
                </div>
                <div className={clsx(styles.boxExplain)}>
                    <h3>Giải thích link</h3>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>https://charm.io.vn/agent/signup/</p>
                        <p className={clsx(styles.value)}>Là đường dẫn chung đến trang đăng ký, tất cả mọi người sẽ có chung phần này</p>
                    </div>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key, styles.nodeId)}>số màu đỏ</p>
                        <p className={clsx(styles.value)}>Là id của bạn, những đối tác của bạn sẽ nối đến id này, id của mỗi người là duy nhất</p>
                    </div>
                </div>
            </div>
        )
    })

