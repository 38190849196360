import { useState } from 'react';
import { retrieveObjectFromLocalStorage, wait } from '../../share/sharedFunction';
import collectionAPI from '../../../API/collectionAPI';
import styles from './UploadPictureCCCD.module.scss';
import clsx from 'clsx';
import { getCurrentDateTimeVietNam } from '../../share/sharedFunction';

const UploadPictureCCCD = ({ stateNum_idKhachhang, stateNum_idGiaodich, selectGiaodich }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };


  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length <= 0) {
      return;
    }
    const imageFile = droppedFiles[0];
    const maxSize = 1000 * 1024; // 500KB in bytes
    if (imageFile.size > maxSize) {
      alert('Hình ảnh phải có dung lượng dưới 1MB (1000KB). Vui lòng chọn hình khác');
      return;
    }
    // Do something with the dropped image file, for example, display it or upload it.
    // console.log('Dropped Image File:', imageFile);

    const formData = new FormData();
    formData.append('file', imageFile);
    const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
    const agentExist = retrieveObjectFromLocalStorage("loginSuccessAgent");
    let token = "";
    if (userExist === null) {
      if(agentExist === null){
        return;
      }
      else {
        token = agentExist.token;
      }
    }
    else {
      token = userExist.token;

    }

    try {
      // upload hình lên google cloud service storage
      let response1
      if(userExist !== null){
        response1 = await collectionAPI.collectionAPI.uploadFile(token, formData);
      }
      else if(agentExist !== null) {
        response1 = await collectionAPI.collectionAPI.uploadFile2(formData);
      }
      await wait(500);
      // upload xong nó sẽ trả ra cái mediaId và save vào bảng media
      const mediaId = response1.data.id;
      // console.log('mediaId: ', mediaId);
      // console.log('stateNum_idKhachhang: ', stateNum_idKhachhang);
      const now = getCurrentDateTimeVietNam();

      let response_hinh_mt = await collectionAPI.collectionAPI.isPropertyOfKhachhangNull(token, stateNum_idKhachhang, "cccd_mt_mediaId");
      let cccd_mt = response_hinh_mt.data;
      if (cccd_mt === true) { // cccd_mt === true nghĩa là cccd mặt trước chưa có thì thêm hình vào mt
        let responseUpdate = await collectionAPI.collectionAPI.updateKhachhangByIdOneField(token, stateNum_idKhachhang, "cccd_mt_mediaId", mediaId);
        // console.log('responseUpdate: ', responseUpdate);
        selectGiaodich(stateNum_idGiaodich);
        return;
      }
      // nếu mt đã có hình thì kiểm tra tiếp mặt sau đã có hình chưa, chưa có thì thêm vào
      let response_hinh_ms = await collectionAPI.collectionAPI.isPropertyOfKhachhangNull(token, stateNum_idKhachhang, "cccd_ms_mediaId");
      let cccd_ms = response_hinh_ms.data;
      if (cccd_ms === false) { // false nghĩa là cccd mặt sau của giao dịch này !== null
        alert("Khách hàng này đã có hình CCCD !. Bạn phải xóa hình cũ trước khi thêm hình, vì một khách hàng chỉ có 1 CCCD");
        return;
      }
      // update cái mediaId vừa có được vào bảng Giaodich, sau này khi lấy giao dịch nào đó theo giaodichId, nó sẽ tự kèm theo hình có signedUrl
      let responseUpdate = await collectionAPI.collectionAPI.updateKhachhangByIdOneField(token, stateNum_idKhachhang, "cccd_ms_mediaId", mediaId);
      let responseCreatedAt = await collectionAPI.collectionAPI.updateKhachhangByIdOneField(token, stateNum_idKhachhang, "cccd_createdAt", now);
      // // ta update cái media vào giao dịch, phải lấy cả các mediaId cũ để truyền vào, nếu ko thì nó sẽ ghi đè
      // // mỗi lần tìm giao dịch theo id, nó sẽ gửi mediaId lên GCS để tìm cái signedUrl để có đường dẫn hình hiện ra FE
      await wait(1000); // phải chờ 5s để nó lưu trên GCS xong sau đó mới lấy trên đó về
      selectGiaodich(stateNum_idGiaodich);

    } catch (err) {
      console.log('err:', err);
    }

  };


  return (
    <div
      className={clsx('dropZone', { dragging: isDragging }, styles.dropZone)} // Use clsx to conditionally apply classes
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    // onClick={handleImageClick}
    >
      <p>Hình CCCD</p>
    </div>
  );
};

export default UploadPictureCCCD;
