import { HoaDonRequest } from '../types/HoaDonRequest';
import api from './api';

export const createSanPham = (body: HoaDonRequest)  => {
    const url = "/hoadon";
    return api.post(url, body).then(res => res.data);
}
export const findAllSanPham = ()  => {
    const url = "/hoadon";
    return api.get(url).then(res => res.data);
}

export const deleteSanPham = (id: number)  => {
    const url = "/hoadon/"+id;
    return api.delete(url).then(res => res.data);
}

// export const findDeNghiBy_EmployeeId_deNghiTypeId = (employeeId: number, deNghiTypeId: number)  => {
//     const url = "/denghi/"+employeeId+"/"+deNghiTypeId;
//     return api.get(url).then(res => res.data);
// }
