// recft_clsx_module_scss
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import styles from "./AgentSignIn.module.scss"; //file scss cùng cấp
import { useDispatch } from "react-redux"; //useSelector: hook lấy state từ jobSlice.js ; useDispatch: hook phát đi đến jobSlice.js
import { useAppStore } from "../../../../stores/AppStore";
import { observer } from "mobx-react-lite";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import collectionAPI from "../../../../API/collectionAPI";
import {
  isSignInAgent,
  setState_objSigninAgent,
} from "../../../../features/generalSlice";
import { storeObjectInLocalStorage } from "../../../share/sharedFunction";
import { generateRandomNumber } from "../utils";
import { useForm } from "react-hook-form";
import { SignInRequest } from "../../../../types/AgentUser";
export const AgentSignIn = observer(() => {
  const { authStore } = useAppStore();
  const [showPass, setShowPass] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SignInRequest>();

  const navigate = useNavigate(); //hook dùng để chuyển trang web
  //   const dispatch = useDispatch();

  //   const submit = async () => {
  //     try {
  //       const data = {
  //         phone: phone,
  //         password: password,
  //       };
  //       console.log("data: ", data);
  //       const response =
  //         await collectionAPI.collectionAPI_AuthController.loginAgent(data);
  //       console.log("response: ", response);
  //       if (response.data.message === "succeed") {
  //         alert("Đăng nhập thành công");
  //         const obj = {
  //           token: response.data.result.access_token,
  //           email: response.data.result.user.email,
  //           name: response.data.result.user.name,
  //           phone: response.data.result.user.phone,
  //           id: response.data.result.user.id,
  //           type: response.data.result.user.type,
  //           nodeId: response.data.result.user.nodeId,
  //           path: response.data.result.user.path,
  //         };
  //         dispatch(isSignInAgent(true)); // explain 1
  //         dispatch(setState_objSigninAgent(obj)); // explain 1
  //         sessionStorage.clear(); // remove tất cả session cũ
  //         localStorage.clear(); // remove tất cả localstorage cũ

  //         storeObjectInLocalStorage("loginSuccessAgent", obj, 8);
  //         navigate("/agent/khachhang", { replace: true }); //chuyển đến trang successLogin <Route path="/successLogin" element={<SuccessLogin/> } />
  //       } else {
  //         alert(response.data.message);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };
  const [stateNumRandomNumber, setStateNumRandomNumber] = useState<number>(1); // number hoặc string
  useEffect(() => {
    const num = generateRandomNumber();
    setStateNumRandomNumber(num);
  },[]);
  const dispatch = useDispatch(); 
  const submit = async (formData: SignInRequest) => {
    const response = await authStore.login(formData);
    if(response.status === "fail"){
      alert(response.message);
      return;
    }
    else if(response.status === "succeed"){
      alert(response.message);
      const obj = {
      token: response.result.access_token,
      email: response.result.user.email,
      name: response.result.user.name,
      phone: response.result.user.phone,
      username: response.result.user.username,
      id: response.result.user.id,
      type: response.result.user.type,
      nodeId: response.result.user.nodeId,
      path: response.result.user.path,
      };
      dispatch(isSignInAgent(true)); // explain 1
      dispatch(setState_objSigninAgent(obj)); // explain 1
      sessionStorage.clear(); // remove tất cả session cũ
      localStorage.clear(); // remove tất cả localstorage cũ

      storeObjectInLocalStorage("loginSuccessAgent", obj, 8);
      // return;
      navigate("/agent/khachhang", { replace: true });
    }
  };

  return (
    <div className={clsx(styles.component_AgentSignIn)}>
      <div className={clsx(styles.container)}>
        <div className={clsx(styles.left)}>
          <img
            src={`../assets/signin/${stateNumRandomNumber}.png`}
            className="avatar"
            alt="avatar"
          />
        </div>
        <form
          onSubmit={handleSubmit(submit)}
          className={clsx(styles.formLogin)}
        >
          <h4>
            <span>Welcome to Charm Services</span>
            <span>
              <img
                src="../assets/logo/logo_icon.png"
                className="avatar"
                alt="avatar"
              />
            </span>
          </h4>
          <h2>HỘI VIÊN ĐĂNG NHẬP</h2>
          {/* <div className={clsx(styles.row)}>
            <label>Số điện thoại: </label>
            <span className={clsx(styles.box)}>
              <input
                type="text"
                {...register("phone", { required: true })}
                placeholder="Số điện thoại..."
              />
              <div></div>
            </span>
          </div> */}
          <div className={clsx(styles.row)}>
            <label>Username: </label>
            <span className={clsx(styles.box)}>
              <input
                type="text"
                {...register("username", { required: true })}
                placeholder="Username..."
              />
              <div></div>
            </span>
          </div>
          <div className={clsx(styles.row)}>
            <label>Mật khẩu: </label>
            <span className={clsx(styles.box)}>
              <input
                type={showPass ? "text" : "password"}
                {...register("password", { required: true })}
                placeholder="Mật khẩu..."
              />
              <span className={clsx(styles.eyeWrapper)}>
                <RemoveRedEyeIcon
                  className={clsx(styles.eye)}
                  onClick={() => setShowPass(!showPass)}
                />
              </span>
            </span>
          </div>

          <div className={clsx(styles.btnWrapper)}>
            <button type="submit">Submit</button>
            <button type="reset" onClick={() => reset()}>
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});
