import { useState } from 'react';
import clsx from 'clsx';
import styles from './hr.module.scss'
import { useDispatch } from 'react-redux';
import FileUpload_bcc from './FileUpload_bcc';
import FileUpload_nghiphep from './FileUpload_nghiphep';

function HRUpload() {
    const dispatch = useDispatch();
    const [stateArrGroupByEmail, setStateArrGroupByEmail] = useState([]);
    const [stateArrInvalid, setStateArrInvalid] = useState([]);
    const [stateArrNghiPhep, setStateArrNghiPhep] = useState([]);

      // Callback function to receive data from the child
    const handleFileUpload_bccData = (arrGroupByEmail:any, invalidArrGroupByName:any) => {
        setStateArrGroupByEmail(arrGroupByEmail);
        setStateArrInvalid(invalidArrGroupByName);
        // console.log('data father: ', arrGroupByEmail);
    };
    const handleFileUpload_nghiphepData = (arrNghiPhep:any) => {
        setStateArrNghiPhep(arrNghiPhep);
    };
    return (
        <div className={clsx(styles.component_HRUpload)}>
            <h1>UPLOAD</h1>
            <div className={clsx(styles.uploadWrapper)}>
                <div className={clsx(styles.upload)}>
                    <p className={clsx(styles.key)}>Upload file chấm công:</p>
                    <FileUpload_bcc/>
                </div>
                <div className={clsx(styles.upload)}>
                    <p className={clsx(styles.key)}>Upload file nghỉ phép:</p>
                    <FileUpload_nghiphep/>
                </div>
            </div>

            <div className={clsx(styles.remarks)}>
                <div className={clsx(styles.chamcong)}>
                    {
                        stateArrGroupByEmail.length > 0 &&
                        (
                            <div className={clsx(styles.successImport)}>
                                <img src="../assets/icon/successIcon.png" className="successIcon" alt="successIcon" />
                                <p className={clsx(styles.info)}>File chấm công đã được upload thành công</p>        
                            </div>
                        )
                    }
                    {stateArrInvalid.length > 0 && (
                        <div className={clsx(styles.errorWrapper)}>
                            <p className={clsx(styles.title)}>Ghi chú file chấm công:</p>
                            <p className={clsx(styles.info)}>File chấm công có tên chưa được đăng ký trong hệ thống như bên dưới</p>
                            <p className={clsx(styles.info)}>Những tên này sẽ không được hiện trong báo cáo cho tới khi có đầy đủ thông tin</p>
                            <div className={clsx(styles.heading, styles.row)}>
                                <p>No.</p>
                                <p>name</p>
                                <p>Action</p>
                            </div>
                            {stateArrInvalid.map((item, index:any) => (
                                <div className={clsx(styles.row)} key={item}>
                                    <p>{index+1}</p>
                                    <p>{item}</p>
                                    <p>
                                        <button>Bổ sung thông tin</button>
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                
                </div>
                <div className={clsx(styles.nghiphep)}>
                    {
                        stateArrNghiPhep.length > 0 &&
                        (
                            <div className={clsx(styles.successImport)}>
                                <img src="../assets/icon/successIcon.png" className="successIcon" alt="successIcon" />
                                <p className={clsx(styles.info)}>File nghỉ phép đã được upload thành công</p>        
                            </div>

                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default HRUpload