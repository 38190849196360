import { makeAutoObservable } from "mobx";
import { retrieveObjectFromLocalStorage } from "../components/share/sharedFunction";

interface SignInState {
  email: string;
  name: string;
  role: string;
  token: string;
  id: number;
  nodeId: number;
  path: string;
  type: string;
  phone: string;
}
export class AgentLinkStore {
  nodeId: number;

  objSignIn_local: SignInState;
  
  constructor() {
    this.nodeId = 0;
    this.objSignIn_local = {
      email: "",
      name: "",
      role: "",
      token: "",
      id: 0,
      nodeId: 0,
      path: "",
      type: "",
      phone: "",
  };

    makeAutoObservable(this);
  }
  checkSignin = () => {
    const userExist = retrieveObjectFromLocalStorage("loginSuccessAgent");
    if (userExist === null) {
        return;
    }
    this.objSignIn_local = userExist;
    this.nodeId = userExist.nodeId
  };

  copyToClipboard = (textToCopy: string) => {
    alert(`${textToCopy}`);
    navigator.clipboard.writeText(textToCopy+this.nodeId)
    .then(() => {
        alert(`Đã copy đoạn text vào clipboard, bạn có thể dán tùy ý`);
    })
    .catch(err => {
        alert('Unable to copy text to clipboard');
    });
  }

}
