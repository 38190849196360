import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import styles from './Rutdao.module.scss'
import { useSelector } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import {
    validatePhirutdao,
    validateTienrutdao,
    isValidCreditCard,
    validateCCCD,
    isValidDateFormatYYYY_MM_dd,
    validatePhoneNumber,
    validateTienRut,
    isValidDateFormat_dd_mm_yyyy,
    convertDateFormat_dd_mm_yyyy_to_yyyy_mm_dd,
    validatePhoneNumberBoolean,
    checkStringType,
} from '../../share/share.validation';
import {
    isNow24HoursLaterThan,
    removeDecimalAndRound,
    formatCreditCardNumber,
    getCurrentDate,
    retrieveObjectFromLocalStorage,
    getFirstAndLastDayOfYearParameter,
    splitMonthYear,
    getFirstAndLastDayOfMonthOfYear,
    addOneDay,
    getFirstAndLastDayOfYear,
    getFirstAndLastDayOfMonth,
    getStartAndEndOfWeek,
    getCurrentDateYYYYMMDD,
    getCurrentDateTimeVietNam,
    formatPhoneNumber,
    formatThoudsand,
    formatDateString,
    wait,
    storeObjectInLocalStorage,
    convertToTitleCase,
    formatCreditCardXXXX,
    replaceFirstFourLetters,
    convertDateFormat_removeYear,
    removeDuplicateObjects,
    formatDateTime,
    checkPermission,
} from '../../share/sharedFunction';
import Switch from '@mui/material/Switch';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import collectionAPI from '../../../API/collectionAPI';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { arr_vietnam } from '../../share/arr_vietnam';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Tooltip from '@mui/material/Tooltip';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import 'bootstrap/dist/css/bootstrap.min.css';
import UploadPicture from './UploadPicture';
import { selectstateRedux_number_view, selectstateRedux_number_giaoDichId, selectstateRedux_number_khachHangId } from '../../../features/generalSlice';
import UploadPicture_tienphi from './UploadPicture_tienphi';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadPictureRut from './UploadPictureRut';
import UploadPictureRut_notId from './UploadPictureRut_notId';
import UploadPictureCCCD from './UploadPictureCCCD';
import UploadPictureNap from './UploadPictureNap';
import UploadPictureNap_notId from './UploadPictureNap_notId';
import NotYetSignIn from '../general/NotYetSignIn';

import UploadPictureDao from './UploadPictureDao';
import UploadPictureDao_notId from './UploadPictureDao_notId'
import { arrEmail5 } from '../../share/arrRole';

function RutDaoKhachHang() {

    type SignInState = {
        email: string;
        fullName: string;
        id: string;
        role: string;
        token: string;
    };
    const [stateObjSignIn, setStateObjSignIn] = useState<SignInState>({
        email: '',
        fullName: '',
        id: '',
        role: '',
        token: '',
    });

    const checkSignin = () => {

        const defaultUser: SignInState = {
            email: '',
            fullName: '',
            id: '',
            role: '',
            token: '',
        };

        const userExist = retrieveObjectFromLocalStorage('loginSuccessEmployee');

        if (userExist) {
            setStateObjSignIn(userExist);
        } else {
            setStateObjSignIn(defaultUser);
        }

    }
    // KHU VỰC GỌI API ----------------------------------------------------start

    const [stateArrMayPos, setStateArrMayPos] = useState<any[]>([]);
    const findAllMayPos = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist !== null) {
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI.findAllMayPos_exclude_phiMayPos(token);
                const sortedArray = response.data.slice().sort((a: any, b: any) => a.name.localeCompare(b.name));
                const filteredArray = sortedArray.filter((obj: any) => obj.visible === true);
                setStateArrMayPos(filteredArray);
            } catch (err) {
                console.log('err:', err);
            }
        }
    }
    const [stateArrDeletePermission, setStateArrDeletePermission] = useState<any[]>([]);
    const findAllDeletePermission = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist !== null) {
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI_deletePermission.findAll(token);
                setStateArrDeletePermission(response.data);
                // console.log('response.data: ', response.data);
            } catch (err) {
                console.log('err:', err);
            }
        }
    }
    const [stateArrTruongHop, setStateArrTruongHop] = useState<any[]>([]);
    const findAllTruongHop = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist !== null) {
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI_truongHop.findAll(token);
                setStateArrTruongHop(response.data);
                // console.log('response.data: ', response.data);
            } catch (err) {
                console.log('err:', err);
            }
        }
    }
    const [stateArrNganhang, setStateArrNganhang] = useState<any[]>([]);
    const findAllNganhang = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist !== null) {
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI.findAllNganhang(token);
                // console.log('response: ', response);
                setStateArrNganhang(response.data);
            } catch (err) {
                console.log('err:', err);
            }
        }
    }
    const idsToRemove = ["Tất cả", "CSKH", "Hotline RĐ"];
    const [stateArrNguonKH, setStateArrNguonKH] = useState<any[]>([]);
    const findAllNguonKH = async () => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist !== null) {
            const token = userExist.token;

            try {
                let response = await collectionAPI.collectionAPI.findAllNguonKH(token);
                // console.log('response.data stateArrNguonKH: ', response.data);
                const newArray = response.data.filter((item: any) => !idsToRemove.includes(item.id));

                setStateArrNguonKH(newArray);
            } catch (err) {
                console.log('err:', err);
            }
        }
    }

    let giaoDichId_redux = useSelector(selectstateRedux_number_giaoDichId);
    let khachhangId_redux = useSelector(selectstateRedux_number_khachHangId);
    // console.log('----------------------------------------rutdaokhachhang');
    const findAllkhachhangByNguonKHByDateRange_Thisweek = async () => {
        // this week
        const obj = getStartAndEndOfWeek()
        let start = obj.startOfWeek;
        let end = obj.endOfWeek;
        setStateString_timeFrame("Tuần này");
        setStateString_startTime(start);
        setStateString_endTime(end);
        getKhachHangData(start, end, "Tất cả");

    }
    useEffect(() => {
        checkSignin();
        findAllNganhang();
        findAllMayPos();
        findAllNguonKH();
        findAllkhachhangByNguonKHByDateRange_Thisweek();
        findAllTruongHop();
        findAllDeletePermission();
    }, []);
    // console.log('6');

    useEffect(() => {
        if (khachhangId_redux !== 0) {
            // console.log('khachhangId_redux rutdaokhachhang: ', khachhangId_redux);
            // console.log('giaoDichId_redux: ', giaoDichId_redux);

            setStateNumber_selectedKH_id(khachhangId_redux);
            setStateNum_idGiaodich(giaoDichId_redux);
            selectKH(khachhangId_redux);
            selectGiaodich(giaoDichId_redux);
            // console.log('2');
        }

    }, [khachhangId_redux, giaoDichId_redux]);

    let stateRedux_view = useSelector(selectstateRedux_number_view);
    // console.log('7');

    useEffect(() => {
        // console.log('stateRedux_view: ', stateRedux_view);
        if (stateRedux_view === 1) {
            invisible_groups_and_listKHWrapper();
        }
        else if (stateRedux_view === 2) {
            // invisible_groups();
            show_groups_and_listKHWrapper();

        }

        else if (stateRedux_view === 3) {
            setStateBool_invisible_groups(false);
            setStateBool_contactsWrapper_2_col(false);
            // setStateBool_arrow_lastLeftArrow(true);
            // setStateBool_invisible_listKHWrapper(false);
            // setStateBool_contactsWrapper_1_col(false);
            // setStateBool_contactsWrapper_2_col(true);
        }
        // console.log('3');

    }, [stateRedux_view]);

    const getKhachHangData = async (start: string, end: string, nguon: string) => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return
        }
        const token = userExist.token;

        try {
            let response = await collectionAPI.collectionAPI.findAllkhachhangByNguonKHByDateRange(token, start, end, nguon);
            // console.log('response.data: ', response.data);
            setStateArr_khachHangByNguonKH(response.data.data); // lưu lại danh sách group nhân viên đc click vào, và giữ tạm ở đó
            setStateArr_khachHangByNguonKHShow(response.data.data); // hiện ra màn hình

        } catch (err) {
            console.log('err:', err);
        }
    }

    // KHU VỰC GỌI API ----------------------------------------------------end
    // city, district, ward start---------------------------------------------------------------------------------
    // const [stateBool_isDisable_tenKhachHang, setStateBool_isDisable_tenKhachHang] = useState(false);
    const [stateBool_isDisable_nguonKH, setStateBool_isDisable_nguonKH] = useState(false);
    const [stateBool_isDisable_city, setStateBool_isDisable_city] = useState(false);
    const [stateBool_isDisable_district, setStateBool_isDisable_district] = useState(false);
    const [stateBool_isDisable_ward, setStateBool_isDisable_ward] = useState(false);

    const [nameCitySelected, setNameCitiSelected] = useState(""); //chứa tên city khi mình chọn trong ô select option
    const [citiesOptions, setCitiesOptions] = useState<any[]>([]); //Tạo ra dánh sách các option để xổ ra

    const [nameDistrictSelected, setNameDistrictSelected] = useState("");
    const [districtsOptions, setDistrictsOptions] = useState<any[]>([]);

    const [nameWardSelected, setNameWardSelected] = useState("");
    const [wardsOptions, setWardsOptions] = useState<any[]>([]);

    const [stateString_address, setStateString_address] = useState("");

    const handleCity = (e: any) => {

        if (e.target.value === "empty") {
            alert("City can not be empty !");
        } else {

            setNameCitiSelected(e.target.value);

            fullfillDistrictSelectOptions(e.target.value);
        }
    }

    const handleDistrict = (e: any) => {
        setNameDistrictSelected(e.target.value); // e.target.value là giá trị khi mình chọn trong ô select option
        fullfillWardsSelectOptions(e.target.value);
        // console.log('setNameDistrictSelected: ', e.target.value);
    }

    const handleAddress = (e: any) => {

        setStateString_address(e.target.value);

    }
    const handleWard = (e: any) => {

        setNameWardSelected(e.target.value);

    }
    const fullfillCitySelectOptions = () => {
        let arr: any = []
        for (let i = 0, len = arr_vietnam.length; i < len; i++) {
            arr.push(
                <option key={Math.random()} value={arr_vietnam[i].name}>{arr_vietnam[i].name}</option>
            )
        }
        setCitiesOptions(arr); //set state cho nó là tập hợp các option để mình chèn vào select lúc render ra
    }
    const fullfillDistrictSelectOptions = (cityName: string) => {
        let arr: any = []
        // console.log('cityName: ', cityName);

        const indexCity = arr_vietnam.findIndex((obj) => obj.name === cityName);// tìm index của city
        // console.log("indexCity: " + indexCity);
        // console.log(arr_vietnam[indexCity].districts);

        let arrDistricts = arr_vietnam[indexCity].districts

        for (let i = 0; i < arrDistricts.length; i++) {
            arr.push(
                <option key={Math.random()} value={arrDistricts[i].name}>{arrDistricts[i].name}</option>
            )
        }

        setDistrictsOptions(arr);
    }
    const fullfillWardsSelectOptions = (districtName: string) => {
        let arr: any = []
        // console.log('districtName: ', districtName);
        const indexCity = arr_vietnam.findIndex((obj) => obj.name === nameCitySelected);// tìm index của city
        const indexDistrict = arr_vietnam[indexCity].districts.findIndex((obj) => obj.name === districtName);// tìm index của city

        let arrWards = arr_vietnam[indexCity].districts[indexDistrict].Wards

        for (let i = 0; i < arrWards.length; i++) {
            arr.push(
                // `$ {id}` ép id thành string
                //@ts-ignore
                <option key={Math.random()} value={arrWards[i].name}>{arrWards[i].name}</option>
            )
        }
        setWardsOptions(arr);
    }
    useEffect(() => {
        fullfillCitySelectOptions();
    }, []);

    // city, district, ward end---------------------------------------------------------------------------------
    const [stateString_tab, setStateString_tab] = useState<string>('giao dịch'); // string

    const switchTab = (tab: string) => {
        setStateString_tab(tab);
        storeObjectInLocalStorage("tonghopStartDateEndDate", { tab: tab }, 72);
    }

    // const [stateGiaodich_selected, setStateGiaodich_selected] = useState<Record<string, any>>({// dùng để chứa object cskh khi post đến backend
    //     "idKhachhang": 0,
    //     "createdAt": null,
    //     "editedAt": null,
    //     "emailNVCSKHCreate": "",
    //     "fullNameNVCSKHcreate": "",
    //     "emailNVCSKHEdit": "",
    //     "emailNVRutdaoCreate": "",
    //     "fullNameNVRutdaoCreate": "",
    //     "emailNVRutdaoEdit": "",
    //     "nhucau": "",
    //     "nganhangId": "",
    //     "loaiThe": "",
    //     "tienrutdao": 0,
    //     "phirutdao": 0,
    //     "tienphi": 0,
    //     "tienship": 0,
    //     "tenchinhanh": "",
    //     "ngaydukienlienhelai": null,
    //     "ngayhanthanhtoan": null,
    //     "trangthaiCSKH": "",
    //     "ghichurutdao": "",
    //     "ghichucskh": "",
    //     "nhanvienship": "",
    //     "diemdenship": "",
    //     "ngayrutdao": null,
    //     "loaigiaodich": "",
    //     "tenmaypos": "",
    //     "teamrutdaoreceivedAt": null,
    //     "teamrutdaothongbaotrangthai": "",
    //     "teamrutdaothongbaotrangthaiAt": null,
    //     "lydocuatrangthai": "",
    //     "noiDungChuyenTien": "",
    // });
    const [stateNumber_selectedKH_id, setStateNumber_selectedKH_id] = useState<number>(khachhangId_redux);

    const [stateString_tenkhachhang, setStateString_tenkhachhang] = useState("");

    const [stateString_nguonKH, setStateString_nguonKH] = useState("Rút đáo");
    const handleChange_nguonKH = (event: any) => {
        const value = event.target.value;
        if (value === "Chọn...") {
            alert("Bạn vui lòng chọn một nguồn khách hàng theo danh sách. Xin cảm ơn.");
            return;
        }
        // console.log('value: ', value);
        setStateString_nguonKH(value)
    };
    const [stateString_nguonKH_selectGroup, setStateString_nguonKH_selectGroup] = useState("Tất cả");
    const handleChange_nguonKH_selectGroup = async (event: any) => {
        const value = event.target.value;
        if (value === "Chọn...") {
            alert("Bạn vui lòng chọn một nguồn khách hàng theo danh sách. Xin cảm ơn.");
            return;
        }
        // console.log('value: ', value);
        setStateString_nguonKH_selectGroup(value);
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist !== null) {
            const token = userExist.token;
            try {
                let response = await collectionAPI.collectionAPI.findAllkhachhangByNguonKH(token, value);
                // console.log(`response.data ${nguonKH}: `, response.data);
                // selectKH(response.data[0].id); // cho nó chọn khách hàng mới nhất mặc định ban đầu
                setStateArr_khachHangByNguonKH(response.data); // lưu lại danh sách group nhân viên đc click vào, và giữ tạm ở đó
                setStateArr_khachHangByNguonKHShow(response.data); // hiện ra màn hình
            } catch (err) {
                console.log('err:', err);
            }
        }

    };

    const handleInputChange_tenkhachhang = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        // console.log('value: ', value);
        setStateString_tenkhachhang(value);
    };

    // SỐ ĐIỆN THOẠI++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const [stateString_sdt, setStateString_sdt] = useState("");

    const handleInputChange_sdt = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setStateString_sdt(value);
        // console.log('value: ', value);

    };

    // const [stateBool_valid_phone, setStateBool_valid_phone] = useState(false); // boolean

    const addPhone = async (event: any) => {

        const result = validatePhoneNumber(stateString_sdt);
        if (result === null) {
            alert("Nhập sai số điện thoại");
            return;
        }
        const objPhone = {
            idKhachhang: stateObj_selectedKH.id,
            phone: stateString_sdt
        }
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        const token = userExist.token;
        // console.log('objPhone: ', objPhone);
        try {
            let response = await collectionAPI.collectionAPI.createPhone(token, objPhone);
            let message = response.data.warning;
            if (message !== undefined) {
                alert(`${message}`);
                // window.location.reload();
                setStateString_sdt('');
            }
            else if (message === undefined) {
                setStateString_sdt(''); // post thành công thì reset lại cho nó về rỗng

                selectKH(stateNumber_selectedKH_id);
                await wait(100);
                selectGiaodich(stateObj_giaoDichSelected.id);
            }

        } catch (err) {
            console.log('err:', err);
        }
    }
    const [stateBool_showEditPhoneBtn, setStateBool_showEditPhoneBtn] = useState(false); // boolean
    const [stateNum_phoneId_to_edit, setStateNum_phoneId_to_edit] = useState(0);
    const handleClickEditIcon = (id: number, phone: string) => {
        setStateBool_showEditPhoneBtn(true);

        setStateNum_phoneId_to_edit(id);
        setStateString_sdt(phone)

    }
    const handleClickDeleteIcon = async (id: number) => {
        // console.log('id: ', id);
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return;
        }
        const email = userExist.email;
        const token = userExist.token;

        const resultCheckPermission = checkPermission(email, stateArrDeletePermission);
        if (!resultCheckPermission) {
            return;
        }
        if (window.confirm("Bạn có chắc chắn muốn xóa sđt này, một khi đã xóa thì sẽ không thể truy hồi lại !") === false) {
            return;
        }

        try {
            await collectionAPI.collectionAPI.deletePhone(token, id);

            selectKH(stateNumber_selectedKH_id);
            await wait(100);
            selectGiaodich(stateObj_giaoDichSelected.id);
            setStateBool_showEditPhoneBtn(false); // cho hiện lại nút thêm
            setStateString_sdt('')
            await wait(100);
            selectKH(stateNumber_selectedKH_id);
            setStateStringAlertMessage("Xóa phone thành công");
            setStateBoolAlert(true);
        } catch (err) {
            console.log('err:', err);
        }

    }
    const editPhone = async () => {
        const result = validatePhoneNumber(stateString_sdt);
        if (result === null) {
            alert("Nhập sai số điện thoại");
            return;
        }
        const objPhone = {
            idKhachhang: stateObj_selectedKH.id,
            phone: stateString_sdt
        }
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return;
        }
        const token = userExist.token;
        try {
            let response = await collectionAPI.collectionAPI.editPhone(token, stateNum_phoneId_to_edit, objPhone);
            let message = response.data.warning;
            if (message !== undefined) {
                alert(`${message}`);
            }
            else if (message === undefined) {
                setStateString_sdt(''); // post thành công thì reset lại cho nó về rỗng
                setStateBool_showEditPhoneBtn(false); // cho hiện lại nút thêm sdt

                selectKH(stateNumber_selectedKH_id);
                await wait(100);
                selectGiaodich(stateObj_giaoDichSelected.id);
            }

            // storeObjectInLocalStorage("token", token, 8);
            // storeObjectInLocalStorage("stateString_selectedGroup", stateString_selectedGroup, 8);
            // storeObjectInLocalStorage("stateNumber_selectedKH_id", stateNumber_selectedKH_id, 8);
            // storeObjectInLocalStorage("idGiaodich", idGiaodich, 8);
            // // setStateString_sdt(''); // post thành công thì reset lại cho nó về rỗng
            // window.location.reload();
        } catch (err) {
            console.log('err:', err);
        }
    }
    // TÊN NGÂN HÀNG++++++++++++++++++++++++++++++++++++++++++++++++++++++

    const [stateString_tennganhang, setStateString_tennganhang] = useState("ABBANK");
    const handleChange_tennganhang = (event: any) => {
        const value = event.target.value;
        setStateString_tennganhang(value)
    };

    const reset_inputThe = () => {

        setStateString_ngayhanthanhtoan("");
        setStateNum_theTinDungId(0);
        setStateString_aiDangGiuThe("Team rút đáo");
        setStateNumber_hanmucthetindung_makeup("")
        setStateNumber_hanmucthetindung(0);
        setStateString_loaithe("");
        setStateString_soTaiKhoanTheTinDung("");
        setStateString_sothetindung_makeup("");
        setStateString_sothetindung("");
        setStateBool_isValid_sothetindung(false);
        setStateString_tennganhang("ABBANK")
        setStateString_tenTrenThe("");

    }
    const reset_inputSTKNH = () => {

        setStateString_sotaikhoannganhang("");
        setStateString_tenNganHangChuyenTien("ABBANK");

    }
    const [stateBool_showInputCredit_new, setStateBool_showInputCredit_new] = useState(false); // dùng để hiện cái input số thẻ tín dũng cũ hay là tạo mới

    const [stateNum_theTinDungId, setStateNum_theTinDungId] = useState(0); // chứa id của thẻ tín dụng
    const handleChange_theTinDung = (event: any) => {
        const value = event.target.value; // value là id của thẻ tín dụng
        // console.log('value: ', value);
        if (value === "Chọn...") {
            alert("Vui lòng chọn một thẻ trong danh sách");
            setStateBool_showInputCredit_new(true);
            reset_inputThe();
            setStateNum_theTinDungId(0);
            return;
        }
        else if (value === "Tạo thẻ mới") {
            setStateBool_showInputCredit_new(true);
            reset_inputThe();
            setStateNum_theTinDungId(0);
            return;
        }
        setStateBool_showInputCredit_new(false);

        // console.log('id the tin dung: ', value);
        setStateNum_theTinDungId(value)
        const obj = stateArr_theOfSelectedKH.find((item: any) => item.id === parseInt(value + ''));
        // console.log('stateArr_theOfSelectedKH: ', stateArr_theOfSelectedKH);
        // console.log('theTinDungSelected: ', obj);

        setStateString_aiDangGiuThe(obj.aiDangGiu);

        // const ngayhanthanhtoan: string = obj.hanDao;
        // if (ngayhanthanhtoan !== null) {
        //     /*start "2024-01-09T23:00:00.000Z" to "yyyy-mm-dd hh:mm" */
        //     const date = new Date(ngayhanthanhtoan);

        //     const year = date.getFullYear();
        //     const month = String(date.getMonth() + 1).padStart(2, '0');
        //     const day = String(date.getDate()).padStart(2, '0');
        //     // const hours = String(date.getHours()).padStart(2, '0');
        //     // const minutes = String(date.getMinutes()).padStart(2, '0');
        //     // let dateConvert =  `${year}-${month}-${day}`;
        //     let dateConvert = `${day}-${month}-${year}`;
        //     /*finish*/
        //     //@ts-ignore
        //     // const yyyy_mm_dd_hh_mm = formatDate(ngaydukienlienhelai);
        //     setStateString_ngayhanthanhtoan(dateConvert);
        // } {
        //     setStateString_ngayhanthanhtoan("");
        // }

        const hanMucTheTinDung = obj.hanMucTheTinDung;
        const moneyToString_hanMucTheTinDung = formatThoudsand(hanMucTheTinDung); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNumber_hanmucthetindung_makeup(moneyToString_hanMucTheTinDung);
        setStateNumber_hanmucthetindung(hanMucTheTinDung);

        setStateString_loaithe(obj.loaiThe);

        setStateString_soTaiKhoanTheTinDung(obj.soTaiKhoanTheTinDung);

        // setStateString_sotaikhoannganhang(obj.soTaiKhoanNganHang);

        // setStateString_tenNganHangChuyenTien(obj.tenNganHangChuyenTien);

        setStateString_tenTrenThe(obj.tenTrenThe);

        setStateString_tennganhang(obj.nganhangId)

        const soTheTinDung = obj.soThe;
        let stringWithSpace = formatCreditCardNumber(soTheTinDung)
        setStateString_sothetindung_makeup(stringWithSpace)
        setStateString_sothetindung(soTheTinDung)
        setStateBool_isValid_sothetindung(false);

        setStateString_aiDangGiuThe(obj.aiDangGiu)

    };
    const [stateNum_stknhId, setStateNum_stknhId] = useState(0); // chứa id của thẻ tín dụng
    const handleChange_stknh = (event: any) => {
        const value = event.target.value; // value là id của thẻ tín dụng
        // console.log('value: ', value);
        if (value === "Chọn...") {
            alert("Vui lòng chọn một STK trong danh sách");
            reset_inputSTKNH();
            setStateNum_stknhId(0);
            return;
        }
        else if (value === "Tạo mới") {
            reset_inputSTKNH();
            setStateNum_stknhId(0);
            return;
        }
        setStateNum_stknhId(value);
        const obj = stateArr_STKNH_OfSelectedKH.find((item: any) => item.id === parseInt(value + ''));

        setStateString_sotaikhoannganhang(obj.soTaiKhoanNganHang);

        setStateString_tenNganHangChuyenTien(obj.tenNganHangChuyenTien);

    };

    // LOẠI THẺ++++++++++++++++++++++++++++++++++++++++++++++++++++++

    const [stateString_loaithe, setStateString_loaithe] = useState("Visa");
    const handleChange_loaithe = (event: any) => {
        const value = event.target.value;
        if (value === "Chọn...") {
            alert("Vui lòng chọn một loại thẻ");
            return;
        }
        // console.log('value: ', value);
        setStateString_loaithe(value)
    };
    // console.log('stateString_loaithe: ', stateString_loaithe);
    const [stateString_aiDangGiuThe, setStateString_aiDangGiuThe] = useState("Team rút đáo");
    const handleChange_aiDangGiuThe = (event: any) => {
        const value = event.target.value;
        if (value === "Chọn...") {
            alert("Vui lòng chọn người đang giữ thẻ");
            return;
        }
        setStateString_aiDangGiuThe(value)
    };
    const [stateString_truongHop, setStateString_truongHop] = useState("Bình thường");
    const handleChange_truongHop = (event: any) => {
        const value = event.target.value;
        setStateString_truongHop(value)
    };
    const [stateString_diachiship, setStateString_diachiship] = useState("");
    const handleInputChange_diachiship = (event: any) => {
        const value = event.target.value;
        setStateString_diachiship(value);
    };

    // tiền rút đáo
    const [stateNumber_tienrutdao, setStateNumber_tienrutdao] = useState<number>(0);
    const [stateNumber_tienrutdao_makeup, setStateNumber_tienrutdao_makeup] = useState<string>("");

    const handleChange_tienrutdao = (event: any) => {
        let value = event.target.value;
        value = value.replace(/[,.]/g, '');
        if (isNaN(+value) === true) { // nếu ko phải là số thì...
            alert("Bạn không được nhập chữ vào ô này !");
            let afterRemoveLastLetter = value.slice(0, -1); // Tức bỏ cái chữ cuối cùng mới nhập vào, chỉ lấy trước nó thì dụ đang nhập 456 mà nhập 456a thì nó bỏ chữ a và giữ lại 456
            setStateNumber_tienrutdao(parseInt(afterRemoveLastLetter + ""));
            setStateNumber_tienrutdao_makeup(formatThoudsand(afterRemoveLastLetter));
            return;
        }

        const moneyToString = formatThoudsand(value); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNumber_tienrutdao_makeup(moneyToString); // để hiện ra ô input có dấu , cho dễ xem

        let stringToNum_tienrutdao = parseInt(value + "");
        setStateNumber_tienrutdao(stringToNum_tienrutdao);

        let tienphi = Math.floor((stringToNum_tienrutdao * stateNumber_phirutdao) / 100);
        tienphi = Math.ceil(tienphi / 1000) * 1000;
        setStateNumber_tienphi(tienphi); // ko cần format, vì khi hiện ra thì mình chỉ cần to localString thì nó có , phân cách

        let tienChuyenKhach = stringToNum_tienrutdao - tienphi;
        setStateNumber_tienChuyenKhach(tienChuyenKhach); // ko cần format, vì khi hiện ra thì mình chỉ cần to localString thì nó có , phân cách
    };

    const [stateNumber_phirutdao, setStateNumber_phirutdao] = useState<number>(0);
    const [stateNumber_tienphi, setStateNumber_tienphi] = useState<number>(0);
    const [stateNumber_tienChuyenKhach, setStateNumber_tienChuyenKhach] = useState<number>(0);

    const handleChange_phirutdao = (event: any) => {
        const value = event.target.value; // type string
        setStateNumber_phirutdao(value);
        let tienphi = Math.floor((stateNumber_tienrutdao / 100) * value);
        // console.log('++++++++++++++++++++++++++++++++++++++++++');
        // console.log('tienphi 1: ', tienphi);
        tienphi = Math.ceil(tienphi / 1000) * 1000;
        // console.log('tienphi 2: ', tienphi);
        setStateNumber_tienphi(tienphi);

        let tienChuyenKhach = stateNumber_tienrutdao - tienphi;
        setStateNumber_tienChuyenKhach(tienChuyenKhach);

    };

    // Chi nhánh
    const [stateString_chinhanh, setStateString_chinhanh] = useState('empty');
    const handleChiNhanh = (event: any) => {
        const value = event.target.value;

        setStateString_chinhanh(value);
    };
    // SỐ TIỀN SHIP ++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const [stateNumber_tienship, setStateNumber_tienship] = useState<number>(0);
    const [stateNumber_tienship_makeup, setStateNumber_tienship_makeup] = useState<number>(0);
    const handleChange_tienship = (event: any) => {

        let value = event.target.value;
        value = value.replace(/[,.]/g, ''); // khi ô input truyền lên thì ô input là kiểu text, mà có dấu phẩy thoudsand separator nên mình phải bỏ dấu phẩy
        if (isNaN(+value) === true) { // nếu ko phải là số thì...
            alert("Bạn không được nhập chữ vào ô này !");
            let afterRemoveLastLetter = value.slice(0, -1); // Tức bỏ cái chữ cuối cùng mới nhập vào, chỉ lấy trước nó thì dụ đang nhập 456 mà nhập 456a thì nó bỏ chữ a và giữ lại 456
            setStateNumber_tienship_makeup(formatThoudsand(afterRemoveLastLetter));
            setStateNumber_tienship(parseInt(afterRemoveLastLetter + ""));
            return;
        }
        const moneyToString = formatThoudsand(value); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNumber_tienship_makeup(moneyToString);
        setStateNumber_tienship(parseInt(value + ""));

    };

    const [stateString_startTime, setStateString_startTime] = useState<string>("");
    const [stateString_endTime, setStateString_endTime] = useState<string>("");
    const handleChange_startTime = (event: any) => {
        // console.log('event.target.value: ', event.target.value);
        setStateString_startTime(event.target.value)
    };
    const handleChange_endTime = async (event: any) => {
        const endTime = event.target.value;

        const startTime_typeDate = new Date(stateString_startTime);
        const endTime_typeDate = new Date(endTime);

        if (endTime_typeDate < startTime_typeDate) {
            alert("Ngày dưới không được sớm hơn ngày trên");
            return;
        }
        else {

            setStateString_endTime(event.target.value);
        }
        getKhachHangData(stateString_startTime, endTime, stateString_selectedGroup);

    };

    const [stateString_dateCreateGD, setStateString_dateCreateGD] = useState<string>("");
    const handleChange_dateCreateGD = (event: any) => {
        // console.log('event.target.value: ', event.target.value);
        setStateString_dateCreateGD(event.target.value)
    };

    const [stateString_dateCreateDao, setStateString_dateCreateDao] = useState<string>("");
    const handleChange_dateCreateDao = (event: any) => {
        setStateString_dateCreateDao(event.target.value)
    };

    const [stateString_dateCreateRut, setStateString_dateCreateRut] = useState<string>("");
    const handleChange_dateCreateRut = (event: any) => {
        setStateString_dateCreateRut(event.target.value)
    };

    // Chọn xem khách hàng theo time frame ++++++++++++++++++++++++++++++++++++++++++++++++++++++
    const [stateString_timeFrame, setStateString_timeFrame] = useState<string>("Tuần này");
    const handleChange_timeFrame = async (event: any) => {
        const timeFrame = event.target.value;

        setStateString_timeFrame(timeFrame);
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return
        }
        const listMonthYear = [
            "12-2023",
            "01-2024",
            "02-2024",
            "03-2024",
            "04-2024"
        ]
        const token = userExist.token;

        let start = ""
        let end = ""
        if (timeFrame === "Hôm nay") {
            start = getCurrentDateYYYYMMDD();
            end = addOneDay(start);
        }
        else if (timeFrame === "Tuần này") {
            let obj = getStartAndEndOfWeek();
            start = obj.startOfWeek;
            end = obj.endOfWeek;
        }
        else if (timeFrame === "Tháng này") {
            let obj = getFirstAndLastDayOfMonth();
            start = obj.firstDayOfMonth;
            end = obj.lastDayOfMonth;
        }
        else if (timeFrame === "Năm nay") {
            let obj = getFirstAndLastDayOfYear();
            start = obj.firstDayOfYear;
            end = obj.lastDayOfYear;
        }
        else if (listMonthYear.includes(timeFrame)) {
            const { month, year } = splitMonthYear(timeFrame)
            const { firstDay, lastDay } = getFirstAndLastDayOfMonthOfYear(month, year);

            start = firstDay;
            end = lastDay;
        }
        else if (timeFrame === "2024" || timeFrame === "2025") {
            const { firstDay, lastDay } = getFirstAndLastDayOfYearParameter(timeFrame);
            start = firstDay;
            end = lastDay;
        }
        // console.log('start: ', start);
        // console.log('end: ', end);
        setStateString_startTime(start);
        setStateString_endTime(end);

        getKhachHangData(start, end, stateString_selectedGroup);

    };
    // TÊN MÁY POS ++++++++++++++++++++++++++++++++++++++++++++++++++++++

    const [stateString_mayPosId, setStateString_mayPosId] = useState("empty");
    const handleChange_mayPosId = (event: any) => {
        const value = event.target.value;
        // console.log('value: ', value);
        setStateString_mayPosId(value)
    };
    const [stateString_chuyenDen, setStateString_chuyenDen] = useState("STK thẻ tín dụng");
    const handleChange_chuyenDen = (event: any) => {
        const value = event.target.value;
        setStateString_chuyenDen(value)
    };
    // tên trên thẻ
    const [stateString_tenTrenThe, setStateString_tenTrenThe] = useState("");
    const handleInputChange_tenTrenThe = (event: any) => {
        const value = event.target.value;
        // console.log('value: ', value);
        setStateString_tenTrenThe(value)
    };

    // ghi chú CSKH
    const [stateString_ghichu, setStateString_ghichu] = useState("");

    // hanmucthetindung
    const [stateNumber_hanmucthetindung, setStateNumber_hanmucthetindung] = useState<number>(0);
    const [stateNumber_hanmucthetindung_makeup, setStateNumber_hanmucthetindung_makeup] = useState<string>("");
    const handleChange_hanmucthetindung = (event: any) => {
        let value = event.target.value;
        value = value.replace(/[,.]/g, ''); // khi ô input truyền lên thì ô input là kiểu text, mà có dấu phẩy thoudsand separator nên mình phải bỏ dấu phẩy
        if (isNaN(+value) === true) { // nếu ko phải là số thì...
            alert("Bạn không được nhập chữ vào ô này !");
            let afterRemoveLastLetter = value.slice(0, -1); // Tức bỏ cái chữ cuối cùng mới nhập vào, chỉ lấy trước nó thì dụ đang nhập 456 mà nhập 456a thì nó bỏ chữ a và giữ lại 456
            setStateNumber_hanmucthetindung(parseInt(afterRemoveLastLetter + ""));
            setStateNumber_hanmucthetindung_makeup(formatThoudsand(afterRemoveLastLetter));
            return;
        }
        const moneyToString = formatThoudsand(value); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNumber_hanmucthetindung(parseInt(value + ""));
        setStateNumber_hanmucthetindung_makeup(moneyToString);
    };

    // soTaiKhoanTheTinDung
    const inputRef_soTaiKhoanTheTinDung = useRef<HTMLInputElement | null>(null);
    const [stateString_soTaiKhoanTheTinDung, setStateString_soTaiKhoanTheTinDung] = useState("");
    const handleInputChange_soTaiKhoanTheTinDung = (event: any) => {
        setStateString_soTaiKhoanTheTinDung(event.target.value);
    };
    // sotaikhoannganhang
    const inputRef_sotaikhoannganhang = useRef<HTMLInputElement | null>(null);
    const [stateString_sotaikhoannganhang, setStateString_sotaikhoannganhang] = useState("");
    const handlechange_sotaikhoannganhang = (event: any) => {
        setStateString_sotaikhoannganhang(event.target.value);
    };
    // tenNganHangChuyenTien
    const inputRef_tenNganHangChuyenTien = useRef<HTMLInputElement | null>(null);
    const [stateString_tenNganHangChuyenTien, setStateString_tenNganHangChuyenTien] = useState("ABBANK");
    const handlechange_tenNganHangChuyenTien = (event: any) => {
        let value = event.target.value;
        setStateString_tenNganHangChuyenTien(event.target.value);
    };
    // Ghi chú từ team Rút đáo
    const [stateString_ghichurutdao, setStateString_ghichurutdao] = useState("");
    const handlechange_ghichurutdao = (event: any) => {
        setStateString_ghichurutdao(event.target.value);
    };
    // Ghi chú phí GD
    const [stateString_ghichuphigiaodich, setStateString_ghichuphigiaodich] = useState("");
    const handlechange_ghichuphigiaodich = (event: any) => {
        setStateString_ghichuphigiaodich(event.target.value);
    };
    // nhanvienship
    const [stateString_nhanvienship, setStateString_nhanvienship] = useState("");
    const handleChange_nhanvienship = (event: any) => {
        setStateString_nhanvienship(event.target.value);
    };
    // sothetindung
    const inputRef_sothetindung = useRef<HTMLInputElement | null>(null);
    const [stateBool_isValid_sothetindung, setStateBool_isValid_sothetindung] = useState(true);
    const [stateString_sothetindung, setStateString_sothetindung] = useState("");
    const [stateString_sothetindung_makeup, setStateString_sothetindung_makeup] = useState("");

    const handlechange_sothetindung = (event: any) => {
        const value = event.target.value;
        let stringWithSpace = formatCreditCardNumber(value)
        setStateString_sothetindung_makeup(stringWithSpace)
        let result = isValidCreditCard(value);
        if (result === true) { // case hợp lệ
            setStateBool_isValid_sothetindung(false); // thì ẩn màu đi
        }
        else { // case soThe ko hợp lệ
            setStateBool_isValid_sothetindung(true); // thì hiện màu lên
        }
        setStateString_sothetindung(value);
    };

    const removeLastDigitSoThe = () => {
    
        if (stateString_sothetindung.length > 0) {
            // Slice off the last character
            const newString = stateString_sothetindung.slice(0, -1);
            setStateString_sothetindung(newString);
            // khi bỏi 1 chữ cái vuối thì chắc chắn soThe ko hợp lệ
            setStateBool_isValid_sothetindung(true); // thì hiện màu lên

            let stringWithSpace = formatCreditCardNumber(newString)
            setStateString_sothetindung_makeup(stringWithSpace)
            let result = isValidCreditCard(newString);
            if (result === true) { // case hợp lệ
                setStateBool_isValid_sothetindung(false); // thì ẩn màu đi
            }
            else { // case soThe ko hợp lệ
                setStateBool_isValid_sothetindung(true); // thì hiện màu lên
            }
            setStateString_sothetindung(newString);
        }
    
    }

    // solo
    const [stateString_solo, setStateString_solo] = useState("");
    const handlechange_solo = (event: any) => {
        setStateString_solo(event.target.value);
    };
    // solo
    const [stateString_nhucau, setStateString_nhucau] = useState("Rút tiền");
    const handleChange_nhucau = (event: any) => {
        const value = event.target.value;
        if (value === "Chọn...") {
            alert("Bạn vui lòng chọn Rút tiền hoặc Đáo hạn !");
            return;
        }
        // console.log('value: ', value);
        setStateString_nhucau(value);
    };
    // solo rut
    const [stateString_solo_rut, setStateString_solo_rut] = useState("");
    const handlechange_solo_rut = (event: any) => {
        setStateString_solo_rut(event.target.value);
    };

    // Team Rút đáo thông báo trạng thái
    const [stateString_teamrutdaothongbaotrangthai, setStateString_teamrutdaothongbaotrangthai] = useState("");
    const handlechange_teamrutdaothongbaotrangthai = (event: any) => {
        setStateString_teamrutdaothongbaotrangthai(event.target.value);
    };
    // Lý do của trạng thái
    const [stateString_lydocuatrangthai, setStateString_lydocuatrangthai] = useState("");
    const handlechange_lydocuatrangthai = (event: any) => {
        setStateString_lydocuatrangthai(event.target.value);
    };
    // Tên nhân viên ship:
    const [stateString_tennhanvienship, setStateString_tennhanvienship] = useState("");
    const handlechange_tennhanvienship = (event: any) => {
        setStateString_tennhanvienship(event.target.value);
    };
    // Tên nhân viên rút đáo:
    const [stateString_tennhanvienrutdao, setStateString_tennhanvienrutdao] = useState("");
    const [stateString_emailNVrutdao, setStateString_emailNVrutdao] = useState("");

    // validate ntns
    const [stateString_ntns, setStateString_ntns] = useState<string>('');
    const [stateBool_valid_ntns, setStateBool_valid_ntns] = useState(false); // boolean
    const handleChange_ntns = (event: any) => {
        const value = event.target.value;
        let result = isValidDateFormat_dd_mm_yyyy(value);
        setStateString_ntns(value);
        if (result === false) {
            setStateBool_valid_ntns(true)
        }
        else {
            setStateBool_valid_ntns(false)
        }
    }
    // validate ngày rút đáo
    const [stateString_ngayrutdao, setStateString_ngayrutdao] = useState<string>('');
    const [stateBool_valid_ngayrutdao, setStateBool_valid_ngayrutdao] = useState(false); // boolean
    const handleChange_ngayrutdao = (event: any) => {
        const value = event.target.value;
        let result = isValidDateFormatYYYY_MM_dd(value);
        setStateString_ngayrutdao(value);
        if (result === false) {
            setStateBool_valid_ngayrutdao(true)
        }
        else {
            setStateBool_valid_ngayrutdao(false)
        }
    }

    // validate cccd
    const [stateString_cccd, setStateString_cccd] = useState<string>('');
    const [stateBool_valid_cccd, setStateBool_valid_cccd] = useState(false); // boolean
    const handleChange_cccd = (event: any) => {
        const value = event.target.value;
        let resultCCCD = validateCCCD(value);
        // let resultCMND = validateCMND(value);

        setStateString_cccd(value);
        if (resultCCCD === false) {
            // if(resultCMND === false){

            //     setStateBool_valid_cccd(true); // true nghĩa là bật class wrong lên
            // }
            // else {
            //     setStateBool_valid_cccd(false); // tắt class wrong đi, , trường hợp này họ nhập CMND
            //     setStateString_cccd(value);
            // }
            setStateBool_valid_cccd(true);
            // setStateString_cccd(value);
        }
        else {
            setStateBool_valid_cccd(false); // // tắt class wrong đi, , trường hợp này họ nhập CCCD
            // setStateString_cccd(value);
        }
    }

    const [stateString_navigation_nhucau, setStateString_navigation_nhucau] = useState("All"); // string
    const handleChange_navigation_nhucau = (e: any) => {
        const nhucau = e.target.value;

        setStateString_navigation_nhucau(nhucau);
        filterGD(nhucau, stateString_navigation_teamrutdaothongbaotrangthai, stateString_navigation_myWork_giaodich);

    }
    // const [stateString_navigation_myWork, setStateString_navigation_myWork] = useState("All"); // string
    // const handleChange_navigation_myWork = (e: any) => {
    //     const myWork = e.target.value;

    //     setStateString_navigation_myWork(myWork);

    //     filterKH(myWork);

    // }

    const [stateBool_switchChecked, setStateBool_switchChecked] = useState(false); // boolean

    const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        // console.log('value: ', value);
        setStateBool_switchChecked(value);
        if (value === false) {
            filterKH("All");
        }
        else if (value === true) {
            filterKH("Tôi tạo");
        }
    };
    const [stateString_navigation_myWork_giaodich, setStateString_navigation_myWork_giaodich] = useState("All"); // string
    const handleChange_navigation_myWork_giaodich = (e: any) => {
        const myWork = e.target.value;
        // console.log('myWork: ', myWork);
        setStateString_navigation_myWork_giaodich(myWork);

        filterGD(stateString_navigation_nhucau, stateString_navigation_teamrutdaothongbaotrangthai, myWork);

    }
    // chỗ chọn filter khách hàng
    const [stateString_navigation_teamrutdaothongbaotrangthai, setStateString_navigation_teamrutdaothongbaotrangthai] = useState("All"); // string
    const handleChange_navigation_teamrutdaothongbaotrangthai = async (e: any) => {
        const trangThai = e.target.value;
        // console.log('trangThai: ', trangThai);
        setStateString_navigation_teamrutdaothongbaotrangthai(trangThai);

        filterGD(stateString_navigation_nhucau, trangThai, stateString_navigation_myWork_giaodich);
    }

    const filterKH = (myWork: string) => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return
        }

        let email = userExist.email;
        // console.log('myWork: ', myWork);
        // console.log('email: ', email);
        let searchedKhachhang = [];
        if (myWork === "All") {
            searchedKhachhang = stateArr_khachHangByNguonKH;
        }
        else if (myWork === "Tôi tạo") {
            searchedKhachhang = stateArr_khachHangByNguonKH.filter(element => element.giaodich.some((obj: any) =>
                obj.emailNVCSKHCreate.toLowerCase() === email.toLowerCase() ||
                obj.emailNVRutdaoCreate.toLowerCase() === email.toLowerCase()
            )
            );
        }
        else if (myWork === "Tôi sửa") {
            searchedKhachhang = stateArr_khachHangByNguonKH.filter(element => element.giaodich.some((obj: any) =>
                obj.emailNVCSKHEdit.toLowerCase() === email.toLowerCase() ||
                obj.emailNVRutdaoEdit.toLowerCase() === email.toLowerCase()
            )
            );
        }
        setStateArr_khachHangByNguonKHShow(searchedKhachhang);
    }
    const filterGD = (nhucau: string, trangThai: string, myWork: string) => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return;
        }

        let email = userExist.email;
        // console.log('----------------------------------------');
        // console.log('nhucau: ', nhucau);
        // console.log('trangthai: ', trangThai);
        // console.log('myWork: ', myWork);
        // console.log('email: ', email);

        // Check if stateArr_giaoDichOfSelectedKH is an array
        if (!Array.isArray(stateArr_giaoDichOfSelectedKH)) {
            // console.error('stateArr_giaoDichOfSelectedKH is not an array');
            return;
        }

        let searchedGiaodich = [];
        // console.log('stateArr_giaoDichOfSelectedKH: ', stateArr_giaoDichOfSelectedKH);
        // ---------------------------------------------------------
        if (myWork === 'All' && nhucau === 'All' && trangThai === 'All') {
            searchedGiaodich = stateArr_giaoDichOfSelectedKH;
        }
        else if (myWork === 'All' && nhucau === 'All' && trangThai !== 'All') {
            searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
            (
                element.teamrutdaothongbaotrangthai.toLowerCase() === trangThai.toLowerCase()
            )
            );
        }
        else if (myWork === 'All' && nhucau !== 'All' && trangThai === 'All') {
            searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
            (
                element.nhucau.toLowerCase() === nhucau.toLowerCase()
            )
            );
        }
        else if (myWork === 'All' && nhucau !== 'All' && trangThai !== 'All') {
            searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
            (
                element.teamrutdaothongbaotrangthai.toLowerCase() === trangThai.toLowerCase() &&
                element.nhucau.toLowerCase() === nhucau.toLowerCase()
            )
            );
        }
        else if (myWork !== 'All' && nhucau === 'All' && trangThai === 'All') {
            if (myWork == "Tôi tạo") {

                searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
                (
                    element.emailNVCSKHCreate.toLowerCase() === email.toLowerCase() ||
                    element.emailNVRutdaoCreate.toLowerCase() === email.toLowerCase()
                )
                );
            }
            else if (myWork == "Tôi sửa") {
                searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
                (
                    element.emailNVCSKHEdit.toLowerCase() === email.toLowerCase() ||
                    element.emailNVRutdaoEdit.toLowerCase() === email.toLowerCase()
                )
                );
            }
        }
        else if (myWork !== 'All' && nhucau === 'All' && trangThai !== 'All') {
            if (myWork == "Tôi tạo") {

                searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
                (
                    element.teamrutdaothongbaotrangthai.toLowerCase() === trangThai.toLowerCase() &&
                    (
                        element.emailNVCSKHCreate.toLowerCase() === email.toLowerCase() ||
                        element.emailNVRutdaoCreate.toLowerCase() === email.toLowerCase()
                    )
                )
                );
            }
            else if (myWork == "Tôi sửa") {
                searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
                (
                    element.teamrutdaothongbaotrangthai.toLowerCase() === trangThai.toLowerCase() &&
                    (
                        element.emailNVCSKHEdit.toLowerCase() === email.toLowerCase() ||
                        element.emailNVRutdaoEdit.toLowerCase() === email.toLowerCase()
                    )
                )
                );
            }
        }
        else if (myWork !== 'All' && nhucau !== 'All' && trangThai === 'All') {
            if (myWork == "Tôi tạo") {

                searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
                (
                    element.nhucau.toLowerCase() === nhucau.toLowerCase() &&
                    (
                        element.emailNVCSKHCreate.toLowerCase() === email.toLowerCase() ||
                        element.emailNVRutdaoCreate.toLowerCase() === email.toLowerCase()
                    )
                )
                );
            }
            else if (myWork == "Tôi sửa") {
                searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
                (
                    element.nhucau.toLowerCase() === nhucau.toLowerCase() &&
                    (
                        element.emailNVCSKHEdit.toLowerCase() === email.toLowerCase() ||
                        element.emailNVRutdaoEdit.toLowerCase() === email.toLowerCase()
                    )
                )
                );
            }
        }
        else if (myWork !== 'All' && nhucau !== 'All' && trangThai !== 'All') {
            if (myWork == "Tôi tạo") {

                searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
                (
                    element.nhucau.toLowerCase() === nhucau.toLowerCase() &&
                    element.teamrutdaothongbaotrangthai.toLowerCase() === trangThai.toLowerCase() &&
                    (
                        element.emailNVCSKHCreate.toLowerCase() === email.toLowerCase() ||
                        element.emailNVRutdaoCreate.toLowerCase() === email.toLowerCase()
                    )
                )
                );
            }
            else if (myWork == "Tôi sửa") {
                searchedGiaodich = stateArr_giaoDichOfSelectedKH.filter(element =>
                (
                    element.nhucau.toLowerCase() === nhucau.toLowerCase() &&
                    element.teamrutdaothongbaotrangthai.toLowerCase() === trangThai.toLowerCase() &&
                    (
                        element.emailNVCSKHEdit.toLowerCase() === email.toLowerCase() ||
                        element.emailNVRutdaoEdit.toLowerCase() === email.toLowerCase()
                    )
                )
                );
            }
        }
        // ---------------------------------------------------------
        // console.log('searchedGiaodich: ', searchedGiaodich);

        // ánh xạ qua thẻ sau khi đã tìm thấy các giao dịch thông qua filter, mỗi giao dịch ứng với 1 thẻ
        // vì vậy ta có đc số thẻ tương ứng với các filter
        // console.log('stateArr_theOfSelectedKH filterGD: ', stateArr_theOfSelectedKH);
        let arrThe = [];
        if (searchedGiaodich.length > 0) {
            for (let obj of searchedGiaodich) {
                // console.log('obj: ', obj);
                let idGiaodich = obj.id;
                // console.log('idGiaodich of searchedGiaodich: ', idGiaodich);
                const foundObj = stateArr_theOfSelectedKH.find((item) => item.idGiaodich === idGiaodich); //lấy một object đầu tiên được tìm thấy mà thoả điều kiện
                // console.log('foundObj: ', foundObj);
                arrThe.push(foundObj);
            }
            arrThe.sort((a: any, b: any) => a.id - b.id);
            setStateArr_theOfSelectedKH_show(arrThe)
            // console.log('arrThe 1090: ', arrThe);
            setStateArr_giaoDichOfSelectedKH_show(searchedGiaodich);
        }
        else {
            setStateArr_theOfSelectedKH_show([])
            setStateArr_giaoDichOfSelectedKH_show([]);

        }
    }


    const save_soLo_rut = async (index: number, idRut: number) => {

        let token = stateObjSignIn.token;
        let arrRut = stateArr_rut_of_giaodich_selected;
        let soLoValue = stateObj_groupInput_solo_rut[index];
        arrRut[index].soLo = soLoValue;
        setStateArr_rut_of_giaodich_selected(arrRut); // thay giá trị trong mảng đáo, khỏi phải lấy data từ server lại và đỡ phải render

        let data = {
            soLo: soLoValue
        }
        // console.log('soLoValue: ', soLoValue);
        // console.log('token: ', token);
        // console.log('data: ', data);
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.updateRutById_partial(token, idRut, data);
            // console.log('response.data: ', response.data);  
            if (response.data.success === true) {
                // alert("save số Lô thành công !");
                // selectGiaodich(stateNum_idGiaodich);
                setStateStringAlertMessage("Lưu số Lô thành công");
                setStateBoolAlert(true);

            }
        } catch (err) { 
            console.log('err:', err);
        }
    }
    const save_soBill_rut = async (index: number, idRut: number) => {

        let token = stateObjSignIn.token;
        let arrRut = stateArr_rut_of_giaodich_selected;

        let soBillValue = stateObj_groupInput_sobill_rut[index];
        arrRut[index].soBill = soBillValue;
        setStateArr_rut_of_giaodich_selected(arrRut); // thay giá trị trong mảng đáo, khỏi phải lấy data từ server lại và đỡ phải render

        let data = {
            soBill: soBillValue
        }
        // console.log('soBillValue: ', soBillValue);
        // console.log('token: ', token);
        // console.log('data: ', data);
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.updateRutById_partial(token, idRut, data);
            // console.log('response.data: ', response.data);  
            if (response.data.success === true) {
                // alert("save số Bill thành công !");
                // selectGiaodich(stateNum_idGiaodich);
                setStateStringAlertMessage("Lưu số Bill thành công");
                setStateBoolAlert(true);
            }
        } catch (err) {
            console.log('err:', err);
        }
    }
    const save_soLo_dao = async (index: number, idDao: number) => {

        let token = stateObjSignIn.token;
        // console.log('index: ', stateObj_groupInput_solo_dao[index]);
        // console.log('idDao: ', idDao);
        let arrDao = stateArr_dao_of_giaodich_selected;

        let soLoValue = stateObj_groupInput_solo_dao[index];

        arrDao[index].soLo = soLoValue;
        setStateArr_dao_of_giaodich_selected(arrDao); // thay giá trị trong mảng đáo, khỏi phải lấy data từ server lại và đỡ phải render

        let data = {
            soLo: soLoValue
        }
        // console.log('soLoValue: ', soLoValue);
        // console.log('token: ', token);
        // console.log('data: ', data);
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.updateDaoById_partial(token, idDao, data);
            // console.log('response.data: ', response.data);  
            if (response.data.success === true) {
                // alert("save số Lô thành công !");
                // selectGiaodich(stateNum_idGiaodich);
                setStateStringAlertMessage("Lưu số Lô thành công");
                setStateBoolAlert(true);
            }
        } catch (err) {
            console.log('err:', err);
        }
    }
    const save_soBill_dao = async (index: number, idDao: number) => {

        let token = stateObjSignIn.token;
        let arrDao = stateArr_dao_of_giaodich_selected;

        let soBillValue = stateObj_groupInput_sobill_dao[index];
        arrDao[index].soBill = soBillValue;
        setStateArr_dao_of_giaodich_selected(arrDao); // thay giá trị trong mảng đáo, khỏi phải lấy data từ server lại và đỡ phải render
        let data = {
            soBill: soBillValue
        }
        // console.log('soBillValue: ', soBillValue);
        // console.log('token: ', token);
        // console.log('data: ', data);
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.updateDaoById_partial(token, idDao, data);
            // console.log('response.data: ', response.data);  
            if (response.data.success === true) {
                // alert("save số Bill thành công !");
                // selectGiaodich(stateNum_idGiaodich);
                setStateStringAlertMessage("Lưu số Bill thành công");
                setStateBoolAlert(true);
            }
        } catch (err) {
            console.log('err:', err);
        }
    }

    const [stateObj_groupInput_solo_rut, setStateObj_groupInput_solo_rut] = useState<{ [key: string]: string }>({});

    const handleInputGroupChange_solo_rut = (index: number, value: string) => {
        setStateObj_groupInput_solo_rut(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };

    // console.log('stateObj_groupInput_solo_rut: ', stateObj_groupInput_solo_rut);
    const [stateObj_groupInput_sobill_rut, setStateObj_groupInput_sobill_rut] = useState<{ [key: string]: string }>({});

    const handleInputGroupChange_sobill_rut = (index: number, value: string) => {
        setStateObj_groupInput_sobill_rut(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };
    const [stateObj_groupInput_solo_dao, setStateObj_groupInput_solo_dao] = useState<{ [key: string]: string }>({});

    const handleInputGroupChange_solo_dao = (index: number, value: string) => {
        setStateObj_groupInput_solo_dao(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };
    const [stateObj_groupInput_sobill_dao, setStateObj_groupInput_sobill_dao] = useState<{ [key: string]: string }>({});

    const handleInputGroupChange_sobill_dao = (index: number, value: string) => {
        setStateObj_groupInput_sobill_dao(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };

    const [stateObj_groupInput_createdAt_dao, setStateObj_groupInput_createdAt_dao] = useState<{ [key: string]: string }>({});
    const handleInputGroupChange_createdAt_dao = (index: number, value: string) => {
        // console.log('index: ', index);
        // console.log('value: ', value);
        setStateObj_groupInput_createdAt_dao(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };
    const [stateObj_groupInput_createdAt_rut, setStateObj_groupInput_createdAt_rut] = useState<{ [key: string]: string }>({});
    const handleInputGroupChange_createdAt_rut = (index: number, value: string) => {
        setStateObj_groupInput_createdAt_rut(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };

    const [stateString_selectedGroup, setStateString_selectedGroup] = useState<string>('Tất cả');
    const [stateArr_khachHangByNguonKH, setStateArr_khachHangByNguonKH] = useState<any[]>([]);
    const [stateArr_khachHangByNguonKHShow, setStateArr_khachHangByNguonKHShow] = useState<any[]>([]);
    const selectGroup = async (nguonKH: string) => {
        setStateString_selectedGroup(nguonKH);
        setStateBool_showGiaodichDetails(false);
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist !== null) {
            const token = userExist.token;
            try {
                let response = await collectionAPI.collectionAPI.findAllkhachhangByNguonKH(token, nguonKH);
                // console.log(`response.data ${nguonKH}: `, response.data);
                // selectKH(response.data[0].id); // cho nó chọn khách hàng mới nhất mặc định ban đầu
                setStateArr_khachHangByNguonKH(response.data); // lưu lại danh sách group nhân viên đc click vào, và giữ tạm ở đó
                setStateArr_khachHangByNguonKHShow(response.data); // hiện ra màn hình
            } catch (err) {
                console.log('err:', err);
            }
        }
    }

    const [stateString_searchText, setStateString_searchText] = useState<string>('');

    const handleChangeSeachInput = (event: any) => {
        setStateString_searchText(event.target.value);
        // Update the searchTerm state with the user input
        let searchText = event.target.value;
        let searchedContacts = stateArr_khachHangByNguonKH.filter(obj =>
            obj.fullNameKH.toLowerCase().includes(searchText.toLowerCase()) ||
            obj.phone.some((phoneObj: any) => phoneObj.phone.toLowerCase().includes(searchText.toLowerCase())) ||
            obj.cccd.toString().includes(searchText.toLowerCase())
        );
        setStateArr_khachHangByNguonKHShow(searchedContacts);
    };
    const [stateArr_giaoDichOfSelectedKH, setStateArr_giaoDichOfSelectedKH] = useState<any[]>([]);
    const [stateArr_giaoDichOfSelectedKH_show, setStateArr_giaoDichOfSelectedKH_show] = useState<any[]>([]);
    const [stateArr_theOfSelectedKH, setStateArr_theOfSelectedKH] = useState<any[]>([]);
    const [stateArr_theOfSelectedKH_show, setStateArr_theOfSelectedKH_show] = useState<any[]>([]);
    const [stateArr_STKNH_OfSelectedKH, setStateArr_STKNH_OfSelectedKH] = useState<any[]>([]);
    const [stateObj_selectedKH, setStateObj_selectedKH] = useState<Record<string, any>>({})

    const selectKH = async (id: number) => {
        // console.log('id: ', id);
        // console.log('4');

        if (id === 0) {
            // console.log("id khách hàng = 0 dòng 935");
            return;
        }

        setStateBool_showAddNewCustomer(false);
        setStateBool_showAddNewGD(false);
        setStateBool_showGiaodichDetails(false);

        setStateNumber_selectedKH_id(id);
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return
        }
        const token = userExist.token;
        try {
            // console.log('id: ', id);
            let response = await collectionAPI.collectionAPI.findKhachhangById(token, id);
            // console.log('response.data: ', response.data);
            setStateObj_selectedKH(response.data);
            let arrGiaoDich = response.data.giaodich; // lấy tất cả giao dịch của KH này
            let arrThe = response.data.the; // lấy tất cả giao dịch của KH này
            let soTaiKhoanNganHang = response.data.soTaiKhoanNganHang; // lấy tất cả giao dịch của KH này

            // console.log('arrThe: ', arrThe);

            setStateArr_STKNH_OfSelectedKH(soTaiKhoanNganHang);
            // console.log('soTaiKhoanNganHang: ', soTaiKhoanNganHang);
            // console.log('arrThe: ', arrThe);
            
            // trường hợp agent thì ngay ban đầu thẻ sẽ empty nó chỉ có idKhachnag và idGiaodich thôi, những cái khác để trống, vì vậy ko cần removeDuplicateObjects 
            const anyContainNullOrEmpty = arrThe.some((obj: any) => obj.soThe === null || obj.soThe === '');

            if(anyContainNullOrEmpty === true){
                setStateArr_theOfSelectedKH(arrThe);
                setStateArr_theOfSelectedKH_show(arrThe);
            }
            else {
                let filteredArray = removeDuplicateObjects(arrThe);
                filteredArray.sort((a: any, b: any) => a.id - b.id);
                setStateArr_theOfSelectedKH(filteredArray);
                setStateArr_theOfSelectedKH_show(filteredArray);
            }

            let arrGiaodichThat = arrGiaoDich.filter((item: any) => item.loaigiaodich === "Giao dịch thật"); // chỉ lấy những giao dịch thật mà thôi
            // console.log('tat ca giao dich cua KH nay: ', arrGiaodichThat);
            arrGiaodichThat.sort((a: any, b: any) => a.id - b.id);
            // console.log('arrGiaodichThat: ', arrGiaodichThat);
            setStateArr_giaoDichOfSelectedKH(arrGiaodichThat);
            setStateArr_giaoDichOfSelectedKH_show(arrGiaodichThat);

            setStateString_navigation_myWork_giaodich("All");
            setStateString_navigation_nhucau("All");
            setStateString_navigation_teamrutdaothongbaotrangthai("All");

        } catch (err) {
            console.log('err:', err);
        }
    }
    // ngaydukienlienhelai
    const [stateString_ngaydukienlienhelai, setStateString_ngaydukienlienhelai] = useState<string>('');
    const handleChange_ngaydukienlienhelai = (event: any) => {
        const value = event.target.value;
        setStateString_ngaydukienlienhelai(value);
    }
    // ngayhanthanhtoan
    const [stateString_ngayhanthanhtoan, setStateString_ngayhanthanhtoan] = useState<string>('');
    const [stateBool_valid_ngayhandaothe, setStateBool_valid_ngayhandaothe] = useState(false); // boolean

    const handleChange_ngayhanthanhtoan = (event: any) => {
        const value = event.target.value;
        let result = isValidDateFormat_dd_mm_yyyy(value);
        // console.log('result: ', result);
        // console.log('value: ', value);
        setStateString_ngayhanthanhtoan(value);
        if (result === false) {
            setStateBool_valid_ngayhandaothe(true)
        }
        else {
            setStateBool_valid_ngayhandaothe(false)
        }
    }

    const [stateString_noidungchuyentien, setStateString_noidungchuyentien] = useState<string>('');

    const [stateObj_giaoDichSelected, setStateObj_giaoDichSelected] = useState<Record<string, any>>({})
    const [stateBool_showGiaodichDetails, setStateBool_showGiaodichDetails] = useState(false);
    const [stateNum_idGiaodich, setStateNum_idGiaodich] = useState(0);
    const [stateArr_imagesOfSelectedGiaoDich, setStateArr_imagesOfSelectedGiaoDich] = useState<any[]>([]);
    // const [stateArr_bill_dao_of_giaodich_selected, setStateArr_bill_dao_of_giaodich_selected] = useState<any[]>([]);
    const [stateArr_dao_of_giaodich_selected, setStateArr_dao_of_giaodich_selected] = useState<any[]>([]);
    const [stateArr_rut_of_giaodich_selected, setStateArr_rut_of_giaodich_selected] = useState<any[]>([]);

    const [stateNum_maxRut, setStateNum_maxRut] = useState(0); // number
    const [stateNum_maxRut_in_dao, setStateNum_maxRut_in_dao] = useState(0); // number
    const [stateNum_maxNap, setStateNum_maxNap] = useState(0); // number

    const selectGiaodich = async (id: number) => {
        // console.log('id giao dich: ', id);
        if (id == 0 || id == null || id == undefined) {
            setStateBool_showGiaodichDetails(false);
            setStateObj_giaoDichSelected({})
            return;
        }
        // set về 0 để nó coi như là ko chọn 1 thẻ cũ nào , để nó render lại giao diện nhập thẻ như ban đầu
        // vì khi chọn 1 thẻ cũ nào thì id sẽ # 0 nên nó sẽ render ra giao diện ko cho sửa đ61i với thẻ cũ
        setStateNum_theTinDungId(0);

        // if(stateNumber_selectedKH_id === undefined || stateNumber_selectedKH_id === 0 || stateNumber_selectedKH_id === null){
        //     setStateNumber_selectedKH_id(1);
        // }
        setStateArr_dao_of_giaodich_selected([]);
        setStateArr_rut_of_giaodich_selected([]);
        setStateString_solo(""); // cho nó về "" ban đầu
        setStateString_solo_rut(""); // cho nó về "" ban đầu
        setStateNum_idGiaodich(id); // bắt cái idGiaodich để hiện màu xanh cho giao dịch click vào
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        let objGiaoDichSelected: { [key: string]: any } = {};
        if (userExist === null) {
            return
        }
        const token = userExist.token;

        try {
            // console.log('id giao dich: ', id);

            let response = await collectionAPI.collectionAPI.findGiaoDichById(token, id);
            // console.log('response.data.data: ', response.data);
            // console.log('response: ', response);
            // console.log('response.data: ', response.data);
            // console.log('response.data.data: ', response.data.data);
            objGiaoDichSelected = response.data.data;

            // console.log('objGiaoDichSelected: ', objGiaoDichSelected);
            if (objGiaoDichSelected === undefined || objGiaoDichSelected === null) {
                alert("Vui lòng chọn một giao dịch trong danh sách, xin cảm ơn.")
                return;
            }
            // console.log('objGiaoDichSelected: ', objGiaoDichSelected);
            let lengthOfDao = objGiaoDichSelected.dao.length;
            let lengthOfRut = objGiaoDichSelected.rut.length;
            // console.log('lengthOfDao: ', lengthOfDao);
            // console.log('lengthOfRut: ', lengthOfRut);
            // console.log('objGiaoDichSelected: ', objGiaoDichSelected);
            if (lengthOfDao > 0) {
                let arrDao = objGiaoDichSelected.dao;
                arrDao.sort((a: any, b: any) => a.id - b.id);
                setStateArr_dao_of_giaodich_selected(arrDao);
                let lastIndex = arrDao.length - 1;
                let tienNapConLai = arrDao[lastIndex].tienNapConLai;
                if (Number.isNaN(tienNapConLai)) {
                    tienNapConLai = 0
                }
                setStateNum_maxNap(tienNapConLai);

                let tienRutConLai = arrDao[lastIndex].tienRutConLai;
                if (Number.isNaN(tienRutConLai)) {
                    tienRutConLai = 0
                }
                setStateNum_maxRut_in_dao(tienRutConLai);

                // lấy giá trị số lô cho vào object stateObj_groupInput_solo_rut và stateObj_groupInput_sobill_rut
                let obj_solo: { [key: string]: string } = {};
                let obj_sobill: { [key: string]: string } = {};
                let obj_createdAt: { [key: string]: string } = {};

                for (let i = 0; i < arrDao.length; i++) {

                    let soLo = arrDao[i].soLo;
                    let soBill = arrDao[i].soBill;
                    let createdAt = arrDao[i].createdAt.split("T")[0];

                    obj_solo[i] = soLo;
                    obj_sobill[i] = soBill;
                    obj_createdAt[i] = createdAt;
                }
                setStateObj_groupInput_solo_dao(obj_solo);
                setStateObj_groupInput_sobill_dao(obj_sobill);
                setStateObj_groupInput_createdAt_dao(obj_createdAt);
            }
            if (lengthOfDao === 0) {
                // if(stateNumber_tienrutdao === 0){
                //     alert("Vui lòng chọn lại giao dịch muốn thực hiện để hệ thống load lại dữ liệu, xin cảm ơn.");
                //     return;
                // }
                setStateNum_maxNap(objGiaoDichSelected.tienrutdao);
                setStateNum_maxRut_in_dao(0);
            }

            if (objGiaoDichSelected.teamrutdaothongbaotrangthai !== "Hủy") {

                if (lengthOfDao === 0 && lengthOfRut === 0) {
                    setStateArr_dao_of_giaodich_selected([]);
                    let response = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, id, "teamrutdaothongbaotrangthai", "Chờ");
                    setStateString_teamrutdaothongbaotrangthai("Chờ");
                }
            }
            // console.log('----------------------------------------',);
            // console.log('lengthOfRut: ', lengthOfRut);
            if (lengthOfRut > 0) {
                let arrRut = objGiaoDichSelected.rut;
                // console.log('arrRut: ', arrRut);
                arrRut.sort((a: any, b: any) => a.id - b.id);
                // console.log('arrRut: ', arrRut);
                setStateArr_rut_of_giaodich_selected(arrRut);
                let lastIndex = arrRut.length - 1;
                let tienConLai = arrRut[lastIndex].tienConLai;
                setStateNum_maxRut(tienConLai);

                // lấy giá trị số lô cho vào object stateObj_groupInput_solo_rut và stateObj_groupInput_sobill_rut
                let obj_solo: { [key: string]: string } = {};
                let obj_sobill: { [key: string]: string } = {};
                let obj_createdAt: { [key: string]: string } = {};

                for (let i = 0; i < arrRut.length; i++) {

                    let soLo = arrRut[i].soLo;
                    let soBill = arrRut[i].soBill;
                    let createdAt = arrRut[i].createdAt.split("T")[0];
                    obj_solo[i] = soLo;
                    obj_sobill[i] = soBill;
                    obj_createdAt[i] = createdAt;
                }
                // console.log('obj_createdAt rut: ', obj_createdAt);

                setStateObj_groupInput_solo_rut(obj_solo);
                setStateObj_groupInput_sobill_rut(obj_sobill);
                setStateObj_groupInput_createdAt_rut(obj_createdAt);
            }
            if (lengthOfRut === 0) {
                // if(stateNumber_tienrutdao === 0){
                //     alert("Vui lòng chọn lại giao dịch muốn thực hiện để hệ thống load lại dữ liệu, xin cảm ơn.");
                //     return;
                // }
                setStateNum_maxRut(objGiaoDichSelected.tienrutdao);
            }

            setStateObj_giaoDichSelected(objGiaoDichSelected);
            let arrHinhAnhOfSelectedGiaodich: any = [];
            objGiaoDichSelected.hinhanh.forEach((item: any, index: any) => {

                let objHinh = {
                    id: item.id,
                    signedUrl: item.signedUrl[0],
                    giaodichId: item.giaodichId,
                    mediaId: item.mediaId
                }
                arrHinhAnhOfSelectedGiaodich.push(objHinh);

            });
            setStateArr_imagesOfSelectedGiaoDich(arrHinhAnhOfSelectedGiaodich);

            // console.log('objGiaoDichSelected: ', objGiaoDichSelected);
            await wait(100);
            const khachhang = objGiaoDichSelected.khachhang;
            setStateBool_showGiaodichDetails(true);
            // lấy các thông tin cũ gán cho state
            setStateString_tenkhachhang(khachhang.fullNameKH);
            setStateString_nhucau(objGiaoDichSelected.nhucau);

            const ngayTaoGiaoDich = objGiaoDichSelected?.createdAt?.split("T")[0];

            setStateString_dateCreateGD(ngayTaoGiaoDich);

            const fullNameNVRutDao = userExist.fullName;
            const emailNVRutDao = userExist.email;
            setStateString_tennhanvienrutdao(fullNameNVRutDao);
            setStateString_emailNVrutdao(emailNVRutDao);
            setStateString_tennganhang(objGiaoDichSelected.nganhangId);
            setStateNumber_tienrutdao(objGiaoDichSelected.tienrutdao);
            setStateNumber_phirutdao(objGiaoDichSelected.phirutdao);
            // console.log('objGiaoDichSelected.tienphi: ', objGiaoDichSelected.tienphi);
            setStateNumber_tienphi(objGiaoDichSelected.tienphi);
            setStateNumber_tienChuyenKhach(objGiaoDichSelected.tienChuyenKhach);

            setStateNumber_tienship(objGiaoDichSelected.tienship);
            setStateString_ghichurutdao(objGiaoDichSelected.ghichurutdao);
            setStateString_ghichuphigiaodich(objGiaoDichSelected.ghiChuPhiGiaoDich);
            setStateString_ghichu(objGiaoDichSelected.ghichucskh);
            // setStateString_ngayrutdao(objGiaoDichSelected.ngayrutdao);
            // console.log('objGiaoDichSelected.teamrutdaothongbaotrangthai: ', objGiaoDichSelected.teamrutdaothongbaotrangthai);
            setStateString_teamrutdaothongbaotrangthai(objGiaoDichSelected.teamrutdaothongbaotrangthai);
            // let event = {
            //     target : {
            //         value: ""
            //     }
            // }
            // console.log('event: ', event);
            // event.target.value = objGiaoDichSelected.the[0].loaiThe
            // handleChange_loaithe(event);
            // let loaiThe = objGiaoDichSelected.the[0].loaiThe;
            // setStateString_loaithe(loaiThe);
            // console.log('objGiaoDichSelected.the.loaiThe: ', objGiaoDichSelected.the[0].loaiThe);
            setStateString_chinhanh(objGiaoDichSelected.chinhanh);
            setStateString_tenkhachhang(khachhang.fullNameKH)
            setStateString_chinhanh(objGiaoDichSelected.tenchinhanh);
            setStateString_lydocuatrangthai(objGiaoDichSelected.teamrutdaothongbaotrangthai)

            // const ngaydukienlienhelai: string = objGiaoDichSelected!.ngaydukienlienhelai;
            // if(ngaydukienlienhelai !== null){
            //     /*start "2024-01-09T23:00:00.000Z" to "yyyy-mm-dd hh:mm" */
            //     const date = new Date(ngaydukienlienhelai);

            //     const year = date.getFullYear();
            //     const month = String(date.getMonth() + 1).padStart(2, '0');
            //     const day = String(date.getDate()).padStart(2, '0');
            //     const hours = String(date.getHours()).padStart(2, '0');
            //     const minutes = String(date.getMinutes()).padStart(2, '0');
            //     let dateConvert =  `${year}-${month}-${day} ${hours}:${minutes}`;
            //     /*finish*/
            //     //@ts-ignore
            //     // const yyyy_mm_dd_hh_mm = formatDate(ngaydukienlienhelai);
            //     // setStateString_ngaydukienlienhelai(dateConvert);
            // }
            const ngayhanthanhtoan: string = objGiaoDichSelected!.ngayhanthanhtoan;
            if (ngayhanthanhtoan !== null) {
                /*start "2024-01-09T23:00:00.000Z" to "yyyy-mm-dd hh:mm" */
                const date = new Date(ngayhanthanhtoan);

                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                // const hours = String(date.getHours()).padStart(2, '0');
                // const minutes = String(date.getMinutes()).padStart(2, '0');
                // let dateConvert =  `${year}-${month}-${day}`;
                let dateConvert = `${day}-${month}-${year}`;
                /*finish*/
                //@ts-ignore
                // const yyyy_mm_dd_hh_mm = formatDate(ngaydukienlienhelai);
                setStateString_ngayhanthanhtoan(dateConvert);
            }

            let ngayRutDao = objGiaoDichSelected.ngayrutdao;
            if (ngayRutDao === null) {
                let currentDate = getCurrentDate();
                setStateString_ngayrutdao(currentDate);
            }

            setStateString_teamrutdaothongbaotrangthai(objGiaoDichSelected.teamrutdaothongbaotrangthai);
            setStateString_lydocuatrangthai(objGiaoDichSelected.lydocuatrangthai);
            // console.log('stateObj_selectedKH: ', stateObj_selectedKH);
            let idGiaodich = id;
            let idKhachhang = khachhang.id; // nguy hiểm chỗ này, khi stateObj_selectedKH chưa kịp có

            if (idKhachhang === undefined || idKhachhang === null) {
                alert("Bạn vui lòng chọn lại khách hàng để hệ thống load lại dữ liệu, Xin cảm ơn");
                setStateBool_showGiaodichDetails(false);
                return;
            }
            if (idGiaodich === undefined || idGiaodich === null) {
                alert("Bạn vui lòng chọn lại giao dịch để hệ thống load lại dữ liệu, Xin cảm ơn");
                setStateBool_showGiaodichDetails(false);
                return;
            }
            if (objGiaoDichSelected.noiDungChuyenTien === null) {
                setStateString_noidungchuyentien(`TT ${idKhachhang} ${idGiaodich}`)
            }
            else {
                setStateString_noidungchuyentien(objGiaoDichSelected.noiDungChuyenTien)
            }

            // lấy thẻ tín dụng ra hiện lên màn hình
            const arrThe = objGiaoDichSelected.the;
            // console.log('arrThe: ', arrThe);
            /*
                "the": [
                    {
                        "id": 178,
                        "idKhachhang": 159,
                        "idGiaodich": 198,
                        "loaiThe": null, *
                        "tenTrenThe": null,*
                        "soThe": null,*
                        "soTaiKhoanNganHang": null, *  // của SoTaiKhoanCHuyenTIen
                        "tenNganHangChuyenTien": null, // của SoTaiKhoanCHuyenTIen
                        "soTaiKhoanTheTinDung": null, *
                        "hanMucTheTinDung": null, *
                        "aiDangGiu": null, *
                        "hanDao": null, *
                        "nganhangId": null *
                    }
                ],
            */
            if (arrThe.length > 0) {
                const tenNganHangChuyenTien = arrThe[0].tenNganHangChuyenTien;
                if (tenNganHangChuyenTien !== null) {
                    setStateString_tenNganHangChuyenTien(tenNganHangChuyenTien)
                }
                else {
                    setStateString_tenNganHangChuyenTien("")
                }

                const soTheTinDung = arrThe[0].soThe;
                if (soTheTinDung !== null) {
                    let stringWithSpace = formatCreditCardNumber(soTheTinDung)
                    setStateString_sothetindung_makeup(stringWithSpace)
                    setStateString_sothetindung(soTheTinDung)
                }
                else {
                    setStateString_sothetindung_makeup("")
                    setStateString_sothetindung("")
                }
                setStateBool_isValid_sothetindung(false);

                const soTaiKhoanTheTinDung = arrThe[0].soTaiKhoanTheTinDung;
                if (soTaiKhoanTheTinDung !== null) {
                    setStateString_soTaiKhoanTheTinDung(soTaiKhoanTheTinDung);
                }
                else {
                    setStateString_soTaiKhoanTheTinDung("");
                }


                const tenTrenThe = arrThe[0].tenTrenThe;
                if (tenTrenThe !== null) {
                    setStateString_tenTrenThe(tenTrenThe);
                }
                else {
                    setStateString_tenTrenThe("");
                }
                const loaithe = arrThe[0].loaiThe;
                if (loaithe !== null) {
                    setStateString_loaithe(loaithe)
                }
                else {
                    setStateString_loaithe("")
                }

                const hanMucTheTinDung = arrThe[0].hanMucTheTinDung;
                if (hanMucTheTinDung !== null) {
                    const moneyToString_hanMucTheTinDung = formatThoudsand(hanMucTheTinDung); // chuyển thành chữ có dấu , phân cách hàng nghìn
                    setStateNumber_hanmucthetindung_makeup(moneyToString_hanMucTheTinDung);
                    setStateNumber_hanmucthetindung(hanMucTheTinDung);
                }
                else {
                    setStateNumber_hanmucthetindung_makeup("");
                    setStateNumber_hanmucthetindung(0);

                }

                const sothetindung = arrThe[0].soThe;
                if (sothetindung !== null) {
                    setStateString_sothetindung(sothetindung)
                }
                else {
                    setStateString_sothetindung("")
                }
                let aiDangGiu = arrThe[0].aiDangGiu;
                if (aiDangGiu !== null) {
                    setStateString_aiDangGiuThe(aiDangGiu);
                }
                else {
                    setStateString_aiDangGiuThe("");
                }


                const ngayhanthanhtoan: string = arrThe[0].hanDao;
                if (ngayhanthanhtoan !== null) {
                    /*start "2024-01-09T23:00:00.000Z" to "yyyy-mm-dd hh:mm" */
                    const date = new Date(ngayhanthanhtoan);
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    // const hours = String(date.getHours()).padStart(2, '0');
                    // const minutes = String(date.getMinutes()).padStart(2, '0');
                    // let dateConvert =  `${year}-${month}-${day}`;
                    let dateConvert = `${day}-${month}-${year}`;
                    /*finish*/
                    //@ts-ignore
                    // const yyyy_mm_dd_hh_mm = formatDate(ngaydukienlienhelai);
                    setStateString_ngayhanthanhtoan(dateConvert);
                }
                else {
                    setStateString_ngayhanthanhtoan("");
                }

                const nganhangId = arrThe[0].nganhangId;
                if (nganhangId !== null) {
                    setStateString_tennganhang(nganhangId);
                }
                else {
                    setStateString_tennganhang("");
                }
            }
            else {
                setStateString_loaithe("")
                setStateString_soTaiKhoanTheTinDung("");
                setStateString_sotaikhoannganhang("");
                setStateString_tenTrenThe("");
                setStateString_sothetindung("");
                setStateString_sothetindung_makeup("");
                setStateNumber_hanmucthetindung(0);
                setStateNumber_hanmucthetindung_makeup("")
            }
            let arrSTKNH = objGiaoDichSelected.soTaiKhoanNganHang;
            if (arrSTKNH.length > 0) {

                const soTaiKhoanNganHang = arrSTKNH[0].soTaiKhoanNganHang;
                setStateString_sotaikhoannganhang(soTaiKhoanNganHang);
                const tenNganHangChuyenTien = arrSTKNH[0].tenNganHangChuyenTien;
                setStateString_tenNganHangChuyenTien(tenNganHangChuyenTien);
            }

            // lấy tiền rút đáo hiện ra màn hình
            const tienrutdao = objGiaoDichSelected.tienrutdao;
            const moneyToString_tienrutdao = formatThoudsand(tienrutdao); // chuyển thành chữ có dấu , phân cách hàng nghìn
            setStateNumber_tienrutdao_makeup(moneyToString_tienrutdao);
            setStateNumber_tienrutdao(tienrutdao);

            //lấy tiền phí hiện ra
            // setStateNumber_tienphi(objGiaoDichSelected.tienphi)

            // // tính luôn tiền phí và tiền chuyển khách
            // let tienphi = Math.floor((tienrutdao * objGiaoDichSelected.phirutdao) / 100);
            // setStateNumber_tienphi(tienphi);
            // let tienChuyenKhach = objGiaoDichSelected.tienrutdao - tienphi;
            // setStateNumber_tienChuyenKhach(tienChuyenKhach);

            // KHÁCH HÀNG-----------------------------------------
            setStateString_address(khachhang.address);
            setStateString_cccd(khachhang.cccd);
            // let ntns_withHh_mm = stateObj_giaoDichSelected.khachhang.ntns;
            // let ntns = ntns_withHh_mm.split('T')[0]
            // setStateString_ntns(ntns);
            const ntns: string = khachhang.ntns;
            if (ntns !== null) {
                /*start "2024-01-09T23:00:00.000Z" to "yyyy-mm-dd hh:mm" */
                const date = new Date(ntns);

                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                // let dateConvert =  `${year}-${month}-${day}`;
                let dateConvert = `${day}-${month}-${year}`;
                /*finish*/
                //@ts-ignore
                // const yyyy_mm_dd_hh_mm = formatDate(ngaydukienlienhelai);
                setStateString_ntns(dateConvert);

            }

            setNameCitiSelected(khachhang.city);

            setNameDistrictSelected(khachhang.district);
            setNameWardSelected(khachhang.ward);

            // console.log('--------------------------------------------');
            // console.log('stateArr_dao_of_giaodich_selected: ', stateArr_dao_of_giaodich_selected);
            // console.log('stateArr_rut_of_giaodich_selected: ', stateArr_rut_of_giaodich_selected);
        } catch (err) {
            console.log('err:', err);
        }

    } // end selectGiaodich

    const [stateNum_idThe, setStateNum_idThe] = useState(0);

    const selectThe = async (id: number, idThe: number) => { // id là id giao dịch
        setStateNum_idThe(idThe); // mục đích là để hiện cái màu xanh cho cái thẻ nào đc click vào
        selectGiaodich(id);

    } // end selectThe

    // console.log('actstateObj_giaoDichSelected.teamrutdaothongbaotrangthaion: ', stateObj_giaoDichSelected.teamrutdaothongbaotrangthai);
    // console.log('stateString_teamrutdaothongbaotrangthai: ', stateString_teamrutdaothongbaotrangthai);
    // console.log('stateObj_giaoDichSelected.nhucau: ', stateObj_giaoDichSelected.nhucau);
    const copyToClipboard = (textToCopy: string) => {
        // alert(`${textToCopy}`);
        navigator.clipboard.writeText(`TT ${stateNumber_selectedKH_id} ${stateNum_idGiaodich}`)
            .then(() => {
                alert(`Đã copy đoạn text ${textToCopy} vào clipboard, bạn có thể dán tùy ý`);
            })
            .catch(err => {
                alert('Unable to copy text to clipboard');
            });
    }
    const copyToClipboard_creditCard = async (textToCopy: string) => {

        let token = stateObjSignIn.token;
        let email = stateObjSignIn.email;
        let data = {
            email: email,
            creditCardNo: textToCopy
        }
        try {
            let response = await collectionAPI.collectionAPI_CreditCardCopy.creditcardcopy(token, data);
        } catch (err) {
            console.log('err:', err);
        }
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert(`Đã copy đoạn text ${textToCopy} vào clipboard, bạn có thể dán tùy ý`);
            })
            .catch(err => {
                alert('Unable to copy text to clipboard');
            });
    }
    const [stateBool_invisible_groups, setStateBool_invisible_groups] = useState(false);
    const [stateBool_contactsWrapper_2_col, setStateBool_contactsWrapper_2_col] = useState(false);
    const [stateBool_contactsWrapper_1_col, setStateBool_contactsWrapper_1_col] = useState(false);
    const invisible_groups = () => {

        setStateBool_invisible_groups(true);
        setStateBool_contactsWrapper_2_col(true);
    }
    const visible_nguonKH = () => {
        setStateBool_invisible_groups(false);
        setStateBool_contactsWrapper_2_col(false);
    }
    const [stateBool_invisible_listKHWrapper, setStateBool_invisible_listKHWrapper] = useState(false);
    const [stateBool_arrow_lastLeftArrow, setStateBool_arrow_lastLeftArrow] = useState(false);
    const invisible_groups_and_listKHWrapper = () => {
        setStateBool_arrow_lastLeftArrow(false)
        setStateBool_invisible_groups(true);
        setStateBool_invisible_listKHWrapper(true);
        setStateBool_contactsWrapper_1_col(true);
        setStateBool_contactsWrapper_2_col(false);

    }
    const show_groups_and_listKHWrapper = () => {
        setStateBool_arrow_lastLeftArrow(true);
        setStateBool_invisible_listKHWrapper(false);
        setStateBool_contactsWrapper_1_col(false);
        setStateBool_contactsWrapper_2_col(true);
        setStateBool_invisible_groups(false);

    }
    const click_rut_dao = (kind: string) => {

        if (stateNumber_selectedKH_id === null || stateNumber_selectedKH_id === undefined || stateNumber_selectedKH_id === 0) {
            alert("Bạn vui lòng chọn lại khách hàng để hệ thống load lại dữ liệu")
            return;
        }
        if (stateNum_idGiaodich === null || stateNum_idGiaodich === undefined || stateNum_idGiaodich === 0) {
            alert("Bạn vui lòng chọn lại giao dịch để hệ thống load lại dữ liệu")
            return;
        }
        setStateString_noidungchuyentien(`TT ${stateNumber_selectedKH_id} ${stateNum_idGiaodich}`)
        if (kind === "Đáo hạn") {
            setStateString_nhucau("Đáo hạn");
        }
        else {
            setStateString_nhucau("Rút tiền");
        }

    }
    // xử lý upload hình start ----------------------------------------------------------------
    const [selectedImage, setSelectedImage] = useState<File | null>(null);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
            // Check if the file size is below 500KB
            if (file.size > 500 * 1024) {
                // Alert or handle the case where the image size exceeds 500KB
                alert('Bạn vui lòng chọn hình có dung lượng dưới 500KB');
                return;
            }

            setSelectedImage(file);
        }
    };
    const handleUpload = async () => {
        if (!selectedImage) {
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedImage);
        // console.log('formData: ', formData);
        // Example of sending the formData to a backend API using fetch
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist !== null) {
            const token = userExist.token;

            try {
                // upload hình lên google cloud service storage
                let response1 = await collectionAPI.collectionAPI.uploadFile(token, formData);
                // upload xong nó sẽ trả ra cái mediaId và save vào bảng media
                const mediaId = response1.data.id;
                const objHinhGIaoDich = {
                    giaodichId: stateNum_idGiaodich,
                    mediaId: mediaId
                }
                // update cái mediaId vừa có được kèm theo giaodichId của nó vào bảng HinhGiaoDich, sau này khi lấy giao dịch nào đó theo giaodichId, nó sẽ tự kèm theo hình có signedUrl
                let response2 = await collectionAPI.collectionAPI.createHinhGiaoDich(token, objHinhGIaoDich);
                // ta update cái media vào giao dịch, phải lấy cả các mediaId cũ để truyền vào, nếu ko thì nó sẽ ghi đè
                // mỗi lần tìm giao dịch theo id, nó sẽ gửi mediaId lên GCS để tìm cái signedUrl để có đường dẫn hình hiện ra FE
                // let response2 = await collectionAPI.collectionAPI.updateGiaodichById(token, stateNum_idGiaodich, {
                //     hinhGiaoDich: [mediaId]
                // });
                await wait(1000); // phải chờ 5s để nó lưu trên GCS xong sau đó mới lấy trên đó về
                selectGiaodich(stateNum_idGiaodich);

            } catch (err) {
                console.log('err:', err);
            }
        }
    }
    // xử lý upload hình end ----------------------------------------------------------------
    const [stateBool_isOpenDialog, setStateBool_isOpenDialog] = useState(false); // boolean
    const [stateString_selectedImg, setStateString_selectedImg] = useState<string>(''); // string

    const selectImg = (imgUrl: string) => {

        setStateBool_isOpenDialog(true);
        setStateString_selectedImg(imgUrl);

    }
    const cancelDialog = () => {

        setStateBool_isOpenDialog(false);
        setStateString_selectedImg('');

    }

    const DeleteSelectedImg = async (id: number, mediaId: string) => {
        const email = stateObjSignIn.email;
        const token = stateObjSignIn.token;
        const resultCheckPermission = checkPermission(email, stateArrDeletePermission);
        if (!resultCheckPermission) {
            return;
        }
        if (window.confirm("Bạn có chắc chắn muốn xóa hình, một khi đã xóa trên hệ thống thì sẽ không thể truy hồi lại !") === false) {
            return;
        }

        try {

            let response = await collectionAPI.collectionAPI.deleteHinhGiaoDichById(token, id);
            // console.log('response: ', response);
            let response2 = await collectionAPI.collectionAPI.deleteMediaById(token, mediaId);
            // console.log('response2: ', response2);
            if (response.data.message === "Image deleted successfully") {
                selectGiaodich(stateObj_giaoDichSelected.id)
                setStateStringAlertMessage("Xóa hình thành công");
                setStateBoolAlert(true);
            }
            // selectGiaodich(giaodichId);

        } catch (err) {
            console.log('err:', err);
        }
    }

    const DeleteSelectedImg_tienPhi = async (giaodichId: number) => {
        const email = stateObjSignIn.email;
        const token = stateObjSignIn.token;
        const resultCheckPermission = checkPermission(email, stateArrDeletePermission);
        if (!resultCheckPermission) {
            return;
        }
        if (window.confirm("Bạn có chắc chắn muốn xóa hình, một khi đã xóa trên hệ thống thì sẽ không thể truy hồi lại !") === false) {
            return;
        }

        try {
            if (giaodichId === 0) {
                alert("Bạn vui lòng chọn 1 giao dịch phía trên đầu để hệ thống cập nhật lại chính xác hình muốn xóa là hình của giao dịch được chọn nhé. Cám ơn bạn");
                return;
            }
            // console.log('giaodichId: ', giaodichId);

            let response = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, giaodichId, "hinhChuyenKhoanTienPhi_mediaId", "null");
            await wait(500);
            let response2 = await collectionAPI.collectionAPI.deleteMediaById(token, stateObj_giaoDichSelected.hinhChuyenKhoanTienPhi_mediaId);
            // let response3 = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, giaodichId,"hinhChuyenKhoanTienPhi_signedUrl",null);
            // console.log('response: ', response);
            // console.log('response2: ', response2);
            if (response.status === 200) {
                alert("Xóa hình chuyển tiền thành công !")
                selectGiaodich(stateObj_giaoDichSelected.id);
                setStateStringAlertMessage("Xóa hình thành công");
                setStateBoolAlert(true);
            }

        } catch (err) {
            console.log('err:', err);
        }
    }
    const DeleteSelectedImg_cccd = async (type: string, khachhangId: number) => {
        const email = stateObjSignIn.email;
        const token = stateObjSignIn.token;
        // kiểm tra thời điểm xóa có quá 24h sau khi tạo hình ko, nếu quá thì ko cho xóa, nếu trong giới hạn thì cho xóa
        const time = stateObj_giaoDichSelected.khachhang.cccd_createdAt;
        const is24HoursLater = isNow24HoursLaterThan(time);
        // false thì là giờ hiện tại ko nhiều hơn 24 tiếng so với time
        // true thì là giờ hiện tại nhiều hơn 24 tiếng so với time
        const resultCheckPermission = checkPermission(email, stateArrDeletePermission);
        if (!resultCheckPermission) {
            return;
        }
        // else { // Đối với những email khác thì...
        //     if(is24HoursLater === true){
        //         alert("Bạn không thể xóa hình sau 24h từ thời điểm tạo hình CCCD, vui lòng nhờ Xuân hoặc Ánh để xóa hình")
        //         return;
        //     }
        // }
        if (window.confirm("Bạn có chắc chắn muốn xóa hình, một khi đã xóa trên hệ thống thì sẽ không thể truy hồi lại !") === false) {
            return;
        }

        try {
            if (khachhangId === 0) {
                alert("Bạn vui lòng chọn 1 giao dịch phía trên đầu để hệ thống cập nhật lại chính xác hình muốn xóa là hình CCCD của khách hàng sở hữu giao dịch này nhé. Cám ơn bạn");
                return;
            }
            let response1: any = "";
            let response2: any = "";
            if (type === "mt") {
                response1 = await collectionAPI.collectionAPI.updateKhachhangByIdOneField(token, khachhangId, "cccd_mt_mediaId", "null");
                response2 = await collectionAPI.collectionAPI.deleteMediaById(token, stateObj_giaoDichSelected.khachhang.cccd_mt_mediaId);
            }
            else if (type === "ms") {
                response1 = await collectionAPI.collectionAPI.updateKhachhangByIdOneField(token, khachhangId, "cccd_ms_mediaId", "null");
                response2 = await collectionAPI.collectionAPI.deleteMediaById(token, stateObj_giaoDichSelected.khachhang.cccd_ms_mediaId);
            }
            let response3 = await collectionAPI.collectionAPI.updateKhachhangByIdOneField(token, khachhangId, "cccd_createdAt", "null");

            await wait(500);
            // console.log('response1: ', response1);
            // console.log('response2: ', response2);
            if (response1.status === 200 || response2.status === 200) {
                alert("Xóa hình CCCD thành công !")
                selectGiaodich(stateObj_giaoDichSelected.id);
                setStateStringAlertMessage("Xóa hình CCCD thành công !");
                setStateBoolAlert(true);
            }

        } catch (err) {
            console.log('err:', err);
        }
    }

    // sobill
    const [stateString_sobill, setStateString_sobill] = useState("");
    const handlechange_sobill = (event: any) => {
        setStateString_sobill(event.target.value);
    };
    // sobill rut
    const [stateString_sobill_rut, setStateString_sobill_rut] = useState("");
    const handlechange_sobill_rut = (event: any) => {
        setStateString_sobill_rut(event.target.value);
    };
    const [stateNum_rut_in_dao, setStateNum_rut_in_dao] = useState<number>(0); // number
    const [stateNum_rut_in_dao_makeup, setStateNum_rut_in_dao_makeup] = useState<string>("");
    const handlechange_rut_in_dao = (event: any) => {
        let value = event.target.value;
        value = value.replace(/[,.]/g, ''); // khi ô input truyền lên thì ô input là kiểu text, mà có dấu phẩy thoudsand separator nên mình phải bỏ dấu phẩy
        if (isNaN(+value) === true) { // nếu ko phải là số thì...
            // console.log('value: ', value);
            alert("Bạn không được nhập chữ vào ô này !");
            let afterRemoveLastLetter = value.slice(0, -1); // Tức bỏ cái chữ cuối cùng mới nhập vào, chỉ lấy trước nó thì dụ đang nhập 456 mà nhập 456a thì nó bỏ chữ a và giữ lại 456
            setStateNum_rut_in_dao_makeup(formatThoudsand(afterRemoveLastLetter));
            setStateNum_rut_in_dao(parseInt(afterRemoveLastLetter + ""));
            return;
        }
        const moneyToString = formatThoudsand(value); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNum_rut_in_dao_makeup(moneyToString);

        // trường hợp khi ta delete lùi về 0 nhiều khi nó có lỗi , nên ta bắt các lỗi để ép nó về 0
        if (value === "" || isNaN(+value) || value === undefined || value === undefined) {
            setStateNum_rut_in_dao(0);
            return;
        }
        setStateNum_rut_in_dao(parseInt(value + ""));
    };
    const [stateNum_nap, setStateNum_nap] = useState<number>(0); // number
    const [stateNum_nap_makeup, setStateNum_nap_makeup] = useState<string>("");
    const handlechange_nap = (event: any) => {
        let value = event.target.value;
        value = value.replace(/[,.]/g, ''); // khi ô input truyền lên thì ô input là kiểu text, mà có dấu phẩy thoudsand separator nên mình phải bỏ dấu phẩy
        if (isNaN(+value) === true) { // nếu ko phải là số thì...
            alert("Bạn không được nhập chữ vào ô này !");
            let afterRemoveLastLetter = value.slice(0, -1); // Tức bỏ cái chữ cuối cùng mới nhập vào, chỉ lấy trước nó thì dụ đang nhập 456 mà nhập 456a thì nó bỏ chữ a và giữ lại 456
            setStateNum_nap_makeup(formatThoudsand(afterRemoveLastLetter));
            setStateNum_nap(parseInt(afterRemoveLastLetter + ""));
            return;
        }
        if (value === '') {
            value = 0;
        }
        // console.log('stateNum_nap: ', value);
        const moneyToString = formatThoudsand(value); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNum_nap_makeup(moneyToString);
        setStateNum_nap(parseInt(value + ""));
    };
    const [stateNum_rut, setStateNum_rut] = useState<number>(0); // number
    const [stateNum_rut_makeup, setStateNum_rut_makeup] = useState<string>("");
    const handlechange_rut = (event: any) => {

        let value = event.target.value;
        value = value.replace(/[,.]/g, ''); // khi ô input truyền lên thì ô input là kiểu text, mà có dấu phẩy thoudsand separator nên mình phải bỏ dấu phẩy
        if (isNaN(+value) === true) { // nếu ko phải là số thì...
            alert("Bạn không được nhập chữ vào ô này !");
            let afterRemoveLastLetter = value.slice(0, -1); // Tức bỏ cái chữ cuối cùng mới nhập vào, chỉ lấy trước nó thì dụ đang nhập 456 mà nhập 456a thì nó bỏ chữ a và giữ lại 456
            setStateNum_rut_makeup(formatThoudsand(afterRemoveLastLetter));
            setStateNum_rut(parseInt(afterRemoveLastLetter + ""));
            return;
        }
        const moneyToString = formatThoudsand(value); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNum_rut_makeup(moneyToString);
        setStateNum_rut(parseInt(value + ""));
    };
    const inputRef_solo = useRef<HTMLInputElement | null>(null);
    const inputRef_sobill = useRef<HTMLInputElement | null>(null);
    const inputRef_soRut_in_dao = useRef<HTMLInputElement | null>(null);
    const inputRef_sonap = useRef<HTMLInputElement | null>(null);

    const postDao = async () => {
        if (stateNumber_selectedKH_id === 0 || stateNumber_selectedKH_id === undefined || stateNumber_selectedKH_id === null) {
            alert("Bạn vui lòng chọn lại khách hàng để hệ thống load lại dữ liệu cho đúng");
            setStateBool_showGiaodichDetails(false);
            return;
        }
        if (stateNum_idGiaodich === 0 || stateNum_idGiaodich === undefined || stateNum_idGiaodich === null) {
            alert("Bạn vui lòng chọn 1 giao dịch để hệ thống load lại dữ liệu cho đúng");
            setStateBool_showGiaodichDetails(false);
            return;
        }

        if (window.confirm("Ban đang thực hiện giao dịch ĐÁO, bạn có muốn tiếp tục ?") === false) {
            return
        }
        setStateString_nhucau("Đáo hạn");
        if (
            stateObj_giaoDichSelected.emailNVRutdaoCreate === "" ||
            stateObj_giaoDichSelected.emailNVRutdaoCreate === null ||
            stateObj_giaoDichSelected.khachhang.cccd === ""
        ) {
            alert("Bạn vui lòng nhập và lưu các thông tin cần thiết trước khi tiến hành ĐÁO");
            return;
        }

        // trường hợp rút thì phải cần số lô số bill
        // trường hợp nạp thì ko cần số lô số bill
        if (stateNum_rut_in_dao !== 0) {
            if (stateString_solo === "" || stateString_solo === undefined || stateString_solo === null) {
                // alert("Bạn vui lòng nhập số Lô ");
                // if (inputRef_solo.current) {
                //     inputRef_solo.current.focus();
                // }
                // console.log('stateString_solo: ', stateString_solo);
                // return;
                setStateString_solo("");
            }
            if (stateString_sobill === "" || stateString_sobill === undefined || stateString_sobill === null) {
                // alert("Bạn vui lòng nhập số Bill ");
                // if (inputRef_sobill.current) {
                //     inputRef_sobill.current.focus();
                // }
                // return;
                setStateString_sobill("");
            }
            // tiền rút khi đáo ko bắt buộc phải nhập, nhưng một khi nhập thì số nhập vào phải đúng trong khoảng dưới đây
            if (stateNum_rut_in_dao <= 0 || stateNum_rut_in_dao > 900000000) {
                alert("Bạn vui lòng nhập số tiền rút > 0 hoặc nhỏ hơn 900,000,000 ");
                if (inputRef_soRut_in_dao.current) {
                    inputRef_soRut_in_dao.current.focus();
                }
                return;
            }
            // if(stateString_mediaId_dao === null || stateString_mediaId_dao === ""){
            //     alert("Bạn vui lòng kéo thả hình chụp số lô số bill trước để làm bằng chứng cho lần thêm này");
            //     return;
            // }
            if (stateString_mayPosId === "" || stateString_mayPosId === "empty" || stateString_mayPosId === undefined) {
                alert("Bạn vui lòng chọn máy pos ");
                return;
            }
        }
        if (stateNum_nap !== 0) {
            if (stateNum_nap <= 0 || stateNum_nap > 900000000) {
                alert("Bạn vui lòng nhập số tiền nạp > 0 hoặc nhỏ hơn 900,000,000");
                if (inputRef_sonap.current) {
                    inputRef_sonap.current.focus();
                }
                return;
            }
            // if(stateString_mediaId_nap === null || stateString_mediaId_nap === ""){
            //     alert("Bạn vui lòng kéo thả hình chụp nạp tiền trước để làm bằng chứng cho lần thêm này");
            //     return;
            // }
        }

        if (stateString_dateCreateDao === "") {
            alert("Vui lòng chọn ngày đáo lần này !")
            return;
        }
        let stk = "";
        // console.log('stateBool_isValid_sothetindung: ', stateBool_isValid_sothetindung);
        // console.log('stateString_soTaiKhoanTheTinDung: ', stateString_soTaiKhoanTheTinDung);
        // console.log('stateString_sothetindung: ', stateString_sothetindung);
        // console.log('stateString_chuyenDen: ', stateString_chuyenDen);
        if (stateString_chuyenDen === "STK thẻ tín dụng") {
            if (stateString_soTaiKhoanTheTinDung === '' || stateString_soTaiKhoanTheTinDung === null || stateString_soTaiKhoanTheTinDung === undefined) {
                alert("Vui lòng nhập số tài khoản thẻ tín dụng");
                if (inputRef_soTaiKhoanTheTinDung.current) {
                    inputRef_soTaiKhoanTheTinDung.current.focus();
                }
                return;
            }
            stk = stateString_soTaiKhoanTheTinDung;
        }
        else if (stateString_chuyenDen === "Số trên thẻ tín dụng") {
            // console.log('stateString_sothetindung: ', stateString_sothetindung);
            if (stateString_sothetindung === '' || stateString_sothetindung === null || stateString_sothetindung === undefined) {
                alert("Vui lòng nhập số trên thẻ tín dụng");
                if (inputRef_sothetindung.current) {
                    inputRef_sothetindung.current.focus();
                    return;
                }
                if (stateBool_isValid_sothetindung === true) {
                    alert("Số thẻ tín dụng chưa hợp lệ, vui lòng kiểm tra lại");
                    return;
                }
            }
            stk = stateString_sothetindung;
        }
        let mayPost = null;
        if (stateString_mayPosId !== "empty") {
            mayPost = stateString_mayPosId;
        }
        const token = stateObjSignIn.token;
        const email = stateObjSignIn.email;
        const empId = stateObjSignIn.id;
        try {
            // let responseSumNapRutByGiaodichId = await collectionAPI.collectionAPI.calculateSumTienNapAndTienRutByGiaodichId(token, stateNum_idGiaodich);
            // console.log('responseSumNapRutByGiaodichId: ', responseSumNapRutByGiaodichId);
            // let sumNap = responseSumNapRutByGiaodichId.data.sumTienNap;
            // let sumRut = responseSumNapRutByGiaodichId.data.sumTienRut;
            // let remainNap = parseInt(stateNumber_tienrutdao+"") - parseInt(sumNap+"") - parseInt(stateNum_nap+"");
            // // let remainRut = Math.abs(parseInt(sumNap+"") - parseInt(sumRut+"") + parseInt(stateNum_nap+"") - parseInt(stateNum_rut_in_dao+""));
            // let remainRut = parseInt(sumNap+"") - parseInt(sumRut+"") + parseInt(stateNum_nap+"") - parseInt(stateNum_rut_in_dao+"");

            /*
          ví dụ giải thích tính ra remainNap / remainRut
          công thức tính :
              remainNap = nạp còn lại trước - nạp hiện tại
              remainRut = rút còn lại của lần trước + nạp hiện tại - rút hiện tại 

          tiền rút đáo: 50
          lần         method          nạp         remainNap           rút                 remainRut
          1           create          20          50-20 = 30          10                  20-10 = 10
          2           create          10          30-10 = 20          5                   10(remainRut trước)+10(nạp now)-5(rút) = 15
          3           edit lần 2      12          30-12 = 18          10                  10(remainRut trước)+12(nạp now)-10(rút) = 12
          edit có nghĩa như là bỏ lần 2, và mình lấy remainNap của lần 1 - nạp lần 3 : 30-12 = 18
          và mình update cái lần 3 ghi đè vào lần 2 trong database
          => như vậy mấu chốt là mình cần lấy giá trị remainNap (30) và remainRut(10) của lần kế cuối , để mình trừ nạp/rút lần mới nhất
          mình sẽ lấy trong stateArr_dao_of_giaodich_selected ở cái obj kế cuối
          */
            const arrDao_length = stateArr_dao_of_giaodich_selected.length;
            // console.log('arrDao_length: ', arrDao_length);
            let remainNap_Cuoi = 0;
            let remainRut_Cuoi = 0;
            if (arrDao_length > 1) {
                const index_Cuoi = arrDao_length - 1; // -1 là cái index cuối
                remainNap_Cuoi = stateArr_dao_of_giaodich_selected[index_Cuoi].tienNapConLai;
                remainRut_Cuoi = stateArr_dao_of_giaodich_selected[index_Cuoi].tienRutConLai;
                // console.log('index_keCuoi: ', index_keCuoi);
            }
            else if (arrDao_length === 1) { // nếu mảng chỉ có 1 ptu thì lấy chính nó luôn, sửa cái đầu tiên
                remainNap_Cuoi = stateArr_dao_of_giaodich_selected[0].tienNapConLai;
                remainRut_Cuoi = stateArr_dao_of_giaodich_selected[0].tienRutConLai;
            }
            else if (arrDao_length === 0) { // khi đáo lần đầu thì mảng đáo nó sẽ === 0
                remainNap_Cuoi = stateNumber_tienrutdao; // nạp cuối sẽ là tiền rút đáo luôn
            }
            // console.log('arrDao_length: ', arrDao_length);
            let remainNap = remainNap_Cuoi - stateNum_nap;
            let remainRut = remainRut_Cuoi + stateNum_nap - stateNum_rut_in_dao;
            // console.log('stateNumber_tienrutdao: ', stateNumber_tienrutdao);
            // console.log('stateNum_nap: ', stateNum_nap);
            // console.log('stateNum_rut_in_dao: ', stateNum_rut_in_dao);
            // console.log('remainNap: ', remainNap);
            // console.log('remainRut: ', remainRut);
            // return;
            if (remainNap < -100000) {
                alert("Có sự nhầm lẫn ở tiền nạp. Tiền nạp còn lại không được nhỏ hơn -100,000 !, chỉ cho phép số âm trong trường hợp rút test dưới 100,000");
                return;
            }
            let minimumRemainRut = -100000 - stateNumber_tienphi; // -100,000 là budget để rút test, còn - stateNumber_tienphi là tiền phí nằm trong tiền rút luôn
            // console.log('minimumRemainRut: ', minimumRemainRut);
            if (remainRut < minimumRemainRut) {
                alert(`Có sự nhầm lẫn ở tiền rút. Tiền rút còn lại không được nhỏ hơn âm 100,000 (test) + tiền phí ${stateNumber_tienphi} !, chỉ cho phép số âm trong trường hợp rút test dưới 100,000 hoặc bao gồm tiền phí trong tiền rút`);
                return;
            }
            let tienNap_parseInt = parseInt(stateNum_nap + "");
            let stateNum_rut_in_dao_parseInt = parseInt(stateNum_rut_in_dao + "");
            if (tienNap_parseInt === null) {
                tienNap_parseInt = 0
            }
            if (stateNum_rut_in_dao_parseInt === null) {
                stateNum_rut_in_dao_parseInt = 0
            }
            const now = getCurrentDateTimeVietNam();
            let objDao = {
                createdAt: stateString_dateCreateDao + "T00:00:00.000Z",
                giaodichId: stateNum_idGiaodich,
                soLo: stateString_solo,
                soBill: stateString_sobill,
                tienNap: tienNap_parseInt,
                tienNapConLai: remainNap,
                tienRut: stateNum_rut_in_dao_parseInt,
                tienRutConLai: remainRut,
                mayPosId: mayPost,
                chuyenDen: stateString_chuyenDen,
                soTaiKhoan: stk,
                // mediaId_rut: stateString_mediaId_dao,
                // mediaId_nap: stateString_mediaId_nap,
                empId: empId,
                truongHopId: stateString_truongHop
            }
            // console.log('objDao: ', objDao);
            // return;
            let responseDao = await collectionAPI.collectionAPI.createDao(token, objDao);
            // console.log('responseDao: ', responseDao);
            if (responseDao.status === 201) {
                alert("Thêm 1 lần rút đáo THÀNH CÔNG, bạn có thêm nhiều lần cho tới khi nào tiền nạp rút còn lại bằng 0, sau đó bạn có thể nhấn Hoàn thành đáo");
            }
            else {
                alert("Thêm THẤT BẠI, vui lòng kiểm tra lại, hoặc liên hệ Mr. Vũ (0982 547 769) để double check ngay");
                setStateString_mediaId_dao('');
                setStateString_mediaId_nap('');
                return
            }

            let responseNhuCau = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "nhucau", "Đáo hạn");

            let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthai", "Đang đáo");

            let responseTrangThaiAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthaiAt", now);

            let responseEditedAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "editedAt", now);
            let responseEmailEdit = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "emailNVRutdaoEdit", email);

            selectKH(stateObj_selectedKH.id);
            await wait(100);
            selectGiaodich(stateNum_idGiaodich);
            setStateString_sobill("");
            setStateNum_rut_in_dao(0);
            setStateNum_nap(0);
            setStateNum_rut_in_dao_makeup('')
            setStateNum_nap_makeup('')
            setStateString_mayPosId('empty');

            click_rut_dao("Đáo hạn");

            setStateString_mediaId_dao(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào
            setStateString_mediaId_nap(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào
            setStateString_mediaId_rut(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào

            // let theExist = await collectionAPI.collectionAPI.createThe(token, objThe);

            // Thêm hoặc update lại thông tin thẻ khi đáo
            const objThe = {
                idKhachhang: stateNumber_selectedKH_id,
                idGiaodich: stateNum_idGiaodich,
                loaiThe: stateString_loaithe,
                tenTrenThe: stateString_tenTrenThe,
                // soThe: stateString_sothetindung,
                soTaiKhoanNganHang: stateString_sotaikhoannganhang,
                soTaiKhoanTheTinDung: stateString_soTaiKhoanTheTinDung,
                hanMucTheTinDung: stateNumber_hanmucthetindung,
            }
            // console.log('objThe: ', objThe);
            const arrThe = stateObj_giaoDichSelected.the;
            // console.log('arrThe: ', arrThe);
            if (arrThe.length !== 0) {
                // let responseCreateThe = await collectionAPI.collectionAPI.createThe(token, objThe);
                // // console.log('responseCreateThe: ', responseCreateThe);
                let theId = arrThe[0].id;
                // console.log('theId: ', theId);
                let responseEditThe = await collectionAPI.collectionAPI.updateTheById(token, theId, objThe);
                // console.log('responseEditThe: ', responseEditThe);
            }

        } catch (err) {
            console.log('err:', err);
        }
    }
    const inputRef_solo_rut = useRef<HTMLInputElement | null>(null);
    const inputRef_sobill_rut = useRef<HTMLInputElement | null>(null);
    const inputRef_sorut = useRef<HTMLInputElement | null>(null);
    const postRut = async () => {
        if (stateNumber_selectedKH_id === 0 || stateNumber_selectedKH_id === undefined) {
            alert("Bạn vui lòng chọn lại khách hàng để hệ thống load lại dữ liệu cho đúng");
            setStateBool_showGiaodichDetails(false);
            return;
        }
        if (stateNum_idGiaodich === 0 || stateNum_idGiaodich === undefined || stateNum_idGiaodich === null) {
            alert("Bạn vui lòng chọn 1 giao dịch, để hệ thống load lại dữ liệu, xin cảm ơn ");
            setStateBool_showGiaodichDetails(false);
            return;
        }
        if (window.confirm("Ban đang thực hiện giao dịch RÚT, bạn có muốn tiếp tục ?") === false) {
            return
        }
        // if (stateObj_giaoDichSelected.emailNVRutdaoCreate === "" || stateObj_giaoDichSelected.emailNVRutdaoCreate === null) {
        //     alert("Bạn vui lòng nhập và lưu các thông tin cần thiết trước khi nhập số Lô, số Bill");
        //     return;
        // }
        setStateString_nhucau("Rút tiền");

        if (stateObj_giaoDichSelected.khachhang.cccd === "") {
            alert("Bạn vui lòng nhập và lưu các thông tin cần thiết trước khi nhập số Lô, số Bill");
            return;
        }

        // if(stateString_solo_rut === ""){
        //     alert("Bạn vui lòng nhập số Lô ");
        //     if (inputRef_solo_rut.current) {
        //         inputRef_solo_rut.current.focus();
        //     }
        //     return;
        // }
        // if(stateString_sobill_rut === ""){
        //     alert("Bạn vui lòng nhập số Bill ");
        //     if (inputRef_sobill_rut.current) {
        //         inputRef_sobill_rut.current.focus();
        //     }
        //     return;
        // }
        // chuyển từ string qua số (tiền rút)
        let stringWithoutCommas_rut = (stateNum_rut + '').replace(/,/g, ''); // bỏ dấu , phân cách
        let stringToNum_rut = parseInt(stringWithoutCommas_rut);

        if (isNaN(stringToNum_rut)) {
            alert("Bạn vui lòng nhập số tiền rút> 0 ");
            return;
        }

        if (stringToNum_rut <= 0 || stringToNum_rut > 900000000) {
            alert("Bạn vui lòng nhập số tiền rút> 0 ");
            if (inputRef_sonap.current) {
                inputRef_sonap.current.focus();
            }
            return;
        }

        if (stateString_mayPosId === "" || stateString_mayPosId === "empty" || stateString_mayPosId === undefined) {
            alert("Bạn vui lòng chọn máy pos ");
            return;
        }
        if (stateString_sotaikhoannganhang === "" || stateString_sotaikhoannganhang === null) {
            alert("Bạn vui lòng Nhập số tài khoản ngân hàng ");
            return;
        }

        if (stateString_dateCreateRut === "") {
            alert("Vui lòng chọn ngày rút lần này !")
            return;
        }

        // if(stateString_mediaId_rut === "" || stateString_mediaId_rut === null){
        //     alert("Bạn vui lòng kéo thả hình chụp số lô số bill trước để làm bằng chứng cho lần thêm này");
        //     return;
        // }
        const token = stateObjSignIn.token;
        const email = stateObjSignIn.email;
        const empId = stateObjSignIn.id;
        try {
            const now = getCurrentDateTimeVietNam()
            /*
            ví dụ giải thích tính ra remainRut
            công thức tính :
                remainRut = rút còn lại của lần trước - rút hiện tại 

            tiền rút đáo: 50
            lần         method          rút                 remainRut
            1           create          10                  50-10 = 40
            2           create          5                   40(remainRut trước) -  5(rút now) = 35
            3           edit lần 2      20                  40(remainRut trước) - 20(nạp now) = 20
            edit có nghĩa như là bỏ lần 2, và mình lấy remainNap của lần 1 - nạp lần 3 : 30-12 = 18
            và mình update cái lần 3 ghi đè vào lần 2 trong database
            => như vậy mấu chốt là mình cần lấy giá trị remainNap (30) và remainRut(10) của lần kế cuối , để mình trừ nạp/rút lần mới nhất
            mình sẽ lấy trong stateArr_dao_of_giaodich_selected ở cái obj kế cuối
            */
            const arrRut_length = stateArr_rut_of_giaodich_selected.length;
            // console.log('stateArr_rut_of_giaodich_selected: ', stateArr_rut_of_giaodich_selected);
            let remainRut_Cuoi = 0;
            if (arrRut_length === 0) {
                remainRut_Cuoi = stateNumber_tienrutdao; // post lần đầu tiên thì tiền rút đáo coi như là tiền rút còn lại
                // console.log('index_keCuoi: ', index_keCuoi);
            }
            else {
                const index_Cuoi = arrRut_length - 1; // -1 là cái index cuối
                remainRut_Cuoi = stateArr_rut_of_giaodich_selected[index_Cuoi].tienConLai;
                // console.log('index_keCuoi: ', index_keCuoi);
            }

            let remainRut = remainRut_Cuoi - stateNum_rut;
            // console.log('remainRut_Cuoi: ', remainRut_Cuoi);
            // console.log('stateNum_rut: ', stateNum_rut);
            // console.log('remainRut: ', remainRut);

            if (remainRut < 0) {
                alert("Có sự nhầm lần ở số tiền rút, vì tiền rút còn lại không thể nhỏ hơn 0. Vui lòng kiểm tra lại");
                return;
            }
            const obj = {
                giaodichId: stateNum_idGiaodich,
                soLo: stateString_solo_rut,
                tienRut: stringToNum_rut,
                tienConLai: remainRut,
                soBill: stateString_sobill_rut,
                mayPosId: stateString_mayPosId,
                chuyenDen: "STK ngân hàng",
                soTaiKhoan: stateString_sotaikhoannganhang,
                // mediaId: stateString_mediaId_rut,
                empId: empId,
                createdAt: stateString_dateCreateRut + "T00:00:00.000Z",
                truongHopId: stateString_truongHop

            }
            // console.log('obj: ', obj);
            let responseRut = await collectionAPI.collectionAPI.createRut(token, obj, email);
            // console.log('responseRut: ', responseRut);

            //ĐÃ LÀM TRONG BACK END HÀM createRut
            // let responseNhuCau = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "nhucau", "Rút tiền");

            // let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthai", "Đang rút");
            // // let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich,"teamrutdaothongbaotrangthai","Đang đáo");

            // let responseTrangThaiAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthaiAt", now);

            // let responseEditedAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "editedAt", now);
            // let responseEmailEdit = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "emailNVRutdaoEdit", email);

            selectKH(stateObj_selectedKH.id);
            await wait(100);
            selectGiaodich(stateNum_idGiaodich);
            setStateString_sobill("");
            setStateNum_rut_in_dao(0);
            setStateNum_rut(0);
            setStateNum_nap(0);
            setStateNum_rut_in_dao_makeup('')
            setStateNum_rut_makeup('')
            setStateNum_nap_makeup('')
            setStateString_mayPosId('empty');
            setStateString_mediaId_dao(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào
            setStateString_mediaId_nap(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào
            setStateString_mediaId_rut(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào

            click_rut_dao("Rút tiền");

            // const objThe = {
            //     idKhachhang: stateNumber_selectedKH_id,
            //     idGiaodich: stateNum_idGiaodich,
            //     loaiThe: stateString_loaithe,
            //     tenTrenThe: stateString_tenTrenThe,
            //     soTaiKhoanNganHang: stateString_sotaikhoannganhang,
            //     tenNganHangChuyenTien: stateString_tenNganHangChuyenTien,
            //     soTaiKhoanTheTinDung: stateString_soTaiKhoanTheTinDung,
            //     hanMucTheTinDung: stateNumber_hanmucthetindung,
            //     aiDangGiu: stateString_aiDangGiuThe
            // }
            // // console.log('objThe: ', objThe);
            // const arrThe = stateObj_giaoDichSelected.the;
            // // console.log('arrThe: ', arrThe);
            // if (arrThe.length !== 0) {
            //     // let responseCreateThe = await collectionAPI.collectionAPI.createThe(token, objThe);
            //     // console.log('responseCreateThe: ', responseCreateThe);
            //     let theId = arrThe[0].id;
            //     // console.log('theId: ', theId);
            //     let responseEditThe = await collectionAPI.collectionAPI.updateTheById(token, theId, objThe);
            //     // console.log('responseEditThe: ', responseEditThe);
            // }

        } catch (err) {
            console.log('err:', err);
        }


    }
    const [stateBool_btnEditDaoShow, setStateBool_btnEditDaoShow] = useState(false);
    const [stateNum_tienNap_cuoiCung, setStateNum_tienNap_cuoiCung] = useState(0);
    const [stateNum_tienRut_in_dao_cuoiCung, setStateNum_tienRut_in_dao_cuoiCung] = useState(0);
    const [stateNum_daoId_to_edit, setStateNum_daoId_to_edit] = useState(0);
    // đổ dữ liệu cũ vào khung cho dễ edit
    const selectDaoToEdit = async (daoId: any) => {

        if (stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Kế toán") {
            if (stateObjSignIn.email !== "xuan.ta@charm.contact" && stateObjSignIn.email !== "anh.nth@charm.contact" && stateObjSignIn.email !== "vu.na@charm.contact") {
                alert("Giao dịch này đã Đáo xong. Bạn vui lòng liên hệ với Ms. Phương hoặc Ms. Ánh để sửa giao dịch nhé, xin cảm ơn !");
                return;
            }
        }
        setStateBool_btnEditDaoShow(true); // cho hiện nút sửa thay cho nút thêm
        let obj: any = stateArr_dao_of_giaodich_selected.find((item: any) => item.id === daoId);
        // console.log('obj dao selected: ', obj);
        let objMayPos: any = stateArrMayPos.filter((item: any) => item.id === obj.mayPosId);
        // console.log('obj: ', obj);

        // lấy lại các hình cũ, nếu ko thay hình mới thì hình cũ giữ nguyên
        setStateString_mediaId_nap(obj.mediaId_nap);
        setStateString_mediaId_dao(obj.mediaId_rut); // trong trường hợp đáo thì rút chính là đáo, chỉ là tên gọi

        setStateNum_daoId_to_edit(obj.id); // phải bắt cái id để biết mà update

        setStateString_solo(obj.soLo);
        setStateString_sobill(obj.soBill);

        // tiền nạp
        const moneyToString_nap = formatThoudsand(obj.tienNap); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNum_nap_makeup(moneyToString_nap);
        setStateNum_nap(obj.tienNap);

        // tiền đáo
        const moneyToString_dao = formatThoudsand(obj.tienRut); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNum_rut_in_dao_makeup(moneyToString_dao);
        setStateNum_rut_in_dao(obj.tienRut);

        setStateString_mayPosId(objMayPos.id);
        setStateString_chuyenDen(obj.chuyenDen);

        // phải lưu số này lại để bước sau trừ số tiền này ra
        /*
        - phải lưu số này lại để bước sau trừ số tiền này ra, lý do khi nó tổng hợp sum thì nó cộng tất cả, kể cả số cuối
        - mà mình muốn sửa số cuối, vì vậy cần phải lưu nó lại để trừ khi update vào database
        */
        setStateNum_tienNap_cuoiCung(obj.tienNap);
        setStateNum_tienRut_in_dao_cuoiCung(obj.tienRut);

        setStateString_mediaId_dao(obj.mediaId_rut);
        setStateString_mediaId_nap(obj.mediaId_nap);

    }
    const [stateBool_btnEditRutShow, setStateBool_btnEditRutShow] = useState(false);
    const [stateNum_tienRut_cuoiCung, setStateNum_tienRut_cuoiCung] = useState(0);
    const [stateNum_RutId_to_edit, setStateNum_RutId_to_edit] = useState(0);
    // đổ dữ liệu cũ vào khung cho dễ edit
    const selectRutToEdit = async (rutId: any) => {

        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return;
        }
        if (stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Kế toán") {
            if (userExist.email !== "xuan.ta@charm.contact" && userExist.email !== "anh.nth@charm.contact" && userExist.email !== "vu.na@charm.contact") {
                alert("Giao dịch này đã Rút xong. Bạn vui lòng liên hệ với Ms. Phương hoặc Ms. Ánh để sửa giao dịch nhé, xin cảm ơn !");
                return;
            }
        }

        setStateBool_btnEditRutShow(true); // cho hiện nút sửa thay cho nút thêm
        let obj: any = stateArr_rut_of_giaodich_selected.find((item: any) => item.id === rutId);
        let objMayPos: any = stateArrMayPos.filter((item: any) => item.id === obj.mayPosId);

        // lấy lại hình cũ, nếu thay thì ok,còn ko thay thì lấy lại hình cũ
        setStateString_mediaId_rut(obj.mediaId);
        setStateNum_RutId_to_edit(obj.id); // phải bắt cái id để biết mà update

        setStateString_solo_rut(obj.soLo);
        setStateString_sobill_rut(obj.soBill);

        // tiền rút
        const moneyToString_rut = formatThoudsand(obj.tienRut); // chuyển thành chữ có dấu , phân cách hàng nghìn
        setStateNum_rut_makeup(moneyToString_rut);
        setStateNum_rut(obj.tienRut);

        setStateString_mayPosId(objMayPos.id);
        setStateString_chuyenDen(obj.chuyenDen);

        // phải lưu số này lại để bước sau trừ số tiền này ra
        /*
        - phải lưu số này lại để bước sau trừ số tiền này ra, lý do khi nó tổng hợp sum thì nó cộng tất cả, kể cả số cuối
        - mà mình muốn sửa số cuối, vì vậy cần phải lưu nó lại 
        remain = tienrutdao - stateNum_tienConLai_cuoiCung - sumTienNapByGiaodichId - tienNap
        */
        setStateNum_tienRut_cuoiCung(obj.tienRut);

    }

    const editDaoToDB = async () => {
        if (window.confirm("Ban đang thực hiện SỬA giao dịch ĐÁO, bạn có muốn tiếp tục ?") === false) {
            return
        }
        if (stateNum_idGiaodich === 0) {
            alert("Bạn vui lòng chọn 1 giao dịch ");
            return;
        }
        // trường hợp rút thì phải cần số lô số bill
        // trường hợp nạp thì ko cần số lô số bill
        // console.log('stateNum_rut_in_dao: ', stateNum_rut_in_dao);
        if (stateNum_rut_in_dao !== 0) {
            if (stateString_solo === "" || stateString_solo === undefined) {
                // alert("Bạn vui lòng nhập số Lô ");
                // if (inputRef_solo.current) {
                //     inputRef_solo.current.focus();
                // }
                // console.log('stateString_solo: ', stateString_solo);
                // return;
                setStateString_solo("");
            }
            if (stateString_sobill === "" || stateString_sobill === undefined) {
                // alert("Bạn vui lòng nhập số Bill ");
                // if (inputRef_sobill.current) {
                //     inputRef_sobill.current.focus();
                // }
                // return;
                setStateString_sobill("")
            }
            // tiền rút khi đáo ko bắt buộc phải nhập, nhưng một khi nhập thì số nhập vào phải đúng trong khoảng dưới đây
            if (stateNum_rut_in_dao <= 0 || stateNum_rut_in_dao > 900000000) {
                alert("Bạn vui lòng nhập số tiền rút > 0 hoặc nhỏ hơn 900,000,000 ");
                if (inputRef_soRut_in_dao.current) {
                    inputRef_soRut_in_dao.current.focus();
                }
                return;
            }
            // if(stateString_mediaId_dao === null || stateString_mediaId_dao === ""){
            //     alert("Bạn vui lòng kéo thả hình chụp số lô số bill trước để làm bằng chứng cho lần thêm này");
            //     return;
            // }
            if (stateString_mayPosId === "" || stateString_mayPosId === "empty" || stateString_mayPosId === undefined) {
                alert("Bạn vui lòng chọn máy pos ");
                return;
            }
        }
        if (stateNum_nap !== 0) {
            if (stateNum_nap <= 0 || stateNum_nap > 900000000) {
                alert("Bạn vui lòng nhập số tiền nạp > 0 hoặc nhỏ hơn 900,000,000");
                if (inputRef_sonap.current) {
                    inputRef_sonap.current.focus();
                }
                return;
            }
            // if(stateString_mediaId_nap === null || stateString_mediaId_nap === ""){
            //     alert("Bạn vui lòng kéo thả hình chụp nạp tiền trước để làm bằng chứng cho lần thêm này");
            //     return;
            // }
        }

        let stk = "";
        // console.log('stateBool_isValid_sothetindung: ', stateBool_isValid_sothetindung);
        // console.log('stateString_soTaiKhoanTheTinDung: ', stateString_soTaiKhoanTheTinDung);
        // console.log('stateString_sothetindung: ', stateString_sothetindung);
        // console.log('stateString_chuyenDen: ', stateString_chuyenDen);
        if (stateString_chuyenDen === "STK thẻ tín dụng") {
            if (stateString_soTaiKhoanTheTinDung === '' || stateString_soTaiKhoanTheTinDung === null || stateString_soTaiKhoanTheTinDung === undefined) {
                alert("Vui lòng nhập số tài khoản thẻ tín dụng");
                if (inputRef_soTaiKhoanTheTinDung.current) {
                    inputRef_soTaiKhoanTheTinDung.current.focus();
                }
                return;
            }
            stk = stateString_soTaiKhoanTheTinDung;
        }
        else if (stateString_chuyenDen === "Số trên thẻ tín dụng") {
            // console.log('stateString_sothetindung: ', stateString_sothetindung);
            if (stateString_sothetindung === '' || stateString_sothetindung === null || stateString_sothetindung === undefined) {
                alert("Vui lòng nhập số trên thẻ tín dụng");
                if (inputRef_sothetindung.current) {
                    inputRef_sothetindung.current.focus();
                    return;
                }
                if (stateBool_isValid_sothetindung === true) {
                    alert("Số thẻ tín dụng chưa hợp lệ, vui lòng kiểm tra lại");
                    return;
                }
            }
            stk = stateString_sothetindung;
        }
        const token = stateObjSignIn.token;

        try {
            // let responseSumNapRutByGiaodichId = await collectionAPI.collectionAPI.calculateSumTienNapAndTienRutByGiaodichId(token, stateNum_idGiaodich);

            // let sumNap = responseSumNapRutByGiaodichId.data.sumTienNap;
            // let sumRut = responseSumNapRutByGiaodichId.data.sumTienRut;
            // let remainNap = parseInt(stateNumber_tienrutdao+"") - parseInt(sumNap+"") - parseInt(stateNum_nap+"") - stateNum_tienNap_cuoiCung;
            // let remainRut = Math.abs(parseInt(sumNap+"") - parseInt(sumRut+"") + parseInt(stateNum_nap+"") - parseInt(stateNum_rut_in_dao+"") - stateNum_tienRut_in_dao_cuoiCung);
            // let remainRut = parseInt(sumNap+"") - parseInt(sumRut+"") + parseInt(stateNum_nap+"") - parseInt(stateNum_rut_in_dao+"") - stateNum_tienRut_in_dao_cuoiCung;

            const now = getCurrentDateTimeVietNam();
            /*
            ví dụ giải thích tính ra remainNap / remainRut
            công thức tính :
                remainNap = nạp còn lại trước - nạp hiện tại
                remainRut = rút còn lại của lần trước + nạp hiện tại - rút hiện tại 

            tiền rút đáo: 50
            lần         method          nạp         remainNap           rút                 remainRut
            1           create          20          50-20 = 30          10                  20-10 = 10
            2           create          10          30-10 = 20          5                   10(remainRut trước)+10(nạp now)-5(rút) = 15
            3           edit lần 2      12          30-12 = 18          10                  10(remainRut trước)+12(nạp now)-10(rút) = 12
            edit có nghĩa như là bỏ lần 2, và mình lấy remainNap của lần 1 - nạp lần 3 : 30-12 = 18
            và mình update cái lần 3 ghi đè vào lần 2 trong database
            => như vậy mấu chốt là mình cần lấy giá trị remainNap (30) và remainRut(10) của lần kế cuối , để mình trừ nạp/rút lần mới nhất
            mình sẽ lấy trong stateArr_dao_of_giaodich_selected ở cái obj kế cuối
            */
            const arrDao_length = stateArr_dao_of_giaodich_selected.length;
            // console.log('arrDao_length: ', arrDao_length);
            let remainNap_keCuoi = 0;
            let remainRut_keCuoi = 0;
            if (arrDao_length > 1) {
                const index_keCuoi = arrDao_length - 2; // -1 là cái index cuối, kế cuối nên phải là - 2
                remainNap_keCuoi = stateArr_dao_of_giaodich_selected[index_keCuoi].tienNapConLai;
                remainRut_keCuoi = stateArr_dao_of_giaodich_selected[index_keCuoi].tienRutConLai;
                // console.log('index_keCuoi: ', index_keCuoi);
            }
            else if (arrDao_length === 1) { // nếu mảng chỉ có 1 ptu thì lấy chính nó luôn, sửa cái đầu tiên
                remainNap_keCuoi = stateArr_dao_of_giaodich_selected[0].tienNapConLai;
                remainRut_keCuoi = stateArr_dao_of_giaodich_selected[0].tienRutConLai;
            }
            let remainNap = remainNap_keCuoi - stateNum_nap;
            let remainRut = remainRut_keCuoi + stateNum_nap - stateNum_rut_in_dao;
            // console.log('stateNumber_tienrutdao: ', stateNumber_tienrutdao);
            // console.log('stateNum_nap: ', stateNum_nap);
            // console.log('stateNum_tienNap_cuoiCung: ', stateNum_tienNap_cuoiCung);
            // console.log('stateNum_rut_in_dao: ', stateNum_rut_in_dao);
            // console.log('remainNap: ', remainNap);
            // console.log('remainRut: ', remainRut);
            if (remainNap < 0) {
                alert("Có sự nhầm lẫn ở tiền nạp. Tiền nạp còn lại không được nhỏ hơn 0 !");
                return;
            }
            if (remainRut < 0) {
                alert("Có sự nhầm lẫn ở tiền rút. Tiền rút còn lại không được nhỏ hơn 0 !");
                return;
            }
            const objDao = {
                giaodichId: stateNum_idGiaodich,
                soLo: stateString_solo,
                soBill: stateString_sobill,
                tienNap: parseInt(stateNum_nap + ""),
                tienNapConLai: remainNap,
                tienRut: parseInt(stateNum_rut_in_dao + ""),
                tienRutConLai: remainRut,
                mayPosId: stateString_mayPosId,
                chuyenDen: stateString_chuyenDen,
                soTaiKhoan: stk,
                createdAt: now,
                mediaId_rut: stateString_mediaId_dao,
                mediaId_nap: stateString_mediaId_nap
            }
            let response_updateDaoById = await collectionAPI.collectionAPI.updateDaoById(token, stateNum_daoId_to_edit, objDao);
            if (response_updateDaoById.status === 200) {
                alert("Update rút đáo THÀNH CÔNG, bạn có thêm nhiều lần cho tới khi nào tiền nạp rút còn lại bằng 0, sau đó bạn có thể nhấn Hoàn thành đáo");
            }
            else {
                alert("Update THẤT BẠI, vui lòng kiểm tra lại, hoặc liên hệ Mr. Vũ (0982 547 769) để double check ngay");
                return
            }
            setStateString_mediaId_dao('');
            setStateString_mediaId_nap('');
            let responseNhuCau = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "nhucau", "Đáo hạn");

            let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthai", "Đang đáo");
            // cho nó về lại rỗng vì là đang đáo, khi nào hủy thì mới có lý do hủy, khi mình lỡ hủy, nó sẽ có lý do, mà giờ mình sửa thì mình cho lý do lại về rỗng
            let responseLydo = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "lydocuatrangthai", "empty");

            let responseEditedAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "editedAt", now);
            let responseEmailEdit = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "emailNVRutdaoEdit", stateObjSignIn.email);

            selectKH(stateObj_selectedKH.id);
            await wait(100);
            selectGiaodich(stateNum_idGiaodich);
            setStateString_sobill("");
            setStateNum_rut_in_dao(0);
            setStateNum_nap(0);
            setStateNum_rut_in_dao_makeup('')
            setStateNum_nap_makeup('')
            setStateString_mayPosId('empty');
            setStateString_mediaId_dao(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào
            setStateString_mediaId_nap(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào
            setStateString_mediaId_rut(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào

            click_rut_dao("Đáo hạn");

            const objThe = {
                idKhachhang: stateNumber_selectedKH_id,
                idGiaodich: stateNum_idGiaodich,
                loaiThe: stateString_loaithe,
                tenTrenThe: stateString_tenTrenThe,
                soThe: stateString_sothetindung,
                soTaiKhoanNganHang: stateString_sotaikhoannganhang,
                soTaiKhoanTheTinDung: stateString_soTaiKhoanTheTinDung,
                hanMucTheTinDung: 0,
                aiDangGiu: stateString_aiDangGiuThe
            }
            // console.log('objThe: ', objThe);
            const arrThe = stateObj_giaoDichSelected.the;
            // console.log('arrThe: ', arrThe);
            if (arrThe.length === 0) {
                let responseCreateThe = await collectionAPI.collectionAPI.createThe(token, objThe);
                // console.log('responseCreateThe: ', responseCreateThe);
            }
            else {
                let theId = arrThe[0].id;
                // console.log('theId: ', theId);
                let responseEditThe = await collectionAPI.collectionAPI.updateTheById(token, theId, objThe);
                // console.log('responseEditThe: ', responseEditThe);

            }

        } catch (err) {
            console.log('err:', err);
        }
        setStateBool_btnEditDaoShow(false); // cho hiện nút sửa thay cho nút thêm
    }
    const editRutToDB = async () => {

        if (stateNum_idGiaodich === 0) {
            alert("Bạn vui lòng chọn 1 giao dịch ");
            return;
        }

        // if(stateString_solo_rut === ""){
        //     alert("Bạn vui lòng nhập số Lô ");
        //     if (inputRef_solo_rut.current) {
        //         inputRef_solo_rut.current.focus();
        //     }
        //     return;
        // }

        // if(stateString_sobill_rut === ""){
        //     alert("Bạn vui lòng nhập số Bill ");
        //     if (inputRef_sobill_rut.current) {
        //         inputRef_sobill_rut.current.focus();
        //     }
        //     return;
        // }

        // chuyển từ string qua số (tiền đáo)
        let stringWithoutCommas_rut = (stateNum_rut + '').replace(/,/g, ''); // bỏ dấu , phân cách
        let stringToNum_rut = parseInt(stringWithoutCommas_rut);

        if (isNaN(stringToNum_rut)) {
            alert("Bạn vui lòng nhập số tiền rút > 0 ");
            return;
        }

        if (stringToNum_rut <= 0 || stringToNum_rut > 900000000) {
            alert("Bạn vui lòng nhập số tiền rút > 0 && nhỏ hơn 900 triệu");
            if (inputRef_soRut_in_dao.current) {
                inputRef_soRut_in_dao.current.focus();
            }
            return;
        }

        if (stateString_mayPosId === "" || stateString_mayPosId === "empty" || stateString_mayPosId === undefined) {
            alert("Bạn vui lòng chọn máy pos ");
            return;
        }
        if (stateString_sotaikhoannganhang === "") {
            alert("Bạn vui lòng Nhập số tài khoản ngân hàng ");
            return;
        }

        const token = stateObjSignIn.token;
        const email = stateObjSignIn.email;
        try {
            /*
            ví dụ giải thích tính ra remainRut
            công thức tính :
                remainRut = rút còn lại của lần trước - rút hiện tại 

            tiền rút đáo: 50
            lần         method          rút                 remainRut
            1           create          10                  50-10 = 40
            2           create          5                   40(remainRut trước) -  5(rút now) = 35
            3           edit lần 2      20                  40(remainRut trước) - 20(nạp now) = 20
            edit có nghĩa như là bỏ lần 2, và mình lấy remainNap của lần 1 - nạp lần 3 : 30-12 = 18
            và mình update cái lần 3 ghi đè vào lần 2 trong database
            => như vậy mấu chốt là mình cần lấy giá trị remainNap (30) và remainRut(10) của lần kế cuối , để mình trừ nạp/rút lần mới nhất
            mình sẽ lấy trong stateArr_dao_of_giaodich_selected ở cái obj kế cuối
            */


            const arrRut_length = stateArr_rut_of_giaodich_selected.length;
            // console.log('stateArr_rut_of_giaodich_selected: ', stateArr_rut_of_giaodich_selected);
            let remainRut_keCuoi = 0;
            if (arrRut_length > 1) {
                const index_keCuoi = arrRut_length - 2; // -1 là cái index cuối, kế cuối nên phải là - 2
                remainRut_keCuoi = stateArr_rut_of_giaodich_selected[index_keCuoi].tienConLai;

            }
            else if (arrRut_length === 1) {
                remainRut_keCuoi = stateArr_rut_of_giaodich_selected[0].tienConLai;

            }

            let remainRut = remainRut_keCuoi - stateNum_rut;

            if (remainRut < 0) {
                alert("Có sự nhầm lần ở số tiền rút, vì tiền rút còn lại không thể nhỏ hơn 0. Vui lòng kiểm tra lại");
                return;
            }
            const now = getCurrentDateTimeVietNam()

            const obj = {
                giaodichId: stateNum_idGiaodich,
                soLo: stateString_solo_rut,
                tienRut: stringToNum_rut,
                tienConLai: remainRut,
                soBill: stateString_sobill_rut,
                mayPosId: stateString_mayPosId,
                chuyenDen: "STK ngân hàng",
                soTaiKhoan: stateString_sotaikhoannganhang,
                createdAt: now,
                mediaId: stateString_mediaId_rut
            }
            // console.log('stateNum_RutId_to_edit: ', stateNum_RutId_to_edit);

            // console.log('Rut: ', obj);
            // return;
            let responseRut_updateRutById = await collectionAPI.collectionAPI.updateRutById(token, stateNum_RutId_to_edit, obj);
            // console.log('responseRut_updateRutById: ', responseRut_updateRutById);

            click_rut_dao("Rút tiền");
            let responseNhuCau = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "nhucau", "Rút tiền");

            let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthai", "Đang rút");
            // let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich,"teamrutdaothongbaotrangthai","Đang đáo");

            let responseTrangThaiAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthaiAt", now);

            let responseEditedAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "editedAt", now);
            let responseEmailEdit = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "emailNVRutdaoEdit", email);

            selectKH(stateObj_selectedKH.id);
            await wait(100);
            selectGiaodich(stateNum_idGiaodich);
            setStateString_sobill("");
            setStateNum_rut(0);
            setStateNum_rut_makeup('');
            setStateNum_rut_in_dao(0);
            setStateNum_nap(0);
            setStateNum_rut_in_dao_makeup('')
            setStateNum_nap_makeup('');
            setStateString_sobill('');
            setStateString_sobill_rut('');
            setStateString_mayPosId('empty');
            setStateBool_btnEditRutShow(false); // quay trở lại nút create
            setStateString_mediaId_dao(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào
            setStateString_mediaId_nap(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào
            setStateString_mediaId_rut(''); // cho về null hết tránh lặp lại hình khi thêm mới nếu ko kéo thả hình vào
            const objThe = {
                idKhachhang: stateNumber_selectedKH_id,
                idGiaodich: stateNum_idGiaodich,
                loaiThe: stateString_loaithe,
                tenTrenThe: stateString_tenTrenThe,
                soThe: stateString_sothetindung,
                soTaiKhoanNganHang: stateString_sotaikhoannganhang,
                tenNganHangChuyenTien: stateString_tenNganHangChuyenTien,
                soTaiKhoanTheTinDung: stateString_soTaiKhoanTheTinDung,
                hanMucTheTinDung: 0,
                aiDangGiu: stateString_aiDangGiuThe

            }
            // console.log('objThe: ', objThe);
            const arrThe = stateObj_giaoDichSelected.the;
            // console.log('arrThe: ', arrThe);
            if (arrThe.length === 0) {
                let responseCreateThe = await collectionAPI.collectionAPI.createThe(token, objThe);
                // console.log('responseCreateThe: ', responseCreateThe);
            }
            else {
                let theId = arrThe[0].id;
                // console.log('theId: ', theId);
                let responseEditThe = await collectionAPI.collectionAPI.updateTheById(token, theId, objThe);
                // console.log('responseEditThe: ', responseEditThe);

            }

        } catch (err) {
            console.log('err:', err);
        }
        setStateBool_btnEditDaoShow(false); // cho hiện nút sửa thay cho nút thêm
    }
    const [stateBoolAlert, setStateBoolAlert] = useState(false);
    const [stateStringAlertMessage, setStateStringAlertMessage] = useState<string>('');
    const deleteDao = async (daoId: any) => {
        setStateBool_btnEditDaoShow(false);

        // if(stateObjSignIn.email !== "xuan.ta@charm.contact" && stateObjSignIn.email !== "anh.nth@charm.contact"&& stateObjSignIn.email !== "vu.na@charm.contact"){
        //     alert("Bạn vui lòng liên hệ với bạn Xuân hoặc Ánh để xóa nhé, xin cảm ơn !");
        //     return;
        // }
        if (window.confirm("Bạn có chắc chắn muốn bỏ lần đáo nạp này ?") === false) {
            return
        }
        const token = stateObjSignIn.token;
        const email = stateObjSignIn.email;
        const now = getCurrentDateTimeVietNam();
        const resultCheckPermission = checkPermission(email, stateArrDeletePermission);
        if (!resultCheckPermission) {
            return;
        }
        try {
            let responseDeleteDao = await collectionAPI.collectionAPI.deleteDao(token, daoId);
            // console.log('responseDeleteDao: ', responseDeleteDao);
            let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthai", "Đang đáo");

            let responseTrangThaiAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthaiAt", now);

            let responseEditedAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "editedAt", now);
            let responseEmailEdit = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "emailNVRutdaoEdit", stateObjSignIn.email);
            selectKH(stateObj_selectedKH.id);
            await wait(100);
            selectGiaodich(stateObj_giaoDichSelected.id);
            setStateStringAlertMessage("Xóa lần đáo thành công");
            setStateBoolAlert(true);
        } catch (err) {
            console.log('err:', err);
        }
    }
    const deleteRut = async (rutId: any) => {
        setStateBool_btnEditRutShow(false);
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return
        }

        const email = userExist.email;
        const token = userExist.token;

        const resultCheckPermission = checkPermission(email, stateArrDeletePermission);
        if (!resultCheckPermission) {
            return;
        }
        if (window.confirm("Bạn có chắc chắn muốn bỏ lần rút tiền này ?") === false) {
            return
        }
        try {
            let responseDeleteRut = await collectionAPI.collectionAPI.deleteRut(token, rutId);
            // console.log('responseDeleteDao: ', responseDeleteDao);
            selectKH(stateObj_selectedKH.id);
            await wait(100);
            selectGiaodich(stateObj_giaoDichSelected.id)
            setStateStringAlertMessage("Xóa lần rút thành công");
            setStateBoolAlert(true);
        } catch (err) {
            console.log('err:', err);
        }
    }

    const backToCreatePhoneBtn = () => {

        setStateBool_showEditPhoneBtn(false);

    }
    const saveGiaodich = async () => {
        const now = getCurrentDateTimeVietNam()
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return;
        }
        const result1 = stateString_tenkhachhang.length < 4 || stateString_tenkhachhang.length > 70 || !/[a-zA-Z]/.test(stateString_tenkhachhang) ? false : true;
        if (!result1) {
            alert("Tên khách hàng phải có độ dài trong khoảng [4-70]");
            return;
        }
        if (stateString_tennganhang === "" || stateString_tennganhang === null || stateString_tennganhang === undefined) {
            alert("Vui lòng chọn Ngân hàng");
            return;
        }

        const result = validateTienRut(stateNumber_tienrutdao);
        if (!result) {
            // console.log('stateNumber_tienrutdao: ', stateNumber_tienrutdao);
            alert("Tiền rút đáo sai, vui lòng nhập lại tiền rút đáo");

            return;
        }
        if (stateString_chinhanh === "empty" || stateString_chinhanh === "" || stateString_chinhanh === null || stateString_chinhanh === undefined) {
            alert("vui lòng chọn chi nhánh");
            return;
        }

        // const check_ngaydukienlienhelai = isValid_yyyy_mm_dd_hh_mm(stateString_ngaydukienlienhelai);
        // if(check_ngaydukienlienhelai === false){
        //     alert("Ngày giờ dự kiến liên hệ bị sai format");
        //     return;
        // }
        // const ngaydukienlienhelai_parse = parseDateString(stateString_ngaydukienlienhelai);

        let ngayhanthanhtoan_parse: string | null = null;
        // console.log('stateString_nhucau: ', stateString_nhucau);
        if (stateString_nhucau === "Rút tiền") {
            ngayhanthanhtoan_parse = null;
            if (stateString_sotaikhoannganhang === "") {
                alert("Không được bỏ trống số tài khoản ngân hàng chuyển tiền đến")
                if (inputRef_sotaikhoannganhang.current) {
                    inputRef_sotaikhoannganhang.current.focus();
                }
                return;
            }
            if (stateString_tenNganHangChuyenTien === "") {
                alert("Không được bỏ trống tên ngân hàng chuyển tiền đến")
                if (inputRef_tenNganHangChuyenTien.current) {
                    inputRef_tenNganHangChuyenTien.current.focus();
                }
                return;
            }
            const obj2 = stateArrNganhang.find((obj) => obj.id === stateString_tenNganHangChuyenTien);
            if (obj2 === undefined || obj2 === null) {
                alert("Tên ngân hàng chuyển tiền bạn nhập không có trong hệ thống. Bạn vui lòng chọn lại ngân hàng chuyển tiền đến");
                return;
            }
        }
        else if (stateString_nhucau === "Đáo hạn") {
            const check_ngayhanthanhtoan = isValidDateFormat_dd_mm_yyyy(stateString_ngayhanthanhtoan);//check dd_mm_yyyy có đúng ko
            if (check_ngayhanthanhtoan === false) {
                alert("Ngày hạn đáo thẻ bị sai format");
                return;
            }
            ngayhanthanhtoan_parse = convertDateFormat_dd_mm_yyyy_to_yyyy_mm_dd(stateString_ngayhanthanhtoan);
            // console.log('ngaydukienlienhelai_parse: ', ngayhanthanhtoan_parse);
            ngayhanthanhtoan_parse = ngayhanthanhtoan_parse + "T00:00:00.000Z"
        }

        let tienphi = Math.round(stateNumber_tienphi); // làm tròn số. ko hiểu sao js lúc tính toán nó lại ra lẻ
        // trường hợp số tiền rút đáo <= 5tr (visa, master) Tiền phí là 100,000 ko cần quan tâm phí rút đáo
        if (stateString_loaithe === "Visa" || stateString_loaithe === "Master card") {
            if (stateNumber_tienrutdao <= 5000000) {
                tienphi = 100000;
            }
        }
        if (stateString_loaithe === "JCB" && stateNumber_tienrutdao <= 5000000) {
            tienphi = 125000;
        }

        // validate phí rút đáo nếu chi nhánh là Bảo Lộc
        if (stateString_chinhanh === "Bảo Lộc" && stateNumber_tienrutdao <= 5000000) {
            tienphi = 125000;
        }
        // console.log('obj 3502: ', objGiaodich);
        if (stateBool_valid_cccd === true) {
            alert("CCCD bị sai, vui lòng nhập lại");
            return;
        }
        if (stateBool_valid_ntns === true) {
            alert("Ngày tháng năm sinh bị sai, vui lòng nhập lại");
            return;
        }

        if (stateString_ntns === "") {
            alert("Vui lòng nhập ngày tháng năm sinh");
            return;
        }
        let ntns_parse: string | null = null;
        // console.log('stateString_nhucau: ', stateString_nhucau);
        if (stateString_nhucau === "Rút tiền") {
            ntns_parse = null;
        }
        const check_ntns = isValidDateFormat_dd_mm_yyyy(stateString_ntns);//check dd_mm_yyyy có đúng ko
        if (check_ntns === false) {
            alert("Ngày tháng năm sinh bị sai format");
            return;
        }
        ntns_parse = convertDateFormat_dd_mm_yyyy_to_yyyy_mm_dd(stateString_ntns);
        // console.log('ngaydukienlienhelai_parse: ', ntns_parse);
        ntns_parse = ntns_parse + "T00:00:00.000Z"

        if (stateString_cccd === "") {
            alert("Vui lòng nhập ngày CCCD");
            return;
        }
        if (nameCitySelected === "") {
            alert("Vui lòng chọn thành phố");
            return;
        }
        if (nameDistrictSelected === "") {
            alert("Vui lòng chọn Quận");
            return;
        }
        if (nameWardSelected === "") {
            alert("Vui lòng chọn Phường");
            return;
        }
        if (stateString_address === "") {
            alert("Vui lòng nhập địa chỉ");
            return;
        }

        if (stateString_tenTrenThe === "") {
            alert("Vui lòng nhập tên trên thẻ tín dụng");
            return;
        }
        // console.log('stateString_soTaiKhoanTheTinDung: ', stateString_soTaiKhoanTheTinDung);
        // chuyển từ string qua số (hanmucthetindung)
        let stringWithoutCommas_hanmucthetindung = (stateNumber_hanmucthetindung + '').replace(/,/g, ''); // bỏ dấu , phân cách
        let stringToNum_hanmucthetindung = parseInt(stringWithoutCommas_hanmucthetindung);

        if (isNaN(stringToNum_hanmucthetindung)) {
            alert("Bạn vui lòng nhập số tiền Đáo> 0 ");
            return;
        }

        if (stringToNum_hanmucthetindung <= 0 || stringToNum_hanmucthetindung > 900000000) {
            alert("Bạn vui lòng nhập số tiền hạn mức thẻ tín dụng> 0 ");
            return;
        }

        if (stateString_tennganhang === "" || stateString_tennganhang === null || stateString_tennganhang === undefined) {
            alert("Bạn vui lòng chọn tên ngân hàng của thẻ !");
            return;
        }
        const obj1 = stateArrNganhang.find((obj) => obj.id === stateString_tennganhang);
        if (obj1 === undefined || obj1 === null) {
            alert("Tên ngân hàng bạn nhập không có trong hệ thống. Bạn vui lòng chọn lại ngân hàng chuyển tiền đến");
            return;
        }

        const email = userExist.email;
        const fullName = userExist.fullName;

        const UpdateKhGdTheStknhPhoneDto = {
            //Khachhang
            // createdAt: Date;
            // editedAt: Date;
            // "emailNVCSKHCreate": email,
            // "fullNameNVCSKHcreate": fullName,
            "emailNVCSKHEdit": email,
            "fullNameNVCSKHedit": fullName,
            "fullNameKH": convertToTitleCase(stateString_tenkhachhang),
            // "nguonKH": stateString_nguonKH,
            "city": nameCitySelected,
            "district": nameDistrictSelected,
            "ward": nameWardSelected,
            "address": stateString_address,
            "cccd": stateString_cccd,
            "ntns": ntns_parse,
            // cccd_mt_mediaId: string,
            // cccd_mt_signedUrl: string,
            // cccd_ms_signedUrl: string,
            // cccd_ms_mediaId: string,
            // cccd_createdAt: Date,

            // Giaodich
            "idKhachhang": stateNumber_selectedKH_id,
            "idGiaodich": stateNum_idGiaodich,
            // idKhachhang: number,
            "createdAt": stateString_dateCreateGD + "T00:00:00.000Z",
            // editedAt: Date,
            // emailNVCSKHCreate: string,
            // fullNameNVCSKHcreate: string,
            // emailNVCSKHEdit: string,
            // "emailNVRutdaoCreate": email,
            // "fullNameNVRutdaoCreate": fullName,
            "fullNameNVRutdaoEdit": fullName,
            "emailNVRutdaoEdit": email,
            // emailNVFinalApprove: email,
            // finalApprovedAt: Date,
            // isApproved: boolean,
            "nhucau": stateString_nhucau,
            "nganhangId": stateString_tennganhang,
            "tienrutdao": stateNumber_tienrutdao,
            "phirutdao": parseFloat(stateNumber_phirutdao + ""),
            "tienphi": removeDecimalAndRound(stateNumber_tienphi),
            // "tienship": parseInt(stateNumber_tienship+""),
            "tienChuyenKhach": stateNumber_tienChuyenKhach,
            "tenchinhanh": stateString_chinhanh,
            "ngaydukienlienhelai": null,
            "ngayhanthanhtoan": ngayhanthanhtoan_parse,
            "trangthaiCSKH": stateObj_giaoDichSelected.trangthaiCSKH,
            "ghichurutdao": stateString_ghichurutdao,
            "ghichucskh": stateString_ghichu,
            "ghiChuPhiGiaoDich": stateString_ghichuphigiaodich,
            // "nhanvienship": string,
            // "diemdenship": string,
            // "ngayrutdao": Date,
            "loaigiaodich": "Giao dịch thật",
            // "teamrutdaoreceivedAt": Date,
            "teamrutdaothongbaotrangthai": stateString_teamrutdaothongbaotrangthai,
            // "teamrutdaothongbaotrangthaiAt": Date,
            "lydocuatrangthai": stateString_lydocuatrangthai,
            "noiDungChuyenTien": `TT ${stateNumber_selectedKH_id} ${stateNum_idGiaodich}`,

            // "tienShipNhanVien": number,
            // "hinhGiaoDich": string[],
            // "hinhChuyenKhoanTienPhi_mediaId": string,
            // "hinhChuyenKhoanTienPhi_signedUrl": string,
            // "checkChuyenPhi": boolean,
            // "resultCheckChuyenPhi": string,

            // The
            // idKhachhang: number,
            // idGiaodich: number,
            "loaiThe": stateString_loaithe,
            "tenTrenThe": stateString_tenTrenThe,
            "soThe": stateString_sothetindung,
            // soTaiKhoanNganHang: string,
            // tenNganHangChuyenTien: string,
            "soTaiKhoanTheTinDung": stateString_soTaiKhoanTheTinDung,
            "hanMucTheTinDung": stateNumber_hanmucthetindung,
            "aiDangGiu": stateString_aiDangGiuThe,
            "hanDao": ngayhanthanhtoan_parse,

            //sotaikhoannganhang
            // idKhachhang: number,
            // idGiaodich: number,
            "soTaiKhoanNganHang": stateString_sotaikhoannganhang,
            "tenNganHangChuyenTien": stateString_tenNganHangChuyenTien,
        }
        console.log('UpdateKhGdTheStknhPhoneDto: ', UpdateKhGdTheStknhPhoneDto);
        // return;
        const token = userExist.token;
        try {
            let response = await collectionAPI.collectionAPI_save_data.update_Kh_Giaodich_The_Stknh(token, UpdateKhGdTheStknhPhoneDto);
            if (response.hasOwnProperty("data") && typeof response.data === "object" && response.data.hasOwnProperty("result")) {
                if (response.data.result === "succeed") {
                    alert(response.data.message);
                }
            } else {
                alert("Lưu dữ liệu không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào !");
            }
        } catch (err) {
            console.log('err:', err);
        }
    }
    const [stateBool_showEditCity, setStateBool_showEditCity] = useState(false); // boolean

    const handleClickEditCity = () => {
        setStateBool_showEditCity(true);
    }

    const doneEditCity = () => {
        setStateBool_showEditCity(false);
    }
    const backToCreateRut = () => {

        setStateBool_btnEditRutShow(false);
        setStateNum_RutId_to_edit(0);
        setStateNum_daoId_to_edit(0);

    }
    const hoanhThanhRut = async () => {
        // console.log('action: ', stateArr_rut_of_giaodich_selected);

        for (let i = 0; i < stateArr_rut_of_giaodich_selected.length; i++) {
            let obj = stateArr_rut_of_giaodich_selected[i];
            if (obj.soLo === "" || obj.soBill === "") {
                alert(`Lần đáo thứ ${i + 1} chưa có số Lô hoặc số Bill, vui lòng bổ sung trước khi hoàn thành giao dịch`)
                return; // Found an object with empty or undefined values
            }

        }
        const email = stateObjSignIn.email;

        // if(email !== "xuan.ta@charm.contact" && email !== "anh.nth@charm.contact"&& email !== "vu.na@charm.contact"){
        //     alert("Bạn vui lòng liên hệ với bạn Xuân hoặc Ánh để hoàn thành giao dịch này nhé, xin cảm ơn !");
        //     return;
        // }
        if (window.confirm("Bạn đã chắc chắn hoàn thành RÚT giao dịch này chưa ?, Bạn cần kiểm tra lại số tiền còn lại đã về 0 chưa nhé") === false) {
            return
        }

        let numberOfRut = stateObj_giaoDichSelected.rut.length;
        if (numberOfRut === 0) {
            alert("Hành động của bạn không hợp lệ !, giao dịch này chưa tiến hành rút lần nào nên chưa hoàn thành xong");
            return;
        }
        let objLanDaoCuoiCung = stateObj_giaoDichSelected.rut[numberOfRut - 1];
        let tienRutConLai = objLanDaoCuoiCung.tienConLai;

        if (tienRutConLai > 100000) {
            alert(`Tiền rút còn lại : ${formatThoudsand(tienRutConLai)}; bạn chưa rút xong, vì vậy giao dịch này chưa được phép hoàn thành rút`);
            return;
        }
        const token = stateObjSignIn.token;
        const now = getCurrentDateTimeVietNam();

        setStateString_teamrutdaothongbaotrangthai("Kế toán");
        try {
            let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthai", "Kế toán");

            if (responseTrangThai.status === 200) {
                alert("Giao dịch này đã hoàn thành rút, và đã chuyển dữ liệu sang bộ phận kế toán để kiểm tra");
            }
            let responseTrangThaiAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthaiAt", now);
            let responseEditedAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "editedAt", now);
            let responseEmailEdit = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "emailNVRutdaoEdit", stateObjSignIn.email);

            selectGiaodich(stateObj_giaoDichSelected.id);
        } catch (err) {
            console.log('err:', err);
        }

    }
    const hoanThanhDao = async () => {
        // console.log('stateArr_dao_of_giaodich_selected: ', stateArr_dao_of_giaodich_selected);
        // có những lần nap sẽ ko có bill, lo, vì vậy ko thể tạo for check tất cả các lần phải có bill và lô đc
        // console.log('action: ', stateArr_dao_of_giaodich_selected);
        let truongHop = "";
        console.log('stateArr_dao_of_giaodich_selected: ', stateArr_dao_of_giaodich_selected);
        for (let i = 0; i < stateArr_dao_of_giaodich_selected.length; i++) {
            let obj = stateArr_dao_of_giaodich_selected[i];
            if (obj.tienRut !== 0) {
                if (obj.soLo === "" || obj.soBill === "") {
                    alert(`Lần đáo thứ ${i + 1}, tiền rút: ${formatThoudsand(obj.tienRut)} chưa có số Lô hoặc số Bill, vui lòng bổ sung trước khi hoàn thành giao dịch`)
                    return; // Found an object with empty or undefined values
                }
            }
            if (obj.truongHopId === null) { // vì data cũ ko có nên nó là null nên mình cho nó có value là Bình thường
                truongHop = "Bình thường";
            }
            else {
                truongHop = obj.truongHopId;

            }
        }
        const email = stateObjSignIn.email;

        // if(email !== "xuan.ta@charm.contact" && email !== "anh.nth@charm.contact"&& email !== "vu.na@charm.contact"){
        //     alert("Bạn vui lòng liên hệ với bạn Xuân hoặc Ánh để hoàn thành giao dịch này nhé, xin cảm ơn !");
        //     return;
        // }

        if (window.confirm("Bạn đã chắc chắn hoàn thành ĐÁO giao dịch này chưa ?, Bạn cần kiểm tra lại số tiền còn lại đã về 0 chưa nhé") === false) {
            return
        }
        let numberOfDao = stateObj_giaoDichSelected.dao.length;
        if (numberOfDao === 0) {
            alert("Giao dịch này chưa đáo lần nào, nên chưa thể hoàn thành");
            return;
        }
        let objLanDaoCuoiCung = stateObj_giaoDichSelected.dao[numberOfDao - 1];
        let tienNapConLai = objLanDaoCuoiCung.tienNapConLai;
        let tienRutConLai = objLanDaoCuoiCung.tienRutConLai;

        // console.log('numberOfDao: ', numberOfDao);
        // console.log('tienNapConLai: ', tienNapConLai);
        // console.log('tienRutConLai: ', tienRutConLai);
        // return;
        if (tienNapConLai > 100000) {
            alert(`Tiền nạp còn lại : ${formatThoudsand(tienNapConLai)}; bạn chưa nạp xong, vì vậy giao dịch này chưa được phép hoàn thành đáo`);
            return;
        }

        if (truongHop === "Bình thường") { // chỉ xét khi trường hợp Bình thường, những trường hợp đặc biệt khác thì ko xét cái này

            let maxTienRutConLai = 100000 + stateNumber_tienphi; // trường hợp đặc biệt: tiền phí bao gồm trong tiền rút
            if (tienRutConLai > maxTienRutConLai) {
                alert(`Tiền rút còn lại : ${formatThoudsand(tienRutConLai)}; bạn chưa rút xong, vì vậy giao dịch này chưa được phép hoàn thành đáo`);
                return;
            }
        }
        const now = getCurrentDateTimeVietNam();

        const token = stateObjSignIn.token;
        setStateString_teamrutdaothongbaotrangthai("Kế toán");
        try {
            // console.log('stateNum_idGiaodich: ', stateNum_idGiaodich);
            let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthai", "Kế toán");
            // console.log('responseTrangThai: ', responseTrangThai);
            if (responseTrangThai.status === 200) {
                alert("Giao dịch này đã hoàn thành đáo, và đã chuyển dữ liệu sang bộ phận kế toán để kiểm tra");
            }
            let responseTrangThaiAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthaiAt", now);
            let responseEditedAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "editedAt", now);
            let responseEmailEdit = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "emailNVRutdaoEdit", stateObjSignIn.email);

            selectGiaodich(stateObj_giaoDichSelected.id);
        } catch (err) {
            console.log('err:', err);
        }

    }

    const huyGiaodich = async () => {
        const email = stateObjSignIn.email;
        if (email !== "xuan.ta@charm.contact" && email !== "anh.nth@charm.contact" && email !== "vu.na@charm.contact") {
            alert("Bạn vui lòng liên hệ với Mr. Vũ (0982 547 769) để hủy giao dịch này nhé, xin cảm ơn !");
            return;
        }
        if (window.confirm("Bạn có chắc chắn muốn hủy giao dịch này ?") === false) {
            return;
        }
        var lydo: string | null = prompt("Vui lòng nhập lý do hủy giao dịch: ");
        if (lydo === "") {
            alert("Bạn không thể hủy giao dịch nếu chưa nhập lý do");
            return;
        }
        //@ts-ignore
        setStateString_lydocuatrangthai(lydo);

        const token = stateObjSignIn.token;
        setStateString_teamrutdaothongbaotrangthai("Hủy");
        try {
            let trangThai = "Hủy";
            let responseTrangThai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthai", trangThai);
            // console.log('responseTrangThai.status: ', responseTrangThai.status);
            if (responseTrangThai.status === 200) {
                alert("Đã hủy giao dịch này");

                selectGiaodich(stateNum_idGiaodich);
            }
            const now = getCurrentDateTimeVietNam()

            let responseTrangThaiAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "teamrutdaothongbaotrangthaiAt", now);
            let responselydocuatrangthai = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "lydocuatrangthai", lydo);

            let responseEditedAt = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "editedAt", now);
            let responseEmailEdit = await collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, stateNum_idGiaodich, "emailNVRutdaoEdit", email);
        } catch (err) {
            console.log('err:', err);
        }

    }
    const [stateBool_showAddNewCustomer, setStateBool_showAddNewCustomer] = useState(false);

    const show_AddNew_KH_GD = () => {
        setStateBool_showInputCredit_new(true);
        setStateBool_isValid_sothetindung(false);
        reset_inputThe();

        setStateBool_showGiaodichDetails(false);
        setStateBool_showAddNewCustomer(true);
        setStateBool_showAddNewGD(false); // phải cho nó false để nó ko hiện ra các phần thêm giao dịch

        setStateString_nhucau("Rút tiền"); // set cho mặc định ban đầu cho trường hợp thêm mới thôi
        setStateNumber_hanmucthetindung_makeup("");
        setStateNumber_hanmucthetindung(0);
        setStateString_ngayhanthanhtoan("");
        reset()
    }

    const save_new_KH_GD = async () => {

        if (stateString_nguonKH === "Chọn...") {
            alert("Bạn vui lòng chọn một nguồn khách hàng theo danh sách. Xin cảm ơn.");
            return;
        }

        // validate tên KH
        const result = stateString_tenkhachhang.length < 4 || stateString_tenkhachhang.length > 70 || !/[a-zA-Z]/.test(stateString_tenkhachhang) ? false : true;
        if (!result) {
            alert("Tên khách hàng phải có độ dài trong khoảng [4-70]");
            return;
        }

        // validate ntns
        let result_ntns = isValidDateFormat_dd_mm_yyyy(stateString_ntns);
        if (result_ntns === false) {
            alert("Ngày tháng năm sinh phải có định dạng dd-mm-yyyy");
            return;
        }

        let ntns = convertDateFormat_dd_mm_yyyy_to_yyyy_mm_dd(stateString_ntns);
        ntns = ntns + "T00:00:00.000Z"

        // cccd
        let resultCCCD = validateCCCD(stateString_cccd);
        // let resultCMND = validateCMND(stateString_cccd);
        if (!resultCCCD) {
            alert("CCCD chưa hợp lệ, vui lòng kiểm tra lại");
            return;
        }

        if (stateString_sothetindung === "" || stateString_sothetindung === null || stateString_sothetindung === undefined) {
            alert("Vui lòng nhập số trên thẻ tín dụng");
            return;
        }
        let result_soTheTinDung_validate = isValidCreditCard(stateString_sothetindung);
        if (!result_soTheTinDung_validate) {
            alert("Số thẻ tín dụng không hợp lệ, vui lòng kiểm tra lại")
            return;
        }

        const regex = /x/i; // The "i" flag makes the match case-insensitive

        // Test if the string contains the letter "x" or "X"
        if (regex.test(stateString_sothetindung)) { // nếu có chứa chữ x nghĩa là sai rồi
            alert("Số thẻ tín dụng không hợp lệ, vui lòng kiểm tra lại")
            return;
        }

        if (nameCitySelected === "" || nameDistrictSelected === "" || nameWardSelected === "" || stateString_address === "") {
            alert("vui lòng nhập địa chỉ, xin cảm ơn");
            return;
        }

        // phone
        const isValidPhoneNumber = validatePhoneNumber(stateString_sdt);
        if (isValidPhoneNumber === null) {
            alert("Số điện thoại chưa hợp lệ, vui lòng kiểm tra lại.");
            return;
        }
        // nhu cầu
        if (stateString_nhucau === "") {
            alert("Vui lòng chọn nhu cầu để xác định Rút hay Đáo, xin cảm ơn");
            return;
        }
        // tiền rút đáo
        const result_tienrutdao = validateTienrutdao(stateNumber_tienrutdao);
        if (result_tienrutdao === false) {
            alert("Vui lòng nhập tiền rút đáo hợp lệ là số từ 1 triệu đến 900 triệu, xin cảm ơn");
            return;
        }
        // phí rút đáo
        const result_phirutdao = validatePhirutdao(stateNumber_phirutdao);
        if (result_phirutdao === false) {
            alert("Vui lòng nhập phí rút đáo hợp lệ là số từ 0.5 đến 8, xin cảm ơn");
            return;
        }
        // ngày dự kiến liên hệ lại
        // const result_ngaydukienlienhelai = validateyyyy_mm_dd_hh_mm(stateString_ngaydukienlienhelai);
        // if(result_ngaydukienlienhelai === false){
        //     alert("Vui lòng nhập ngày giờ liên hệ lại hợp lệ, xin cảm ơn");
        //     return;
        // }
        // ngày hạn thanh toán
        let ngayhanthanhtoan_parse: string | null = null;
        // console.log('stateString_nhucau: ', stateString_nhucau);
        if (stateString_nhucau === "Rút tiền") {
            ngayhanthanhtoan_parse = null;
            if (stateString_tenNganHangChuyenTien === "") {
                alert("Không được bỏ trống tên ngân hàng chuyển tiền đến")
                if (inputRef_tenNganHangChuyenTien.current) {
                    inputRef_tenNganHangChuyenTien.current.focus();
                }
                return;
            }

            const obj2 = stateArrNganhang.find((obj) => obj.id === stateString_tenNganHangChuyenTien);
            if (obj2 === undefined || obj2 === null) {
                alert("Tên ngân hàng chuyển tiền bạn nhập không có trong hệ thống. Bạn vui lòng chọn lại ngân hàng chuyển tiền đến");
                return;
            }
        }

        else if (stateString_nhucau === "Đáo hạn") {
            const check_ngayhanthanhtoan = isValidDateFormat_dd_mm_yyyy(stateString_ngayhanthanhtoan);//check dd_mm_yyyy có đúng ko
            if (check_ngayhanthanhtoan === false) {
                alert("Ngày hạn đáo thẻ bị sai format");
                return;
            }
            ngayhanthanhtoan_parse = convertDateFormat_dd_mm_yyyy_to_yyyy_mm_dd(stateString_ngayhanthanhtoan);
            // console.log('ngaydukienlienhelai_parse: ', ngayhanthanhtoan_parse);
            ngayhanthanhtoan_parse = ngayhanthanhtoan_parse + "T00:00:00.000Z"
        }
        // validate tên KH
        const result_tentrenthe = stateString_tenTrenThe.length < 4 || stateString_tenTrenThe.length > 70 || !/[a-zA-Z]/.test(stateString_tenTrenThe) ? false : true;
        if (!result_tentrenthe) {
            alert("Tên trên thẻ phải có độ dài trong khoảng [4-70]");
            return;
        }

        // chi nhánh
        if (stateString_chinhanh === "empty" || stateString_chinhanh === "" || stateString_chinhanh === null || stateString_chinhanh === undefined) {
            alert("vui lòng chọn chi nhánh");
            return;
        }
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return;
        }

        if (stateString_tennganhang === "" || stateString_tennganhang === null || stateString_tennganhang === undefined) {
            alert("Bạn vui lòng chọn tên ngân hàng của thẻ !");
            return;
        }
        const obj1 = stateArrNganhang.find((obj) => obj.id === stateString_tennganhang);
        if (obj1 === undefined || obj1 === null) {
            alert("Tên ngân hàng của thẻ bạn nhập không có trong hệ thống. Bạn vui lòng chọn lại ngân hàng chuyển tiền đến");
            return;
        }
        if (stateString_dateCreateGD === "") {
            alert("Bạn vui lòng chọn ngày tạo giao dịch!");
            return;
        }

        let email = userExist.email;
        let fullName = userExist.fullName;
        let token = userExist.token;

        const KhGdTheStknhPhoneDto = {
            // phone
            // idKhachhang: number;
            "phone": stateString_sdt,

            //Khachhang
            // "createdAt": "",
            // editedAt: Date;
            "emailNVCSKHCreate": email,
            "fullNameNVCSKHcreate": fullName,
            "emailNVCSKHEdit": email,
            "fullNameNVCSKHedit": fullName,
            "fullNameKH": convertToTitleCase(stateString_tenkhachhang),
            "nguonKH": stateString_nguonKH,
            "nguonAgent": null,
            "city": nameCitySelected,
            "district": nameDistrictSelected,
            "ward": nameWardSelected,
            "address": stateString_address,
            "cccd": stateString_cccd,
            "ntns": ntns,
            // cccd_mt_mediaId: string,
            // cccd_mt_signedUrl: string,
            // cccd_ms_signedUrl: string,
            // cccd_ms_mediaId: string,
            // cccd_createdAt: Date,

            // Giaodich
            // idKhachhang: number,
            "createdAt": stateString_dateCreateGD + "T00:00:00.000Z",
            // createdAt: Date,
            // editedAt: Date,
            // emailNVCSKHCreate: string,
            // fullNameNVCSKHcreate: string,
            // emailNVCSKHEdit: string,
            "emailNVRutdaoCreate": email,
            "fullNameNVRutdaoCreate": fullName,
            "fullNameNVRutdaoEdit": fullName,
            "emailNVRutdaoEdit": email,
            // emailNVFinalApprove: email,
            // finalApprovedAt: Date,
            // isApproved: boolean,
            "nhucau": stateString_nhucau,
            "nganhangId": stateString_tennganhang,
            "tienrutdao": stateNumber_tienrutdao,
            "phirutdao": parseFloat(stateNumber_phirutdao + ""),
            "tienphi": removeDecimalAndRound(stateNumber_tienphi),
            "tienship": parseInt(stateNumber_tienship + ""),
            tienChuyenKhach: stateNumber_tienChuyenKhach,
            "tenchinhanh": stateString_chinhanh,
            "ngaydukienlienhelai": null,
            "ngayhanthanhtoan": ngayhanthanhtoan_parse,
            "trangthaiCSKH": "",
            "ghichurutdao": stateString_ghichurutdao,
            "ghichucskh": stateString_ghichuphigiaodich,
            "ghiChuPhiGiaoDich": stateString_ghichuphigiaodich,
            // "nhanvienship": string,
            // "diemdenship": string,
            // "ngayrutdao": Date,
            "loaigiaodich": "Giao dịch thật",
            // "teamrutdaoreceivedAt": Date,
            "teamrutdaothongbaotrangthai": "Chờ",
            // "teamrutdaothongbaotrangthaiAt": Date,
            "lydocuatrangthai": stateString_lydocuatrangthai,
            "noiDungChuyenTien": "",
            // "tienShipNhanVien": number,
            // "hinhGiaoDich": string[],
            // "hinhChuyenKhoanTienPhi_mediaId": string,
            // "hinhChuyenKhoanTienPhi_signedUrl": string,
            // "checkChuyenPhi": boolean,
            // "resultCheckChuyenPhi": string,

            // The
            // idKhachhang: number,
            // idGiaodich: number,
            "loaiThe": stateString_loaithe,
            "tenTrenThe": stateString_tenTrenThe,
            "soThe": stateString_sothetindung,
            // soTaiKhoanNganHang: string,
            // tenNganHangChuyenTien: string,
            "soTaiKhoanTheTinDung": stateString_soTaiKhoanTheTinDung,
            "hanMucTheTinDung": stateNumber_hanmucthetindung,
            "aiDangGiu": stateString_aiDangGiuThe,
            "hanDao": ngayhanthanhtoan_parse,

            //sotaikhoannganhang
            // idKhachhang: number,
            // idGiaodich: number,
            "soTaiKhoanNganHang": stateString_sotaikhoannganhang,
            "tenNganHangChuyenTien": stateString_tenNganHangChuyenTien,
        }
        console.log('KhGdTheStknhPhoneDto: ', KhGdTheStknhPhoneDto);
        // return;
        if (window.confirm("Bạn đã chắc chắn chưa ?") === false) {
            return;
        }

        try {
            let response = await collectionAPI.collectionAPI_save_data.create_Kh_Giaodich_The_Stknh_Phone(token, KhGdTheStknhPhoneDto);
            // console.log('response: ', response);
            if (response.hasOwnProperty("data") && typeof response.data === "object" && response.data.hasOwnProperty("result")) {
                if (response.data.result === "succeed") {
                    alert(response.data.message);
                    let khachhangId = parseInt(response.data.khachhangId + '')
                    selectGroup("Tất cả");
                    selectKH(khachhangId);
                    setStateBool_showAddNewCustomer(false);
                    setStateBool_showGiaodichDetails(false); // cho cái details của giao dịch ẩn đi, tránh hiện cái cũ lộn dữ liệu
                    reset();
                }
                else if (response.data.result === "fail") {
                    alert(response.data.message);
                    return;
                }
            } else {
                alert("Lưu dữ liệu không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào !");
                return;
            }
        } catch (err) {
            console.log('err:', err);
        }

    } // end save_new_KH_GD 
    const reset = () => {

        setStateString_tenkhachhang("");
        setStateString_ntns("");
        setStateString_cccd("");
        setNameCitiSelected("");
        setNameDistrictSelected("");
        setNameWardSelected("");
        setStateString_address("");
        setStateString_sdt("");
        setStateString_nhucau("");
        setStateNumber_tienrutdao(0);
        setStateNumber_tienrutdao_makeup("");
        setStateNumber_phirutdao(0);
        setStateNumber_tienphi(0);
        setStateNumber_tienChuyenKhach(0);
        setStateNumber_tienship(0);
        setStateString_diachiship("");
        setStateString_ngaydukienlienhelai("");
        setStateString_ngayhanthanhtoan("");
        setStateString_tennganhang("ABBANK");
        setStateString_loaithe("");
        setStateString_tenTrenThe("");
        setStateString_soTaiKhoanTheTinDung("");
        setStateString_sotaikhoannganhang("");
        setStateString_tenNganHangChuyenTien("ABBANK");
        setStateString_sothetindung_makeup("")
        setStateString_sothetindung("");
        setStateNumber_hanmucthetindung(0);
        setStateNumber_hanmucthetindung_makeup("");

        setStateString_dateCreateGD("");

        setStateString_chinhanh("");
        setStateString_ghichurutdao("");
        setStateString_ghichuphigiaodich("");
        setStateString_nhanvienship("");
    }
    const cancelAddNew_KH_GD = () => {

        setStateBool_showAddNewCustomer(false);
        setStateBool_showAddNewGD(false);
        // setStateBool_showGiaodichDetails(false);

    }
    const [stateBool_showAddNewGD, setStateBool_showAddNewGD] = useState(false);

    const show_AddNew_GD_the = () => {
        // console.log('stateNumber_selectedKH_id: ', stateNumber_selectedKH_id);
        if (stateNumber_selectedKH_id === 0 || stateNumber_selectedKH_id === undefined || stateNumber_selectedKH_id === null) {
            alert("Bạn vui lòng chọn một khách hàng, sau đó mới có thể thêm giao dịch & thẻ của khách hàng đó");
            return;
        }

        setStateBool_showInputCredit_new(true);
        setStateBool_isValid_sothetindung(false);
        reset_inputThe();

        setStateBool_showGiaodichDetails(false);
        setStateBool_showAddNewCustomer(false);
        setStateBool_showAddNewGD(true);
        reset(); // reset lại các field cho họ nhập
        setStateString_loaithe("Visa"); //Exception: cho loại thẻ ban đầu mặc định là Visa, ko khai báo thì nó sẽ có value là undefined
    }
    const save_new_GD_of_old_customer = async () => {
        // console.log('stateString_ngayhanthanhtoan: ', stateString_ngayhanthanhtoan);
        // nhu cầu
        if (stateString_nhucau === "") {
            alert("Vui lòng chọn nhu cầu để xác định Rút hay Đáo, xin cảm ơn");
            return;
        }
        // tiền rút đáo
        const result_tienrutdao = validateTienrutdao(stateNumber_tienrutdao);
        if (result_tienrutdao === false) {
            alert("Vui lòng nhập tiền rút đáo hợp lệ là số từ 1 triệu đến 900 triệu, xin cảm ơn");
            return;
        }
        // phí rút đáo
        const result_phirutdao = validatePhirutdao(stateNumber_phirutdao);
        if (result_phirutdao === false) {
            alert("Vui lòng nhập phí rút đáo hợp lệ là số từ 0.5 đến 5, xin cảm ơn");
            return;
        }
        // ngày dự kiến liên hệ lại
        // const result_ngaydukienlienhelai = validateyyyy_mm_dd_hh_mm(stateString_ngaydukienlienhelai);
        // if(result_ngaydukienlienhelai === false){
        //     alert("Vui lòng nhập ngày giờ liên hệ lại hợp lệ, xin cảm ơn");
        //     return;
        // }
        // ngày hạn thanh toán
        let ngayhanthanhtoan_parse: string | null = null;
        // console.log('stateString_nhucau: ', stateString_nhucau);
        if (stateString_nhucau === "Rút tiền") {
            ngayhanthanhtoan_parse = null;
            if (stateString_tenNganHangChuyenTien === "") {
                alert("Không được bỏ trống tên ngân hàng chuyển tiền đến")
                if (inputRef_tenNganHangChuyenTien.current) {
                    inputRef_tenNganHangChuyenTien.current.focus();
                }
                return;
            }
            // console.log('stateString_tenNganHangChuyenTien: ', stateString_tenNganHangChuyenTien);
            const obj2 = stateArrNganhang.find((obj) => obj.id === stateString_tenNganHangChuyenTien);
            if (obj2 === undefined || obj2 === null) {
                alert("Tên ngân hàng chuyển tiền bạn nhập không có trong hệ thống. Bạn vui lòng chọn lại ngân hàng chuyển tiền đến");
                return;
            }
        }
        else if (stateString_nhucau === "Đáo hạn") {
            const check_ngayhanthanhtoan = isValidDateFormat_dd_mm_yyyy(stateString_ngayhanthanhtoan);//check dd_mm_yyyy có đúng ko
            if (check_ngayhanthanhtoan === false) {
                alert("Ngày hạn đáo thẻ bị sai format dd-mm-yyyy");
                return;
            }
            ngayhanthanhtoan_parse = convertDateFormat_dd_mm_yyyy_to_yyyy_mm_dd(stateString_ngayhanthanhtoan);
            // console.log('ngaydukienlienhelai_parse: ', ngayhanthanhtoan_parse);
            ngayhanthanhtoan_parse = ngayhanthanhtoan_parse + "T00:00:00.000Z"
        }

        if (stateString_sothetindung === "" || stateString_sothetindung === null || stateString_sothetindung === undefined) {
            alert("Vui lòng nhập số trên thẻ tín dụng");
            return;
        }
        let result_soTheTinDung_validate = isValidCreditCard(stateString_sothetindung);
        if (!result_soTheTinDung_validate) {
            alert("Số thẻ tín dụng không hợp lệ, vui lòng kiểm tra lại")
            return;
        }

        const regex = /x/i; // The "i" flag makes the match case-insensitive

        // Test if the string contains the letter "x" or "X"
        if (regex.test(stateString_sothetindung)) { // nếu có chứa chữ x nghĩa là sai rồi
            alert("Số thẻ tín dụng không hợp lệ, vui lòng kiểm tra lại")
            return;
        }

        // validate tên KH
        const result_tentrenthe = stateString_tenTrenThe.length < 4 || stateString_tenTrenThe.length > 70 || !/[a-zA-Z]/.test(stateString_tenTrenThe) ? false : true;
        if (!result_tentrenthe) {
            alert("Tên trên thẻ phải có độ dài trong khoảng [4-70]");
            return;
        }

        if (stateString_loaithe === undefined || stateString_loaithe === "" || stateString_loaithe === null) {
            alert("Vui lòng chọn loại thẻ");
            return;
        }

        // chi nhánh
        if (stateString_chinhanh === "empty" || stateString_chinhanh === "" || stateString_chinhanh === null || stateString_chinhanh === undefined) {
            alert("vui lòng chọn chi nhánh");
            return;
        }
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if (userExist === null) {
            return;
        }
        if (stateString_tennganhang === "" || stateString_tennganhang === null || stateString_tennganhang === undefined) {
            alert("Bạn vui lòng chọn tên ngân hàng của thẻ !");
            return;
        }
        const obj1 = stateArrNganhang.find((obj) => obj.id === stateString_tennganhang);
        if (obj1 === undefined || obj1 === null) {
            alert("Tên ngân hàng bạn nhập không có trong hệ thống. Bạn vui lòng chọn lại ngân hàng chuyển tiền đến");
            return;
        }

        let email = userExist.email;
        let fullName = userExist.fullName;
        let token = userExist.token;

        const now = getCurrentDateTimeVietNam()


        const GdTheStknhPhoneDto = {

            // Giaodich
            "idKhachhang": stateNumber_selectedKH_id,
            "createdAt": stateString_dateCreateGD + "T00:00:00.000Z",
            "emailNVCSKHCreate": email,
            "fullNameNVCSKHcreate": fullName,
            "emailNVCSKHEdit": email,
            "emailNVRutdaoCreate": email,
            "fullNameNVRutdaoCreate": fullName,
            "fullNameNVRutdaoEdit": fullName,
            "emailNVRutdaoEdit": email,
            // emailNVFinalApprove: email,
            // finalApprovedAt: Date,
            // isApproved: boolean,
            "nhucau": stateString_nhucau,
            "nganhangId": stateString_tennganhang,
            "tienrutdao": stateNumber_tienrutdao,
            "phirutdao": parseFloat(stateNumber_phirutdao + ""),
            "tienphi": removeDecimalAndRound(stateNumber_tienphi),
            "tienship": parseInt(stateNumber_tienship + ""),
            "tienChuyenKhach": stateNumber_tienChuyenKhach,
            "tenchinhanh": stateString_chinhanh,
            "ngaydukienlienhelai": null,
            "ngayhanthanhtoan": ngayhanthanhtoan_parse,
            "trangthaiCSKH": "",
            "ghichurutdao": stateString_ghichurutdao,
            "ghichucskh": stateString_ghichu,
            "ghiChuPhiGiaoDich": stateString_ghichuphigiaodich,
            // "nhanvienship": string,
            // "diemdenship": string,
            // "ngayrutdao": Date,
            "loaigiaodich": "Giao dịch thật",
            // "teamrutdaoreceivedAt": Date,
            "teamrutdaothongbaotrangthai": "Chờ",
            // "teamrutdaothongbaotrangthaiAt": Date,
            "lydocuatrangthai": stateString_lydocuatrangthai,
            "noiDungChuyenTien": "",
            // "tienShipNhanVien": number,
            // "hinhGiaoDich": string[],
            // "hinhChuyenKhoanTienPhi_mediaId": string,
            // "hinhChuyenKhoanTienPhi_signedUrl": string,
            // "checkChuyenPhi": boolean,
            // "resultCheckChuyenPhi": string,

            // The
            // idKhachhang: number,
            // idGiaodich: number,
            "loaiThe": stateString_loaithe,
            "tenTrenThe": stateString_tenTrenThe,
            "soThe": stateString_sothetindung,
            // soTaiKhoanNganHang: string,
            // tenNganHangChuyenTien: string,
            "soTaiKhoanTheTinDung": stateString_soTaiKhoanTheTinDung,
            "hanMucTheTinDung": stateNumber_hanmucthetindung,
            "aiDangGiu": stateString_aiDangGiuThe,
            "hanDao": ngayhanthanhtoan_parse,

            //sotaikhoannganhang
            // idKhachhang: number,
            // idGiaodich: number,
            "soTaiKhoanNganHang": stateString_sotaikhoannganhang,
            "tenNganHangChuyenTien": stateString_tenNganHangChuyenTien,
        }
        // console.log('GdTheStknhPhoneDto: ', GdTheStknhPhoneDto);
        // return;
        if (window.confirm("Bạn đã chắc chắn chưa ?") === false) {
            return;
        }

        try {
            if (GdTheStknhPhoneDto.loaiThe === undefined || GdTheStknhPhoneDto.loaiThe === "" || GdTheStknhPhoneDto.loaiThe === null) {
                alert("Vui lòng chọn loại thẻ");
                return;
            }
            if (GdTheStknhPhoneDto.tenTrenThe === undefined || GdTheStknhPhoneDto.tenTrenThe === "" || GdTheStknhPhoneDto.tenTrenThe === null) {
                alert("Vui lòng nhập tên trên thẻ");
                return;
            }
            if (GdTheStknhPhoneDto.soThe === undefined || GdTheStknhPhoneDto.soThe === "" || GdTheStknhPhoneDto.soThe === null) {
                alert("Vui lòng nhập số trên thẻ");
                return;
            }

            // tạo giao dịch mới
            let response = await collectionAPI.collectionAPI_save_data.create_Giaodich_The_Stknh_Phone(token, GdTheStknhPhoneDto);
            // console.log('response: ', response);
            if (response.hasOwnProperty("data") && typeof response.data === "object" && response.data.hasOwnProperty("result")) {
                if (response.data.result === "succeed") {
                    alert(response.data.message);
                    setStateBool_showAddNewCustomer(false);
                    setStateBool_showAddNewGD(false);
                    let idGiaodich = response.data.idGiaodich;
                    findAllkhachhangByNguonKHByDateRange_Thisweek()
                    await wait(100);
                    selectKH(stateNumber_selectedKH_id);
                    await wait(100);
                    selectGiaodich(idGiaodich);
                    reset(); //reset lại các field
                    setStateBool_showGiaodichDetails(false); // cho cái details của giao dịch ẩn đi, tránh hiện cái cũ lộn dữ liệu
                }
            } else {
                alert("Lưu dữ liệu không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào !");
            }
            // console.log('objGiaodich: ' + JSON.stringify(objGiaodich, null, 4));
            // let responseGiaodich = await collectionAPI.collectionAPI.createGiaodich(token, objGiaodich);

            // if (responseGiaodich.status !== 201) {
            //     alert("Lưu dữ liệu không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào ! ");
            //     return;
            // }
            // await wait(100); // dừng lại 0.1 giây để nó lấy id của giao dịch

            // let giaoDichIdNew;
            // const maxAttempts = 5; // Maximum number of attempts
            // let attempt = 0;
            // while (!giaoDichIdNew && attempt < maxAttempts) {
            //     await wait(200); // Wait for 0.5 seconds

            //     // Check if idKhachhang exists in response data
            //     if (responseGiaodich?.data?.id) {
            //         giaoDichIdNew = responseGiaodich.data.id;
            //     } else {
            //         attempt++;
            //         // Wait for another second if idKhachhang is still not available
            //         await wait(200);
            //     }
            // }

            // // tạo thẻ mới
            // objThe.idGiaodich = giaoDichIdNew;
            // let responseThe = await collectionAPI.collectionAPI.createThe(token, objThe);

            // if (responseThe.status !== 201) {
            //     alert("Lưu dữ liệu thẻ không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào ! ");
            //     return;
            // }
            // // tạo STKNH mới
            // await wait(100); 
            // // tạo so tai khoan ngan hang moi
            // if(stateString_nhucau === "Rút tiền"){

            //     objSTKNH.idKhachhang = stateNumber_selectedKH_id;
            //     objSTKNH.idGiaodich = giaoDichIdNew;
            //     let responseSTKNH = await collectionAPI.collectionAPI_SoTaiKhoanNganHang.create(token, objSTKNH);
            //     if (responseSTKNH.status !== 201) {
            //         alert("Lưu dữ liệu STK NH không thành công. Vui lòng liên hệ Mr. Vũ (0982 547 769) để xử lý gấp, có thể liên hệ bất cứ lúc nào: ");
            //         return;
            //     }
            // }

            // alert("Đã lưu dữ liệu giao dịch thành công, bạn đã có thể tiến hành Rút và Đáo với giao dịch này");
            // // reset();
            // setStateBool_showAddNewCustomer(false);
            // setStateBool_showAddNewGD(false);
            // await wait(100);
            // selectKH(stateNumber_selectedKH_id);
            // await wait(100);
            // selectGiaodich(giaoDichIdNew);
            // reset(); //reset lại các field
            // setStateBool_showGiaodichDetails(false); // cho cái details của giao dịch ẩn đi, tránh hiện cái cũ lộn dữ liệu
        } catch (err) {
            console.log('err:', err);
        }

    } // end save_new_GD_of_old_customer
    const backToPostDao = () => {

        setStateBool_btnEditDaoShow(false); // ẩn nút cập nhật dữ liệu vàng đi quay lại nút thêm
        selectGiaodich(stateNum_idGiaodich);
        setStateString_sobill("");
        setStateNum_rut_in_dao(0);
        setStateNum_nap(0);
        setStateNum_rut_in_dao_makeup('')
        setStateNum_nap_makeup('')
        setStateString_mayPosId('empty');
        setStateNum_RutId_to_edit(0);
        setStateNum_daoId_to_edit(0);
        click_rut_dao("Đáo hạn");
    }

    const [stateString_mediaId_rut, setStateString_mediaId_rut] = useState<string>('');
    const receive_mediaId_rut_FromChild_notId = (mediaId: string) => {
        setStateString_mediaId_rut(mediaId);
    }
    const receive_mediaId_rut_FromChild = async (mediaId: string, idRut: number) => {
        const token = stateObjSignIn.token;
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.updateFieldRutById(token, idRut, "mediaId", mediaId);

            await wait(500);
            selectGiaodich(stateNum_idGiaodich);
            setStateString_mediaId_rut('');
        } catch (err) {
            console.log('err:', err);
        }
    }
    const [stateString_mediaId_dao, setStateString_mediaId_dao] = useState<string | ''>('');
    const receive_mediaId_dao_FromChild = async (mediaId: string, idDao: number) => {
        const token = stateObjSignIn.token;
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.updateFieldDaoById(token, idDao, "mediaId_rut", mediaId);

            await wait(500);
            selectGiaodich(stateNum_idGiaodich);
            setStateString_mediaId_dao('');
        } catch (err) {
            console.log('err:', err);
        }
    }
    const receive_mediaId_dao_FromChild_notId = async (mediaId: string) => {
        setStateString_mediaId_dao(mediaId);

    }
    const receive_mediaId_nap_FromChild_notId = async (mediaId: string) => {
        setStateString_mediaId_nap(mediaId);

    }
    const [stateString_mediaId_nap, setStateString_mediaId_nap] = useState<string>('');
    const receive_mediaId_nap_FromChild = async (mediaId: string, idDao: number) => {
        const token = stateObjSignIn.token;
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.updateFieldDaoById(token, idDao, "mediaId_nap", mediaId);

            await wait(500);
            selectGiaodich(stateNum_idGiaodich);
            setStateString_mediaId_nap('');
        } catch (err) {
            console.log('err:', err);
        }
    }

    // debouncer cho ô input kiểm tra sđt tồn tại --------------------start
    const [inputPhoneExist, setInputPhoneExist] = useState<string>('');

    // // Debounce function
    // const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    //     let timeoutId: NodeJS.Timeout;
    //     return (...args: Parameters<F>) => {
    //         clearTimeout(timeoutId);
    //         timeoutId = setTimeout(() => func(...args), delay);
    //     };
    // };

    // // Debounced search function (replace with your actual search function)
    // const debouncedSearch = debounce((value: string) => {
    //     // console.log('Searching for:', value); // Replace with your actual search logic
    // }, 5000); // Adjust the delay as needed (e.g., 1000ms = 1 second)



    const handleChangeSeachInputPhoneExist = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputPhoneExist(value);
        // debouncedSearch(value); // Call debounced function
    };


    const checkPhoneExist = async () => {
        if (inputPhoneExist === '') {
            alert("Bạn vui lòng nhập số điện thoại")
            return;
        }
        const resultValidate = validatePhoneNumberBoolean(inputPhoneExist);
        if (!resultValidate) {
            alert(`Số điện thoại ${inputPhoneExist} không hợp lệ`)
            return;
        }
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.checkPhone(stateObjSignIn.token, inputPhoneExist);
            // console.log('response: ', response);
            if (response.data.warning === "existed") {
                alert(`SĐT đã tồn tại của khách hàng ${response.data.result.khachhang.fullNameKH}`)
                return;
            }
            else {
                alert("số điện thoại này chưa tồn tại trong hệ thống")
                return;
            }
        } catch (err) {
            console.log('err:', err);
        }
    }
    // debouncer cho ô input kiểm tra sđt tồn tại --------------------end

    const [stateString_inputExistKH, setStateString_inputExistKH] = useState<string>('');
    const handleChangeSeachInputKHExist = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setStateString_inputExistKH(value);
    };

    const checkKHExist = async () => {
        if (stateString_inputExistKH === '') {
            alert("Bạn vui lòng nhập nội dung tìm kiếm");
            findAllkhachhangByNguonKHByDateRange_Thisweek()
            return;
        }
        let type = "";
        const resultCheck = checkStringType(stateString_inputExistKH)
        if (resultCheck === "invalid" || resultCheck === "digit_letter") {
            alert("Nội dung tìm không được chứa cả chữ và số, không được chứa ký tự đặc biệt")
            return;
        }
        else if (resultCheck === "letter") {
            type = "name"
        }
        else if (resultCheck === "digit") {
            const resultValidate = validatePhoneNumberBoolean(stateString_inputExistKH);
            if (!resultValidate) {
                alert(`Số điện thoại ${stateString_inputExistKH} không hợp lệ`)
                return;
            }
            type = "phone"
        }
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.findKhachhangByNameOrPhone(stateObjSignIn.token, type, stateString_inputExistKH);
            // console.log('response: ', response);
            if (response.data!.success === false) {
                alert(response.data.error)
                return;
            }
            setStateArr_khachHangByNguonKH(response.data.data); // lưu lại danh sách group nhân viên đc click vào, và giữ tạm ở đó
            setStateArr_khachHangByNguonKHShow(response.data.data); // hiện ra màn hình
        } catch (err) {
            console.log('err:', err);
        }
    }

    const updateRutCreatedAt = async (index: number, id: number, createdAt: string) => {

        let token = stateObjSignIn.token;
        let data = {
            createdAt: createdAt + "T00:00:00.000Z"
        }
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.updateRutById_partial(token, id, data);
            // console.log('response.data: ', response.data);  
            if (response.data.success === true) {
                // alert("save ngày tạo thành công !");
                // selectGiaodich(stateNum_idGiaodich);
                setStateStringAlertMessage("Lưu Ngày tạo thành công");
                setStateBoolAlert(true);
            }
        } catch (err) {
            console.log('err:', err);
        }

    }

    const updateDaoCreatedAt = async (index: number, id: number, createdAt: string) => {

        let token = stateObjSignIn.token;
        let data = {
            createdAt: createdAt + "T00:00:00.000Z"
        }
        let response: any;
        try {
            response = await collectionAPI.collectionAPI.updateDaoById_partial(token, id, data);
            // console.log('response.data: ', response.data);  
            if (response.data.success === true) {
                // alert("save ngày tạo thành công !");
                // selectGiaodich(stateNum_idGiaodich);
                setStateStringAlertMessage("Lưu Ngày tạo thành công");
                setStateBoolAlert(true);

            }
        } catch (err) {
            console.log('err:', err);
        }

    }
    useEffect(() => {
        if (stateBoolAlert) {
            // Set a timeout to toggle stateBoolAlert after 3 seconds
            const timer = setTimeout(() => {
                setStateBoolAlert(false);
                setStateStringAlertMessage(''); // Clear the alert message
            }, 3000);

            // Clean up the timer on component unmount or when stateBoolAlert changes
            return () => clearTimeout(timer);
        }
    }, [stateBoolAlert]);
    return (
        stateObjSignIn.token === "" ? <NotYetSignIn /> :
            <div className={clsx(styles.component_RutDaoKhachHang)}>
                {stateBoolAlert && <Alert variant="filled" severity="success" style={{ position: 'absolute', left: '1rem', bottom: '5%' }}>{stateStringAlertMessage}</Alert>}
                <div className={clsx(styles.KHWrapper, { [styles.contactsWrapper_2_col]: stateBool_contactsWrapper_2_col }, { [styles.contactsWrapper_1_col]: stateBool_contactsWrapper_1_col })}>
                    {/* <div className={clsx(styles.groups, {[styles.invisibleGroup]: stateBool_invisible_groups})}> 
                    <div className={clsx(styles.top)}>
                        <div className={clsx(styles.title)}>
                            <h4>Nguồn KH </h4>
                            <span >
                                <KeyboardDoubleArrowLeftIcon 
                                    className={clsx(styles.leftArrow)} 
                                    onClick={invisible_groups}
                                />
                            </span>
                        </div>
                        {
                            stateArrNguonKH.map((obj, index) => {
                                return ( 
                                    <div
                                        className={clsx(styles.oneSubGroup, {
                                        [styles.selectedGroup]: obj.id === stateString_selectedGroup,
                                        })}
                                        key={obj.id}
                                        onClick={() => selectGroup(obj.id)}
                                    >
                                        <PeopleOutlineIcon className={clsx(styles.icon, {
                                        [styles.selectedGroup]: obj.id === stateString_selectedGroup,
                                        })} />

                                        <p className={clsx(styles.groupName)}>{obj.name}</p>
                                    </div>
                                )
                            })
                        }
                        
                        {
                            stateArrNguonKH.map((obj, index) => (
                                obj.name !== "Rút đáo" && (
                                    <div
                                        className={clsx(styles.oneSubGroup, {
                                        [styles.selectedGroup]: obj.id === stateString_selectedGroup,
                                        })}
                                        key={obj.id}
                                        onClick={() => selectGroup(obj.id)}
                                    >
                                        <PeopleOutlineIcon className={clsx(styles.icon, {
                                        [styles.selectedGroup]: obj.id === stateString_selectedGroup,
                                        })} />

                                        <p className={clsx(styles.groupName)}>{obj.name}</p>
                                    </div>
                                )
                            ))
                        }
                    </div>
                    <div className={clsx(styles.notifications)}>
                        <div className={clsx(styles.row, styles.cho)}>
                            <p className={clsx(styles.key)}>Chờ</p>
                            <p className={clsx(styles.value)}>{stateObjReportCount.cho}</p>
                        </div>
                        <div className={clsx(styles.row, styles.doing)}>
                            <p className={clsx(styles.key)}>Đang rút</p>
                            <p className={clsx(styles.value)}>{stateObjReportCount.dangRut}</p>
                        </div>
                        <div className={clsx(styles.row, styles.doing)}>
                            <p className={clsx(styles.key)}>Đang đáo</p>
                            <p className={clsx(styles.value)}>{stateObjReportCount.dangDao}</p>
                        </div>
                    </div>
                </div> */}
                    {/* end group */}
                    <div className={clsx(styles.listKHWrapper, { [styles.invisible_listKHWrapper]: stateBool_invisible_listKHWrapper })}>
                        <div className={clsx(styles.nguonKHWrapper)}>
                            <p className={clsx(styles.key)}>Nguồn KH:</p>
                            <select name="from" onChange={handleChange_nguonKH_selectGroup} className={clsx(styles.selectOption)} value={stateString_nguonKH_selectGroup}>
                                <option value="Chọn...">Chọn...</option>
                                <option value="Tất cả">Tất cả</option>
                                {
                                    stateArrNguonKH.map((obj, index) => {
                                        return (
                                            <option key={obj.id} value={obj.id}>{obj.id}</option>
                                        )
                                    })
                                }
                            </select>
                            <span className={clsx(styles.iconWrapper)}>
                                <Tooltip title="Tạo khách hàng mới và giao dịch mới" placement="top">
                                    {/* <AddCircleOutlineIcon className={clsx(styles.iconAdd)} onClick={show_AddNew_KH_GD}/> */}
                                    <button onClick={show_AddNew_KH_GD} className={clsx(styles.btnAddKH)}>Thêm KH</button>
                                </Tooltip>
                            </span>
                        </div>
                        <div className={clsx(styles.titleWrapper)}>
                            {/* {
                            stateBool_invisible_groups &&
                            <KeyboardDoubleArrowRightIcon className={clsx(styles.rightArrow)} onClick={visible_nguonKH}/>
                        }
                        {
                            !stateBool_invisible_groups &&
                            <KeyboardDoubleArrowLeftIcon 
                                className={clsx(styles.leftArrow_when_3_col)} 
                                onClick={invisible_groups_and_listKHWrapper}
                            />
                        } */}
                            <h3>Danh sách khách hàng</h3>

                        </div>
                        <div className={clsx(styles.navigation)}>
                            <div className={clsx(styles.timeWrapper)}>
                                <select name="from" onChange={handleChange_timeFrame} className={clsx(styles.selectOption)} value={stateString_timeFrame}>
                                    <option value="Hôm nay">Hôm nay</option>
                                    {/* <option value="Hôm qua">Hôm qua</option>
                                <option value="2 ngày trước">2 ngày trước</option>
                                <option value="3 ngày trước">3 ngày trước</option>
                                <option value="4 ngày trước">4 ngày trước</option> */}
                                    <option value="Tuần này">Tuần này</option>
                                    <option value="Tháng này">Tháng này</option>
                                    <option value="Năm nay">Năm nay</option>
                                    <option value="01-2024">01-2024</option>
                                    <option value="02-2024">02-2024</option>
                                    <option value="03-2024">03-2024</option>
                                    <option value="04-2024">04-2024</option>
                                    <option value="05-2024">05-2024</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                </select>
                            </div>
                            <div className={clsx(styles.dateRange)}>
                                <Tooltip title="Ngày bắt đầu" placement="left">
                                    <input type="date"
                                        className={clsx(styles.inputDate)}
                                        value={stateString_startTime}
                                        onChange={handleChange_startTime} />
                                </Tooltip>
                                <Tooltip title="Ngày kết thúc" placement="left">
                                    <input type="date"
                                        className={clsx(styles.inputDate)}
                                        value={stateString_endTime}
                                        onChange={handleChange_endTime} />
                                </Tooltip>
                            </div>
                            <div className={clsx(styles.aiTao)}>
                                <p className={clsx(styles.item)}>All</p>
                                <Switch
                                    checked={stateBool_switchChecked}
                                    onChange={handleSwitch}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="warning"
                                    className={clsx(styles.switch)}
                                />
                                <p className={clsx(styles.item)}>Tôi tạo</p>
                            </div>
                        </div>
                        <div className={clsx(styles.myWork)}>
                            {/* <div className={clsx(styles.box)}>
                                <input type="radio" name="myWork" checked={stateString_navigation_myWork === "All"} value="All" className={clsx(styles.item)}
                                    onChange={handleChange_navigation_myWork} /> <span>All</span>
                            </div>
                            <div className={clsx(styles.box)}>
                                <input type="radio" name="myWork" value="Tôi tạo" className={clsx(styles.item)}
                                    onChange={handleChange_navigation_myWork} /> <span>Tôi tạo</span>
                            </div> */}
                            {/* <div className={clsx(styles.box)}>
                                <input type="radio" name="myWork" value="Tôi sửa" className={clsx(styles.item)}
                                    onChange={handleChange_navigation_myWork} /> <span>Tôi sửa</span>
                            </div> */}
                            <div className={clsx(styles.box)}>
                                <div className={clsx(styles.searchGroup)}>
                                    {/* <SearchIcon className={clsx(styles.iconSearch)} /> */}
                                    <p className={clsx(styles.btnCheck)} onClick={checkKHExist}>Tìm tất cả</p>
                                    <input
                                        type="text"
                                        placeholder="KH theo tên, sđt ..."
                                        value={stateString_inputExistKH}
                                        onChange={handleChangeSeachInputKHExist}
                                    />
                                </div>
                            </div>

                            {/* <div className={clsx(styles.box)}>
                                <div className={clsx(styles.searchGroup)}>
                                    <p className={clsx(styles.btnCheck)} onClick={checkPhoneExist}>Check</p>
                                    <input
                                        type="text"
                                        placeholder="SĐT cũ ..."
                                        value={inputPhoneExist}
                                        onChange={handleChangeSeachInputPhoneExist}
                                    />
                                </div>
                            </div> */}
                            <div className={clsx(styles.searchWrapper)}>
                                <div className={clsx(styles.searchGroup)}>
                                    <SearchIcon className={clsx(styles.iconSearch)} />
                                    <input
                                        type="text"
                                        placeholder="Tìm tên, sđt, cccd..."
                                        value={stateString_searchText}
                                        onChange={handleChangeSeachInput}
                                    />
                                </div>
                            </div>
                            {/* <span className={clsx(styles.nhucauInner)}>
                            <div className={clsx(styles.box)}>
                                <input type="radio" name="nhucau" checked={stateString_navigation_nhucau === "All"} value="All" className={clsx(styles.item)} 
                                onChange={handleChange_navigation_nhucau}/> <span>All</span>
                            </div>
                            <div className={clsx(styles.box)}>
                                <input type="radio" name="nhucau" checked={stateString_navigation_nhucau === "Rút tiền"} value="Rút tiền" className={clsx(styles.item)} 
                                onChange={handleChange_navigation_nhucau}/> <span>Rút tiền</span>
                            </div>
                            <div className={clsx(styles.box)}>
                                <input type="radio" name="nhucau" value="Đáo hạn" className={clsx(styles.item)} 
                                onChange={handleChange_navigation_nhucau}/> <span>Đáo hạn</span>
                            </div>
                        </span> */}
                            {/* <span className={clsx(styles.myWork)}>
                            <div className={clsx(styles.box)}>
                                <input type="radio" name="myWork" checked={stateString_navigation_myWork === "All"} value="All" className={clsx(styles.item)} 
                                onChange={handleChange_navigation_myWork}/> <span>All</span>
                            </div>
                            <div className={clsx(styles.box)}>
                                <input type="radio" name="myWork" value="Tôi tạo" className={clsx(styles.item)} 
                                onChange={handleChange_navigation_myWork}/> <span>Tôi tạo</span>
                            </div>
                            <div className={clsx(styles.box)}>
                                <input type="radio" name="myWork" value="Tôi sửa" className={clsx(styles.item)} 
                                onChange={handleChange_navigation_myWork}/> <span>Tôi sửa</span>
                            </div>
                        </span> */}
                        </div>
                        {/* <div className={clsx(styles.navigation_teamrutdaothongbaotrangthai)}>
                    
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="trangthai" checked={stateString_navigation_teamrutdaothongbaotrangthai === "Chờ"} value="Chờ" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai}/> <span>Chờ</span>
                        </div>
                        <div className={clsx(styles.box)}>
                            {
                                stateString_navigation_nhucau === "Rút tiền" ?
                                    <>
                                        <input type="radio" name="trangthai"  value="Đang Rút" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai}/> <span>Đang Rút</span>
                                    </>
                                    :
                                    <>
                                        <input type="radio" name="trangthai"  value="Đang đáo" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai}/> <span>Đang đáo</span>
                                    </>
                            }
    
                        </div>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="trangthai" value="Kế toán" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai}/> <span>Kế toán</span>
                        </div>
                        <div className={clsx(styles.box)}>
                            <input type="radio" name="trangthai" value="Hủy" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai}/> <span>Hủy</span>
                        </div>
                    </div> */}

                        {
                            !stateBool_invisible_groups &&
                            <div className={clsx(styles.listKH_mini)}>
                                <div className={clsx(styles.KHRow, styles.heading)}>
                                    <p className={clsx(styles.cellHeadID)}>ID KH</p>
                                    <p className={clsx(styles.cellHead)} >Tên</p>
                                    <p className={clsx(styles.cellHead)} >SĐT</p>
                                    <p className={clsx(styles.cellHead)} >Cập nhật lúc</p>
                                </div>
                                <div className={clsx(styles.listKH)}>
                                    {stateArr_khachHangByNguonKHShow &&
                                        stateArr_khachHangByNguonKHShow.map((obj: any, index: any) => {
                                            if (obj.nguonKH === "agent") {
                                                return (
                                                    <div className={clsx(styles.KHRow, {
                                                        [styles.selectedKH]: obj.id === stateNumber_selectedKH_id
                                                    }, styles.agent)}
                                                        key={obj.id}
                                                        onClick={() => selectKH(obj.id)}>

                                                        <p>{obj.id}</p>
                                                        <p className={clsx(styles.cellRowKH)} >{convertToTitleCase(obj.fullNameKH)}</p>
                                                        <p className={clsx(styles.cellRow)}>
                                                            {obj.phone &&
                                                                obj.phone.map((item: any, phoneIndex: any) => (
                                                                    <span key={phoneIndex}>{replaceFirstFourLetters(formatPhoneNumber(item.phone))}<br /></span>
                                                                ))}
                                                        </p>
                                                        <p className={clsx(styles.cellRow)} >{convertDateFormat_removeYear(obj.editedAt)}</p>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className={clsx(styles.KHRow, {
                                                        [styles.selectedKH]: obj.id === stateNumber_selectedKH_id
                                                    })}
                                                        key={obj.id}
                                                        onClick={() => selectKH(obj.id)}>

                                                        <p>{obj.id}</p>
                                                        <p className={clsx(styles.cellRowKH)} >{convertToTitleCase(obj.fullNameKH)}</p>
                                                        <p className={clsx(styles.cellRow)}>
                                                            {obj.phone &&
                                                                obj.phone.map((item: any, phoneIndex: any) => (
                                                                    <span key={phoneIndex}>{replaceFirstFourLetters(formatPhoneNumber(item.phone))}<br /></span>
                                                                ))}
                                                        </p>
                                                        <p className={clsx(styles.cellRow)} >{convertDateFormat_removeYear(obj.editedAt)}</p>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {
                            stateBool_invisible_groups &&
                            <div className={clsx(styles.listKH_full)}>
                                <div className={clsx(styles.KHRow, styles.heading)}>
                                    <p>ID KH</p>
                                    <p className={clsx(styles.cellHead)} >Tên</p>
                                    <p className={clsx(styles.cellHead)} >SĐT</p>
                                    <p className={clsx(styles.cellHead)} >CCCD</p>
                                    <p className={clsx(styles.cellHead)} >Cập nhật lúc</p>
                                </div>
                                <div className={clsx(styles.listKH)}>
                                    {stateArr_khachHangByNguonKHShow &&
                                        stateArr_khachHangByNguonKHShow.map((obj, index) => {
                                            // let arrStatus = obj.giaodich.map((item:any) => item.teamrutdaothongbaotrangthai); //tạo mảng kiểu số arrScores, chỉ chứa các điểm số của mỗi sinh viên
                                            // const cho = arrStatus.some((element:any) => element.includes(""));
                                            // const thanhCong = arrStatus.some((element:any) => element.includes("Thành công"));
                                            // const dangDao = arrStatus.some((element:any) => element.includes("Đang đáo"));
                                            // const hong = arrStatus.some((element:any) => element.includes("Hủy"));
                                            // let status = ""
                                            // if(cho === false && dangDao === false && hong === true && thanhCong === false){
                                            //     status = "fail"
                                            // }
                                            // else if(cho || dangDao){
                                            //     status = "waiting"
                                            // }
                                            // else if(cho === false && dangDao === false && hong === false && thanhCong === true){
                                            //     status = "success"
                                            // }
                                            return (
                                                <div className={clsx(styles.KHRow, {
                                                    [styles.selectedKH]: obj.id === stateNumber_selectedKH_id
                                                })}
                                                    key={obj.id}
                                                    onClick={() => selectKH(obj.id)}>
                                                    <p>{obj.id}</p>
                                                    <p className={clsx(styles.cellRow)} >{obj.fullNameKH}</p>
                                                    <p className={clsx(styles.cellRow)}>
                                                        {obj.phone && obj.phone.map((item: any, phoneIndex: any) => (
                                                            <span key={phoneIndex}>{formatPhoneNumber(replaceFirstFourLetters(item.phone))}<br /></span>
                                                        ))}
                                                    </p>
                                                    <p className={clsx(styles.cellRow)} >{obj.cccd}</p>
                                                    <p className={clsx(styles.cellRow)} >{formatDateString(obj.editedAt)}</p>
                                                    {/* {
                                                (() => {
                                                    if (status === "waiting") {
                                                        return (
                                                            <p className={clsx(styles.iconWrapper)}>

                                                                <VerifiedIcon className={clsx(styles.xong)}/>
                                                            </p>

                                                            );
                                                        } 
                                                        else if (status === "success") {
                                                            return (
                                                                <p className={clsx(styles.iconWrapper)}>
                                                                    <VerifiedIcon className={clsx(styles.xong)}/>
                                                                </p>
                                                        );
                                                    }
                                                    else if (status === "fail") {
                                                        return (
                                                            <p className={clsx(styles.iconWrapper)}>
                                                                <ClearIcon className={clsx(styles.hong)}/>
                                                            </p>
                                                        );
                                                    }
                                                })()
                                            } */}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className={clsx(styles.giaodichSection)}>
                        <div className={clsx(styles.giaoDichGroup)}>
                            {
                                stateBool_showAddNewCustomer === false && stateBool_showAddNewGD == false &&
                                <>
                                    <div className={clsx(styles.titleWrapperDachSachGIaoDich)}>
                                        <span>
                                            {
                                                stateBool_invisible_groups && stateBool_arrow_lastLeftArrow &&
                                                <KeyboardDoubleArrowLeftIcon
                                                    className={clsx(styles.leftArrow_when_2_col)}
                                                    onClick={invisible_groups_and_listKHWrapper}
                                                />
                                            }
                                            {
                                                stateBool_invisible_listKHWrapper &&
                                                <KeyboardDoubleArrowRightIcon className={clsx(styles.rightArrow)} onClick={show_groups_and_listKHWrapper} />
                                            }
                                        </span>
                                        <div className={clsx(styles.navigation)}>
                                            <h3 className={clsx(styles.btn, { [styles.selected]: "giao dịch" === stateString_tab })} onClick={() => switchTab('giao dịch')}>Danh sách giao dịch</h3>
                                            <h3 className={clsx(styles.btn, { [styles.selected]: "thẻ" === stateString_tab })} onClick={() => switchTab('thẻ')}>Danh sách thẻ</h3>
                                        </div>
                                        <span>
                                            <Tooltip title="Tạo giao dịch, thẻ mới cho khách hàng này" placement="top">
                                                {/* <AddCircleOutlineIcon className={clsx(styles.iconAdd)} onClick={show_AddNew_GD_the}/> */}
                                                <button onClick={show_AddNew_GD_the} className={clsx(styles.btnIconAdd)}>Thêm GD, Thẻ</button>
                                            </Tooltip>
                                        </span>
                                    </div>
                                    <div className={clsx(styles.navigation_giaodich)}>
                                        <div className={clsx(styles.nhucau_mywork)}>
                                            <span className={clsx(styles.nhucau)}>
                                                <div className={clsx(styles.box)}>
                                                    <input type="radio" name="nhucau" checked={stateString_navigation_nhucau === "All"} value="All" className={clsx(styles.item)}
                                                        onChange={handleChange_navigation_nhucau} /> <span>All</span>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <input type="radio" name="nhucau" checked={stateString_navigation_nhucau === "Rút tiền"} value="Rút tiền" className={clsx(styles.item)}
                                                        onChange={handleChange_navigation_nhucau} /> <span>Rút tiền</span>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <input type="radio" name="nhucau" value="Đáo hạn" className={clsx(styles.item)}
                                                        onChange={handleChange_navigation_nhucau} /> <span>Đáo hạn</span>
                                                </div>
                                            </span>
                                            <span className={clsx(styles.myWork)}>
                                                <div className={clsx(styles.box)}>
                                                    <input type="radio" name="myWork_giaodich" checked={stateString_navigation_myWork_giaodich === "All"} value="All" className={clsx(styles.item)}
                                                        onChange={handleChange_navigation_myWork_giaodich} /> <span>All</span>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <input type="radio" name="myWork_giaodich" value="Tôi tạo" className={clsx(styles.item)}
                                                        onChange={handleChange_navigation_myWork_giaodich} /> <span>Tôi tạo</span>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <input type="radio" name="myWork_giaodich" value="Tôi sửa" className={clsx(styles.item)}
                                                        onChange={handleChange_navigation_myWork_giaodich} /> <span>Tôi sửa</span>
                                                </div>
                                            </span>
                                        </div>
                                        <div className={clsx(styles.navigation_teamrutdaothongbaotrangthai)}>

                                            <div className={clsx(styles.box)}>
                                                <input type="radio" name="trangthai" value="All" checked={stateString_navigation_teamrutdaothongbaotrangthai === "All"} className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai} /> <span>All</span>
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <input type="radio" name="trangthai" value="Chờ" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai} /> <span>Chờ</span>
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                {
                                                    stateString_navigation_nhucau === "Rút tiền" ?
                                                        <>
                                                            <input type="radio" name="trangthai" value="Đang Rút" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai} /> <span>Đang Rút</span>
                                                        </>
                                                        :
                                                        <>
                                                            <input type="radio" name="trangthai" value="Đang đáo" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai} /> <span>Đang đáo</span>
                                                        </>
                                                }

                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <input type="radio" name="trangthai" value="Kế toán" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai} /> <span>Kế toán</span>
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <input type="radio" name="trangthai" value="Hủy" className={clsx(styles.item)} onChange={handleChange_navigation_teamrutdaothongbaotrangthai} /> <span>Hủy</span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        stateString_tab === "giao dịch" ?
                                            <span className={clsx(styles.listGiaodich)}>
                                                <div className={clsx(styles.heading)}>
                                                    <p>ID Giao dịch</p>
                                                    <p>Nhân viên CSKH</p>
                                                    <p>Nhu cầu</p>
                                                    <p>Tiền rút đáo</p>
                                                    <p>Tạo lúc</p>
                                                    <p>Trạng thái</p>
                                                </div>
                                                <div className={clsx(styles.rowWrapper)}>

                                                    {
                                                        stateArr_giaoDichOfSelectedKH_show &&
                                                        stateArr_giaoDichOfSelectedKH_show.map((obj, index) => {
                                                            // if(stateNum_idGiaodich === undefined || stateNum_idGiaodich === 0 || stateNum_idGiaodich === null){
                                                            //     alert("Bạn vui lòng chọn 1 giao dịch muốn xử lý, Xin cảm ơn.");
                                                            //     // return ;
                                                            // }
                                                            // let idGIaodich_compare = 0;
                                                            // if(giaoDichId_redux === 0){
                                                            //     idGIaodich_compare = stateNum_idGiaodich;
                                                            // }
                                                            // else {
                                                            //     idGIaodich_compare = giaoDichId_redux;
                                                            // }
                                                            // console.log('stateNum_idGiaodich: ', stateNum_idGiaodich);
                                                            return (
                                                                <div
                                                                    className={clsx(styles.row, {
                                                                        [styles.selectedGiaodich]: obj.id === stateNum_idGiaodich,
                                                                    })}
                                                                    key={obj.id}
                                                                    onClick={() => selectGiaodich(obj.id)}
                                                                >
                                                                    <p>{obj.id}</p>
                                                                    <p>{obj.fullNameNVCSKHcreate}</p>
                                                                    <p>{obj.nhucau}</p>
                                                                    <p>{formatThoudsand(obj.tienrutdao)}</p>
                                                                    <p>{formatDateTime(obj.createdAt)}</p>
                                                                    <p>{obj.teamrutdaothongbaotrangthai}</p>
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                </div>
                                            </span>
                                            :
                                            <span className={clsx(styles.listThe)}>
                                                <div className={clsx(styles.heading)}>
                                                    <p>ID Thẻ</p>
                                                    <p>Loại thẻ</p>
                                                    <p>Ngân hàng</p>
                                                    <p>STK Thẻ tín dụng</p>
                                                    <p>Số thẻ</p>
                                                    <p>Tên trên thẻ</p>
                                                    <p>Người giữ thẻ</p>
                                                </div>
                                                <div className={clsx(styles.rowWrapper)}>

                                                    {stateArr_theOfSelectedKH_show &&
                                                        stateArr_theOfSelectedKH_show.map((obj, index) => {
                                                            return (
                                                                <div
                                                                    className={clsx(styles.row, {
                                                                        [styles.selectedGiaodich]: obj.id === stateNum_idThe,
                                                                    })}
                                                                    key={obj.id}
                                                                    onClick={() => selectThe(obj.idGiaodich, obj.id)}
                                                                >
                                                                    <p>{obj.id}</p>
                                                                    <p>{obj.loaiThe}</p>
                                                                    <p>{obj.nganhangId}</p>
                                                                    <p>{obj.soTaiKhoanTheTinDung}</p>
                                                                    <p>{obj.soThe}</p>
                                                                    <p>{obj.tenTrenThe}</p>
                                                                    <p>{obj.aiDangGiu}</p>
                                                                </div>
                                                            );
                                                        })
                                                    }

                                                </div>
                                            </span>

                                    }
                                </>
                            }

                        </div>
                        {/* kết thúc list diao dịch */}
                        {
                            stateBool_showGiaodichDetails &&
                            <>
                                <div className={clsx(styles.giaoDichDetails)}>
                                    <h3>Dữ liệu chi tiết của khách hàng và giao dịch được click vào</h3>
                                    <div className={clsx(styles.imageWrapper)}>

                                        {(() => {
                                            if (stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Chờ" && stateObj_giaoDichSelected.nhucau === "Rút tiền") {
                                                return (
                                                    <div className={clsx(styles.image)}>
                                                        <img src="../assets/status/rut1.png" alt="status" />
                                                    </div>
                                                )
                                            }
                                            else if (stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Chờ" && stateObj_giaoDichSelected.nhucau === "Đáo hạn") {
                                                return (
                                                    <div className={clsx(styles.image)}>
                                                        <img src="../assets/status/dao1.png" alt="status" />
                                                    </div>
                                                )
                                            }
                                            else if (stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Đang đáo") {
                                                return (
                                                    <div className={clsx(styles.image)}>
                                                        <img src="../assets/status/dao2.png" alt="status" />
                                                    </div>
                                                )
                                            }
                                            else if (stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Đang rút") {
                                                return (
                                                    <div className={clsx(styles.image)}>
                                                        <img src="../assets/status/rut2.png" alt="status" />
                                                    </div>
                                                )
                                            }
                                            else if ((
                                                stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Kế toán" || stateString_teamrutdaothongbaotrangthai === "Kế toán") && stateObj_giaoDichSelected.nhucau === "Đáo hạn") {

                                                return (
                                                    <div className={clsx(styles.image)}>
                                                        <img src="../assets/status/dao3.png" alt="status" />
                                                    </div>
                                                )
                                            }
                                            else if ((stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Kế toán" || stateString_teamrutdaothongbaotrangthai === "Kế toán") && stateObj_giaoDichSelected.nhucau === "Rút tiền") {

                                                return (
                                                    <div className={clsx(styles.image)}>
                                                        <img src="../assets/status/rut3.png" className="avatar" alt="avatar" />

                                                    </div>
                                                )
                                            }
                                            else if ((stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Hủy" || stateString_teamrutdaothongbaotrangthai === "Hủy") && stateObj_giaoDichSelected.nhucau === "Rút tiền") {

                                                return (
                                                    <div className={clsx(styles.image)}>
                                                        <img src="../assets/status/rut3fail.png" className="avatar" alt="avatar" />

                                                    </div>
                                                )
                                            }
                                            else if ((stateObj_giaoDichSelected.teamrutdaothongbaotrangthai === "Hủy" || stateString_teamrutdaothongbaotrangthai === "Hủy") && stateObj_giaoDichSelected.nhucau === "Đáo hạn") {

                                                return (
                                                    <div className={clsx(styles.image)}>
                                                        <img src="../assets/status/dao3fail.png" className="avatar" alt="avatar" />

                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                    <div className={clsx(styles.TopBtn)}>
                                        <button onClick={saveGiaodich} className={clsx(styles.btnSaveDuLieu)}>Lưu dữ liệu</button>
                                    </div>
                                    <div className={clsx(styles.inputSectionGeneral)}>
                                        <div className={clsx(styles.left)}>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>ID Khách hàng</p>
                                                <p className={clsx(styles.value)}>{stateObj_selectedKH.id}</p>
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Tạo dữ liệu KH lúc</p>
                                                <p className={clsx(styles.value)}>{formatDateTime(stateObj_selectedKH.createdAt)}</p>
                                            </div>
                                            {/* <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Sửa dữ liệu KH lúc</p>
                                            <p className={clsx(styles.value)}>{formatDateString(stateObj_selectedKH.editedAt)}</p>
                                        </div> */}
                                            {
                                                (() => {
                                                    if (stateObj_giaoDichSelected.fullNameNVCSKHcreate !== "") {
                                                        return (
                                                            <div className={clsx(styles.box)}>
                                                                {
                                                                    stateObj_selectedKH.nguonKH === "agent" ?
                                                                        <p className={clsx(styles.key)}>Tên hội viên tạo</p>
                                                                        :
                                                                        <p className={clsx(styles.key)}>NV CSKH tạo</p>

                                                                }
                                                                <p className={clsx(styles.value)}>{stateObj_giaoDichSelected.fullNameNVCSKHcreate}</p>
                                                            </div>
                                                        );
                                                    } else if (stateObj_giaoDichSelected.fullNameNVRutdaoCreate !== "") {
                                                        return (
                                                            <div className={clsx(styles.box)}>
                                                                <p className={clsx(styles.key)}>NV Rút đáo tạo</p>
                                                                <p className={clsx(styles.value)}>{stateObj_giaoDichSelected.fullNameNVRutdaoCreate}</p>
                                                            </div>
                                                        );
                                                    }
                                                })()
                                            }
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Tên KH</p>
                                                {/* <p className={clsx(styles.value)}>{stateObj_selectedKH.fullNameKH}</p> */}
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag)}
                                                    value={stateString_tenkhachhang}
                                                    onChange={handleInputChange_tenkhachhang}
                                                // placeholder='Nhập tên KH'
                                                />
                                            </div>

                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Nguồn KH</p>
                                                {
                                                    stateObj_selectedKH.nguonKH === "agent" ?
                                                        <p className={clsx(styles.value)}>Hội viên</p>
                                                        :
                                                        <p className={clsx(styles.value)}>{stateObj_selectedKH.nguonKH}</p>

                                                }
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Birthday</p>
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag, { [styles.wrong]: stateBool_valid_ntns })}
                                                    value={stateString_ntns}
                                                    onChange={handleChange_ntns}
                                                    placeholder='dd-mm-yyyy'
                                                />
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>CCCD</p>
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag, { [styles.wrong]: stateBool_valid_cccd })}
                                                    value={stateString_cccd}
                                                    onChange={handleChange_cccd}
                                                    placeholder='nhập CCCD'
                                                />
                                            </div>

                                            <div className={clsx(styles.box)}>
                                                <span className={clsx(styles.leftCity)}>
                                                    <p className={clsx(styles.key)}>Thành phố</p>
                                                    <span onClick={() => handleClickEditCity()} className={clsx(styles.editIcon)}><BorderColorIcon color="warning" /></span>
                                                </span>
                                                <p className={clsx(styles.value)}>{nameCitySelected}</p>
                                            </div>

                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Quận</p>
                                                <p className={clsx(styles.value)}>{nameDistrictSelected}</p>
                                            </div>

                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Phường</p>
                                                <p className={clsx(styles.value)}>{nameWardSelected}</p>
                                            </div>
                                            {
                                                stateBool_showEditCity &&
                                                <>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Chọn Thành phố</p>
                                                        <select name="citySelected" onChange={handleCity} disabled={stateBool_isDisable_city}
                                                            className={clsx(styles.selectOption)}
                                                        // value={nameCitySelected}
                                                        >
                                                            <option value="empty">Please select a city...</option>
                                                            {citiesOptions}
                                                        </select>
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Chọn Quận</p>
                                                        <select name="districtSelected"
                                                            onChange={handleDistrict}
                                                            disabled={stateBool_isDisable_district}
                                                            className={clsx(styles.selectOption)}
                                                        // value={nameDistrictSelected}
                                                        >
                                                            {districtsOptions}
                                                        </select>
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Chọn Phường</p>
                                                        <select name="wardSelected" onChange={handleWard}
                                                            disabled={stateBool_isDisable_ward} className={clsx(styles.selectOption)}
                                                        // value={nameWardSelected}
                                                        >
                                                            {wardsOptions}
                                                        </select>
                                                    </div>
                                                    <div className={clsx(styles.box, styles.btnDoneEditCityWrapper)}>
                                                        <button className={clsx(styles.btnDoneEditCity)} onClick={doneEditCity}>Thêm/sửa Thành phố xong</button>
                                                    </div>
                                                </>
                                            }

                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Số nhà tên đường</p>
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag)}
                                                    value={stateString_address}
                                                    onChange={handleAddress}
                                                    placeholder='nhập địa chỉ'
                                                />
                                            </div>
                                            {
                                                stateObj_selectedKH.phone?.length > 0 &&
                                                stateObj_selectedKH.phone.map((obj: any, index: any) => (
                                                    <div className={clsx(styles.box)} key={index}>
                                                        <p className={clsx(styles.key, styles.sdt)}>
                                                            <span>SĐT {index + 1}:</span>
                                                            <span className={clsx(styles.right)}>
                                                                <span onClick={() => handleClickDeleteIcon(obj.id)} className={clsx(styles.deleteIcon)}><DeleteForeverIcon color="error" /></span>
                                                                <span onClick={() => handleClickEditIcon(obj.id, obj.phone)} className={clsx(styles.editIcon)}><BorderColorIcon color="warning" /></span>
                                                            </span>
                                                        </p>
                                                        <p className={clsx(styles.value)}>{formatPhoneNumber(obj.phone)}</p>
                                                    </div>
                                                ))}
                                            <div className={clsx(styles.box)}>
                                                <div className={clsx(styles.btnWrapper)}>
                                                    {
                                                        stateBool_showEditPhoneBtn === false ?
                                                            <button onClick={addPhone} className={clsx(styles.addPhone)}>Thêm SĐT</button>
                                                            :
                                                            <span className={clsx(styles.GroupEditBtn)}>
                                                                <button onClick={editPhone}>Sửa SĐT</button>
                                                                <span onClick={backToCreatePhoneBtn}><SettingsBackupRestoreIcon /></span>
                                                            </span>

                                                    }
                                                </div>
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag)}
                                                    // value={formatPhoneNumber(stateString_sdt)}
                                                    value={stateString_sdt}
                                                    onChange={handleInputChange_sdt}
                                                    placeholder='nhập SĐT'
                                                />
                                            </div>
                                        </div>
                                        <div className={clsx(styles.middle)}>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Nhu cầu</p>
                                                {/* <select name="from" onChange={handleChange_nhucau} className={clsx(styles.selectOption)} value={stateString_nhucau}>
                                                <option value="Rút tiền mặt">Rút tiền mặt</option>
                                                <option value="Đáo hạn">Đáo hạn</option>
                                            </select> */}
                                                <div className={clsx(styles.ruttien_daohan)}>
                                                    {
                                                        (() => {
                                                            if (stateArr_dao_of_giaodich_selected.length > 0 && stateArr_rut_of_giaodich_selected.length === 0) {
                                                                return (
                                                                    <p className={clsx(styles.btn, { [styles.green]: "Đáo hạn" === stateString_nhucau })} onClick={() => click_rut_dao('Đáo hạn')}>ĐÁO</p>
                                                                );
                                                            }
                                                            else if (stateArr_dao_of_giaodich_selected.length === 0 && stateArr_rut_of_giaodich_selected.length > 0) {
                                                                return (
                                                                    <p className={clsx(styles.btn, { [styles.green]: "Rút tiền" === stateString_nhucau })} onClick={() => click_rut_dao('Rút tiền')}>RÚT</p>
                                                                );
                                                            }
                                                            else if (stateArr_dao_of_giaodich_selected.length === 0 && stateArr_rut_of_giaodich_selected.length === 0) {
                                                                return (
                                                                    <>
                                                                        <p className={clsx(styles.btn, { [styles.green]: "Rút tiền" === stateString_nhucau })} onClick={() => click_rut_dao('Rút tiền')}>RÚT</p>
                                                                        <p className={clsx(styles.btn, { [styles.green]: "Đáo hạn" === stateString_nhucau })} onClick={() => click_rut_dao('Đáo hạn')}>ĐÁO</p>
                                                                    </>
                                                                );
                                                            }
                                                        })()
                                                    }
                                                </div>

                                            </div>

                                            {/* <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Tên NV Rút Đáo</p>
                                            <p className={clsx(styles.value)}>{stateString_tennhanvienrutdao}</p>
                                        </div> */}

                                            {/* <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Tiền ship</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)} 
                                                value={stateNumber_tienship_makeup}
                                                onChange={handleChange_tienship}
                                                />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Tiền ship Nhân viên</p>
                                            <p className={clsx(styles.value)}>{formatThoudsand(stateNumber_tienship/2)}</p>
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Địa chỉ ship</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)} 
                                                value={stateString_diachiship}
                                                onChange={handleInputChange_diachiship}
                                                placeholder='nhập địa chỉ ship'
                                                />
                                        </div> */}
                                            {/* <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Ngày giờ liên hệ lại</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)} 
                                                value={stateString_ngaydukienlienhelai}
                                                onChange={handleChange_ngaydukienlienhelai}
                                                placeholder='YYYY-MM-DD HH:MM'
                                                />
                                        </div> */}


                                            {/* <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Chi chú của CSKH</p>
                                            <p className={clsx(styles.value)}>{stateString_ghichu}</p>
                                        </div> */}
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Ngày tạo GD</p>
                                                <input type="date"
                                                    className={clsx(styles.inputDate)}
                                                    value={stateString_dateCreateGD}
                                                    onChange={handleChange_dateCreateGD} />
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Chi nhánh</p>
                                                <select name="from" onChange={handleChiNhanh} className={clsx(styles.selectOption)} value={stateString_chinhanh}>
                                                    <option value="empty">Chọn chi nhánh...</option>
                                                    <option value="Q1">Q1</option>
                                                    <option value="Q4">Q4</option>
                                                    <option value="Q7">Q7</option>
                                                    <option value="Q10">Q10</option>
                                                    <option value="Q12">Q12</option>
                                                    <option value="Q. Tân Phú">Q. Tân Phú</option>
                                                    <option value="Dĩ An">Dĩ An</option>
                                                    <option value="Bảo Lộc">Bảo Lộc</option>
                                                    <option value="Vạn Phúc city">Vạn Phúc city</option>
                                                </select>
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Ghi chú rút đáo</p>
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag)}
                                                    value={stateString_ghichurutdao}
                                                    onChange={handlechange_ghichurutdao}
                                                />
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Ghi chú phí GD</p>
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag)}
                                                    value={stateString_ghichuphigiaodich}
                                                    onChange={handlechange_ghichuphigiaodich}
                                                />
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key, styles.importantKey)}>Tiền rút đáo</p>
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag)}
                                                    value={stateNumber_tienrutdao_makeup}
                                                    onChange={handleChange_tienrutdao}
                                                />
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Phí rút đáo (%)</p>
                                                <input
                                                    type="number"
                                                    className={clsx(styles.inputTag)}
                                                    value={stateNumber_phirutdao}
                                                    onChange={handleChange_phirutdao}
                                                />
                                            </div>
                                            <div className={clsx(styles.box)}>
                                                <Tooltip title="Tiền phí = Tiền rút đáo x phí rút đáo" placement="top">
                                                    <p className={clsx(styles.key)}>Tiền phí</p>
                                                </Tooltip>
                                                <p className={clsx(styles.value)}>{formatThoudsand(stateNumber_tienphi)}</p>
                                            </div>
                                            {
                                                stateString_nhucau === "Rút tiền" &&
                                                <div className={clsx(styles.box)}>
                                                    <Tooltip title="Tiền chuyển khách = Tiền rút đáo - tiền phí" placement="top">
                                                        <p className={clsx(styles.key)}>Tiền chuyển khách</p>
                                                    </Tooltip>
                                                    <p className={clsx(styles.value)}>{formatThoudsand(stateNumber_tienChuyenKhach)}</p>
                                                </div>
                                            }
                                            {
                                                stateString_nhucau === "Đáo hạn" &&

                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Ngày hạn đáo thẻ</p>
                                                    <input
                                                        type="text"
                                                        className={clsx(styles.inputTag, { [styles.wrong]: stateBool_valid_ngayhandaothe })}
                                                        value={stateString_ngayhanthanhtoan}
                                                        onChange={handleChange_ngayhanthanhtoan}
                                                        placeholder='dd-mm-yyyy'
                                                    />
                                                </div>
                                            }
                                            <div className={clsx(styles.boxMerge)}>
                                                <p className={clsx(styles.key)}>Cấp bậc hội viên của giao dịch này</p>
                                            </div>
                                            <div className={clsx(styles.boxMerge)}>
                                                <p className={clsx(styles.typeWrapper)}>
                                                    <span className={clsx(styles.type)}>
                                                        {stateObj_giaoDichSelected.memberType}
                                                    </span>
                                                </p>
                                            </div>
                                            {/* <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Nhân viên ship</p>
                                            <select name="from" onChange={handleChange_nhanvienship} className={clsx(styles.selectOption)} value={stateString_nhanvienship}>
                                                {
                                                    stateArrEmployee.map((obj, index) => {
                                                        return ( 
                                                            <option key={obj.id} value={obj.fullName}>{obj.fullName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div> */}

                                        </div>
                                        {
                                            stateObj_giaoDichSelected.emailNVCSKHCreate === "agent" ?
                                            <div className={clsx(styles.right)}>

                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Tên ngân hàng của thẻ
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_tennganhang)} />
                                                    </p>

                                                    <input type="text" id="listNganhang3" list="listNganhang33"
                                                        onChange={handleChange_tennganhang}
                                                        value={stateString_tennganhang}
                                                        className={clsx(styles.inputTag)} />

                                                    <datalist id="listNganhang33">
                                                        {stateArrNganhang &&
                                                            stateArrNganhang.map((obj, index) => {
                                                                return (
                                                                    <option key={obj.id} value={obj.id} />
                                                                )
                                                            })
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Loại thẻ
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_loaithe)} />
                                                    </p>
                                                    <select name="from" onChange={handleChange_loaithe} className={clsx(styles.selectOption)} value={stateString_loaithe} >
                                                        <option value="Chọn...">Chọn...</option>
                                                        <option value="Visa">Visa</option>
                                                        <option value="Master card">Master card</option>
                                                        <option value="Evo">Evo</option>
                                                        <option value="JCB">JCB</option>
                                                        <option value="amex">amex</option>
                                                        <option value="Napas">Napas</option>
                                                        <option value="Lotte Finance">Lotte Finance</option>
                                                        <option value="Shinhan Finance">Shinhan Finance</option>
                                                    </select>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Tên trên thẻ tín dụng
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_tenTrenThe)} />
                                                    </p>
                                                    <input
                                                        type="text"
                                                        className={clsx(styles.inputTag)}
                                                        value={stateString_tenTrenThe}
                                                        onChange={handleInputChange_tenTrenThe}
                                                        placeholder='Nhập tên trên thẻ tín dụng'
                                                    />
                                                </div>

                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>STK thẻ tín dụng
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_soTaiKhoanTheTinDung)} />
                                                    </p>
                                                    <input
                                                        type="text"
                                                        className={clsx(styles.inputTag)}
                                                        value={stateString_soTaiKhoanTheTinDung}
                                                        onChange={handleInputChange_soTaiKhoanTheTinDung}
                                                        ref={inputRef_soTaiKhoanTheTinDung}
                                                        placeholder='Nhập số tài khoản thẻ tín dụng'
                                                    />
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Số trên thẻ tín dụng:
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard_creditCard(stateString_sothetindung_makeup)} />
                                                    </p>
                                                    {/* <input
                                                        type="text"
                                                        className={clsx(styles.inputTag, { [styles.wrong]: stateBool_isValid_sothetindung })}
                                                        value={stateString_sothetindung_makeup}
                                                        onChange={handlechange_sothetindung}
                                                        ref={inputRef_sothetindung}
                                                    /> */}
                                                    {
                                                        // nếu khác rỗng thì che xxxx, bằng rỗng thì hiện ra rỗng
                                                        stateBool_isValid_sothetindung === false ?
                                                            <div className={clsx(styles.valueWrapper)} >
                                                                <p className={clsx(styles.value)}>{formatCreditCardXXXX(stateString_sothetindung_makeup)}</p>
                                                                <KeyboardBackspaceIcon className={clsx(styles.btnClose)} onClick={removeLastDigitSoThe}/>
                                                            </div>
                                                            :
                                                            <input
                                                                type="text"
                                                                className={clsx(styles.inputTag, { [styles.wrong]: stateBool_isValid_sothetindung })}
                                                                value={stateString_sothetindung_makeup}
                                                                onChange={handlechange_sothetindung}
                                                                ref={inputRef_sothetindung}
                                                            />
                                                    }
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Hạn mức thẻ tín dụng</p>
                                                    <input
                                                        type="text"
                                                        className={clsx(styles.inputTag)}
                                                        value={stateNumber_hanmucthetindung_makeup}
                                                        onChange={handleChange_hanmucthetindung}
                                                    />
                                                </div>
                                                {/* <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Lý do của trạng thái</p>
                                                    <input
                                                        type="text"
                                                        className={clsx(styles.inputTag)}
                                                        value={stateString_lydocuatrangthai}
                                                        onChange={handlechange_lydocuatrangthai}
                                                    />
                                                </div> */}
                                                <div className={clsx(styles.box)}>
                                                    <Tooltip title="2 ký tự đầu là: TT (Thanh toán), phần giữa là: id khách hàng, phần cuối là id giao dịch" placement="top">
                                                        <p className={clsx(styles.key)}>Nội dung chuyển tiền cho KH
                                                            <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_noidungchuyentien)} />
                                                        </p>
                                                    </Tooltip>
                                                    <p className={clsx(styles.value)} >{stateString_noidungchuyentien === '' ? `TT ${stateNumber_selectedKH_id} ${stateNum_idGiaodich}` : stateString_noidungchuyentien}</p>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Ai đang giữ thẻ</p>
                                                    <select name="from" onChange={handleChange_aiDangGiuThe} className={clsx(styles.selectOption)} value={stateString_aiDangGiuThe}>
                                                        <option value="Chọn...">Chọn...</option>
                                                        <option value="Chủ thẻ">Chủ thẻ</option>
                                                        <option value="Team rút đáo">Team rút đáo</option>
                                                    </select>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Trạng thái rút đáo</p>
                                                    <p className={clsx(styles.value)}>{stateString_teamrutdaothongbaotrangthai}</p>
                                                </div>
                                                {
                                                    stateString_nhucau === "Rút tiền" &&
                                                    <>
                                                        <div className={clsx(styles.box3)}>
                                                            <Tooltip title="Áp dung khi Rút" placement="top">
                                                                <p className={clsx(styles.key)}>Thông tin ngân hàng để chuyển tiền cho khách</p>
                                                            </Tooltip>
                                                        </div>
                                                        <div className={clsx(styles.box)}>
                                                            <p className={clsx(styles.key, styles.chonTheTinDung)}>Chọn STK Ngân hàng cũ</p>
                                                            <select name="from" onChange={handleChange_stknh} className={clsx(styles.selectOption)} value={stateNum_stknhId}>
                                                                <option value="Chọn...">Chọn...</option>
                                                                {stateArr_STKNH_OfSelectedKH &&
                                                                    stateArr_STKNH_OfSelectedKH.map((obj, index) => {
                                                                        return (
                                                                            <option key={obj.id} value={obj.id}>{obj.tenNganHangChuyenTien} - {obj.soTaiKhoanNganHang}</option>
                                                                        )
                                                                    })
                                                                }
                                                                <option value="Tạo mới">Tạo mới</option>
                                                            </select>
                                                        </div>
                                                        {
                                                            stateNum_stknhId === 0 ?
                                                                <>
                                                                    <div className={clsx(styles.box)}>
                                                                        <Tooltip title="Áp dung khi Rút" placement="top">
                                                                            <p className={clsx(styles.key)}>STK ngân hàng:
                                                                                <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_sotaikhoannganhang)} />
                                                                            </p>
                                                                        </Tooltip>
                                                                        <input
                                                                            type="text"
                                                                            className={clsx(styles.inputTag)}
                                                                            value={stateString_sotaikhoannganhang}
                                                                            onChange={handlechange_sotaikhoannganhang}
                                                                            ref={inputRef_sotaikhoannganhang}
                                                                            // placeholder='Nhập tên KH'
                                                                        />
                                                                    </div>
                                                                    {/* <div className={clsx(styles.box)}>
                                                                        <p className={clsx(styles.key)}>STK ngân hàng (copy)</p>
                                                                        <p className={clsx(styles.value, styles.copyToClipboard)} onClick={() => copyToClipboard(stateString_sotaikhoannganhang)}>{stateString_sotaikhoannganhang}</p>
                                                                    </div> */}
                                                                    <div className={clsx(styles.box)}>
                                                                        <Tooltip title="Áp dung khi Rút" placement="top">
                                                                            <p className={clsx(styles.key)}>Tên ngân hàng chuyển tiền</p>
                                                                        </Tooltip>
                                                                        <input className={clsx(styles.inputTag)} type="text" id="listNganhang1" list="listNganhang11" onChange={handlechange_tenNganHangChuyenTien} value={stateString_tenNganHangChuyenTien} />
                                                                        <datalist id="listNganhang11">
                                                                            {stateArrNganhang &&
                                                                                stateArrNganhang.map((obj, index) => {
                                                                                    return (
                                                                                        <option key={obj.id} value={obj.id} />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </datalist>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className={clsx(styles.box)}>
                                                                        <Tooltip title="Áp dung khi Rút" placement="top">
                                                                            <p className={clsx(styles.key)}>STK ngân hàng
                                                                                <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_sotaikhoannganhang)} />
                                                                            </p>
                                                                        </Tooltip>
                                                                        <p className={clsx(styles.value)}>{stateString_sotaikhoannganhang}</p>
                                                                    </div>
                                                                    <div className={clsx(styles.box)}>
                                                                        <Tooltip title="Áp dung khi Rút" placement="top">
                                                                            <p className={clsx(styles.key)}>Tên ngân hàng chuyển tiền
                                                                                <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_tenNganHangChuyenTien)} />
                                                                            </p>
                                                                        </Tooltip>
                                                                        <p className={clsx(styles.value)}>{stateString_tenNganHangChuyenTien}</p>

                                                                    </div>
                                                                </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            :
                                            // trường hợp là khách hàng thường, ko phải là khách hàng của agent
                                            <div className={clsx(styles.right)}>

                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Tên ngân hàng của thẻ
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_tennganhang)} />
                                                    </p>

                                                    <input type="text" id="listNganhang3" list="listNganhang33"
                                                        onChange={handleChange_tennganhang}
                                                        value={stateString_tennganhang}
                                                        className={clsx(styles.inputTag)} />

                                                    <datalist id="listNganhang33">
                                                        {stateArrNganhang &&
                                                            stateArrNganhang.map((obj, index) => {
                                                                return (
                                                                    <option key={obj.id} value={obj.id} />
                                                                )
                                                            })
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Loại thẻ
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_loaithe)} />
                                                    </p>
                                                    <p className={clsx(styles.value)}>{stateString_loaithe}</p>

                                                    {/* <select name="from" onChange={handleChange_loaithe} className={clsx(styles.selectOption)} value={stateString_loaithe} >
                                                        <option value="Chọn...">Chọn...</option>
                                                        <option value="Visa">Visa</option>
                                                        <option value="Master card">Master card</option>
                                                        <option value="Evo">Evo</option>
                                                        <option value="JCB">JCB</option>
                                                        <option value="amex">amex</option>
                                                        <option value="Napas">Napas</option>
                                                        <option value="Lotte Finance">Lotte Finance</option>
                                                        <option value="Shinhan Finance">Shinhan Finance</option>
                                                    </select> */}
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Tên trên thẻ tín dụng
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_tenTrenThe)} />
                                                    </p>
                                                    {/* <p className={clsx(styles.key)}>{stateString_tenTrenThe}</p> */}
                                                    {/* <input
                                                        type="text"
                                                        className={clsx(styles.inputTag)}
                                                        value={stateString_tenTrenThe}
                                                        onChange={handleInputChange_tenTrenThe}
                                                        placeholder='Nhập tên trên thẻ tín dụng'
                                                        readOnly
                                                    /> */}
                                                    <p className={clsx(styles.value)}>{stateString_tenTrenThe}</p>

                                                    {/* <input
                                                type="text"
                                                className={clsx(styles.inputTag)} 
                                                value={stateString_tenTrenThe}
                                                onChange={handleInputChange_tenTrenThe}
                                                // placeholder='Nhập tên KH'
                                                /> */}
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>STK thẻ tín dụng
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_soTaiKhoanTheTinDung)} />
                                                    </p>
                                                    <input
                                                        type="text"
                                                        className={clsx(styles.inputTag)}
                                                        value={stateString_soTaiKhoanTheTinDung}
                                                        onChange={handleInputChange_soTaiKhoanTheTinDung}
                                                        ref={inputRef_soTaiKhoanTheTinDung}
                                                    // placeholder='Nhập tên KH'
                                                    />
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Số trên thẻ tín dụng:
                                                        <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard_creditCard(stateString_sothetindung_makeup)} />
                                                    </p>
                                                    {
                                                        // nếu khác rỗng thì che xxxx, bằng rỗng thì hiện ra rỗng
                                                        stateString_sothetindung_makeup !== "" ?
                                                            <p className={clsx(styles.value)}>{formatCreditCardXXXX(stateString_sothetindung_makeup)}</p>
                                                            :
                                                            <input
                                                                type="text"
                                                                className={clsx(styles.inputTag, { [styles.wrong]: stateBool_isValid_sothetindung })}
                                                                value={stateString_sothetindung_makeup}
                                                                onChange={handlechange_sothetindung}
                                                                ref={inputRef_sothetindung}
                                                            />
                                                    }
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Hạn mức thẻ tín dụng</p>
                                                    <input
                                                        type="text"
                                                        className={clsx(styles.inputTag)}
                                                        value={stateNumber_hanmucthetindung_makeup}
                                                        onChange={handleChange_hanmucthetindung}
                                                    />
                                                </div>
                                                {/* <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Lý do của trạng thái</p>
                                                    <input
                                                        type="text"
                                                        className={clsx(styles.inputTag)}
                                                        value={stateString_lydocuatrangthai}
                                                        onChange={handlechange_lydocuatrangthai}
                                                    />
                                                </div> */}
                                                <div className={clsx(styles.box)}>
                                                    <Tooltip title="2 ký tự đầu là: TT (Thanh toán), phần giữa là: id khách hàng, phần cuối là id giao dịch" placement="top">
                                                        <p className={clsx(styles.key)}>Nội dung chuyển tiền cho KH
                                                            <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_noidungchuyentien)} />
                                                        </p>
                                                    </Tooltip>
                                                    <p className={clsx(styles.value)} >{stateString_noidungchuyentien === '' ? `TT ${stateNumber_selectedKH_id} ${stateNum_idGiaodich}` : stateString_noidungchuyentien}</p>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Ai đang giữ thẻ</p>
                                                    <select name="from" onChange={handleChange_aiDangGiuThe} className={clsx(styles.selectOption)} value={stateString_aiDangGiuThe}>
                                                        <option value="Chọn...">Chọn...</option>
                                                        <option value="Chủ thẻ">Chủ thẻ</option>
                                                        <option value="Team rút đáo">Team rút đáo</option>
                                                    </select>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Trạng thái rút đáo</p>
                                                    <p className={clsx(styles.value)}>{stateString_teamrutdaothongbaotrangthai}</p>
                                                </div>
                                                {
                                                    stateString_nhucau === "Rút tiền" &&
                                                    <>
                                                        <div className={clsx(styles.box3)}>
                                                            <Tooltip title="Áp dung khi Rút" placement="top">
                                                                <p className={clsx(styles.key)}>Thông tin ngân hàng để chuyển tiền cho khách</p>
                                                            </Tooltip>
                                                        </div>
                                                        <div className={clsx(styles.box)}>
                                                            <p className={clsx(styles.key, styles.chonTheTinDung)}>Chọn STK Ngân hàng cũ</p>
                                                            <select name="from" onChange={handleChange_stknh} className={clsx(styles.selectOption)} value={stateNum_stknhId}>
                                                                <option value="Chọn...">Chọn...</option>
                                                                {stateArr_STKNH_OfSelectedKH &&
                                                                    stateArr_STKNH_OfSelectedKH.map((obj, index) => {
                                                                        return (
                                                                            <option key={obj.id} value={obj.id}>{obj.tenNganHangChuyenTien} - {obj.soTaiKhoanNganHang}</option>
                                                                        )
                                                                    })
                                                                }
                                                                <option value="Tạo mới">Tạo mới</option>
                                                            </select>
                                                        </div>
                                                        {
                                                            stateNum_stknhId === 0 ?
                                                                <>
                                                                    <div className={clsx(styles.box)}>
                                                                        <Tooltip title="Áp dung khi Rút" placement="top">
                                                                            <p className={clsx(styles.key)}>STK ngân hàng
                                                                                <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_sotaikhoannganhang)} />
                                                                            </p>
                                                                        </Tooltip>
                                                                        <input
                                                                            type="text"
                                                                            className={clsx(styles.inputTag)}
                                                                            value={stateString_sotaikhoannganhang}
                                                                            onChange={handlechange_sotaikhoannganhang}
                                                                            ref={inputRef_sotaikhoannganhang}
                                                                        // placeholder='Nhập tên KH'
                                                                        />
                                                                    </div>
                                                                    {/* <div className={clsx(styles.box)}>
                                                                        <p className={clsx(styles.key)}>STK ngân hàng (copy)</p>
                                                                        <p className={clsx(styles.value, styles.copyToClipboard)} onClick={() => copyToClipboard(stateString_sotaikhoannganhang)}>{stateString_sotaikhoannganhang}</p>
                                                                    </div> */}
                                                                    <div className={clsx(styles.box)}>
                                                                        <Tooltip title="Áp dung khi Rút" placement="top">
                                                                            <p className={clsx(styles.key)}>Tên ngân hàng chuyển tiền</p>
                                                                        </Tooltip>
                                                                        <input className={clsx(styles.inputTag)} type="text" id="listNganhang1" list="listNganhang11" onChange={handlechange_tenNganHangChuyenTien} value={stateString_tenNganHangChuyenTien} />
                                                                        <datalist id="listNganhang11">
                                                                            {stateArrNganhang &&
                                                                                stateArrNganhang.map((obj, index) => {
                                                                                    return (
                                                                                        <option key={obj.id} value={obj.id} />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </datalist>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className={clsx(styles.box)}>
                                                                        <Tooltip title="Áp dung khi Rút" placement="top">
                                                                            <p className={clsx(styles.key)}>STK ngân hàng
                                                                                <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_sotaikhoannganhang)} />
                                                                            </p>
                                                                        </Tooltip>
                                                                        <p className={clsx(styles.value)}>{stateString_sotaikhoannganhang}</p>
                                                                    </div>
                                                                    <div className={clsx(styles.box)}>
                                                                        <Tooltip title="Áp dung khi Rút" placement="top">
                                                                            <p className={clsx(styles.key)}>Tên ngân hàng chuyển tiền
                                                                                <ContentCopyIcon className={clsx(styles.iconCopy)} onClick={() => copyToClipboard(stateString_tenNganHangChuyenTien)} />
                                                                            </p>
                                                                        </Tooltip>
                                                                        <p className={clsx(styles.value)}>{stateString_tenNganHangChuyenTien}</p>

                                                                    </div>
                                                                </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        }
                                        {/* end right */}
                                    </div>
                                    <div className={clsx(styles.rutdaoAndImagesSection)}>
                                        <div className={clsx(styles.pictureContainer)}>
                                            <div className={clsx(styles.box, styles.imageUpload_tienPhi)}>
                                                <UploadPictureCCCD stateNum_idKhachhang={stateNumber_selectedKH_id} stateNum_idGiaodich={stateNum_idGiaodich} selectGiaodich={selectGiaodich} />
                                            </div>
                                            <div className={clsx(styles.hinhCCCD)}>
                                                {
                                                    stateObj_giaoDichSelected.khachhang?.cccd_mt_mediaId !== null &&
                                                    <div className={clsx(styles.image)}>
                                                        <img src={stateObj_giaoDichSelected.khachhang?.cccd_mt_signedUrl} alt="hình mặt trước"
                                                            onClick={() => selectImg(stateObj_giaoDichSelected.khachhang.cccd_mt_signedUrl)} />
                                                        <ClearIcon className={clsx(styles.iconX)} onClick={() => DeleteSelectedImg_cccd("mt", stateObj_giaoDichSelected.khachhang.id)} />
                                                    </div>
                                                }
                                                {
                                                    stateObj_giaoDichSelected.khachhang?.cccd_ms_mediaId !== null &&
                                                    <div className={clsx(styles.image)}>
                                                        <img src={stateObj_giaoDichSelected.khachhang?.cccd_ms_signedUrl} alt="hình mặt sau"
                                                            onClick={() => selectImg(stateObj_giaoDichSelected.khachhang.cccd_ms_signedUrl)} />
                                                        <ClearIcon className={clsx(styles.iconX)} onClick={() => DeleteSelectedImg_cccd("ms", stateObj_giaoDichSelected.khachhang.id)} />
                                                    </div>
                                                }
                                            </div>
                                            <div className={clsx(styles.box, styles.imageUpload_tienPhi)}>
                                                <UploadPicture_tienphi stateNum_idGiaodich={stateNum_idGiaodich} selectGiaodich={selectGiaodich} />
                                            </div>
                                            <div className={clsx(styles.hinhChuyenPhi)}>
                                                {
                                                    stateObj_giaoDichSelected.hinhChuyenKhoanTienPhi_mediaId !== null &&

                                                    <div className={clsx(styles.image)}>
                                                        <img src={stateObj_giaoDichSelected.hinhChuyenKhoanTienPhi_signedUrl} alt="hình chuyển phí"
                                                            onClick={() => selectImg(stateObj_giaoDichSelected.hinhChuyenKhoanTienPhi_signedUrl)} />
                                                        <ClearIcon className={clsx(styles.iconX)} onClick={() => DeleteSelectedImg_tienPhi(stateObj_giaoDichSelected.id)} />
                                                    </div>
                                                }
                                            </div>
                                            <div className={clsx(styles.box, styles.imageUpload)}>
                                                <UploadPicture stateNum_idGiaodich={stateNum_idGiaodich} selectGiaodich={selectGiaodich} />
                                            </div>
                                            <div className={clsx(styles.hinhCuaGiaoDich)}>
                                                {stateArr_imagesOfSelectedGiaoDich &&
                                                    stateArr_imagesOfSelectedGiaoDich.map((obj, index) => {
                                                        return (
                                                            <div className={clsx(styles.image)} key={obj.id}>
                                                                <img key={obj.mediaId} src={obj.signedUrl} alt="hình giao dịch"
                                                                    onClick={() => selectImg(obj.signedUrl)} />
                                                                <ClearIcon className={clsx(styles.iconX)} onClick={() => DeleteSelectedImg(obj.id, obj.mediaId)} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </div>
                                        <div className={clsx(styles.rutDaoInput)}>
                                            {
                                                stateString_nhucau === "Đáo hạn" ?
                                                    stateArr_dao_of_giaodich_selected.length === 0 ? (
                                                        <p className={clsx(styles.message)}>Giao dịch này chưa đáo lần nào</p>
                                                    ) : (
                                                        <>
                                                            {/* Your logic before rendering when the array is not empty */}
                                                            {stateArr_dao_of_giaodich_selected && stateArr_dao_of_giaodich_selected.map((obj, index) => {
                                                                return (
                                                                    <div className={clsx(styles.daoWrapper_old)} key={obj.id}>
                                                                        <div className={clsx(styles.titleDaoWrapper)}>
                                                                            <span className={clsx(styles.xoaWrapper)}>
                                                                                {index === stateArr_dao_of_giaodich_selected.length - 1 && (
                                                                                    <div className={clsx(styles.btnWrapper_lastDao)}>
                                                                                        {/* <button onClick={()=>selectDaoToEdit(obj.id)} className={clsx(styles.btnEdit)}>Sửa</button> */}
                                                                                        <button onClick={() => deleteDao(obj.id)} className={clsx(styles.btnDelete)}>Xóa</button>
                                                                                    </div>
                                                                                )}
                                                                            </span>
                                                                            <p className={clsx(styles.titleDao)}>Lần {index + 1}</p>
                                                                            <p className={clsx(styles.creater)}>Thực hiện bởi: &nbsp;
                                                                                {
                                                                                    obj.employee !== null && obj.employee !== undefined &&
                                                                                    <span>
                                                                                        {obj.employee?.fullName}
                                                                                    </span>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <p className={clsx(styles.key)}>Số Lô:
                                                                                    <span>
                                                                                        <CloudUploadIcon className={clsx(styles.saveIcon)} onClick={() => save_soLo_dao(index, obj.id)} />
                                                                                    </span>
                                                                                </p>
                                                                                {/* <p className={clsx(styles.value)}>{obj.soLo}</p> */}
                                                                                <input
                                                                                    type="text"
                                                                                    className={clsx(styles.inputTag, styles.inputThird)}
                                                                                    // `$ {id}` ép id thành string
                                                                                    //@ts-ignore
                                                                                    value={stateObj_groupInput_solo_dao[index] || ''}
                                                                                    placeholder="Nhập số lô..."
                                                                                    onChange={(event) => handleInputGroupChange_solo_dao(index, event.target.value)}
                                                                                />
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>Số Bill:
                                                                                    <span>
                                                                                        <CloudUploadIcon className={clsx(styles.saveIcon)} onClick={() => save_soBill_dao(index, obj.id)} />
                                                                                    </span>
                                                                                </p>
                                                                                {/* <p className={clsx(styles.value)}>{obj.soBill}</p> */}
                                                                                <input
                                                                                    type="text"
                                                                                    className={clsx(styles.inputTag, styles.inputThird)}
                                                                                    // `$ {id}` ép id thành string
                                                                                    //@ts-ignore
                                                                                    value={stateObj_groupInput_sobill_dao[index] || ''}
                                                                                    placeholder="Nhập số Bill..."
                                                                                    onChange={(event) => handleInputGroupChange_sobill_dao(index, event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <p className={clsx(styles.key)}>Tiền nạp:</p>
                                                                                <p className={clsx(styles.value)}>{formatThoudsand(obj.tienNap)}</p>
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>Tiền nạp còn lại:</p>
                                                                                <p className={clsx(styles.value)}>{formatThoudsand(obj.tienNapConLai)}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <p className={clsx(styles.key)}>Tiền rút:</p>
                                                                                <p className={clsx(styles.value)}>{formatThoudsand(obj.tienRut)}</p>
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>Tiền rút còn lại:</p>
                                                                                <p className={clsx(styles.value)}>{formatThoudsand(obj.tienRutConLai)}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <p className={clsx(styles.key)}>Máy pos:</p>
                                                                                {
                                                                                    obj.maypos !== null &&
                                                                                    <p className={clsx(styles.value)}>{obj.maypos.name}</p>
                                                                                }

                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>
                                                                                    <span className={clsx(styles.keykey)}>
                                                                                        Tạo lúc:
                                                                                    </span>
                                                                                    <span className={clsx(styles.saveIconWrapper)}>
                                                                                        <CloudUploadIcon className={clsx(styles.saveIcon)} onClick={() => updateDaoCreatedAt(index, obj.id, stateObj_groupInput_createdAt_dao[index])} />
                                                                                    </span>
                                                                                </p>
                                                                                <input type="date"
                                                                                    className={clsx(styles.inputDate)}
                                                                                    value={stateObj_groupInput_createdAt_dao[index] || ''}
                                                                                    onChange={(event) => handleInputGroupChange_createdAt_dao(index, event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <p className={clsx(styles.key)}>Chuyển đến:</p>
                                                                                <p className={clsx(styles.value)}>{obj.chuyenDen}</p>
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>STK:</p>
                                                                                <p className={clsx(styles.value)}>{obj.soTaiKhoan}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>Trường hợp:</p>
                                                                                <p className={clsx(styles.value)}>{obj.truongHopId === null ? "Bình thường" : obj.truongHopId}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row, styles.rowImage)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <div className={clsx(styles.imageUpload_dao)}>
                                                                                    <UploadPictureNap send_MediaId_nap_ToParent={receive_mediaId_nap_FromChild} idNap={obj.id} />
                                                                                </div>
                                                                                <div className={clsx(styles.imageShow)}>
                                                                                    {
                                                                                        obj.hasOwnProperty('signedUrl_nap') &&
                                                                                        <>
                                                                                            <p className={clsx(styles.key)}>sao kê nạp tiền:</p>
                                                                                            <p className={clsx(styles.value, styles.hinhLoBill)}>
                                                                                                <img src={obj.signedUrl_nap} alt="Hình sao kê nạp tiền"
                                                                                                    onClick={() => selectImg(obj.signedUrl_nap)} />
                                                                                            </p>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className={clsx(styles.right, styles.imageWrapper)}>
                                                                                <div className={clsx(styles.imageUpload_dao)}>
                                                                                    <UploadPictureDao send_MediaId_dao_ToParent={receive_mediaId_dao_FromChild} idDao={obj.id} />
                                                                                </div>
                                                                                <div className={clsx(styles.imageShow)}>
                                                                                    {
                                                                                        obj.hasOwnProperty('signedUrl_rut') &&
                                                                                        <>
                                                                                            <p className={clsx(styles.key)}>số Lô, Số Bill:</p>
                                                                                            <p className={clsx(styles.value, styles.hinhLoBill)}>
                                                                                                <img src={obj.signedUrl_rut} alt="hình số Lô, số Bill"
                                                                                                    onClick={() => selectImg(obj.signedUrl_rut)} />
                                                                                            </p>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    )
                                                    :
                                                    stateArr_rut_of_giaodich_selected.length === 0 ? (
                                                        <p className={clsx(styles.message)}>Giao dịch này chưa rút lần nào</p>
                                                    ) : (
                                                        <>
                                                            {/* Your logic before rendering when the array is not empty */}
                                                            {stateArr_rut_of_giaodich_selected && stateArr_rut_of_giaodich_selected.map((obj, index) => {

                                                                return (
                                                                    <div className={clsx(styles.rutWrapper_old)} key={obj.id}>
                                                                        <div className={clsx(styles.titleRutWrapper)}>
                                                                            <span className={clsx(styles.xoaWrapper)}>
                                                                                {index === stateArr_rut_of_giaodich_selected.length - 1 && (
                                                                                    <div className={clsx(styles.btnWrapper_lastRut)}>
                                                                                        {/* <button onClick={()=>selectRutToEdit(obj.id)} className={clsx(styles.btnEdit)}>Sửa</button> */}
                                                                                        <button onClick={() => deleteRut(obj.id)} className={clsx(styles.btnDelete)}>Xóa</button>
                                                                                    </div>
                                                                                )}
                                                                            </span>
                                                                            <p className={clsx(styles.titleRut)}>Lần {index + 1}</p>
                                                                            <p className={clsx(styles.creater)}>Thực hiện bởi: &nbsp;
                                                                                {
                                                                                    obj.employee !== null && obj.employee !== undefined &&
                                                                                    <span>
                                                                                        {obj.employee?.fullName}
                                                                                    </span>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <p className={clsx(styles.key)}>
                                                                                    <span className={clsx(styles.keykey)}>
                                                                                        Số Lô:
                                                                                    </span>
                                                                                    <span className={clsx(styles.saveIconWrapper)}>
                                                                                        <CloudUploadIcon className={clsx(styles.saveIcon)} onClick={() => save_soLo_rut(index, obj.id)} />
                                                                                    </span>
                                                                                </p>
                                                                                {/* <p className={clsx(styles.value)}>{obj.soLo}</p> */}
                                                                                <input
                                                                                    type="text"
                                                                                    className={clsx(styles.inputTag, styles.inputThird)}
                                                                                    // `$ {id}` ép id thành string
                                                                                    //@ts-ignore
                                                                                    value={stateObj_groupInput_solo_rut[index] || ''}
                                                                                    placeholder="Nhập số lô..."
                                                                                    onChange={(event) => handleInputGroupChange_solo_rut(index, event.target.value)}
                                                                                />
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>
                                                                                    <span className={clsx(styles.keykey)}>
                                                                                        Số Bill:
                                                                                    </span>
                                                                                    <span className={clsx(styles.saveIconWrapper)}>
                                                                                        <CloudUploadIcon className={clsx(styles.saveIcon)} onClick={() => save_soBill_rut(index, obj.id)} />
                                                                                    </span>
                                                                                </p>
                                                                                {/* <p className={clsx(styles.value)}>{obj.soBill}</p> */}
                                                                                <input
                                                                                    type="text"
                                                                                    className={clsx(styles.inputTag, styles.inputThird)}
                                                                                    // `$ {id}` ép id thành string
                                                                                    //@ts-ignore
                                                                                    value={stateObj_groupInput_sobill_rut[index] || ''}
                                                                                    placeholder="Nhập số Bill..."
                                                                                    onChange={(event) => handleInputGroupChange_sobill_rut(index, event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <p className={clsx(styles.key)}>Tiền rút:</p>
                                                                                <p className={clsx(styles.value)}>{formatThoudsand(obj.tienRut)}</p>

                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>Tiền rút còn lại:</p>
                                                                                <p className={clsx(styles.value)}>{formatThoudsand(obj.tienConLai)}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <p className={clsx(styles.key)}>Máy pos:</p>
                                                                                <p className={clsx(styles.value)}>{obj.maypos.name}</p>
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>
                                                                                    <span className={clsx(styles.keykey)}>
                                                                                        Tạo lúc:
                                                                                    </span>
                                                                                    <span className={clsx(styles.saveIconWrapper)}>
                                                                                        <CloudUploadIcon className={clsx(styles.saveIcon)} onClick={() => updateRutCreatedAt(index, obj.id, stateObj_groupInput_createdAt_rut[index])} />
                                                                                    </span>
                                                                                </p>
                                                                                <input type="date"
                                                                                    className={clsx(styles.inputDate)}
                                                                                    value={stateObj_groupInput_createdAt_rut[index] || ''}
                                                                                    onChange={(event) => handleInputGroupChange_createdAt_rut(index, event.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                <p className={clsx(styles.key)}>Chuyển đến:</p>
                                                                                <p className={clsx(styles.value)}>{obj.chuyenDen}</p>
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>STK:</p>
                                                                                <p className={clsx(styles.value)}>{obj.soTaiKhoan}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>
                                                                                <p className={clsx(styles.key)}>Trường hợp:</p>
                                                                                <p className={clsx(styles.value)}>{obj.truongHopId === null ? "Bình thường" : obj.truongHopId}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className={clsx(styles.row)}>
                                                                            <div className={clsx(styles.left)}>
                                                                                {/* <div className={clsx(styles.inner_left)}>
                                                                                        
                                                                                    </div> */}
                                                                                <div className={clsx(styles.imageUpload_rut)}>
                                                                                    <UploadPictureRut send_MediaId_rut_ToParent={receive_mediaId_rut_FromChild} idRut={obj.id} />
                                                                                </div>
                                                                                <div className={clsx(styles.imageShow)}>
                                                                                    {
                                                                                        obj.hasOwnProperty('signedUrl') &&
                                                                                        <>
                                                                                            <p className={clsx(styles.key)}>số Lô, Số Bill:</p>
                                                                                            <p className={clsx(styles.value, styles.hinhLoBill)}>
                                                                                                <img src={obj.signedUrl} alt="hình số Lô, số Bill"
                                                                                                    onClick={() => selectImg(obj.signedUrl)} />
                                                                                            </p>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className={clsx(styles.right)}>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    )


                                            }
                                            {
                                                stateString_nhucau === "Đáo hạn" ?
                                                    <div className={clsx(styles.daoWrapper)}>
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Tiền nạp: <span className={clsx(styles.suggest)}>(Max {formatThoudsand(stateNum_maxNap)})</span></p>
                                                            <input
                                                                type="text"
                                                                className={clsx(styles.inputTag)}
                                                                value={stateNum_nap_makeup}
                                                                ref={inputRef_sonap}
                                                                onChange={handlechange_nap}
                                                            />
                                                        </div>
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Tiền rút:
                                                                <span className={clsx(styles.suggest)}>
                                                                    {
                                                                        stateNum_maxRut_in_dao >= 0 ?
                                                                            <span>
                                                                                (
                                                                                Max rút {formatThoudsand(stateNum_maxRut_in_dao + stateNum_nap)}
                                                                                &nbsp;+
                                                                                phí {formatThoudsand(stateNumber_tienphi)}
                                                                                &nbsp;= {formatThoudsand(stateNum_maxRut_in_dao + stateNum_nap + stateNumber_tienphi)}
                                                                                )
                                                                            </span>
                                                                            :
                                                                            <span>&nbsp;(0)</span>

                                                                    }
                                                                </span>
                                                            </p>
                                                            <input
                                                                type="text"
                                                                className={clsx(styles.inputTag)}
                                                                value={stateNum_rut_in_dao_makeup}
                                                                ref={inputRef_soRut_in_dao}
                                                                onChange={handlechange_rut_in_dao}
                                                            />
                                                        </div>
                                                        {/* <div className={clsx(styles.row)}>
                                                            <div className={clsx(styles.left)}>
                                                                <p className={clsx(styles.key)}>Số Lô (Đáo):</p>
                                                                <input
                                                                    type="text"
                                                                    className={clsx(styles.inputTag)}
                                                                    value={stateString_solo}
                                                                    ref={inputRef_solo}
                                                                    onChange={handlechange_solo}
                                                                />
                                                            </div>
                                                            <div className={clsx(styles.right)}>
                                                                <p className={clsx(styles.key)}>Số Bill:</p>
                                                                <input
                                                                    type="text"
                                                                    className={clsx(styles.inputTag)}
                                                                    value={stateString_sobill}
                                                                    ref={inputRef_sobill}
                                                                    onChange={handlechange_sobill}
                                                                />
                                                            </div>
                                                        </div> */}
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Máy pos</p>
                                                            <select name="from" onChange={handleChange_mayPosId} className={clsx(styles.selectOption)} value={stateString_mayPosId}>
                                                                <option value="empty">Chọn máy pos</option>
                                                                {stateArrMayPos &&
                                                                    stateArrMayPos.map((obj, index) => {
                                                                        return (
                                                                            <option key={obj.id} value={obj.id}>{obj.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Chuyển đến</p>
                                                            <select name="from" onChange={handleChange_chuyenDen} className={clsx(styles.selectOption)} value={stateString_chuyenDen}>
                                                                <option value="STK thẻ tín dụng">STK thẻ tín dụng</option>
                                                                <option value="Số trên thẻ tín dụng">Số trên thẻ tín dụng</option>
                                                            </select>
                                                        </div>
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Ngày Đáo lần này</p>
                                                            <input type="date"
                                                                className={clsx(styles.inputDate)}
                                                                value={stateString_dateCreateDao}
                                                                onChange={handleChange_dateCreateDao} />
                                                        </div>
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Trường hợp</p>
                                                            <select name="from" onChange={handleChange_truongHop} className={clsx(styles.selectOption)} value={stateString_truongHop}>
                                                                {stateArrTruongHop &&
                                                                    stateArrTruongHop.map((obj, index) => {
                                                                        return (
                                                                            <option key={obj.id} value={obj.id}>{obj.id}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className={clsx(styles.btnDaoWrapper)}>
                                                            <button className={clsx(styles.post)} onClick={postDao}>Thêm Đáo</button>
                                                        </div>
                                                        {/* <div className={clsx(styles.row)}>
                                                            <div className={clsx(styles.left, styles.leftDouble)}>
                                                                <div className={clsx(styles.leftRow)}>
                                                                </div>
                                                                <div className={clsx(styles.leftRow)}>
                                                                </div>
                                                            </div>
                                                            <div className={clsx(styles.btnDaoWrapper)}>
                                                                <div className={clsx(styles.imageUpload_dao)}>
                                                                    <UploadPictureDao_notId send_MediaId_dao_ToParent={receive_mediaId_dao_FromChild_notId} />
                                                                </div>
                                                                <div className={clsx(styles.imageUpload_dao)}>
                                                                    <UploadPictureNap_notId send_MediaId_nap_ToParent={receive_mediaId_nap_FromChild_notId} />
                                                                </div>
                                                                <div className={clsx(styles.btnPostBill)}>
                                                                    {
                                                                        stateBool_btnEditDaoShow === true ?
                                                                            <span className={clsx(styles.editGroupBtn)}>
                                                                                <button className={clsx(styles.edit)} onClick={editDaoToDB}>Cập nhật dữ liệu trong khung vào giao dịch này</button>
                                                                                <SettingsBackupRestoreIcon onClick={backToPostDao} className={clsx(styles.iconBack)} />
                                                                            </span>
                                                                            :
                                                                            <button className={clsx(styles.post)} onClick={postDao}>Thêm Đáo</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    :
                                                    <div className={clsx(styles.rutWrapper)}>
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Tiền rút: <span className={clsx(styles.suggest)}>(Max {formatThoudsand(stateNum_maxRut)})</span></p>
                                                            <input
                                                                type="text"
                                                                className={clsx(styles.inputTag)}
                                                                value={stateNum_rut_makeup}
                                                                ref={inputRef_sorut}
                                                                onChange={handlechange_rut}
                                                            />
                                                        </div>
                                                        {/* <div className={clsx(styles.row)}>
                                                            <div className={clsx(styles.left)}>
                                                                <p className={clsx(styles.key)}>Số Lô (Rút):</p>
                                                                <input
                                                                    type="text"
                                                                    className={clsx(styles.inputTag)}
                                                                    value={stateString_solo_rut}
                                                                    ref={inputRef_solo_rut}
                                                                    onChange={handlechange_solo_rut}
                                                                />
                                                            </div>
                                                            <div className={clsx(styles.right)}>
                                                                <p className={clsx(styles.key)}>Số Bill:</p>
                                                                <input
                                                                    type="text"
                                                                    className={clsx(styles.inputTag)}
                                                                    value={stateString_sobill_rut}
                                                                    ref={inputRef_sobill_rut}
                                                                    onChange={handlechange_sobill_rut}
                                                                />
                                                            </div>
                                                        </div> */}

                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Máy pos</p>
                                                            <select name="from" onChange={handleChange_mayPosId} className={clsx(styles.selectOption)} value={stateString_mayPosId}>
                                                                <option value="empty">Chọn máy pos</option>
                                                                {stateArrMayPos &&
                                                                    stateArrMayPos.map((obj, index) => {
                                                                        return (
                                                                            <option key={obj.id} value={obj.id}>{obj.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Chuyển đến</p>
                                                            <p className={clsx(styles.key)}>STK ngân hàng</p>
                                                        </div>
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Ngày Rút lần này</p>
                                                            <input type="date"
                                                                className={clsx(styles.inputDate)}
                                                                value={stateString_dateCreateRut}
                                                                onChange={handleChange_dateCreateRut} />
                                                        </div>
                                                        <div className={clsx(styles.row)}>
                                                            <p className={clsx(styles.key)}>Trường hợp</p>
                                                            <select name="from" onChange={handleChange_truongHop} className={clsx(styles.selectOption)} value={stateString_truongHop}>
                                                                {stateArrTruongHop &&
                                                                    stateArrTruongHop.map((obj, index) => {
                                                                        return (
                                                                            <option key={obj.id} value={obj.id}>{obj.id}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className={clsx(styles.btnWrapperRut)}>
                                                            <button className={clsx(styles.post)} onClick={postRut}>Thêm Rút</button>
                                                        </div>
                                                        {/* <div className={clsx(styles.row)}>
                                                            <div className={clsx(styles.left, styles.leftDouble)}>
                                                                <div className={clsx(styles.row)}>
                                                                </div>
                                                                <div className={clsx(styles.leftRow)}>
                                                                </div>
                                                                <div className={clsx(styles.leftRow)}>
                                                                </div>
                                                                <div className={clsx(styles.leftRow)}>
                                                                </div>
                                                            </div>
                                                            <div className={clsx(styles.btnWrapperRut)}>
                                                                <div className={clsx(styles.imageUpload_rut)}>
                                                                    <UploadPictureRut_notId send_MediaId_rut_ToParent={receive_mediaId_rut_FromChild_notId} />
                                                                </div>
                                                                <div className={clsx(styles.btnPostBill)}>
                                                                    {
                                                                        stateBool_btnEditRutShow === true ?
                                                                            <span>
                                                                                <button className={clsx(styles.edit)} onClick={editRutToDB}>Cập nhật dữ liệu trong khung vào giao dịch này</button>
                                                                                <SettingsBackupRestoreIcon className={clsx(styles.backIcon)} onClick={backToCreateRut} />
                                                                            </span>
                                                                            :
                                                                            <button className={clsx(styles.post)} onClick={postRut}>Thêm Rút</button>
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div> */}
                                                    </div>
                                            }
                                            <div className={clsx(styles.bottomBtnWrapper)}>
                                                {
                                                    stateString_nhucau === "Rút tiền" ?
                                                        <button onClick={hoanhThanhRut}>Hoàn Thành Rút</button>
                                                        :
                                                        <button onClick={hoanThanhDao}>Hoàn Thành Đáo</button>
                                                }
                                                <button className={clsx(styles.btnCancel)} onClick={huyGiaodich}>Hủy giao dịch</button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        }
                        {/* Thêm mới khách hàng */}
                        {
                            stateBool_showAddNewCustomer === true && stateBool_showAddNewGD === false &&
                            <div className={clsx(styles.addNewCustomer_wrapper)}>
                                <div className={clsx(styles.topAddNewCustomer_wrapper)}>
                                    <span>
                                        {
                                            stateBool_invisible_groups && stateBool_arrow_lastLeftArrow &&
                                            <KeyboardDoubleArrowLeftIcon
                                                className={clsx(styles.leftArrow_when_2_col)}
                                                onClick={invisible_groups_and_listKHWrapper}
                                            />
                                        }
                                        {
                                            stateBool_invisible_listKHWrapper &&
                                            <KeyboardDoubleArrowRightIcon className={clsx(styles.rightArrow)} onClick={show_groups_and_listKHWrapper} />
                                        }
                                    </span>
                                    <h3>Thêm mới khách hàng và giao dịch</h3>
                                    <span></span>
                                </div>
                                <div className={clsx(styles.TopBtn_createNew)}>
                                    <button onClick={save_new_KH_GD} className={clsx(styles.btnSave)}>Tạo mới Khách hàng, giao dịch & thẻ</button>
                                    <button onClick={cancelAddNew_KH_GD} className={clsx(styles.btnCancel)}>Cancel</button>
                                </div>
                                <div className={clsx(styles.inputSection)}>
                                    <div className={clsx(styles.left)}>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Tên KH</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)}
                                                value={stateString_tenkhachhang}
                                                onChange={handleInputChange_tenkhachhang}
                                            // placeholder='Nhập tên KH'
                                            />
                                        </div>

                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Nguồn KH</p>
                                            {/* <p className={clsx(styles.value)}>Rút Đáo</p> */}
                                            <select name="from" onChange={handleChange_nguonKH} disabled={stateBool_isDisable_nguonKH} className={clsx(styles.selectOption)} value={stateString_nguonKH}>
                                                <option value="Chọn...">Chọn...</option>
                                                {stateArrNguonKH &&
                                                    stateArrNguonKH.map((obj, index) => {
                                                        return (
                                                            <option key={obj.id} value={obj.id}>{obj.id}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Birthday</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag, { [styles.wrong]: stateBool_valid_ntns })}
                                                value={stateString_ntns}
                                                onChange={handleChange_ntns}
                                                placeholder='dd-mm-yyyy'
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>CCCD</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag, { [styles.wrong]: stateBool_valid_cccd })}
                                                value={stateString_cccd}
                                                onChange={handleChange_cccd}
                                                placeholder='nhập CCCD'
                                            />
                                        </div>

                                        <div className={clsx(styles.box)}>
                                            <span className={clsx(styles.leftCity)}>
                                                <p className={clsx(styles.key)}>Thành phố</p>
                                                <span onClick={() => handleClickEditCity()} className={clsx(styles.editIcon)}><BorderColorIcon color="warning" /></span>
                                            </span>
                                            <p className={clsx(styles.value)}>{nameCitySelected}</p>
                                        </div>

                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Quận</p>
                                            <p className={clsx(styles.value)}>{nameDistrictSelected}</p>
                                        </div>

                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Phường</p>
                                            <p className={clsx(styles.value)}>{nameWardSelected}</p>
                                        </div>
                                        {
                                            stateBool_showEditCity &&
                                            <>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Chọn Thành phố</p>
                                                    <select name="citySelected" onChange={handleCity} disabled={stateBool_isDisable_city}
                                                        className={clsx(styles.selectOption)}
                                                    // value={nameCitySelected}
                                                    >
                                                        <option value="empty">Please select a city...</option>
                                                        {citiesOptions}
                                                    </select>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Chọn Quận</p>
                                                    <select name="districtSelected"
                                                        onChange={handleDistrict}
                                                        disabled={stateBool_isDisable_district}
                                                        className={clsx(styles.selectOption)}
                                                    // value={nameDistrictSelected}
                                                    >
                                                        {districtsOptions}
                                                    </select>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key)}>Chọn Phường</p>
                                                    <select name="wardSelected" onChange={handleWard}
                                                        disabled={stateBool_isDisable_ward} className={clsx(styles.selectOption)}
                                                    // value={nameWardSelected}
                                                    >
                                                        {wardsOptions}
                                                    </select>
                                                </div>
                                                <div className={clsx(styles.box, styles.btnDoneEditCityWrapper)}>
                                                    <button className={clsx(styles.btnDoneEditCity)} onClick={doneEditCity}>Thêm/sửa Thành phố xong</button>
                                                </div>
                                            </>
                                        }

                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Số nhà tên đường</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)}
                                                value={stateString_address}
                                                onChange={handleAddress}
                                                placeholder='nhập địa chỉ'
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <div className={clsx(styles.btnWrapper)}>
                                                <p className={clsx(styles.key)}>Số điện thoại</p>
                                            </div>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)}
                                                value={formatPhoneNumber(stateString_sdt)}
                                                onChange={handleInputChange_sdt}
                                                placeholder='nhập SĐT'
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx(styles.middle)}>

                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Ngày tạo GD</p>
                                            <input type="date"
                                                className={clsx(styles.inputDate)}
                                                value={stateString_dateCreateGD}
                                                onChange={handleChange_dateCreateGD} />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Nhu cầu</p>
                                            <select name="from" onChange={handleChange_nhucau} className={clsx(styles.selectOption)} value={stateString_nhucau}>
                                                <option value="Chọn...">Chọn...</option>
                                                <option value="Rút tiền">Rút tiền</option>
                                                <option value="Đáo hạn">Đáo hạn</option>
                                            </select>
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Chi nhánh</p>
                                            <select name="from" onChange={handleChiNhanh} className={clsx(styles.selectOption)} value={stateString_chinhanh}>
                                                <option value="empty">Chọn chi nhánh...</option>
                                                <option value="Q1">Q1</option>
                                                <option value="Q4">Q4</option>
                                                <option value="Q7">Q7</option>
                                                <option value="Q10">Q10</option>
                                                <option value="Q12">Q12</option>
                                                <option value="Q. Tân Phú">Q. Tân Phú</option>
                                                <option value="Dĩ An">Dĩ An</option>
                                                <option value="Bảo Lộc">Bảo Lộc</option>
                                                <option value="Vạn Phúc city">Vạn Phúc city</option>
                                            </select>
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Ghi chú rút đáo</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)}
                                                value={stateString_ghichurutdao}
                                                onChange={handlechange_ghichurutdao}
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Ghi chú phí GD</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)}
                                                value={stateString_ghichuphigiaodich}
                                                onChange={handlechange_ghichuphigiaodich}
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key, styles.importantKey)}>Tiền rút đáo</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)}
                                                value={stateNumber_tienrutdao_makeup}
                                                onChange={handleChange_tienrutdao}
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Phí rút đáo (%)</p>
                                            <input
                                                type="number"
                                                className={clsx(styles.inputTag)}
                                                value={stateNumber_phirutdao}
                                                onChange={handleChange_phirutdao}
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <Tooltip title="Tiền phí = Tiền rút đáo x phí rút đáo" placement="top">
                                                <p className={clsx(styles.key)}>Tiền phí</p>
                                            </Tooltip>
                                            <p className={clsx(styles.value)}>{formatThoudsand(stateNumber_tienphi)}</p>
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <Tooltip title="Tiền chuyển khách = Tiền rút đáo - tiền phí" placement="top">
                                                <p className={clsx(styles.key)}>Tiền chuyển khách</p>
                                            </Tooltip>
                                            <p className={clsx(styles.value)}>{formatThoudsand(stateNumber_tienChuyenKhach)}</p>
                                        </div>
                                        {/* <div className={clsx(styles.box)}>
                                        <p className={clsx(styles.key)}>Tiền ship</p>
                                        <input
                                            type="number"
                                            className={clsx(styles.inputTag)} 
                                            value={stateNumber_tienship}
                                            onChange={handleChange_tienship}
                                            />
                                    </div>
                                    <div className={clsx(styles.box)}>
                                        <p className={clsx(styles.key)}>Tiền ship Nhân viên</p>
                                        <p className={clsx(styles.value)}>{formatThoudsand(stateNumber_tienship/2)}</p>
                                    </div>
                                    <div className={clsx(styles.box)}>
                                        <p className={clsx(styles.key)}>Địa chỉ ship</p>
                                        <input
                                            type="text"
                                            className={clsx(styles.inputTag)} 
                                            value={stateString_diachiship}
                                            onChange={handleInputChange_diachiship}
                                            placeholder='nhập địa chỉ ship'
                                            />
                                    </div> */}
                                        {/* <div className={clsx(styles.box)}>
                                        <p className={clsx(styles.key)}>Ngày giờ liên hệ lại</p>
                                        <input
                                            type="text"
                                            className={clsx(styles.inputTag)} 
                                            value={stateString_ngaydukienlienhelai}
                                            onChange={handleChange_ngaydukienlienhelai}
                                            placeholder='YYYY-MM-DD HH:MM'
                                            />
                                    </div> */}
                                        {
                                            stateString_nhucau === "Đáo hạn" &&

                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Ngày hạn đáo thẻ</p>
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag, { [styles.wrong]: stateBool_valid_ngayhandaothe })}
                                                    value={stateString_ngayhanthanhtoan}
                                                    onChange={handleChange_ngayhanthanhtoan}
                                                    placeholder='dd-mm-yyyy'
                                                />
                                            </div>
                                        }

                                    </div>
                                    <div className={clsx(styles.right)}>
                                        {/* <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key, styles.chonTheTinDung)}>Chọn thẻ tín dụng cũ:</p>
                                            <select name="from" onChange={handleChange_theTinDung} className={clsx(styles.selectOption)} value={stateNum_theTinDungId}>
                                                <option value="Chọn...">Chọn...</option>
                                                {
                                                    stateArr_theOfSelectedKH.length !== 0 &&
                                                    stateArr_theOfSelectedKH.map((obj, index) => {
                                                        if (obj.soThe !== null && obj.soThe !== "" && obj.soThe !== undefined) {
                                                            return (
                                                                <option key={obj.id} value={obj.id}>{obj.loaiThe} - {formatCreditCardXXXX(obj.soThe)}</option>
                                                            );
                                                        } else {
                                                            return null; // Render nothing if obj.soThe doesn't meet the condition
                                                        }
                                                    })
                                                }
                                                <option value="Tạo thẻ mới">Tạo thẻ mới</option>
                                            </select>
                                        </div> */}
                                        {
                                            // trường hợp tạo giao dịch mới nhưng nhấn vào chữ Chọn...
                                            stateNum_theTinDungId === 0 ?
                                                // khi chọn tạo mới
                                                <>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Tên ngân hàng của thẻ</p>
                                                        {/* <select name="from" onChange={handleChange_tennganhang} className={clsx(styles.selectOption)} value={stateString_tennganhang}>
                                                    <option value="Chọn...">Chọn...</option>
                                                    
                                                    {
                                                        stateArrNganhang.map((obj, index) => {
                                                            return ( 
                                                                <option key={obj.id} value={obj.id}>{obj.id}</option>
                                                            )
                                                        })
                                                    }
                                                </select> */}
                                                        <input className={clsx(styles.inputTag)} type="text" id="listNganhang2" list="listNganhang22" onChange={handleChange_tennganhang} value={stateString_tennganhang} />

                                                        <datalist id="listNganhang22">
                                                            {stateArrNganhang &&
                                                                stateArrNganhang.map((obj, index) => {
                                                                    return (
                                                                        <option key={obj.id} value={obj.id} />
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Loại thẻ: </p>
                                                        <select name="from" onChange={handleChange_loaithe} className={clsx(styles.selectOption)} value={stateString_loaithe}>
                                                            <option value="Chọn...">Chọn...</option>
                                                            <option value="Visa">Visa</option>
                                                            <option value="Master card">Master card</option>
                                                            <option value="Evo">Evo</option>
                                                            <option value="JCB">JCB</option>
                                                            <option value="amex">amex</option>
                                                            <option value="Napas">Napas</option>
                                                            <option value="Lotte Finance">Lotte Finance</option>
                                                            <option value="Shinhan Finance">Shinhan Finance</option>
                                                        </select>
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Tên trên thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateString_tenTrenThe}
                                                            onChange={handleInputChange_tenTrenThe}
                                                            placeholder='Nhập tên trên thẻ tín dụng'
                                                        />
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>STK thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateString_soTaiKhoanTheTinDung}
                                                            onChange={handleInputChange_soTaiKhoanTheTinDung}
                                                            ref={inputRef_soTaiKhoanTheTinDung}
                                                        // placeholder='Nhập tên KH'
                                                        />
                                                    </div>
                                                    {/* trường hợp tạo giao dịch mới nhưng nhấn vào chữ Chọn... */}
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Số trên thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag, { [styles.wrong]: stateBool_isValid_sothetindung })}
                                                            value={stateString_sothetindung_makeup}
                                                            onChange={handlechange_sothetindung}
                                                            ref={inputRef_sothetindung}
                                                        />
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Hạn mức thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateNumber_hanmucthetindung_makeup}
                                                            onChange={handleChange_hanmucthetindung}
                                                        />
                                                    </div>


                                                    {/* <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Nhân viên ship</p>
                                                <select name="from" onChange={handleChange_nhanvienship} className={clsx(styles.selectOption)} value={stateString_nhanvienship}>
                                                    {
                                                        stateArrEmployee.map((obj, index) => {
                                                            return ( 
                                                                <option key={obj.id} value={obj.fullName}>{obj.fullName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div> */}
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Ai đang giữ thẻ</p>
                                                        <select name="from" onChange={handleChange_aiDangGiuThe} className={clsx(styles.selectOption)} value={stateString_aiDangGiuThe}>
                                                            <option value="Chọn...">Chọn...</option>
                                                            <option value="Chủ thẻ">Chủ thẻ</option>
                                                            <option value="Team rút đáo">Team rút đáo</option>
                                                        </select>
                                                    </div>
                                                </>
                                                :
                                                // khi chọn 1 thẻ tín dụng cũ
                                                <>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Tên ngân hàng của thẻ:</p>
                                                        <p className={clsx(styles.value)}>{stateString_tennganhang}</p>

                                                    </div>

                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Loại thẻ</p>
                                                        <p className={clsx(styles.value)}>{stateString_loaithe}</p>
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Tên trên thẻ</p>
                                                        <p className={clsx(styles.value)}>{stateString_tenTrenThe}</p>
                                                        {/* <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateString_tenTrenThe}
                                                            onChange={handleInputChange_tenTrenThe}
                                                            placeholder='Nhập tên trên thẻ tín dụng'
                                                        /> */}
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>STK thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateString_soTaiKhoanTheTinDung}
                                                            onChange={handleInputChange_soTaiKhoanTheTinDung}
                                                            ref={inputRef_soTaiKhoanTheTinDung}
                                                        // placeholder='Nhập tên KH'
                                                        />
                                                    </div>
                                                    {/* trường hợp tạo giao dịch mới nhưng nhấn vào chữ Chọn... */}
                                                    {/* Khi chọn 1 thẻ cũ */}
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Số trên thẻ tín dụng (new)</p>
                                                        {
                                                            stateString_sothetindung_makeup && ( // Check if stateString_sothetindung_makeup is truthy
                                                                <p className={clsx(styles.value, styles.copyToClipboard)} onClick={() => copyToClipboard_creditCard(stateString_sothetindung_makeup)}>
                                                                    {formatCreditCardXXXX(stateString_sothetindung_makeup)}
                                                                </p>
                                                            )
                                                        }
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Hạn mức thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateNumber_hanmucthetindung_makeup}
                                                            onChange={handleChange_hanmucthetindung}
                                                        />
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Ai đang giữ thẻ</p>
                                                        <select name="from" onChange={handleChange_aiDangGiuThe} className={clsx(styles.selectOption)} value={stateString_aiDangGiuThe}>
                                                            <option value="Chọn...">Chọn...</option>
                                                            <option value="Chủ thẻ">Chủ thẻ</option>
                                                            <option value="Team rút đáo">Team rút đáo</option>
                                                        </select>
                                                    </div>
                                                </>
                                        }
                                        {
                                            stateString_nhucau === "Rút tiền" &&
                                            <>
                                                <div className={clsx(styles.box3)}>
                                                    <Tooltip title="Áp dung khi Rút" placement="top">
                                                        <p className={clsx(styles.key)}>Thông tin ngân hàng để chuyển tiền cho khách:</p>
                                                    </Tooltip>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <Tooltip title="Áp dung khi Rút" placement="top">
                                                        <p className={clsx(styles.key)}>Số tài khoản ngân hàng</p>
                                                    </Tooltip>
                                                    <input
                                                        type="text"
                                                        className={clsx(styles.inputTag)}
                                                        value={stateString_sotaikhoannganhang}
                                                        onChange={handlechange_sotaikhoannganhang}
                                                        ref={inputRef_sotaikhoannganhang}
                                                    // placeholder='Nhập tên KH'
                                                    />
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <Tooltip title="Áp dung khi Rút" placement="top">
                                                        <p className={clsx(styles.key)}>Tên ngân hàng chuyển tiền</p>
                                                    </Tooltip>
                                                    <input className={clsx(styles.inputTag)} type="text" id="listNganhang1" list="listNganhang11" onChange={handlechange_tenNganHangChuyenTien} value={stateString_tenNganHangChuyenTien} />
                                                    <datalist id="listNganhang11">
                                                        {stateArrNganhang &&
                                                            stateArrNganhang.map((obj, index) => {
                                                                return (
                                                                    <option key={obj.id} value={obj.id} />
                                                                )
                                                            })
                                                        }
                                                    </datalist>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Tạo mới giạo dịch của khách hàng cũ */}
                        {
                            stateBool_showAddNewCustomer === false && stateBool_showAddNewGD === true &&
                            <div className={clsx(styles.addNewCustomer_wrapper)}>
                                <div className={clsx(styles.topAddNewCustomer_wrapper)}>
                                    <span>
                                        {
                                            stateBool_invisible_groups && stateBool_arrow_lastLeftArrow &&
                                            <KeyboardDoubleArrowLeftIcon
                                                className={clsx(styles.leftArrow_when_2_col)}
                                                onClick={invisible_groups_and_listKHWrapper}
                                            />
                                        }
                                        {
                                            stateBool_invisible_listKHWrapper &&
                                            <KeyboardDoubleArrowRightIcon className={clsx(styles.rightArrow)} onClick={show_groups_and_listKHWrapper} />
                                        }
                                    </span>
                                    <h3>Thêm mới giao dịch của khách hàng đã chọn</h3>
                                    <span></span>
                                </div>
                                <div className={clsx(styles.TopBtn_createNew)}>
                                    <button onClick={save_new_GD_of_old_customer} className={clsx(styles.btnSave)}>Tạo mới giao dịch & thẻ</button>
                                    <button onClick={cancelAddNew_KH_GD} className={clsx(styles.btnCancel)}>Cancel</button>
                                </div>
                                <div className={clsx(styles.inputSection, { [styles.inputSection_2_col]: stateBool_showAddNewGD })}>
                                    <div className={clsx(styles.middle)}>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Nhu cầu</p>
                                            <select name="from" onChange={handleChange_nhucau} className={clsx(styles.selectOption)} value={stateString_nhucau}>
                                                <option value="Chọn...">Chọn...</option>
                                                <option value="Rút tiền">Rút tiền</option>
                                                <option value="Đáo hạn">Đáo hạn</option>
                                            </select>
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Ngày tạo GD</p>
                                            <input type="date"
                                                className={clsx(styles.inputDate)}
                                                value={stateString_dateCreateGD}
                                                onChange={handleChange_dateCreateGD} />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Chi nhánh</p>
                                            <select name="from" onChange={handleChiNhanh} className={clsx(styles.selectOption)} value={stateString_chinhanh}>
                                                <option value="empty">Chọn chi nhánh...</option>
                                                <option value="Q1">Q1</option>
                                                <option value="Q4">Q4</option>
                                                <option value="Q7">Q7</option>
                                                <option value="Q10">Q10</option>
                                                <option value="Q12">Q12</option>
                                                <option value="Q. Tân Phú">Q. Tân Phú</option>
                                                <option value="Dĩ An">Dĩ An</option>
                                                <option value="Bảo Lộc">Bảo Lộc</option>
                                                <option value="Vạn Phúc city">Vạn Phúc city</option>
                                            </select>
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Ghi chú rút đáo</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)}
                                                value={stateString_ghichurutdao}
                                                onChange={handlechange_ghichurutdao}
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Ghi chú phí GD</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)}
                                                value={stateString_ghichuphigiaodich}
                                                onChange={handlechange_ghichuphigiaodich}
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key, styles.importantKey)}>Tiền rút đáo</p>
                                            <input
                                                type="text"
                                                className={clsx(styles.inputTag)}
                                                value={stateNumber_tienrutdao_makeup}
                                                onChange={handleChange_tienrutdao}
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key)}>Phí rút đáo (%)</p>
                                            <input
                                                type="number"
                                                className={clsx(styles.inputTag)}
                                                value={stateNumber_phirutdao}
                                                onChange={handleChange_phirutdao}
                                            />
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <Tooltip title="Tiền phí = Tiền rút đáo x phí rút đáo" placement="top">
                                                <p className={clsx(styles.key)}>Tiền phí</p>
                                            </Tooltip>
                                            <p className={clsx(styles.value)}>{formatThoudsand(stateNumber_tienphi)}</p>
                                        </div>
                                        <div className={clsx(styles.box)}>
                                            <Tooltip title="Tiền chuyển khách = Tiền rút đáo - tiền phí" placement="top">
                                                <p className={clsx(styles.key)}>Tiền chuyển khách</p>
                                            </Tooltip>
                                            <p className={clsx(styles.value)}>{formatThoudsand(stateNumber_tienChuyenKhach)}</p>
                                        </div>
                                        {/* <div className={clsx(styles.box)}>
                                        <p className={clsx(styles.key)}>Tiền ship</p>
                                        <input
                                            type="number"
                                            className={clsx(styles.inputTag)} 
                                            value={stateNumber_tienship}
                                            onChange={handleChange_tienship}
                                            />
                                    </div>
                                    <div className={clsx(styles.box)}>
                                        <p className={clsx(styles.key)}>Tiền ship Nhân viên</p>
                                        <p className={clsx(styles.value)}>{formatThoudsand(stateNumber_tienship/2)}</p>
                                    </div>
                                    <div className={clsx(styles.box)}>
                                        <p className={clsx(styles.key)}>Địa chỉ ship</p>
                                        <input
                                            type="text"
                                            className={clsx(styles.inputTag)} 
                                            value={stateString_diachiship}
                                            onChange={handleInputChange_diachiship}
                                            placeholder='nhập địa chỉ ship'
                                            />
                                    </div> */}
                                        {/* <div className={clsx(styles.box)}>
                                        <p className={clsx(styles.key)}>Ngày giờ liên hệ lại</p>
                                        <input
                                            type="text"
                                            className={clsx(styles.inputTag)} 
                                            value={stateString_ngaydukienlienhelai}
                                            onChange={handleChange_ngaydukienlienhelai}
                                            placeholder='YYYY-MM-DD HH:MM'
                                            />
                                    </div> */}
                                        {
                                            stateString_nhucau === "Đáo hạn" &&

                                            <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Ngày hạn đáo thẻ</p>
                                                <input
                                                    type="text"
                                                    className={clsx(styles.inputTag, { [styles.wrong]: stateBool_valid_ngayhandaothe })}
                                                    value={stateString_ngayhanthanhtoan}
                                                    onChange={handleChange_ngayhanthanhtoan}
                                                    placeholder='dd-mm-yyyy'
                                                />
                                            </div>
                                        }

                                    </div>
                                    <div className={clsx(styles.right)}>
                                        <div className={clsx(styles.box)}>
                                            <p className={clsx(styles.key, styles.chonTheTinDung)}>Chọn thẻ tín dụng cũ:</p>
                                            <select name="from" onChange={handleChange_theTinDung} className={clsx(styles.selectOption)} value={stateNum_theTinDungId}>
                                                <option value="Chọn...">Chọn...</option>
                                                {
                                                    stateArr_theOfSelectedKH.length !== 0 &&
                                                    stateArr_theOfSelectedKH.map((obj, index) => {
                                                        if (obj.soThe !== null && obj.soThe !== "" && obj.soThe !== undefined) {
                                                            return (
                                                                <option key={obj.id} value={obj.id}>{obj.loaiThe} - {formatCreditCardXXXX(obj.soThe)}</option>
                                                            );
                                                        } else {
                                                            return null; // Render nothing if obj.soThe doesn't meet the condition
                                                        }
                                                    })
                                                }
                                                <option value="Tạo thẻ mới">Tạo thẻ mới</option>
                                            </select>
                                        </div>
                                        {
                                            // trường hợp tạo giao dịch mới nhưng nhấn vào chữ Chọn...
                                            stateNum_theTinDungId === 0 ?
                                                // khi chọn tạo mới
                                                <>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Tên ngân hàng của thẻ</p>
                                                        {/* <select name="from" onChange={handleChange_tennganhang} className={clsx(styles.selectOption)} value={stateString_tennganhang}>
                                                    <option value="Chọn...">Chọn...</option>
                                                    
                                                    {
                                                        stateArrNganhang.map((obj, index) => {
                                                            return ( 
                                                                <option key={obj.id} value={obj.id}>{obj.id}</option>
                                                            )
                                                        })
                                                    }
                                                </select> */}
                                                        <input className={clsx(styles.inputTag)} type="text" id="listNganhang2" list="listNganhang22" onChange={handleChange_tennganhang} value={stateString_tennganhang} />

                                                        <datalist id="listNganhang22">
                                                            {stateArrNganhang &&
                                                                stateArrNganhang.map((obj, index) => {
                                                                    return (
                                                                        <option key={obj.id} value={obj.id} />
                                                                    )
                                                                })
                                                            }
                                                        </datalist>
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Loại thẻ: </p>
                                                        <select name="from" onChange={handleChange_loaithe} className={clsx(styles.selectOption)} value={stateString_loaithe}>
                                                            <option value="Chọn...">Chọn...</option>
                                                            <option value="Visa">Visa</option>
                                                            <option value="Master card">Master card</option>
                                                            <option value="Evo">Evo</option>
                                                            <option value="JCB">JCB</option>
                                                            <option value="amex">amex</option>
                                                            <option value="Napas">Napas</option>
                                                            <option value="Lotte Finance">Lotte Finance</option>
                                                            <option value="Shinhan Finance">Shinhan Finance</option>
                                                        </select>
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Tên trên thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateString_tenTrenThe}
                                                            onChange={handleInputChange_tenTrenThe}
                                                            placeholder='Nhập tên trên thẻ tín dụng'
                                                        />
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>STK thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateString_soTaiKhoanTheTinDung}
                                                            onChange={handleInputChange_soTaiKhoanTheTinDung}
                                                            ref={inputRef_soTaiKhoanTheTinDung}
                                                        // placeholder='Nhập tên KH'
                                                        />
                                                    </div>
                                                    {/* trường hợp tạo giao dịch mới nhưng nhấn vào chữ Chọn... */}
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Số trên thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag, { [styles.wrong]: stateBool_isValid_sothetindung })}
                                                            value={stateString_sothetindung_makeup}
                                                            onChange={handlechange_sothetindung}
                                                            ref={inputRef_sothetindung}
                                                        />
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Hạn mức thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateNumber_hanmucthetindung_makeup}
                                                            onChange={handleChange_hanmucthetindung}
                                                        />
                                                    </div>


                                                    {/* <div className={clsx(styles.box)}>
                                                <p className={clsx(styles.key)}>Nhân viên ship</p>
                                                <select name="from" onChange={handleChange_nhanvienship} className={clsx(styles.selectOption)} value={stateString_nhanvienship}>
                                                    {
                                                        stateArrEmployee.map((obj, index) => {
                                                            return ( 
                                                                <option key={obj.id} value={obj.fullName}>{obj.fullName}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div> */}
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Ai đang giữ thẻ</p>
                                                        <select name="from" onChange={handleChange_aiDangGiuThe} className={clsx(styles.selectOption)} value={stateString_aiDangGiuThe}>
                                                            <option value="Chọn...">Chọn...</option>
                                                            <option value="Chủ thẻ">Chủ thẻ</option>
                                                            <option value="Team rút đáo">Team rút đáo</option>
                                                        </select>
                                                    </div>
                                                </>
                                                :
                                                // khi chọn 1 thẻ tín dụng cũ
                                                <>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Tên ngân hàng của thẻ:</p>
                                                        <p className={clsx(styles.value)}>{stateString_tennganhang}</p>

                                                    </div>

                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Loại thẻ</p>
                                                        <p className={clsx(styles.value)}>{stateString_loaithe}</p>
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Tên trên thẻ</p>
                                                        <p className={clsx(styles.value)}>{stateString_tenTrenThe}</p>
                                                        {/* <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateString_tenTrenThe}
                                                            onChange={handleInputChange_tenTrenThe}
                                                            placeholder='Nhập tên trên thẻ tín dụng'
                                                        /> */}
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>STK thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateString_soTaiKhoanTheTinDung}
                                                            onChange={handleInputChange_soTaiKhoanTheTinDung}
                                                            ref={inputRef_soTaiKhoanTheTinDung}
                                                        // placeholder='Nhập tên KH'
                                                        />
                                                    </div>
                                                    {/* trường hợp tạo giao dịch mới nhưng nhấn vào chữ Chọn... */}
                                                    {/* Khi chọn 1 thẻ cũ */}
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Số trên thẻ tín dụng</p>
                                                        {
                                                            stateString_sothetindung_makeup && ( // Check if stateString_sothetindung_makeup is truthy
                                                                <p className={clsx(styles.value, styles.copyToClipboard)} onClick={() => copyToClipboard_creditCard(stateString_sothetindung_makeup)}>
                                                                    {formatCreditCardXXXX(stateString_sothetindung_makeup)}
                                                                </p>
                                                            )
                                                        }
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Hạn mức thẻ tín dụng</p>
                                                        <input
                                                            type="text"
                                                            className={clsx(styles.inputTag)}
                                                            value={stateNumber_hanmucthetindung_makeup}
                                                            onChange={handleChange_hanmucthetindung}
                                                        />
                                                    </div>
                                                    <div className={clsx(styles.box)}>
                                                        <p className={clsx(styles.key)}>Ai đang giữ thẻ</p>
                                                        <select name="from" onChange={handleChange_aiDangGiuThe} className={clsx(styles.selectOption)} value={stateString_aiDangGiuThe}>
                                                            <option value="Chọn...">Chọn...</option>
                                                            <option value="Chủ thẻ">Chủ thẻ</option>
                                                            <option value="Team rút đáo">Team rút đáo</option>
                                                        </select>
                                                    </div>
                                                </>
                                        }
                                        {
                                            stateString_nhucau === "Rút tiền" &&
                                            <>
                                                <div className={clsx(styles.box3)}>
                                                    <Tooltip title="Áp dung khi Rút" placement="top">
                                                        <p className={clsx(styles.key)}>Thông tin ngân hàng để chuyển tiền cho khách:</p>
                                                    </Tooltip>
                                                </div>
                                                <div className={clsx(styles.box)}>
                                                    <p className={clsx(styles.key, styles.chonTheTinDung)}>Chọn STK Ngân hàng cũ</p>
                                                    <select name="from" onChange={handleChange_stknh} className={clsx(styles.selectOption)} value={stateNum_stknhId}>
                                                        <option value="Chọn...">Chọn...</option>
                                                        {stateArr_STKNH_OfSelectedKH &&
                                                            stateArr_STKNH_OfSelectedKH.map((obj, index) => {
                                                                return (
                                                                    <option key={obj.id} value={obj.id}>{obj.tenNganHangChuyenTien} - {obj.soTaiKhoanNganHang}</option>
                                                                )
                                                            })
                                                        }
                                                        <option value="Tạo mới">Tạo mới</option>
                                                    </select>
                                                </div>
                                                {
                                                    stateNum_stknhId === 0 ?
                                                        <>
                                                            <div className={clsx(styles.box)}>
                                                                <Tooltip title="Áp dung khi Rút" placement="top">
                                                                    <p className={clsx(styles.key)}>Số tài khoản ngân hàng</p>
                                                                </Tooltip>
                                                                <input
                                                                    type="text"
                                                                    className={clsx(styles.inputTag)}
                                                                    value={stateString_sotaikhoannganhang}
                                                                    onChange={handlechange_sotaikhoannganhang}
                                                                    ref={inputRef_sotaikhoannganhang}
                                                                // placeholder='Nhập tên KH'
                                                                />
                                                            </div>
                                                            <div className={clsx(styles.box)}>
                                                                <Tooltip title="Áp dung khi Rút" placement="top">
                                                                    <p className={clsx(styles.key)}>Tên ngân hàng chuyển tiền</p>
                                                                </Tooltip>
                                                                <input className={clsx(styles.inputTag)} type="text" id="listNganhang1" list="listNganhang11" onChange={handlechange_tenNganHangChuyenTien} value={stateString_tenNganHangChuyenTien} />
                                                                <datalist id="listNganhang11">
                                                                    {stateArrNganhang &&
                                                                        stateArrNganhang.map((obj, index) => {
                                                                            return (
                                                                                <option key={obj.id} value={obj.id} />
                                                                            )
                                                                        })
                                                                    }
                                                                </datalist>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className={clsx(styles.box)}>
                                                                <Tooltip title="Áp dung khi Rút" placement="top">
                                                                    <p className={clsx(styles.key)}>Số tài khoản ngân hàng</p>
                                                                </Tooltip>
                                                                <p className={clsx(styles.value)}>{stateString_sotaikhoannganhang}</p>
                                                            </div>
                                                            <div className={clsx(styles.box)}>
                                                                <Tooltip title="Áp dung khi Rút" placement="top">
                                                                    <p className={clsx(styles.key)}>Tên ngân hàng chuyển tiền</p>
                                                                </Tooltip>
                                                                <p className={clsx(styles.value)}>{stateString_tenNganHangChuyenTien}</p>

                                                            </div>
                                                        </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={clsx(styles.backShadow, { [styles.appear]: stateBool_isOpenDialog })} onClick={cancelDialog}>
                </div>
                <div className={clsx(styles.dialogImg, { [styles.appear]: stateBool_isOpenDialog })}>
                    <img src={stateString_selectedImg} alt="hinh giao dich" />
                </div>


            </div>
    )
}
export default RutDaoKhachHang

