import { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './Caidat.module.scss'
import { retrieveObjectFromLocalStorage } from '../../share/sharedFunction';
import NotYetSignIn from '../general/NotYetSignIn';

function Caidat() {
   const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
      email: "",
      fullName: "",
      role: "",
      token:""
   })
   const checkSignin = () => {

      const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
      // console.log('userExist: ', userExist);
      if(userExist === null){
         return
      }
      setStateObjSignIn(userExist);

   }
   useEffect(() => {
      checkSignin();
   },[]);
   return (
      stateObjSignIn.token === "" ? <NotYetSignIn /> :

      <div className={clsx(styles.component_Caidat)}>
         <div className={clsx(styles.container)}>
            {
               stateObjSignIn.role === "admin" &&
               <div className={clsx(styles.pair)}>
                  <p className={clsx(styles.key)}>Token</p>
                  <p className={clsx(styles.value)}>{stateObjSignIn.token}</p>
               </div>
            }
         </div>
      </div>
   )
}
export default Caidat