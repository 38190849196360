import './App.css';
import MainView from './components/pages/MainView';
function App() {
  return (
    <div className="App">
      <MainView/>
    </div>
  );
}

export default App;
