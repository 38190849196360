import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './Agent.module.scss'
import AgentSubMenu from './AgentSubMenu';
import AgentMenuBar from './AgentMenuBar';

function AgentHome() {
   const dispatch = useDispatch(); //hook phát đi đến GeneralSlice.js

   return (
      <div className={clsx(styles.component_AgentHome)}>
         <AgentMenuBar/>
         <AgentSubMenu/>
         <Outlet/>

      </div>
   )
}
export default AgentHome


