import { useState } from 'react';
import clsx from 'clsx';
import styles from './Capnhat.module.scss' 
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

function Capnhat() {
    const arrDetails = {
        hr: [
            "Upload file chấm công excel và nhập tự động vào database",
            "Báo cáo phân tích dữ liệu chấm công",
            "Gửi mail chấm công cho nhân viên"
        ],
        cskh: [
            "Nhập dữ liệu khách hàng mới",
            "Cập nhật dữ liệu khách hàng",
            "Xuất ra file Excel",
            "Báo cáo lượng khách hàng trong ngày theo từng nhân viên",
        ],
        rutdao: [
            "Gom khách hàng them nguồn",
            "Tiến hành giao dịch Đáo",
            "Tiến hành giao dịch Rút",
            "Hiện danh sách nhóm khách hàng",
            "Hiện danh sách khách hàng",
            "Hiện danh sách giao dịch",
            "Hiện danh sách thẻ",
            "Lưu dữ liệu khách hàng / Thẻ",
            "Tìm kiếm khách hàng",
            "Thêm hình CCCD, tiền chuyển phí, lô bill...",
            "Tạo khách hàng mới",
            "Tạo giao dịch / Thẻ mới",
            "Cập nhật dữ liệu Khách hàng",
            "Theo dõi dòng chảy giao dịch",
            "Hiện danh sách giao dịch và filter",
            "Hiện danh sách thẻ và filter",
            "Download và hiện sao kê cho các tài khoản Quỹ",
            "Phân tích sao kê",
            "Tổng hợp sao kê",
            "Báo cáo thu chi",
        ],
        ketoan: [
            "Tổng hợp tiền Pos về, phí Pos",
            "Tính lợi nhuận",
            "Xác nhận hoàn thành giao dịch",
            "Kiểm tra đối chiếu tiền phí khách chuyển"
        ],
    }
    const [stateArrDetails, setStateArrDetails] = useState<string[]>(arrDetails.ketoan);
    const [stateString_selectedWork, setStateString_selectedWork] = useState<string>('ketoan');
    const showDetails = (key: string) => {
        let newState: string[] = [];
        switch (key) {
            case 'hr':
                newState = arrDetails.hr;
                
                break;
            case 'cskh':
                newState = arrDetails.cskh;
                
                break;
            case 'rutdao':
                newState = arrDetails.rutdao;
                
                break;
            case 'ketoan':
                newState = arrDetails.ketoan;
                
                break;
            default:
                break;
        }
        setStateArrDetails(newState);
        setStateString_selectedWork(key);
    }

    return (
        <div className={clsx(styles.component_Capnhat)}>
            <h4>CẬP NHẬT</h4>
            <div className={clsx(styles.container)}>
                <div className={clsx(styles.left)}>
                    <h5>Timeline phát triển</h5>

                    <Timeline
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.8,
                            },
                        }}
                        >
                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                            15/02 - 25/02/2024
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent 
                            onClick={()=>showDetails('ketoan')} 
                            className={clsx(styles.work, {[styles.selectedmenuItem]: 'ketoan' === stateString_selectedWork})}
                            >Phòng Kế toán</TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                            31/11 - 14/01/2024
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent 
                            onClick={()=>showDetails('rutdao')} 
                            className={clsx(styles.work, {[styles.selectedmenuItem]: 'rutdao' === stateString_selectedWork})
                            }>Phòng Rút Đáo</TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                            24/11 - 30/12/2023
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent 
                            onClick={()=>showDetails('cskh')} 
                            className={clsx(styles.work, {[styles.selectedmenuItem]: 'cskh' === stateString_selectedWork})}
                            >Phòng CSKH</TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                            17/11 - 23/11/2023
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                            <TimelineDot />
                            </TimelineSeparator>
                            <TimelineContent 
                            onClick={()=>showDetails('hr')} 
                            className={clsx(styles.work, {[styles.selectedmenuItem]: 'hr' === stateString_selectedWork})}
                            >Phòng HR</TimelineContent>
                        </TimelineItem>
                    </Timeline>
                
                </div>
                <div className={clsx(styles.right)}>
                    <h5>Chi tiết cập nhật:</h5>
                    <ol>
                        {
                            stateArrDetails.map((obj, index) => {
                                return ( 
                                    <li className={clsx(styles.item)} key={obj}>{obj}</li>
                                )
                            })
                        }
                    </ol>
                </div>
            </div>
        </div>
    )
}
export default Capnhat