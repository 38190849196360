export function checkStringType(inputString) {
  const containsDigit = /\d/.test(inputString);
  const containsLetter = /[a-zA-Z]/.test(inputString);
  // const containsInvalidChar = /[^a-zA-Z0-9]/.test(inputString);

  // if (containsInvalidChar) {
  //   return "invalid";
  if (containsDigit && containsLetter) {
    return "digit_letter";
  } else if (containsDigit) {
    return "digit";
  } else if (containsLetter) {
    return "letter";
  } else {
      // Handle cases where the string is empty
    return "invalid";
  }
}

export function validateUsername(username) {
  // Check if the username is not empty
  if (!username) {
      return false;
  }

  // Check length constraints
  if (username.length < 4 || username.length > 20) {
      return false;
  }

  // Check for allowed characters using a regular expression
  if (!/^[a-zA-Z0-9_]+$/.test(username)) {
      return false;
  }

  // Validation passed
  return true;
}

export function validateEmail(email) {
  // Regular expression for stricter email validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export function isTodayOrLater(inputDate) {
  // Parse the input date string into a Date object
  const inputDateObj = new Date(inputDate);

  // Get the current date
  const currentDate = new Date();

  // Set the time part of the current date to midnight for accurate comparison
  currentDate.setHours(0, 0, 0, 0);

  // Compare the input date with the current date
  return inputDateObj >= currentDate;
}
export function isFuture(inputDate) {
  // Parse the input date string into a Date object
  const inputDateObj = new Date(inputDate);

  // Get the current date
  const currentDate = new Date();

  // Set the time part of the current date to midnight for accurate comparison
  currentDate.setHours(0, 0, 0, 0);

  // Compare the input date with the current date and ensure it's greater than the current date
  return inputDateObj > currentDate;
}

export function isFromTodayToThePast(inputDate) {
  // Parse the input date string into a Date object
  const inputDateObj = new Date(inputDate);

  // Get the current date
  const currentDate = new Date();

  // Set the time part of the current date to midnight for accurate comparison
  currentDate.setHours(0, 0, 0, 0);
  // Get tomorrow's date
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  tomorrowDate.setHours(0, 0, 0, 0);
  
  // Compare the input date with today and tomorrow
  return inputDateObj <= tomorrowDate;
}

export function validateyyyy_mm_dd_hh_mm(inputString) {
  // Define the regular expression pattern for the format "YYYY-MM-DD HH:MM"
  const pattern = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

  // Test the input string against the pattern
  const match = inputString.match(pattern);

  if (!match) {
      // The input string does not match the expected format
      return false;
  }

  // Extract components from the matched groups
  const year = parseInt(match[1], 10);
  const month = parseInt(match[2], 10);
  const day = parseInt(match[3], 10);
  const hour = parseInt(match[4], 10);
  const minute = parseInt(match[5], 10);

  // Get the current date and time
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed
  const currentDay = currentDate.getDate();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();

  // Check if input is later than current date and time
  if (
      year > currentYear ||
      (year === currentYear && month > currentMonth) ||
      (year === currentYear && month === currentMonth && day > currentDay) ||
      (year === currentYear && month === currentMonth && day === currentDay && hour > currentHour) ||
      (year === currentYear && month === currentMonth && day === currentDay && hour === currentHour && minute > currentMinute)
  ) {
      // The input string has a valid format and is later than today and the current hour
      return true;
  }

  // The input string has the correct format but is not later than today and the current hour
  return false;
}

export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function validateTienrutdao(input) {
  // Check if input is a valid number
  if (typeof input !== 'number' || isNaN(input)) {
    return false;
  }

  // Check if the number is an integer (not a float)
  if (!Number.isInteger(input)) {
    return false;
  }

  // Check if the number is within the specified range
  if (input >= 1000000 && input <= 900000000) {
    return true;
  }

  // Number does not satisfy the criteria
  return false;
}

export function validatePhirutdao(input) {
  // Check if input is not empty
  if (input === undefined || input === null || input === '') {
      return false;
  }

  // Convert the input to a number
  const numberValue = parseFloat(input);

  // Check if the converted value is a valid number
  if (isNaN(numberValue)) {
      return false;
  }

  // Check if the number is within the specified range
  if (numberValue >= 0.5 && numberValue <= 8) {
      return true;
  }

  // Number does not satisfy the criteria
  return false;
}
export function convertDateFormat_dd_mm_yyyy_to_yyyy_mm_dd(dateString) {
  // Split the date string into day, month, and year parts
  var parts = dateString.split('-');
  
  // Rearrange the parts into the yyyy-mm-dd format
  var yyyy_mm_dd = parts[2] + '-' + parts[1] + '-' + parts[0];
  
  return yyyy_mm_dd;
}
export function isValidDateFormat_dd_mm_yyyy(dateString) { //check dd_mm_yyyy có đúng ko
  var dateFormat = /^\d{2}-\d{2}-\d{4}$/;
  
  if (!dateFormat.test(dateString)) {
    return false; // Not in correct format
  }
  
  var parts = dateString.split('-');
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);
  
  // Validate month
  if (month < 1 || month > 12) {
    return false; // Invalid month
  }
  
  // Validate day based on month and year
  var maxDaysInMonth = new Date(year, month, 0).getDate();
  if (day < 1 || day > maxDaysInMonth) {
    return false; // Invalid day
  }
  
  return true; // Valid date
}

export function isValid_yyyy_mm_dd_hh_mm(inputString) { //check true false if input has correct format: yyyy-mm-dd hh:mm
  const dateTimeRegex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) (2[0-3]|[01][0-9]):([0-5][0-9])$/;
  
  const match = inputString.match(dateTimeRegex);

  if (!match) {
    // The input string does not match the expected format
    return false;
  }

  const year = parseInt(match[1]);
  const month = parseInt(match[2]);
  const day = parseInt(match[3]);
  const hour = parseInt(match[4]);
  const minute = parseInt(match[5]);

  // Validate year, month, and day
  if (isNaN(year) || year < 1000 || year > 9999 || isNaN(month) || month < 1 || month > 12 || isNaN(day) || day < 1 || day > new Date(year, month, 0).getDate()) {
    return false;
  }

  // Validate hour and minute
  if (isNaN(hour) || hour < 0 || hour > 23 || isNaN(minute) || minute < 0 || minute > 59) {
    return false;
  }

  // If all checks pass, the format and values are valid
  return true;
}
export function formatDate(inputDate) { // "2024-01-09T23:00:00.000Z" to "yyyy-mm-dd hh:mm"
  // console.log('inputDate: ', inputDate);
  alert(inputDate);
  if(inputDate === null){
    return "";
  }
  const date = new Date(inputDate);
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
export function parseDateString(inputDateString) { // "yyyy-mm-dd hh:mm" to "yyyy-mm-ddTHH:mm:ss.SSSZ"
  const [datePart, timePart] = inputDateString.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');

  const isoDateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00.000Z`;

  return isoDateString;
}

export function validatePhoneNumber(phoneNumber) {
    // Regular expression to validate a phone number with exactly 10 digits
  const phoneRegex = /^\d{10}$/;

  // Test if the phone number matches the regex
  if (!phoneRegex.test(phoneNumber)) {
    return null; // Return null if the basic format is not met
  }

  // Extract the first three digits of the phone number
  const firstThreeDigits = phoneNumber.substring(0, 3);

  // Define lists of valid first three digits for each carrier
  const prefixMap = {
    "Viettel": ["086", "096", "097", "098", "039", "038", "037", "036", "035", "034", "033", "032"],
    "Vinaphone": ["091", "094", "088", "083", "084", "085", "081", "082"],
    "Mobiphone": ["070", "079", "077", "076", "078", "089", "090", "093"],
    "Vietnamobile": ["092", "052", "056", "058"],
    "Gmobile": ["099", "059"],
    "Itelecom": ["087"],
  };

  // Check if the first three digits belong to any of the specified lists
  for (const [carrier, prefixes] of Object.entries(prefixMap)) {
    if (prefixes.includes(firstThreeDigits)) {
      return carrier; // Phone number is valid, return the carrier name
    }
  }

  return null; // Phone number is not valid
};
export function validatePhoneNumberBoolean(phoneNumber) {
    // Regular expression to validate a phone number with exactly 10 digits
  const phoneRegex = /^\d{10}$/;

  // Test if the phone number matches the regex
  if (!phoneRegex.test(phoneNumber)) {
    return false; // Return null if the basic format is not met
  }

  // Extract the first three digits of the phone number
  const firstThreeDigits = phoneNumber.substring(0, 3);

  // Define lists of valid first three digits for each carrier
  const prefixMap = {
    "Viettel": ["086", "096", "097", "098", "039", "038", "037", "036", "035", "034", "033", "032"],
    "Vinaphone": ["091", "094", "088", "083", "084", "085", "081", "082"],
    "Mobiphone": ["070", "079", "077", "076", "078", "089", "090", "093"],
    "Vietnamobile": ["092", "052", "056", "058"],
    "Gmobile": ["099", "059"],
    "Itelecom": ["087"],
  };

  // Check if the first three digits belong to any of the specified lists
  for (const [carrier, prefixes] of Object.entries(prefixMap)) {
    if (prefixes.includes(firstThreeDigits)) {
      return true; // Phone number is valid, 
    }
  }

  return false; // Phone number is not valid
};
  
export function validateTienRut(number) {
  const minValue = 1000000;
  const maxValue = 900000000;

  // Convert the input to a numeric value
  const numericValue = parseInt(number+"");

  // Check if the numeric value is a valid number and within the specified range
  if (!isNaN(numericValue) && numericValue >= minValue && numericValue <= maxValue) {
    return true;
      // const stringValue = String(numericValue);

      // // Check if the numeric value is an integer or a floating-point number
      // if (Number.isInteger(numericValue)) {
      //     // Check if the last three digits are zero
      //     if (stringValue.slice(-3) === '000') { // 3 số cuối phải là 0 để bảo đảm tròn
      //         return true; // Number is a rounded integer with last three digits as zero
      //     } else {
      //         return false; // Last three digits are not zero
      //     }

      // } else {
      //     return false; // Number is a floating-point number
      // }
  } else {
      return false; // Number is invalid or out of range
  }
}
export function validateTienship(number) {
  const minValue = 20000;
  const maxValue = 9000000;

  // Convert the input to a numeric value
  const numericValue = parseInt(number+"");

  // Check if the numeric value is a valid number and within the specified range
  if (!isNaN(numericValue) && numericValue >= minValue && numericValue <= maxValue) {
    return true;
      // const stringValue = String(numericValue);

      // // Check if the numeric value is an integer or a floating-point number
      // if (Number.isInteger(numericValue)) {

      //     // Check if the last three digits are zero
      //     if (stringValue.slice(-3) === '000') { // 3 số cuối phải là 0 để bảo đảm tròn
      //         return true; // Number is a rounded integer with last three digits as zero
      //     } else {
      //         return false; // Last three digits are not zero
      //     }

      // } else {
      //     return false; // Number is a floating-point number
      // }
  } else {
      return false; // Number is invalid or out of range
  }
}

  
export function validate_phirutdao(input) {
  // Regular expression to validate the input
//   const regex = /^[0-3]\.\d$/;
    const regex = /^(0\.[6-9]|1\.\d|2\.[0-9]|3\.[0-9])$/;

  // Test if the input matches the regex
  return regex.test(input);
  }

export function addWeekdays(days) {
  let resultDate = new Date();

  while (days > 0) {
    resultDate.setDate(resultDate.getDate() + 1);

    // Check if the current day is not a weekend (Saturday or Sunday)
    if (resultDate.getDay() !== 0 && resultDate.getDay() !== 6) {
      days--;
    }
  }

  // Convert the resultDate to a string
  return resultDate.toDateString();
}

export function calculateFutureDate (days) {
  let resultDate = new Date();

  while (days > 0) {
      resultDate.setDate(resultDate.getDate() + 1);
  
      // Check if the current day is not a weekend (Saturday or Sunday)
      if (resultDate.getDay() !== 0 && resultDate.getDay() !== 6) {
          days--;
      }
  }

  // Convert the resultDate to a string in the "yyyy-mm-dd" format
  const formattedDate = resultDate.toISOString().split('T')[0];
  return formattedDate;
};

export function validatePassword(password) {
  /*
  validate password must have at least 8 characters maximum 30 characters, including lowercase, uppercase, special character, number
  */
  // Check if the password length is between 8 and 30 characters
  if (password.length < 8 || password.length > 30) {
    return false;
  }

  // Check if the password contains at least one lowercase letter
  if (!/[a-z]/.test(password)) {
    return false;
  }

  // Check if the password contains at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    return false;
  }

  // Check if the password contains at least one special character
  if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
    return false;
  }

  // Check if the password contains at least one number
  if (!/\d/.test(password)) {
    return false;
  }

  // If all checks pass, the password is valid
  return true;
}

export function isTimeSoonerThanNow(inputTime) { // input: hh:mm; output true or false
  // Get the current time
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  // Parse the input time string to get hours and minutes
  const [inputHour, inputMinute] = inputTime.split(":").map(Number);

  // Compare the input time with the current time
  if (inputHour < currentHour || (inputHour === currentHour && inputMinute < currentMinute)) {
    return true; // Input time is sooner than current time
  } else {
    return false; // Input time is later than or equal to current time
  }
}

export function isDateToday(inputDate) {
  // Get the current date
  const today = new Date();

  // Format the current date in yyyy-mm-dd
  const currentDateString = today.toISOString().split("T")[0];

  // Compare the input date with the current date
  return inputDate === currentDateString;
}

export function isValidDateFormatYYYY_MM_dd(dateString) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) {
    return false; // If the format is not valid, return false
  }

  // Extract year, month, and day from the string
  const [year, month, day] = dateString.split('-').map(Number);

  // Validate month (1 to 12)
  if (month < 1 || month > 12) {
    return false;
  }

  // Validate day based on the month
  const daysInMonth = new Date(year, month, 0).getDate(); // Get the last day of the month
  return day >= 1 && day <= daysInMonth;
}

export function validateCCCD(inputString) {
  const arrMa = ["001","001","002","004","006","008","010","011","012","014","015","017","019","019","020","022","024","025","026","027","030","031","033","034","035","036","037","038","040","042","044","045","046","048","049","051","052","054","056","058","060","062","064","064","066","067","068","070","072","074","075","077","079","080","082","083","084","086","087","089","091","092","093","095","094","096"]
  // Check if the input string contains exactly 12 digits
  if (!/^\d{12}$/.test(inputString)) {
    return false;
  }

  // Check if digit index 0 to 2 exists in arrMa
  const firstThreeDigits = inputString.slice(0, 3);
  if (!arrMa.includes(firstThreeDigits)) {
    return false;
  }

  // Check if digit index 3 is between 0 and 9
  const fourthDigit = inputString.charAt(3);
  if (!/^[0-9]$/.test(fourthDigit)) {
    return false;
  }

  // Check if digit index 4 to 5 are between 00 and 99
  const fifthAndSixthDigits = inputString.slice(4, 6);
  if (!/^[0-9]{2}$/.test(fifthAndSixthDigits)) {
    return false;
  }

  // If all conditions are met, return true
  return true;
}
export function validateCMND(inputString) {

  if (!/^\d{9}$/.test(inputString)) {
    return false;
  }
  return true;
}

export function isValidCreditCard(inputText) {
  // Remove spaces and non-numeric characters
  const cleanedInput = inputText.replace(/\D/g, '');

  // Check if the input is a 16-digit number
  if (!/^\d{16}$/.test(cleanedInput)) {
    return false;
  }

  // if (/^\d{6}x{8}\d{6}$/.test(cleanedInput)) {
  //   return false;
  // }

  // Apply the Luhn algorithm
  let sum = 0;
  let double = false;

  for (let i = cleanedInput.length - 1; i >= 0; i--) {
    let digit = parseInt(cleanedInput[i], 10);

    if (double) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    double = !double;
  }

  return sum % 10 === 0;
}