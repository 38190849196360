import React from 'react'
import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import styles from './Bgd.module.scss'
import BgdSubMenu from './BgdSubMenu';
function BgdHome() {
   return (
    <div className={clsx(styles.component_BgdHome)}>
    <BgdSubMenu/>   
    <Outlet/>

 </div>
   )
}

export default BgdHome