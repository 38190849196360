import { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './ThuChi.module.scss'
import {
    parseBool,
    assignColorBold,
    assignColorIndexes,
    storeObjectInLocalStorage,
    retrieveObjectFromLocalStorage,
    getFirstAndLastDayOfYearParameter,
    splitMonthYear,
    getFirstAndLastDayOfMonthOfYear,
    addOneDay,
    getFirstAndLastDayOfYear,
    getFirstAndLastDayOfMonth,
    getStartAndEndOfWeek,
    getCurrentDateYYYYMMDD,
    getCurrentDateTimeVietNam,
    formatPhoneNumber,
    formatDateString,
    formatDateToYYYYMMDD,
    replaceFirstFourLetters,
} from '../../share/sharedFunction';
import collectionAPI from '../../../API/collectionAPI';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotYetSignIn from '../general/NotYetSignIn';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Tooltip from '@mui/material/Tooltip';
import { arrEmail3 } from '../../share/arrRole';

function ThuChi() {

    const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
        email: "",
        fullName: "",
        role: "",
        token:""
    })
    const checkSignin = () => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        // console.log('userExist: ', userExist);
        if(userExist === null){
            return
        }
        setStateObjSignIn(userExist);
    
    }
    const recallStartDateEndDateFromLocalStorage = () => {
    
        const obj = retrieveObjectFromLocalStorage("thuChiStartDateEndDate");
        const nhucau = retrieveObjectFromLocalStorage("thuchi_nhucau");
        if(obj === null || obj === "" || obj === undefined){
            return;
        }
        if(nhucau === null || nhucau === "" || nhucau === undefined){
            return;
        }
        setStateString_nhucau(nhucau);
        setStateString_startTime(obj.startDate);
        setStateString_singleDay(obj.startDate);
        setStateString_endTime(obj.endDate);
        getDataByRange(obj.startDate, obj.endDate);

    }

    const loadDataThisWeekAtBeginning = () => {
    
        // this week
        const obj = getStartAndEndOfWeek()
        let start = obj.startOfWeek;
        let end = obj.endOfWeek;
        setStateString_timeFrame("Tuần này");
        setStateString_startTime(start);
        setStateString_endTime(end);
        getDataByRange(start, end);
    
    }
    useEffect(() => {
        loadDataThisWeekAtBeginning();
        checkSignin();
    },[]);
    // START THU CHI---------------------------------------------------------------------------------------------------------
    const [stateString_singleDay, setStateString_singleDay] = useState<string>("");
    const [stateArr_rut, setStateArr_rut] = useState<any[]>([]);
    const [stateArr_dao, setStateArr_dao] = useState<any[]>([]);

    const [stateString_startTime, setStateString_startTime] = useState<string>("");
    const [stateString_endTime, setStateString_endTime] = useState<string>("");

    const handleChange_startTime = (event:any) => { 
        // console.log('event.target.value: ', event.target.value);
        setStateString_startTime(event.target.value) 
    }; 
    const handleChange_endTime = async (event:any) => { 
        // console.log('stateString_startTime: ', stateString_startTime);
        if(stateString_startTime === "" || stateString_startTime === null || stateString_startTime === undefined){
            alert("Vui lòng chọn ngày bắt đầu trước khi chọn ngày kết thúc ! Xin cảm ơn");
            return;
        }
        const endDate = event.target.value;
        if(endDate === "" || endDate === null || endDate === undefined){
            alert("Vui lòng chọn ngày ngày kết thúc ! Xin cảm ơn");
            return;
        }
        setStateString_endTime(event.target.value);
        const obj = {
            startDate: stateString_startTime,
            endDate: endDate
        }
        localStorage.removeItem("thuChiStartDateEndDate"); // bỏ cái cũ đi
        storeObjectInLocalStorage("thuChiStartDateEndDate", obj, 72);

        getDataByRange(stateString_startTime, endDate)
        setStateString_singleDay("");
        setStateString_timeFrame("Chọn...");
    }; 
    const [stateString_nhucau, setStateString_nhucau] = useState("Rút tiền"); // string
    const handleChange_nhucau = (e:any) => {
        const nhucau = e.target.value;
        setStateString_nhucau(nhucau);
        localStorage.removeItem("thuchi_nhucau"); // bỏ cái cũ đi
        storeObjectInLocalStorage("thuchi_nhucau", nhucau, 72);

    }
    const handleChange_singleDay = async (event:any) => { 
        let start = event.target.value;
        let end = addOneDay(start);
        setStateString_startTime(start);
        setStateString_endTime(end);
        setStateString_singleDay(start);
        const obj = {
            startDate: start,
            endDate: end
        }
        localStorage.removeItem("thuChiStartDateEndDate"); // bỏ cái cũ đi
        storeObjectInLocalStorage("thuChiStartDateEndDate", obj, 72);
        // getDataBySingleDay(date);
        getDataByRange(start, end);
    };

    const getDataBySingleDay = async (date: string) => {
        // console.log('date: ', date);
        let token = stateObjSignIn.token;
        try {
            const responseRut: any = await collectionAPI.collectionAPI.getRutRecordsForDate(token, date);
            // console.log('response.data rut: ', responseRut.data);
            const sortedArray_rut = responseRut.data.sort((a: any, b: any) => a.id - b.id);
            setStateArr_rut(sortedArray_rut);
            
            const responseDao: any = await collectionAPI.collectionAPI.getDaoRecordsForDate(token, date);
            // console.log('response.data dao: ', responseDao.data);
            const sortedArray_dao = responseDao.data.sort((a: any, b: any) => a.id - b.id);
            setStateArr_dao(sortedArray_dao);
        }catch(err){
            // console.log('err:', err);
        }
    }
    const [stateArr_binaryDao, setStateArr_binaryDao] = useState<any[]>([]);
    const [stateArr_binaryDaoBold, setStateArr_binaryDaoBold] = useState<any[]>([]);
    const [stateArr_binaryRut, setStateArr_binaryRut] = useState<any[]>([]);
    const [stateArr_binaryRutBold, setStateArr_binaryRutBold] = useState<any[]>([]);
    const getDataByRange = async (startDay: string, endDay: string) => {
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        let token = userExist.token;
        try {
            const responseRut: any = await collectionAPI.collectionAPI.getRutRecordsForDateRange(token, startDay, endDay);
            // console.log('response.data rut: ', responseRut.data);
            const sortedArray_rut = responseRut.data.sort((a: any, b: any) => a.id - b.id);
            // console.log('sortedArray_rut: ', sortedArray_rut);
            setStateArr_rut(sortedArray_rut);
            
            let arrGiaoDichId_rut = [];
            let arrBoolRut = [];
            for (let obj of sortedArray_rut) {
                arrGiaoDichId_rut.push(obj.giaodichId)
                let id = obj.giaodichId;
                let bool = obj.giaodich.isApproved;
                let objBool: { [key: string]: boolean } = {};
                objBool[id] = bool;
                arrBoolRut.push(objBool);
            }

            // Merge objects in arrBoolRut into a single object
            const mergedCheckedItems_rut = arrBoolRut.reduce((acc, obj) => {
                return { ...acc, ...obj };
            }, {});

            setCheckedItems_rut(mergedCheckedItems_rut);

            // console.log('arrGiaoDichId_rut: ', arrGiaoDichId_rut);
            let arrBinaryRut: string[] = assignColorIndexes(arrGiaoDichId_rut);
            let arrBinaryRutBold: string[] = assignColorBold(arrGiaoDichId_rut);
            setStateArr_binaryRut(arrBinaryRut);  // dùng để set màu nền cho các dòng
            setStateArr_binaryRutBold(arrBinaryRutBold); // dùng để set màu nền cho các dòng
            // console.log('arrBinaryRut: ', arrBinaryRut);
            // console.log('arrBinaryRutBold: ', arrBinaryRutBold);
            setStateArr_rut(sortedArray_rut);


            const responseDao: any = await collectionAPI.collectionAPI.getDaoRecordsForDateRange(token, startDay, endDay);
            const sortedArray_dao = responseDao.data.sort((a: any, b: any) => a.id - b.id);
            let arrGiaoDichId_dao = [];
            let arrBoolDao = [];
            for (let obj of sortedArray_dao) {
                arrGiaoDichId_dao.push(obj.giaodichId)
                let id = obj.giaodichId;
                let bool = obj.giaodich.isApproved;
                let objBool: { [key: string]: boolean } = {};
                objBool[id] = bool;
                arrBoolDao.push(objBool);
            }

            // Merge objects in arrBoolDao into a single object
            const mergedCheckedItems_dao = arrBoolDao.reduce((acc, obj) => {
                return { ...acc, ...obj };
            }, {});

            setCheckedItems_dao(mergedCheckedItems_dao);

            // console.log('arrGiaoDichId_dao: ', arrGiaoDichId_dao);
            let arrBinaryDao: string[] = assignColorIndexes(arrGiaoDichId_dao);
            let arrBinaryDaoBold: string[] = assignColorBold(arrGiaoDichId_dao);
            setStateArr_binaryDao(arrBinaryDao);  // dùng để set màu nền cho các dòng
            setStateArr_binaryDaoBold(arrBinaryDaoBold); // dùng để set màu nền cho các dòng
            // console.log('arrBinaryDao: ', arrBinaryDao);
            // console.log('arrBinaryDaoBold: ', arrBinaryDaoBold);
            setStateArr_dao(sortedArray_dao);
            // console.log('sortedArray_dao: ', sortedArray_dao);
        }catch(err){
            // console.log('err:', err);
        }
    }
    const [checkedItems_dao, setCheckedItems_dao] = useState<{ [key: string]: boolean }>({}); // chứa các id true
    const [checkedItems_rut, setCheckedItems_rut] = useState<{ [key: string]: boolean }>({}); // chứa các id true

    // chỉ có kế toán đc check thôi
    const handleCheckboxChange_dao = async (event:any) => {

        let token = stateObjSignIn.token;
        let email = stateObjSignIn.email;
        if(!arrEmail3.includes(email)){
            alert("Chỉ có kế toán mới có quyền xác nhận hoàn thành, xin cảm ơn !");
            return;
        }
        const { name, checked } = event.target; // name chính là giaodichId
        const obj = stateArr_dao.find((item) => item.giaodichId === parseInt(name+"")); 
        // console.log('obj: ', obj);
        // console.log('obj.mediaId: ', obj.mediaId);
        // const hinhLoBill_mediaId = obj.mediaId;
        const hinhChuyenKhoanTienPhi_mediaId = obj.giaodich.hinhChuyenKhoanTienPhi_mediaId
        // if(hinhLoBill_mediaId === null){
        //     alert("Chưa có bằng chứng hình Lô Bill, nên bạn chưa thể xác nhận");
        //     return;
        // }
        const checkChuyenPhi = obj.giaodich.checkChuyenPhi;
        if(checkChuyenPhi === false){

            if(hinhChuyenKhoanTienPhi_mediaId === null){
                alert("Chưa có bằng chứng hình chuyển khoản phí và hệ thống check sao kê tự động cũng chưa đạt ... nên bạn chưa thể xác nhận");
                return;
            }
        }

        setCheckedItems_dao({ ...checkedItems_dao, [name]: checked });
        // console.log('name: ', name); // name chính là giaodichId
        // console.log('checked: ', checked); // checked là true nếu check vào, là false nếu uncheck
        const now = getCurrentDateTimeVietNam();

        
        try {
            if (checked === true) {
                if(obj.memberId === undefined || obj.memberId === null || obj.memberId === ""){ // nếu giao dịch này ko thuộc hệ thống đại lý mới thì chỉ xác nhận true false chứ ko cộng điểm
                    await Promise.all([
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "isApproved", "true"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "emailNVFinalApprove", email),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "finalApprovedAt", now)
                    ]);
                    setStateArr_dao(prevState => {
                        return prevState.map(item => {
                            if (item.giaodichId === name) {
                                return { ...item, giaodich: { ...item.giaodich, isApproved: true } };
                            }
                            return item;
                        });
                    });
                    
                }
                else { // nếu giao dịch này có memberId tức là nó thuộc hệ thống đại lý mới
                    
                    await Promise.all([
                        collectionAPI.collectionAPI.updateGiaodichWithApproval(token, name, "true"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "emailNVFinalApprove", email),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "finalApprovedAt", now)
                    ]);
                    setStateArr_dao(prevState => {
                        return prevState.map(item => {
                            if (item.giaodichId === name) {
                                return { ...item, giaodich: { ...item.giaodich, isApproved: true } };
                            }
                            return item;
                        });
                    });
                }

            } else {
                if(obj.memberId === undefined || obj.memberId === null || obj.memberId === ""){ // nếu giao dịch này ko thuộc hệ thống đại lý mới thì chỉ xác nhận true false chứ ko cộng điểm
                    await Promise.all([
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "isApproved", "false"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "emailNVFinalApprove", "null"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "finalApprovedAt", "null")

                    ]);
                    setStateArr_dao(prevState => {
                        return prevState.map(item => {
                            if (item.giaodichId === name) {
                                return { ...item, giaodich: { ...item.giaodich, isApproved: true } };
                            }
                            return item;
                        });
                    });
                    
                }
                else { // nếu giao dịch này có memberId tức là nó thuộc hệ thống đại lý mới
                    
                    await Promise.all([
                        collectionAPI.collectionAPI.updateGiaodichWithApproval(token, name, "false"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "emailNVFinalApprove", "null"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "finalApprovedAt", "null")
                    ]);
                    setStateArr_dao(prevState => {
                        return prevState.map(item => {
                            if (item.giaodichId === name) {
                                return { ...item, giaodich: { ...item.giaodich, isApproved: false } };
                            }
                            return item;
                        });
                    });
                }
                

                // await Promise.all([
                //     collectionAPI.collectionAPI.updateGiaodichWithApproval(token, name, "false"),
                //     collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "emailNVFinalApprove", "null"),
                //     collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "finalApprovedAt", "null")
                // ]);
        
                // setStateArr_dao(prevState => {
                //     return prevState.map(item => {
                //         if (item.giaodichId === name) {
                //             return { ...item, giaodich: { ...item.giaodich, isApproved: false } };
                //         }
                //         return item;
                //     });
                // });
            }
        } catch (err) {
            // console.log('err:', err);
        }

    };
    // chỉ có kế toán đc check thôi
    const handleCheckboxChange_rut = async (event:any) => {

        let token = stateObjSignIn.token;
        let email = stateObjSignIn.email;
        if(!arrEmail3.includes(email)){
            alert("Chỉ có kế toán mới có quyền xác nhận hoàn thành, xin cảm ơn !");
            return;
        }
        const { name, checked } = event.target; // name chính là giaodichId
        const obj = stateArr_rut.find((item) => item.giaodichId === parseInt(name+"")); 
        // console.log('obj: ', obj);
        // console.log('obj.mediaId: ', obj.mediaId);
        // const hinhLoBill_mediaId = obj.mediaId;
        const hinhChuyenKhoanTienPhi_mediaId = obj.giaodich.hinhChuyenKhoanTienPhi_mediaId
        // if(hinhLoBill_mediaId === null){
        //     alert("Chưa có bằng chứng hình Lô Bill, nên bạn chưa thể xác nhận");
        //     return;
        // }
        const checkChuyenPhi = obj.giaodich.checkChuyenPhi;
        if(checkChuyenPhi === false){

            if(hinhChuyenKhoanTienPhi_mediaId === null){
                alert("Chưa có bằng chứng hình chuyển khoản phí và hệ thống check sao kê tự động cũng chưa đạt ... nên bạn chưa thể xác nhận");
                return;
            }
        }

        setCheckedItems_rut({ ...checkedItems_rut, [name]: checked });
        // console.log('name: ', name); // name chính là giaodichId
        // console.log('checked: ', checked); // checked là true nếu check vào, là false nếu uncheck
        const now = getCurrentDateTimeVietNam();

        try {

            if (checked === true) {
                if(obj.memberId === undefined || obj.memberId === null || obj.memberId === ""){ // nếu giao dịch này ko thuộc hệ thống đại lý mới thì chỉ xác nhận true false chứ ko cộng điểm
                    await Promise.all([
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "isApproved", "true"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "emailNVFinalApprove", email),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "finalApprovedAt", now)
                    ]);
                    setStateArr_rut(prevState => {
                        return prevState.map(item => {
                            if (item.giaodichId === name) {
                                return { ...item, giaodich: { ...item.giaodich, isApproved: true } };
                            }
                            return item;
                        });
                    });
                    
                }
                else { // nếu giao dịch này có memberId tức là nó thuộc hệ thống đại lý mới
                    
                    await Promise.all([
                        collectionAPI.collectionAPI.updateGiaodichWithApproval(token, name, "true"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "emailNVFinalApprove", email),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "finalApprovedAt", now)
                    ]);
                    setStateArr_rut(prevState => {
                        return prevState.map(item => {
                            if (item.giaodichId === name) {
                                return { ...item, giaodich: { ...item.giaodich, isApproved: true } };
                            }
                            return item;
                        });
                    });
                }

            } else {
                if(obj.memberId === undefined || obj.memberId === null || obj.memberId === ""){ // nếu giao dịch này ko thuộc hệ thống đại lý mới thì chỉ xác nhận true false chứ ko cộng điểm
                    await Promise.all([
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "isApproved", "false"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "emailNVFinalApprove", "null"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "finalApprovedAt", "null")

                    ]);
                    setStateArr_dao(prevState => {
                        return prevState.map(item => {
                            if (item.giaodichId === name) {
                                return { ...item, giaodich: { ...item.giaodich, isApproved: true } };
                            }
                            return item;
                        });
                    });
                    
                }
                else { // nếu giao dịch này có memberId tức là nó thuộc hệ thống đại lý mới
                    
                    await Promise.all([
                        collectionAPI.collectionAPI.updateGiaodichWithApproval(token, name, "false"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "emailNVFinalApprove", "null"),
                        collectionAPI.collectionAPI.updateGiaodichByIdOneField(token, name, "finalApprovedAt", "null")
                    ]);
                    setStateArr_dao(prevState => {
                        return prevState.map(item => {
                            if (item.giaodichId === name) {
                                return { ...item, giaodich: { ...item.giaodich, isApproved: false } };
                            }
                            return item;
                        });
                    });
                }
                
            }
        } catch (err) {
            // console.log('err:', err);
        }

    };
    // console.log('----------------------------------------------');
    // console.log('stateArr_dao 668: ', stateArr_dao);

    const [stateNum_rowSelected, setStateNum_rowSelected] = useState(0);
    const [stateBool_showDetails, setStateBool_showDetails] = useState(false);
    const handle_rowSelected = (id: number) => {
    
        setStateNum_rowSelected(id)
        setStateBool_showDetails(!stateBool_showDetails);
    }

    const [stateBool_isOpenDialog, setStateBool_isOpenDialog] = useState(false); // boolean
    const [stateString_selectedImg, setStateString_selectedImg] = useState<string>(''); // string

    const selectImg = (imgUrl: string) => {
    
        setStateBool_isOpenDialog(true);
        setStateString_selectedImg(imgUrl);
    
    }
    const cancelDialog = () => {
    
        setStateBool_isOpenDialog(false);
        setStateString_selectedImg('');
    
    }
    const [stateString_timeFrame, setStateString_timeFrame] = useState<string>("Chọn...");

    const handleChange_timeFrame = async (event:any) => {
        
        const timeFrame = event.target.value;
        if(timeFrame === "Chọn..."){
            alert("Bạn phải chọn 1 khung thời gian cụ thể trong danh sách !");
            return;
        }
        setStateString_timeFrame(timeFrame);

        const listMonthYear = [
            "12-2023",
            "01-2024",
            "02-2024",
            "03-2024",
            "04-2024"
        ]

        let start = ""
        let end = ""
        if(timeFrame === "Hôm nay"){
            start = getCurrentDateYYYYMMDD();
            end = addOneDay(start);
        }
        else if (timeFrame === "Tuần này"){
            let obj = getStartAndEndOfWeek();
            start = obj.startOfWeek;
            end = obj.endOfWeek;
        }
        else if (timeFrame === "Tháng này"){
            let obj = getFirstAndLastDayOfMonth();
            start = obj.firstDayOfMonth;
            end = obj.lastDayOfMonth;
        }
        else if (timeFrame === "Năm nay"){
            let obj = getFirstAndLastDayOfYear();
            start = obj.firstDayOfYear;
            end = obj.lastDayOfYear;
        }
        else if (listMonthYear.includes(timeFrame)){
            const {month, year} = splitMonthYear(timeFrame)
            const {firstDay, lastDay} = getFirstAndLastDayOfMonthOfYear(month, year);

            start = firstDay;
            end = lastDay;
        }
        else if(timeFrame === "2024" || timeFrame === "2025"){
            const {firstDay, lastDay} = getFirstAndLastDayOfYearParameter(timeFrame);
            start = firstDay;
            end = lastDay;
        }

        setStateString_startTime(start);
        setStateString_endTime(end);
        setStateString_singleDay("");
        const obj = {
            startDate: start,
            endDate: end
        }
        localStorage.removeItem("thuChiStartDateEndDate"); // bỏ cái cũ đi
        storeObjectInLocalStorage("thuChiStartDateEndDate", obj, 72);
        getDataByRange(start, end);
    
    };

    const [stateString_tienPhi, setStateString_tienPhi] = useState("All"); // string
    const handleChange_tienPhi = (e:any) => {
        const checkTienPhi = e.target.value;
        setStateString_tienPhi(checkTienPhi);
        // console.log('checkTienPhi: ', checkTienPhi);
        
    }
    // END THU CHI---------------------------------------------------------------------------------------------------------


    return (
        stateObjSignIn.token === "" ? <NotYetSignIn /> :
        <div className={clsx(styles.component_ThuChi)}>
            <h4>THU CHI RÚT ĐÁO</h4>
            <div className={clsx(styles.navigation_thuchi)}>
                <div className={clsx(styles.chooseDate)}>
                    <p className={clsx(styles.key)}>Ngày </p>
                    <input type="date" 
                        className={clsx(styles.inputDate)} 
                        value={stateString_singleDay} 
                        onChange={handleChange_singleDay}/>
                </div>
                <div className={clsx(styles.dateRange)}>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Bắt đầu</p>
                        <input type="date" 
                            className={clsx(styles.inputDate)} 
                            value={stateString_startTime} 
                            onChange={handleChange_startTime}/>
                    </div>
                    <div className={clsx(styles.row)}>
                        <p className={clsx(styles.key)}>Kết thúc</p>
                        <input type="date" 
                            className={clsx(styles.inputDate)} 
                            value={stateString_endTime} 
                            onChange={handleChange_endTime}/>
                    </div>
                </div>
                <div className={clsx(styles.timeWrapper)}>
                    <p className={clsx(styles.key)}>Khung thời gian</p>

                    <select name="from" onChange={handleChange_timeFrame} className={clsx(styles.selectOption)} value={stateString_timeFrame}>
                        <option value="Chọn...">Chọn...</option>
                        <option value="Hôm nay">Hôm nay</option>
                        <option value="Tuần này">Tuần này</option>
                        <option value="Tháng này">Tháng này</option>
                        <option value="Năm nay">Năm nay</option>
                        <option value="01-2024">01-2024</option>
                        <option value="02-2024">02-2024</option>
                        <option value="03-2024">03-2024</option>
                        <option value="04-2024">04-2024</option>
                        <option value="05-2024">05-2024</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                    </select>
                </div>
                <div className={clsx(styles.nhucau)}>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="nhucau" checked={stateString_nhucau === "Đáo hạn"} value="Đáo hạn" className={clsx(styles.item)} 
                        onChange={handleChange_nhucau}/> <p>Đáo hạn</p>
                    </div>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="nhucau" value="Rút tiền" className={clsx(styles.item)} 
                        onChange={handleChange_nhucau}/> <p>Rút tiền</p>
                    </div>
                </div>
                <div className={clsx(styles.tienPhi)}>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="tienPhi" checked={stateString_tienPhi === "All"} value="All" className={clsx(styles.item)} 
                        onChange={handleChange_tienPhi}/> <p>Tất cả</p>
                    </div>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="tienPhi" checked={stateString_tienPhi === "true"} value="true" className={clsx(styles.item)} 
                        onChange={handleChange_tienPhi}/> <p className={clsx(styles.succeed)}><VerifiedIcon/></p>
                    </div>
                    <div className={clsx(styles.box)}>
                        <input type="radio" name="tienPhi" checked={stateString_tienPhi === "false"} value="false" className={clsx(styles.item)} 
                        onChange={handleChange_tienPhi}/> <p className={clsx(styles.fail)}><HighlightOffIcon/></p>
                    </div>
                </div>
            </div>
            {
                stateString_nhucau === "Rút tiền" ?
                <div className={clsx(styles.tableRut)}>
                    <div className={clsx(styles.row, styles.heading)}>
                        <p>ID<br/>Giao dịch</p>
                        <p>Tên KH</p>
                        <p>Số thẻ <br/>tín dụng</p>
                        <p>Số Lô</p>
                        <p>Số Bill</p>
                        <p>Tiền phải <br/> rút máy POS</p>

                        <p>Tiền rút máy POS <br/>còn lại phải rút</p>
                        <p>Tiền nạp thực tế<br/> vào thẻ KH</p>
                        <p>Hình Lô Bill</p>
                        <p>Hình chuyển phí</p>
                        <p>Check<br/>chuyển phí</p>
                        <p>Kế toán <br/> xác nhận </p>
                    </div>
                    {
                        stateArr_rut.map((obj, index) => {
                            if(obj.giaodich.checkChuyenPhi === parseBool(stateString_tienPhi)){
                                return ( 
                                    
                                    <div className={clsx(styles.containerRow)} key={obj.id}
                                        style={{ 
                                            backgroundColor: stateArr_binaryRut[index]
                                        }}
                                    >
                                        <div className={clsx(styles.row)} 
                                            style={{ 
                                                backgroundColor: stateArr_binaryRutBold[index] 
                                            }}
                                        >
                                            <p className={clsx(styles.btnWrapperIcon)}>
                                                <span onClick={()=>handle_rowSelected(obj.id)} className={clsx(styles.btn)}>
                                                    {
                                                        (stateBool_showDetails === true && stateNum_rowSelected === obj.id) ?
                                                        <KeyboardArrowDownIcon/>
                                                        :
                                                        <KeyboardArrowRightIcon/>
                                                    }
                                                </span> 
                                                <span>
                                                    {obj.giaodichId}
                                                </span>
                                            </p>
                                            <p>{obj.giaodich?.khachhang?.fullNameKH}</p>
                                            <p>{obj.giaodich?.the[0]?.soThe}</p>
                                            <p>{obj.soLo}</p>
                                            <p>{obj.soBill}</p>
                                            {
                                                obj.giaodich?.tienrutdao !== null ?
                                                <p>{obj.giaodich?.tienrutdao.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienConLai !== null ?
                                                <p>{obj.tienConLai.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienRut !== null ?
                                                <p>{obj.tienRut.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            <p>
                                                {
                                                    obj.mediaId !== null && obj.signedUrl !== null &&
                                                    <img src={obj.signedUrl} onClick={()=>selectImg(obj.signedUrl)} className="hinh" alt="hinh" />
                                                }
                                            </p>
                                            <p>
                                                {
                                                    obj.giaodich?.hinhChuyenKhoanTienPhi_mediaId !== null ?
                                                    <img src={obj.giaodich?.hinhChuyenKhoanTienPhi_signedUrl} 
                                                        onClick={()=>selectImg(obj.giaodich?.hinhChuyenKhoanTienPhi_signedUrl)} 
                                                        className="avatar" 
                                                        alt="avatar" />
                                                    :
                                                    <span></span>
                                                }
                                            </p>
                                            {
                                                obj.giaodich?.checkChuyenPhi === true ?
                                                    <Tooltip title={obj.giaodich?.resultCheckChuyenPhi} placement="top">
                                                        <p className={clsx(styles.succeed)}> <VerifiedIcon/> </p>
                                                    </Tooltip>
                                                :
                                                    <Tooltip title="Nội dung chuyển tiền không khớp, Phòng Kế toán vui lòng kiểm tra lại" placement="top">
                                                            <p className={clsx(styles.value, styles.fail)}> <HighlightOffIcon/> </p>
                                                    </Tooltip>
                                            }
                                            <p >
                                                <input
                                                    type="checkbox"
                                                    name={obj.giaodichId} // Assuming each item has a unique identifier
                                                    checked={checkedItems_rut[obj.giaodichId.toString()] || false}
                                                    onChange={handleCheckboxChange_rut}
                                                />
                                            </p>
                                        </div>
                                        {
                                            (() => {
                                                if (obj.id === stateNum_rowSelected && stateBool_showDetails === true) {
                                                    
                                                    return (
                                                        <div className={clsx(styles.additionalInfos)}>
                                                            <div className={clsx(styles.khachhang)}>
                                                                <p className={clsx(styles.title)}>Thông tin Khách Hàng</p>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>ID KH</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.id}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tên KH</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.fullNameKH}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>CCCD</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.cccd}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Bithday</p>
                                                                    <p className={clsx(styles.value)}>{formatDateToYYYYMMDD(obj.giaodich?.khachhang?.ntns)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nguồn KH</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.nguonKH}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Thành phố</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.city}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Quận</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.district}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Phường</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.nguonKH}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Địa chỉ</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.address}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tạo lúc</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.khachhang?.createdAt)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Sửa lúc</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.khachhang?.editedAt)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nhân viên tạo</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.fullNameNVCSKHcreate}</p>
                                                                </div>
                                                                {
                                                                    obj.giaodich?.khachhang?.phone.length > 0 &&
                                                                    obj.giaodich?.khachhang?.phone.map((item:any, index:any) => (
                                                                        <div className={clsx(styles.pair)} key={item.id}>
                                                                            <p className={clsx(styles.key)}>Số ĐT {index + 1}</p>
                                                                            <p className={clsx(styles.value)}>{replaceFirstFourLetters(formatPhoneNumber(item.phone))}</p>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className={clsx(styles.giaodich)}>
                                                                <p className={clsx(styles.title)}>Thông tin về giao dịch này</p>
    
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>ID Giao dịch</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.id}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nhu cầu</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.nhucau}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền rút đáo</p>
                                                                    {
                                                                        obj.giaodich.tienrutdao !== null &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.tienrutdao.toLocaleString('en-US')}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Phí rút đáo</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.phirutdao}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền phí</p>
                                                                    {
                                                                        obj.giaodich.tienphi !== null &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.tienphi.toLocaleString('en-US')}</p>
                                                                    }
                                                                    
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền chuyển khách</p>
                                                                    {
                                                                        obj.giaodich.tienChuyenKhach !== null &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.tienChuyenKhach.toLocaleString('en-US')}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền ship</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.tienship}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nhân viên tạo</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.fullNameNVRutdaoCreate}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Ngân hàng</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.nganhangId}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Ngày hạn thanh toán</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.ngayhanthanhtoan)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nhân viên ship</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.nhanvienship}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nội dung chuyển tiền</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.noiDungChuyenTien}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Trạng thái</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.teamrutdaothongbaotrangthai}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tên chi nhánh</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.tenchinhanh}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Check chuyển phí</p>
                                                                    {
                                                                        obj.giaodich?.checkChuyenPhi === true ?
                                                                            <Tooltip title={obj.giaodich?.resultCheckChuyenPhi} placement="top">
                                                                                <p className={clsx(styles.value, styles.succeed)}> <VerifiedIcon/> </p>
                                                                            </Tooltip>
                                                                        :
                                                                            <Tooltip title="Nội dung chuyển tiền không khớp, Phòng Kế toán phải kiểm tra lại" placement="top">
                                                                                    <p className={clsx(styles.value, styles.fail)}> <HighlightOffIcon/> </p>
                                                                            </Tooltip>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={clsx(styles.maypos)}>
                                                            <p className={clsx(styles.title)}>Thông tin về máy pos</p>
    
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Chi nhánh</p>
                                                                    <p className={clsx(styles.value)}>{obj.maypos?.chinhanh}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tên máy POS</p>
                                                                    <p className={clsx(styles.value)}>{obj.maypos?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className={clsx(styles.the)}>
                                                                <p className={clsx(styles.title)}>Thông tin về thẻ</p>
    
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Hạn mức thẻ</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 && obj.giaodich?.the[0]?.hanMucTheTinDung !== null &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.hanMucTheTinDung.toLocaleString('en-US')}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Loại thẻ</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 &&
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.loaiThe}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>STK ngân hàng</p>
                                                                    {
                                                                        obj.giaodich?.soTaiKhoanNganHang.length !== 0 &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.soTaiKhoanNganHang[0]?.soTaiKhoanNganHang}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>STK thẻ T.Dụng</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.soTaiKhoanTheTinDung}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số thẻ</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.soThe}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tên trên thẻ</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.tenTrenThe}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={clsx(styles.dao)}>
                                                                <p className={clsx(styles.title)}>Thông tin về Rút</p>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Chuyển đến</p>
                                                                    <p className={clsx(styles.value)}>{obj.chuyenDen}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tạo lúc</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.createdAt)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số Bill</p>
                                                                    <p className={clsx(styles.value)}>{obj.soBill}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số Lô</p>
                                                                    <p className={clsx(styles.value)}>{obj.soLo}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số tài khoản</p>
                                                                    <p className={clsx(styles.value)}>{obj.soTaiKhoan}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền Rút</p>
                                                                    {
                                                                        obj.tienRut !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienRut.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền rút còn lại</p>
                                                                    {
                                                                        obj.tienConLai !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienConLai.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                } 
                                            })()
                                        }
                                    </div>
                                )
                            }
                            else if(stateString_tienPhi === "All"){
                                return ( 
                                    
                                    <div className={clsx(styles.containerRow)} key={obj.id}
                                        style={{ 
                                            backgroundColor: stateArr_binaryRut[index]
                                        }}
                                    >
                                        <div className={clsx(styles.row)} 
                                            style={{ 
                                                backgroundColor: stateArr_binaryRutBold[index] 
                                            }}
                                        >
                                            <p className={clsx(styles.btnWrapperIcon)}>
                                                <span onClick={()=>handle_rowSelected(obj.id)} className={clsx(styles.btn)}>
                                                    {
                                                        (stateBool_showDetails === true && stateNum_rowSelected === obj.id) ?
                                                        <KeyboardArrowDownIcon/>
                                                        :
                                                        <KeyboardArrowRightIcon/>
                                                    }
                                                </span> 
                                                <span>
                                                    {obj.giaodichId}
                                                </span>
                                            </p>
                                            <p>{obj.giaodich?.khachhang?.fullNameKH}</p>
                                            <p>{obj.giaodich?.the[0]?.soThe}</p>
                                            <p>{obj.soLo}</p>
                                            <p>{obj.soBill}</p>
                                            {
                                                obj.giaodich?.tienrutdao !== null ?
                                                <p>{obj.giaodich?.tienrutdao.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienConLai !== null ?
                                                <p>{obj.tienConLai.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienRut !== null ?
                                                <p>{obj.tienRut.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            <p>
                                                {
                                                    obj.mediaId_rut !== null && obj.signedUrl_rut !== null &&
                                                    <img src={obj.signedUrl_rut} onClick={()=>selectImg(obj.signedUrl_rut)} className="hinh" alt="hinh" />
                                                }
                                            </p>
                                            <p>
                                                {
                                                    obj.giaodich?.hinhChuyenKhoanTienPhi_mediaId !== null ?
                                                    <img src={obj.giaodich?.hinhChuyenKhoanTienPhi_signedUrl} 
                                                        onClick={()=>selectImg(obj.giaodich?.hinhChuyenKhoanTienPhi_signedUrl)} 
                                                        className="avatar" 
                                                        alt="avatar" />
                                                    :
                                                    <span></span>
                                                }
                                            </p>
                                            {
                                                obj.giaodich?.checkChuyenPhi === true ?
                                                    <Tooltip title={obj.giaodich?.resultCheckChuyenPhi} placement="top">
                                                        <p className={clsx(styles.succeed)}> <VerifiedIcon/> </p>
                                                    </Tooltip>
                                                :
                                                    <Tooltip title="Nội dung chuyển tiền không khớp, Phòng Kế toán vui lòng kiểm tra lại" placement="top">
                                                            <p className={clsx(styles.value, styles.fail)}> <HighlightOffIcon/> </p>
                                                    </Tooltip>
                                            }
                                            <p >
                                                <input
                                                    type="checkbox"
                                                    name={obj.giaodichId} // Assuming each item has a unique identifier
                                                    checked={checkedItems_rut[obj.giaodichId.toString()] || false}
                                                    onChange={handleCheckboxChange_rut}
                                                />
                                            </p>
                                        </div>
                                        {
                                            (() => {
                                                if (obj.id === stateNum_rowSelected && stateBool_showDetails === true) {
                                                    return (
                                                        <div className={clsx(styles.additionalInfos)}>
                                                            <div className={clsx(styles.khachhang)}>
                                                                <p className={clsx(styles.title)}>Thông tin Khách Hàng</p>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>ID KH</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.id}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tên KH</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.fullNameKH}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>CCCD</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.cccd}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Bithday</p>
                                                                    <p className={clsx(styles.value)}>{formatDateToYYYYMMDD(obj.giaodich?.khachhang?.ntns)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nguồn KH</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.nguonKH}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Thành phố</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.city}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Quận</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.district}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Phường</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.nguonKH}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Địa chỉ</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.address}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tạo lúc</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.khachhang?.createdAt)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Sửa lúc</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.khachhang?.editedAt)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nhân viên tạo</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.fullNameNVCSKHcreate}</p>
                                                                </div>
                                                                {
                                                                    obj.giaodich?.khachhang?.phone.length > 0 &&
                                                                    obj.giaodich?.khachhang?.phone.map((item:any, index:any) => (
                                                                        <div className={clsx(styles.pair)} key={item.id}>
                                                                            <p className={clsx(styles.key)}>Số ĐT {index + 1}</p>
                                                                            <p className={clsx(styles.value)}>{replaceFirstFourLetters(formatPhoneNumber(item.phone))}</p>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div className={clsx(styles.giaodich)}>
                                                                <p className={clsx(styles.title)}>Thông tin về giao dịch này</p>
    
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>ID Giao dịch</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.id}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nhu cầu</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.nhucau}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền rút đáo</p>
                                                                    {
                                                                        obj.giaodich?.tienrutdao !== null &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.tienrutdao.toLocaleString('en-US')}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Phí rút đáo</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.phirutdao}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền phí</p>
                                                                    {
                                                                        obj.giaodich?.tienphi !== null &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.tienphi.toLocaleString('en-US')}</p>
                                                                    }
                                                                    
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền chuyển khách</p>
                                                                    {
                                                                        obj.giaodich?.tienChuyenKhach !== null &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.tienChuyenKhach.toLocaleString('en-US')}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền ship</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.tienship}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nhân viên tạo</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.fullNameNVRutdaoCreate}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Ngân hàng</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.nganhangId}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Ngày hạn thanh toán</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.ngayhanthanhtoan)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nhân viên ship</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.nhanvienship}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Nội dung chuyển tiền</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.noiDungChuyenTien}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Trạng thái</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.teamrutdaothongbaotrangthai}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tên chi nhánh</p>
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.tenchinhanh}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Check chuyển phí</p>
                                                                    {
                                                                        obj.giaodich?.checkChuyenPhi === true ?
                                                                            <Tooltip title={obj.giaodich?.resultCheckChuyenPhi} placement="top">
                                                                                <p className={clsx(styles.value, styles.succeed)}> <VerifiedIcon/> </p>
                                                                            </Tooltip>
                                                                        :
                                                                            <Tooltip title="Nội dung chuyển tiền không khớp, Phòng Kế toán phải kiểm tra lại" placement="top">
                                                                                    <p className={clsx(styles.value, styles.fail)}> <HighlightOffIcon/> </p>
                                                                            </Tooltip>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={clsx(styles.maypos)}>
                                                            <p className={clsx(styles.title)}>Thông tin về máy pos</p>
    
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Chi nhánh</p>
                                                                    <p className={clsx(styles.value)}>{obj.maypos?.chinhanh}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tên máy POS</p>
                                                                    <p className={clsx(styles.value)}>{obj.maypos?.name}</p>
                                                                </div>
                                                            </div>
                                                            <div className={clsx(styles.the)}>
                                                                <p className={clsx(styles.title)}>Thông tin về thẻ</p>
    
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Hạn mức thẻ</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 && obj.giaodich?.the[0]?.hanMucTheTinDung !== null &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.hanMucTheTinDung.toLocaleString('en-US')}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Loại thẻ</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 &&
                                                                    <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.loaiThe}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số tài khoản ngân hàng</p>
                                                                    {
                                                                        obj.giaodich?.soTaiKhoanNganHang.length !== 0 &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.soTaiKhoanNganHang[0]?.soTaiKhoanNganHang}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>STK thẻ T.Dụng</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.soTaiKhoanTheTinDung}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số thẻ</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.soThe}</p>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tên trên thẻ</p>
                                                                    {
                                                                        obj.giaodich?.the.length !== 0 &&
                                                                        <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.tenTrenThe}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={clsx(styles.dao)}>
                                                                <p className={clsx(styles.title)}>Thông tin về Rút</p>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Chuyển đến</p>
                                                                    <p className={clsx(styles.value)}>{obj.chuyenDen}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tạo lúc</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.createdAt)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số Bill</p>
                                                                    <p className={clsx(styles.value)}>{obj.soBill}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số Lô</p>
                                                                    <p className={clsx(styles.value)}>{obj.soLo}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số tài khoản</p>
                                                                    <p className={clsx(styles.value)}>{obj.soTaiKhoan}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền Rút</p>
                                                                    {
                                                                        obj.tienRut !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienRut.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền rút còn lại</p>
                                                                    {
                                                                        obj.tienConLai !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienConLai.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                } 
                                            })()
                                        }
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                :
                <div className={clsx(styles.tableDao)}>
                    <div className={clsx(styles.row, styles.heading)}>
                        <p>ID<br/>Giao dịch</p>
                        <p>Tên KH</p>
                        <p>Số thẻ <br/>tín dụng</p>
                        <p>Số Lô</p>
                        <p>Số Bill</p>
                        <p>Trạng thái</p>
                        <p>Tiền<br/>phải đáo</p>
                        <p>Tiền nạp</p>
                        <p>Tiền nạp <br/> còn lại</p>
                        <p>Tiền rút</p>
                        <p>Tiền rút <br/> còn lại</p>
                        <p>Hình<br/>Lô Bill</p>
                        <p>Hình<br/>chuyển phí</p>
                        <p>Check<br/>chuyển phí</p>
                        <p>Kế toán <br/> xác nhận </p>
                    </div>
                    {
                        stateArr_dao.map((obj, index) => {
                            if(obj.giaodich?.checkChuyenPhi === parseBool(stateString_tienPhi)){
                                return ( 
                                    <div className={clsx(styles.containerRow)} key={obj.id} 
                                        style={{ 
                                            backgroundColor: stateArr_binaryDao[index]
                                            }}
                                    >
                                        <div className={clsx(styles.row)} 
                                            style={{ 
                                                backgroundColor: stateArr_binaryDaoBold[index] 
                                            }}
                                        >
                                            <p className={clsx(styles.btnWrapperIcon)}>
                                                <span onClick={()=>handle_rowSelected(obj.id)} className={clsx(styles.btn)}>
                                                    {
                                                        (stateBool_showDetails === true && stateNum_rowSelected === obj.id) ?
                                                        <KeyboardArrowDownIcon/>
                                                        :
                                                        <KeyboardArrowRightIcon/>
                                                    }
                                                </span> 
                                                <span>
                                                    {obj.giaodichId}
                                                </span>
                                            </p>
                                            <p>{obj.giaodich?.khachhang?.fullNameKH}</p>
                                            <p>{obj.giaodich?.the[0]?.soThe}</p>
                                            <p>{obj.soLo}</p>
                                            <p>{obj.soBill}</p>
                                            <p>{obj.giaodich?.teamrutdaothongbaotrangthai}</p>
    
                                            {
                                                obj.giaodich?.tienrutdao !== null ?
                                                <p>{obj.giaodich?.tienrutdao.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienNap !== null ?
                                                <p>{obj.tienNap.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienNapConLai !== null ?
                                                <p>{obj.tienNapConLai.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienRut !== null ?
                                                <p>{obj.tienRut.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienRutConLai !== null ?
                                                <p>{obj.tienRutConLai.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            <p>
                                                {
                                                    obj.mediaId_rut !== null &&
                                                    <img src={obj.signedUrl_rut} onClick={()=>selectImg(obj.signedUrl_rut)} className="avatar" alt="avatar" />
                                                }
                                            </p>
                                            <p>
                                                {
                                                    obj.giaodich?.hinhChuyenKhoanTienPhi_mediaId !== null ?
                                                    <img src={obj.giaodich?.hinhChuyenKhoanTienPhi_signedUrl} 
                                                        onClick={()=>selectImg(obj.giaodich?.hinhChuyenKhoanTienPhi_signedUrl)} 
                                                        className="avatar" 
                                                        alt="avatar" />
                                                    :
                                                    <span></span>
                                                }
                                            </p>
                                            {
                                                obj.giaodich?.checkChuyenPhi === true ?
                                                    <Tooltip title={obj.giaodich?.resultCheckChuyenPhi} placement="top">
                                                        <p className={clsx(styles.succeed)}> <VerifiedIcon/> </p>
                                                    </Tooltip>
                                                :
                                                    <Tooltip title="Nội dung chuyển tiền không khớp, Phòng Kế toán vui lòng kiểm tra lại" placement="top">
                                                            <p className={clsx(styles.value, styles.fail)}> <HighlightOffIcon/> </p>
                                                    </Tooltip>
                                            }
                                            <p >
                                                <input
                                                    type="checkbox"
                                                    name={obj.giaodichId} // Assuming each item has a unique identifier
                                                    checked={checkedItems_dao[obj.giaodichId.toString()] || false}
                                                    onChange={handleCheckboxChange_dao}
                                                />
                                            </p>
                                        </div>
                                        {
                                            (() => {
                                                if (obj.id === stateNum_rowSelected && stateBool_showDetails === true) {
                                                    return (
                                                        <div className={clsx(styles.additionalInfos)}>
                                                            <div className={clsx(styles.khachhang)}>
                                                                <p className={clsx(styles.title)}>Thông tin Khách Hàng</p>
                                                                {
                                                                    obj.giaodich !== null && obj.giaodich?.khachhang !== null &&
                                                                    <>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>ID KH</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.id}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tên KH</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.fullNameKH}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>CCCD</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.cccd}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Bithday</p>
                                                                            <p className={clsx(styles.value)}>{formatDateToYYYYMMDD(obj.giaodich?.khachhang?.ntns)}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nguồn KH</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.nguonKH}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Thành phố</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.city}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Quận</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.district}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Phường</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.nguonKH}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Địa chỉ</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.address}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tạo lúc</p>
                                                                            <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.khachhang?.createdAt)}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Sửa lúc</p>
                                                                            <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.khachhang?.editedAt)}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nhân viên tạo</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.fullNameNVCSKHcreate}</p>
                                                                        </div>
                                                                        {
                                                                            obj.giaodich.khachhang.phone.length > 0 &&
                                                                            obj.giaodich.khachhang.phone.map((item:any, index:any) => (
                                                                                <div className={clsx(styles.pair)} key={item.id}>
                                                                                    <p className={clsx(styles.key)}>Số ĐT {index + 1}</p>
                                                                                    <p className={clsx(styles.value)}>{replaceFirstFourLetters(formatPhoneNumber(item.phone))}</p>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className={clsx(styles.giaodich)}>
                                                                <p className={clsx(styles.title)}>Thông tin về giao dịch này</p>
                                                                {
                                                                    obj.giaodich !== null &&
                                                                    <>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>ID Giao dịch</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.id}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nhu cầu</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.nhucau}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tiền rút đáo</p>
                                                                            {
                                                                                obj.giaodich.tienrutdao !== null &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.tienrutdao?.toLocaleString('en-US')}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Phí rút đáo</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.phirutdao}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tiền phí</p>
                                                                            {
                                                                                obj.giaodich.tienphi !== null &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.tienphi.toLocaleString('en-US')}</p>
                                                                            }
                                                                            
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tiền chuyển khách</p>
                                                                            {
                                                                                obj.giaodich.tienChuyenKhach !== null &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.tienChuyenKhach.toLocaleString('en-US')}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tiền ship</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.tienship}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nhân viên tạo</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.fullNameNVRutdaoCreate}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Ngân hàng</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.nganhangId}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Ngày hạn thanh toán</p>
                                                                            <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.ngayhanthanhtoan)}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nhân viên ship</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.nhanvienship}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>ND chuyển tiền</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.noiDungChuyenTien}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Trạng thái</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.teamrutdaothongbaotrangthai}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tên chi nhánh</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.tenchinhanh}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Check chuyển phí</p>
                                                                            {
                                                                                obj.giaodich.checkChuyenPhi === true ?
                                                                                    <Tooltip title={obj.giaodich?.resultCheckChuyenPhi} placement="top">
                                                                                        <p className={clsx(styles.value, styles.succeed)}> <VerifiedIcon/> </p>
                                                                                    </Tooltip>
                                                                                :
                                                                                    <Tooltip title="Nội dung chuyển tiền không khớp, Phòng Kế toán phải kiểm tra lại" placement="top">
                                                                                            <p className={clsx(styles.value, styles.fail)}> <HighlightOffIcon/> </p>
                                                                                    </Tooltip>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                    
                                                            </div>
                                                            <div className={clsx(styles.maypos)}>
                                                                <p className={clsx(styles.title)}>Thông tin về máy pos</p>
                                                                {
                                                                    obj.maypos !== null &&
                                                                    <>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Chi nhánh</p>
                                                                            <p className={clsx(styles.value)}>{obj.maypos.chinhanh}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tên máy POS</p>
                                                                            <p className={clsx(styles.value)}>{obj.maypos.name}</p>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className={clsx(styles.the)}>
                                                                <p className={clsx(styles.title)}>Thông tin về thẻ</p>
                                                                {
                                                                    obj.giaodich !== null && obj.giaodich.the !== null &&
                                                                    <>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Hạn mức thẻ</p>
                                                                            {
                                                                                obj.giaodich.the.length !== 0 && obj.giaodich?.the[0]?.hanMucTheTinDung !== null &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.hanMucTheTinDung.toLocaleString('en-US')}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Loại thẻ</p>
                                                                            {
                                                                                obj.giaodich.the.length !== 0 &&
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.loaiThe}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>STK N.Hàng</p>
                                                                            {
                                                                                obj.giaodich.soTaiKhoanNganHang.length !== 0 &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.soTaiKhoanNganHang[0]?.soTaiKhoanNganHang}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>STK thẻ T.Dụng</p>
                                                                            {
                                                                                obj.giaodich.the.length !== 0 &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.soTaiKhoanTheTinDung}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Số thẻ</p>
                                                                            {
                                                                                obj.giaodich.the.length !== 0 &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.soThe}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tên trên thẻ</p>
                                                                            {
                                                                                obj.giaodich.the.length !== 0 &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.tenTrenThe}</p>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className={clsx(styles.dao)}>
                                                                <p className={clsx(styles.title)}>Thông tin về Đáo</p>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Chuyển đến</p>
                                                                    <p className={clsx(styles.value)}>{obj.chuyenDen}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tạo lúc</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.createdAt)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số Bill</p>
                                                                    <p className={clsx(styles.value)}>{obj.soBill}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số Lô</p>
                                                                    <p className={clsx(styles.value)}>{obj.soLo}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số tài khoản</p>
                                                                    <p className={clsx(styles.value)}>{obj.soTaiKhoan}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền nạp</p>
                                                                    {
                                                                        obj.tienNap !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienNap.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền nạp còn lại</p>
                                                                    {
                                                                        obj.tienNapConLai !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienNapConLai.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền rút</p>
                                                                    {
                                                                        obj.tienRut !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienRut.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền rút còn lại</p>
                                                                    {
                                                                        obj.tienRutConLai !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienRutConLai.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                } 
                                            })()
                                        }
                                    </div>
                                )
                            }
                            else if(stateString_tienPhi === "All"){
                                return ( 
                                    <div className={clsx(styles.containerRow)} key={obj.id} 
                                        style={{ 
                                            backgroundColor: stateArr_binaryDao[index]
                                            }}
                                    >
                                        <div className={clsx(styles.row)} 
                                            style={{ 
                                                backgroundColor: stateArr_binaryDaoBold[index] 
                                            }}
                                        >
                                            <p className={clsx(styles.btnWrapperIcon)}>
                                                <span onClick={()=>handle_rowSelected(obj.id)} className={clsx(styles.btn)}>
                                                    {
                                                        (stateBool_showDetails === true && stateNum_rowSelected === obj.id) ?
                                                        <KeyboardArrowDownIcon/>
                                                        :
                                                        <KeyboardArrowRightIcon/>
                                                    }
                                                </span> 
                                                <span>
                                                    {obj.giaodichId}
                                                </span>
                                            </p>
                                            <p>{obj.giaodich?.khachhang?.fullNameKH}</p>
                                            <p>{obj.giaodich?.the[0]?.soThe}</p>
                                            <p>{obj.soLo}</p>
                                            <p>{obj.soBill}</p>
                                            <p>{obj.giaodich?.teamrutdaothongbaotrangthai}</p>
    
                                            {
                                                obj.giaodich?.tienrutdao !== null ?
                                                <p>{obj.giaodich?.tienrutdao.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienNap !== null ?
                                                <p>{obj.tienNap.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienNapConLai !== null ?
                                                <p>{obj.tienNapConLai.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienRut !== null ?
                                                <p>{obj.tienRut.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            {
                                                obj.tienRutConLai !== null ?
                                                <p>{obj.tienRutConLai.toLocaleString('en-US')}</p>:<p></p>
                                            }
                                            <p>
                                                {
                                                    obj.mediaId_rut !== null &&
                                                    <img src={obj.signedUrl_rut} onClick={()=>selectImg(obj.signedUrl_rut)} className="avatar" alt="avatar" />
                                                }
                                            </p>
                                            <p>
                                                {
                                                    obj.giaodich?.hinhChuyenKhoanTienPhi_mediaId !== null ?
                                                    <img src={obj.giaodich?.hinhChuyenKhoanTienPhi_signedUrl} 
                                                        onClick={()=>selectImg(obj.giaodich?.hinhChuyenKhoanTienPhi_signedUrl)} 
                                                        className="avatar" 
                                                        alt="avatar" />
                                                    :
                                                    <span></span>
                                                }
                                            </p>
                                            {
                                                obj.giaodich?.checkChuyenPhi === true ?
                                                    <Tooltip title={obj.giaodich?.resultCheckChuyenPhi} placement="top">
                                                        <p className={clsx(styles.succeed)}> <VerifiedIcon/> </p>
                                                    </Tooltip>
                                                :
                                                    <Tooltip title="Nội dung chuyển tiền không khớp, Phòng Kế toán vui lòng kiểm tra lại" placement="top">
                                                            <p className={clsx(styles.value, styles.fail)}> <HighlightOffIcon/> </p>
                                                    </Tooltip>
                                            }
                                            <p >
                                                <input
                                                    type="checkbox"
                                                    name={obj.giaodichId} // Assuming each item has a unique identifier
                                                    checked={checkedItems_dao[obj.giaodichId.toString()] || false}
                                                    onChange={handleCheckboxChange_dao}
                                                />
                                            </p>
                                        </div>
                                        {
                                            (() => {
                                                if (obj.id === stateNum_rowSelected && stateBool_showDetails === true) {
                                                    return (
                                                        <div className={clsx(styles.additionalInfos)}>
                                                            <div className={clsx(styles.khachhang)}>
                                                                <p className={clsx(styles.title)}>Thông tin Khách Hàng</p>
                                                                {
                                                                    obj.giaodich !== null && obj.giaodich.khachhang !== null &&
                                                                    <>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>ID KH</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.id}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tên KH</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.fullNameKH}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>CCCD</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.cccd}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Bithday</p>
                                                                            <p className={clsx(styles.value)}>{formatDateToYYYYMMDD(obj.giaodich?.khachhang?.ntns)}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nguồn KH</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.nguonKH}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Thành phố</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.city}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Quận</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.district}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Phường</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.nguonKH}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Địa chỉ</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.address}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tạo lúc</p>
                                                                            <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.khachhang?.createdAt)}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Sửa lúc</p>
                                                                            <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.khachhang?.editedAt)}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nhân viên tạo</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.khachhang?.fullNameNVCSKHcreate}</p>
                                                                        </div>
                                                                        {
                                                                            obj.giaodich?.khachhang?.phone.length > 0 &&
                                                                            obj.giaodich?.khachhang?.phone.map((item:any, index:any) => (
                                                                                <div className={clsx(styles.pair)} key={item.id}>
                                                                                    <p className={clsx(styles.key)}>Số ĐT {index + 1}</p>
                                                                                    <p className={clsx(styles.value)}>{replaceFirstFourLetters(formatPhoneNumber(item.phone))}</p>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className={clsx(styles.giaodich)}>
                                                                <p className={clsx(styles.title)}>Thông tin về giao dịch này</p>
                                                                {
                                                                    obj.giaodich !== null &&
                                                                    <>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>ID Giao dịch</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.id}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nhu cầu</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.nhucau}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tiền rút đáo</p>
                                                                            {
                                                                                obj.giaodich.tienrutdao !== null &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.tienrutdao?.toLocaleString('en-US')}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Phí rút đáo</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.phirutdao}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tiền phí</p>
                                                                            {
                                                                                obj.giaodich.tienphi !== null &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.tienphi.toLocaleString('en-US')}</p>
                                                                            }
                                                                            
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tiền chuyển khách</p>
                                                                            {
                                                                                obj.giaodich.tienChuyenKhach !== null &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.tienChuyenKhach.toLocaleString('en-US')}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tiền ship</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.tienship}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nhân viên tạo</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.fullNameNVRutdaoCreate}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Ngân hàng</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.nganhangId}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Ngày hạn thanh toán</p>
                                                                            <p className={clsx(styles.value)}>{formatDateString(obj.giaodich?.ngayhanthanhtoan)}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Nhân viên ship</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.nhanvienship}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>ND chuyển tiền</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.noiDungChuyenTien}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Trạng thái</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.teamrutdaothongbaotrangthai}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tên chi nhánh</p>
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.tenchinhanh}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Check chuyển phí</p>
                                                                            {
                                                                                obj.giaodich.checkChuyenPhi === true ?
                                                                                    <Tooltip title={obj.giaodich?.resultCheckChuyenPhi} placement="top">
                                                                                        <p className={clsx(styles.value, styles.succeed)}> <VerifiedIcon/> </p>
                                                                                    </Tooltip>
                                                                                :
                                                                                    <Tooltip title="Nội dung chuyển tiền không khớp, Phòng Kế toán phải kiểm tra lại" placement="top">
                                                                                            <p className={clsx(styles.value, styles.fail)}> <HighlightOffIcon/> </p>
                                                                                    </Tooltip>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                    
                                                            </div>
                                                            <div className={clsx(styles.maypos)}>
                                                                <p className={clsx(styles.title)}>Thông tin về máy pos</p>
                                                                {
                                                                    obj.maypos !== null &&
                                                                    <>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Chi nhánh</p>
                                                                            <p className={clsx(styles.value)}>{obj.maypos?.chinhanh}</p>
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tên máy POS</p>
                                                                            <p className={clsx(styles.value)}>{obj.maypos?.name}</p>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className={clsx(styles.the)}>
                                                                <p className={clsx(styles.title)}>Thông tin về thẻ</p>
                                                                {
                                                                    obj.giaodich !== null && obj.giaodich?.the !== null &&
                                                                    <>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Hạn mức thẻ</p>
                                                                            {
                                                                                obj.giaodich.the.length !== 0 && obj.giaodich?.the[0]?.hanMucTheTinDung !== null &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.hanMucTheTinDung.toLocaleString('en-US')}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Loại thẻ</p>
                                                                            {
                                                                                obj.giaodich.the.length !== 0 &&
                                                                            <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.loaiThe}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>STK N.Hàng</p>
                                                                            {
                                                                                obj.giaodich.soTaiKhoanNganHang.length !== 0 &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.soTaiKhoanNganHang[0]?.soTaiKhoanNganHang}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>STK thẻ T.Dụng</p>
                                                                            {
                                                                                obj.giaodich?.the.length !== 0 &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.soTaiKhoanTheTinDung}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Số thẻ</p>
                                                                            {
                                                                                obj.giaodich?.the.length !== 0 &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.soThe}</p>
                                                                            }
                                                                        </div>
                                                                        <div className={clsx(styles.pair)}>
                                                                            <p className={clsx(styles.key)}>Tên trên thẻ</p>
                                                                            {
                                                                                obj.giaodich?.the.length !== 0 &&
                                                                                <p className={clsx(styles.value)}>{obj.giaodich?.the[0]?.tenTrenThe}</p>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className={clsx(styles.dao)}>
                                                                <p className={clsx(styles.title)}>Thông tin về Đáo</p>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Chuyển đến</p>
                                                                    <p className={clsx(styles.value)}>{obj.chuyenDen}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tạo lúc</p>
                                                                    <p className={clsx(styles.value)}>{formatDateString(obj.createdAt)}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số Bill</p>
                                                                    <p className={clsx(styles.value)}>{obj.soBill}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số Lô</p>
                                                                    <p className={clsx(styles.value)}>{obj.soLo}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Số tài khoản</p>
                                                                    <p className={clsx(styles.value)}>{obj.soTaiKhoan}</p>
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền nạp</p>
                                                                    {
                                                                        obj.tienNap !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienNap.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền nạp còn lại</p>
                                                                    {
                                                                        obj.tienNapConLai !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienNapConLai.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền rút</p>
                                                                    {
                                                                        obj.tienRut !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienRut.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                                <div className={clsx(styles.pair)}>
                                                                    <p className={clsx(styles.key)}>Tiền rút còn lại</p>
                                                                    {
                                                                        obj.tienRutConLai !== null ?
                                                                        <p className={clsx(styles.value)}>{obj.tienRutConLai.toLocaleString('en-US')}</p>:<span></span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                } 
                                            })()
                                        }
                                    </div>
                                )
                            }
                        })
                    }
                </div>

            }
            <div className={clsx(styles.backShadow,{[styles.appear]: stateBool_isOpenDialog})} onClick={cancelDialog}>
            </div>
            <div className={clsx(styles.dialogImg, {[styles.appear]: stateBool_isOpenDialog})}>
                <img src={stateString_selectedImg}  alt="hinh anh" />
            </div>
        </div>
    )
}
export default ThuChi