import axios from "axios";

const instance = axios.create({
   // baseURL: process.env.REACT_APP_API_BASE || 'http://localhost:3001/api',
   baseURL: process.env.REACT_APP_API_BASE || '/api',
   headers: {},
   // headers: {'Access-Control-Allow-Origin': '*'},
   // onDownloadProgress: progressEvent => {
   //    // Calculate and log the download progress
   //    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
   // console.log(`Download Progress: ${progress}%`);
   // }
});

//quản lý những cái lỗi axios
instance.interceptors.response.use(
   (res) => res, //nếu có res thì trả ra res
   (err) => err //nếu có lỗi thì trả ra lỗi
)

export default instance;

/*
process.env.REACT_APP_API_BASE. Nếu biến này không được định nghĩa, nó sẽ mặc định thành '/api'.

Cấu hình này cho phép bạn dễ dàng chuyển đổi giữa các URL cơ sở API khác nhau 
trong các môi trường khác nhau (phát triển, sản xuất, v.v.) 
bằng cách thay đổi giá trị của biến REACT_APP_API_BASE trong tệp .env.


*/

