import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import clsx from "clsx";
import styles from "./hr.module.scss";
import collectionAPI from "../../../API/collectionAPI";
import React, { useState, useEffect, useRef } from "react";
import { getCurrentDateTime, convertDateString, convertNumbertoDate, combineDateAndTime, convertNumberToHourMinute, addMinutesToTime } from './../../share/sharedFunction';

const FileUpload_bcc = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [stateArr_name_email_fullName, setStateArr_name_email_fullName] = useState([]);

  const getData_findAllEmployees = async () => {
    const response = await collectionAPI.collectionAPI.findAllEmployees(); //phải có await nghĩa là khi nào có data rồi thì mới lấy
    // console.log("response.data: ", response.data);
    setStateArr_name_email_fullName(response.data);
  };
  useEffect(() => {
    getData_findAllEmployees().then();
  }, []);

  // console.log("stateArr_name_email_fullName: ", stateArr_name_email_fullName);
  const onDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    // console.log('selectedFile: ', selectedFile);
    setFile(selectedFile);

    const reader = new FileReader();
    reader.onload = async (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });

      // Assuming you want to read data from the first sheet
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Parse sheet data
      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      sheetData.splice(0, 2); // bỏ 2 dòng đầu tiêu đề

      const resultArr = [];
      const invalidArr = [];

      for (const row of sheetData) {
        const nameToFind = row[2];
        const foundObj = stateArr_name_email_fullName.find(
          (obj) => obj.nameChamCong === nameToFind
        );

        if (foundObj) {
          // console.log('foundObj: ', foundObj);
          // If a match is found, push the corresponding email and elements at index 6 and 7 into resultArr
          const resultDate = convertNumbertoDate(row[4]);
          const checkIn = convertNumberToHourMinute(row[6]);
          const checkOut = convertNumberToHourMinute(row[7]);
          const checkInPlus7Minutes = addMinutesToTime(checkIn,7); // phải + 7 phút vào vì phần read file excel nó bị lỗi tự trừ 7 phút
          const checkOutPlus7Minutes = addMinutesToTime(checkOut,7); // phải + 7 phút vào vì phần read file excel nó bị lỗi tự trừ 7 phút
          resultArr.push({
            email: foundObj.email,
            createdAt: getCurrentDateTime(),
            // name: foundObj.nameChamCong,
            // fullName: foundObj.fullName,
            // date: resultDate, // lấy cột ngày có index 4
            checkin: combineDateAndTime(resultDate,checkInPlus7Minutes), 
            checkout: combineDateAndTime(resultDate,checkOutPlus7Minutes), 
          });
        } else {
          // If no match is found, add to invalidArr
          invalidArr.push({
            nameToFind,
            // Add other relevant data if needed
          });
        }
      }
      // console.log('resultArr: ', resultArr);

      let response = await collectionAPI.collectionAPI.addChamCong(resultArr);
      // console.log('response: ', response);

      // const arrGroupByEmail = groupByEmail(resultArr);
      // const invalidArrGroupByName = getUniqueNames(invalidArr);
      // console.log(arrGroupByEmail);
      // onDataFromFileUpload_bcc(arrGroupByEmail, invalidArrGroupByName);
    };

    reader.readAsBinaryString(selectedFile);
  };

  /*
    chuyển array về định dạng
    [
        {
            email: "email1",
            allDate: ["2020-05-25", "2020-05-26"],
            checkIn: ["10:20","15:20"],
            checkOut: ["10:20","17:20"],
        }
    ]
    */

  

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx",
  });

  return (
    <div>
      <div {...getRootProps()} className={clsx(styles.dropZone)}>
        <input {...getInputProps()} />
        <p>Kéo thả file excel vào đây (.xlsx) , </p>
        <p>hoặc nhấn để chọn</p>
      </div>
    </div>
  );
};

// const dropzoneStyle = {
//     border: "2px dashed #cccccc",
//     borderRadius: "4px",
//     textAlign: "center",
//     cursor: "pointer",
//     // border: "1px solid blue",
//     padding: "0 0 0 0",
//     margin: "0 0 0 0",
// };

export default FileUpload_bcc;
