import { useState } from 'react';
import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './hr.module.scss'
import HRSubMenu from './HRSubMenu';

function HRHome() {
   const [open, setOpen] = useState(true);
   // setOpen(bool);
   const dispatch = useDispatch(); //hook phát đi đến GeneralSlice.js



   return (
      <div className={clsx(styles.component_HRHome)}>
         <div className={clsx(styles.main)}>
            <HRSubMenu/>   
            <Outlet/>
         </div>
      </div>
   )
}
export default HRHome