
const arrEmail1 = 
    ["linh.lnp@charm.contact",
    "tu.tn@charm.contact",
    "nhon.dp@charm.contact",
    "xuan.ta@charm.contact",
    "anh.nth@charm.contact", 
    "vu.na@charm.contact",
    "phi.mh@charm.contact",
    "ngan.ttk@charm.contact"
    ]

const arrEmail2 = 
    [
        "linh.lnp@charm.contact",
        "tu.tn@charm.contact",
        "nhon.dp@charm.contact",
        "ngan.ttk@charm.contact",
        "vu.na@charm.contact"
    ]

const arrEmail3 = 
    [
        "linh.lnp@charm.contact",
        "vu.na@charm.contact",
        "nhan.dt@charm.contact"
    ]
const arrEmail4 = 
    [
        "phuong.nt@charm.contact",
        "vy.nnt@charm.contact",
        "thuong.ttt@charm.contact",
        "linh.lnp@charm.contact",
        "tu.tn@charm.contact",
        "nhon.dp@charm.contact",
        "phi.mh@charm.contact",
        "vu.na@charm.contact"
    ]
const arrEmail5 = 
    [
        "vu.na@charm.contact",
        "anh.nth@charm.contact",
        "phuong.dh@charm.contact",
    ]
const arrEmail_backOffice = 
    [
        "phuong.dh@charm.contact",
        "phong.th@charm.contact",
        "thinh.bh@charm.contact",
        "nhan.dt@charm.contact",
        "quynh.dnh@charm.contact",
        "nhung.th@charm.contact",
        "yen.ttk@charm.contact",
        "quy.nh1@charm.contact",
        "vy.ntt@charm.contact",
        "phuong.nt@charm.contact",
        "thuong.ttt@charm.contact",
        "hoang.n@charm.contact"
    ]
const arrRole_HR = ["staffHR","TPHR", "BGD","admin"]
const arrRole_CSKH = ["staffCSKH","TPCSKH","TPVH", "BGD","admin"];
const arrRole_Finance = ["staffRD","TPRD", "BGD","staffKT","TPKT","admin"];
const arrRole_KT = ["BGD","TPKT","staffKT","admin"];
const arrRole_admin = ["admin"];
const arrRole_bgd = ["BGD","admin"];
export { 
    arrEmail1, 
    arrEmail2, 
    arrEmail3,
    arrEmail4,
    arrEmail5,
    arrRole_HR,
    arrRole_CSKH,
    arrRole_Finance,
    arrRole_KT,
    arrRole_admin,
    arrEmail_backOffice,
    arrRole_bgd
};