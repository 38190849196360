import { useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './Quy.module.scss'
import collectionAPI from '../../../API/collectionAPI';
import {
    extractHHMM,
    retrieveObjectFromLocalStorage,
    formatThoudsand,
    wait,
    formatDate2,
    getNextDay,
    convertDateFormat4,
} from '../../share/sharedFunction';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { isFromTodayToThePast } from '../../share/share.validation';
import { arrEmail2} from '../../share/arrRole';

function Quy() {


    const [stateObjSignIn, setStateObjSignIn] = useState<Record<string, any>>({
        email: "",
        fullName: "",
        role: "",
        token:""
    })
    const checkSignin = () => {
    
        const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
        if(userExist === null){
            return
        }
        setStateObjSignIn(userExist);
        // console.log('userExist: ', userExist);
    
    }
    useEffect(() => {
        checkSignin();
    },[]);
    const [stateString_accountNo_left, setStateString_accountNo_left] = useState<string>('');
    const [stateString_accountNo_user_left, setStateString_accountNo_user_left] = useState<string>('');
    const [stateString_accountNo_user_right, setStateString_accountNo_user_right] = useState<string>('');
    const [stateString_accountNo_right, setStateString_accountNo_right] = useState<string>('');
    const [stateString_user_left, setStateString_user_left] = useState<string>(''); // username khi đăng nhập mb bank
    const [stateString_user_right, setStateString_user_right] = useState<string>(''); // username khi đăng nhập mb bank
    const handleChange_employee_left = (e:any) => {
        if(e.target.value === "Chọn nhân viên"){
            alert(" Bạn phải chọn 1 nhân viên trong danh sách");
            return;
        }
        setStateString_accountNo_user_left(e.target.value);
        const parts = e.target.value.split('-');
        setStateString_accountNo_left(parts[0]);
        setStateString_user_left(parts[1]);
    }
    const handleChange_employee_right = (e:any) => {
        if(e.target.value === "Chọn nhân viên"){
            alert(" Bạn phải chọn 1 nhân viên trong danh sách");
            return;
        }
        setStateString_accountNo_user_right(e.target.value);
        const parts = e.target.value.split('-');
        setStateString_accountNo_right(parts[0]);
        setStateString_user_right(parts[1]);
    }

    const [stateString_date_left, setStateString_date_left] = useState<string>("");
    const [stateArr_quy_left_credit_vao, setStateArr_quy_left_credit_vao] = useState<any[]>([]);
    const [stateNum_vao_left, setStateNum_vao_left] = useState(0);
    const [stateArr_quy_left_debit_ra, setStateArr_quy_left_debit_ra] = useState<any[]>([]);
    const [stateNum_ra_left, setStateNum_ra_left] = useState(0);


    const handleChange_date_left = async (event:any) => { 
        let date = event.target.value;
        if(stateString_accountNo_left === "Chọn nhân viên" || stateString_accountNo_left === ""){
            alert("Vui lòng chọn nhân viên trước khi chọn ngày !");
            return;
        }
        if(!isFromTodayToThePast(date)){
            alert("Bạn không được chọn ngày trong tương lai để lấy sao kê !");
            return;
        }
        setStateString_date_left(date);
        
    }; 
    const [stateString_date_right, setStateString_date_right] = useState<string>("");
    const [stateArr_quy_right_credit_vao, setStateArr_quy_right_credit_vao] = useState<any[]>([]);
    const [stateNum_vao_right, setStateNum_vao_right] = useState(0);
    const [stateArr_quy_right_debit_ra, setStateArr_quy_right_debit_ra] = useState<any[]>([]);
    const [stateNum_ra_right, setStateNum_ra_right] = useState(0);
    const handleChange_date_right = async (event:any) => { 
        let date = event.target.value;
        if(stateString_accountNo_right === "Chọn nhân viên"){
            alert("Vui lòng chọn nhân viên trước khi chọn ngày !");
            return;
        }
        if(!isFromTodayToThePast(date)){
            alert("Bạn không được chọn ngày trong tương lai để lấy sao kê !");
            return;
        }
        setStateString_date_right(event.target.value) 
   
    }; 

    const [stateObj_groupInput_vao_left, setStateObj_groupInput_vao_left] = useState({});

    // Handler function to update input value based on index
    const handleInputChange_vao_left = (index: number, value: string) => {
        setStateObj_groupInput_vao_left(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };
    const [stateObj_groupInput_ra_left, setStateObj_groupInput_ra_left] = useState({});

    // Handler function to update input value based on index
    const handleInputChange_ra_left = (index: number, value: string) => {
        setStateObj_groupInput_ra_left(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };
    const [stateObj_groupInput_vao_right, setStateObj_groupInput_vao_right] = useState({});
    const handleInputChange_vao_right = (index: number, value: string) => {
        setStateObj_groupInput_vao_right(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };
    const [stateObj_groupInput_ra_right, setStateObj_groupInput_ra_right] = useState({});

    // Handler function to update input value based on index
    const handleInputChange_ra_right = (index: number, value: string) => {
        setStateObj_groupInput_ra_right(prevInputValues => ({
            ...prevInputValues,
            [index]: value
        }));
    };
    const saveGhichu_vao_left = async () => {
    
        // console.log('stateObj_groupInput_vao_left: ', stateObj_groupInput_vao_left);
        const token = stateObjSignIn.token;
        for (let i = 0; i < stateArr_quy_left_credit_vao.length; i++){
            let pos = stateArr_quy_left_credit_vao[i].pos;
            // `$ {id}` ép id thành string
            //@ts-ignore
            let ghiChu: any = stateObj_groupInput_vao_left[i];
            if(ghiChu !== null){ // cái nào null nghĩa là ko update thì bỏ qua
                try {
                    let response = await collectionAPI.collectionAPI.updateFieldByPos(token, pos,"ghiChu",ghiChu);
                    // console.log('response: ', response);                    
                }catch(err){
                    // console.log('err:', err);
                }
            }
            await wait(300);
        }  
    }
    const saveGhichu_ra_left = async () => {
    
        // console.log('stateObj_groupInput_ra_left: ', stateObj_groupInput_ra_left);
        const token = stateObjSignIn.token;
        for (let i = 0; i < stateArr_quy_left_debit_ra.length; i++){
            let pos = stateArr_quy_left_debit_ra[i].pos;
            // `$ {id}` ép id thành string
            //@ts-ignore
            let ghiChu: any = stateObj_groupInput_ra_left[i];
            if(ghiChu !== null){ // cái nào null nghĩa là ko update thì bỏ qua
                try {
                    let response = await collectionAPI.collectionAPI.updateFieldByPos(token, pos,"ghiChu",ghiChu);
                    // console.log('response: ', response);                    
                }catch(err){
                    // console.log('err:', err);
                }
            }
            await wait(300);
        }  
    }
    const saveGhichu_vao_right = async () => {
    
        // console.log('stateObj_groupInput_vao_right: ', stateObj_groupInput_vao_right);
        const token = stateObjSignIn.token;
        for (let i = 0; i < stateArr_quy_right_credit_vao.length; i++){
            let pos = stateArr_quy_right_credit_vao[i].pos;
            // `$ {id}` ép id thành string
            //@ts-ignore
            let ghiChu: any = stateObj_groupInput_vao_right[i];
            if(ghiChu !== null){ // cái nào null nghĩa là ko update thì bỏ qua
                try {
                    let response = await collectionAPI.collectionAPI.updateFieldByPos(token, pos,"ghiChu",ghiChu);
                    // console.log('response: ', response);                    
                }catch(err){
                    // console.log('err:', err);
                }
            }
            await wait(300);
        }  
    }
    const saveGhichu_ra_right = async () => {
    
        // console.log('stateObj_groupInput_ra_right: ', stateObj_groupInput_ra_right);
        const token = stateObjSignIn.token;
        for (let i = 0; i < stateArr_quy_right_debit_ra.length; i++){
            let pos = stateArr_quy_right_debit_ra[i].pos;
            // `$ {id}` ép id thành string
            //@ts-ignore
            let ghiChu: any = stateObj_groupInput_ra_right[i];
            if(ghiChu !== null){ // cái nào null nghĩa là ko update thì bỏ qua
                try {
                    let response = await collectionAPI.collectionAPI.updateFieldByPos(token, pos,"ghiChu",ghiChu);
                    // console.log('response: ', response);                    
                }catch(err){
                    // console.log('err:', err);
                }
            }
            await wait(300);
        }  
    }
    const [stateBool_isLoading_left, setStateBool_isLoading_left] = useState(false);
    const getTransactions_left = async () => {
        
        // start lấy giao dịch trong bảng transactions
        const token = stateObjSignIn.token;
        // let fromDate = stateString_date_left;
        // let toDate = addOneDay(fromDate);
        // console.log('fromDate: ', fromDate);
        // console.log('toDate: ', toDate);
        // console.log('stateString_accountNo_left: ', stateString_accountNo_left);

        // end lấy giao dịch trong bảng transactions
        if(stateString_accountNo_left === "Chọn nhân viên" || stateString_accountNo_left === ""){
            alert("Vui lòng chọn nhân viên trước khi Download !");
            return;
        }
        if(stateString_date_left === "" || stateString_date_left === null || stateString_date_left === undefined){
            alert("Vui lòng chọn ngày muốn download sao kê");
            return;
        }
        if(!isFromTodayToThePast(stateString_date_left)){
            alert("Bạn không được chọn ngày trong tương lai để lấy sao kê !");
            return;
        }
        let selectedDay = formatDate2(stateString_date_left); // "dd/mm/yyyy"
        let nextDay = formatDate2(getNextDay(stateString_date_left)); // "dd/mm/yyyy"
        // console.log('selectedDay: ', selectedDay);
        // console.log('nextDay: ', nextDay);
        let data = {
            accountNo: stateString_accountNo_left,
            fromDate: selectedDay,
            toDate: nextDay
        }
        // console.log('data: ', data);
        setStateBool_isLoading_left(true); // cho hiện svg loading
        try {
            let response;
            let arr : any= [];
            let arrPromise : any;
            let downloadDate = stateString_date_left+"T00:00:00Z"; // "yyyy-mm-ddT00:00:00Z"
            let resultDownloadDate = await collectionAPI.collectionAPI.findDownloadDateByAccountAndDate(token,stateString_accountNo_left, downloadDate);
            // console.log('resultDownloadDate: ', resultDownloadDate);
            if(resultDownloadDate.data === "" || resultDownloadDate.data === null){ // ko tồn tại trong bảng downloadDate
                // DL từ MB BANK
                arrPromise = new Promise(async (resolve) => {
                    const intervalId = setInterval(async () => {
                        response = await collectionAPI.collectionAPI.transactions(data, stateString_user_left);
                        // console.log('response:', response);
                        let result = response.hasOwnProperty('data');
                        // console.log("response có property data"); // true
                        if (result === true) {
                            clearInterval(intervalId); // Stop the interval
                            setStateBool_isLoading_left(false); // tắt svg loading
            
                            if (response.data.message === "no data") {
                                alert(`Tài khoản: ${stateString_accountNo_left}, user: ${stateString_user_left} trong ngày ${selectedDay} không có lịch sử giao dịch`);
                                resolve([]);
                            } else {
                                resolve(response.data.transactions);
                            }
                        }
                    }, 10000);
                });
            }
            else { // đã tồn tại trong bảng downloadDate
                if (resultDownloadDate.data.haveData === false && resultDownloadDate.data.isDone === true){
                    alert(`Tài khoản: ${stateString_accountNo_left}, user: ${stateString_user_left} trong ngày ${selectedDay} không có lịch sử giao dịch`)
                    setStateBool_isLoading_left(false);// tắt svg loading
                    return;
                }
                else if (resultDownloadDate.data.haveData === true && resultDownloadDate.data.isDone === true){ // nếu đã download xong mà có data thì lấy dữ liệu trong bảng transactions
                    // chuyển thành đúng định dạng ngày để lấy data từ bảng transactions
                    let fromDate = convertDateFormat4(selectedDay); // from dd/mm/yyyy to mm-dd-yyyy
                    let toDate = convertDateFormat4(nextDay); // from dd/mm/yyyy to mm-dd-yyyy
                    let accountNo = stateString_accountNo_left;
                    arrPromise = new Promise(async (resolve) => {
                        try {
                            response = await collectionAPI.collectionAPI.getTransactionsByDateAndAccount(token, fromDate, toDate, accountNo);
                            // console.log('response 324: ', response);
                            
                            if (!response.data || response.data.length === 0) {
                                alert(`Tài khoản: ${stateString_accountNo_left}, user: ${stateString_user_left} trong ngày ${selectedDay} không có lịch sử giao dịch`);
                                setStateBool_isLoading_left(false);// tắt svg loading
                                resolve([]);
                            } else {
                                setStateBool_isLoading_left(false);// tắt svg loading
                                resolve(response.data);
                            }
                        } catch (error) {
                            // Handle errors if necessary
                            console.error('Error occurred:', error);
                            setStateBool_isLoading_left(false);// tắt svg loading
                            resolve([]);
                        }
                    });
                }
                else if (resultDownloadDate.data.isDone === false){ // nếu đã download xong mà có data thì lấy dữ liệu trong bảng transactions
                    let downloadDateId = parseInt(resultDownloadDate.data.id+'');
                    // DL từ MB BANK
                    // nó sẽ chờ 10 s sau mới thực hiện khối lệnh trong interval
                    arrPromise = new Promise(async (resolve) => {
                        const intervalId = setInterval(async () => {
                            response = await collectionAPI.collectionAPI.transactions2(data, stateString_user_left, downloadDateId);
                            // console.log('response 344:', response);
                            let result = response.hasOwnProperty('data');
                            // console.log("response có property data"); // true
                            if (result === true) {
                                clearInterval(intervalId); // Stop the interval
                                setStateBool_isLoading_left(false); // tắt svg loading
                    
                                if (response.data.message === "no data") {
                                    alert(`Tài khoản: ${stateString_accountNo_left}, user: ${stateString_user_left} trong ngày ${selectedDay} không có lịch sử giao dịch`);
                                    resolve([]);
                                } else {
                                    resolve(response.data.transactions);
                                }
                            }
                        }, 10000);
                    });

                }
            }
            arr = await arrPromise; // khi code chạy tới đây thì nó ko chạy tiếp mà nó phải chờ cho Promise làm xong trả kết quả ra thì mới chạy tiếp
            // console.log('arr 367: ', arr);
            let arr_vao = [];
            let arr_ra = [];
            let obj_input_vao_left: any = {};
            let count_input_vao_left = 0;
            let obj_input_ra_left: any = {};
            let count_input_ra_left = 0;
            // sort time từ sớm đến muộn
            let sortedArr = arr.sort((a: any, b: any) => new Date(a.transactionDate).getTime() - new Date(b.transactionDate).getTime());
            let sum_vao_left = 0;
            let sum_ra_left = 0;
            for (let obj of sortedArr) {
                if(obj.creditAmount !== "0"){
                    arr_vao.push(obj);
                    obj_input_vao_left[count_input_vao_left] = obj.ghiChu;
                    count_input_vao_left++;
                    sum_vao_left += parseInt(obj.creditAmount)
                }
                else {
                    arr_ra.push(obj);
                    obj_input_ra_left[count_input_ra_left] = obj.ghiChu;
                    count_input_ra_left++;
                    sum_ra_left += parseInt(obj.debitAmount)

                }
            }
            setStateNum_vao_left(sum_vao_left);
            setStateNum_ra_left(sum_ra_left);
            setStateObj_groupInput_vao_left(obj_input_vao_left);
            setStateObj_groupInput_ra_left(obj_input_ra_left);
            setStateArr_quy_left_credit_vao(arr_vao);
            setStateArr_quy_left_debit_ra(arr_ra);


            
        }catch(err){
            // console.log('err:', err);
        }
    }
    const [stateBool_isLoading_right, setStateBool_isLoading_right] = useState(false);
    const getTransactions_right = async () => {
        
        const token = stateObjSignIn.token;
        if(stateString_accountNo_right === "Chọn nhân viên" || stateString_accountNo_right === ""){
            alert("Vui lòng chọn nhân viên trước khi Download !");
            return;
        }
        if(stateString_date_right === "" || stateString_date_right === null || stateString_date_right === undefined){
            alert("Vui lòng chọn ngày muốn download sao kê");
            return;
        }
        if(!isFromTodayToThePast(stateString_date_right)){
            alert("Bạn không được chọn ngày trong tương lai để lấy sao kê !");
            return;
        }
        let selectedDay = formatDate2(stateString_date_right); // "dd/mm/yyyy"
        let nextDay = formatDate2(getNextDay(stateString_date_right)); // "dd/mm/yyyy"
        // console.log('selectedDay: ', selectedDay);
        // console.log('nextDay: ', nextDay);
        let data = {
            accountNo: stateString_accountNo_right,
            fromDate: selectedDay,
            toDate: nextDay
        }
        // console.log('data: ', data);
        setStateBool_isLoading_right(true); // cho hiện svg loading
        try {
            let response;
            let arr : any= [];
            let arrPromise : any;
            let downloadDate = stateString_date_right+"T00:00:00Z"; // "yyyy-mm-ddT00:00:00Z"
            let resultDownloadDate = await collectionAPI.collectionAPI.findDownloadDateByAccountAndDate(token,stateString_accountNo_right, downloadDate);
            // console.log('resultDownloadDate: ', resultDownloadDate);
            if(resultDownloadDate.data === "" || resultDownloadDate.data === null){ // ko tồn tại trong bảng downloadDate
                // DL từ MB BANK
                arrPromise = new Promise(async (resolve) => {
                    const intervalId = setInterval(async () => {
                        response = await collectionAPI.collectionAPI.transactions(data, stateString_user_right);
                        // console.log('response:', response);
                        let result = response.hasOwnProperty('data');
                        // console.log("response có property data"); // true
                        if (result === true) {
                            clearInterval(intervalId); // Stop the interval
            
                            if (response.data.message === "no data") {
                                alert(`Tài khoản: ${stateString_accountNo_right}, user: ${stateString_user_right} trong ngày ${selectedDay} không có lịch sử giao dịch`);
                                resolve([]);
                            } else {
                                resolve(response.data.transactions);
                            }
                        }
                    }, 10000);
                });
            }
            else { // đã tồn tại trong bảng downloadDate
                if (resultDownloadDate.data.haveData === false && resultDownloadDate.data.isDone === true){
                    alert(`Tài khoản: ${stateString_accountNo_right}, user: ${stateString_user_right} trong ngày ${selectedDay} không có lịch sử giao dịch`)
                    setStateBool_isLoading_right(false);// tắt svg loading
                    return;
                }
                else if (resultDownloadDate.data.haveData === true && resultDownloadDate.data.isDone === true){ // nếu đã download xong mà có data thì lấy dữ liệu trong bảng transactions
                    // chuyển thành đúng định dạng ngày để lấy data từ bảng transactions
                    let fromDate = convertDateFormat4(selectedDay); // from dd/mm/yyyy to mm-dd-yyyy
                    let toDate = convertDateFormat4(nextDay); // from dd/mm/yyyy to mm-dd-yyyy
                    let accountNo = stateString_accountNo_right;
                    arrPromise = new Promise(async (resolve) => {
                        response = await collectionAPI.collectionAPI.getTransactionsByDateAndAccount(token, fromDate, toDate, accountNo);
                        // console.log('response 324: ', response);
                        
                        if (response.data === null || response.data.length === 0) {
                            alert(`Tài khoản: ${stateString_accountNo_right}, user: ${stateString_user_right} trong ngày ${selectedDay} không có lịch sử giao dịch`);
                            setStateBool_isLoading_right(false);// tắt svg loading
                            resolve([]); // Resolve with an empty array
                            return;
                        } else {
                            setStateBool_isLoading_right(false);// tắt svg loading
                            resolve(response.data); // Resolve with the data
                        }
                    });
                    
                }
                else if (resultDownloadDate.data.isDone === false){ // nếu đã download xong mà có data thì lấy dữ liệu trong bảng transactions
                    let downloadDateId = parseInt(resultDownloadDate.data.id+'');
                    // DL từ MB BANK
                    // nó sẽ chờ 10 s sau mới thực hiện khối lệnh trong interval
                    arrPromise = new Promise(async (resolve) => {
                        const intervalId = setInterval(async () => {
                            response = await collectionAPI.collectionAPI.transactions2(data, stateString_user_right, downloadDateId);
                            // console.log('response 344:', response);
                            let result = response.hasOwnProperty('data');
                            // console.log("response có property data"); // true
                            if (result === true) {
                                clearInterval(intervalId); // Stop the interval
                    
                                if (response.data.message === "no data") {
                                    alert(`Tài khoản: ${stateString_accountNo_right}, user: ${stateString_user_right} trong ngày ${selectedDay} không có lịch sử giao dịch`);
                                    resolve([]);
                                } else {
                                    resolve(response.data.transactions);
                                }
                            }
                        }, 10000);
                    });

                }
            }
            arr = await arrPromise; // khi code chạy tới đây thì nó ko chạy tiếp mà nó phải chờ cho Promise làm xong trả kết quả ra thì mới chạy tiếp
            setStateBool_isLoading_right(false); // tắt svg loading
            // console.log('arr 367: ', arr);
            let arr_vao = [];
            let arr_ra = [];
            let obj_input_vao_right: any = {};
            let count_input_vao_right = 0;
            let obj_input_ra_right: any = {};
            let count_input_ra_right = 0;
            // sort time từ sớm đến muộn
            let sortedArr = arr.sort((a: any, b: any) => new Date(a.transactionDate).getTime() - new Date(b.transactionDate).getTime());
            
            let sum_vao_right = 0;
            let sum_ra_right = 0;
            for (let obj of sortedArr) {
                if(obj.creditAmount !== "0"){
                    arr_vao.push(obj);
                    obj_input_vao_right[count_input_vao_right] = obj.ghiChu;
                    count_input_vao_right++;
                    sum_vao_right += parseInt(obj.creditAmount)
                }
                else {
                    arr_ra.push(obj);
                    obj_input_ra_right[count_input_ra_right] = obj.ghiChu;
                    count_input_ra_right++;
                    sum_ra_right += parseInt(obj.debitAmount)

                }
            }
            setStateNum_vao_right(sum_vao_right);
            setStateNum_ra_right(sum_ra_right);
            setStateObj_groupInput_vao_right(obj_input_vao_right);
            setStateObj_groupInput_ra_right(obj_input_ra_right);
            setStateArr_quy_right_credit_vao(arr_vao);
            setStateArr_quy_right_debit_ra(arr_ra);


            
        }catch(err){
            // console.log('err:', err);
        }
    }
    return (
        <div className={clsx(styles.component_Quy)}>
            <div className={clsx(styles.left)}>
                <div className={clsx(styles.title)}>
                    <h4>
                        NHẬN QUỸ (SÁNG) 
                    </h4>
                    <p className={clsx(styles.btnDownload)} onClick={getTransactions_left}>
                        <CloudDownloadIcon/>
                    </p>
                    <p>
                        {
                            stateBool_isLoading_left &&
                            <img src="../assets/svg/Spinner.svg" className={clsx(styles.loader)} alt="loader" />
                        }
                    </p>
                    
                </div>
                <div className={clsx(styles.general)}>
                    <div className={clsx(styles.innerLeft)}>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Người nhận:</p>
                            <select name="from" onChange={handleChange_employee_left} className={clsx(styles.selectOption)} value={stateString_accountNo_user_left}>
                                <option value="Chọn nhân viên">Chọn nhân viên...</option>
                                <option value="060627167979-0876666687">Ms. Tú</option>
                                <option value="060920026060-0945875008">Ms. Ánh</option>
                                <option value="0000018007220-0357169173">Ms. Xuân</option>
                                {
                                    (() => {
                                        if (arrEmail2.includes(stateObjSignIn.email)) {
                                            return (
                                                <option value="1010160784007-Linhle01">Mrs. Linh</option>
                                            );
                                        } 
                                    })()
                                }
                            </select>
                        </div>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Ngày: </p>
                            <input type="date" 
                                className={clsx(styles.inputDate)} 
                                value={stateString_date_left} 
                                onChange={handleChange_date_left}/>
                        </div>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>MB Bank Account No. : </p>
                            <p className={clsx(styles.value)}>{stateString_accountNo_left}</p>
                        </div>
                        
                    </div>
                    <div className={clsx(styles.innerRight)}>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Tổng tiền vào : </p>
                            <p className={clsx(styles.value)}>{formatThoudsand(stateNum_vao_left)}</p>
                        </div>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Tổng tiền ra : </p>
                            <p className={clsx(styles.value)}>{formatThoudsand(stateNum_ra_left)}</p>
                        </div>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Còn lại : </p>
                            <p className={clsx(styles.value)}>{formatThoudsand(stateNum_vao_left-stateNum_ra_left)}</p>
                        </div>
                    </div>
                </div>
                <div className={clsx(styles.table)}>
                    <div className={clsx(styles.heading)}>
                        <p>STT</p>
                        <p>Thời gian</p>
                        <p className={clsx(styles.money)}>Tiền vào</p>
                        <p>Mô tả</p>
                        <p className={clsx(styles.btnSaveWrapper)}>
                            <span>
                                Ghi chú
                            </span>
                            <span className={clsx(styles.btnSave)} onClick={saveGhichu_vao_left}>
                                Save
                            </span>
                        </p>
                                
                    </div>
                    {
                        stateArr_quy_left_credit_vao.length > 0 &&
                        stateArr_quy_left_credit_vao.map((obj, index) => {
                            // console.log(obj);

                            return (
                                <div className={clsx(styles.row)} key={index}>
                                    <p>{index + 1}</p>
                                    <p>{extractHHMM(obj.transactionDate)}</p>
                                    <p className={clsx(styles.money)}>{formatThoudsand(obj.creditAmount)}</p>
                                    <p>{obj.addDescription}</p>
                                    <p>
                                        <input
                                            type="text"
                                            className={clsx(styles.inputTag, styles.inputThird)}
                                            // `$ {id}` ép id thành string
                                            //@ts-ignore
                                            value={stateObj_groupInput_vao_left[index] || ''}
                                            placeholder="Nhập ghi chú..."
                                            onChange={(event) => handleInputChange_vao_left(index, event.target.value)}
                                        />
                                    </p>
                                </div>
                            );
                        })
                    }

                    

                    <div className={clsx(styles.totalRow)}>
                        <p className={clsx(styles.one)}></p>
                        <p className={clsx(styles.two)}>Tổng: </p>
                        <p className={clsx(styles.three)}>{formatThoudsand(stateNum_vao_left)}</p>
                        <p className={clsx(styles.four)}></p>
                        <p className={clsx(styles.five)}></p>
                    </div>
                </div>
                <div className={clsx(styles.table, styles.table_left_bottom)}>
                    <div className={clsx(styles.heading)}>
                        <p>STT</p>
                        <p>Thời gian</p>
                        <p className={clsx(styles.money)}>Tiền ra</p>
                        <p>Mô tả</p>
                        <p className={clsx(styles.btnSaveWrapper)}>
                            <span>
                                Ghi chú
                            </span>
                            <span className={clsx(styles.btnSave)} onClick={saveGhichu_ra_left}>
                                Save
                            </span>
                        </p>
                                
                    </div>
                    {
                        stateArr_quy_left_debit_ra.length > 0 &&
                        stateArr_quy_left_debit_ra.map((obj, index) => {
                            return (
                                <div className={clsx(styles.row)} key={index}>
                                    <p>{index + 1}</p>
                                    <p>{extractHHMM(obj.transactionDate)}</p>
                                    <p className={clsx(styles.money)}>{formatThoudsand(obj.debitAmount)}</p>
                                    <p>{obj.addDescription}</p>
                                    <p>
                                        <input
                                            type="text"
                                            className={clsx(styles.inputTag, styles.inputThird)}
                                            // `$ {id}` ép id thành string
                                            //@ts-ignore
                                            value={stateObj_groupInput_ra_left[index] || ''}
                                            placeholder="Nhập ghi chú..."
                                            onChange={(event) => handleInputChange_ra_left(index, event.target.value)}
                                        />
                                    </p>
                                </div>
                            );
                        })
                    }

                    

                    <div className={clsx(styles.totalRow)}>
                        <p className={clsx(styles.one)}></p>
                        <p className={clsx(styles.two)}>Tổng: </p>
                        <p className={clsx(styles.three)}>{formatThoudsand(stateNum_ra_left)}</p>
                        <p className={clsx(styles.four)}></p>
                        <p className={clsx(styles.five)}></p>
                    </div>
                </div>
            </div>
            {/* end left */}
            <div className={clsx(styles.right)}>
                <div className={clsx(styles.title)}>
                    <h4>
                        NHẬN QUỸ (CHIỀU) 
                    </h4>
                    <p className={clsx(styles.btnDownload)} onClick={getTransactions_right}>
                        <CloudDownloadIcon/>
                    </p>
                    <p>
                        {
                            stateBool_isLoading_right &&
                            <img src="../assets/svg/Spinner.svg" className={clsx(styles.loader)} alt="loader" />
                        }
                    </p>
                    
                </div>
                <div className={clsx(styles.general)}>
                    <div className={clsx(styles.innerLeft)}>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Người nhận:</p>
                            <select name="from" onChange={handleChange_employee_right} className={clsx(styles.selectOption)} value={stateString_accountNo_user_right}>
                                <option value="Chọn nhân viên">Chọn nhân viên...</option>
                                <option value="060920026060-0945875008">Ms. Ánh</option>
                                <option value="0000018007220-0357169173">Ms. Xuân</option>
                                {
                                    (() => {
                                        if (arrEmail2.includes(stateObjSignIn.email)) {
                                            return (
                                                <option value="1010160784007-Linhle01">Mrs. Linh</option>
                                            );
                                        } 
                                    })()
                                }
                            </select>
                        </div>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Ngày: </p>
                            <input type="date" 
                                className={clsx(styles.inputDate)} 
                                value={stateString_date_right} 
                                onChange={handleChange_date_right}/>
                        </div>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>MB Bank Account No. : </p>
                            <p className={clsx(styles.value)}>{stateString_accountNo_right}</p>
                        </div>
                        
                    </div>
                    <div className={clsx(styles.innerRight)}>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Tổng tiền vào : </p>
                            <p className={clsx(styles.value)}>{formatThoudsand(stateNum_vao_right)}</p>
                        </div>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Tổng tiền ra : </p>
                            <p className={clsx(styles.value)}>{formatThoudsand(stateNum_ra_right)}</p>
                        </div>
                        <div className={clsx(styles.row2)}>
                            <p className={clsx(styles.key)}>Còn lại : </p>
                            <p className={clsx(styles.value)}>{formatThoudsand(stateNum_vao_right-stateNum_ra_right)}</p>
                        </div>
                    </div>
                </div>
                <div className={clsx(styles.table)}>
                    <div className={clsx(styles.heading)}>
                        <p>STT</p>
                        <p>Thời gian</p>
                        <p className={clsx(styles.money)}>Tiền vào</p>
                        <p>Mô tả</p>
                        <p className={clsx(styles.btnSaveWrapper)}>
                            <span>
                                Ghi chú
                            </span>
                            <span className={clsx(styles.btnSave)} onClick={saveGhichu_vao_right}>
                                Save
                            </span>
                        </p>
                                
                    </div>
                    {
                        stateArr_quy_right_credit_vao.length > 0 &&
                        stateArr_quy_right_credit_vao.map((obj, index) => {
                            // console.log(obj);

                            return (
                                <div className={clsx(styles.row)} key={index}>
                                    <p>{index + 1}</p>
                                    <p>{extractHHMM(obj.transactionDate)}</p>
                                    <p className={clsx(styles.money)}>{formatThoudsand(obj.creditAmount)}</p>
                                    <p>{obj.addDescription}</p>
                                    <p>
                                        <input
                                            type="text"
                                            className={clsx(styles.inputTag, styles.inputThird)}
                                            // `$ {id}` ép id thành string
                                            //@ts-ignore
                                            value={stateObj_groupInput_vao_right[index] || ''}
                                            placeholder="Nhập ghi chú..."
                                            onChange={(event) => handleInputChange_vao_right(index, event.target.value)}
                                        />
                                    </p>
                                </div>
                            );
                        })
                    }

                    

                    <div className={clsx(styles.totalRow)}>
                        <p className={clsx(styles.one)}></p>
                        <p className={clsx(styles.two)}>Tổng: </p>
                        <p className={clsx(styles.three)}>{formatThoudsand(stateNum_vao_right)}</p>
                        <p className={clsx(styles.four)}></p>
                        <p className={clsx(styles.five)}></p>
                    </div>
                </div>
                <div className={clsx(styles.table, styles.table_left_bottom)}>
                    <div className={clsx(styles.heading)}>
                        <p>STT</p>
                        <p>Thời gian</p>
                        <p className={clsx(styles.money)}>Tiền ra</p>
                        <p>Mô tả</p>
                        <p className={clsx(styles.btnSaveWrapper)}>
                            <span>
                                Ghi chú
                            </span>
                            <span className={clsx(styles.btnSave)} onClick={saveGhichu_ra_right}>
                                Save
                            </span>
                        </p>
                                
                    </div>
                    {
                        stateArr_quy_right_debit_ra.length > 0 &&
                        stateArr_quy_right_debit_ra.map((obj, index) => {
                            return (
                                <div className={clsx(styles.row)} key={index}>
                                    <p>{index + 1}</p>
                                    <p>{extractHHMM(obj.transactionDate)}</p>
                                    <p className={clsx(styles.money)}>{formatThoudsand(obj.debitAmount)}</p>
                                    <p>{obj.addDescription}</p>
                                    <p>
                                        <input
                                            type="text"
                                            className={clsx(styles.inputTag, styles.inputThird)}
                                            // `$ {id}` ép id thành string
                                            //@ts-ignore
                                            value={stateObj_groupInput_ra_left[index] || ''}
                                            placeholder="Nhập ghi chú..."
                                            onChange={(event) => handleInputChange_ra_right(index, event.target.value)}
                                        />
                                    </p>
                                </div>
                            );
                        })
                    }

                    

                    <div className={clsx(styles.totalRow)}>
                        <p className={clsx(styles.one)}></p>
                        <p className={clsx(styles.two)}>Tổng: </p>
                        <p className={clsx(styles.three)}>{formatThoudsand(stateNum_ra_right)}</p>
                        <p className={clsx(styles.four)}></p>
                        <p className={clsx(styles.five)}></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Quy