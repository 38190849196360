import React, { useState } from 'react';
import { retrieveObjectFromLocalStorage, wait } from '../../share/sharedFunction';
import collectionAPI from '../../../API/collectionAPI';
import styles from './Rutdao.module.scss' 
import clsx from 'clsx';

const UploadPictureNap = (props) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  }; 


  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    if(props.idNap == undefined || props.idNap == null){
      alert("Vui lòng chọn lại giao dịch hoặc thẻ cần làm, để hệ thống refresh lại dữ liệu cho chính xác, xin cảm ơn");
      return;
    }
    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles.length <= 0) {
        return;
    }
    const imageFile = droppedFiles[0];
    const maxSize = 1000 * 1024; // 500KB in bytes
    if (imageFile.size > maxSize) {
      alert('Hình ảnh phải có dung lượng dưới 1MB (1000KB). Vui lòng chọn hình khác');
      return;
    }
    // Do something with the dropped image file, for example, display it or upload it.
    // console.log('Dropped Image File:', imageFile);

    const formData = new FormData();
    formData.append('file', imageFile);
    const userExist = retrieveObjectFromLocalStorage("loginSuccessEmployee");
    if(userExist === null){
        return;
    }
    const token = userExist.token;

    try {
        // upload hình lên google cloud service storage
        let response1 = await collectionAPI.collectionAPI.uploadFile(token, formData);
        // console.log('response1: ', response1);
        if(response1.status === 201){
          alert("Thêm hình nạp tiền thành công !")
        }
        // upload xong nó sẽ trả ra cái mediaId và save vào bảng media

        const mediaId = response1.data.id;
        if(props.idNap == undefined || props.idNap == null){
          alert("Vui lòng chọn lại giao dịch hoặc thẻ cần làm, để hệ thống refresh lại dữ liệu cho chính xác, xin cảm ơn");
        }
        const idNap = props.idNap;
        // console.log('props.idNap: ', props.idNap);
        // console.log('idNap: ', idNap);
        props.send_MediaId_nap_ToParent(mediaId, idNap)

    } catch (err) {
        console.log('err:', err);
    }
    
  };
  

  return (
        <div
            className={clsx('dropZone', { dragging: isDragging }, styles.dropZone)} // Use clsx to conditionally apply classes
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            // onClick={handleImageClick}
            >
            <p>Hình nạp</p>
        </div>
  );
};

export default UploadPictureNap;
