import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useDispatch } from 'react-redux';
import styles from './Quanly.module.scss'
import QuanlySubMenu from './QuanlySubMenu';

function QuanlyHome() {
   const dispatch = useDispatch(); //hook phát đi đến GeneralSlice.js

   return (
      <div className={clsx(styles.component_QuanlyHome)}>
         <QuanlySubMenu/>   
         <Outlet/>

      </div>
   )
}
export default QuanlyHome


